export const ADD_PRODUCT_REQUEST = "ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";
export const ADD_PRODUCT_RESET = "ADD_PRODUCT_RESET";
export const UPLOAD_MAIN_IMAGE_REQUEST = "UPLOAD_MAIN_IMAGE_REQUEST";
export const UPLOAD_MAIN_IMAGE_SUCCESS = "UPLOAD_MAIN_IMAGE_SUCCESS";
export const UPLOAD_MAIN_IMAGE_FAIL = "UPLOAD_MAIN_IMAGE_FAIL";
export const UPLOAD_MAIN_IMAGE_RESET = "UPLOAD_MAIN_IMAGE_RESET";
export const UPLOAD_IMAGES_REQUEST = "UPLOAD_IMAGES_REQUEST";
export const UPLOAD_IMAGES_SUCCESS = "UPLOAD_IMAGES_SUCCESS";
export const UPLOAD_IMAGES_FAIL = "UPLOAD_IMAGES_FAIL";
export const UPLOAD_IMAGES_RESET = "UPLOAD_IMAGES_RESET";
//inside add product
export const VEHICLE_BRAND_REQUEST = "VEHICLE_BRAND_REQUEST";
export const VEHICLE_BRAND_SUCCESS = "VEHICLE_BRAND_SUCCESS";
export const VEHICLE_BRAND_FAIL = "VEHICLE_BRAND_LIST_FAIL";
export const BRAND_MODULES_REQUEST = "BRAND_MODULES_REQUEST";
export const BRAND_MODULES_SUCCESS = "BRAND_MODULES_SUCCESS";
export const BRAND_MODULES_FAIL = "BRAND_MODULES_FAIL";
export const BODYTYPES_REQUEST = "BODYTYPES_REQUEST";
export const BODYTYPES_SUCCESS = "BODYTYPES_SUCCESS";
export const BODYTYPES_FAIL = "BODYTYPES_FAIL";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL";
export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";