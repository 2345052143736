import React, { useState, useEffect } from "react";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import {
  verifyEmail,
  userActivate,
  register,
  login,
  firebaseRegisterHandler,
  resendCode,
} from "../../../../store/actions/authActions";
import Loader from "../../Loader/Loader";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import {
  ACTIVATE_USER_RESET,
  EMAIL_VERIFICATION_RESET,
} from "../../../../store/constants/authConstants";
import { uploadProfilePicture } from "../../../../store/actions/userActions";
import { emailVerificationReducer } from "../../../../store/reducers/authReducers";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../../../assests/svg/La7i2_7alak1.1.svg'

const VerifyEmail = ({ setStep, closeHandler, email, formData }) => {
  const dispatch = useDispatch();
  const maskedEmail = formData.email ? formData.email.slice(0, 3) + '*'.repeat(formData.email.length - 3 - formData.email.split('@')[0].length) + formData.email.split('@')[1] : '';
  const [loading, setIsLoading] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const vrf = useSelector(state => state.emailVerification);
  const emailVerification = useSelector((state) => state.emailVerification);
  const { error, code: codeBackend } = emailVerification;
  const activateUser = useSelector((state) => state.activateUser);
  const { success } = activateUser;
  const [code, setCode] = useState("");
  const [Viewemail, setViewemail] = useState("test")
  const [isConfirmed, setIsConfirmed] = useState(false);
  function handleBut() {
    setIsConfirmed(true);
  }
  const [isResending, setIsResending] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const handleResendCode = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const response = await dispatch(resendCode());
      
      setIsLoading(false);
      if (response) {
        setSend(true);
        toast.success("Code resent successfully.")
        setIsResending(true)
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // You can add additional error handling logic here, such as showing an error message to the user.
      setIsLoading(false);
      setIsResending(false);
    }
  };
  ///
  

  useEffect(() => {
    let countdownInterval;

    if (isResending && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setIsResending(false);
      setCountdown(30);
      clearInterval(countdownInterval);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [isResending, countdown]);

  const token = localStorage.getItem('token');
  const [failMessage, setFailMessage] = useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true)
      const response = await axios.post('https://backend.la7i27alak.com/user/verify/email', {
        emailVerificationCode: code
      }, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        }
      });
      dispatch(verifyEmail(response.data));
      // setStep('login with code');
      setStep('favorite categories');
      // closeHandler();
      // setStep('login')
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error('Invalid verification code. Please try again.')
        setTimeout(() => setFailMessage(''), 3000);
        setIsLoading(false)
      }
    }
  };
  const [send, setSend] = useState(false)
  return (
    <div className={`${styles.container} ${darkMode ? styles.containerDark : ''}`} >
      <Close
        className={styles.close}
        onClick={() => {
          closeHandler();
          setStep("login");
          dispatch({ type: EMAIL_VERIFICATION_RESET });
          dispatch({ type: ACTIVATE_USER_RESET });
        }}
      />
      {/* <Back className={styles.back} onClick={() => setStep("register")} /> */}
      <div className={styles.verification_container}>
      <div className={styles.logoCont}>
            <img src={logo} alt='logo' className={styles.logo} />
          </div>
        <h3 className={`${styles.verification_title} ${darkMode ? styles.verification_titleDark : ''}`}>Verification Code</h3>
        <p className={styles.verification_text}>
          A 4-digit verification code has been sent to your email
          {/* <span className={styles.email}>{formData.email}</span> */}
          <span className={styles.email}>{maskedEmail}</span>
        </p>
        <div className={styles.code_container}>
          <input
            type="text"
            className={`${styles.code_input} ${darkMode ? styles.code_inputDark : ''}`}
            placeholder="Enter 4-digit code"
            maxLength={4}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          {loading ? (
            <div className="center" style={{paddingTop:'30px'}}>
              <Loader />
            </div>
          ) : (
            isResending?(
              <button
              className={styles.send_btnRsnd}
           
              disabled={isResending}
            >
              Resend in {countdown}s
            </button>
           ):(
            <button
              className={`${styles.send_btn} ${darkMode ? styles.send_btnDark : ''}`}
              onClick={handleResendCode} 
              disabled={isResending}           >
              Resend Code
            </button>
            )
          )}
          {error && (
            <div className={styles.message}>
              <ErrorMessage>{error}</ErrorMessage>
            </div>
          )}
        </div>
      </div>
      {failMessage && <p style={{ color: 'red' }}>{failMessage}</p>}

      <button className={`${styles.confirm_btn} ${darkMode ? styles.confirm_btnDark : ''}`} 
      onClick={handleSubmit}>
        Confirm
      </button>
    </div>
  );
};
export default VerifyEmail;