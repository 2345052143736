import React, { useEffect } from "react";
import styles from "./HelpPage.module.scss";
import CurrentPath from "../../common/components/CurrentPath/CurrentPath";
import Search from "../../common/components/Search/Search";
import Header from "../../common/components/Header/Header";
import Footer from "../../common/components/Footer/Footer";
import Card from "./card/Card";
import Sell from "../../assests/images/Sell.png";
import Cart from "../../assests/images/cart.png";
import Profile from "../../assests/images/user.png";
import Bid from "../../assests/images/Bid.png";
import Star from "../../assests/images/Star.png";
import Payment from "../../assests/images/Payment.png";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
const HelpPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.isHelp) {
      const pageName = "Help "; // Replace this with the actual page name
      const pageUrl = "/help"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Help "; // Replace this with the actual page name
      const pageUrl = "/help"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, []);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <>
      <Helmet>
        <title>Help</title>
        <meta name="Help" content="This is Help Page" />
        <meta
          name="keywords"
          content={`la7i27alak faq, faq, help, help la7i27alak, what to do, more info, product, service, la7i27alak`}
        />
      </Helmet>
      <Header />
      <section className={`${styles.help_section} ${darkMode ? styles.help_sectionDark : ''}`}>
        <CurrentPath />
        <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>How can we help you?</h1>
        {/* <div className={styles.search_container}>
          <Search />
        </div> */}
        {/* <h3 className={`${styles.help} ${darkMode?styles.helpDark:''}`}>Browse Help Topics</h3> */}
        <div class={styles.card_container}>
          <Card image={'/HBuy.svg'} alt={"Buy"} text={"Buy"} />
          <Card image={'/HSell.svg'} alt={"Sell"} text={"Sell"} />
          <Card image={'/HBid.svg'} alt={"Bid"} text={"Bid"} />
          <Card image={'/HFeatured.svg'} alt={"Feature-Products"} text={"Feature Products"} />
          <Card image={'/HPayment.svg'} alt={"Payment"} text={"Payment"} />
          <Card image={'/Hperson.svg'} alt={'Profile'} text={"Profile"} />
        </div>
      </section>
      <Footer />
    </>
  );
};
export default HelpPage;
