import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import "./DragAndDrop.scss";
import { ImageConfig } from "./ImageConfig";
import upload from "../../../assests/images/upload.png";
import { Formik } from "formik";
const DropFileInput = (props) => {
  const wrapperRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(props.selectedFile);
  const [fileList, setFileList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      setSelectedFile(newFile);
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
      props.onFileChange(updatedList);
    }
  };
  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    setSelectedFile(null);
    props.onFileChange(updatedList);
  };
  const [activeButton, setActiveButton] = useState(1);
  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
    const categoryType = buttonNumber === 1 ? "Products" : "Services";
    props.onCategoryTypeChange(categoryType);
  };
  return (
    <>
      <div className="containerdiv">
        <div
          ref={wrapperRef}
          className="drop-file-input"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="drop-file">
            <img src={upload} alt="" className="uploadim" />
            <p className="drag">Drag & Drop icon here </p>
            <br /> <p className="or">or </p>
            <br /> <button className="uploadbut">Upload Icon</button>
            <input
              type="file"
              value=""
              accept="image/svg+xml"
              onChange={onFileDrop}
              className="inputF"
            />
          </div>
        </div>
      </div>
      {/* <div className='ProdServ'>
                <div className='catT'><p>Category type</p></div>
            <button
             className={activeButton === 1 ? "active" : ""}
            onClick={() => handleButtonClick(1)}
            >
                    Products
            </button>
            <button
             className={activeButton === 2 ? "active" : ""}
             onClick={() => handleButtonClick(2)}
            >
             Services
            </button>
            </div> 
    </div>*/}
      {fileList.length > 0 ? (
        <div className="drop-file-preview">
          {fileList.map((item, index) => (
            <div key={index} className="drop-file-preview__item">
              <img
                src={
                  ImageConfig[item.type.split("/")[1]] || ImageConfig["default"]
                }
                alt=""
              />
              <div className="drop-file-preview__item__info">
                <p>{item.name}</p>
                <p>{item.size}B</p>
              </div>
              <span
                className="drop-file-preview__item__del"
                onClick={() => fileRemove(item)}
              >
                x
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};
DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};
export default DropFileInput;
