import {
    GET_SERVICE_BY_TYPE_FAIL,
    GET_SERVICE_BY_TYPE_SUCCESS,
    GET_SERVICE_BY_TYPE_REQUEST
} from "../constants/productDetailsConstants";
const initialState = {
    serviceByType: [],
    error: null,
    loading: false,
};
export const GetServiceByType = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_BY_TYPE_REQUEST:
            return {
                ...state,
                serviceByType: action.payload,
                error: null,
                loading: true,
            };
        case GET_SERVICE_BY_TYPE_SUCCESS:
            return {
                ...state,
                serviceByType: action.payload,
                error: null,
                loading: false,
            };
        case GET_SERVICE_BY_TYPE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};