import React, { useState, useEffect } from "react";
import styles from "./ChangePassword.module.scss";
import { BiHide, BiShow, BiLockAlt } from "react-icons/bi";
import { useFormik } from "formik";
import * as Yup from "yup";
import VerifyCode from "../EditProfilePage/Verify Code/VerifyCode";
import Layout from "../../common/components/Layouts/Layout";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/components/Loader/Loader";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import { passwordRules } from "../../common/constants/regex";
import axios from "axios";
import { getAuth, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const pageName = "Change Password"; // Replace this with the actual page name
    const pageUrl = "/user/edit-profile/change-password"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const token = localStorage.getItem("token");
  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [isOpen, setIsOpen] = useState(false);
  const userById = useSelector((state) => state.userById);
  const { user } = userById;
  const changePassword = useSelector((state) => state.changePassword);
  const { loading, success, error } = changePassword;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  useEffect(() => {
    if (success) {
      navigate("/user/edit-profile/");
    } else if (userInfo === null) {
      navigate("/");
    }
  }, [success, dispatch, navigate, userInfo]);
  const [password, setPassword] = useState("");
  const [failm, Setfailm] = useState("");
  const [sucm, Setsucm] = useState("");
  const formik = useFormik({
    initialValues: {
      current: "",
      new: "",
      confirm: "",
    },
    onSubmit: async (values) => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        // Reauthenticate the user with their current password
        const credential = EmailAuthProvider.credential(user.email, values.current);
        await reauthenticateWithCredential(user, credential);
        // Update the user's password
        await updatePassword(user, values.new);
        // Password updated successfully
        toast.success('Password updated successfully.');
        navigate('/')
      } catch (error) {
        // Handle the error
        if (error.code === 'auth/wrong-password') {
          Setfailm('Current Password is Incorrect');
        } else {
           Setfailm('Error updating password, please try again');
        }
      }
    },
    validationSchema: Yup.object({
      current: Yup.string().required("Current Password is required"),
      new: Yup.string()
        .matches(passwordRules, {
          message: "Please create a stronger password",
        })
        .required("New Password is required"),
      confirm: Yup.string()
        .oneOf([Yup.ref("new"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
  });
  const handlepassInputFocus = () => {
    Setsucm('')
    Setfailm("");
  };
  return (
    <>
      <Helmet>
        <title>Update Password</title>
        <meta name='Update Password' content="Update Password page" />
      </Helmet>
      <Layout title={"Edit Profile"}>
        <div className={styles.wrapper}>
          <form className={`${styles.form_section} ${darkMode ? styles.form_sectionDark : ''}`} onSubmit={formik.handleSubmit}>
            <h3 className={`${styles.subtitle} ${darkMode ? styles.subtitleDark : ''}`}>Set New Password</h3>
            <p className={`${styles.text}`}>
              Your new password must be different from previously used passwords
            </p>
            <div className={styles.input_container}>
              <input
                type={currentPassword ? "text" : "password"}
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                placeholder="Current Password"
                name="current"
                onFocus={handlepassInputFocus}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.current}
              />
              {formik.errors.current && formik.touched.current && (
                <p>{formik.errors.current}</p>
              )}
              {!currentPassword && (
                <BiHide
                  className={styles.pass_icon}
                  onClick={() => setCurrentPassword(true)}
                />
              )}
              {currentPassword && (
                <BiShow
                  className={styles.pass_icon}
                  onClick={() => setCurrentPassword(false)}
                />
              )}
              <BiLockAlt className={styles.icon} />
            </div>
            <div className={styles.input_container}>
              <input
                type={newPassword ? "text" : "password"}
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                placeholder="New Password"
                name="new"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.new}
              />
              {formik.errors.new && formik.touched.new && (
                <p>{formik.errors.new}</p>
              )}
              {!newPassword && (
                <BiHide
                  className={styles.pass_icon}
                  onClick={() => setNewPassword(true)}
                />
              )}
              {newPassword && (
                <BiShow
                  className={styles.pass_icon}
                  onClick={() => setNewPassword(false)}
                />
              )}
              <BiLockAlt className={styles.icon} />
            </div>
            <div className={styles.input_container}>
              <input
                type={confirmPassword ? "text" : "password"}
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                placeholder="Confirm Password"
                name="confirm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirm}
              />
              {formik.errors.confirm && formik.touched.confirm && (
                <p>{formik.errors.confirm}</p>
              )}
              {!confirmPassword && (
                <BiHide
                  className={styles.pass_icon}
                  onClick={() => setConfirmPassword(true)}
                />
              )}
              {confirmPassword && (
                <BiShow
                  className={styles.pass_icon}
                  onClick={() => setConfirmPassword(false)}
                />
              )}
              <BiLockAlt className={styles.icon} />
            </div>
            {loading ? (
              <Loader />
            ) : error ? (
              <ErrorMessage>{error}</ErrorMessage>
            ) : (
              <>
                <div style={{ color: 'red' }}>
                  {failm}
                </div>
                <div style={{ color: '#0298a6' }}>
                  {sucm}
                </div>
                <button
                  type="submit"
                  className={`${styles.button} ${darkMode ? styles.buttonDark : ''}`}
                  onClick={formik.handleSubmit}
                >
                  Change Password
                </button>
              </>
            )}
            <VerifyCode open={isOpen} setOpen={() => setIsOpen(false)} />
          </form>
        </div>
      </Layout>
    </>
  );
};
export default ChangePasswordPage;
