import React from "react";
import styles from "./styles.module.scss";
import ReactDom from "react-dom";
import { ReactComponent as Close } from "../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../assests/svg/canceldark.svg";
import { useSelector } from "react-redux";
const ConfirmModal = ({ isOpen, onClose, children }) => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  if (!isOpen) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay} onClick={onClose} />
      <div className={`${styles.modal} ${darkMode?styles.modalDark:''}`}>
      {darkMode ? (
            <CloseDark className={styles.close} onClick={onClose} />
          ) :
            (<Close className={styles.close} onClick={onClose} />)} 
        {children}
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default ConfirmModal;
