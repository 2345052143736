import React, { useState, useEffect, useRef } from "react";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap-submenu/dist/index.css";
import "./servicesDropdown.css"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styles from './index.scss'
import { GetAllTypes } from "../../../../store/actions/servicesActions";
function ServicesDropDown() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const types = useSelector((state) => state.types?.types?.data?.types || [])
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    useEffect(() => {
        dispatch(GetAllTypes())
    }, [dispatch]);
    return (
        <NavDropdown
            id="nav-dropdown-dark-example"
            title="Services"
            menuVariant={darkMode ? "dark" : "white"}
            className={`text ${darkMode ? 'textDark' : ''}`}
        >
            {types && types.map((category) => (
                <NavDropdown.Item
                    key={category._id}
                    href={category.title}
                    title={category.title}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate(`/services/${category.title.replaceAll(" ", "-")}/${category._id}`);
                    }}
                >
                    {category.title}
                </NavDropdown.Item>
            ))}
        </NavDropdown>
    );
}
export default ServicesDropDown;
