import React, { useState } from "react";
import styles from "./Service.module.scss";
import { ReactComponent as Arrow } from "../../../../assests/svg/right arrow.svg";
import { ReactComponent as Frame } from "../../../../assests/svg/Frame.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Service = () => {
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const activateFirst = () => {
    setFirst(true);
    setSecond(false);
  };
  const activateSecond = () => {
    setFirst(false);
    setSecond(true);
  };
  const clickHandler = () => {
    if (first) {
      navigate("/sell/services/offer");
    } else if (second) {
      navigate("/sell/services/request");
    }
  };
  return (
    <div className={styles.container}>
      <div className={`${styles.title} ${darkMode?styles.titleDark : '' }`}>
        <h1>Service Type</h1>
        <h3 className={`${styles.subtitle} ${darkMode?styles.subtitleDark : '' }`}>What type of service do you need?</h3>
        <div
          className={`${styles.service_container} ${darkMode?styles.service_containerDark : '' } ${
            first ? styles.active : ""
          }`}
          onClick={activateFirst}
        >
          <p className={`${styles.service_text} ${darkMode? styles.service_textDark:''}`}>Offer a Service</p>
          {first && <Frame className={styles.active_icon} />}
        </div>
        <p className={styles.text}>
          Offering a service means presenting a professioonal skill you can
          provide, which other users may need.
        </p>
        <div
          className={`${styles.service_container} ${darkMode?styles.service_containerDark : '' } ${
            second ? styles.active : ""
          }`}
          onClick={activateSecond}
        >
          <p className={`${styles.service_text} ${darkMode? styles.service_textDark:''}`}>Request a Service</p>
          {second && <Frame className={styles.active_icon} />}
        </div>
        <p className={styles.text}>
          Requesting a service means looking for those with professioonal skills
          to help with a specific task you may require assistance with.
        </p>
        <button
          className={`${styles.next} ${darkMode? styles.nextDark:''} ${
            first || second ? styles.active_next : ""
          }`}
          onClick={() => clickHandler()}
        >
          Next <Arrow className={styles.icon} />
        </button>
      </div>
    </div>
  );
};
export default Service;
