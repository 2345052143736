import {
    SEND_OFFER_FAIL,
    SEND_OFFER_SUCCESS,
    DONE_DEAL_SUCCESS,
    DONE_DEAL_FAIL,
    GET_OFFER_BY_DATE_SUCCESS,
    GET_OFFER_BY_DATE_FAIL
} from "../constants/sendOfferConstants";
import axios from "axios";
import { url } from "../../common/constants/url";
export const SendOffer = (data) => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token')
        
        const response = await axios.post(`${url}/product/waitingList/join `, data, {
          headers: {
            'authorization': `${token}`,
            'Content-Type': 'application/json'
          },
        });
        dispatch(sendOfferSuccess(response.data));
        return response.data;
      } catch (error) {
        dispatch(sendOfferFail(error));
        // console.log(error)
      }
    };
  };
  const sendOfferSuccess = (data) => ({
    type: SEND_OFFER_SUCCESS,
    payload: data
  });
  const sendOfferFail = (error) => ({
    type: SEND_OFFER_FAIL,
    payload: error
  });
  export const DoneDeal = (id) => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token')
        
        const response = await axios.post(`${url}/offer/doneDeal/${id} `,null, {
          headers: {
            'authorization': `${token}`,
          },
        });
        dispatch(DoneDealSuccess(response.data));
        return response.data;
      } catch (error) {
        dispatch(DoneDealFail(error));
        // console.log(error)
      }
    };
  };
  const DoneDealSuccess = (data) => ({
    type: DONE_DEAL_SUCCESS,
    payload: data
  });
  const DoneDealFail = (error) => ({
    type: DONE_DEAL_FAIL,
    payload: error
  });
  export const GetOfferByDate = (rawData) => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token')
        
        const response = await axios.post(`${url}/all/getSpecialOffers `,rawData, {
          headers: {
            'authorization': `${token}`,
            'Content-Type':'application/json'
          },
        });
        dispatch(GetOfferByDateSuccess(response.data));
        return response.data;
      } catch (error) {
        dispatch(GetOfferByDateFail(error));
        // console.log(error)
      }
    };
  };
  const GetOfferByDateSuccess = (data) => ({
    type: GET_OFFER_BY_DATE_SUCCESS,
    payload: data
  });
  const GetOfferByDateFail = (error) => ({
    type: GET_OFFER_BY_DATE_FAIL,
    payload: error
  });
