import React, { useState, useEffect } from "react";
import styles from "./EditProfilePage.module.scss";
import { FaStar, FaRegStar, FaCamera } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import "./index.scss";
import { Link } from "react-router-dom";
import {
  AiOutlineMail,
  AiOutlineUser,
  AiOutlineCalendar,
  AiFillDelete,
  AiOutlineLink,
} from "react-icons/ai";
import { CgSortAz } from "react-icons/cg";
import axios from "axios";
import Layout from "../../common/components/Layouts/Layout";
import ReviewCard from "./ReviewCard/ReviewCard";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Profile from "../../assests/svg/Icon material-person.svg";
import {
  uploadProfilePicture,
  updateProfile,
  listUserRates,
  changeProfilePicture,
  updateProfileWithImage,
  getUserById,
  GetAverageReview,
} from "../../store/actions/userActions";
import {
  USERCARD_RESET,
  USER_RESET,
} from "../../store/constants/userConstants";
import { EditProfile } from "../../store/actions/editProfileActions";
import { GetReviewsByUID } from "../../store/actions/userActions";
import { GetUser } from "../../store/actions/userActions";
import { GetProductPerUser } from "../../store/actions/userActions";
import RatingCard from "../UserPage/components/RatingCard/RatingCard";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/components/Loader/Loader";
const EditProfilePage = () => {
  const navigate = useNavigate();
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Months are zero-indexed
    let day = today.getDate();
    // Ensure the month and day are formatted with leading zeros if needed
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    dispatch({ type: "CLEAR_BREADCRUMBS" });
    const pageName = "Edit Profile"; // Replace this with the actual page name
    const pageUrl = "/user/edit-profile"; // Replace this with the actual page URL
    dispatch({
      type: "ADD_BREADCRUMB",
      payload: { name: pageName, url: pageUrl },
    });
  }, []);
  const dispatch = useDispatch();
  const [reviews, setReviews] = useState([]);
  const [reviewsVisible, setReviewsVisible] = useState(true);
  const [product, setProduct] = useState([]);
  const [loading, setloading] = useState(false);
  const handleHideReviews = () => {
    setReviewsVisible(!reviewsVisible);
  };
  const updateUser = useSelector((state) => state.updateUser);
  const { success } = updateUser;
  //const userById = useSelector((state) => state.userById);
  const userInformation = JSON.parse(localStorage.getItem("userInfo"));
  const uid = userInformation ? userInformation.uid : null;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  //let id = JSON.parse(atob(userInfo?.split(".")[1]))?.id || "";
  const getusercardrates = useSelector((state) => state.getusercardrates);
  const { usercard } = getusercardrates;
  const [prodUser, setProdUser] = useState([]);
  const [count, setCount] = useState(null);

  useEffect(() => {
    dispatch(GetUser(uid))
      .then((response) => {
        setProdUser(response.data?.user);
        setCount(response.data?.postCount);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch, uid]);
  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [dob, setdob] = useState("");
  const [preview, setPreview] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("username", username);
    formData.append("phone", phone);
    formData.append("dob", dob);
    if (image) {
      formData.append("image", image);
    }
    if (phone && phone.length < 10) {
      toast.warning("Please  write a valid phone number.");
    } else {
      try {
        const response = await axios.put(
          `https://backend.la7i27alak.com/user`,
          formData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const newImageUrl = response.data?.user?.imageUrl;
        setImage(newImageUrl);
        setSuccessMessage("Profile Updated Sucessfully");
        setTimeout(() => setSuccessMessage(""), 3000);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result); // set preview state to base64-encoded image string
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const [role, setRole] = useState("");
  const userid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  useEffect(() => {
    setloading(true);
    axios
      .get(`https://backend.la7i27alak.com/user/${userid}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data?.user?.username) {
          setUsername(response.data?.user?.username);
        } else if (response.data?.user?.companyName) {
          setUsername(response.data?.user?.companyName);
        }
        setdob(response.data?.user?.dob);
        setImageUrl(response.data?.user?.imageUrl);
        setEmail(response.data?.user?.email);
        setPhone(response.data?.user?.phone);
        setRole(response.data?.user?.role);
        setloading(false);
      })
      .catch((error) => console.error(error));
  }, [userid, token]);
  const baseUrl = "https://backend.la7i27alak.com";
  useEffect(() => {
    dispatch(GetReviewsByUID(uid))
      .then((response) => {
        setReviews(response.data?.reviews);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch, uid]);
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyLink = () => {
    const currentUrl = `https://share.la7i27alak.com/share/profile/${username.replace(
      " ",
      "-"
    )}/${userid}`;
    const input = document.createElement("input");
    input.setAttribute("value", currentUrl);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    toast.success("Copied");
  };
  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    if (selectedDate > currentDate) {
      // If selected date is in the future, set it to the current date
      setdob(currentDate.toISOString().substr(0, 10)); // Format the date as 'YYYY-MM-DD'
    } else {
      setdob(e.target.value);
    }
  };
  return (
    <>
      <Helmet>
        <title>Edit Profile</title>
        <meta name="Edit Profile" content="Edit Profile" />
      </Helmet>
      <Layout title={"Edit Profile"}>
        <section className={styles.secedit}>
          <div
            className={`${styles.container} ${
              darkMode ? styles.containerDark : ""
            }`}
          >
            <div className={styles.shareCont}>
              <div className={styles.share} onClick={handleCopyLink}>
                <AiOutlineLink className={styles.shareic} />
                Share Profile
              </div>
              <ToastContainer />
            </div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </div>
            ) : (
              <>
                <div className={styles.wrapper}>
                  {/* Left */}
                  <div className={styles.account_container}>
                    <div className={styles.picture_container}>
                      <img
                        src={preview || imageUrl}
                        alt="profile"
                        className={styles.pp}
                      />
                      <div className={styles.camera_container}>
                        <FaCamera
                          onClick={handleClick}
                          className={styles.camera_icon}
                        />
                      </div>
                      <button
                        className={styles.change_image}
                        onClick={handleClick}
                      >
                        Change Image
                      </button>
                      <input
                        type="file"
                        accept="image/png, image/jpg, image/jpeg"
                        ref={hiddenFileInput}
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    </div>
                    <div className={styles.info_container}>
                      <h1
                        className={`${styles.name} ${
                          darkMode ? styles.nameDark : ""
                        }`}
                      >
                        {username}
                      </h1>
                      <div className={styles.details_container}>
                        <p
                          className={`${styles.details} ${
                            darkMode ? styles.detailsDark : ""
                          }`}
                        >
                          {count ?? 0}{" "}
                          <span
                            className={`${styles.span} ${
                              darkMode ? styles.spanDark : ""
                            }`}
                          >
                            Posts
                          </span>
                        </p>
                        <p
                          className={`${styles.details} ${
                            darkMode ? styles.detailsDark : ""
                          }`}
                        >
                          {prodUser && prodUser.followers
                            ? prodUser.followers?.length
                            : 0}{" "}
                          <span
                            className={`${styles.span} ${
                              darkMode ? styles.spanDark : ""
                            }`}
                          >
                            Followers
                          </span>
                        </p>
                        <p
                          className={`${styles.details} ${
                            darkMode ? styles.detailsDark : ""
                          }`}
                        >
                          {prodUser && prodUser.following
                            ? prodUser.following?.length
                            : 0}{" "}
                          <span
                            className={`${styles.span} ${
                              darkMode ? styles.spanDark : ""
                            }`}
                          >
                            Followings
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Right */}
                  <RatingCard
                    averageStar={prodUser && prodUser.reviews?.average}
                    reviews={reviews}
                    id={uid}
                  />
                </div>
                <div className={styles.edit_section}>
                  <h1
                    className={`${styles.edit_title} ${
                      darkMode ? styles.edit_titleDark : ""
                    }`}
                  >
                    User Information
                  </h1>
                  <div className={styles.form_container}>
                    <div className={styles.label_input_container}>
                      <label
                        htmlFor="username"
                        className={`${styles.label} ${
                          darkMode ? styles.labelDark : ""
                        }`}
                      >
                        Username
                      </label>
                      <div className={styles.input_container}>
                        <input
                          type="text"
                          id="username"
                          className={`${styles.input} ${
                            darkMode ? styles.inputDark : ""
                          }`}
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                        <AiOutlineUser className={styles.input_icon} />
                      </div>
                    </div>
                    {role === "MERCHANT" ? null : (
                      <div className={styles.label_input_container}>
                        <label
                          htmlFor="date"
                          className={`${styles.label} ${
                            darkMode ? styles.labelDark : ""
                          }`}
                        >
                          Date of Birth
                        </label>
                        <div className={styles.input_container}>
                          <AiOutlineCalendar className={styles.input_icon} />
                          <input
                            type="date"
                            id="date"
                            className={`${styles.input} ${
                              darkMode ? styles.inputDark : ""
                            }`}
                            value={dob}
                            onChange={handleDateChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <h1
                    className={`${styles.edit_title} ${
                      darkMode ? styles.edit_titleDark : ""
                    }`}
                  >
                    Contact Information
                  </h1>
                  <div className={styles.form_container}>
                    <div className={styles.label_input_container}>
                      <label
                        htmlFor="phone"
                        className={`${styles.label} ${
                          darkMode ? styles.labelDark : ""
                        }`}
                      >
                        Phone Number
                      </label>
                      <div className="edit--phone">
                        <PhoneInput
                          defaultCountry="LB"
                          placeholder="Phone number"
                          value={phone}
                          onChange={setPhone}
                          // style={{backgroundColor: darkMode? '#015866':'',border:darkMode? 'none':''}}
                          autoFocus
                          className={`${styles.phonei} ${
                            darkMode ? styles.phoneiDark : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className={styles.label_input_container}>
                      <label
                        htmlFor="email"
                        className={`${styles.label} ${
                          darkMode ? styles.labelDark : ""
                        }`}
                      >
                        Email address
                      </label>
                      <div className={styles.input_container}>
                        <AiOutlineMail className={styles.input_icon} />
                        <input
                          type="email"
                          id="email"
                          className={`${styles.input} ${
                            darkMode ? styles.inputDark : ""
                          }`}
                          value={email}
                          // onChange={(e) => setEmail(e.target.value)}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className={styles.button_container}>
              {successMessage && (
                <p style={{ color: "#0298a6" }}>{successMessage}</p>
              )}
              <button
                className={`${styles.update_btn} ${
                  darkMode ? styles.update_btnDark : ""
                }`}
                onClick={handleSubmit}
              >
                Update Profile
              </button>
              <Link
                className={styles.edit_password}
                to={"/user/edit-profile/change-password"}
              >
                Change Password?
              </Link>
              <Link
                className={styles.delete_profile}
                to={"/user/delete-account"}
              >
                <AiFillDelete className={styles.icd} /> Delete Account?
              </Link>
            </div>
            <div className={styles.reviews_section}>
              <h1
                className={`${styles.reviews_title} ${
                  darkMode ? styles.reviews_titleDark : ""
                }`}
              >
                Reviews
              </h1>
              <div className={styles.sort_container}>
                {/* <span >
                <CgSortAz className={`${styles.sort_icon} ${darkMode ? styles.sort_iconDark : ''}`} />{" "}
                <select className={`${styles.sort} ${darkMode ? styles.sortDark : ''}`}>
                  <option value="sort">Sort</option>
                </select>{" "}
              </span> */}
                <span
                  className={`${styles.hide} ${
                    darkMode ? styles.hideDark : ""
                  }`}
                  onClick={handleHideReviews}
                >
                  {reviewsVisible ? "Hide all" : "Unhide all"}
                </span>
              </div>
            </div>

            {reviewsVisible &&
              reviews.map((review) => (
                <div key={review.id} className={styles.review}>
                  <ReviewCard
                    from={review.from}
                    id={review._id}
                    time={review.createdAt}
                    text={review.comment}
                    star={review.rating}
                  />
                </div>
              ))}
          </div>
        </section>
      </Layout>
      <style>
        {`
          ${
            darkMode
              ? ".edit--phone .PhoneInputInput { background: transparent;color:#a6c0c8 }"
              : ""
          }
        `}
      </style>
    </>
  );
};
export default EditProfilePage;
