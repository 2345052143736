import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import axios from "axios";
import "./editinfo.scss";
import PhoneInput from "react-phone-number-input";
import { toast } from "react-toastify";
const EditInfo = ({ setStep }) => {
  const location = useLocation();
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Months are zero-indexed
    let day = today.getDate();
    // Ensure the month and day are formatted with leading zeros if needed
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`;
  }
  const token = localStorage.getItem("token");
  const [name, setName] = useState(location.state?.username || "");
  const [image, setImage] = useState(null);
  const [dob, setDob] = useState(getCurrentDate());
  const [error, setError] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      toast.error("Please select a date in the past.");
      setDob(getCurrentDate());
    } else {
      formik.handleChange(event);
      setDob(event.target.value);
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required("Username cannot be empty"),
    dob: yup.date().required("Date of birth cannot be empty"),
    phone: yup
      .string()
      .required("Phone number cannot be empty")
      .matches(/^[0-9+]+$/, "Phone number must be numeric")
      .min(8, "Phone number must be at least 8 digits"),
    //   .max(15, "Phone number cannot be more than 10 digits"),
  });
  const handleFocus = () => {
    setError("");
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      dob: "",
      phone: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedDate = new Date(dob);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      toast.error("Date of birth cannot be in the future.");
      return;
    }
    if (phone && phone.length < 10) {
      toast.warning("Please write a valid phone number.");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("username", name);
      formData.append("dob", dob);
      formData.append("phone", phone);
      if (image) {
        formData.append("image", image, image.name);
      }
      if (!phone) {
        toast.warning("Please enter a phone number.");
        return;
      }
      if (!dob) {
        toast.warning("Please enter a date of birth.");
        return;
      }
      if (name.trim() === "") {
        toast.warning("Please enter a username");
        return;
      } else {
        setLoading(true);
        const response = await axios.put(
          "https://backend.la7i27alak.com/user",
          formData,
          {
            headers: {
              Authorization: `${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      // // console.log(error);
    }
  };

  return (
    <div className="container-info">
      <form className="center-div" onSubmit={handleSubmit}>
        <div className="photoContainer">
          {image ? (
            <img
              className="photo"
              src={URL.createObjectURL(image)}
              alt="Selected profile"
            />
          ) : (
            <img
              className="photo"
              src={location.state?.photoURL}
              alt="User profile"
            />
          )}
          <div className="text">
            <label className="label" htmlFor="fileInput">
              Edit Your Photo
            </label>
          </div>
          <input
            type="file"
            id="fileInput"
            className="fileInput"
            accept="image/png, image/jpg, image/jpeg"
            onChange={(event) => setImage(event.target.files[0])}
            style={{ display: "none" }}
          />
        </div>
        <div className="inputContainer">
          <input
            type="text"
            value={name}
            placeholder="Username"
            className={error ? "erroruserInput" : "inputN"}
            onChange={(event) => setName(event.target.value)}
            onFocus={handleFocus}
          />
          {error && <div className="errorMessage">{error}</div>}

          <input
            type="date"
            name="dob"
            value={dob}
            placeholder="Date of birth"
            className={`inputN ${formik.errors.dob ? "errorInput" : ""}`}
            onChange={handleDateChange}
            onBlur={formik.handleBlur}
          />
          {formik.errors.dob && formik.touched.dob && (
            <span className="errorMessage">{formik.errors.dob}</span>
          )}
          <PhoneInput
            value={phone}
            className={`inputN ${formik.errors.phone ? "errorInput" : ""}`}
            defaultCountry="LB"
            onChange={(value) => {
              formik.setFieldValue("phone", value);
              setPhone(value);
            }}
            inputProps={{
              name: "phone",
              onBlur: formik.handleBlur,
            }}
            placeholder="Phone number"
          />
          {formik.errors.phone && formik.touched.phone && (
            <span className="errorMessage">{formik.errors.phone}</span>
          )}

          {loading === true ? (
            <button className="submitLoad" type="submit" disabled={loading}>
              Submitting...
            </button>
          ) : (
            <button className="submit" type="submit" disabled={loading}>
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};
export default EditInfo;
