import {
  JOIN_BID_REQUEST,
  JOIN_BID_SUCCESS,
  JOIN_BID_RESET,
  JOIN_BID_FAIL,
  CHECK_USER_PAYMENT_BID_FAIL,
  CHECK_USER_PAYMENT_BID_REQUEST,
  CHECK_USER_PAYMENT_BID_RESET,
  CHECK_USER_PAYMENT_BID_SUCCESS,
} from "../constants/makePaymentConstants";
export const joinBidReducer = (
  state = { loading: false, error: null, receipt_url: null },
  action
) => {
  switch (action.type) {
    case JOIN_BID_REQUEST:
      return { ...state, loading: true };
    case JOIN_BID_SUCCESS:
      return { ...state, loading: false, receipt_url: action.payload };
    case JOIN_BID_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case JOIN_BID_RESET:
      return {
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};
export const checkUserPaymentBidReducer = (
  state = { loading: false, error: null, success: null },
  action
) => {
  switch (action.type) {
    case CHECK_USER_PAYMENT_BID_REQUEST:
      return { ...state, loading: true };
    case CHECK_USER_PAYMENT_BID_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case CHECK_USER_PAYMENT_BID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CHECK_USER_PAYMENT_BID_RESET:
      return {
        loading: false,
        error: null,
        success: null,
      };
    default:
      return state;
  }
};
