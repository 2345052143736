import React, { useState, useEffect } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "../Sell Product Form Page/SellProductForm.module.scss";
import Camera from "../../assests/images/camera.png";
import LocationMarker from "../../assests/svg/location marker.svg";
import { AiFillPlusCircle, AiOutlineRight } from "react-icons/ai";
import Switch from "react-switch";
import PostSuccess from "../../common/components/PostSuccess/PostSuccess";
import { useFormik } from "formik";
import { PropertiesValidationSchema } from "../Sell Product Form Page/index";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CreateProduct } from "../../store/actions/addProductActions";
import { url } from "../../common/constants/url";
import { GetAllSubCategories } from "../../store/actions/categoryAction";
import ColorDropdown from "../Sell Product Form Page/Properties/ColorList";
import { Scrollbars } from "react-custom-scrollbars";
import EditModel from "../Sell Product Form Page/Properties/EditModel";
import { Link } from "react-router-dom";
import { addProductArray } from "../../store/actions/PreviewActions";
import { AiOutlineDown } from "react-icons/ai";
import { EditProducts } from "../../store/actions/myProductsActions";
import { GetSingleProduct } from "../../store/actions/myProductsActions";
import AddressComponent from "../AddressPage/AddressComponent/AddressComponent";
import { useNavigate } from "react-router-dom";
import { getAddressByUser } from "../../store/actions/addAddressActions";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDuration } from "date-fns";
import MapModal from "../Sell Product Form Page/Properties/MapModal";
const EditProduct = () => {
  const dispatch = useDispatch();
  const { ProductId } = useParams();
  useEffect(() => {
    const pageName = "Edit product"; // Replace this with the actual page name
    const pageUrl = `/edit-product/${ProductId}`; // Replace this with the actual page URL
    dispatch({
      type: "ADD_BREADCRUMB",
      payload: { name: pageName, url: pageUrl },
    });
  }, []);
  const [product, setProduct] = useState([]);
  const [dynamicFieldsValues, setDynamicFieldsValues] = useState([]);
  const address = useSelector((state) => state.address?.address);
  const navigate = useNavigate();
  const defaultAddress = address.find((address) => address.isDefault);
  useEffect(() => {
    dispatch(getAddressByUser());
  }, [dispatch]);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [productTitle, setProductTitle] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [isNegotiable, setIsNegotiable] = useState("");
  const [photoStates, setPhotoStates] = useState(new Array(16).fill(null));
  const [isExchangeable, setIsExchangeable] = useState("");
  const [isRefundable, setIsRefundable] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [conditionState, setConditionState] = useState({
    condition: false,
  });
  const [discountnState, setDiscountState] = useState(false);
  const handleOnButtonClick = () => {
    setDiscountState(true);
  };
  const handleOffButtonClick = () => {
    setDiscountState(false);
  };
  const [newPrice, setNewPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const handleNewPriceChange = (e) => {
    const enteredPrice = parseInt(e.target.value);
    setNewPrice(enteredPrice);
    if (enteredPrice >= price) {
      setDiscount(0);
    } else {
      setDiscount((1 - enteredPrice / price) * 100);
    }
  };
  const handleDiscountChange = (e) => {
    const enteredDiscount = parseInt(e.target.value);
    setDiscount(enteredDiscount);
    setNewPrice(price * (1 - enteredDiscount / 100));
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [timer, setTimer] = useState(false);
  const [bid, setBid] = useState(false);
  const [quantity, setQuantity] = useState(null);
  const [bids, setBids] = useState({ enabled: false, biddings: [] });
  const [endTDate, setEndTDate] = useState("");
  const [deadlineTimer, setdeadlineTimer] = useState({
    enabled: false,
    deadline: null,
  });
  const [categoryId, setCategoryId] = useState(null);
  function handleLocationClick() {
    setShowDropdown(!showDropdown);
  }
  function handleOptionClick(id) {
    const selectedAddress = address.find((add) => add._id === id);
    setSelectedAddress(selectedAddress);
    setShowDropdown(false);
  }
  const [isDown, setIsDown] = useState(false);
  const handleArrow = () => {
    setIsDown(!isDown);
  };
  const currentDateInMillis = new Date().getTime();
  const formattedDate = new Date(currentDateInMillis)
    .toISOString()
    .slice(0, 16);
  const [biddings, setBiddings] = useState([]);
  useEffect(() => {
    dispatch(GetSingleProduct(ProductId))
      .then((data) => {
        // console.log(data);
        setProduct(data);
        setProductTitle(data.data?.product?.title);
        setProductDescription(data.data?.product?.description);
        setPrice(data.data?.product?.pricing?.price);
        setQuantity(data.data?.product?.quantity);
        setCategoryId(data.data?.product?.categoryId);
        setIsNegotiable(data.data?.product?.pricing?.negotiable);
        setIsExchangeable(data.data?.product?.pricing?.exchangeable);
        setIsRefundable(data.data?.product?.pricing?.refundable);
        setBiddings(data?.data?.product?.bidding);
        if (data?.data?.product?.discount?.enabled) {
          setDiscountState(data?.data?.product?.discount?.enabled);
          setDiscount(data?.data?.product?.discount?.percentage);
          setNewPrice(data?.data?.product?.discount?.newPrice);
        }
        setConditionState((prevState) => ({
          ...prevState,
          condition: data.data?.product?.condition,
        }));
        setSelectedAddress(data.data?.product?.pickUpAddress);
        setDynamicFieldsValues(data.data?.product?.fields);
        setBid(data.data?.product?.bidding?.enabled);
        if (data.data?.product?.timer?.deadline) {
          setEndTDate(
            new Date(data.data?.product?.timer?.deadline)
              .toISOString()
              .substring(0, 16)
          );
        }
        setTimer(data.data?.product?.timer?.enabled);
        const imageUrls = data.data?.product?.imageUrls;
        const nullCount = 16 - (imageUrls?.length || 0);
        const nullArray = new Array(nullCount).fill(null);
        const updatedPhotoStates = imageUrls
          ? [...imageUrls, ...nullArray]
          : new Array(16).fill(null);
        setPhotoStates(updatedPhotoStates);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [ProductId, dispatch]);
  const CategoryArray = Object.values(product && product);
  const fieldsArray = CategoryArray.map((item) => item.product.fields);
  const updatedFields = fieldsArray.flat().map((field) => {
    const matchingObj = dynamicFieldsValues.find(
      (item) => item.id === field.id
    );
    return { ...field, value: matchingObj ? matchingObj.value : null };
  });
  const [subCategories, setSubCategories] = useState([]);
  const userById = useSelector((state) => state.userById);
  const { user, error } = userById;
  const [showModal, setShowModal] = useState(false);
  const [price, setPrice] = useState(null);
  const [dynamicConditionState, setdynamicConditionState] = useState(null);
  const [dynamicListState, setdynamicListState] = useState(null);
  const [activeBool, setActiveBool] = useState(0);
  const handleBoolClick = (index) => {
    setActiveBool(index);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [selectedItem, setSelectedItem] = useState(null);
  function handleSelect(index, value) {
    setIsOpen(false);
    setSelectedItem(value);
  }
  const [fileUploaded, setFileUploaded] = useState("");
  const hiddenFileInput = React.useRef(null);
  const handleClick = (index) => {
    hiddenFileInputs[index].current.click();
  };
  const handleChange = (event, index) => {
    const file = event.target.files[0];
    const newPhotoStates = [...photoStates];
    newPhotoStates[index] = file;
    setPhotoStates(newPhotoStates);
    formik.setFieldValue(`file${index}`, file);
  };
  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    setProduct((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const loading = useSelector((state) => state.edtProducts?.loading);
  const newphotoStates = photoStates.filter(
    (item) => item !== null && item !== ""
  );
  const fileNames = photoStates
    .map((file) => file && file.name)
    .filter(Boolean);
  const hiddenFileInputs = photoStates.map(() => React.createRef());
  const [vehicleBrand, setVehicleBrand] = useState("");
  const [success, setSuccess] = useState(false);
  const [startTDate, setStartTDate] = useState("");

  useEffect(() => {
    if (timer) {
      const dateObject = new Date(endTDate);
      const milliseconds = dateObject.getTime();
      setdeadlineTimer({ enabled: true, deadline: milliseconds });
    } else {
      setdeadlineTimer({ enabled: false, deadline: null });
    }
  }, [startTDate, endTDate, timer]);
  useEffect(() => {
    if (bid) {
      setBids(biddings);
    } else {
      setBids({ enabled: false, biddings: [] });
    }
  }, [bid]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    type: "product",
    PropertyType: "",
    PaymentMethod: "",
    Bedrooms: "",
    Bathrooms: "",
    Size: "",
    Furnisher: "",
    ConditionProperty: "",
    RentalDuration: "",
    CommercialType: "",
    Equipped: "",
    Landtype: "",
    ChaletType: "",
    BuildingType: "",
    LivingRoomType: "",
    BedroomType: "",
    DiningRoomType: "",
    KitchenAndKitchenwareType: "",
    BathroomType: "",
    HomeDecorationAndAccessoriesType: "",
    GardenAndOutdooType: "",
    file: null,
    file1: null,
    dynamicFields: {},
  });
  const getConditionValue = () => {
    return conditionState.condition === "false" ? 0 : 1;
  };
  const [openaddress, setOpenaddress] = useState(false);

  const pricing = {
    price: parseFloat(price),
    negotiable: isNegotiable,
    exchangeable: isExchangeable,
    refundable: isRefundable,
  };
  const jsonPricing = JSON.stringify(pricing, [
    "price",
    "negotiable",
    "exchangeable",
    "refundable",
  ]);
  const formik = useFormik({
    initialValues: formData,
    onSubmit: (values) => {
      // console.log({ ...values, ...conditionState, seller: user.id });
      // dispatch(addProductHandler(values));
      // setSuccess(true);
    },
    validationSchema: PropertiesValidationSchema,
  });
  const conditionValue = getConditionValue();
  const conditionString = conditionValue.toString();
  const handleEditClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const discountObject = {
    enabled: true,
    percentage: discount,
  };
  const isBackendURL = (url) =>
    typeof url === "string" &&
    url.startsWith("https://storage.googleapis.com/");
  // Separate the URLs into two arrays
  const backendURLs = newphotoStates.filter(isBackendURL);
  const backendURLsString = backendURLs.join(",");
  const nonBackendURLs = newphotoStates.filter((url) => !isBackendURL(url));
  const handleQuantityChange = (e) => {
    const newValue = e.target.value;
    // Validate if the input is an integer
    if (Number.isInteger(Number(newValue))) {
      setQuantity(newValue);
    }
  };
  const backendURLsArray = Object.values(backendURLs);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", productTitle);
      formData.append("categoryId", categoryId);
      formData.append("description", productDescription);
      formData.append("condition", conditionString);
      formData.append("pricing", jsonPricing);
      formData.append("fields", JSON.stringify(updatedFields));
      formData.append("timer", JSON.stringify(deadlineTimer));
      formData.append("imageUrls", JSON.stringify(backendURLsArray));
      formData.append("quantity", quantity);
      for (const file of nonBackendURLs) {
        formData.append("images", file);
      }
      formData.append("bidding", JSON.stringify(bids));
      formData.append("pickUpAddressId", selectedAddress._id);
      if (discountnState) {
        formData.append("discount", JSON.stringify(discountObject));
      }

      const response = await dispatch(EditProducts(ProductId, formData));
      if (response) {
        setSuccess(true);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const ProductArray = [];
  const myObject = {
    ProductId: ProductId,
    title: formik.values.title,
    conditionString: conditionString,
    description: formik.values.description,
    Pricing: pricing,
    fields: updatedFields,
    timer: deadlineTimer,
    images: newphotoStates,
  };
  ProductArray.push(myObject);
  const ProductPreview = useSelector((state) => state.PreviewReducer);
  const newProductArray = [
    ...(ProductPreview?.ProductArray || []),
    { ...ProductPreview, ...ProductArray },
  ];
  const response = dispatch(addProductArray(newProductArray));
  const booleanFields =
    CategoryArray &&
    CategoryArray.length > 0 &&
    CategoryArray[0].product?.fields?.filter((field) => field.type === "list");
  const numberOfBooleans = booleanFields.length;
  const [isOpenList, setIsOpenList] = useState(
    Array(numberOfBooleans).fill(false)
  );
  const [selectedItems, setSelectedItems] = useState(
    Array(numberOfBooleans).fill(null)
  );
  const handleDelete = (index) => {
    const updatedPhotoStates = [...photoStates];
    updatedPhotoStates[index] = null;
    setPhotoStates(updatedPhotoStates);
  };
  return (
    <>
      <MapModal
        open={openaddress}
        onClose={() => {
          setOpenaddress(false);
        }}
      />
      <Layout title={"Edit Product"}>
        <form className={styles.wrapper} onSubmit={formik.handleSubmit}>
          {showModal && <EditModel handleCloseModal={handleCloseModal} />}
          <div className={styles.title_container}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Product Title
            </p>
            <div className={styles.title_input_wrapper}>
              <input
                type="text"
                className={`${styles.input} ${
                  darkMode ? styles.inputDark : ""
                } ${
                  formik.errors.title && formik.touched.title
                    ? styles.error_input
                    : ""
                }`}
                placeholder="Name of product and related info"
                name="title"
                onChange={(e) => setProductTitle(e.target.value)}
                onBlur={formik.handleBlur}
                value={productTitle}
              />
              {formik.errors.productTitle && formik.touched.productTitle && (
                <p className={styles.error_message}>
                  {formik.errors.productTitle}
                </p>
              )}
            </div>
          </div>
          <div className={styles.desc_container}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Description
            </p>
            <div className={styles.description_wrapper}>
              <textarea
                className={`${styles.text_area} ${
                  darkMode ? styles.text_areaDark : ""
                } ${
                  formik.errors.description && formik.touched.description
                    ? styles.error_input
                    : ""
                }`}
                placeholder="Tell us more about your product"
                name="description"
                onChange={(e) => setProductDescription(e.target.value)}
                onBlur={formik.handleBlur}
                value={productDescription}
              />
              {formik.errors.productDescription &&
                formik.touched.productDescription && (
                  <p className={styles.error_message}>
                    {formik.errors.productDescription}
                  </p>
                )}
            </div>
          </div>
          <div className={styles.condDisc}>
            <div className={styles.condition_container}>
              <p
                className={`${styles.label} ${
                  darkMode ? styles.labelDark : ""
                }`}
              >
                Condition
              </p>
              <div className={styles.switch_container}>
                <div
                  className={`${styles.switch_btn} ${
                    darkMode ? styles.switch_btnDark : ""
                  } ${darkMode ? styles.activeDark : ""} ${
                    conditionState.condition ? styles.active : ""
                  }`}
                  onClick={() => setConditionState({ condition: true })}
                >
                  New
                </div>
                <div
                  className={`${styles.switch_btn} ${
                    darkMode ? styles.switch_btnDark : ""
                  } ${darkMode ? styles.activeDark : ""} ${
                    !conditionState.condition ? styles.active : ""
                  }`}
                  onClick={() => setConditionState({ condition: false })}
                >
                  Used
                </div>
              </div>
            </div>
            {!bid && (
              <div className={styles.discount_container}>
                <p
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                >
                  Discount
                </p>
                <div className={styles.switch_container}>
                  <div
                    className={`${styles.switch_btn} ${
                      darkMode ? styles.switch_btnDark : ""
                    } ${discountnState ? styles.active : ""}`}
                    onClick={handleOnButtonClick}
                  >
                    ON
                  </div>
                  <div
                    className={`${styles.switch_btn} ${
                      darkMode ? styles.switch_btnDark : ""
                    } ${!discountnState ? styles.active : ""}`}
                    onClick={handleOffButtonClick}
                  >
                    OFF
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.price_container}>
            {discountnState ? (
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <label
                    className={`${styles.label} ${
                      darkMode ? styles.labelDark : ""
                    }`}
                  >
                    Price
                  </label>
                  <div className={styles.input_container}>
                    <input
                      type="number"
                      className={`${styles.input} ${
                        darkMode ? styles.inputDark : ""
                      }`}
                      placeholder="000,000"
                      name="price"
                      // onChange={(event) => handlePriceChange(event)}
                      onChange={(e) => setPrice(e.target.value)}
                      onBlur={formik.handleBlur}
                      value={price}
                      disabled={true}
                      onWheel={(e) => e.target.blur()}
                    />
                    <div
                      className={`${styles.currency} ${
                        darkMode ? styles.currencyDark : ""
                      }`}
                    >
                      $
                    </div>
                  </div>
                  <label
                    className={`${styles.label} ${
                      darkMode ? styles.labelDark : ""
                    }`}
                  >
                    New Price
                  </label>
                  <div className={styles.input_container}>
                    <input
                      type="number"
                      className={`${styles.input} ${
                        darkMode ? styles.inputDark : ""
                      }`}
                      placeholder="000,000"
                      name="price"
                      value={newPrice}
                      onChange={handleNewPriceChange}
                      onWheel={(e) => e.target.blur()}
                      // onBlur={formik.handleBlur}
                    />
                    <div
                      className={`${styles.currency} ${
                        darkMode ? styles.currencyDark : ""
                      }`}
                    >
                      $
                    </div>
                  </div>
                  <label
                    className={`${styles.label} ${
                      darkMode ? styles.labelDark : ""
                    }`}
                  >
                    Discount
                  </label>
                  <div className={styles.input_container}>
                    <input
                      type="number"
                      className={`${styles.input} ${
                        darkMode ? styles.inputDark : ""
                      }`}
                      placeholder="000,000"
                      onWheel={(e) => e.target.blur()}
                      name="price"
                      value={discount}
                      onChange={handleDiscountChange}
                      // onBlur={formik.handleBlur}
                    />
                    <div
                      className={`${styles.currency} ${
                        darkMode ? styles.currencyDark : ""
                      }`}
                    >
                      %
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <label
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                >
                  Price
                </label>
                <div className={styles.input_container}>
                  <input
                    type="number"
                    className={`${styles.input} ${
                      darkMode ? styles.inputDark : ""
                    }`}
                    placeholder="000,000"
                    onWheel={(e) => e.target.blur()}
                    name="price"
                    // onChange={(event) => handlePriceChange(event)}
                    onChange={(e) => setPrice(e.target.value)}
                    value={price}
                  />
                  <div
                    className={`${styles.currency} ${
                      darkMode ? styles.currencyDark : ""
                    }`}
                  >
                    $
                  </div>
                </div>
              </>
            )}
            <div className={styles.checkboxes}>
              <div className={styles.checkbox_container}>
                <input
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  type="checkbox"
                  name="negotiatable"
                  id="box1"
                  checked={isNegotiable}
                  onChange={(e) => setIsNegotiable(e.target.checked)}
                />
                <label
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  htmlFor="box1"
                >
                  Negotiatable
                </label>
              </div>
              <div className={styles.checkbox_container}>
                <input
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  type="checkbox"
                  name="Exchangable"
                  id="box2"
                  checked={isExchangeable}
                  onChange={(e) => setIsExchangeable(e.target.checked)}
                />
                <label
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  htmlFor="box2"
                >
                  Exchangable
                </label>
              </div>
              <div className={styles.checkbox_container}>
                <input
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  type="checkbox"
                  name="Refundable"
                  id="box3"
                  checked={isRefundable}
                  onChange={(e) => setIsRefundable(e.target.checked)}
                />
                <label
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  htmlFor="box3"
                >
                  Refundable
                </label>
              </div>
            </div>
          </div>
          <div className={styles.quantity_container}>
            <label
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Quantity
            </label>
            <div className={styles.input_container}>
              <input
                type="number"
                className={`${styles.input} ${darkMode ? styles.inputDark : ""}
               ${
                 formik.errors.quantity && formik.touched.quantity
                   ? styles.error_input
                   : ""
               }`}
                placeholder="Place your Quantity"
                name="quantity"
                onChange={handleQuantityChange}
                onBlur={formik.handleBlur}
                value={quantity || ""}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) => {
                  if (e.key === "." || e.key === ",") {
                    e.preventDefault();
                  }
                }}
              />
              {formik.errors.quantity && formik.touched.quantity && (
                <p className={styles.error_message}>{formik.errors.quantity}</p>
              )}
              <div
                className={`${styles.currency} ${
                  darkMode ? styles.currencyDark : ""
                }`}
              >
                Qt
              </div>
            </div>
          </div>
          <div className={styles.line}></div>
          {CategoryArray.map((item) =>
            item.product.fields.map((subCategory, index) => {
              const renderField = (subCategory) => {
                switch (subCategory.type) {
                  case "string":
                    return (
                      <div className={`${styles.fieldContainer}`}>
                        <div>
                          <label
                            className={`${styles.label} ${
                              darkMode ? styles.labelDark : ""
                            }`}
                          >
                            {subCategory.title}
                          </label>
                          <div className={styles.input_container}>
                            <input
                              type="text"
                              className={`${styles.inputString}  ${
                                darkMode ? styles.inputStringDark : ""
                              }
                                                ${
                                                  formik.errors[
                                                    subCategory.title
                                                  ] &&
                                                  formik.touched[
                                                    subCategory.title
                                                  ]
                                                    ? styles.error_input
                                                    : ""
                                                }`}
                              placeholder={subCategory.title}
                              name={`dynamicFields.${subCategory.title}.${subCategory.type?.id}`}
                              onChange={(e) => {
                                const value = e.target.value;
                                const fieldName = subCategory.title;
                                const id = subCategory.id;
                                formik.setFieldValue(
                                  `dynamicFields.${fieldName}`,
                                  value
                                );
                                const existingObjectIndex =
                                  dynamicFieldsValues.findIndex(
                                    (obj) => obj.id === id
                                  );
                                if (existingObjectIndex !== -1) {
                                  // If an object exists, update its value property
                                  const updatedArray = [...dynamicFieldsValues];
                                  updatedArray[existingObjectIndex].value =
                                    value;
                                  setDynamicFieldsValues(updatedArray);
                                } else {
                                  // If an object doesn't exist, add a new object to the array
                                  setDynamicFieldsValues([
                                    ...dynamicFieldsValues,
                                    { id, value },
                                  ]);
                                }
                              }}
                              onBlur={formik.handleBlur}
                              value={
                                formik.values.dynamicFields[
                                  subCategory.title
                                ] !== undefined
                                  ? formik.values.dynamicFields[
                                      subCategory.title
                                    ]
                                  : subCategory.value
                              }
                            />
                            {formik.errors[subCategory.title] &&
                              formik.touched[subCategory.title] && (
                                <p className={styles.error_message}>
                                  {formik.errors[subCategory.title]}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  case "list":
                    const toggleDropdown = (index) => {
                      const updatedIsOpenList = [...isOpenList];
                      updatedIsOpenList[index] = !updatedIsOpenList[index];
                      setIsOpenList(updatedIsOpenList);
                    };
                    // Function to handle selecting an option in a specific list
                    const handleSelect = (index, value) => {
                      const updatedSelectedItems = [...selectedItems];
                      updatedSelectedItems[index] = value;
                      setSelectedItems(updatedSelectedItems);
                      const updatedIsOpenList = [...isOpenList];
                      updatedIsOpenList[index] = false;
                      setIsOpenList(updatedIsOpenList);
                    };
                    return (
                      <div className={`${styles.fieldContainer}`}>
                        <div className="titleL">
                          <label
                            className={`${styles.label} ${
                              darkMode ? styles.labelDark : ""
                            }`}
                          >
                            {subCategory.title}
                          </label>
                          <div className={styles.input_container}>
                            <div>
                              <button
                                className="listb"
                                onClick={() => {
                                  toggleDropdown(index);
                                }}
                              >
                                {selectedItems[index]
                                  ? selectedItems[index]
                                  : `${
                                      subCategory.possibleValues[
                                        subCategory.value
                                      ]
                                    }`}
                              </button>
                              {isOpenList[index] && (
                                <div className="drop">
                                  <Scrollbars
                                    autoHeight
                                    autoHeightMin={0}
                                    autoHeightMax={200}
                                  >
                                    {subCategory.possibleValues.map(
                                      (value, valueIndex) => (
                                        <div
                                          key={valueIndex}
                                          style={{
                                            backgroundColor:
                                              valueIndex === hoveredIndex
                                                ? "lightgray"
                                                : "white",
                                            width: "140px",
                                            height: "30px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            setdynamicListState(valueIndex);
                                            const fieldName = subCategory.title;
                                            const id = subCategory.id;
                                            formik.setFieldValue(
                                              `dynamicFields.${fieldName}`,
                                              index
                                            );
                                            const existingObjectIndex =
                                              dynamicFieldsValues.findIndex(
                                                (obj) => obj.id === id
                                              );
                                            if (existingObjectIndex !== -1) {
                                              const updatedArray = [
                                                ...dynamicFieldsValues,
                                              ];
                                              updatedArray[
                                                existingObjectIndex
                                              ].value = valueIndex;
                                              setDynamicFieldsValues(
                                                updatedArray
                                              );
                                            } else {
                                              setDynamicFieldsValues([
                                                ...dynamicFieldsValues,
                                                { id, value: valueIndex },
                                              ]);
                                            }
                                            handleSelect(index, value);
                                          }}
                                          onMouseEnter={() => {
                                            setHoveredIndex(valueIndex);
                                          }}
                                          onMouseLeave={() => {
                                            setHoveredIndex(-1);
                                          }}
                                        >
                                          {value}
                                        </div>
                                      )
                                    )}
                                  </Scrollbars>
                                </div>
                              )}
                            </div>
                            {formik.errors[subCategory.title] &&
                              formik.touched[subCategory.title] && (
                                <p className={styles.error_message}>
                                  {formik.errors[subCategory.title]}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  case "number":
                    return (
                      <div className={`${styles.fieldContainer}`}>
                        <div>
                          <label
                            className={`${styles.label} ${
                              darkMode ? styles.labelDark : ""
                            }`}
                          >
                            {subCategory.title}
                          </label>
                          <div className={styles.input_container}>
                            <input
                              type="text"
                              className={`${styles.inputString} ${
                                darkMode ? styles.inputStringDark : ""
                              }
                              ${
                                formik.errors[subCategory.title] &&
                                formik.touched[subCategory.title]
                                  ? styles.error_input
                                  : ""
                              }`}
                              placeholder={subCategory.title}
                              name={`dynamicFields.${subCategory.title}.${subCategory.type?.id}`}
                              onChange={(e) => {
                                const value = e.target.value;
                                const fieldName = subCategory.title;
                                const id = subCategory.id;
                                formik.setFieldValue(
                                  `dynamicFields.${fieldName}`,
                                  value
                                );
                                const existingObjectIndex =
                                  dynamicFieldsValues.findIndex(
                                    (obj) => obj.id === id
                                  );
                                if (existingObjectIndex !== -1) {
                                  // If an object exists, update its value property
                                  const updatedArray = [...dynamicFieldsValues];
                                  updatedArray[existingObjectIndex].value =
                                    value;
                                  setDynamicFieldsValues(updatedArray);
                                } else {
                                  // If an object doesn't exist, add a new object to the array
                                  setDynamicFieldsValues([
                                    ...dynamicFieldsValues,
                                    { id, value },
                                  ]);
                                }
                              }}
                              onBlur={formik.handleBlur}
                              value={subCategory.value}
                            />
                            {formik.errors[subCategory.title] &&
                              formik.touched[subCategory.title] && (
                                <p className={styles.error_message}>
                                  {formik.errors[subCategory.title]}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  case "boolean":
                    return (
                      <div className={styles.condition_container}>
                        <p
                          className={`${styles.label} ${
                            darkMode ? styles.labelDark : ""
                          }`}
                        >
                          {subCategory.title}
                        </p>
                        <div className={styles.switch_container}>
                          {subCategory.possibleValues.map((value, index) => (
                            <div
                              key={index}
                              className={`${styles.switch_btn} ${
                                subCategory.value === index ? styles.active : ""
                              } ${darkMode ? styles.switch_btnDark : ""} ${
                                darkMode ? styles.activeDark : ""
                              }`}
                              onClick={() => {
                                setdynamicConditionState(index);
                                const fieldName = subCategory.title;
                                const id = subCategory.id;
                                formik.setFieldValue(
                                  `dynamicFields.${fieldName}`,
                                  index
                                );
                                const existingObjectIndex =
                                  dynamicFieldsValues.findIndex(
                                    (obj) => obj.id === id
                                  );
                                if (existingObjectIndex !== -1) {
                                  const updatedArray = [...dynamicFieldsValues];
                                  updatedArray[existingObjectIndex].value =
                                    index;
                                  setDynamicFieldsValues(updatedArray);
                                } else {
                                  setDynamicFieldsValues([
                                    ...dynamicFieldsValues,
                                    { id, value: index },
                                  ]);
                                }
                                handleBoolClick(index);
                              }}
                            >
                              {value}
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  default:
                    return null;
                }
              };
              return renderField(subCategory);
            })
          )}
          <div className={styles.line2}></div>
          <div className={styles.image_section}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Add Photos
            </p>
            <div className={styles.wrapper}>
              {photoStates.map((photoState, index) => (
                <div
                  className={`${styles.image_container} ${
                    darkMode ? styles.image_containerDark : ""
                  }`}
                  onClick={() => handleClick(index)}
                >
                  <img
                    src={
                      photoState
                        ? typeof photoState === "string" &&
                          photoState.startsWith(
                            "https://storage.googleapis.com/"
                          )
                          ? photoState
                          : URL.createObjectURL(photoState)
                        : Camera
                    }
                    alt="camera"
                    className={`${
                      photoState ? styles.full_image : styles.image
                    }`}
                    file={formik.values.file}
                  />
                  {photoState && (
                    <div
                      className={styles.remove_button}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                    >
                      X
                    </div>
                  )}
                  <input
                    type="file"
                    ref={hiddenFileInputs[index]}
                    onChange={(event) => handleChange(event, index)}
                    accept="image/png, image/jpg, image/jpeg"
                    style={{ display: "none" }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.line}></div>
          <p className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}>
            {selectedLocation || "Location"}
          </p>
          <div className={styles.location_section}>
            {address && address.length > 0 ? (
              address
                .filter((addr) => addr.isDefault)
                .map((addr) => (
                  <AddressComponent
                    defaultAddress={true}
                    title={addr.title}
                    description={addr.description}
                    threedots={false}
                  />
                ))
            ) : (
              <AddressComponent
                defaultAddress={false}
                title={"No Addresses"}
                description={"You Can Add a Location"}
                threedots={false}
              />
            )}
            <div className={styles.vertical_line}></div>
            <div className={styles.new_address_container}>
              <p
                className={`${styles.new_address_text} ${
                  darkMode ? styles.new_address_textDark : ""
                }`}
              >
                Select a new address as the location
              </p>
              <div
                className={`${styles.location} ${
                  darkMode ? styles.locationDark : ""
                }`}
                onClick={handleLocationClick}
              >
                {selectedAddress ? selectedAddress.title : "Location"}{" "}
                <AiOutlineRight className={styles.icon} />
              </div>
              {showDropdown &&
                (address.length > 0 ? (
                  <div className={styles.dropdown}>
                    <ul>
                      {address.map((add) => (
                        <li
                          key={add._id}
                          className={styles.places}
                          onClick={() => handleOptionClick(add._id)}
                        >
                          {add.title}
                        </li>
                      ))}
                    </ul>
                    <div
                      className={styles.addloc}
                      onClick={() => setOpenaddress(true)}
                    >
                      <AiFillPlusCircle
                        className={styles.add_button}
                        onClick={() => setOpenaddress(true)}
                      />
                      <span>Add Location</span>
                    </div>
                  </div>
                ) : (
                  <div className={styles.dropdown}>
                    <div
                      className={styles.addloc}
                      onClick={() => setOpenaddress(true)}
                    >
                      <AiFillPlusCircle
                        className={styles.add_button}
                        onClick={() => setOpenaddress(true)}
                      />

                      <span>Add Location</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.line}></div>
          <div className={styles.last_section}>
            <div>
              <div className={styles.flex}>
                <p
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                >
                  Set a Timer
                </p>
                <Switch
                  checked={timer}
                  onChange={() => setTimer(!timer)}
                  onColor={"#0298a6"}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
              {timer && (
                <div className={styles.flexColumn}>
                  <div className={styles.flex}></div>
                  <div className={styles.flex}>
                    <label
                      className={`${styles.labelt} ${
                        darkMode ? styles.labeltDark : ""
                      }`}
                    >
                      End Date:
                    </label>
                    <input
                      className="datein"
                      type="datetime-local"
                      value={
                        endTDate === "1970-01-01T00:00"
                          ? formattedDate
                          : endTDate
                      }
                      onChange={(e) => {
                        const newEndDate = e.target.value;
                        if (newEndDate > startTDate) {
                          setEndTDate(newEndDate);
                        } else {
                          toast.error("End date should be after start date.");
                        }
                      }}
                    />
                  </div>
                </div>
              )}
              <p
                className={`${styles.text} ${darkMode ? styles.textDark : ""}`}
              >
                When active the product will be available only for a limited
                time, specified by you. (can be changed later)
              </p>
            </div>
            <div className={styles.vertical_line}></div>
          </div>
          <div
            className={styles.button_container}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {loading ? (
              <button className={styles.post_nowld}>Update</button>
            ) : (
              <button
                className={`${styles.post_now} ${
                  darkMode ? styles.post_nowDark : ""
                }`}
                type={"submit"}
                onClick={handleSubmit}
              >
                Update
              </button>
            )}
          </div>
        </form>
        <PostSuccess
          update={true}
          open={success}
          onClose={() => setSuccess(false)}
        />
      </Layout>
    </>
  );
};
export default EditProduct;
