import React, { useState } from "react";
import styles from "./SellModal.module.scss";
import ReactDom from "react-dom";
import { ReactComponent as Close } from "../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../assests/svg/canceldark.svg";
import Product from "../../../assests/svg/product.svg";
import cancelDark from "../../../assests/svg/product.svg";
import ServiceImg from "../../../assests/svg/service.svg";
import ServiceImgDark from "../../../assests/svg/serviceDark.svg";
import { useNavigate } from "react-router-dom";
import Service from "./Service/Service";
import { useSelector } from "react-redux";
const SellModal = ({ open, onClose, service, setService }) => {
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
        <div className={styles.container}>
          {darkMode?(
            <CloseDark className={styles.close} onClick={onClose}/>
            ):(
            <Close className={styles.close} onClick={onClose} />
          )
          }
          {!service ? (
            <>
              {" "}
              <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>
                Select what you want to offer for sale:
              </h1>
              <div className={styles.type_container}>
                <div
                  className={styles.card_container}
                  onClick={() => {
                    onClose();
                    navigate("/sell/products");
                  }}
                >
                  <div className={styles.card}>
                    <img src={Product} alt="product" className={styles.img} />
                  </div>
                  <h3 className={`${styles.type} ${darkMode ? styles.typeDark : ''}`}>Products</h3>
                </div>
                <div className={styles.middle}>
                  <div className={`${styles.line} ${darkMode ? styles.lineDark : ''}`}></div>
                  <p className={`${styles.or} ${darkMode ? styles.orDark : ''}`}>Or</p>
                  <div className={`${styles.line} ${darkMode ? styles.lineDark : ''}`}></div>
                </div>
                <div className={styles.card_container} onClick={setService}>
                  <div className={styles.card}>
                    <img
                      src={darkMode ? ServiceImgDark:ServiceImg}
                      alt="product"
                      className={styles.img}
                    />
                  </div>
                  <h3 className={`${styles.type} ${darkMode ? styles.typeDark : ''}`}>Services</h3>
                </div>
              </div>
            </>
          ) : (
            <Service />
          )}
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default SellModal;
