import {
  JOIN_BID_REQUEST,
  JOIN_BID_SUCCESS,
  JOIN_BID_FAIL,
  CHECK_USER_PAYMENT_BID_FAIL,
  CHECK_USER_PAYMENT_BID_REQUEST,
  CHECK_USER_PAYMENT_BID_SUCCESS,
} from "../constants/makePaymentConstants";
import { url } from "../../common/constants/url";
import axios from "axios";
export const join_bid =
  (amount, description, caid, userid, productid) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: JOIN_BID_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const {
        createCustomer: { customer_id },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo}`,
        },
      };
      let currency = "usd";
      let cid = customer_id;
      let body = {
        amount,
        description,
        currency,
        cid,
        caid,
        userid,
        productid,
      };
      const { data } = await axios.post(
        `${url}/stripe_card/makepaymentCustomerbycredit`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: JOIN_BID_SUCCESS,
        payload: data.receipt_url,
      });
    } catch (error) {
      dispatch({
        type: JOIN_BID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const userPaymentBidHandler =
  (userid, productid) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CHECK_USER_PAYMENT_BID_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo}`,
        },
      };
      let body = {
        productid,
        userid,
      };
      const { data } = await axios.post(
        `${url}/paymentbid/ifuserpaidbidfees`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: CHECK_USER_PAYMENT_BID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CHECK_USER_PAYMENT_BID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
