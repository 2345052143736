import React, { useState, useEffect } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./SellServiceCategory.module.scss";
import OfferCard from "./Card/OfferCard";
import Footer from "../../common/components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { GetAllTypes } from "../../store/actions/servicesActions";
const SellServiceOfferCategory = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const pageName = "Services Types"; // Replace this with the actual page name
    const pageUrl = `/sell/services/offer`; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const types = useSelector((state) => state.types?.types?.data?.types)
 
  useEffect(() => {
    dispatch(GetAllTypes());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>Offer Service</title>
        <meta name="Offer Service" content="Offer your services here" />
        <meta
          name="keywords"
          content={`Offer a service, sell a service on la7i27alak, services, la7i27alak`}
        />
      </Helmet>
      <Layout title={"Services Categories"} footer={true}>
        <h2 className={`${styles.subtitleDark} ${darkMode ? styles.subtitle : ''}`}>What are you offering?</h2>
        <div className={`${styles.categories_container} ${darkMode ? styles.categories_containerDark : ''}`}>
          <OfferCard alltypes={types} />
        </div>
      </Layout>
    </>
  );
};
export default SellServiceOfferCategory
