import React, { useState } from "react";
import styles from "./ProductsList.module.scss";
import Card from "../Card/Card";
import { url } from "../../../common/constants/url";
import { useSelector } from "react-redux";
import EmptyProducts from "../EmptyProducts/EmptyProducts";
const ProductsList = ({ products, MyServices }) => {
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [all, setAll] = useState(true);
  const [active, setActive] = useState(false);
  const [sold, setSold] = useState(false);
  const [offer, setOffer] = useState(true)
  const [request, setRequest] = useState(false)
  const [myProducts, setMyProducts] = useState(products);
  const [myService, setMyService] = useState(MyServices && MyServices.filter(function (el) {
    return el.typeOfService === "offer"
  }))
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const activateFirst = () => {
    setFirst(true);
    setSecond(false);
  };
  const activateSecond = () => {
    setFirst(false);
    setSecond(true);
  };
  const activateAll = () => {
    setAll(true);
    setActive(false);
    setSold(false);
    setMyProducts(products);
  };
  const activateActive = () => {
    setAll(false);
    setActive(true);
    setSold(false);
    setMyProducts(
      products.filter(function (el) {
        return el.status == "confirmed";
      })
    );
  };
  const activateSold = () => {
    setAll(false);
    setActive(false);
    setSold(true);
    setMyProducts(
      products.filter(function (el) {
        return el.status == "sold";
      })
    );
  };
  const activateOffer = () => {
    setOffer(true);
    setRequest(false);
    setMyService(MyServices && MyServices.filter(function (el) {
      return el.typeOfService === "offer"
    }))
  }
  const activateRequest = () => {
    setOffer(false);
    setRequest(true);
    setMyService(MyServices && MyServices.filter(function (el) {
      return el.typeOfService === "request"
    }))
  }
  return (
    <section className={styles.container}>
      <section className={`${styles.search__section} ${darkMode ? styles.search__sectionDark : ''}`}>
        <div className={`${styles.search__type} ${darkMode ? styles.search__typeDark : ''}`}>
          <button
            className={`${styles.button} ${first ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
            onClick={activateFirst}
          >
            Product
          </button>
          <button
            className={`${styles.button} ${second ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
            onClick={activateSecond}
          >
            Service
          </button>
        </div>
        {first ? (
          <>
            <ul className={styles.category__container}>
              <li
                className={`${styles.category__item} ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ""} ${all ? styles.active : ""}`}
                onClick={activateAll}
              >
                All
              </li>
              <li
                className={`${styles.category__item} ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ""} ${active ? styles.active : ""}`}
                onClick={activateActive}
              >
                Active
              </li>
              <li
                className={`${styles.category__item} ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ""} ${sold ? styles.active : ""}`}
                onClick={activateSold}
              >
                Sold
              </li>
            </ul>
            <div className={styles.card_container}>
              {myProducts && myProducts.length > 0 ? (
                myProducts.map((p) => {
                  return (
                    <Card
                      id={p._id}
                      key={p.id}
                      title={p.title}
                      price={p.pricing.price}
                      image={p.imageUrls[0]}
                      likes={p.likes}
                      views={p.viewCount}
                      waiting={p.waitingListNb}
                      active={p.status === "confirmed" ? true : false}
                      isService={second}
                      isDiscount={p.discount?.enabled}
                      percentage={p.discount?.percentage}
                      featured={p.featured}
                      timeposted={p.createdAt}
                      status={p.status}
                      quantity={p.quantity}
                      bid={p.bidding?.enabled}
                    />
                  );
                })
              ) : (
                <EmptyProducts />
              )}
            </div>
          </>
        ) : (
          <>
            <ul className={styles.category__container}>
              <li
                className={`${styles.category__item} ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ""} ${offer ? styles.active : ""}`}
                onClick={activateOffer}
              >
                Offer
              </li>
              <li
                className={`${styles.category__item} ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ""} ${request ? styles.active : ""}`}
                onClick={activateRequest}
              >
                Request
              </li>
            </ul>
            <div className={styles.card_container}>
              {myService && myService.length > 0 ? (
                myService.map((p) => {
                 
                    return (
                      <Card
                        id={p._id}
                        key={p.id}
                        title={p.title}
                        price={p.pricing.price}
                        image={p.imageUrls[0]}
                        likes={p.likes}
                        views={p.viewCount}
                        waiting={p.waitingListNb}
                        sold={p.published}
                        active={p.status === "confirmed" ? true : false}
                        isService={second}
                        isDiscount={p.discount?.enabled}
                        percentage={p.discount?.percentage}
                        newPrice={p.discount?.newPrice}
                        timeposted={p.createdAt}
                        status={p.status}
                        featured={p.featured}
                      />
                    );
                })
              ) : (
                <EmptyProducts />
              )}
            </div>
          </>
        )}
      </section>
    </section>
  );
};
export default ProductsList;
