import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  EMAIL_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_FAIL,
  ACTIVATE_USER_REQUEST,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAIL,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  GOOGLE_LOGIN_FAIL,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_SUCCESS,
  FACEBOOK_LOGIN_REQUEST,
  FACEBOOK_LOGIN_SUCCESS,
  FACEBOOK_LOGIN_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  FIREBASE_REGISTER_REQUEST,
  FIREBASE_REGISTER_SUCCESS,
  FIREBASE_REGISTER_FAIL,
  ADD_TOKEN_REQUEST,
  ADD_TOKEN_SUCCESS,
  ADD_TOKEN_FAIL,
  CODE_VERIFICATION_SEND_SUCCESS,
  CODE_VERIFICATION_SEND_FAILED,
  ADM_LOGIN_REQUEST,
  ADM_LOGIN_SUCCESS,
  ADM_LOGIN_FAIL,
  adm_LOGOUT,
  SET_ADM_INFO,
  USER_LOGIN_RESET,
  RESEND_CODE_FAIL,
  RESEND_CODE_SUCCESS
} from "../constants/authConstants";
import { url } from "../../common/constants/url";
import axios from "axios";
import { USERCARD_RESET, USER_RESET } from "../constants/userConstants";
import {
  ADD_CARD_TO_CUSTOMER_RESET,
  ADD_CARD_TO_DB_RESET,
  CARDS_LIST_RESET,
  CREATE_CARD_RESET,
  CREATE_CUSTOMER_RESET,
} from "../constants/paymentConstants";
import { WHISHLIST_IDS_RESET } from "../constants/wishlistConstants";
import { auth, db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: { token: userCredential.user.uid },
    });
    const userInfo = {
      uid: userCredential.user.uid,
      email: userCredential.user.email
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.message,
    });
  }
};
export const loginadm = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: ADM_LOGIN_REQUEST,
    });
    const admCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    dispatch({
      type: ADM_LOGIN_SUCCESS,
      payload: { token: admCredential.user.uid },
    });
    const admInfo = {
      uid: admCredential.user.uid,
      email: admCredential.user.email
    };
    localStorage.setItem("admInfo", JSON.stringify(admInfo));
    dispatch({
      type: SET_ADM_INFO,
      payload: admInfo,
    })
    // dispatch(userLoginReset());
  } catch (error) {
    dispatch({
      type: ADM_LOGIN_FAIL,
      payload: error.message,
    });
  }
};
export const userLoginReset = () => {
  return {
    type: USER_LOGIN_RESET,
  };
};
export const AddTokenAction = (fcmtoken, jj) => async (dispatch, getState) => {
  try {
    let idd = jj ? JSON.parse(atob(jj?.split('.')[1]))?.id : '';
    dispatch({
      type: ADD_TOKEN_REQUEST,
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let body = {
      Users_id: idd,
      token: fcmtoken,
    };
    const { data } = await axios.post(
      url + `/token/addtoken`,
      body,
      config
    );

    // console.log(3);
    dispatch({
      type: ADD_TOKEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_TOKEN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const logout = () => async (dispatch) => {
  try {
    const keysToKeep = ["admInfo", "tokenad", "uidad"];
for (let key in localStorage) {
  if (!keysToKeep.includes(key)) {
    localStorage.removeItem(key);
  }
}
    
    
    // localStorage.clear();
    dispatch({
      type: USER_LOGOUT,
    });
  } catch (error) {
    // console.log(error);
  }
};
export const logoutadm = () => async (dispatch) => {
  try {
    const keysToKeep = ["userInfo", "token", "uid"];
    for (let key in localStorage) {
      if (!keysToKeep.includes(key)) {
        localStorage.removeItem(key);
      }
    }
    dispatch({
      type: adm_LOGOUT,
    });
  } catch (error) {
    // console.log(error);
  }
};
// export const loginGoogle = (user) => (dispatch) => {
//   dispatch({
//     type: USER_LOGIN_SUCCESS,
//     payload: user,
//   });
// };
export const register =
  (username, email, dateofbirth, password) => async (dispatch) => {
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: {
        username: username,
        email: email,
        dateofbirth: dateofbirth,
        password: password,
      }
    });
  };
export const registerCompany = (formData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.post(`${url}/user`, formData, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      dispatch(createUserSuccess(response.data));
    } catch (error) {
      dispatch(createUserFailure(error));
    }
  };
};
const createUserSuccess = (userData) => ({
  type: USER_REGISTER_SUCCESS,
  payload: userData
});
const createUserFailure = (error) => ({
  type: USER_REGISTER_FAIL,
  payload: error
});
export const resendCode = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.get(`${url}/user/verify/resendVerification`, {
        headers: {
          'Authorization': `${token}`
        },
      });
      dispatch(resendCodeSuccess());
      return response.data
    } catch (error) {
      dispatch(resenCodeFail(error));
    }
  };
};
const resendCodeSuccess = () => ({
  type: RESEND_CODE_SUCCESS,
});
const resenCodeFail = (error) => ({
  type: RESEND_CODE_FAIL,
  payload: error
});
export const register_reset = (dispatch) => {
  dispatch({ type: USER_REGISTER_RESET });
};
export const verifyEmail = (code) => async (dispatch) => {
  
  dispatch({
    type: USER_LOGIN_SUCCESS,
    payload:code
  });
};
const verifyEmailSuccess = (responseData) => ({
  type: EMAIL_VERIFICATION_SUCCESS,
  payload: responseData
});
const verifyEmailFailure = (error) => ({
  type: EMAIL_VERIFICATION_FAIL,
  payload: error
});
export const sendVerificationCode = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('token')
    
    try {
      const response = await axios.get(`${url}/user/verify/email/send`, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        }
      });
      dispatch(sendCodeSuccess(response.data));
    } catch (error) {
      dispatch(sendCodeFailure(error));
    }
  };
};
const sendCodeSuccess = (responseData) => ({
  type: CODE_VERIFICATION_SEND_SUCCESS,
  payload: responseData
});
const sendCodeFailure = (error) => ({
  type: CODE_VERIFICATION_SEND_FAILED,
  payload: error
});
export const userActivate = (email) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVATE_USER_REQUEST,
    });
    await axios.patch(`${url}/User/${email}/activateduser`);
    dispatch({
      type: ACTIVATE_USER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ACTIVATE_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const userForgetPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: FORGET_PASSWORD_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${url}/User/forgotpassword`,
      { email },
      config
    );
    dispatch({
      type: FORGET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FORGET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
const initialState = {
  user: null
};
export const loginGoogle = (user) => (dispatch) => {
  dispatch({
    type: USER_LOGIN_SUCCESS,
    payload: user,
  });
};
export const loginWithFacebook = () => async (dispatch) => {
  try {
    dispatch({
      type: FACEBOOK_LOGIN_REQUEST,
    });
    const { data } = await axios.get(`${url}/User/auth/facebook`);
    dispatch({
      type: FACEBOOK_LOGIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FACEBOOK_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateuserpass = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PASSWORD_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.patch(
      url + "/User/updateuserpass",
      { email, password },
      config
    );
    dispatch({
      type: UPDATE_PASSWORD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const firebaseRegisterHandler =
  (email, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FIREBASE_REGISTER_REQUEST,
      });
      const {
        userRegister: { userData },
      } = getState();
      const timestamp = new Date();
      let res = await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, "Users", res.user.uid), {
        username: userData.username,
        email: email,
        uid: userData.id,
        createdAt: timestamp,
      });
      dispatch({
        type: FIREBASE_REGISTER_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: FIREBASE_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
