import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import styles from "./RateExperience.module.scss";
import { FaStar } from "react-icons/fa";
import { ReactComponent as Close } from "../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../assests/svg/canceldark.svg";
import Switch from "../../../common/components/Switch/Switch";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imagee from "../../../assests/images/user.png";
import { RateExperienceUser } from "../../../store/actions/userActions";
import Price from "../../AdminProducts/components/Price/Price";
import { GetProductOffer } from "../../../store/actions/bidActions";
import { DoneDeal } from "../../../store/actions/sendOfferActions";
const RateExperience = ({
  open,
  setOpen,
  name,
  image,
  productId,
  time,
  star,
  price,
  offerId,
  userId,
}) => {
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [prodUser, setProdUser] = useState([]);
  const [product, setProduct] = useState([]);
  const [finalPrice, setFinalPrice] = useState(price);
  const loading = useSelector((state) => state.reviewsByUID?.loading);
  const loadingg = useSelector((state) => state.rate?.loading);
  const handleClick = (value) => {
    setRating(value);
  };
  const handleFinalPriceChange = (e) => {
    const inputValue = e.target.value;

    // Regular expression to match values with no more than 2 digits after the decimal point
    const regex = /^\d+(\.\d{0,2})?$/;

    if (regex.test(inputValue)) {
      setFinalPrice(inputValue);
    }
  };

  const data = {
    productId: productId,
    stars: rating,
    finalPrice: finalPrice,
    comment: message,
    to: userId,
  };
  const handleRate = async () => {
    if (message.trim() === "") {
      toast.error("Please fill all fields.");
      return;
    }
    const response = dispatch(RateExperienceUser(JSON.stringify(data)));
    if (response) {
      try {
        const response = await dispatch(DoneDeal(offerId));
        if (response) {
          dispatch(GetProductOffer(productId));
          setOpen();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setMessage(value);
  };
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}>
        <div
          className={`${styles.modal_styles} ${
            darkMode ? styles.modal_stylesDark : ""
          }`}
        >
          <div className={styles.wrapper}>
            {darkMode ? (
              <CloseDark className={styles.close} onClick={setOpen} />
            ) : (
              <Close className={styles.close} onClick={setOpen} />
            )}
            {/* left side */}
            {/* right side */}
            <div className={styles.main_container}>
              <div className={styles.title}>
                <h3
                  className={`${styles.texttt} ${
                    darkMode ? styles.textttDark : ""
                  }`}
                >
                  Rate Your Experience With:
                </h3>
              </div>
              <img src={image} alt="profile" className={styles.image} />
              <div className={styles.name_container}>
                <h3
                  className={`${styles.name} ${
                    darkMode ? styles.nameDark : ""
                  }`}
                >
                  {name}
                </h3>
              </div>
              <div className={styles.rating_container}>
                <p
                  className={`${styles.overall} ${
                    darkMode ? styles.overallDark : ""
                  }`}
                >
                  Your overall rating of your interaction
                </p>
                <div className={styles.stars}>
                  <FaStar
                    className={`${styles.star} ${
                      rating >= 1 ? styles.gold : ""
                    }`}
                    onClick={() => handleClick(1)}
                  />
                  <FaStar
                    className={`${styles.star} ${
                      rating >= 2 ? styles.gold : ""
                    }`}
                    onClick={() => handleClick(2)}
                  />
                  <FaStar
                    className={`${styles.star} ${
                      rating >= 3 ? styles.gold : ""
                    }`}
                    onClick={() => handleClick(3)}
                  />
                  <FaStar
                    className={`${styles.star} ${
                      rating >= 4 ? styles.gold : ""
                    }`}
                    onClick={() => handleClick(4)}
                  />
                  <FaStar
                    className={`${styles.star} ${
                      rating >= 5 ? styles.gold : ""
                    }`}
                    onClick={() => handleClick(5)}
                  />
                </div>
              </div>
              <p
                className={`${styles.question} ${
                  darkMode ? styles.questionDark : ""
                }`}
              >
                What did you like or dislike?
              </p>
              <textarea
                className={`${styles.textarea} ${
                  darkMode ? styles.textareaDark : ""
                }`}
                placeholder="Type your message here"
                value={message} // The value of the textarea is controlled by the "comment" state
                onChange={handleChange} // Set the onChange event to update "comment"
              />
              <div className={styles.price_container}>
                <label
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                >
                  Final price reached was:
                </label>
                <div className={styles.input_container}>
                  <input
                    type="number"
                    className={`${styles.input} ${
                      darkMode ? styles.inputDark : ""
                    }`}
                    name="price"
                    value={finalPrice}
                    onChange={handleFinalPriceChange}
                  />
                  <div
                    className={`${styles.currency} ${
                      darkMode ? styles.currencyDark : ""
                    }`}
                  >
                    $
                  </div>
                </div>
              </div>
              {loadingg ? (
                <button disabled={true} className={styles.submit_loading}>
                  Submit...
                </button>
              ) : (
                <button
                  className={`${styles.submit} ${
                    darkMode ? styles.submitDark : ""
                  } ${rating === 0 ? styles.submitcheck : ""}`}
                  disabled={rating === 0}
                  onClick={handleRate}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default RateExperience;
