export const ADD_SERVICE_REQUEST = "ADD_SERVICE_REQUEST";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAIL = "ADD_SERVICE_FAIL";
export const ADD_SERVICE_RESET = "ADD_SERVICE_RESET";
export const UPLOAD_MAIN_IMAGE_SERVICE_REQUEST =
  "UPLOAD_MAIN_IMAGE_SERVICE_REQUEST";
export const UPLOAD_MAIN_IMAGE_SERVICE_SUCCESS =
  "UPLOAD_MAIN_IMAGE_SERVICE_SUCCESS";
export const UPLOAD_MAIN_IMAGE_SERVICE_FAIL = "UPLOAD_MAIN_IMAGE_SERVICE_FAIL";
export const UPLOAD_MAIN_IMAGE_SERVICE_RESET =
  "UPLOAD_MAIN_IMAGE_SERVICE_RESET";
export const UPLOAD_IMAGES_SERVICE_REQUEST = "UPLOAD_IMAGES_SERVICE_REQUEST";
export const UPLOAD_IMAGES_SERVICE_SUCCESS = "UPLOAD_IMAGES_SERVICE_SUCCESS";
export const UPLOAD_IMAGES_SERVICE_FAIL = "UPLOAD_IMAGES_SERVICE_FAIL";
export const UPLOAD_IMAGES_SERVICE_RESET = "UPLOAD_IMAGES_SERVICE_RESET";
