import {
  GET_RATE_EXPERIENCE_FAIL,
  GET_RATE_EXPERIENCE_SUCCESS,
  GET_RATE_EXPERIENCE_REQUEST,
} from "../constants/adminDashboardConstants";
const initialState = {
  finalRate: [],
  error: null,
  loading: false,
};
export const RateFinalExperience = (state = initialState, action) => {
  switch (action.type) {
    case GET_RATE_EXPERIENCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_RATE_EXPERIENCE_SUCCESS:
      return {
        ...state,
        finalRate: action.payload,
        error: null,
        loading: false,
      };
    case GET_RATE_EXPERIENCE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
