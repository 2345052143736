import { url } from "../../common/constants/url";
import axios from "axios";
import {
    GET_ADMIN_CHATS_FAIL,
    GET_ADMIN_CHATS_SUCCESS
} from "../constants/authConstants";
export const GetAdminUID = () => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.get(`${url}/chats/getChats`, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            
            dispatch(GetAdminUIDSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(GetAdminUIDFail(error));
            // console.log(error)
        }
    };
};
export const GetAdminUIDSuccess = (categories) => {
    return {
        type: GET_ADMIN_CHATS_SUCCESS,
        payload: categories,
    };
};
const GetAdminUIDFail = (error) => ({
    type: GET_ADMIN_CHATS_FAIL,
    payload: error
});