import React from "react";
import Layout from "../../common/components/Layouts/Layout";
import Search from "../../common/components/Search/Search";
import styles from "./SpecialOffers.module.scss";
import DateContainer from "./components/DateContainer/DateContainer";
import FeedContainer from "./components/FeedContainer/FeedContainer";
import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from "date-fns";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { GetOfferByDate } from "../../store/actions/sendOfferActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../common/components/Loader/Loader";
//
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const SpecialOffersPage = () => {
  const location = useLocation();
  const {selectedDayURL}=useParams();
  const dispatch = useDispatch();
  const navigate=useNavigate()
  useEffect(() => {
    if (location.state?.offerState) {
      const pageName = "Special Offers"; // Replace this with the actual page name
      const pageUrl = "/special-offers"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Special Offers"; // Replace this with the actual page name
      const pageUrl = "/special-offers"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, []);
  let today = startOfToday();
  const offerByDate = useSelector((state) => state.OfferByDate?.OfferByDate)
   let [selectedDay, setSelectedDay] = useState(today);
   const [loading, setLoading] = useState(false);
  let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());
  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });
  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }
  const day = String(selectedDay.getDate()).padStart(2, '0');
  const month = String(selectedDay.getMonth() + 1).padStart(2, '0');
  const year = selectedDay.getFullYear();
  const formattedDate = `${month}-${day}-${year}`;
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const rawData = { "date": formattedDate };
        await dispatch(GetOfferByDate(rawData));
      } catch (error) {
        // Handle error if needed
      }
      setLoading(false);
    };
  
    fetchData();
  }, [dispatch, formattedDate]);
  useEffect(() => {
    setSelectedDay(selectedDayURL ? parseISO(selectedDayURL) : today);
  }, [selectedDayURL]);
  const handleDayClick = (day) => {
    setSelectedDay(day);
    const dayFormatted = format(day, 'yyyy-MM-dd');
    navigate(`/special-offers/${dayFormatted}`); // Update URL with selected day
  };
  return (
    <>
      <Helmet>
        <title>Special Offers</title>
        <meta name="Special Offers" content="Special Offers" />
        <meta
          name="keywords"
          content={`special offers, la7i27alak, special offers la7i27alak`}
        />
      </Helmet>
      <Layout>
    {loading?(
      <div style={{display:"flex",alignItems:'center',justifyContent:'center'}}>
      <Loader/>
      </div>
    ):(
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <section className={styles.calender_section}>
              <div>
                <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Created offers</h1>
                <DateContainer selectedDay={selectedDay} offerByDate={offerByDate} />
              </div>
              <div className="md:pr-19" >
                <div className="flex items-center">
                  <h2 className={`flex-auto font-semibold ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                    {format(firstDayCurrentMonth, "MMMM yyyy")}
                  </h2>
                  <button
                    type="button"
                    onClick={previousMonth}
                    className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Previous month</span>
                    <HiOutlineChevronLeft
                      className="w-5 h-5"
                      aria-hidden="true"
                    />
                  </button>
                  <button
                    onClick={nextMonth}
                    type="button"
                    className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Next month</span>
                    <HiOutlineChevronRight
                      className="w-5 h-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <div className={`grid grid-cols-7 mt-10 text-md leading-6 text-center ${darkMode ? 'text-white' : 'text-gray-900'}`}>
                  <div>Mo</div>
                  <div>Tu</div>
                  <div>We</div>
                  <div>Th</div>
                  <div>Fr</div>
                  <div>Sa</div>
                  <div>Su</div>
                </div>
                <div className="grid grid-cols-7 mt-2 text-sm">
                  {days.map((day, dayIdx) => (
                    <div
                      key={day.toString()}
                      className={classNames(
                        dayIdx === 0 && colStartClasses[getDay(day)],
                        "py-1.5"
                      )}
                    >
                      <button
                        type="button"
                        onClick={() => handleDayClick(day)}
                        className={classNames(
                          isEqual(day, selectedDay) && "text-white",
                          !isEqual(day, selectedDay) &&
                          isToday(day) &&
                          "border-2 border-gray-900 ",
                          !isEqual(day, selectedDay) &&
                            !isToday(day) &&
                            isSameMonth(day, firstDayCurrentMonth) &&
                            darkMode ? "text-white" : "text-gray-900",
                          !isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          !isSameMonth(day, firstDayCurrentMonth) &&
                          "text-gray-900",
                          isEqual(day, selectedDay) &&
                          isToday(day) &&
                          "bg-blue-400",
                          isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          "bg-blue-400",
                          !isEqual(day, selectedDay) && "hover:bg-gray-200",
                          (isEqual(day, selectedDay) || isToday(day)) &&
                          "font-semibold",
                          "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                        )}
                      >
                        <time dateTime={format(day, "yyyy-MM-dd")}>
                          {format(day, "d")}
                        </time>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            {offerByDate && offerByDate.results?.length === 0 ? (
              <section className={styles.feed_section}>
              <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>No Feeds</h1>
              <div className={styles.feeds}>
                <FeedContainer offerByDate={offerByDate && offerByDate.results} loading={loading}/>
              </div>
            </section>
            ):(
              <section className={styles.feed_section}>
                <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Feeds</h1>
                <div className={styles.feeds}>
                  <FeedContainer offerByDate={offerByDate && offerByDate.results} loading={loading}/>
                </div>
              </section>
            )}
          </div>
        </div>
        )}
      </Layout>
    </>
  );
};
let colStartClasses = [
  "",
  "col-start-1",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
];
export default SpecialOffersPage;
