import {
    CHECK_UNREAD_NOTIFICATION_FAIL,
    CHECK_UNREAD_NOTIFICATION_SUCCESS
} from "../constants/NotificationConstants";
const initialState = {
    checkNotification: [],
    error: null,
    loading: false,
};
export const CheckNotifications = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_UNREAD_NOTIFICATION_SUCCESS:
            return {
                ...state,
                checkNotification: action.payload,
                error: null,
                loading: false,
            };
        case CHECK_UNREAD_NOTIFICATION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
