import React, { useState, useEffect } from "react";
import styles from "./FAQPage.module.scss";
import Layout from "../../common/components/Layouts/Layout";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { Helmet } from "react-helmet";
import { GetFAQ } from "../../store/actions/companyActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const FAQPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.isFaq) {
      const pageName = "FAQ"; // Replace this with the actual page name
      const pageUrl = "/FAQ"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "FAQ"; // Replace this with the actual page name
      const pageUrl = "/FAQ"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, []);
  const faq = useSelector((state) => state.faq?.faq?.data?.faqs)
 
  useState(() => {
    dispatch(GetFAQ())
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>FAQ</title>
        <meta name="FAQ" content="Frequently Asked Questions" />
        <meta
          name="keywords"
          content={`la7i27alak faq, faq, help, help la7i27alak, what to do, more info, product, service, la7i27alak`}
        />
      </Helmet>
      <Layout title={"Frequently Asked Questions"}>
        <div className={styles.faq}>
          <MDBContainer className="mt-5" style={{ maxWidth: '1000px' }}>
            <MDBAccordion alwaysOpen initialActive={1}>
              {faq && faq.map((faqItem) => (
                <MDBAccordionItem collapseId={faqItem._id} headerTitle={faqItem.title}>
                  {faqItem.description}
                </MDBAccordionItem>
              ))}
            </MDBAccordion>
          </MDBContainer>
        </div>
      </Layout>;
    </>
  )
};
export default FAQPage;
