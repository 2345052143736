import React, { useEffect, useState } from "react";
import styles from "./Card.module.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { url } from "../../../common/constants/url";
import { GetAllCategories } from "../../../store/actions/categoryAction";
import { GetAllCategoriesSuccess } from "../../../store/actions/categoryAction";
import { GetAllSubCategories } from "../../../store/actions/categoryAction";
import { GetAllSubCategoriesSuccess } from "../../../store/actions/categoryAction";
const Card = ({ image, categoryId, title, subCategory }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filterBar = useSelector((state) => state.filterBar.isFilterBarOn);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <div className={styles.card_container}>
      <div className={`${styles.card} ${darkMode ? styles.cardDark : ''} ${filterBar ? styles.cardBar : ''}`}>
        <img src={image} alt={title} className={styles.card_image} />
        <div className={styles.subcategories_container}>
          {Array.isArray(subCategory) && subCategory.length > 0 &&
            subCategory.map((item) => (
              <div className={styles.subcategories_item} key={item.title}>
              <Link
  to={{
    pathname: `/categories/${title
      .replaceAll(' ', '-')
      .replaceAll('&', 'And')
      .replaceAll(',', '')}/${item.title
        .replaceAll(' ', '-')
        .replaceAll('&', 'And')
        .replaceAll(',', '')}/${item._id}/${item.parentCategoryId}`,
    state: { isSubCategory: true }
  }}
  className={`${styles.subcategories_title} ${darkMode ? styles.subcategories_titleDark : ''}`}
>
  {item.title}
</Link>
              </div>
            ))
          }
        </div>
      </div>
      <div className={styles.title_container}>
        <h1 className={`${styles.card_title} ${darkMode ? styles.card_titleDark : ''}`}>{title}</h1>
      </div>
    </div>
  );
};
export default Card;
