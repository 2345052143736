import { FILTER_BAR, FILTER_ICON } from "../constants/filterConstant";
export const handleFilterBar = (e) => async (dispatch) => {
  dispatch({
    type: FILTER_BAR,
    payload: e,
  });
};
export const handleFilterIcon = (e) => async (dispatch) => {
  dispatch({
    type: FILTER_ICON,
    payload: e,
  });
};
