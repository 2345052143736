import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  ADD_FAVORITE_REQUEST,
  ADD_FAVORITE_FAIL,
  ADD_FAVORITE_SUCCESS,
} from "../constants/favoriteConstants";
import axios from "axios";
import { url } from "../../common/constants/url";
export const getCategoriesList = () => async (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_LIST_REQUEST,
    });
    const { data } = await axios.get(
      `${url}/Category_products/getCategory_products`
    );
    dispatch({
      type: CATEGORY_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const addCategoryToFavorite =
  (user_id, cat_id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_FAVORITE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo}`,
        },
      };
      let body = {
        user_id,
        cat_id,
      };
      await axios.post(
        `${url}/favorites_categories_service/addfavorites_categories_service`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: ADD_FAVORITE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ADD_FAVORITE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
