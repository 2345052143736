import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import user from "../../../../assests/images/user.png";
import edit from "../../../../assests/images/edit.png";
import inbox from "../../../../assests/images/envelope.png";
import settings from "../../../../assests/images/settings.png";
import help from "../../../../assests/images/question.png";
import logout from "../../../../assests/images/log-out.png";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { GetNotification } from "../../../../store/actions/NotificationAction";
import { timePosted } from "../../../Utils";
import { AiOutlineBell } from "react-icons/ai";
import { Link } from 'react-router-dom';
const NotiDropDown = ({ children }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  let menuRef = useRef();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 901) {
        setOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const navigate = useNavigate();
  const notification = useSelector((state) => state.notification?.notification?.notifications)
  useEffect(() => {
    dispatch(GetNotification());
  }, [dispatch])
  return (
    <div className="notification-menu-container" ref={menuRef}>
      <div
        className="menu-trigger"
        onClick={() => {
          setOpen(!open);
        }}
      >
        {children}
      </div>
      {/* {open && <div className="triangle"></div>} */}
      <div className={`dropdown-menuu ${darkMode ? 'dropdown-menuuDark' : ''} ${open ? 'active' : 'inactive'}`}>
        <ul>
          {notification && notification.length > 0 ? (
            <>
              <button style={{ color: '#0298a6', textDecoration: 'underline', display: 'flex', float: 'right' ,marginRight:'20px'}} onClick={() => navigate('/notifications')}>
                see all
              </button>
              {notification.map((item, index) => (
                <DropdownItem
                  key={index}
                  img={inbox}
                  title={item.title}
                  //old={item.old}
                  time={item.date}
                  body={item.body}
                  url={item.webUrl}
                />
              ))}
            </>
          ) : (
            <div style={{ marginRight: '30px', marginLeft:'35px',marginTop:'10px',width: '150px' }}>No Notifications</div>
          )}
          {/* <button style={{ color: '#0298a6', textDecoration: 'underline' }} onClick={() => navigate('/notifications')}>
            see all
          </button> */}
        </ul>
      </div>
    </div>
  );
};
function DropdownItem({ img, title, old, time, body, url }) {
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const newUrl = url ? new URL(url) : null;
  const path = newUrl?.pathname;
  return (
    <Link to={path || '/'} className="custom-link">
      <li className={`dropdownItem`}>
        <div className={"wrapper"}>
          <div className="image__container">
            <AiOutlineBell className={darkMode ? 'icondark' : 'icon'} />
          </div>
          <div className={`text__container ${darkMode ? 'text__containerDark' : ''}`}>
            <h4 className={`text ${darkMode ? 'textDark' : ''}`}>{title}</h4>
            <h6 className={`text ${darkMode ? 'textDark' : ''}`}>{body}</h6>
            <p style={{ color: '#0298a6' }}>{timePosted(time) === "now" ? "now" : timePosted(time) + " ago"}</p>
          </div>
        </div>
      </li>
    </Link>
  );
}
export default NotiDropDown;
