import React, { useState, useEffect } from "react";
import styles from "./ReviewsCard.module.scss";
import { FaStar, FaRegStar, FaCamera } from "react-icons/fa";
import { timePosted } from "../../../../common/Utils";
import { GetReviewsByUID } from "../../../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { GetUser } from "../../../../store/actions/userActions";
import { url } from "../../../../common/constants/url";
const ReviewsCard = ({ from, id, time, text, star }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const filledStars = (num) => {
    let stars = [];
    for (let i = 0; i < num; i++) {
      stars.push(<FaStar key={i} />);
    }
    for (let i = num; i < 5; i++) {
      stars.push(<FaRegStar key={i} />);
    }
    return stars;
  };
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.image_container}>
          <img
            src={from && from.imageUrl}
            alt="user"
            className={styles.image}
          />
          <div className={`${styles.name_container} ${darkMode ? styles.name_containerDark : ''}`}>
            <h3 className={`${styles.name} ${darkMode ? styles.nameDark : ''}`}>{from && from.username}</h3>
            <div className={styles.star}>
              {filledStars(star)}
            </div>
          </div>
        </div>
        <p className={`${styles.time} ${darkMode ? styles.timeDark : ''}`}>
          {timePosted(time) === "now" ? "now" : timePosted(time) + " ago"}
        </p>
      </div>
      <p className={`${styles.text} ${darkMode ? styles.textDark : ''}`}>
        {text}
      </p>
      <div className={`${styles.line} ${darkMode ? styles.lineDark : ''}`}></div>
    </div>
  );
};
export default ReviewsCard;
