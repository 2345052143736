export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAIL = "CREATE_SERVICE_FAIL";
export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_TYPE_SUCCESS = "CREATE_SERVICE_TYPE_SUCCESS";
export const CREATE_SERVICE_TYPE_FAIL = "CREATE_SERVICE_TYPE_FAIL";
export const DELETE_SERVICE_BY_ID_SUCCESS = "DELETE_SERVICE_BY_ID_SUCCESS";
export const DELETE_SERVICE_BY_ID_FAIL = "DELETE_SERVICE_BY_ID_FAIL";
export const EDIT_SERVICE_BY_ID_SUCCESS = "EDIT_SERVICE_BY_ID_SUCCESS";
export const EDIT_SERVICE_BY_ID_FAIL = "EDIT_SERVICE_BY_ID_FAIL";
export const CONFIRM_SERVICE_BY_ID_SUCCESS = "CONFIRM_SERVICE_BY_ID_SUCCESS";
export const CONFIRM_SERVICE_BY_ID_FAIL = "CONFIRM_SERVICE_BY_ID_FAIL";
export const UNCONFIRM_SERVICE_BY_ID_SUCCESS = "UNCONFIRM_SERVICE_BY_ID_SUCCESS";
export const UNCONFIRM_SERVICE_BY_ID_FAIL = "UNCONFIRM_SERVICE_BY_ID_FAIL";
export const FEATURE_SERVICE_SUCCESS = "FEATURE_SERVICE_SUCCESS";
export const FEATURE_SERVICE_FAIL = "FEATURE_SERVICE_FAIL";
export const UNFEATURE_SERVICE_SUCCESS = "UNFEATURE_SERVICE_SUCCESS";
export const UNFEATURE_SERVICE_FAIL = "UNFEATURE_SERVICE_FAIL";
export const GET_ALL_TYPES_SUCCESS = "GET_ALL_TYPES_SUCCESS";
export const GET_ALL_TYPES_FAIL = "GET_ALL_TYPES_FAIL";
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS";
export const GET_ALL_SERVICES_FAIL = "GET_ALL_SERVICES_FAIL";
export const GET_ALL_SERVICES_REQUEST = "GET_ALL_SERVICES_REQUEST";
export const GET_SINGLE_SERVICE_SUCCESS = "GET_SINGLE_SERVICE_SUCCESS";
export const GET_SINGLE_SERVICE_FAIL = "GET_SINGLE_SERVICE_FAIL";
export const GET_ALL_SERVICES_BY_TYPE_SUCCESS = "GET_ALL_SERVICES_BY_TYPE_SUCCESS";
export const GET_ALL_SERVICES_BY_TYPE_FAIL = "GET_ALL_SERVICES_BY_TYPE_FAIL";
export const GET_TYPE_BY_ID_SUCCESS = "GET_TYPE_BY_ID_SUCCESS";
export const GET_TYPE_BY_ID_FAIL = "GET_TYPE_BY_ID_FAIL";
export const GET_ALL_OFFERED_SERVICES_SUCCESS = "GET_ALL_OFFERED_SERVICES_SUCCESS";
export const GET_ALL_OFFERED_SERVICES_FAIL = "GET_ALL_OFFERED_SERVICES_FAIL";
export const GET_SERVICES_WISHLIST_SUCCESS = "GET_SERVICES_WISHLIST_SUCCESS";
export const GET_SERVICES_WISHLIST_FAIL = "GET_SERVICES_WISHLIST_FAIL";
export const GET_MY_SERVICES_SUCCESS = "GET_MY_SERVICES_SUCCESS";
export const GET_MY_SERVICES_FAIL = "GET_MY_SERVICES_FAIL";
export const REMOVE_SERVICE_WHISHLIST_SUCCESS = "REMOVE_SERVICE_WHISHLIST_SUCCESS";
export const REMOVE_SERVICE_WHISHLIST_FAIL = "REMOVE_SERVICE_WHISHLIST_FAIL";
export const EDIT_SERVICE_TYPE_SUCCESS = "EDIT_SERVICE_TYPE_SUCCESS";
export const EDIT_SERVICE_TYPE_FAIL = "EDIT_SERVICE_TYPE_FAIL";
export const DELETE_SERVICE_TYPE_BY_ID_SUCCESS = "DELETE_SERVICE_TYPE_BY_ID_SUCCESS";
export const DELETE_SERVICE_TYPE_BY_ID_FAIL = "DELETE_SERVICE_TYPE_BY_ID_FAIL";
