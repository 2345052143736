import {
  ADD_TO_WAITINGLIST_REQUEST,
  ADD_TO_WAITINGLIST_SUCCESS,
  ADD_TO_WAITINGLIST_FAIL,
  ADD_TO_WAITINGLIST_RESET,
  PAYMENT_WAITING_FOR_SURE_REQUEST,
  PAYMENT_WAITING_FOR_SURE_SUCCESS,
  PAYMENT_WAITING_FOR_SURE_FAIL,
  PAYMENT_WAITING_FOR_SURE_RESET,
  REMOVE_FROM_WAITINGLIST_FAIL,
  REMOVE_FROM_WAITINGLIST_REQUEST,
  REMOVE_FROM_WAITINGLIST_RESET,
  REMOVE_FROM_WAITINGLIST_SUCCESS,
  GET_WAITINGLIST_FAIL,
  GET_WAITINGLIST_REQUEST,
  GET_WAITINGLIST_RESET,
  GET_WAITINGLIST_SUCCESS,
  CHECK_USER_IN_WAITINGLIST_REQUEST,
  CHECK_USER_IN_WAITINGLIST_SUCCESS,
  CHECK_USER_IN_WAITINGLIST_FAIL,
  CHECK_USER_IN_WAITINGLIST_RESET,
  ACCEPT_WAITINGLIST_FAIL,
  ACCEPT_WAITINGLIST_REQUEST,
  ACCEPT_WAITINGLIST_RESET,
  ACCEPT_WAITINGLIST_SUCCESS,
  IF_ANY_USER_ACCEPTED_REQUEST,
  IF_ANY_USER_ACCEPTED_FAIL,
  IF_ANY_USER_ACCEPTED_RESET,
  IF_ANY_USER_ACCEPTED_SUCCESS,
  CANCEL_WAITINGLIST_REQUEST,
  CANCEL_WAITINGLIST_SUCCESS,
  CANCEL_WAITINGLIST_FAIL,
  CANCEL_WAITINGLIST_RESET,
  GET_MY_WAITING_LIST_REQUEST,
  GET_MY_WAITING_LIST_SUCCESS,
  GET_MY_WAITING_LIST_FAIL,
} from "../constants/waitingListConstants";
export const addToWaitingListReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case ADD_TO_WAITINGLIST_REQUEST:
      return { ...state, loading: true };
    case ADD_TO_WAITINGLIST_SUCCESS:
      return { ...state, loading: false, success: true };
    case ADD_TO_WAITINGLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case ADD_TO_WAITINGLIST_RESET:
      return { loading: false, error: null, success: false };
    default:
      return state;
  }
};
const initialState = {
  myWaitList: [],
  error: null,
  loading: false
};
export const GetMyWaitingListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MY_WAITING_LIST_REQUEST": 
      return {
        ...state,
        myWaitList: action.payload,
        loading: true,
        error: null,
      };
    case "GET_MY_WAITING_LIST_SUCCESS":
      return {
        ...state,
        myWaitList: action.payload,
        loading: false,
        error: null,
      };
    case "GET_MY_WAITING_LIST_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const paymentComingForSureReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case PAYMENT_WAITING_FOR_SURE_REQUEST:
      return { ...state, loading: true };
    case PAYMENT_WAITING_FOR_SURE_SUCCESS:
      return { ...state, loading: false, success: true };
    case PAYMENT_WAITING_FOR_SURE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case PAYMENT_WAITING_FOR_SURE_RESET:
      return { loading: false, error: null, success: false };
    default:
      return state;
  }
};
export const removeFromWaitingListReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case REMOVE_FROM_WAITINGLIST_REQUEST:
      return { ...state, loading: true };
    case REMOVE_FROM_WAITINGLIST_SUCCESS:
      return { ...state, loading: false, success: true };
    case REMOVE_FROM_WAITINGLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case REMOVE_FROM_WAITINGLIST_RESET:
      return { loading: false, error: null, success: false };
    default:
      return state;
  }
};
export const getWaitingListReducer = (
  state = { loading: false, error: null, waitinglist: [] },
  action
) => {
  switch (action.type) {
    case GET_WAITINGLIST_REQUEST:
      return { ...state, loading: true };
    case GET_WAITINGLIST_SUCCESS:
      return { ...state, loading: false, waitinglist: action.payload };
    case GET_WAITINGLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_WAITINGLIST_RESET:
      return { loading: false, error: null, waitinglist: [] };
    default:
      return state;
  }
};
export const checkUserInWaitingListReducer = (
  state = { loading: false, error: null, user: null },
  action
) => {
  switch (action.type) {
    case CHECK_USER_IN_WAITINGLIST_REQUEST:
      return { ...state, loading: true };
    case CHECK_USER_IN_WAITINGLIST_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case CHECK_USER_IN_WAITINGLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CHECK_USER_IN_WAITINGLIST_RESET:
      return { loading: false, error: null, user: null };
    default:
      return state;
  }
};
export const acceptWaitingListReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case ACCEPT_WAITINGLIST_REQUEST:
      return { ...state, loading: true };
    case ACCEPT_WAITINGLIST_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case ACCEPT_WAITINGLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case ACCEPT_WAITINGLIST_RESET:
      return { loading: false, error: null, success: false };
    default:
      return state;
  }
};
export const ifanyuseracceptedReducer = (
  state = { loading: false, error: null, user: null },
  action
) => {
  switch (action.type) {
    case IF_ANY_USER_ACCEPTED_REQUEST:
      return { ...state, loading: true };
    case IF_ANY_USER_ACCEPTED_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case IF_ANY_USER_ACCEPTED_FAIL:
      return { ...state, loading: false, error: action.payload };
    case IF_ANY_USER_ACCEPTED_RESET:
      return { loading: false, error: null, user: null };
    default:
      return state;
  }
};
export const cancelWaitingListReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case CANCEL_WAITINGLIST_REQUEST:
      return { ...state, loading: true };
    case CANCEL_WAITINGLIST_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case CANCEL_WAITINGLIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CANCEL_WAITINGLIST_RESET:
      return { loading: false, error: null, success: false };
    default:
      return state;
  }
};
