import React from "react";
import styles from "./styles.module.scss";
const CatCardSkel = () => {
  return (
    <div
      className={styles.containerSkel}
    >
      <div className={styles.cardSkell}>
        <div className={styles.imageSkel}> </div>
      </div>
      <div className={styles.nameSkel}></div>
      <div className={styles.cardSkell}>
        <div className={styles.imageSkel}> </div>
      </div>
      <div className={styles.nameSkel}></div>
      <div className={styles.cardSkell}>
        <div className={styles.imageSkel}> </div>
      </div>
      <div className={styles.nameSkel}></div>
      <div className={styles.cardSkell}>
        <div className={styles.imageSkel}> </div>
      </div>
      <div className={styles.nameSkel}></div>
    </div>
  );
};
export default CatCardSkel;
