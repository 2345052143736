import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import ps5 from "../../../assests/images/PS5 1.png";
import green from "../../../assests/svg/green.svg";
import red from "../../../assests/svg/red.svg";
import { MdLocationPin, MdTimer } from "react-icons/md";
import { AiFillHeart, AiOutlineHeart, AiFillBell } from "react-icons/ai";
import { useSelector } from "react-redux";
import { timeLeft, timePosted } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeFromWishlist } from "../../../store/actions/whishlistActions";
import { countDown } from "../../Utils";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { REMOVE_ITEM_WHISHLIST_RESET } from "../../../store/constants/wishlistConstants";
import Loader from "../Loader/Loader";
import { Link } from 'react-router-dom';
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { AddWishlist, RemoveWishList, GetWishList, GetWishlistSuccess } from "../../../store/actions/whishlistActions";
import Modal from "../Modal/Modal";
import { GetWishListService } from "../../../store/actions/whishlistActions";
import { GetWishlistServiceSuccess } from "../../../store/actions/whishlistActions";
import { AddServiceToWishlist } from "../../../store/actions/whishlistActions";
import { GetServicesWishlist } from "../../../store/actions/servicesActions";
import { RemoveServiceWishList } from "../../../store/actions/servicesActions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LazyLoad from 'react-lazyload'
const Cards = ({ title, price, mainimage, id, featured, time, start, end, old, sold,location, timer, discounted, percentage, mobile = false, isService ,ServicesWishlist,wishList}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(false);
  const { user } = useSelector((state) => state.userLogin);
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);

  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingWishlist, setLoadingWishlist] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  
  const whishlistArray = useSelector((state) => state.whishlistArray);
  const { whishlistID, error, loading } = whishlistArray;
  const removeFromWishList = useSelector((state) => state.removeFromWishList);
  const {
    success,
    error: errorRemove,
    loading: loadingRemove,
  } = removeFromWishList;
  const removeWishlist = useSelector((state) => state.removeWishlist?.loading)
  let newTitle = title ? title.replaceAll(" ", "-") : '';
  const isProductInWishlist = (wishList ?? []).some(product => product._id === id) || (ServicesWishlist ?? []).some(product => product._id === id);
  const handleHeartClick = async (event) => {
    event.stopPropagation();
    if (loggedInUser) {
      if (isProductInWishlist) {
        setIsOpen(true);
      } else {
        if (isService) {
          if (id) {
            const response = await dispatch(AddServiceToWishlist(id))
            if (response) {
              dispatch(GetServicesWishlist())
            }
          }
        } else {
          const formData = new FormData();
          if (id) {
            formData.append("productId", id);
            const response = await dispatch(AddWishlist(formData))
            if (response) {
              dispatch(GetWishList())
            }
          }
        }
      }
    } else {
      toast.error('Please login first.');
      setIsOpenModal(true);
    }
  };
  const removeWishList = async (event) => {
    event.stopPropagation();
    setLoadingWishlist(true)
    if (isService) {
      const response = await dispatch(RemoveServiceWishList(id))
      if (response) {
        dispatch(GetServicesWishlist())
        setIsOpen(false)
      }
      setLoadingWishlist(false)
    } else {
      const formData = new FormData();
      formData.append("productId", id);
      setLoadingWishlist(true)
      const response = await dispatch(RemoveWishList(formData))
      if (response) {
        dispatch(GetWishList())
        setIsOpen(false)
      }
      setLoadingWishlist(false)
    }
  };
  let formattedDate = '';
  const currentDate = new Date().toISOString();
  if (timer && end) {
    const endDate = new Date(end);
    if (!isNaN(endDate)) {
      formattedDate = endDate.toISOString();
    } else {
      return
    }
  }
  const newprice = price - (price * (percentage / 100))
  return (
    <>
      <Modal
        open={isOpenModal}
        closeHandler={() => setIsOpenModal(false)}
      // page={"login"}
      />
      {mobile ? (
        <div onClick={(event) => {
  event.preventDefault();
  navigate(`/${isService ? 'service' : 'product'}/${newTitle}/${id}`);
}} className={`${styles.cardmb} ${darkMode ? styles.cardmbDark : ''}`}>
          <div className={styles.image__containermb}>
            {featured && (
              <div className={`${styles.featured_containermb}`}>
                <p className={styles.featured_textmb}>Featured</p>
              </div>
            )}
            <Link to={`/${isService ? "service" : "product"}/${newTitle}/${id}`} state={{ id, isService }}>
              <LazyLoad height={166} offset={100}>
                <img
                  className={styles.card__imagemb}
                  src={mainimage ? mainimage : ps5}
                  alt="product"
                />
              </LazyLoad>
            </Link>
            {timer && end && (
              <div className={styles.timermb}>
                <AiFillBell className={styles.timermb__icon} />{' '}
                <span className={styles.timermb__text}>{countDown(end) || "Expired"}</span>
              </div>
            )}
          </div>
          <div className={styles.detailsmb}>
            {discounted ? (
              <div className={`${styles.price__containermb} ${discounted ? styles.price__containermbdisc : ''}`}>
                <h3 className={`${styles.pricemb} ${darkMode ? styles.pricembDark : ''}`}>$ {newprice}</h3>
                <div className={styles.oldmbpd}>
                  <h3 className={`${styles.oldmbprice} ${darkMode ? styles.oldmbpriceDark : ''}`}>
                    {price ? price : '400'}
                  </h3>
                  <h3 className={`${styles.discountmb} ${darkMode ? styles.discountmbDark : ''}`}>%{percentage}</h3>
                </div>
              </div>
            ) : (
              <div className={styles.price__containermb}>
                <h3 className={`${styles.pricemb} ${darkMode ? styles.pricembDark : ''}`}>$ {price ? price : '400'}</h3>
              </div>
            )}
            <h4
              className={`${styles.titlemb} ${discounted ? styles.titlembdisc : ''} ${darkMode ? styles.titlembDark : ''
                }`}
            >
              {title ? title : 'PS5 Sony Playstation 5 Console Disc Version'}
            </h4>
            <p className={styles.locationmb}>
              <MdLocationPin className={styles.location__iconmb} />{' '}
              <span className={styles.location__textmb}>{location}</span>
            </p>
            <p className={styles.timemb}>
              <MdTimer className={styles.time__iconmb} />{' '}
              <span className={styles.time__textmb}>{timePosted(time)} ago</span>
            </p>
            {isProductInWishlist ? (
              <AiFillHeart className={styles.heartmb} onClick={handleHeartClick} />
            ) : (
              <AiOutlineHeart className={styles.heartmb} onClick={handleHeartClick} />
            )}
            {sold?(
                <div className={styles.condition_containermb}>
                  <p className={styles.conditionmb}>{'Sold'}</p>
                  <img src={red} alt={'Sold'} className={styles.condition_imagemb} />
                </div>
              
            ):(
              old !== undefined && (
                <div className={styles.condition_containermb}>
                  <p className={styles.conditionmb}>{old ? 'New' : 'Used'}</p>
                  <img src={old ? green : red} alt={old ? 'new' : 'used'} className={styles.condition_imagemb} />
                </div>
              )
            )}
            
          </div>
        </div>
      ) : (
        <>
          <div onClick={(event) => {
  event.preventDefault();
  navigate(`/${isService ? 'service' : 'product'}/${newTitle}/${id}`);
}} className={`${styles.card} ${darkMode ? styles.cardDark : ''}`}>
            <div className={styles.image__container}>
              <Link to={`/${isService ? "service" : "product"}/${newTitle}/${id}`} state={{ id, isService }}>
                <img
                  className={styles.card__image}
                  src={mainimage ? mainimage : ps5}
                  alt="product"
                />
              </Link>
              {isProductInWishlist ? (
                <div className={styles.heart}
                  onClick={handleHeartClick} >
                  <svg viewBox="0 0 35 31" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M31.2918 3.00002C29.4634 1.55208 27.1398 0.839235 24.7933 1.00635C22.4467 1.17347 20.2533 2.20801 18.6587 3.89971L17.3277 5.24356L15.9966 3.89971C14.4021 2.20801 12.2086 1.17347 9.86208 1.00635C7.51551 0.839235 5.19191 1.55208 3.36352 3.00002C2.36647 3.84292 1.55511 4.87527 0.978757 6.03433C0.402404 7.19339 0.0730964 8.45495 0.0108487 9.74233C-0.051399 11.0297 0.154713 12.316 0.616659 13.5231C1.0786 14.7301 1.78673 15.8327 2.69801 16.7639L15.7987 29.9909C15.9968 30.1932 16.2348 30.3542 16.4983 30.4642C16.7618 30.5742 17.0453 30.6309 17.3318 30.6309C17.6183 30.6309 17.9019 30.5742 18.1654 30.4642C18.4288 30.3542 18.6668 30.1932 18.865 29.9909L31.9657 16.7639C32.8778 15.8332 33.5867 14.7307 34.0492 13.5236C34.5117 12.3164 34.7181 11.0298 34.6558 9.74219C34.5936 8.45454 34.264 7.19275 33.687 6.03365C33.1101 4.87455 32.298 3.84239 31.3001 3.00002H31.2918Z" fill="#0298A6" /> </svg>
                </div>
              ) : (
                <div className={styles.heart} onClick={handleHeartClick}>
                  <svg viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M32.5927 3.03518C30.7467 1.58724 28.4008 0.874391 26.0317 1.04151C23.6625 1.20862 21.448 2.24316 19.8381 3.93487L18.4943 5.27872L17.1505 3.93487C15.5406 2.24316 13.326 1.20862 10.9569 1.04151C8.58778 0.874391 6.24183 1.58724 4.39586 3.03518C3.38922 3.87808 2.57006 4.91043 1.98817 6.06949C1.40627 7.22855 1.0738 8.4901 1.01095 9.77748C0.948107 11.0649 1.1562 12.3512 1.62259 13.5582C2.08897 14.7653 2.80391 15.8679 3.72395 16.799L16.9506 30.026C17.1506 30.2283 17.3909 30.3893 17.657 30.4993C17.923 30.6093 18.2092 30.666 18.4985 30.666C18.7877 30.666 19.074 30.6093 19.34 30.4993C19.606 30.3893 19.8463 30.2283 20.0464 30.026L33.273 16.799C34.1939 15.8684 34.9097 14.7659 35.3766 13.5587C35.8435 12.3516 36.0519 11.065 35.9891 9.77734C35.9262 8.4897 35.5934 7.22791 35.011 6.06881C34.4285 4.90971 33.6086 3.87755 32.6011 3.03518H32.5927Z" fill="#F8FEFF" stroke="#0298A6" stroke-width="2" /> </svg>
                </div>
              )}
              {sold?(
                <div className={styles.condition_container}>
                <p className={styles.condition}>{'Sold'}</p>
                <img src={red} alt={"Sold"} className={styles.condition_image} />
              </div>
              ):(
                old !== undefined && (
                  <div className={styles.condition_container}>
                    <p className={styles.condition}>{old ? "New" : "Used"}</p>
                    <img src={old ? green : red} alt={old ? "new" : "used"} className={styles.condition_image} />
                  </div>
                )
              )}
             
              {timer && end && formattedDate && (
                <div className={styles.timer}>
                  <AiFillBell className={styles.timer__icon} />{" "}
                  <span className={styles.timer__text}>
                    {countDown(end)}
                  </span>
                </div>
              )}
              {featured && (
                <div className={styles.featured}>
                  <p>FEATURED</p>
                </div>
              )}
            </div>
            <div className={styles.details}>
              {discounted ? (
                <div className={`${styles.price__container} ${discounted ? styles.price__containerdisc : ''}`}>
                  <h3 className={`${styles.price} ${darkMode ? styles.priceDark : ''}`}>$ {newprice}</h3>
                  <div className={styles.oldpd}>
                    <h3 className={`${styles.oldprice} ${darkMode ? styles.oldpriceDark : ''}`}>${price ? price : "400"}</h3>
                    <h3 className={`${styles.discount} ${darkMode ? styles.discountDark : ''}`}>%{percentage}</h3>
                  </div>
                </div>
              ) : (
                <div className={styles.price__container}>
                  <h3 className={`${styles.price} ${darkMode ? styles.priceDark : ''}`}>$ {price ? price : "400"}</h3>
                </div>
              )}
              <h4 className={`${styles.title} ${discounted ? styles.titledisc : ''} ${darkMode ? styles.titleDark : ''}`}>
                {title.length > 30 ? `${title.substring(0, 30)}..` : title}
              </h4>
              <p className={styles.location}>
                <MdLocationPin className={styles.location__icon} />{" "}
                <span className={styles.location__text}>{location}</span>
              </p>
              <p className={styles.time}>
                <MdTimer className={styles.time__icon} />{" "}
                <span className={styles.time__text}>{timePosted(time)} ago</span>
              </p>
            </div>
          </div>
        </>
      )}
      <ConfirmModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <h1 className={`${styles.confirm_title} ${darkMode ? styles.confirm_titleDark : ''}`}>Confirmation</h1>
        <p className={`${styles.confirm_message} ${darkMode ? styles.confirm_messageDark : ''}`}>
          Are you sure you want to remove this product from your wishlist?
        </p>
        {loadingWishlist ? (
          <div className="center">
            <Loader />{" "}
          </div>
        ) : errorRemove ? (
          <div className="center">
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        ) : (
          <div className={styles.buttons_container}>
            <button className={styles.cancel} onClick={() => setIsOpen(false)}>
              Cancel
            </button>
            <button
              className={styles.confirm}
              onClick={removeWishList}
            >
              Confirm
            </button>
          </div>
        )}
      </ConfirmModal>
    </>
  );
};
export default Cards;
