import React, { useState, useEffect } from "react";
import styles from "../AdminAnalytics/AdminAnalytics.module.scss";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import img from "../../assests/svg/back person.svg";
import { Table } from "react-bootstrap";
import Role from "../AdminDashUsers/components/Role/Role";
import { Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUsers } from "../../store/actions/AdminDashboard";
import IsAdmin from "../AdminDashUsers/components/Role/IsAdmin";
import { ChangeRole } from "../../store/actions/AdminDashboard";
import { GetEndedBids } from "../../store/actions/AdminDashboard";
import ThreeDots from "../AdminDashUsers/components/threeDots/threeDots";
import Loader from "../../common/components/Loader/Loader";
const EndedBidPage = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial value
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const endedBids = useSelector(
    (state) => state.endedBids?.endedBids?.endedBids
  );
  const loading = useSelector((state) => state.endedBids?.loading);

  useEffect(() => {
    dispatch(GetEndedBids());
    console.log(endedBids)
  }, [dispatch]);
  return (
    <AdminLayout>
      <div className={styles.header}>
        <h3>Ended bids</h3>
      </div>
      {loading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : isSmallScreen ? (
        <>
          <div
            style={{ color: "#0298a6", fontWeight: "500", marginLeft: "10px" }}
          >
            Number of closed bid: {endedBids && endedBids.length}{" "}
          </div>
          {endedBids &&
            endedBids.map((user, index) => (
              <div className={styles.table}>
                <Table striped bordered hover style={{ width: "250px" }}>
                  <tbody>
                    <React.Fragment key={index}>
                      <tr>
                        <td className={styles.customername}>Buyer:</td>
                        <td className={styles.customername}>
                          <div className={styles.uInfo}>
                            <img
                              src={user.buyer?.imageUrl}
                              alt="Customer"
                              className={styles.customerImage}
                            />
                            {user.buyer?.username}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>
                          Buyer phone number:
                        </td>
                        <td className={styles.customername}>
                          {user.buyer?.phone}
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>Seller:</td>
                        <td className={styles.customername}>
                          <div className={styles.uInfo}>
                            <img
                              src={user.seller?.imageUrl}
                              alt="Customer"
                              className={styles.customerImage}
                            />
                            {user.seller?.username}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>
                          Seller phone number:
                        </td>
                        <td className={styles.customername}>
                          {user.seller?.phone}
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>Product:</td>
                        <td className={styles.customername}>
                          {user.product?.title}
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>Amount:</td>
                        <td className={styles.customername}>${user.amount}</td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>Quantity:</td>
                        <td className={styles.customername}>{user.quantity}</td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>Created Date:</td>
                        <td className={styles.customername}>
                          {user.createdAt.slice(0, 10)}
                        </td>
                      </tr>
                    </React.Fragment>
                  </tbody>
                </Table>
              </div>
            ))}
        </>
      ) : (
        <div className={styles.table}>
          <div
            style={{ color: "#0298a6", fontWeight: "500", marginLeft: "10px" }}
          >
            Number of closed bid: {endedBids && endedBids.length}{" "}
          </div>
          <Table striped bordered hover>
  <thead>
    <tr>
      <th>Product ID</th> {/* New column for product ID */}
      <th>Buyer</th>
      <th>Buyer phone number</th>
      <th>Seller</th>
      <th>Seller phone number</th>
      <th>Product</th>
      <th>Amount</th>
      <th>Quantity</th>
      <th>Date</th>
    </tr>
  </thead>
  <tbody>
    {endedBids &&
      endedBids.map((user, index) => (
        <tr key={index}>
          <td className={styles.customername}>
            {user.product?._id}
          </td>
          <td className={styles.customername} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
            <div className={styles.uInfo}>
              <img
                src={user.buyer?.imageUrl}
                alt="Customer"
                className={styles.customerImage}
              />
              {user.buyer?.username}
            </div>
          </td>
          <td className={styles.customername}>{user.buyer?.phone}</td>
          <td className={styles.customername} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>
            <div className={styles.uInfo}>
              <img
                src={user.seller?.imageUrl}
                alt="Customer"
                className={styles.customerImage}
              />
              {user.seller?.username}
            </div>
          </td>
          <td className={styles.customername}>
            {user.seller?.phone}
          </td>

          <td className={styles.email}>
            <div className={styles.email}>{user.product?.title}</div>
          </td>

          <td className={styles.customername}>${user.amount}</td>
          <td className={styles.customername}>{user.quantity}</td>
          {/* <td><IsAdmin state={user && user.isAdmin} /></td> */}
          <td className={styles.customername}>
            {user.createdAt.slice(0, 10)}
          </td>
        </tr>
      ))}
  </tbody>
</Table>
        </div>
      )}
    </AdminLayout>
  );
};
export default EndedBidPage;
