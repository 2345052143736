import {
  ORDER_HISTORY_FAIL,
  ORDER_HISTORY_REQUEST,
  ORDER_HISTORY_SUCCESS,
} from "../constants/orderConstants";
export const orderHistoryReducer = (
  state = { orderHistoryList: [], loading: false, order: null },
  action
) => {
  switch (action.type) {
    case ORDER_HISTORY_REQUEST:
      return { ...state, loading: true };
    case ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        orderHistoryList: action.payload,
      };
    case ORDER_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
