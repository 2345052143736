import {
  CATEGORIES_LIST_FAIL,
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_SUCCESS,
  CATEGORIES_LIST_RESET,
  PRODUCT_LIST_BY_SUBCATEGORY_REQUEST,
  PRODUCT_LIST_BY_SUBCATEGORY_SUCCESS,
  PRODUCT_LIST_BY_SUBCATEGORY_FAIL,
  PRODUCT_LIST_BY_SUBCATEGORY_RESET,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_FAIL
} from "../constants/categoryConstants";
export const categoriesListReducer = (
  state = { categories: [], error: null, loading: false },
  action
) => {
  switch (action.type) {
    case CATEGORIES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case CATEGORIES_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CATEGORIES_LIST_RESET:
      return { ...state, categories: [], error: null, loading: false };
    default:
      return state;
  }
};
export const productsListBySubCatReducer = (
  state = { products: [], error: null, loading: false },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_BY_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_LIST_BY_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      };
    case PRODUCT_LIST_BY_SUBCATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PRODUCT_LIST_BY_SUBCATEGORY_RESET:
      return { ...state, products: [], error: null, loading: false };
    default:
      return state;
  }
};
const initialState = {
  categories: [],
  error: null,
  loading: false
};
export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CATEGORIES_SUCCESS":
      return {
        ...state,
        categories: action.payload,
        loading: false,
        error: null,
      };
    case "GET_ALL_CATEGORIES_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
