import React, { useState } from "react";
import "./ViewDetails.scss";
import PreviewModal from "../../../Sell Product Form Page/Properties/previewModal";
const ViewDetails = ({ fields, pricing, selectedAddress, defaultAddress, images, description, condition, title, Enabled, Negotiable, createProduct, quantity }) => {
    const [preview, setPreview] = useState(false);
    const openPreview = () => {
        setPreview(true)
    };
    return (
        <>
            <PreviewModal
                open={preview}
                onClose={() => {
                    setPreview(false);
                }}
                fields={fields}
                pricing={pricing}
                defaultAddress={defaultAddress}
                selectedAddress={selectedAddress}
                images={images}
                description={description}
                condition={condition}
                title={title}
                Enabled={Enabled}
                Negotiable={Negotiable}
                createProduct={createProduct}
                quantity={quantity}
            />
            <div className="ViewDetails">
                <button className="VDBut" onClick={() => setPreview(true)}>View Details</button>
            </div>
        </>
    )
}
export default ViewDetails