import React, { useState } from "react";
import styles from "./ContactUs.module.scss";
import Layout from "../../common/components/Layouts/Layout";
import Contact from "../../assests/svg/ContactUs.svg";
import { ReactComponent as Call } from "../../assests/svg/call.svg";
import { ReactComponent as Chat } from "../../assests/svg/chat.svg";
import { ReactComponent as WhatsApp } from "../../assests/svg/whatsapp.svg";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { PostContactUsForm } from "../../store/actions/userActions";
import ContactUsMessageSuccess from "../../common/components/PostSuccess/ContactUsMessageSuccess";
import { Helmet } from "react-helmet";
import { GetAdminUID } from "../../store/actions/AdminChat";
import { useEffect } from "react";
import { db } from "../../firebase";
import { get, ref, update, runTransaction, push, set, increment } from 'firebase/database';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { GetUser } from "../../store/actions/userActions";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ContactUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.isContact) {
      const pageName = "Contact us"; // Replace this with the actual page name
      const pageUrl = "/contact-us"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Contact us"; // Replace this with the actual page name
      const pageUrl = "/contact-us"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, []);
  const [success, setSuccess] = useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    comment: ''
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.comment) {
      // Display a toast for missing required fields
      toast.error('Please fill all the required fields.');
      return; // Prevent form submission
    }
    try {
      const response = await dispatch(PostContactUsForm(formData));
      if (response) {
        setSuccess(true)
        setFormData({
          name: "",
          email: "",
          comment: ""
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }
  const uid = localStorage.getItem('uid')
  const chatWithCustomer = async () => {
    const chatlistRef = ref(db, 'chatlist');
    const snapshot = await get(chatlistRef);
    const roomId = uuidv4();
    const response = await dispatch(GetUser(uid));
    setUserProfile(response.data);
    const responseUID = await dispatch(GetAdminUID())
    const admin = await dispatch(GetUser(responseUID && responseUID.userWithMinChats))
    if (snapshot.exists()) {
      const chatlistData = snapshot.val();
      if (!chatlistData[uid]?.[admin && admin.data?.user?._id]) {
        await update(ref(db, `chatlist/${uid}/${admin && admin.data?.user?._id}`), {
          email: admin && admin.data?.user?.email,
          id: admin && admin.data?.user?._id,
          image: admin && admin.data?.user?.imageUrl,
          name: admin && admin.data?.user?.username,
          roomId: roomId
        });
      }
      if (!chatlistData[admin && admin.data?.user?._id]?.[uid]) {
        await update(ref(db, `chatlist/${admin && admin.data?.user?._id}/${uid}`), {
          email: response.data?.user?.email,
          id: response.data?.user?._id,
          image: response.data?.user?.imageUrl,
          name: response.data?.user?.username,
          roomId: roomId
        });
      }
    }
    const openRoomIdref = ref(db, `chatlist/${uid}/${admin.data?.user?._id}/roomId`);
    const openRoomIdSnapshot = await get(openRoomIdref);
    const openRoomId = openRoomIdSnapshot.val();
    navigate(`/chat/${openRoomId}`)
  };
  function openWhatsAppConversation() {
    const sellerPhoneNumber = '+9613034767'; // Replace with the seller's phone number
    const currentUrl = window.location.href;
    const text = `Hello La7i27alak Support Team!`;
    const encodedText = encodeURIComponent(text);
    const url = `https://wa.me/${sellerPhoneNumber}?text=${encodedText}`;
    window.location.href = url;
  }
  function initiatePhoneCall() {
    const phoneNumber = '+9613034767'; // Replace with the phone number you want to call
    const url = `tel:${phoneNumber}`;
    window.location.href = url;
  }
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name='Contact Us' content="Contact Us" />
        <meta
          name="keywords"
          content={`Contact us, contact la7i27alak, help la7i27alak, more info la7i27alak, more info, info, contact, support, la7i27alak support, support la7i27alak, product, service, la7i27alak`}
        />
      </Helmet>
      <Layout title={"Contact Us"}>
        <div className={styles.container}>
          <div className={styles.img_container}>
            <img src={Contact} alt="contact us" />
            <div className={styles.button_container}>
              <div onClick={initiatePhoneCall} className={`${styles.btn} ${darkMode ? styles.btnDark : ''}`}>
                <Call className={styles.btn_icon} />
                Call Us
              </div>
              <div className={`${styles.btn} ${darkMode ? styles.btnDark : ''}`} onClick={chatWithCustomer} >
                <Chat className={styles.btn_icon} />
                Chat
              </div>
              <div onClick={openWhatsAppConversation} className={`${styles.btn} ${darkMode ? styles.btnDark : ''}`}>
                <WhatsApp className={styles.btn_icon} />
                Whatsapp
              </div>
            </div>
          </div>
          <div className={styles.form_section}>
            <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Quick Contact</h1>
            <div className={`${styles.form_container} ${darkMode ? styles.form_containerDark : ''}`}>
              <div className={styles.wrapper}>
                <form onSubmit={handleSubmit}>
                  <div className={styles.input_container}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Username"
                      className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <AiOutlineUser className={styles.input_icon} />
                  </div>
                  <div className={styles.input_container}>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <AiOutlineMail className={styles.input_icon} />
                  </div>
                  <textarea
                    className={`${styles.text_area} ${darkMode ? styles.text_areaDark : ''}`}
                    name="comment"
                    placeholder="Enter your message here"
                    value={formData.comment}
                    onChange={handleChange}
                  />
                  <button type="submit" className={`${styles.send} ${darkMode ? styles.sendDark : ''}`} onClick={handleSubmit}>
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ContactUsMessageSuccess open={success} onClose={() => setSuccess(false)} />
      </Layout>
    </>
  );
};
export default ContactUs;
