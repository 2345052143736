import React, { useEffect, useState } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./PaymentPage.module.scss";
import { AiOutlinePlus } from "react-icons/ai";
import MasterCard from "../../assests/svg/master card img.svg";
import VisaCard from "../../assests/svg/visa image.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  createCustomerStripe,
  defaultCustomerStripe,
  deleteCustomerStripe,
  getCustomerStripe,
  getDefaultCard,
  listCustomerCards,
  updateDefaultCardHandler,
} from "../../store/actions/paymentActions";
import Cards from "react-credit-cards";
import {
  CARDS_LIST_RESET,
  CREATE_CUSTOMER_RESET,
  DEFAULT_CARD_RESET,
  UPDATE_DEFAULT_CARD_RESET,
} from "../../store/constants/paymentConstants";
import "react-credit-cards/es/styles-compiled.css";
import Loader from "../../common/components/Loader/Loader";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import { Helmet } from "react-helmet";
import { id } from "date-fns/locale";
import { DefaultCustomerReducer } from "../../store/reducers/paymentReducers";
const PaymentPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.isPayment) {
      const pageName = "Payment page"; // Replace this with the actual page name
      const pageUrl = "/user/payment"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Payment page"; // Replace this with the actual page name
      const pageUrl = "/user/payment"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, []);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userById = useSelector((state) => state.userById);
  const { user } = userById;
  const getCustomerCards = useSelector((state) => state.getCustomerCards);
  const { loading, cards, error } = getCustomerCards;
  const defaultCard = useSelector((state) => state.defaultCard);
  const { card_id } = defaultCard;
  const updateDefaultCard = useSelector((state) => state.updateDefaultCard);
  const { success } = updateDefaultCard;
  const [isDefault, setIsDefault] = useState("");
  useEffect(() => {
    dispatch({ type: CARDS_LIST_RESET });
    dispatch({ type: DEFAULT_CARD_RESET });
    dispatch({ type: UPDATE_DEFAULT_CARD_RESET });
    if (userInfo === null) {
      navigate("/");
    } else if (user !== null) {
      dispatch(listCustomerCards());
      dispatch(getDefaultCard());
    }
  }, [navigate, userInfo, dispatch, user, success]);
  useEffect(() => {
    dispatch(getCustomerStripe());
  }, [dispatch]);
  const cardList = useSelector((state) => state.getCustomer?.getCards?.data?.paymentMethods);
  const delLoad = useSelector((state) => state.delCustomer?.loading);
   const cardId = cardList && cardList.length > 0 ? cardList[0].id : null;
  const [selectedCardId, setSelectedCardId] = useState(null);
  const handleDelete = async (cardId) => {
    const response = await dispatch(deleteCustomerStripe(cardId))
    dispatch(getCustomerStripe())
  };
  const makeCardDefault = async (cardId) => {
    const response = await dispatch(defaultCustomerStripe(cardId))
    dispatch(getCustomerStripe())
  };
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <>
      <Helmet>
        <title>Payment</title>
        <meta name='Payment' content="Payment" />
      </Helmet>
      <Layout title={"Payment Method"}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            {loading ? (
              <div className="center">
                <Loader />
              </div>
            ) : error ? (
              <div className="center">
                <ErrorMessage>{error}</ErrorMessage>
              </div>
            ) : (
              <div>
                <div className={styles.flex}>
                  {cardList && cardList.map((card) => (
                    <div key={card.id}>
                      <Cards
                        expiry={`${card.card.exp_month.toString().padStart(2, '0')}${card.card.exp_year}`}
                        focused={false}
                        name={card.billing_details.name}
                        number={`............${card.card.last4}`}
                        // number={5555555555554444}
                        preview={true}
                        issuer={card.card.brand}
                      />
                      <button className={
                        delLoad && selectedCardId === card.id
                          ? `${styles.removeload} ${darkMode ? styles.removeDark : ''}`
                          : `${styles.remove} ${darkMode ? styles.removeDark : ''}`
                      }
                        onClick={() => {
                          setSelectedCardId(card.id);
                          handleDelete(card.id);
                        }}
                        disabled={delLoad}
                      >{delLoad && selectedCardId === card.id ? 'Removing...' : 'Remove Card'}</button>
                      <div className={styles.image_container}>
                        <div className={styles.input_container}>
                          <input
                            type="radio"
                            name="card"
                            id={`radio${card.id}`}
                            onClick={() => {
                              makeCardDefault(card.id)
                            }}
                            checked={card && card.isDefault}
                            disabled={card.isDefault}
                          />
                          <label htmlFor={`radio${card.id}`} className={`${styles.text} ${darkMode ? styles.textDark : ''}`}>
                            Use as default payment method
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                  {!cardList && null}
                </div>
              </div>
            )}
          </div>
          <button
            className={`${styles.add_btn} ${darkMode ? styles.add_btnDark : ''}`}
            onClick={() => navigate("/user/payment/add-card")}
          >
            <AiOutlinePlus className={styles.icon} /> Add Card
          </button>
        </div>
      </Layout>
    </>
  );
};
export default PaymentPage;
