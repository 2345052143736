import React, { useEffect } from "react";
import styles from "./SellPage.module.scss";
import CurrentPath from "../../../../common/components/CurrentPath/CurrentPath";
import Search from "../../../../common/components/Search/Search";
import Header from "../../../../common/components/Header/Header";
import Footer from "../../../../common/components/Footer/Footer";
import Card from "./Card/Card";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
const FeatureProducts = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const pageName = "Help feature product"; // Replace this with the actual page name
    const pageUrl = "/help/feature-products"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <>
      <Header />
      <section className={`${styles.sell_section} ${darkMode ? styles.sell_sectionDark : ''}`}>
        <CurrentPath />
        <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>How can we help you?</h1>
        {/* <div className={styles.search_container}>
          <Search />
        </div> */}
        <div className={`${styles.how_to_sell} ${darkMode ? styles.how_to_sellDark : ''}`}>
          <h3 className={styles.how_title}>How To Feature a Product?</h3>
          <div className={styles.card_container}>
            <Card title={'Create a Profile'} text={'Sign up or log in to access your account.'} step={'1'} />
            <Card title={'Sell a Product'} text={'List your product in relevant categories to showcase it.'} step={'2'} />
            <Card title={`Navigate to "My Offers"`} text={`Find the 'My Offers' section in your profile dropdown menu.`} step={'3'} />
            <Card title={`Click "Sell Faster"`} text={`Select 'Sell Faster' to feature your product and increase visibility.`} step={'4'} />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default FeatureProducts;
