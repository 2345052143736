import {
    GET_BOUGHT_HISTORY_FAIL,
    GET_BOUGHT_HISTORY_REQUEST,
    GET_BOUGHT_HISTORY_SUCCESS
} from "../constants/userConstants";
const initialState = {
    BoughtHistory: [],
    error: null,
    loading: false,
};
export const GetBoughtHistory = (state = initialState, action) => {
    switch (action.type) {
        case GET_BOUGHT_HISTORY_REQUEST:
            return {
                ...state,
                BoughtHistory: action.payload,
                error: null,
                loading: true,
            };
        case GET_BOUGHT_HISTORY_SUCCESS:
            return {
                ...state,
                BoughtHistory: action.payload,
                error: null,
                loading: false,
            };
        case GET_BOUGHT_HISTORY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
