export const UPLOAD_PROFILE_PICUTRE_REQUEST = "UPLOAD_PROFILE_PICUTRE_REQUEST";
export const UPLOAD_PROFILE_PICUTRE_SUCCESS = "UPLOAD_PROFILE_PICUTRE_SUCCESS";
export const UPLOAD_PROFILE_PICUTRE_FAIL = "UPLOAD_PROFILE_PICUTRE_FAIL";
export const UPLOAD_PROFILE_PICUTRE_RESET = "UPLOAD_PROFILE_PICUTRE_RESET";
export const GET_USER_BY_ID_REQUEST = "GET_USER_BY_ID_REQUEST";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAIL = "GET_USER_BY_ID_FAIL";
export const USER_RESET = "USER_RESET";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";
export const GET_USERCARD_BY_ID_REQUEST = "GET_USERCARD_BY_ID_REQUEST";
export const GET_USERCARD_BY_ID_SUCCESS = "GET_USERCARD_BY_ID_SUCCESS";
export const GET_USERCARD_BY_ID_FAIL = "GET_USERCARD_BY_ID_FAIL";
export const USERCARD_RESET = "USERCARD_RESET";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";
// when you visit a user
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_BY_ID_FAIL";
export const GET_USER_RESET = "GET_USER_RESET";
export const GET_USER_CATEGORY_SUCCESS = "GET_USER_CATEGORY_SUCCESS";
export const GET_USER_CATEGORY_FAIL = "GET_USER_CATEGORY_FAIL";
export const GET_PRODUCT_PER_USER_SUCCESS = "GET_PRODUCT_PER_USER_SUCCESS";
export const GET_PRODUCT_PER_USER_FAIL = "GET_PRODUCT_PER_USER_FAIL";
export const FOLLOW_USER_SUCCESS = "FOLLOW_USER_SUCCESS";
export const FOLLOW_USER_FAIL = "FOLLOW_USER_FAIL";
export const IS_BLOCKED_SUCCESS = "IS_BLOCKED_SUCCESS";
export const IS_BLOCKED_FAIL = "IS_BLOCKED_FAIL";
export const UNFOLLOW_USER_SUCCESS = "UNFOLLOW_USER_SUCCESS";
export const UNFOLLOW_USER_FAIL = "UNFOLLOW_USER_FAIL";
export const GET_USER_FOLLOWING_SUCCESS = "GET_USER_FOLLOWING_SUCCESS";
export const GET_USER_FOLLOWING_FAIL = "GET_USER_FOLLOWING_FAIL";
export const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS";
export const ADD_REVIEW_FAIL = "ADD_REVIEW_FAIL";
export const GET_REVIEW_BY_UID_SUCCESS = "GET_REVIEW_BY_UID_SUCCESS";
export const GET_REVIEW_BY_UID_FAIL = "GET_REVIEW_BY_UID_FAIL";
export const POST_REPORT_SUCCESS = "POST_REPORT_SUCCESS";
export const POST_REPORT_FAIL = "POST_REPORT_FAIL";
export const POST_BLOCK_SUCCESS = "POST_BLOCK_SUCCESS";
export const POST_BLOCK_FAIL = "POST_BLOCK_FAIL";
export const POST_CONTACT_US_SUCCESS = "POST_CONTACT_US_SUCCESS";
export const POST_CONTACT_US_FAIL = "POST_CONTACT_US_FAIL";
export const GET_CURRENT_LOCATION_SUCCESS = "GET_CURRENT_LOCATION_SUCCESS";
export const GET_CURRENT_LOCATION_FAIL = "GET_CURRENT_LOCATION_FAIL";
export const GET_AVERAGE_REVIEW_SUCCESS = "GET_AVERAGE_REVIEW_SUCCESS";
export const GET_AVERAGE_REVIEW_FAIL = "GET_AVERAGE_REVIEW_FAIL";
export const GET_BOUGHT_HISTORY_SUCCESS = "GET_BOUGHT_HISTORY_SUCCESS";
export const GET_BOUGHT_HISTORY_REQUEST = "GET_BOUGHT_HISTORY_REQUEST";
export const GET_BOUGHT_HISTORY_FAIL = "GET_BOUGHT_HISTORY_FAIL";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL";
export const GET_BLOCKED_USERS_SUCCESS = "GET_BLOCKED_USERS_SUCCESS";
export const GET_BLOCKED_USERS_FAIL = "GET_BLOCKED_USERS_FAIL";
export const UNBLOCK_USER_SUCCESS = "UNBLOCK_USER_SUCCESS";
export const UNBLOCK_USER_FAIL = "UNBLOCK_USER_FAIL";
export const RATE_EXPERIENCE_SUCCESS = "RATE_EXPERIENCE_SUCCESS";
export const RATE_EXPERIENCE_FAIL = "RATE_EXPERIENCE_FAIL";
export const RATE_EXPERIENCE_REQUEST = "RATE_EXPERIENCE_REQUEST";
export const POST_UNBLOCK_SUCCESS = "POST_UNBLOCK_SUCCESS";
export const POST_UNBLOCK_FAIL = "POST_UNBLOCK_FAIL";
export const SEND_MESSAGE_NOTIFICATION_SUCCESS = "SEND_MESSAGE_NOTIFICATION_SUCCESS";
export const SEND_MESSAGE_NOTIFICATION_FAIL = "SEND_MESSAGE_NOTIFICATION_FAIL";
export const GET_ALL_CONTACT_US_SUCCESS = "GET_ALL_CONTACT_US_SUCCESS";
export const GET_ALL_CONTACT_US_FAIL = "GET_ALL_CONTACT_US_FAIL";
export const GET_PAYMENT_AMOUNTS_SUCCESS = "GET_PAYMENT_AMOUNTS_SUCCESS";
export const GET_PAYMENT_AMOUNTS_FAIL = "GET_PAYMENT_AMOUNTS_FAIL";
export const GET_PAYMENT_HISTORY_REQUEST = "GET_PAYMENT_HISTORY_REQUEST";
export const GET_PAYMENT_HISTORY_SUCCESS = "GET_PAYMENT_HISTORY_SUCCESS";
export const GET_PAYMENT_HISTORY_FAIL = "GET_PAYMENT_HISTORY_FAIL";

export const GET_PRODUCT_PER_USER_ID_SUCCESS = "GET_PRODUCT_PER_USER_ID_SUCCESS";
export const GET_PRODUCT_PER_USER_ID_FAIL = "GET_PRODUCT_PER_USER_ID_FAIL";


