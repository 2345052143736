import React, { useState, useEffect } from "react";
import styles from "../../ProductDescriptionPage/components/ImageComponent/ImageComponent.module.scss";
import { AiOutlineEye } from "react-icons/ai";
import { AiFillHeart, AiOutlineHeart, AiFillBell } from "react-icons/ai";
import { ReactComponent as WhatsApp } from "../../../assests/svg/whatsapp green.svg";
import { ReactComponent as FB } from "../../../assests/svg/facebook.svg";
import { MdListAlt } from "react-icons/md";
import { AiOutlineLink } from "react-icons/ai";
import { useSelector } from "react-redux";
import { AddWishlist, RemoveWishList, GetWishList, GetWishlistSuccess } from "../../../store/actions/whishlistActions";
import { useDispatch } from "react-redux";
import Modal from "../../../common/components/Modal/Modal";
import ConfirmModal from "../../../common/components/ConfirmModal/ConfirmModal";
import Loader from "../../../common/components/Loader/Loader";
import ErrorMessage from "../../../common/components/ErrorMessage/ErrorMessage";
import { GetServicesWishlist } from "../../../store/actions/servicesActions";
import { AddServiceToWishlist } from "../../../store/actions/whishlistActions";
import { RemoveServiceWishList } from "../../../store/actions/servicesActions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ImageComponentForPreview = ({ featured, images, images1, views, waiting, likes, id, isService, createProduct }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.userLogin);
    const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
    useEffect(() => {
        setLoggedInUser(user ? true : false);
    }, [user]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    const ServicesWishlist = useSelector((state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList)
    const wishList = useSelector((state) => state.Wishlist?.Wishlist?.data?.wishList)
    useEffect(() => {
        if (isService) {
            dispatch(GetServicesWishlist())
        } else {
            dispatch(GetWishList())
        }
    }, [dispatch, id, isService]);
    const whishlistArray = useSelector((state) => state.whishlistArray);
    const { whishlistID, error, loading } = whishlistArray;
    const removeFromWishList = useSelector((state) => state.removeFromWishList);
    const {
        success,
        error: errorRemove,
        loading: loadingRemove,
    } = removeFromWishList
    const isProductInWishlist = (wishList ?? []).some(product => product._id === id) || (ServicesWishlist ?? []).some(product => product._id === id);
    const handleHeartClick = async () => {
        if (loggedInUser) {
            if (isProductInWishlist) {
                setIsOpen(true);
            } else {
                if (isService) {
                    dispatch(AddServiceToWishlist(id))
                        .then(() => {
                            const updatedWishList = wishList ? [...wishList, { _id: id }] : [{ _id: id }];
                            dispatch(GetServicesWishlist())
                        })
                        .catch((error) => {
                            // Handle the error here, if necessary
                        });
                } else {
                    const formData = new FormData();
                    formData.append("productId", id);
                    const response = await dispatch(AddWishlist(formData))
                    if (response) {
                        dispatch(GetWishList())
                    }
                }
            }
        } else {
            toast.error('Please login first.')
            setIsOpenModal(true);
        }
    };
    const [mainImg, setMainImg] = useState(images && images[0]);
    const [otherImgs, setOtherImgs] = useState(
        images && images.filter(function (el) {
            return el !== mainImg;
        })
    );
    {/*const [mainImg1, setMainImg1] = useState(images1 && images1[0]?.name);
    const [otherImgs1, setOtherImgs1] = useState(
        images1 && images1.slice(1).map((file) => file.name)
    );
    useEffect(() => {
        if (images1) {
            const imageUrls = images1.map((file) => URL.createObjectURL(file));
            setMainImg1(imageUrls[0]);
            setOtherImgs1(imageUrls.slice(1));
        }
    }, [images1]);*/}
    const handleWhatsAppClick = () => {
        const currentUrl = window.location.href;
        const text = `Check out this link: ${currentUrl}`;
        const encodedText = encodeURIComponent(text);
        const url = `https://api.whatsapp.com/send?text=${encodedText}`;
        window.open(url, '_blank');
    };
    const handleFBClick = () => {
        const currentUrl = encodeURIComponent(window.location.href);
        const text = `Check out this link: ${currentUrl}`;
        const encodedText = encodeURIComponent(text);
        const url = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}&quote=${encodedText}`;
        const encodedUrl = encodeURIComponent(url);
        const finalUrl = `https://www.facebook.com/dialog/share?app_id=1405541430272248&display=popup&href=${encodedUrl}`;
        window.open(finalUrl, '_blank');
    };
    {/* const imgHandler = (index) => {
        if (createProduct) {
            let temp = mainImg1;
            setMainImg1(otherImgs[index]);
            setOtherImgs1((prevState) => prevState.filter((img, i) => i != index));
            setOtherImgs1((prevState) => [...prevState, temp]);
        } else {
            let temp = mainImg;
            setMainImg(otherImgs[index]);
            setOtherImgs((prevState) => prevState.filter((img, i) => i != index));
            setOtherImgs((prevState) => [...prevState, temp]);
        }
    };*/}
    const imgHandler = (index) => {
        let temp = mainImg;
        setMainImg(otherImgs[index]);
        setOtherImgs((prevState) => prevState.filter((img, i) => i != index));
        setOtherImgs((prevState) => [...prevState, temp]);
    }
    const [isCopied, setIsCopied] = useState(false);
    const handleCopyLink = () => {
        const currentUrl = window.location.href;
        const input = document.createElement('input');
        input.setAttribute('value', currentUrl);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };
    return (
        <>
            <Modal
                open={isOpenModal}
                closeHandler={() => setIsOpenModal(false)}
            // page={"login"}
            />
            <div className={styles.images_container}>
                <div className={styles.big_image_container}>
                    {isProductInWishlist ? (
                        <AiFillHeart className={styles.wishlist} />
                    ) : (
                        <AiOutlineHeart className={styles.wishlist} />
                    )}
                    {featured && <div className={styles.featured}>Featured</div>}
                    {createProduct ? (
                        <img
                            src={mainImg}
                            alt="product"
                            className={styles.image}
                        />
                    ) : (
                        <img
                            src={
                                mainImg
                                    ? mainImg
                                    : "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=webp&v=1530129081"
                            }
                            alt="product"
                            className={styles.image}
                        />
                    )}
                    <div className={`${styles.image_details} ${darkMode ? styles.image_detailsDark : ''}`}>
                        <p className={styles.image_details_text}>
                            <AiOutlineEye className={styles.icon} />
                            {views} Views
                        </p>
                        <p className={styles.image_details_text}>
                            <MdListAlt className={styles.icon} /> {waiting} Waiting List
                        </p>
                        <p className={styles.image_details_text}>
                            <AiOutlineHeart className={styles.icon} />
                            {likes} Likes
                        </p>
                    </div>
                    <div className={`${styles.social_media_container} ${darkMode ? styles.social_media_containerDark : ''}`}>
                        Share: <WhatsApp className={styles.social} />{" "}
                        <FB className={styles.social} />{" "}
                        <AiOutlineLink className={styles.social} />{" "}
                    </div>
                    <div className={styles.copied}>
                        {isCopied &&
                            <p className={styles.copiedp}>Copied</p>
                        }
                    </div>
                </div>
                {/*<div className={styles.small_images_container}>
                    {createProduct ? (
                        otherImgs1 && otherImgs1.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt=""
                                className={styles.small_image}
                                onClick={() => imgHandler(index)}
                            />
                        ))
                    ) : (
                        otherImgs && otherImgs.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt=""
                                className={styles.small_image}
                                onClick={() => imgHandler(index)}
                            />
                        ))
                    )}
                        </div>*/}
                <div className={styles.small_images_container}>
                    {otherImgs && otherImgs.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt=""
                            className={styles.small_image}
                            onClick={() => imgHandler(index)}
                        />
                    ))}
                </div>
            </div>
            <ConfirmModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <h1 className={styles.confirm_title}>Confirmation</h1>
                <p className={styles.confirm_message}>
                    Are you sure do want to remove this product from your wishlist?
                </p>
                {loadingRemove ? (
                    <div className="center">
                        <Loader />{" "}
                    </div>
                ) : errorRemove ? (
                    <div className="center">
                        <ErrorMessage>{error}</ErrorMessage>
                    </div>
                ) : (
                    <div className={styles.buttons_container}>
                        <button className={styles.cancel} onClick={() => setIsOpen(false)}>
                            Cancel
                        </button>
                        <button
                            className={styles.confirm}
                            onClick={() => {
                                if (isService) {
                                    dispatch(RemoveServiceWishList(id)).then(() => setIsOpen(false))
                                    dispatch(GetServicesWishlist())
                                } else {
                                    const formData = new FormData();
                                    formData.append("productId", id);
                                    dispatch(RemoveWishList(formData)).then(() => {
                                        const updatedWishList = wishList.filter(product => product._id !== id);
                                    }).then(() => setIsOpen(false));
                                    dispatch(GetWishList())
                                }
                            }}
                        >
                            Confirm
                        </button>
                    </div>
                )}
            </ConfirmModal>
        </>
    );
};
export default ImageComponentForPreview;