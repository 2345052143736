import React from "react";
import styles from "./ComingModal.module.scss";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ReactDom from "react-dom";
import Card from "../Card/Card";
import { ReactComponent as Coming } from "../../../../assests/svg/coming.svg";
import { ReactComponent as ComingSure } from "../../../../assests/svg/coming sure.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const ComingModal = ({ open, closeHandler, coming, image, featured, price, name, date, condition, id, location }) => {
  const  navigate=useNavigate()
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}>
        <div className={`${styles.modal_styles} ${darkMode? styles.modal_stylesDark: ''}`}>
          <div className={styles.container}>
            <AiOutlineCloseCircle
              className={`${styles.icon} ${darkMode? styles.iconDark: ''}`}
              onClick={closeHandler}
            />
            <h1 className={`${styles.title} ${darkMode? styles.titleDark: ''}`}>Great! You're on the waiting list</h1>
            <Card
            image={image}
            featured={featured}
            price={price}
            name={name}
            date={date}
            condition={condition} 
            id={id}
            location={location}/>
            {coming ? (
              <h2 className={styles.coming}>
                <Coming className={styles.coming_icon} />I am coming now
              </h2>
            ) : (
              <h2 className={styles.coming}>
                <ComingSure className={styles.comingsure_icon} />I am coming now{" "}
                <span>for sure</span>
              </h2>
            )}
            <p className={`${styles.text} ${darkMode? styles.textDark: ''}`}>
              Now you need to wait for the seller to choose you as a candidate
              depending on your location, rating and more
            </p>
            {/* <p className={styles.waiting}>Waiting For: 00:01:31</p> */}
            <p className={styles.waiting_text}>
              In the meantime go checkout our large collection of other products
              and services
            </p>
            <div className={styles.btn_container}>
              <button className={styles.shopping} onClick={()=>navigate('/')}>Go Shopping</button>
              <button className={styles.cancel} onClick={closeHandler}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default ComingModal;
