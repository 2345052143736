import { SEARCH_AUTOCOMPLETE_ADMIN_SUCCESS,SEARCH_AUTOCOMPLETE_ADMIN_FAIL } from "../constants/productDetailsConstants";
const initialState = {
    autocompleteCOD: [],
    error: null,
    loading: false,
};
export const autocompleteCOD = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_AUTOCOMPLETE_ADMIN_SUCCESS:
            return {
                ...state,
                autocompleteCOD: action.payload,
                error: null,
                loading: false,
            };
        case SEARCH_AUTOCOMPLETE_ADMIN_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
