import React, { useState, useEffect, useRef } from "react";
import styles from "./NegotiateModal.module.scss";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ReactDom from "react-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { db } from "../../../../firebase";
import { get, ref, update, runTransaction, push, set, increment } from 'firebase/database';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { GetUser } from "../../../../store/actions/userActions";
import { useNavigate } from "react-router-dom";
const NegotiateModal = ({ open, closeHandler, image, price, name, userProduct, featured, condition, location, id, date, isDiscount, percentage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [unread, setUnread] = useState(0);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [inputPrice, setInputPrice] = useState("");
  const [inputText, setInputText] = useState("All I have in my budget is ")
  const [ConvoRoomID, setConvoRoomId] = useState(null);
  const [userProfile, setUserProfile] = useState([]);
  const [err, seterr] = useState('');
  const [productPrice, setProductPrice] = useState('')
  const [isConvoRoomIdUpdated, setIsConvoRoomIdUpdated] = useState(false)
  const specificUserId = localStorage.getItem('uid')
  const [loading, setLoading] = useState(false)
  const buttonRef = useRef(null);

  const handleInputPriceChange = (event) => {
    setInputPrice(event.target.value);
  };
  const handleInputTextChange = (event) => {
    setInputText(event.target.value);
  };
  const handleinpfocus = () => {
    seterr('')
  }
  useEffect(() => {
    const updateProductPrice = async () => {
      try {
        if (isDiscount) {
          await setProductPrice(price - (price * percentage / 100));
        } else {
          await setProductPrice(price);
        }
      } catch (error) {
        console.error('Error updating product price:', error);
      }
    };
    updateProductPrice();
  }, [isDiscount]);
  const sendOfferToCustomer = async (customerId) => {
    const chatlistRef = ref(db, 'chatlist');
    const snapshot = await get(chatlistRef);
    const roomId = uuidv4();
    const response = await dispatch(GetUser(specificUserId));
    setUserProfile(response.data);

    if (snapshot.exists()) {
      const chatlistData = snapshot.val();
      if (!chatlistData[specificUserId]?.[customerId._id]) {
        await update(ref(db, `chatlist/${specificUserId}/${customerId._id}`), {
          email: customerId.email,
          id: customerId._id,
          image: customerId.imageUrl,
          name: customerId.username,
          roomId: roomId
        });
      }
      if (!chatlistData[customerId._id]?.[specificUserId]) {
        await update(ref(db, `chatlist/${customerId._id}/${specificUserId}`), {
          email: response.data?.email,
          id: response.data?._id,
          image: response.data?.imageUrl,
          name: response.data?.username,
          roomId: roomId
        });
      }
    }
  };
  if (!userProduct) {
    return null; // or render a loading state/component
  }
  const sendSecondMessageLogic = async () => {
    if (inputPrice.trim() !== "" && inputText.trim() !== "" && inputPrice < productPrice) {
      const newMessage = {
        condition: condition,
        featured: featured,
        from: specificUserId,
        location: location,
        message: inputText + ' ' + inputPrice + ' $',
        msgType: 'object',
        newPrice: inputPrice,
        offerimage: image,
        offername: name,
        offerprice: productPrice,
        roomId: ConvoRoomID,
        sendTime: moment().format(),
        timestamp: moment().format('hh:mm'),
        to: userProduct._id,
        productId: id,
        date: date
      };
      const newReference = push(ref(db, '/messages/' + ConvoRoomID + '/'));
      newMessage.id = newReference.key;
      set(newReference, newMessage)
        .then(() => {
          let chatListupdate = {
            name: userProduct.username,
            id: userProduct._id,
            image: userProduct.imageUrl,
            lastMsg: inputText + inputPrice + " " + "$",
            roomId: ConvoRoomID,
            sendTime: moment().format(),
            timestamp: moment(newMessage.sendTime).format('hh:mm'),
            unread: unread
          };
          let chatListupdate2 = {
            name: userProfile.user?.username,
            id: userProfile.user?._id,
            image: userProfile.user?.imageUrl,
            lastMsg: inputText + inputPrice + " " + "$",
            roomId: ConvoRoomID,
            sendTime: newMessage.sendTime,
            timestamp: moment(newMessage.sendTime).format('hh:mm'),
            unread: unread + 1
          };
          const chatListRef = ref(
            db,
            '/chatlist/' +
            userProduct._id +
            '/' +
            specificUserId
          );
          update(chatListRef, { unread: increment(1) })
            .then(() => {
              // console.log('Incremented unread successfully.');
            })
            .catch((error) => {
              console.error('Error incrementing unread:', error);
            });
          const chatListRef1 = ref(
            db,
            '/chatlist/' +
            userProduct._id +
            '/' +
            specificUserId
          );
          update(chatListRef1, chatListupdate2)
            .then(() => {
              // console.log('Data updated.');
            })
            .catch((error) => {
              console.error('Error updating data:', error);
            });
          const chatListRef2 = ref(
            db,
            '/chatlist/' +
            specificUserId +
            '/' +
            userProduct._id
          );
          update(chatListRef2, chatListupdate)
            .then(() => {
              // console.log('Data updated.');
              closeHandler();
              navigate(`/chat/${ConvoRoomID}`)
            })
            .catch((error) => {
              console.error('Error updating data:', error);
            });
        })
        .catch((error) => {
          console.error('Error setting new message:', error);
        });
    }
  };
  const sendFirstMessageLogic = async () => {
    if (inputPrice.trim() !== "" && inputText.trim() !== "" && inputPrice < productPrice && userProduct) {
      const newMessage = {
        from: specificUserId,
        message: inputText + ' ' + inputPrice + ' $',
        msgType: 'text',
        roomId: ConvoRoomID,
        sendTime: moment().format(),
        timestamp: moment().format('hh:mm'),
        to: userProduct._id,
      };
      const newReference = push(ref(db, '/messages/' + ConvoRoomID + '/'));
      newMessage.id = newReference.key;
      try {
        await set(newReference, newMessage);
        const chatListupdate = {
          name: userProduct.username,
          id: userProduct._id,
          image: userProduct.imageUrl,
          lastMsg: inputText + ' ' + inputPrice + ' $',
          roomId: ConvoRoomID,
          sendTime: moment().format(),
          timestamp: moment(newMessage.sendTime).format('hh:mm'),
          unread: unread
        };
        const chatListupdate2 = {
          name: userProfile.user?.username,
          id: userProfile.user?._id,
          image: userProfile.user?.imageUrl,
          lastMsg: inputText + ' ' + inputPrice + ' $',
          roomId: ConvoRoomID,
          sendTime: newMessage.sendTime,
          timestamp: moment(newMessage.sendTime).format('hh:mm'),
          unread: unread
        };
        const chatListRef = ref(db, '/chatlist/' + userProduct._id + '/' + specificUserId);
        const chatListRef1 = ref(db, '/chatlist/' + specificUserId + '/' + userProduct._id);
        await Promise.all([
          update(chatListRef, { unread: increment(1) }),
          update(chatListRef1, chatListupdate2),
          update(chatListRef, chatListupdate)
        ]);

        await sendSecondMessageLogic();
      } catch (error) {
        console.error('Error setting new message:', error);
      }
    } else {
      seterr('Please enter a price, and ensure that the price you offer is less than the original price.')
      setLoading(false)
    }
  };
  const handleSendMessage = async () => {
    setLoading(true);
    if (specificUserId && userProduct) {
      await sendOfferToCustomer(userProduct).catch((error) => {
        // console.log('Error updating chatlist:', error);
      });
    } else {
      // console.log("error")
    }
    const userRef = ref(db, 'chatlist/' + specificUserId + '/' + userProduct._id);
    try {
      const snapshot = await get(userRef);
      const userData = snapshot.val();
      if (userData && userData.roomId) {
        await Promise.all([
          setConvoRoomId(userData.roomId),
          setIsConvoRoomIdUpdated(true)
        ]);
        await sendFirstMessageLogic();
      } else {
        // console.log('ConvoRoomId is not available.');
      }
      if (buttonRef.current) {
        buttonRef.current.click();
        buttonRef.current.click = function stop() { };
      }
    } catch (error) {
      setLoading(false)
      // console.log('Error fetching user data:', error);
    }
  };
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}>
        <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
          <div className={styles.container}>
            <AiOutlineCloseCircle
              className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`}
              onClick={closeHandler}
            />
            <div className={styles.image_container}>
              <img
                src={image}
                alt=""
                className={styles.image}
              />
              <div className={styles.details}>
                <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>
                  USD {isDiscount ? price - (price * percentage) / 100 : price}
                </h1>
                <p className={`${styles.text} ${darkMode ? styles.textDark : ''}`}>
                  {name}
                </p>
              </div>
            </div>
            <div className={styles.input_container}>
              <input
                type="number"
                placeholder="37.500"
                value={inputPrice}
                onChange={handleInputPriceChange}
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                onFocus={handleinpfocus}
              />
              <div className={`${styles.dollar} ${darkMode ? styles.dollarDark : ''}`}>$</div>
            </div>
            <div style={{ color: "red", fontSize: '10px', marginBottom: '0px' }}>{err}</div>
            <textarea
              className={`${styles.text_area} ${darkMode ? styles.text_areaDark : ''}`}
              placeholder="Type your message here"
              value={inputText}
              onChange={handleInputTextChange}
              disabled
            />
            <button ref={buttonRef} className={`${styles.send} ${loading ? styles.sendload : ''} ${darkMode ? styles.sendDark : ''}`} onClick={handleSendMessage}>Send Offer</button>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default NegotiateModal;
