import React, { useState, useEffect } from "react";
import styles from "../Sell Service Form Page/SellServiceForm.module.scss"
import LocationSection from "../Sell Service Form Page/LocationSection/LocationSection";
import Layout from "../../common/components/Layouts/Layout";
import Camera from "../../assests/images/camera.png";
import Switch from "react-switch";
import PostSuccess from "../../common/components/PostSuccess/PostSuccess";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useLocation } from 'react-router-dom';
import PhotosComponents from "../Sell Product Form Page/PhotosComponents/PhotosComponents";
import { useNavigate } from "react-router-dom";
import { GetMyServices } from "../../store/actions/servicesActions";
import { CreateService } from "../../store/actions/servicesActions";
import { Scrollbars } from 'react-custom-scrollbars';
import { GetSingleService } from "../../store/actions/servicesActions";
import moment from 'moment';
import {
    addServiceHandler,
    uploadImagesHandler,
    uploadMainImageHandler,
} from "../../store/actions/addServiceActions";
import { EditServiceById } from "../../store/actions/servicesActions";
import {
    ADD_SERVICE_RESET,
    UPLOAD_IMAGES_SERVICE_RESET,
    UPLOAD_MAIN_IMAGE_SERVICE_RESET,
} from "../../store/constants/addServiceContants";
import { getAddressByUser } from "../../store/actions/addAddressActions";
import AddressComponent from "../AddressPage/AddressComponent/AddressComponent";
import { AiOutlinePlusCircle, AiOutlineRight } from "react-icons/ai";
import { OfferServieValidationSchema } from "../Sell Product Form Page/index";
import { CreateProduct } from "../../store/actions/addProductActions";
import { useParams } from "react-router-dom";
import { GetTypeById } from "../../store/actions/servicesActions";
import MapModal from "../Sell Product Form Page/Properties/MapModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const EditService = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    const location = useLocation();
    const { serviceCatId } = location.state;
    useEffect(() => {
        const pageName = "Edit service"; // Replace this with the actual page name
        const pageUrl = "/edit-service"; // Replace this with the actual page URL
        dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }, []);
    const [service, setService] = useState([]);
    const [serviceTitle, setServiceTitle] = useState("")
    const [serviceDescription, setServiceDescription] = useState("")
    const [selectedAddress, setSelectedAddress] = useState(null)
    const [dynamicFieldsValues, setDynamicFieldsValues] = useState([]);
    const [deadlineTimer, setdeadlineTimer] = useState({ enabled: false, deadline: null });
    const [timer, setTimer] = useState(false)
    const [endTime, setEndTime] = useState('')
    const [typeOfService, setTypeOfService] = useState('')
    const [price, setPrice] = useState(null);
    const [newPrice, setNewPrice] = useState('');
    const [discount, setDiscount] = useState('');
    const [discountnState, setDiscountState] = useState(false);
    const discountObject = {
        enabled: true,
        percentage: discount
    }

    useEffect(() => {
        dispatch(GetSingleService(location.state?.id))
            .then((data) => {
                // console.log(data)
                setService(data);
                setServiceTitle(data.data?.service?.title)
                setServiceDescription(data.data?.service?.description)
                setPrice(data.data?.service?.pricing?.price)
                setSelectedAddress(data.data?.service?.serviceAddress)
                if (data?.data?.service?.discount?.enabled) {
                    setDiscountState(data?.data?.service?.discount?.enabled);
                    setDiscount(data?.data?.service?.discount?.percentage);
                    setNewPrice(data?.data?.service?.discount?.newPrice);
                  }
                setDynamicFieldsValues(data.data?.service?.fields)
                setTimer(data.data?.service?.timer?.enabled)
                if(data.data?.service?.timer?.deadline){
                    setEndTDate(new Date(data.data?.service?.timer?.deadline).toISOString().substring(0, 16));
                }
                setTypeOfService(data.data?.service?.typeOfService)
                const imageUrls = data.data?.service?.imageUrls;
                const nullCount = 16 - (imageUrls?.length || 0);
                const nullArray = new Array(nullCount).fill(null);
                const updatedPhotoStates = imageUrls ? [...imageUrls, ...nullArray] : new Array(16).fill(null);
                setPhotoStates(updatedPhotoStates);
            })
            .catch((error) => {
                // console.log(error);
            });
    }, [serviceCatId, dispatch]);
    const TypeArray = service ? Object.values(service) : [];
    const [openaddress, setOpenaddress] = useState(false);
    const fieldsArray = TypeArray.map(item => item.service.fields);
    const updatedFields = fieldsArray.flat().map((field) => {
        const matchingObj = dynamicFieldsValues && dynamicFieldsValues.find((item) => item.title === field.title);
        return { ...field, value: matchingObj ? matchingObj.value : null };
    });
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const [hoveredIndex, setHoveredIndex] = useState(-1);
    const userById = useSelector((state) => state.userById);
    const [isNegotiable, setIsNegotiable] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [startTDate, setStartTDate] = useState('');
    const [endTDate, setEndTDate] = useState('');
    const { user } = userById;
    const [priceErr, setPriceErr] = useState('');
    const address = useSelector((state) => state.address?.address);
    const navigate = useNavigate();
    const defaultAddress = address.find(address => address.isDefault);
    useEffect(() => {
        dispatch(getAddressByUser());
    }, [dispatch]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [dynamicConditionState, setdynamicConditionState] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [dynamicListState, setdynamicListState] = useState(null);
    function handleLocationClick() {
        setShowDropdown(!showDropdown);
    }
    const [selectedItem, setSelectedItem] = useState(null);
    function handleSelect(index, value) {
        setIsOpen(false);
        setSelectedItem(value)
    }
    const [activeBool, setActiveBool] = useState(0);
    const handleBoolClick = (index) => {
        setActiveBool(index);
    }
    function handleSelect(index, value) {
        setIsOpen(false);
        setSelectedItem(value)
    }
    function handleOptionClick(id) {
        const selectedAddress = address.find(add => add._id === id);
        setSelectedAddress(selectedAddress);
        setShowDropdown(false);
    }
    const handlePriceChange = (event) => {
        const inputValue = event.target.value;
        const newPrice = inputValue !== '' ? parseFloat(inputValue) : null;
        if (isNaN(newPrice) || newPrice === null) {
            setPrice(null);
            formik.setFieldValue('price', null);
            setPriceErr('Price is required')
        } else {
            setPrice(newPrice);
            formik.setFieldValue('price', newPrice);
            setPriceErr('')
        }
    };
    const [success, setSuccess] = useState(false);
    const currentDateInMillis = new Date().getTime();
    const formattedDate = new Date(currentDateInMillis).toISOString().slice(0, 16);
    const [fileUploaded, setFileUploaded] = useState("");
    const hiddenFileInput = React.useRef(null);
    const [photoStates, setPhotoStates] = useState(new Array(16).fill(null));
    const handleClick = (index) => {
        hiddenFileInputs[index].current.click();
    };
    const handleChange = (event, index) => {
        const file = event.target.files[0];
        const newPhotoStates = [...photoStates];
        newPhotoStates[index] = file;
        setPhotoStates(newPhotoStates);
        formik.setFieldValue(`file${index}`, file);
    };
    const hiddenFileInputs = photoStates.map(() => React.createRef());
    const [imagesUploaded, setImagesUploaded] = useState([]);
    const hiddenImageInput = React.useRef(null);
    const handleImageClick = (event) => {
        hiddenImageInput.current.click();
    };
    const handleImageChange = (event) => {
        setImagesUploaded((prevState) => [...prevState, event.target.files[0]]);
    };
    useEffect(() => {
        if (timer) {
            const dateObject = new Date(endTDate);
            const milliseconds = dateObject.getTime();
            setdeadlineTimer({ enabled: true, deadline: milliseconds });
        } else {
            setdeadlineTimer({ enabled: false, deadline: null });
        }
    }, [startTDate, endTDate, timer]);
    const [formData, setFormData] = useState({
        title: "",
        type: "",
        description: "",
        price: "",
    });
    const pricing = {
        price: parseFloat(price)
    };
    const jsonPricing = JSON.stringify(pricing, ['price']);
    const newphotoStates = photoStates.filter((item) => item !== null && item !== '');
    const isBackendURL = (url) => typeof url === 'string' && url.startsWith("https://storage.googleapis.com/");
    // Separate the URLs into two arrays
    const backendURLs = newphotoStates.filter(isBackendURL);
    const backendURLsString = backendURLs.join(",");
    const nonBackendURLs = newphotoStates.filter((url) => !isBackendURL(url));
    const backendURLsArray = Object.values(backendURLs);
    const formik = useFormik({
        initialValues: formData,
        onSubmit: (values) => {
            // console.log({ ...values, seller: user.id });
            // dispatch(addProductHandler(values));
            // setSuccess(true);
        },
        validationSchema: OfferServieValidationSchema,
    });
    const loading = useSelector(
        (state) => state.CreateService?.loading
    );
    const handleEditClick = () => {
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false);
    };
   
    const handleNewPriceChange = (e) => {
        const enteredPrice = parseInt(e.target.value);
        setNewPrice(enteredPrice);
        if (enteredPrice >= price) {
            setDiscount(0);
        } else {
            setDiscount((1 - enteredPrice / price) * 100);
        }
    };
    const handleDiscountChange = (e) => {
        const enteredDiscount = parseInt(e.target.value);
        setDiscount(enteredDiscount);
        setNewPrice(price * (1 - enteredDiscount / 100));
    };
    const handleOnButtonClick = () => {
        setDiscountState(true);
    };
    const handleOffButtonClick = () => {
        setDiscountState(false);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("title", serviceTitle);
            formData.append("description", serviceDescription)
            formData.append("pricing", jsonPricing)
            if (updatedFields) {
                formData.append("fields", JSON.stringify(updatedFields))
            }
            formData.append("timer", JSON.stringify(deadlineTimer))
            formData.append("isService", 1)
            formData.append("typeOfService", typeOfService)
            formData.append("imageUrls", JSON.stringify(backendURLsArray))
            for (const file of nonBackendURLs) {
                formData.append("images", file);
            }
            if (selectedLocation == null) {
                formData.append("serviceAddressId", defaultAddress._id)
            } else {
                formData.append("serviceAddressId", selectedLocation)
            }
            if (discountnState) {
                formData.append("discount", JSON.stringify(discountObject))
            }
            const response = await dispatch(EditServiceById(location.state?.id, formData));
            if (response) {
                setSuccess(true)
                dispatch(GetMyServices())
            }
        } catch (error) {
            // console.log(error);
        } 
    }
    const booleanFields = TypeArray && TypeArray.length > 0 && TypeArray[0].service?.fields?.filter((field) => field.type === "list");
    const numberOfBooleans = booleanFields.length;
    const [isOpenList, setIsOpenList] = useState(Array(numberOfBooleans).fill(false));
    const [selectedItems, setSelectedItems] = useState(Array(numberOfBooleans).fill(null));
    const handleDelete = (index) => {
        const updatedPhotoStates = [...photoStates];
        updatedPhotoStates[index] = null;
        setPhotoStates(updatedPhotoStates);
    };
    return (
        <>
            <MapModal
                open={openaddress}
                onClose={() => {
                    setOpenaddress(false);
                }}
            />
            <Layout title={"Offer Service Information"}>
                <form className={styles.wrapper} onSubmit={formik.handleSubmit}>
                    <div className={styles.title_container}>
                        <p className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>Service Title</p>
                        <div className={styles.input_container}>
                            <input
                                type="text"
                                className={`${styles.input}  ${darkMode ? styles.inputDark : ''} ${formik.errors.title && formik.touched.title
                                    ? styles.error_input
                                    : ""
                                    }`}
                                placeholder="Name of product and related info"
                                name="title"
                                onChange={(e) => setServiceTitle(e.target.value)}
                                onBlur={formik.handleBlur}
                                value={serviceTitle}
                            />
                            {formik.errors.serviceTitle && formik.touched.serviceTitle && (
                                <p className={styles.error_message}>{formik.errors.serviceTitle}</p>
                            )}
                        </div>
                    </div>
                    <div className={styles.desc_container}>
                        <p className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>Description Of Service</p>
                        <div className={styles.textarea_container}>
                            <textarea
                                className={`${styles.text_area} ${darkMode ? styles.text_areaDark : ''} ${formik.errors.description && formik.touched.description
                                    ? styles.error_input
                                    : ""
                                    }`}
                                placeholder="Tell us more about your product"
                                name="description"
                                onChange={(e) => setServiceDescription(e.target.value)}
                                onBlur={formik.handleBlur}
                                value={serviceDescription}
                            />
                            {formik.errors.serviceDescription && formik.touched.serviceDescription && (
                                <p className={styles.error_message}>
                                    {formik.errors.serviceDescription}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className={styles.condDisc}>
                            <div className={styles.discount_container}>
                                <p className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>Discount</p>
                                <div className={styles.switch_container}>
                                    <div
                                        className={`${styles.switch_btn} ${darkMode ? styles.switch_btnDark : ''} ${discountnState ? styles.active : ''}`}
                                        onClick={handleOnButtonClick}
                                    >
                                        ON
                                    </div>
                                    <div
                                        className={`${styles.switch_btn} ${darkMode ? styles.switch_btnDark : ''} ${!discountnState ? styles.active : ''}`}
                                        onClick={handleOffButtonClick}
                                    >
                                        OFF
                                    </div>
                                </div>
                            </div>
                       
                    </div>
                    <div className={styles.price_container}>
                        {discountnState ?
                            (<>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>Price</label>
                                    <div className={styles.input_container}>
                                        <input
                                            type="number"
                                            className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                                            placeholder="000,000"
                                            name="price"
                                            // onChange={(event) => handlePriceChange(event)}
                                            onChange={(e) => setPrice(e.target.value)}
                                            onBlur={formik.handleBlur}
                                            value={price}
                                            disabled={true}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                        <div className={`${styles.currency} ${darkMode ? styles.currencyDark : ''}`}>$</div>
                                    </div>
                                    <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>New Price</label>
                                    <div className={styles.input_container}>
                                        <input
                                            type="number"
                                            className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                                            placeholder="000,000"
                                            name="price"
                                            value={newPrice}
                                            onChange={handleNewPriceChange}
                                            onWheel={(e) => e.target.blur()}
                                        // onBlur={formik.handleBlur}
                                        />
                                        <div className={`${styles.currency} ${darkMode ? styles.currencyDark : ''}`}>$</div>
                                    </div>
                                    <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>Discount</label>
                                    <div className={styles.input_container}>
                                        <input
                                            type="number"
                                            className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                                            placeholder="000,000"
                                            onWheel={(e) => e.target.blur()}
                                            name="price"
                                            value={discount}
                                            onChange={handleDiscountChange}
                                        // onBlur={formik.handleBlur}
                                        />
                                        <div className={`${styles.currency} ${darkMode ? styles.currencyDark : ''}`}>%</div>
                                    </div>
                                </div>
                            </>
                            ) : (
                                <>
                                    <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>Price</label>
                                    <div className={styles.input_container}>
                                        <input
                                            type="number"
                                            className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                                            placeholder="000,000"
                                            onWheel={(e) => e.target.blur()}
                                            name="price"
                                            // onChange={(event) => handlePriceChange(event)}
                                            onChange={(e) => setPrice(e.target.value)}
                                            value={price}
                                        />
                                        <div className={`${styles.currency} ${darkMode ? styles.currencyDark : ''}`}>$</div>
                                    </div>
                                </>
                            )}
                    </div>
                    <div className={styles.line}></div>
                    {TypeArray.map((item) =>
                        item.service?.fields?.map((subCategory, index) => {
                            const renderField = (subCategory) => {
                                switch (subCategory.type.toLowerCase()) {
                                    case 'string':
                                        const fieldTitle = subCategory.title;
                                        const fieldValue = formik.values.dynamicFields?.[subCategory.title] || '';
                                        const fieldError = formik.errors[subCategory.title];
                                        const fieldTouched = formik.touched[subCategory.title];
                                        return (
                                            <div className={`${styles.fieldContainer}`}>
                                                <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>{fieldTitle}</label>
                                                <div className={styles.input_container}>
                                                    <input
                                                        type="text"
                                                        className={`${styles.inputString} ${darkMode ? styles.inputStringDark : ''} 
            ${fieldError && fieldTouched ? styles.error_input : ''}`}
                                                        placeholder={fieldTitle}
                                                        name={`dynamicFields.${fieldTitle}`}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            formik.setFieldValue(`dynamicFields.${fieldTitle}`, value);
                                                            const existingObjectIndex = dynamicFieldsValues.findIndex(obj => obj.title === fieldTitle);
                                                            if (existingObjectIndex !== -1) {
                                                                const updatedArray = [...dynamicFieldsValues];
                                                                updatedArray[existingObjectIndex].value = value;
                                                                setDynamicFieldsValues(updatedArray);
                                                            } else {
                                                                setDynamicFieldsValues([...dynamicFieldsValues, { title: fieldTitle, value }]);
                                                            }
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={subCategory.value}
                                                    />
                                                    {fieldError && fieldTouched && (
                                                        <p className={styles.error_message}>{fieldError}</p>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    case 'list':
                                        const toggleDropdown = (index) => {
                                            const updatedIsOpenList = [...isOpenList];
                                            updatedIsOpenList[index] = !updatedIsOpenList[index];
                                            setIsOpenList(updatedIsOpenList);
                                        };
                                        // Function to handle selecting an option in a specific list
                                        const handleSelect = (index, value) => {
                                            const updatedSelectedItems = [...selectedItems];
                                            updatedSelectedItems[index] = value;
                                            setSelectedItems(updatedSelectedItems);
                                            const updatedIsOpenList = [...isOpenList];
                                            updatedIsOpenList[index] = false;
                                            setIsOpenList(updatedIsOpenList);
                                        };
                                        return (
                                            <div className={`${styles.fieldContainer}`}>
                                                <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>
                                                    {subCategory.title}
                                                </label>
                                                <div className={styles.input_container}>
                                                    <div>
                                                        <button className={`${styles.listb} ${darkMode ? styles.listbdark : ''}`} onClick={() => {
                                                            toggleDropdown(index);
                                                        }}>
                                                            {selectedItems[index] ? selectedItems[index] : `${subCategory.possibleValues[subCategory.value]}`}
                                                        </button>
                                                        {isOpenList[index] && (
                                                            <div className={`${styles.drop} ${darkMode ? styles.dropdark : ''}`}>
                                                                <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={200}>
                                                                    {subCategory.possibleValues.map((value, valueIndex) => (
                                                                        <div
                                                                            key={valueIndex}
                                                                            className={`${styles.droplist} ${darkMode ? styles.droplistdark : ''}`}
                                                                            onClick={() => {
                                                                                setdynamicListState(valueIndex);
                                                                                const fieldName = subCategory.title;
                                                                                const title = subCategory.title;
                                                                                formik.setFieldValue(`dynamicFields.${fieldName}`, index);
                                                                                const existingObjectIndex = dynamicFieldsValues.findIndex(obj => obj.title === title);
                                                                                if (existingObjectIndex !== -1) {
                                                                                    const updatedArray = [...dynamicFieldsValues];
                                                                                    updatedArray[existingObjectIndex].value = valueIndex;
                                                                                    setDynamicFieldsValues(updatedArray);
                                                                                } else {
                                                                                    setDynamicFieldsValues([...dynamicFieldsValues, { title, value: valueIndex }]);
                                                                                }
                                                                                handleSelect(index, value)
                                                                            }}
                                                                            onMouseEnter={() => {
                                                                                setHoveredIndex(valueIndex);
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                setHoveredIndex(-1);
                                                                            }}
                                                                        >
                                                                            {value}
                                                                        </div>
                                                                    ))}
                                                                </Scrollbars>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {formik.errors[subCategory.title] && formik.touched[subCategory.title] && (
                                                        <p className={styles.error_message}>{formik.errors[subCategory.title]}</p>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    case 'number':
                                        return (
                                            <div className={`${styles.fieldContainer}`}>
                                                <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>{subCategory.title}</label>
                                                <div className={styles.input_container}>
                                                    <input
                                                        type="text"
                                                        className={`${styles.inputString} ${darkMode ? styles.inputStringDark : ''}
                              ${formik.errors[subCategory.title] && formik.touched[subCategory.title]
                                                                ? styles.error_input
                                                                : ""
                                                            }`}
                                                        placeholder={subCategory.title}
                                                        name={`dynamicFields.${subCategory.title}`}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            const fieldName = subCategory.title;
                                                            const title = subCategory.title;
                                                            formik.setFieldValue(`dynamicFields.${fieldName}`, value);
                                                            const existingObjectIndex = dynamicFieldsValues.findIndex(obj => obj.title === title);
                                                            if (existingObjectIndex !== -1) {
                                                                // If an object exists, update its value property
                                                                const updatedArray = [...dynamicFieldsValues];
                                                                updatedArray[existingObjectIndex].value = value;
                                                                setDynamicFieldsValues(updatedArray);
                                                            } else {
                                                                // If an object doesn't exist, add a new object to the array
                                                                setDynamicFieldsValues([...dynamicFieldsValues, { title, value }]);
                                                            }
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={subCategory.value}
                                                    />
                                                    {formik.errors[subCategory.title] && formik.touched[subCategory.title] && (
                                                        <p className={styles.error_message}>{formik.errors[subCategory.title]}</p>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    case 'boolean':
                                        return (
                                            <div className={styles.Boolean_container}>
                                                <p className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>{subCategory.title}</p>
                                                <div className={styles.switch_container}>
                                                    {subCategory.possibleValues.map((value, index) => (
                                                        <div
                                                            key={index}
                                                            className={`${styles.switch_btn} ${subCategory.value === index ? styles.active : ""
                                                                } ${darkMode ? styles.switch_btnDark : ''} ${darkMode ? styles.activeDark : ''}`}
                                                            onClick={() => {
                                                                setdynamicConditionState(index);
                                                                const fieldName = subCategory.title;
                                                                const title = subCategory.title;
                                                                formik.setFieldValue(`dynamicFields.${fieldName}`, index);
                                                                const existingObjectIndex = dynamicFieldsValues.findIndex(obj => obj.title === title);
                                                                if (existingObjectIndex !== -1) {
                                                                    const updatedArray = [...dynamicFieldsValues];
                                                                    updatedArray[existingObjectIndex].value = index;
                                                                    setDynamicFieldsValues(updatedArray);
                                                                } else {
                                                                    setDynamicFieldsValues([...dynamicFieldsValues, { title, value: index }]);
                                                                }
                                                                handleBoolClick(index);
                                                            }}
                                                        >
                                                            {value}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            };
                            return renderField(subCategory);
                        }
                        )
                    )}
                    <div className={styles.line2}></div>
                    <div className={styles.image_section}>
                        <p className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>Add Photos</p>
                        <div className={styles.wrapper}>
                            {photoStates.map((photoState, index) => (
                                <div className={`${styles.image_container} ${darkMode ? styles.image_containerDark : ''}`} onClick={() => handleClick(index)}>
                                    <img
                                        src={photoState ? ((typeof photoState === 'string' && photoState.startsWith('https://storage.googleapis.com/')) ? photoState : URL.createObjectURL(photoState)) : Camera}
                                        alt="camera"
                                        className={`${photoState ? styles.full_image : styles.image}`}
                                        file={formik.values.file}
                                    />
                                    {photoState && (
                                        <div className={styles.remove_button}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(index)
                                            }}
                                        >
                                            X
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        ref={hiddenFileInputs[index]}
                                        onChange={(event) => handleChange(event, index)}
                                        style={{ display: "none" }}
                                    />
                                    <input
                                        type="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        ref={hiddenFileInput[index]}
                                        onChange={handleChange}
                                        style={{ display: "none" }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.line}></div>
                    <p className={styles.label}>{selectedLocation || "Location"}</p>
                    <div className={styles.location_section}>
                        {address && address.length > 0 ? (
                            address
                                .filter((addr) => addr.isDefault)
                                .map((addr) => (
                                    <AddressComponent
                                        defaultAddress={true}
                                        title={addr.title}
                                        description={addr.description}
                                        threedots={false}
                                    />
                                ))
                        ) : (
                            <AddressComponent
                                defaultAddress={false}
                                title={"No Addresses"}
                                description={"You Can Add a Location"}
                                threedots={false}
                            />
                        )}
                        <div className={styles.vertical_line}></div>
                        <div className={styles.new_address_container}>
                            <p className={`${styles.new_address_text} ${darkMode ? styles.new_address_textDark : ''}`}>
                                Select a new address as the location
                            </p>
                            <div className={`${styles.location} ${darkMode ? styles.locationDark : ''}`} onClick={handleLocationClick}>
                                {selectedAddress ? selectedAddress.title : "Location"} <AiOutlineRight className={styles.icon} />
                            </div>
                            {showDropdown && (
                address.length > 0 ? (
                  <>
                    <div className={styles.dropdown}>
                      <ul>
                        {address.map(add => (
                          <li key={add._id} className={styles.places} onClick={() => handleOptionClick(add._id)}>
                            {add.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.dropdown}>
                      <div className={styles.addloc} onClick={() => setOpenaddress(true)}>
                        
                        <AiOutlinePlusCircle className={styles.add_button}
                          onClick={() => setOpenaddress(true)}/>
                        <span>Add Location</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles.dropdown}>
                    <div className={styles.addloc} onClick={() => setOpenaddress(true)}>
                    <AiOutlinePlusCircle className={styles.add_button}
                          onClick={() => setOpenaddress(true)}/>
                      <span>Add Location</span>
                    </div>
                  </div>
                )
              )}
                        </div>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.last_section}>
                        <div>
                            <div className={styles.flex}>
                                <p className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>Set a Timer</p>
                                <Switch
                                    checked={timer}
                                    onChange={() => setTimer(!timer)}
                                    onColor={'#0298a6'}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                />
                            </div>
                            {timer && (
                                <div className={styles.flexColumn}>
                                    <div className={styles.flex}>
                                        {/* <label className={`${styles.labelt} ${darkMode ? styles.labeltDark : ''}`}>Start Date:</label>
                                        <input className="datein" type="datetime-local" value={startTDate} onChange={(e) => setStartTDate(e.target.value)} /> */}
                                    </div>
                                    <div className={styles.flex}>
                                        <label className={`${styles.labelt} ${darkMode ? styles.labeltDark : ''}`}>End Date:</label>
                                        <input
                                            className="datein"
                                            type="datetime-local"
                                            value={endTDate === '1970-01-01T00:00' ? formattedDate : endTDate}
                                            onChange={(e) => {
                                                const newEndDate = e.target.value;
                                                const currentDate = new Date().toISOString().slice(0, 16); // Get current date in "YYYY-MM-DDTHH:MM" format
                                                if (newEndDate >= currentDate) { // Allow dates equal to or greater than current date
                                                    setEndTDate(newEndDate);
                                                } else {
                                                    toast.error("End date cannot be in the past.");
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            <p className={`${styles.text} ${darkMode ? styles.textDark : ''}`}>
                                When active the product will be available only for a limited time,
                                specified by you. (can be changed later)
                            </p>
                        </div>
                    </div>
                    <button className={`${styles.post} ${loading ? styles.postload : ''} ${darkMode ? styles.postDark : ''}`} type={"submit"} onClick={handleSubmit}>
                        Update
                    </button>
                </form>
                <PostSuccess update={true} open={success} onClose={() => setSuccess(false)} />
            </Layout>
        </>
    );
};
export default EditService;
