import React from "react";
import styles from "./Card.module.scss";
import Sell from "../../../assests/images/Sell.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Card = ({image,text,alt}) => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const navigate = useNavigate();
  return (
    <div className={`${styles.card} ${darkMode?styles.cardDark:''}`} onClick={() => navigate(`/help/${text.replace(" ","-")}`)}>
      <img src={image} alt={alt} className={styles.img} />
      <h2 className={`${styles.title} ${darkMode?styles.titleDark:''}`}>{text}</h2>
    </div>
  );
};
export default Card;
