import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import Cards from "../../../../common/components/Cards/Cards";
import { BsArrowRight } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import "./styles.css";
// import required modules
import { Grid, Pagination } from "swiper";
import ProdCardSkel from "../FeaturedProduct/ProdCardSkel";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../../../common/components/ErrorMessage/ErrorMessage";
import Loader from "../../../../common/components/Loader/Loader";
import { featuredListHandler } from "../../../../store/actions/featuredActions";
import { FEATURED_LIST_RESET } from "../../../../store/constants/featuredConstants";
import Car from "../../../../assests/images/Car.png";
import SwiperCore, { Navigation } from "swiper";
import { getRecommendedProduct } from "../../../../store/actions/recommendedProductAction";
import { GetAllServices } from "../../../../store/actions/servicesActions";
import { GetAllServicesByType } from "../../../../store/actions/servicesActions";
import { GetServicesWishlist } from "../../../../store/actions/servicesActions";
import { GetWishList } from "../../../../store/actions/whishlistActions";
SwiperCore.use([Navigation]);
const RequestedServices = () => {
    const dispatch = useDispatch();
    const [confirmedServices, setConfirmedServices] = useState([])
    const loading = useSelector(
        (state) => state.confirmedServicesByType?.loading
    );
    useEffect(() => {
        const data = { "status": ["confirmed"] }
        dispatch(GetAllServicesByType({ pageSize: 10, page: 1, type: "request", rawData: data }))
            .then((data) => {
                setConfirmedServices(data.data?.services)
            })
            .catch((error) => {
                // console.log(error);
            });
    }, [dispatch]);
    const confirmedServices1 = useSelector((state) => state.confirmedServicesByType?.confirmedServicesByType?.data?.services)
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isSmallScreen = windowWidth < 768;
    const screenWidth = window.innerWidth;
  let slidesPerView;
  if (screenWidth >1900) {
    slidesPerView = 6; 
  }
 else if (screenWidth < 768) {
    slidesPerView = 'auto';
  } else {
    slidesPerView = 4;
  }
  const ServicesWishlist = useSelector((state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList)
  const wishList = useSelector((state) => state.Wishlist?.Wishlist?.data?.wishList)
  useEffect(() => {
      dispatch(GetServicesWishlist())
      dispatch(GetWishList())
  }, [dispatch]);
    return (
        <section className={`${styles.section} ${darkMode ? styles.sectionDark : ''}`}>
            <div className={styles.header}>
                <h2 className={`${styles.header__title} ${darkMode ? styles.header__titleDark : ''}`}>Requested Services</h2>
                <h2 className={styles.see_all}>
                    <Link className={`${styles.S_all} ${darkMode ? styles.S_allDark : ''}`} to={"/Requested-Services"}>
                        See all <BsArrowRight className={styles.arrow} />
                    </Link>
                </h2>
            </div>
            <div className={styles.swiper__section}>
                <Swiper
                    freeMode
                    navigation={!isSmallScreen}
                    modules={[Navigation]}
                    spaceBetween={30}
                    slidesPerView={slidesPerView}
                >
                    {loading ? (
                        <div>
                            <ProdCardSkel />
                        </div>
                    ) : (
                        confirmedServices1 && confirmedServices1
                            .filter((card) => card.typeOfService === "request")
                            .map((card) => (
                                <SwiperSlide className={styles.swiperslide} key={card.id}>
                                    <Cards
                                        key={card._id}
                                        title={card.title}
                                        price={card.pricing?.price}
                                        mainimage={card.imageUrls[0]}
                                        id={card._id}
                                        featured={card.featured}
                                        time={card.createdAt}
                                        end={card.timer?.deadline}
                                        old={card.condition}
                                        location={card.serviceAddress?.region}
                                        isService={card.isService}
                                        timer={card.timer?.enabled}
                                        discounted={card.discount?.enabled}
                                        percentage={card.discount?.percentage}
                                        ServicesWishlist={ServicesWishlist}
                                        wishList={wishList}
                                    />
                                </SwiperSlide>
                            ))
                    )}
                </Swiper>
                <style>
                    {`
                    .swiper-button-next { color: #0298a6; }
                    .swiper-button-prev { color: #0298a6; }
                    `}
                  </style>
            </div>
        </section>
    );
};
export default RequestedServices;
