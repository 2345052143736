import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { ReactComponent as Email } from "../../../../../assests/svg/email.svg";
import { ReactComponent as Password } from "../../../../../assests/svg/password.svg";
import { ReactComponent as Exclamation } from "../../../../../assests/svg/exclamation.svg";
import { ReactComponent as Phone } from "../../../../../assests/svg/gray phone.svg";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./index.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { BiHide, BiShow, BiLockAlt } from "react-icons/bi";
import {
  verifyEmail,
  register,
  login,
  registerCompany,
  sendVerificationCode,
  resendCode
} from "../../../../../store/actions/authActions";
import { uploadProfilePicture } from "../../../../../store/actions/userActions";
import { EMAIL_VERIFICATION_RESET } from "../../../../../store/constants/authConstants";
import { createCustomerStripeRegisterCompany } from "../../../../../store/actions/paymentActions";
import { passwordRules } from "../../../../constants/regex";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import app, { auth } from "../../../../../firebase";
import axios from 'axios';
import Loader from "../../../Loader/Loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../../../../assests/svg/La7i2_7alak1.1.svg'
const StepTwo = ({ setPage, formData, setFormData, image, setImage, fileUploaded }) => {
  const dispatch = useDispatch();
  const emailVerification = useSelector((state) => state.emailVerification);
  const { error, code: codeBackend } = emailVerification;
  const [value, setValue] = useState();
  const [verify, setVerify] = useState(false);
  const [Clicked, setClicked] = useState(false);
  const [code, setCode] = useState();
  const [terms, setTerms] = useState(false);

  useEffect(() => {
    dispatch({ type: EMAIL_VERIFICATION_RESET });
  }, [dispatch]);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  //for eye toggle
  const [password, setPassword] = useState(false);
  const [Cpassword, setCPassword] = useState(false);
  const handleSubmit = async (values) => {
    // handleChanges(values);
    // updatePhone()

    if (!value) {
      // Show a toast message to the user
      toast.error('Phone number cannot be empty.');
      return; // Stop the submission
    }
    if (terms === false) {
      toast.error('Please accept terms of service.');
      return;
    }
    setFormData((prev) => ({
      ...prev,
      phoneNumber: value,
      email: values.email,
      password: values.password,
      website: values.website
    }));
    try {
      const { email, password, companyName, companySize, industry, phoneNumber, website } = values;
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const token = await userCredential.user.getIdToken();
      localStorage.setItem('token', token);
      localStorage.setItem("uid", userCredential.user.uid);
      const formData = new FormData();
      formData.append('username', `${companyName}`);
      formData.append('companyName', `${companyName}`)
      if (website) {
        formData.append('website', website)
      } else {
        formData.append('website', null)
      }
      formData.append('email', email);
      formData.append('role', "MERCHANT");
      formData.append('size', companySize);
      formData.append('industry', industry);
      formData.append('phone', value)
      if (image) {
        formData.append('image', image);
      }
      const response = await axios.post('https://backend.la7i27alak.com/user', formData, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      const newImageUrl = response.data.imageUrl;
      setImage(newImageUrl);
    } catch (error) {
      console.error(error);
      if (error.message.includes("email-already-in-use")) {
        toast.error("This email is already registered. Please use a different email or try to log in.")
        setIsLoading(false);
        return;
      }
    }
    setVerify(true);
  };
  const maskedEmail = formData.email ? formData.email.slice(0, 3) + '*'.repeat(formData.email.length - 3 - formData.email.split('@')[0].length) + formData.email.split('@')[1] : '';
  const stepTwoValidationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short")
      .matches(passwordRules, {
        message: "Please create a stronger password",
      }),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    acceptedTos: Yup.boolean().oneOf(
      [true],
      "Please accept the terms of service"
    ),
  });
  const token = localStorage.getItem('token');
  const [failMessage, setFailMessage] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    let countdownInterval;

    if (isResending && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setIsResending(false);
      setCountdown(30);
      clearInterval(countdownInterval);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [isResending, countdown]);
  const verificationHandler = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }
      setIsLoading(true)
      const response = await axios.post(
        'https://backend.la7i27alak.com/user/verify/email',
        {
          emailVerificationCode: code
        },
        {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch(verifyEmail(response.data));
      setPage(2);
      // closeHandler();
      // setStep('login')
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error('Invalid verification code. Please try again.');
        setTimeout(() => setFailMessage(''), 3000);
        setIsLoading(false);
        setIsResending(true);
      }
    }
  };
  return (
    <>
      {!verify ? (
        <Formik
          initialValues={formData}
          onSubmit={handleSubmit}
          validationSchema={stepTwoValidationSchema}
        >
          {(props) => (
            <Form>
              <div className={styles.container}>
                <h1 className={styles.title}>Account Information</h1>
                <div className={styles.form_container}>
                  <div
                    className={`${styles.input_container} ${props.errors.email && props.touched.email
                      ? styles.error_input
                      : ""
                      }`}
                  >
                    <input
                      type="email"
                      name="email"
                      className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                      placeholder="Email address"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.email}
                    />
                    <Email className={styles.input_icon} />
                    {props.errors.email && props.touched.email && (
                      <p className={styles.error_message}>
                        {props.errors.email}
                      </p>
                    )}
                  </div>
                  <div className={styles.phone_input_container}>
                    <div className="small--phone">
                      <PhoneInput
                        defaultCountry="LB"
                        placeholder="Phone number"
                        value={value}
                        onChange={setValue}
                        className={`${styles.phone_input} ${darkMode ? styles.phone_inputDark : ''}`}
                      />
                    </div>
                    <Phone className={styles.phone} />
                  </div>
                  <div
                    className={`${styles.input_container} ${props.errors.password && props.touched.password
                      ? styles.error_input
                      : ""
                      }`}
                  >
                    <input
                      type={password ? 'text' : 'password'}
                      name="password"
                      className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                      placeholder="Password"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.password}
                    />
                    <BiLockAlt className={styles.input_icon} />
                    {/* <Password className={styles.input_icon} /> */}
                    {!password && (
                      <BiHide
                        className={styles.pass_icon}
                        onClick={() => setPassword(true)}
                      />
                    )}
                    {password && (
                      <BiShow
                        className={styles.pass_icon}
                        onClick={() => setPassword(false)}
                      />
                    )}
                    {props.errors.password && props.touched.password && (
                      <p className={styles.error_message}>
                        {props.errors.password}
                      </p>
                    )}
                  </div>
                  <div
                    className={`${styles.input_container} ${props.errors.confirmPassword &&
                      props.touched.confirmPassword
                      ? styles.error_input
                      : ""
                      }`}
                  >
                    <input
                      type={Cpassword ? 'text' : 'password'}
                      name="confirmPassword"
                      className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                      placeholder="Confirm Password"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.confirmPassword}
                    />
                    {/* <Password className={styles.input_icon} /> */}
                    <BiLockAlt className={styles.input_icon} />
                    {!Cpassword && (
                      <BiHide
                        className={styles.pass_icon}
                        onClick={() => setCPassword(true)}
                      />
                    )}
                    {Cpassword && (
                      <BiShow
                        className={styles.pass_icon}
                        onClick={() => setCPassword(false)}
                      />
                    )}
                    {props.errors.confirmPassword &&
                      props.touched.confirmPassword && (
                        <p className={styles.error_message}>
                          {props.errors.confirmPassword}
                        </p>
                      )}
                  </div>
                </div>
                <div className={styles.remember_container}>
                  <input
                    type="checkbox"
                    name="acceptedTos"
                    id="remember"
                    className={styles.check}
                    onChange={() => setTerms(!terms)}
                    // onBlur={props.handleBlur}
                    value={terms}
                  />
                  <label htmlFor="remember" className={`${styles.remember} ${darkMode ? styles.rememberDark : ''}`}>
                    I verify that I am an authorized representative of this
                    organization and have the right to act on its behalf in the
                    creation and management of this account. The organization
                    and I agree to all{" "}
                    <span className={styles.terms}>Terms of Service.</span>
                  </label>
                  {props.errors.acceptedTos && props.touched.acceptedTos && (
                    <p className={styles.error_message}>
                      {props.errors.acceptedTos}
                    </p>
                  )}
                </div>
                <p className={`${styles.verify} ${darkMode ? styles.verifyDark : ''}`}>
                  <Exclamation className={styles.exclamation_icon} />
                  Our team will contact you to verify this information.
                </p>
                <button className={`${styles.btn} ${darkMode ? styles.btnDark : ''}`} type={"submit"}>
                  Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className={styles.verify_container}>
          <div className={styles.logoCont}>
            <img src={logo} alt='logo' className={styles.logo} />
          </div>
          <div className={styles.verification_container}>
            <h3 className={`${styles.verification_title} ${darkMode ? styles.verification_titleDark : ''}`}>Verification Code</h3>
            <p className={`${styles.verification_text} ${darkMode ? styles.verification_textDark : ''}`}>
              A 4-digit verification code has been sent to your email
              {/* <span className={styles.email}>{formData.email}</span> */}
              <span className={styles.email}>{maskedEmail}</span>

            </p>
            <div className={styles.code_container}>
              <input
                className={`${styles.code_input} ${darkMode ? styles.code_inputDark : ''}`}
                placeholder="Enter 4-digit code"
                maxLength="4"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              {isResending ? (
                <button
                  className={styles.send_btnRsnd}

                  disabled={isResending}
                >
                  Resend in {countdown}s
                </button>
              ) : (
                <button
                  className={`${styles.send_btn} ${darkMode ? styles.send_btnDark : ''}`}
                  onClick={() => {
                    dispatch(resendCode());
                    setIsResending(true);
                  }}
                  disabled={isResending}
                >
                  Resend Code
                </button>
              )}

            </div>
          </div>
          {/* {failMessage && <p style={{ color: 'red' }}>{failMessage}</p>} */}
          {loading ? (
            <div className="center">
              <Loader />
            </div>
          ) : (
            <button
              className={`${styles.confirm_btn} ${darkMode ? styles.confirm_btnDark : ''}`}
              onClick={() => {
                verificationHandler();
                // setPage(2);
              }}
            >
              Confirm
            </button>
          )}
        </div>
      )}
      <style>
        {`
          ${darkMode ? '.small--phone .PhoneInputInput { background: transparent;color:#a6c0c8 }' : ''}
        `}
      </style>
    </>
  );
};
export default StepTwo;