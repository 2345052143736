import React, { useState, useEffect } from "react";
import styles from "./Bid.module.scss";
import { AiFillBell } from "react-icons/ai";
import BidModal from "../BidModal/BidModal";
import { countDown, timeLeft } from "../../../../common/Utils";
import { join_bid } from "../../../../store/actions/makePaymentActions";
import {
  createCustomerStripe,
  listCustomerCards,
} from "../../../../store/actions/paymentActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userPaymentBidHandler } from "../../../../store/actions/makePaymentActions";
import { getBidersHandler } from "../../../../store/actions/bidActions";
import Modal from "../../../../common/components/Modal/Modal";
import { USER_RESET } from "../../../../store/constants/userConstants";
import ChoosePaymentModal from "../ChoosePaymentModal/ChoosePaymentModal";
import { JoinBid } from "../../../../store/actions/bidActions";
import { GetAllowedBidders } from "../../../../store/actions/bidActions";
import { CheckUserInBid } from "../../../../store/actions/bidActions";
import { toast } from "react-toastify";
const Bid = ({ start, end, card_id = null, name, productId, timer, isService, preview, id }) => {
  const hasPreview = preview || false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uid = localStorage.getItem('uid')
  const [open, setOpen] = useState(false);
  // login modal
  const [isOpen, setIsOpen] = useState(false);
  // choose payment modal
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const { user } = useSelector((state) => state.userLogin);
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);
  const joinBid = useSelector((state) => state.joinBid);
  const { receipt_url } = joinBid;
  const allowedBidders = useSelector((state) => state.allowedBidders?.allowedBidders)
  const inBid = useSelector((state) => state.inBid?.inBid?.message)
  const checkUserPaymentBid = useSelector((state) => state.checkUserPaymentBid);
  const { success } = checkUserPaymentBid;
  const getBiders = useSelector((state) => state.getBiders);
  const { biders, error, loading } = getBiders;
  useEffect(() => {
    dispatch(getBidersHandler(productId));
    dispatch(GetAllowedBidders(productId))
    dispatch(CheckUserInBid(productId))
    if (user !== null) {
      dispatch(userPaymentBidHandler(user.id, productId));
    }
  }, [dispatch]);
  const [loadingBid, setLoadingBid] = useState(false)
  const ID = { "productId": productId }
  const isExpired = end && countDown(end);
  const joinBidHandler = async () => {
    setLoadingBid(true)
    const response = await dispatch(JoinBid(JSON.stringify(ID)))
    if (response) {
      navigate(`/product/${name.replaceAll(" ", "-")}/${productId}/bid`, {
        state: {
          id: productId,
        },
      })
    } else {
      navigate('/user/payment/add-card')
      toast.error("Please add a payment before joining the bid.")
    }
    setLoadingBid(false)
  };
  let formattedDate = '';
  const currentDate = new Date().toISOString();
  if (timer && end) {
    const endDate = new Date(end);
    if (!isNaN(endDate)) {
      formattedDate = endDate.toISOString();
    } else {
      // Handle invalid date format
      console.error(`Invalid date format for end: ${end}`);
    }
  }
  return (
    <>
      <Modal open={isOpen} closeHandler={() => setIsOpen(false)} />
      <div className={styles.line}></div>
      <div className={styles.bid_container}>
        <div className={styles.flex}>
          {!isService && (
            <div className={styles.biders}>
              <div className={styles.image_container}>
                {allowedBidders && allowedBidders.allowedBidders && allowedBidders.allowedBidders.length > 0 ? (
                  <>
                    {allowedBidders.allowedBidders[0] && (
                      <img
                        src={allowedBidders.allowedBidders[0].imageUrl}
                        alt=""
                        className={`${styles.image} ${styles.first}`}
                      />
                    )}
                    {allowedBidders.allowedBidders[1] && (
                      <img
                        src={allowedBidders.allowedBidders[1].imageUrl}
                        alt=""
                        className={`${styles.image} ${styles.second}`}
                      />
                    )}
                    {allowedBidders.allowedBidders[2] && (
                      <img
                        src={allowedBidders.allowedBidders[2].imageUrl}
                        alt=""
                        className={`${styles.image} ${styles.third}`}
                      />
                    )}
                    {allowedBidders.allowedBiddersCount >1 ? (
                      <p>{allowedBidders.allowedBiddersCount} Bidders</p>
                    ) :(<p>{allowedBidders.allowedBiddersCount} Bidder</p>)}
                    
                  </>
                ) : (
                  <>
                    <div  className={styles.overlay}>0</div>
                    <p className={styles.overlayP} style={{marginTop:'20px',marginBottom:'30px'}}>0 Bidder</p>
                  </>
                )}
              </div>
            </div>
          )}
          {!hasPreview && (
            timer && end && (
              <div className={styles.timer}>
                <AiFillBell className={styles.timer__icon} />{" "}
                <span className={styles.timer__text}>
                  {countDown(end)}
                </span>
              </div>
            )
          )}
        </div>
        {
          user !== null ? (
            isService ? (
              <button
                className={styles.join}
                onClick={() => setOpen(true)}
                disabled={isExpired === "Expired"}
              >
                {isExpired === "Expired" ? "Expired" : "Join Offer"}
              </button>
            ) : (
              inBid || id === uid ? (
                <button
                  className={styles.join}
                  onClick={() =>
                    navigate(`/product/${name.replaceAll(" ", "-")}/${productId}/bid`, {
                      state: {
                        id: productId,
                      },
                    })
                  }
                  disabled={isExpired === "Expired"}
                >
                  {isExpired === "Expired" ? "Expired" : "View Bid"}
                </button>
              ) : (
                <button
                  className={styles.join}
                  onClick={() => setOpen(true)}
                  disabled={isExpired === "Expired"}
                >
                  {isExpired === "Expired" ? "Expired" : "Join Bid"}
                </button>
              )
            )
          ) : (
            <button
              className={styles.login_btn}
              onClick={() => {
                dispatch({ type: USER_RESET });
                setIsOpen(true);
              }}
            >
              Login For More Details
            </button>
          )
        }
      </div>
      <BidModal
        open={open}
        closeHandler={() => setOpen(false)}
        joinBidHandler={joinBidHandler}
        name={name}
        loadingBid={loadingBid}
      />
      <ChoosePaymentModal
        open={isPaymentModal}
        closeHandler={() => setIsPaymentModal(false)}
      />
    </>
  );
};
export default Bid;