import React, { useState, useEffect } from "react";
import styles from './DeleteModal.module.scss'
import ReactDom from "react-dom";
import { ReactComponent as Close } from "../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../assests/svg/canceldark.svg";
import { useSelector, useDispatch } from "react-redux";
import { DeleteAccount } from "../../store/actions/userActions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const DeleteModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [loading, setLoading] = useState(false)
  const isOtherReasonSelected = selectedReasons.includes('Other');
  useEffect(() => {
    setAdditionalComments("")
  }, [isOtherReasonSelected])
  const reasons = [
    "No longer require apps services",
    "Dissatisfying experience",
    "Found a better alternative",
    "Just need a fresh start",
    "App not working as expected",
    "Other",
  ];
  const [additionalComments, setAdditionalComments] = useState("");
  const handleCommentsChange = (event) => {
    setAdditionalComments(event.target.value);
  };
  const [isReasonChecked, setIsReasonChecked] = useState(false);
   const data = [...selectedReasons, additionalComments];
  const handleReasonChange = (event) => {
    const { value } = event.target;
    setIsReasonChecked(event.target.checked)
    // Check if the value is already in the selectedReasons array
    const index = selectedReasons.indexOf(value);
    if (index === -1) {
      // If not found, add it to the array
      setSelectedReasons([...selectedReasons, value]);
    } else {
      // If found, remove it from the array
      setSelectedReasons(selectedReasons.filter((reason) => reason !== value));
    }
  };
  useEffect(() => {
    const lgout = document.getElementById('lgout');
    lgout.addEventListener('click', () => {
      window.location.reload();
    });
    return () => {
      lgout.removeEventListener('click', () => {
        window.location.reload();
      });
    };
  }, []);
  const handleDeleteAccount = async () => {
    setLoading(true)
    const response = await dispatch(DeleteAccount())
    setLoading(false)
    if (response) {
      localStorage.removeItem('uid')
      localStorage.removeItem('token')
      localStorage.removeItem("userInfo");
      toast.success('Account deleted successfully.');
    }
  };
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={`${styles.modal_styles}  ${darkMode ? styles.modal_stylesDark : ''}`}  >
        <div className={styles.container}>
          {/* {darkMode ? (
            <CloseDark className={styles.close} onClick={onClose} />
          ) :
            (<Close className={styles.close} onClick={onClose} />)} */}
          <h2 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>
            Why do you want to delete your account?
          </h2>
          <div className={styles.checkbox_group}>
            {reasons.map((reason) => (
              <label key={reason} className={`${styles.lb} ${darkMode ? styles.lbDark : ''}`}>
                <input
                  type="checkbox"
                  name="reason"
                  onChange={handleReasonChange}
                  value={reason}
                  style={{marginRight:'10px'}}
                  checked={selectedReasons.includes(reason)}
                />
                {reason}
              </label>
            ))}
          </div>
          <input
            type="text"
            className={`${styles.inp} ${darkMode ? styles.inpDark : ''}`}
            placeholder="Additional Comments"
            disabled={!isOtherReasonSelected}
            onChange={handleCommentsChange}
            value={additionalComments}
          />
          {selectedReasons.length !== 0 || loading === true ? (
            <button className={`${styles.submit} ${darkMode ? styles.submitDark : ''}`} type="submit" onClick={handleDeleteAccount} disabled={loading}>Submit</button>
          ) : (
            <button  className={styles.submitdis}>Submit</button>
          )}
        </div>
      </div>
    </>,
    document.getElementById('portal')
  );
};
export default DeleteModal;
