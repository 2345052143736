import {
    CHECK_IF_USER_IN_WAITING_LIST_FAIL,
    CHECK_IF_USER_IN_WAITING_LIST_SUCCESS
} from "../constants/myProductsContants";
const initialState = {
    checkInWaitingList: [],
    error: null,
    loading: false,
};
export const CheckUserInWaitingList = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_IF_USER_IN_WAITING_LIST_SUCCESS:
            return {
                ...state,
                checkInWaitingList: action.payload,
                error: null,
                loading: false,
            };
        case CHECK_IF_USER_IN_WAITING_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
