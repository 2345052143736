import React from "react";
import styles from "./Empty.module.scss";
import Img from "../../../../assests/svg/order history empty.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const EmptyHistory = () => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
const navigate=useNavigate()
  return (
    <div className={styles.empty_container}>
      <img src={Img} alt="empty list" className={styles.img} />
      <h3 className={`${styles.subtitle} ${darkMode? styles.subtitleDark:''}`}>You Have No Orders Yet</h3>
      <p className={styles.text}>
        Check our large list of products and make your first deal
      </p>
      <button className={`${styles.button} ${darkMode? styles.buttonDark:''}`} onClick={()=>navigate('/')}>Go Shopping</button>
    </div>
  );
};
export default EmptyHistory;
