import React, { useEffect } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./SellOrBid.module.scss";
import Card from "./Card/Card";
import Footer from "../../common/components/Footer/Footer";
import { useSelector } from "react-redux";
import { url } from "../../common/constants/url";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
const SellOrBidProductCategory = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "CLEAR_BREADCRUMBS" })
    const pageName = "All Categories"; // Replace this with the actual page name
    const pageUrl = `/sell/products`; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const categories = useSelector((state) => state.categories?.categories?.data?.categories);
  const loading = useSelector((state) => state.categories.loading);
  const error = useSelector((state) => state.categories.error);
  const darkMode = useSelector((state) => state.darkMode.darkMode)
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Helmet>
        <title>Sell Product</title>
        <meta name='Sell Product' content='Sell Product' />
        <meta
          name="keywords"
          content={`Sell products, la7i27alak`}
        />
      </Helmet>
      <Layout title={"Product Categories"} footer={true}>
        <h2 className={`${styles.subtitle} ${darkMode ? styles.subtitleDark : ''}`}>What are you offering?</h2>
        <div className={`${styles.categories_container} ${darkMode ? styles.categories_containerDark : ''}`}>
          {categories && categories.map((category) => (
            <Card
              key={category._id} // Use the _id property as the key prop value
              title={category.title}
              image={category.iconUrl}
              categoryId={category._id}
              subCategory={category.subCategories}
            />
          ))}
        </div>
      </Layout>
      {/* <Footer /> */}
    </>
  );
};
export default SellOrBidProductCategory;
