import React, { useState, useEffect } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./NotificationSetting.module.scss";
import { ReactComponent as Bell } from "../../assests/svg/bell.svg";
import Switch from "../../common/components/Switch/Switch";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { enableNotification } from "../../store/actions/NotificationAction";
import { GetUser } from "../../store/actions/userActions";
const NotificationSetting = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const uid = localStorage.getItem('uid')
  const [isEnabled, setIsEnabled] = useState(false)
  useEffect(() => {
    if (location.state?.isNotification) {
      const pageName = "Notification settings"; // Replace this with the actual page name
      const pageUrl = "/notifications-setting"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Notification settings"; // Replace this with the actual page name
      const pageUrl = "/notifications-setting"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
    const fetchData = async () => {
      const response = await dispatch(GetUser(uid))
      
      setIsEnabled(response.data?.user?.notifications?.enabled)
    };
    fetchData();
  }, []);
  const changeNotification = async () => {
    if (isEnabled) {
      const data = { "enabled": false }
      const response = dispatch(enableNotification(data))
    } else {
      const data = { "enabled": true }
      const response = dispatch(enableNotification(data))
    }
  }
  const [Noti, setNoti] = useState(false);
  const [notiRecommendations, setNotiRecommendations] = useState(false);
  const [notiSellingChat, setNotiSellingChat] = useState(false);
  const [notiBuyingChat, setNotiBuyingChat] = useState(false);
  const [notiBids, setNotiBids] = useState(false);
  const [notiSpecialOffers, setNotiSpecialOffers] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <>
      <Helmet>
        <title>Notification Settings</title>
        <meta name='Notification Settings' content="Notification Settings" />
      </Helmet>
      <Layout title={"Notifications"}>
        <div className={styles.wrapper}>
          <p className={`${styles.text} ${darkMode ? styles.textDark : ''}`}>
            Enable push notifications allows us to send you info and updates on
            new products, sales, and more
          </p>
          <div className={styles.container}>
            <div className={styles.main_setting}>
              <div className={styles.title}>
                <Bell className={styles.icon} />
                <h1>Notifications</h1>
              </div>
              <Switch isSelected={isEnabled} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default NotificationSetting;
