import React, { useEffect } from "react";
import styles from "./index.module.scss";
import Header from "../../common/components/Header/Header";
import Footer from "../../common/components/Footer/Footer";
import CurrentPath from "../../common/components/CurrentPath/CurrentPath";
import noti from "../../assests/svg/noti.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  AiOutlineArrowRight,
  AiOutlineBell,
  AiOutlineMail,
  AiOutlineSetting,
} from "react-icons/ai";
import img from "../../assests/svg/notification-bell.svg";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { timePosted } from "../../common/Utils";
import inbox from "../../assests/images/envelope.png";
import { useDispatch } from "react-redux";
import Layout from "../../common/components/Layouts/Layout";
const NotificationsPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "CLEAR_BREADCRUMBS" });
    const pageName = "Notification"; // Replace this with the actual page name
    const pageUrl = "/notifications"; // Replace this with the actual page URL
    dispatch({
      type: "ADD_BREADCRUMB",
      payload: { name: pageName, url: pageUrl },
    });
  }, []);
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const notification = useSelector(
    (state) => state.notification?.notification?.notifications
  );
  const url = notification.webUrl;
  const newUrl = url ? new URL(url) : null;
  const path = newUrl?.pathname;
  return (
    <>
      <Helmet>
        <title>Notification </title>
        <meta name="Notification " content="Notification " />
      </Helmet>
      <Layout title={"Notification"}>
        <section
          className={`${styles.noti_section} ${
            darkMode ? styles.noti_sectionDark : ""
          }`}
        >
          <div className={styles.sets}>
            <button
              onClick={() =>
                navigate("/notifications-setting", {
                  state: { isNotification: true },
                })
              }
              className={styles.butsets}
            >
              <AiOutlineSetting className={styles.ic} />
              Notification Settings
            </button>
          </div>
          <div className={styles.container}>
            {notification.length === 0 ? (
              <>
                <img src={noti} alt="no notification" className={styles.img} />
                <h3
                  className={`${styles.subtitle} ${
                    darkMode ? styles.subtitleDark : ""
                  }`}
                >
                  No Notifications
                </h3>
                <p className={styles.text}>
                  We Will inform you when anything new arrives
                </p>
                <button
                  className={`${styles.btn} ${darkMode ? styles.btnDark : ""}`}
                  onClick={() => navigate("/")}
                >
                  Back
                </button>
              </>
            ) : (
              <>
                {notification && notification.length > 0 ? (
                  <>
                    {notification.map((notification, index) => {
                      const url = notification.webUrl;
                      const newUrl = url ? new URL(url) : null;
                      const path = newUrl?.pathname;
                      return (
                        <Link
                          className={`${styles.notification} ${
                            darkMode ? styles.notificationDark : ""
                          }`}
                          key={index}
                          to={path || "/"}
                        >
                          <div className={styles.imgnot}>
                            {/* <img src={inbox} className={styles.imgn} alt='notification' /> */}
                            <AiOutlineBell
                              className={`${styles.imgn} ${
                                darkMode ? styles.imgnDark : ""
                              }`}
                            />
                          </div>
                          <div className={styles.notifyCont}>
                            <div
                              className={`${styles.notificationTitle} ${
                                darkMode ? styles.notificationTitleDark : ""
                              }`}
                            >
                              {notification.title}
                            </div>
                            <div
                              className={`${styles.notificationContent} ${
                                darkMode ? styles.notificationContentDark : ""
                              }`}
                            >
                              {notification.body}
                            </div>
                          </div>
                          <div className={styles.time}>
                            {timePosted(notification.date) === "now"
                              ? "now"
                              : timePosted(notification.date) + " ago"}
                          </div>
                        </Link>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <img
                      src={noti}
                      alt="no notification"
                      className={styles.img}
                    />
                    <h3
                      className={`${styles.subtitle} ${
                        darkMode ? styles.subtitleDark : ""
                      }`}
                    >
                      No Notifications
                    </h3>
                    <p className={styles.text}>
                      We Will inform you when anything new arrives
                    </p>
                    <button
                      className={`${styles.btn} ${
                        darkMode ? styles.btnDark : ""
                      }`}
                      onClick={() => navigate("/")}
                    >
                      Back
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </section>
      </Layout>
    </>
  );
};
export default NotificationsPage;
