import {
    GET_ALL_SERVICES_BY_TYPE_SUCCESS,
    GET_ALL_SERVICES_BY_TYPE_FAIL,
} from "../constants/servicesConstants";
const initialState = {
    confirmedServicesByType: [],
    error: null,
    loading: false,
};
export const confirmedServicesByTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SERVICES_BY_TYPE_SUCCESS:
            return {
                ...state,
                confirmedServicesByType: action.payload,
                error: null,
                loading: false,
            };
        case GET_ALL_SERVICES_BY_TYPE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
