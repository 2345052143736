import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Pagination } from 'react-bootstrap';
import styles from "../CategoryPage/CategoryPage.module.scss";
import Layout from "../../common/components/Layouts/Layout";
import CurrentPath from "../../common/components/CurrentPath/CurrentPath";
import Search from "../../common/components/Search/Search";
import Header from "../../common/components/Header/Header";
import img from "../../assests/svg/empty products.svg";
import Footer from "../../common/components/Footer/Footer";
import Cards from "../../common/components/Cards/Cards";
import { CgSortAz } from "react-icons/cg";
import { MdGridView } from "react-icons/md";
import { AiFillFilter, AiOutlineMenu } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { listMyProductsBySubCategory } from "../../store/actions/categoryAction";
import { PRODUCT_LIST_BY_SUBCATEGORY_RESET } from "../../store/constants/categoryConstants";
import Loader from "../../common/components/Loader/Loader";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import { handleFilterIcon } from "../../store/actions/filterActions";
import { GetProductBySubCat, GetProductBySubCatSuccess } from "../../store/actions/productDetailsAction";
import { GetProductByCat, GetProductByCatSuccess } from "../../store/actions/productDetailsAction";
import CardSkel from "../../common/components/Cards/CardSkel";
import { Helmet } from "react-helmet";
import { SearchProduct } from "../../store/actions/productDetailsAction";
import { useNavigate } from "react-router-dom";
import { SearchAllService } from "../../store/actions/productDetailsAction";
import FilterModal from "../../common/components/FilterModal/FilterModal";
import { GetServicesWishlist } from "../../store/actions/servicesActions";
import { GetWishList } from "../../store/actions/whishlistActions";
const SearchPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [activePage, setActivePage] = useState(1);
    const [sort, setSort] = useState("")
    useEffect(() => {
        let pageUrl;
        const pageName = "Search"; // Replace this with the actual page name
        if (params.SubId) {
            pageUrl = `/search/${params.product}/${params.text}/${params.CatId}/${params.SubId}`
        } else if (params.CatId) {
            pageUrl = `/search/${params.product}/${params.text}/${params.CatId}`
        } else {
            pageUrl = `/search/${params.product}/${params.text}`
        }
        dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    })
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    const location = useLocation();
    const [filterValues, setFilterValues] = useState({});
    const filterValue = useSelector((state) => state.filterValue?.convertedFilterValues)
    const search = useSelector((state) => state.SearchComponent?.SearchComponent?.data?.products)
    const totalProducts = useSelector((state) => state.SearchComponent?.SearchComponent?.data?.productCount)
    const totalServices = useSelector((state) => state.searchAllService?.searchAllService?.data?.serviceCount)
    const pageSizeProducts = useSelector((state) => state.SearchComponent?.SearchComponent?.data?.pageSize)
    const totalPagesProducts = Math.ceil(totalProducts / pageSizeProducts);
    const totalPagesServices = Math.ceil(totalServices / pageSizeProducts);
    const searchAllService = useSelector((state) => state.searchAllService?.searchAllService?.data?.services)
    const error = useSelector((state) => state.searchAllService?.error)
    const columnSize = window.innerWidth < 768 ? 10 : 12;
    const handleSortChange = async (event) => {
        const selectedValue = event.target.value;
        // Save the selected value in the state variable "setSort"
        if (selectedValue === 'A-Z') {
            setSort('az');
        } else if (selectedValue === 'Z-A') {
            setSort('za');
        } else if (selectedValue === 'Highest-to-lowest-price') {
            setSort('high');
        } else if (selectedValue === 'lowest-to-highest-price') {
            setSort('low');
        } else if (selectedValue === 'featured') {
            setSort('featured')
        } else if (selectedValue === 'default') {
            setSort("")
        }
    };
    const handleFilterInputChange = (event, key) => {
        const { value } = event.target;
        setFilterValues(prevValues => ({ ...prevValues, [key]: value }));
    }
    const handleFilterValuesUpdate = (newFilterValues) => {
        setFilterValues(newFilterValues);
    }
    const [first, setFirst] = useState(true);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const loading = useSelector(
        (state) => state.cat?.loading
    );
    // const loading=false
    const activateFirst = () => {
        setFirst(true);
        setSecond(false);
        setThird(false);
    };
    const activateSecond = () => {
        setFirst(false);
        setSecond(true);
        setThird(false);
    };
    const activateThird = () => {
        setFirst(false);
        setSecond(false);
        setThird(true);
    };
    const [view, setView] = useState(false);
    useEffect(() => {
        function handleResize() {
            setView(window.innerWidth < 445);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [test, setTest] = useState([])
    useEffect(() => {
        if (params.product === 'product') {
            if (location.state?.isBid) {
                setTest(filterValue)
                const specialCriteria = { "specialCriteria": ["products", "bids"] }
                const combined = {
                    ...specialCriteria,
                    ...test
                }
                let parms = { page: activePage, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, rawData: combined };
                if (params.SubId) {
                    parms = { ...parms, subCategoryId: params.SubId };
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                } else if (params.CatId) {
                    parms = { ...parms, categoryId: params.CatId };
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                } else {
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                }
            } else {
                setTest(filterValue)
                let parms = { page: activePage, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, rawData: test };
                if (params.SubId) {

                    parms = { ...parms, subCategoryId: params.SubId };
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                } else if (params.CatId) {

                    parms = { ...parms, categoryId: params.CatId };
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                } else {
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                }
            }
        } else {
            let parms;
            if (location.state?.type) {
                setTest(filterValue)
                if (params.CatId) {
                    parms = { page: activePage, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, typeOfService: location.state?.type, rawData: test, categoryId: params.CatId };
                } else {
                    parms = { page: activePage, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, typeOfService: location.state?.type, rawData: test };
                }
            } else {
                setTest(filterValue)
                if (params.CatId) {
                    parms = { page: activePage, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, rawData: test, categoryId: params.CatId };
                } else {
                    parms = { page: activePage, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, rawData: test };
                }
            }
            try {
                const response = dispatch(SearchAllService(parms));
            } catch (error) {
                // console.log(error);
            }
        }
    }, [dispatch, sort, params])
    const handlePageChange = async (pageNumber) => {
        if (params.product === 'product' && pageNumber >= 1 && pageNumber <= totalPagesProducts) {
            setActivePage(pageNumber);
        } else if (params.product === 'service' && pageNumber >= 1 && pageNumber <= totalPagesServices) {
            setActivePage(pageNumber);
        }
        if (params.product === 'product') {
            if (location.state?.isBid) {
                setTest(filterValue)
                const specialCriteria = { "specialCriteria": ["products", "bids"] }
                const combined = {
                    ...specialCriteria,
                    ...test
                }

                let parms = { page: activePage, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, rawData: combined };
                if (params.SubId) {
                    parms = { ...parms, subCategoryId: params.SubId };
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                } else if (params.CatId) {
                    parms = { ...parms, categoryId: params.CatId };
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                } else {
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                }
            } else {
                setTest(filterValue)
                let parms = { page: activePage, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, rawData: test };
                if (params.SubId) {
                    parms = { ...parms, subCategoryId: params.SubId };
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                } else if (params.CatId) {
                    parms = { ...parms, categoryId: params.CatId };
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                } else {
                    try {
                        const response = dispatch(SearchProduct(parms));
                    } catch (error) {
                        // console.log(error);
                    }
                }
            }
        } else {
            let parms;
            if (location.state?.type) {
                setTest(filterValue)
                if (params.CatId) {
                    parms = { page: pageNumber, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, typeOfService: location.state?.type, rawData: test, categoryId: params.CatId };
                } else {
                    parms = { page: pageNumber, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, typeOfService: location.state?.type, rawData: test };
                }

            } else {
                setTest(filterValue)
                if (params.CatId) {
                    parms = { page: pageNumber, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, rawData: test, categoryId: params.CatId };
                } else {
                    parms = { page: pageNumber, pageSize: columnSize, text: params.text.replace("-", " "), sort: sort, rawData: test };
                }
            }
            try {
                const response = await dispatch(SearchAllService(parms));
                let url = `/ search / ${"service"} /${params.text}`;
                navigate(url);
            } catch (error) {
                // console.log(error);
            }
        }
    };
    let countToDisplay;
    if (params.product === 'service') {
        countToDisplay = totalServices;
    } else {
        countToDisplay = totalProducts;
    }
    const [isOpen, setIsOpen] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const savedValue = useSelector((state) => state.value.value);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const handleCategoryFilter = () => {
        // console.log(searchAllService)
    };
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleFilter = () => {
        if (screenWidth > 900) {
            dispatch({ type: 'SAVE_VALUE', payload: !savedValue });
            setIsOpen(!savedValue)
        } else {
            dispatch({ type: 'SAVE_VALUE', payload: false });
            setIsFilterOpen(true)
        }
    };
    useEffect(() => {
        return () => {
            dispatch({ type: 'SAVE_VALUE', payload: false });
        };
    }, []);
    // Handle visibilitychange event
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                dispatch({ type: 'SAVE_VALUE', payload: false });
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    const ServicesWishlist = useSelector((state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList)
    const wishList = useSelector((state) => state.Wishlist?.Wishlist?.data?.wishList)
    useEffect(() => {
        dispatch(GetServicesWishlist())
        dispatch(GetWishList())
    }, [dispatch]);
    return (
        <>
            <Helmet>
                <title>Search Results</title>
                <meta name={"Search Page"} content="This is Search Page" />
                <meta
                    name="keywords"
                    content={`Search page, products, services, all products, search products, search services, all services, la7i27alak`}
                />
            </Helmet>
            <Layout footer={true} isFilterIcon={true} handleFilterValuesUpdate={handleFilterValuesUpdate} handleFilterInputChange={handleFilterInputChange} onCategoryFilter={handleCategoryFilter} text={params.text} offer={params.product} searchCatId={params.CatId} searchSubId={params.SubId} type={location.state?.type} typeId={params.CatId} searchBid={location.state?.isBid} sort={sort} search={true} >
                <section className={`${styles.category_section} ${darkMode ? styles.category_sectionDark : ''}`}>
                    <div className={styles.result_container}>
                        <h1 className={`${styles.result_title} ${darkMode ? styles.result_titleDark : ''}`}>
                            {countToDisplay} Results Found
                        </h1>
                        <div className={styles.result_options}>
                            <div onClick={handleFilter} className={styles.filter_container}>
                                <AiFillFilter className={styles.filter_icon} />
                                <button className={styles.filter_btn}>Filter</button>
                            </div>
                            <span>
                                <CgSortAz className={`${styles.sort_icon} ${darkMode ? styles.sort_iconDark : ''}`} />{" "}
                                <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`} htmlFor="sort">Sort:</label>
                                <select className={`${styles.select} ${darkMode ? styles.selectDark : ''}`} name="sort" id="sort" onChange={handleSortChange}>
                                    <option value="default" selected disabled={sort === "default"}>Sort</option>
                                    <option value="featured">Featured</option>
                                    <option value="A-Z">Alphabetically: A-Z</option>
                                    <option value="Z-A">Alphabetically: Z-A</option>
                                    <option value="Highest-to-lowest-price">Highest to Lowest Price</option>
                                    <option value="lowest-to-highest-price">Lowest to Highest Price</option>
                                </select>
                            </span>
                            <span className={`${styles.span} ${darkMode ? styles.spanDark : ''}`}
                                onClick={() => setView(!view)}
                            >
                                View:
                                {view ? (
                                    <AiOutlineMenu className={styles.view_icon}
                                    />
                                ) : (
                                    <MdGridView className={styles.view_icon} />
                                )}
                            </span>
                        </div>
                    </div>
                    {loading ? (
                        <div>
                            {/* <Loader /> */}
                            <CardSkel />
                        </div>
                    ) : (
                        <div>
                            {params.product === 'product' && search && search.length > 0 ? (
                                <div className={`grid gap-4 ${view ? 'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3' : 'grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4'}`}>
                                    {search.filter((p) => p.status === 'confirmed').map((p) => (
                                        <Cards
                                            id={p._id}
                                            key={p._id}
                                            title={p.title}
                                            price={p.pricing.price}
                                            old={p.condition}
                                            time={p.createdAt}
                                            mainimage={p.imageUrls[0]}
                                            featured={p.featured}
                                            location={p.pickUpAddress?.region}
                                            timer={p.timer?.enabled}
                                            end={p.timer?.deadline}
                                            discounted={p.discount?.enabled}
                                            percentage={p.discount?.percentage}
                                            mobile={view}
                                            isService={p.isService}
                                            ServicesWishlist={ServicesWishlist}  // Pass ServicesWishlist as prop
                                            wishList={wishList}
                                            sold={p && p.status === "sold"}

                                        />
                                    ))}
                                </div>
                            ) : params.product === 'service' && searchAllService && searchAllService.length > 0 ? (
                                <div className={`grid gap-4 ${view ? 'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3' : 'grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4'}`}>
                                    {searchAllService.filter((p) => p.status === 'confirmed').map((p) => (
                                        <Cards
                                            id={p._id}
                                            key={p._id}
                                            title={p.title}
                                            price={p.pricing.price}
                                            old={p.condition}
                                            time={p.createdAt}
                                            mainimage={p.imageUrls[0]}
                                            featured={p.featured}
                                            location={p.serviceAddress?.region}
                                            timer={p.timer?.enabled}
                                            end={p.timer?.deadline}
                                            discounted={p.discount?.enabled}
                                            percentage={p.discount?.percentage}
                                            mobile={view}
                                            isService={p.isService}
                                            ServicesWishlist={ServicesWishlist}  // Pass ServicesWishlist as prop
                                            wishList={wishList}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className={styles.empty_container}>
                                    <img src={img} alt="empty products" className={styles.img} />
                                    <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>No Results Found</h1>
                                    <button className={`${styles.button} ${darkMode ? styles.buttonDark : ''}`} onClick={() => navigate('/')}>Go Shopping</button>
                                </div>
                            )}
                        </div>
                    )}
                    {params.product === "product" ? (
                        <>
                            {totalPagesProducts > 0 && (
                                <Pagination style={{ marginBottom: '0', paddingBottom: '20px' }}>
                                    {activePage === 1 ? null : (
                                        <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
                                    )}
                                    {totalPagesProducts === 1 ? (
                                        <Pagination.Item linkStyle={{ background: '#0298a6', border: 'none' }} active>{activePage}</Pagination.Item>
                                    ) : (
                                        <>
                                            {activePage === 1 ? null : (
                                                <Pagination.Item onClick={() => handlePageChange(activePage - 1)} active={activePage === 1}>
                                                    {activePage - 1}
                                                </Pagination.Item>
                                            )}
                                            <Pagination.Item linkStyle={{ background: '#0298a6', border: 'none' }} active>{activePage}</Pagination.Item>
                                            {activePage === totalPagesProducts ? null : (
                                                <Pagination.Item onClick={() => handlePageChange(activePage + 1)} active={activePage === totalPagesProducts}>
                                                    {activePage === totalPagesProducts ? activePage : activePage + 1}
                                                </Pagination.Item>
                                            )}
                                        </>
                                    )}
                                    {activePage === totalPagesProducts ? null : (
                                        <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPagesProducts} />
                                    )}
                                </Pagination>
                            )}
                        </>
                    ) : (
                        <>
                            {totalPagesServices > 0 && (
                                <Pagination style={{ marginBottom: '0', paddingBottom: '20px' }}>
                                    {activePage === 1 ? null : (
                                        <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
                                    )}
                                    {totalPagesServices === 1 ? (
                                        <Pagination.Item linkStyle={{ background: '#0298a6', border: 'none' }} active>{activePage}</Pagination.Item>
                                    ) : (
                                        <>
                                            {activePage === 1 ? null : (
                                                <Pagination.Item onClick={() => handlePageChange(activePage - 1)} active={activePage === 1}>
                                                    {activePage - 1}
                                                </Pagination.Item>
                                            )}
                                            <Pagination.Item linkStyle={{ background: '#0298a6', border: 'none' }} active>{activePage}</Pagination.Item>
                                            {activePage === totalPagesServices ? null : (
                                                <Pagination.Item onClick={() => handlePageChange(activePage + 1)} active={activePage === totalPagesServices}>
                                                    {activePage === totalPagesServices ? activePage : activePage + 1}
                                                </Pagination.Item>
                                            )}
                                        </>
                                    )}
                                    {activePage === totalPagesServices ? null : (
                                        <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPagesServices} />
                                    )}
                                </Pagination>
                            )}
                        </>
                    )}
                </section>
            </Layout>
            <FilterModal
                open={isFilterOpen}
                onClose={() => setIsFilterOpen(false)}
                text={params.text}
                offer={params.product}
                searchCatId={params.CatId}
                searchSubId={params.SubId}
                type={location.state?.type}
                searchBid={location.state?.isBid}
                sort={sort}
            />
        </>
    );
};
export default SearchPage;