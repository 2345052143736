import React,{useState,useEffect} from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const PrivateRoutes = () => {
  const { user } = useSelector((state) => state.userLogin);
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);
  return loggedInUser ? <Outlet /> : <Navigate to={"/"} />;
};
export default PrivateRoutes;
