import React, { useState, useEffect } from "react";
import styles from '../AdminNotification/Adminnot.module.scss'
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import Calendar from "../../assests/svg/calender.svg";
import { BsCaretDown } from "react-icons/bs";
import DatePicker from "react-datepicker";
import img from '../../assests/svg/notification-bell.svg'
import { GetAllContactUs } from "../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { VscReport } from "react-icons/vsc";
import { timePosted } from "../../common/Utils";
import nochat from '../../assests/svg/undraw_account_re_o7id 1.svg'
import Loader from "../../common/components/Loader/Loader";
import { AiOutlineBell } from "react-icons/ai";
import { GetAllReports } from "../../store/actions/AdminDashboard";
import { useNavigate } from "react-router-dom";
const AdminReports = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const currentDate = new Date();
    const formattedDate = `${selectedDate.toLocaleString("default", {
        month: "short"
    })} ${selectedDate.getDate()}`;
    const contactUs = useSelector((state) => state.contactUs?.contactUs?.data?.contacts)
    const GetReports = useSelector((state) => state.GetReports?.GetReports?.data?.reports)
    const loading = useSelector((state) => state.contactUs?.loading)
    useEffect(() => {
        dispatch(GetAllContactUs())
        dispatch(GetAllReports())
    }, [])
    return (
        <AdminLayout>
            <div className={styles.header}>
                <h3>Reports</h3>
                <div className={styles.calendar}>
                    <img src={Calendar} alt="" />
                    Today <span className={styles.date}> {formattedDate}</span>
                    <button onClick={toggleDatePicker}>
                        <BsCaretDown />
                    </button>
                    {showDatePicker && (
                        <div className={styles.datepicker_wrapper}>
                            <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                showPopperArrow={false}
                                dateFormat="MMM d"
                                inline
                            />
                        </div>
                    )}
                </div>
            </div>
            <div>
                {loading ? (
                    <Loader />
                ) : GetReports && GetReports.length > 0 ? (
                    GetReports.map((notification, index) => (
                        <div className={styles.container}>
                            <div className={styles.notification} key={index} onClick={() => navigate(`/user-review/${notification.to?.username.replace(" ", "-")}/${notification.to?._id}`)}>
                                <div className={styles.imgnot}>
                                    {/* <img src={inbox} className={styles.imgn} alt='notification' /> */}
                                    <VscReport className={`${styles.imgn} ${darkMode ? styles.imgnDark : ''}`} />
                                </div>
                                <div className={styles.notifyCont}>
                                    <div className={styles.notificationTitle}>
                                        {"From: "}  {notification.from?.username} <br />  {"To: " + notification.to?.username}
                                    </div>
                                    {notification && notification.comment.map((comment, commentIndex) => (
                                        <div key={commentIndex} className={styles.comment}>
                                            {comment}
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.time}>
                                    {timePosted(notification.createdAt)}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    // Show a message when loading is false and there is no data available
                    <div className="noconv">
                        <img className="nochatp" src={nochat} alt="nochat" />
                        <h4 className={darkMode ? "startchatDark" : "startchat"}>No Report Messages Yet</h4>
                    </div>
                )}
            </div>
        </AdminLayout >
    )
}
export default AdminReports