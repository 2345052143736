import { url } from "../../common/constants/url";
import axios from "axios";
import {
  ADD_TO_WAITINGLIST_REQUEST,
  ADD_TO_WAITINGLIST_SUCCESS,
  ADD_TO_WAITINGLIST_FAIL,
  PAYMENT_WAITING_FOR_SURE_REQUEST,
  PAYMENT_WAITING_FOR_SURE_SUCCESS,
  PAYMENT_WAITING_FOR_SURE_FAIL,
  REMOVE_FROM_WAITINGLIST_FAIL,
  REMOVE_FROM_WAITINGLIST_REQUEST,
  REMOVE_FROM_WAITINGLIST_SUCCESS,
  GET_WAITINGLIST_REQUEST,
  GET_WAITINGLIST_SUCCESS,
  GET_WAITINGLIST_FAIL,
  CHECK_USER_IN_WAITINGLIST_REQUEST,
  CHECK_USER_IN_WAITINGLIST_SUCCESS,
  CHECK_USER_IN_WAITINGLIST_FAIL,
  ACCEPT_WAITINGLIST_REQUEST,
  ACCEPT_WAITINGLIST_SUCCESS,
  ACCEPT_WAITINGLIST_FAIL,
  CANCEL_WAITINGLIST_REQUEST,
  CANCEL_WAITINGLIST_SUCCESS,
  CANCEL_WAITINGLIST_FAIL,
  IF_ANY_USER_ACCEPTED_REQUEST,
  IF_ANY_USER_ACCEPTED_SUCCESS,
  IF_ANY_USER_ACCEPTED_FAIL,
  GET_MY_WAITING_LIST_SUCCESS,
  GET_MY_WAITING_LIST_FAIL,
  GET_MY_WAITING_LIST_REQUEST,
  GET_LAST_SEEN_SUCCESS,
  GET_LAST_SEEN_FAIL
} from "../constants/waitingListConstants";
export const addToWaitingListHandler =
  (User_id, Products_id, forsure) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_TO_WAITINGLIST_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let body = {
        User_id,
        Products_id,
        forsure,
      };
      await axios.post(
        `${url}/waitinglist/addwaitinglist`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: ADD_TO_WAITINGLIST_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ADD_TO_WAITINGLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const paymentComingForSureHandler =
  (amount, description, caid, userid, productid) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: PAYMENT_WAITING_FOR_SURE_REQUEST,
        });
        const {
          userLogin: { userInfo },
        } = getState();
        const {
          createCustomer: { customer_id },
        } = getState();
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo}`,
          },
        };
        let currency = "usd";
        let cid = customer_id;
        let body = {
          amount,
          description,
          currency,
          cid,
          caid,
          userid,
          productid,
        };
        // console.log(body);
        await axios.post(
          `${url}/stripe_card/makepaymentForsure`,
          JSON.stringify(body),
          config
        );
        dispatch({
          type: PAYMENT_WAITING_FOR_SURE_SUCCESS,
        });
      } catch (error) {
        dispatch({
          type: PAYMENT_WAITING_FOR_SURE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };
export const removeFromWaitingListHandler =
  (user_id, product_id) => async (dispatch) => {
    try {
      dispatch({
        type: REMOVE_FROM_WAITINGLIST_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let body = {
        user_id,
        product_id,
      };
      await axios.post(
        `${url}/waitinglist/deleteuserfromwaitinglist`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: REMOVE_FROM_WAITINGLIST_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: REMOVE_FROM_WAITINGLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getWaitingListHandler = (Products_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_WAITINGLIST_REQUEST,
    });
    const { data } = await axios.get(
      `${url}/waitinglist/${Products_id}/getwaitinglistusers`
    );
    dispatch({
      type: GET_WAITINGLIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_WAITINGLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const checkUserInWaitingListHandler =
  (product_id, user_id) => async (dispatch) => {
    try {
      dispatch({
        type: CHECK_USER_IN_WAITINGLIST_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let body = {
        product_id,
        user_id,
      };
      const { data } = await axios.post(
        `${url}/waitinglist/checkuserinwaitinglist`,
        body,
        config
      );
      dispatch({
        type: CHECK_USER_IN_WAITINGLIST_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: CHECK_USER_IN_WAITINGLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const acceptWaitingListHandler =
  (product_id, user_id) => async (dispatch) => {
    try {
      dispatch({
        type: ACCEPT_WAITINGLIST_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let body = {
        product_id,
        user_id,
      };
      const { data } = await axios.post(
        `${url}/waitinglist/Acceptrequest`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: ACCEPT_WAITINGLIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ACCEPT_WAITINGLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const ifanyuseracceptedHandler = (product_id) => async (dispatch) => {
  try {
    dispatch({
      type: IF_ANY_USER_ACCEPTED_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let body = {
      product_id,
    };
    const { data } = await axios.post(
      url + `/waitinglist/ifanyuseraccepted`,
      body,
      config
    );
    dispatch({
      type: IF_ANY_USER_ACCEPTED_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: IF_ANY_USER_ACCEPTED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const cancelWaitingListHandler =
  (product_id, user_id) => async (dispatch) => {
    try {
      dispatch({
        type: CANCEL_WAITINGLIST_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let body = {
        product_id,
        user_id,
      };
      const { data } = await axios.post(
        `${url}/waitinglist/cancelrequest`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: CANCEL_WAITINGLIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CANCEL_WAITINGLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const GetMyWaitingList = () => {
  return async (dispatch) => {
    try {
      dispatch(GetMyWaitingListRequest())
      const token = localStorage.getItem('token')

      const response = await axios.get(`${url}/offer/allWaitinglist`, {
        headers: {
          'authorization': `${token}`,
        },
      });

      dispatch(GetMyWaitingListSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetMyWaitingListFail(error));
      // console.log(error)
    }
  };
};

export const GetMyWaitingListRequest = () => {
  return {
    type: GET_MY_WAITING_LIST_REQUEST,
  };
};
export const GetMyWaitingListSuccess = (categories) => {
  return {
    type: GET_MY_WAITING_LIST_SUCCESS,
    payload: categories,
  };
};


const GetMyWaitingListFail = (error) => ({
  type: GET_MY_WAITING_LIST_FAIL,
  payload: error
});


export const GetLastSeen = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')

      const response = await axios.get(`${url}/offer/getLastKnown/${id}`, {
        headers: {
          'authorization': `${token}`,
        },
      });

      dispatch(GetLastSeenSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetLastSeenFail(error));
      // console.log(error)
    }
  };
};


export const GetLastSeenSuccess = (categories) => {
  return {
    type: GET_LAST_SEEN_SUCCESS,
    payload: categories,
  };
};


const GetLastSeenFail = (error) => ({
  type: GET_LAST_SEEN_FAIL,
  payload: error
});