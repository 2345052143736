import React ,{useState,useEffect} from "react";
import "./ProductName.scss";
import iphone from "../../../../assests/images/iphone.jpg"
const ProductName = ({ title, image }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1050);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial value
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='leftI'>
      <div className="image-container">
        <img
          src={image}
          alt={title}
          className="Product-image"
        />
      </div>
      {isSmallScreen?(<p className="text">
      {title.length > 15 ? title.substring(0, 15) + "..." : title}
      </p>):(<p className="text">
        {title}
      </p>)}
      
    </div>
  );
};
export default ProductName;