import React, { useState, useEffect } from "react";
import styles from "./BidDetailsPage.module.scss";
import Layout from "../../common/components/Layouts/Layout";
import DetailsTables from "./components/DetailsTable/DetailsTables";
import BiddersTable from "./components/BiddersTable/BiddersTable";
import PlaceBidModal from "./components/PlaceBidModal/PlaceBidModal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PLACE_BID_RESET } from "../../store/constants/bidConstants";
import auth from "../../firebase";
import io from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { GetSingleProduct } from "../../store/actions/myProductsActions";
import { useParams } from "react-router-dom";
import ConfirmModal from "../../common/components/ConfirmModal/ConfirmModal";
import { EndOneBid } from "../../store/actions/bidActions";
import { EndAllBid } from "../../store/actions/bidActions";
import Loader from "../../common/components/Loader/Loader";
const BidDetailsPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    const pageName = "Bid"; // Replace this with the actual page name
    const pageUrl = `/product/${params.productName}/${params.productId}/bid`; // Replace this with the actual page URL
    dispatch({
      type: "ADD_BREADCRUMB",
      payload: { name: pageName, url: pageUrl },
    });
  }, []);
  const uid = localStorage.getItem("uid");
  const [bidders, setBidders] = useState(null);
  const bidDetails = {
    startingPrice: 10000,
    startingPriceDate: "27 Aug 2022 at 11:20 PM",
    currentBid: 40000,
    numberOfBids: 30,
    NumberOfBidders: 23,
    retractions: 0,
    timeLeft: "3 days 22 hours 12 mins",
    durationOfBid: "5 days",
    bidList: [
      {
        id: 1,
        image: "https://picsum.photos/200/300",
        name: "Joseph Skaff",
        bidAmount: 40000,
        bidTime: "31 Aug 2022 at 12:04 AM",
      },
      {
        id: 2,
        image: "https://picsum.photos/200/300",
        name: "Anthony Tannoury",
        bidAmount: 30000,
        bidTime: "31 Aug 2022 at 12:04 AM",
      },
      {
        id: 3,
        image: "https://picsum.photos/200/300",
        name: "Michael Kozah",
        bidAmount: 20000,
        bidTime: "31 Aug 2022 at 12:04 AM",
      },
      {
        id: 4,
        image: "https://picsum.photos/200/300",
        name: "Elie Sfeir",
        bidAmount: 10000,
        bidTime: "31 Aug 2022 at 12:04 AM",
      },
    ],
  };
  const [bidModal, setBidModal] = useState(false);
  const location = useLocation();
  const [bid, setBid] = useState(0);
  const [loadingendbid, setLoadingendbid] = useState(false);
  const [bidList, setBidList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const userById = useSelector((state) => state.userById);
  const [quantity, setQuantity] = useState([]);
  const [userData, setUserData] = useState([]);
  const [expired, setExpired] = useState(false);
  const [formattedDate, setFormattedDate] = useState(null);
  const [daysDiff, setDaysDiff] = useState(null);
  const [timer1, setTimer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [EndSigular, setEndSigular] = useState(false);
  const [EndEntire, setEndEntire] = useState(false);
  const { user } = userById;
  const productId = location?.state?.id;
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [price, setPrice] = useState(null);
  const [time, setTime] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(GetSingleProduct(params.productId));
      setQuantity(response?.data?.product?.quantity);
      setPrice(response?.data?.product?.pricing?.price);
      setTime(response?.data?.product?.createdAt);
      setUserData(response.data?.product);
      const timer = response.data?.product?.timer?.enabled;
      const deadline = response.data?.product?.timer?.deadline;
      setTimer(response.data?.product?.timer?.enabled);
      if (timer && deadline) {
        const endDate = new Date(deadline);
        if (!isNaN(endDate)) {
          setFormattedDate(endDate.toISOString());
        } else {
          // Handle invalid date format
          console.error(`Invalid date format for end: ${deadline}`);
        }
      }
      // Calculate the difference in milliseconds and set the daysDiff state
      if (formattedDate && userData.createdAt) {
        const startDate = new Date(userData.createdAt);
        const endDate = new Date(formattedDate);
        const timeDiff = endDate - startDate;
        const calculatedDaysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        setDaysDiff(calculatedDaysDiff);
      }
    };
    if (timer1 && timer1) {
      const currentDate = new Date().toISOString();
      const currentDate1 = new Date(currentDate);
      const deadlineDate = new Date(formattedDate);
      const timeDifference = deadlineDate - currentDate1;
      if (timeDifference <= 0) {
        setExpired(true);
      } else {
        setExpired(false);
        let remainingTime = timeDifference;
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        remainingTime -= days * (1000 * 60 * 60 * 24);
        const hours = Math.floor(remainingTime / (1000 * 60 * 60));
        remainingTime -= hours * (1000 * 60 * 60);
        const minutes = Math.floor(remainingTime / (1000 * 60));
        remainingTime -= minutes * (1000 * 60);
        const seconds = Math.floor(remainingTime / 1000);
        // Output the countdown
        setTimeLeft(`${days} days, ${hours} hours, ${minutes} minutes`);
      }
    }
    fetchData();
  }, [dispatch, params.productId, formattedDate, userData.createdAt]);
  const endSingleBid = async () => {
    setLoadingendbid(true);
    const data = { productId: params.productId };
    const response = await dispatch(EndOneBid(data));
    const response1 = await dispatch(GetSingleProduct(params.productId));
    setQuantity(response?.data?.product?.quantity);
    setLoadingendbid(false);
    setEndSigular(false);
  };
  const endBid = async () => {
    setLoadingendbid(true);
    const data = { productId: params.productId };
    const response = await dispatch(EndAllBid(data));
    const response1 = await dispatch(GetSingleProduct(params.productId));
    setQuantity(response?.data?.product?.quantity);
    setLoadingendbid(false);
    setEndEntire(false);
  };
  const getOldBids = async () => {
    const response = await fetch(
      `https://backend.la7i27alak.com/product/bid/previousBids/${params.productId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );
    if (response.ok) {
      const data = await response.json();
      setBidList(data.previousBids);
      setLoading(false);
    } else {
      toast.error(
        "Error",
        "Unable to get the current bids. Please try again later."
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchTokenAndData = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      setAuthToken(token);
      if (token) {
        await getOldBids();
        const socket = io("https://backend.la7i27alak.com/", {
          transports: ["websocket"],
          query: {
            type: "bid",
            productId: params.productId,
            isSeller: "false",
            authorization: token,
          },
        });
        socket.on("connect", () => {});
        socket.on("connect_error", (err) => {
          // console.log(`Connection Error: ${err.message}`);
        });
        socket.on("placeBid", (data) => {
          if (data?.numberOfBidders) {
            setBidders(data?.numberOfBidders);
          } else if (data?.message === "An item has been sold by bid!") {
            setQuantity(data?.quantityLeft);
          } else if (data?.message === "All items has been sold by bid!") {
            setQuantity(0);
          } else {
            setBidList((oldBidList) => [data?.newBid, ...oldBidList]);
          }
        });
        return () => {
          socket.disconnect();
        };
      }
    };
    fetchTokenAndData();
  }, []);
  return (
    <>
      <Helmet>
        <title>Bid Details</title>
        <meta name="Bid Details" content="Bid Details" />
        <meta
          name="keywords"
          content={`bid, bid in Lebanon, bids, biddings, Lebanon, E-commerce bid, cars, new, LA7I27ALAK, la7i27alak, product, service`}
        />
      </Helmet>
      <Layout title={"Bid Details"}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.left_side}>
              <DetailsTables
                bidDetails={bidDetails}
                bidList={bidList}
                productId={params.productId}
                quantity={quantity}
                bidders={bidders}
              />
              {userData && userData.user?._id === uid ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {quantity !== null && quantity > 0 ? (
                    quantity > 1 ? (
                      <>
                        <button
                          className={`${styles.end_bid}`}
                          onClick={() => {
                            setEndEntire(true);
                          }}
                        >
                          End Entire Bid
                        </button>
                        <button
                          className={`${styles.end_bid_s}`}
                          onClick={() => setEndSigular(true)}
                        >
                          End Singular Bid
                        </button>
                      </>
                    ) : (
                      <button
                        className={`${styles.end_bid}`}
                        onClick={() => {
                          setEndEntire(true);
                        }}
                      >
                        End Bid
                      </button>
                    )
                  ) : quantity === 0 ? (
                    <button className={`${styles.end_bid1}`} disabled={true}>
                      Out of stock
                    </button>
                  ) : null}
                </div>
              ) : (
                <>
                  {quantity !== null && quantity > 0 ? (
                    <button
                      className={`${styles.bid} ${
                        darkMode ? styles.bidDark : ""
                      }`}
                      onClick={() => {
                        if (!expired) {
                          setBidModal(true);
                        }
                      }}
                      disabled={expired}
                    >
                      {expired ? "Bid expired" : "Place a bid"}
                    </button>
                  ) : quantity === 0 ? (
                    <button className={`${styles.end_bid1}`} disabled={true}>
                      Out of stock
                    </button>
                  ) : null}
                </>
              )}
            </div>
            <div className={styles.right_side}>
              <BiddersTable
                bidDetails={bidDetails}
                bidList={bidList}
                price={price}
                time={time}
              />
            </div>
          </div>
        )}
        <PlaceBidModal
          open={bidModal}
          closeHandler={() => setBidModal(false)}
          id={params.productId}
          bidDetails={bidDetails}
          bidList={bidList}
          productId={params ? params.productId : null}
        />
      </Layout>
      <ConfirmModal isOpen={EndEntire} onClose={() => setEndEntire(false)}>
        <h1
          className={`${styles.confirm_title} ${
            darkMode ? styles.confirm_titleDark : ""
          }`}
        >
          Confirmation
        </h1>
        <p
          className={`${styles.confirm_message} ${
            darkMode ? styles.confirm_messageDark : ""
          }`}
        >
          This will stop the bidding and award the complete product quantity to
          the highest bidder.
        </p>
        {loadingendbid ? (
          <div className="center">
            <Loader />{" "}
          </div>
        ) : (
          <div className={styles.buttons_container}>
            <button
              className={styles.cancel}
              onClick={() => setEndSigular(false)}
            >
              Discard
            </button>
            <button className={styles.confirm} onClick={() => endBid()}>
              Proceed
            </button>
          </div>
        )}
      </ConfirmModal>
      <ConfirmModal isOpen={EndSigular} onClose={() => setEndSigular(false)}>
        <h1
          className={`${styles.confirm_title} ${
            darkMode ? styles.confirm_titleDark : ""
          }`}
        >
          Warning!
        </h1>
        <p
          className={`${styles.confirm_message} ${
            darkMode ? styles.confirm_messageDark : ""
          }`}
        >
          This will result in selling one item at the price offered by the
          highest bidder, to that very highest bidder.
        </p>
        {loadingendbid ? (
          <div className="center">
            <Loader />{" "}
          </div>
        ) : (
          <div className={styles.buttons_container}>
            <button
              className={styles.cancel}
              onClick={() => setEndSigular(false)}
            >
              Discard
            </button>
            <button className={styles.confirm} onClick={() => endSingleBid()}>
              Proceed
            </button>
          </div>
        )}
      </ConfirmModal>
    </>
  );
};
export default BidDetailsPage;
