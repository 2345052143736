import { ADD_TOKEN_FAIL, ADD_TOKEN_REQUEST, ADD_TOKEN_SUCCESS } from "../constants/authConstants";
import {
  UPLOAD_PROFILE_PICUTRE_FAIL,
  UPLOAD_PROFILE_PICUTRE_REQUEST,
  UPLOAD_PROFILE_PICUTRE_SUCCESS,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  USER_RESET,
  UPLOAD_PROFILE_PICUTRE_RESET,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_RESET,
  UPDATE_USER_FAIL,
  GET_USERCARD_BY_ID_REQUEST,
  GET_USERCARD_BY_ID_SUCCESS,
  GET_USERCARD_BY_ID_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_USER_RESET,
  UPDATE_GEOLOCATION_REQUEST,
  UPDATE_GEOLOCATION_SUCCESS,
  UPDATE_GEOLOCATION_RESET,
  UPDATE_GEOLOCATION_FAIL,
  GET_GEOLOCATION_REQUEST,
  GET_GEOLOCATION_SUCCESS,
  GET_GEOLOCATION_RESET,
  GET_GEOLOCATION_FAIL,
  GET_USER_CATEGORY_SUCCESS,
  GET_USER_CATEGORY_FAIL
} from "../constants/userConstants";
export const AddTokensReducer = (state = { success: null }, action) => {
  switch (action.type) {
    case ADD_TOKEN_REQUEST:
      return { ...state, loading: true };
    case ADD_TOKEN_SUCCESS:
      return { ...state, loading: false, success: action.payload };
    case ADD_TOKEN_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const profilePictureReducer = (state = { image: null }, action) => {
  switch (action.type) {
    case UPLOAD_PROFILE_PICUTRE_REQUEST:
      return { ...state, loading: true };
    case UPLOAD_PROFILE_PICUTRE_SUCCESS:
      return { ...state, loading: false, image: action.payload };
    case UPLOAD_PROFILE_PICUTRE_FAIL:
      return { ...state, loading: false, error: action.payload };
    case UPLOAD_PROFILE_PICUTRE_RESET:
      return { image: null };
    default:
      return state;
  }
};
export const userByIdReducer = (
  state = { loading: false, user: null, error: null },
  action
) => {
  switch (action.type) {
    case GET_USER_BY_ID_REQUEST:
      return { ...state, loading: true };
    case GET_USER_BY_ID_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case GET_USER_BY_ID_FAIL:
      return { ...state, loading: false, error: action.payload };
    case USER_RESET:
      return { loading: false, user: null, error: null };
    default:
      return state;
  }
};
export const updateUserReducer = (
  state = { loading: false, success: false, error: null },
  action
) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return { ...state, loading: true };
    case UPDATE_USER_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_USER_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getusercardratesReducer = (
  state = { loading: false, usercard: null, error: null },
  action
) => {
  switch (action.type) {
    case GET_USERCARD_BY_ID_REQUEST:
      return { ...state, loading: true };
    case GET_USERCARD_BY_ID_SUCCESS:
      return { ...state, loading: false, usercard: action.payload };
    case GET_USERCARD_BY_ID_FAIL:
      return { ...state, loading: false, error: action.payload };
    case USER_RESET:
      return {};
    default:
      return state;
  }
};
export const changePasswordReducer = (
  state = { loading: false, success: false, error: null },
  action
) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, success: true };
    case CHANGE_PASSWORD_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
// when you visit a user
export const userReducer = (
  state = { userDetails: null, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return { ...state, loading: true };
    case GET_USER_SUCCESS:
      return { ...state, loading: false, userDetails: action.payload };
    case GET_USER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_USER_RESET:
      return { user: null, loading: false, error: null };
    default:
      return state;
  }
};
const initialState = {
  userId: [],
  error: null,
  loading: false
};
export const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_CATEGORY_SUCCESS":
      return {
        ...state,
        userId: action.payload,
        loading: false,
        error: null,
      };
    case "GET_USER_CATEGORY_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
