import React, { useState } from 'react';
import styles from './SearchChat.module.scss';
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from 'react-redux';
const SearchChat = () => {
    const [activeButton, setActiveButton] = useState('All');
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    const handleButtonClick = (button) => {
      setActiveButton(button);
    };
  return (
    <div className={styles.search_chat}>
      <div className={styles.search_input}>
      {/* <AiOutlineSearch className={styles.searchic}  />
        <input type="text" className={`${darkMode ? styles.searchinpDark: styles.searchinp}`} placeholder="Search" style={{paddingLeft:50}}/> */}
      </div>
    </div>
  );
};
export default SearchChat;
