import React, { useState, useEffect } from "react";
import styles from "./RatingCard.module.scss";
import { FaStar, FaRegStar } from "react-icons/fa";
import { GetAverageReview } from "../../../../store/actions/userActions";
import { useSelector, useDispatch } from "react-redux";
const RatingCard = ({ reviews, id, averageStar }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const ratingCounts = reviews.reduce(
    (acc, cur) => {
      acc.count += 1;
      acc.total += cur.rating;
      acc[cur.rating] += 1;
      return acc;
    },
    { count: 0, total: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
  );
  const average = useSelector((state) => state.AverageReview?.AverageReview)
  useEffect(() => {
    const response = dispatch(GetAverageReview(id))
   }, [dispatch, id])
 
  // Calculate the percentage of each rating value
  const percentage = (count, total) => {
    return total === 0 ? 0 : Math.round((count / total) * 100);
  };
  function getPercentageByRating(rating) {
    if (average && average.starPercentages) {
      switch (rating) {
        case 5:
          return average.starPercentages['5'] || 0;
        case 4:
          return average.starPercentages['4'] || 0;
        case 3:
          return average.starPercentages['3'] || 0;
        case 2:
          return average.starPercentages['2'] || 0;
        case 1:
          return average.starPercentages['1'] || 0;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  }
  return (
    <div className={`${styles.rating_container} ${darkMode ? styles.rating_containerDark : ''}`}>
      <div className={styles.score_container}>
        <h4 className={`${styles.score} ${darkMode ? styles.scoreDark : ''}`}>
          {averageStar}/5
        </h4>
        <p className={styles.score_text}>Based on {ratingCounts.count} reviews</p>
        <div className={styles.score_star}>
          {[...Array(5)].map((_, i) => (
            <React.Fragment key={i}>
              {i < Math.floor(averageStar)  ? (
                <FaStar />
              ) : (
                <FaRegStar />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className={styles.stars_container}>
        {[5, 4, 3, 2, 1].map((rating) => (
          <div className={styles.star} key={rating}>
            {rating} stars
            <div className={styles.line}>
              <div
                className={styles.filled}
                style={{ width: `${getPercentageByRating(rating)}%` }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default RatingCard;
