import React, { useEffect } from "react";
import styles from "./BiddersTable.module.scss";
import { getBidersHandler } from "../../../../store/actions/bidActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../common/components/Loader/Loader";
import ErrorMessage from "../../../../common/components/ErrorMessage/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
const BiddersTable = ({ bidDetails, bidList, price, time }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const getBiders = useSelector((state) => state.getBiders);
  const { biders, error, loading } = getBiders;
  const placeBid = useSelector((state) => state.placeBid);
  const { success } = placeBid;
  const displayTime = (time) => {
    let fulltime = format(parseISO(time, new Date()), "dd MMMM yyyy HH:mm");
    let begintime = fulltime.slice(0, -5);
    let endttime = fulltime.slice(-5);
    return `${begintime} at ${endttime}`;
  };
  const date = new Date(time);
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={`${styles.table_heading} ${darkMode ? styles.table_headingDark : ''}`}>
            <th>Bider</th>
            <th>Bider Amount</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>

          {loading ? (
            <div className="center">
              <Loader />
            </div>
          ) : (
            bidList.map((bider, i) => {
              if (bider !== undefined) {
                const date = new Date(bider?.timestamp);
                const options = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                };
                const formattedDate = date.toLocaleDateString("en-US", options);
                return (
                  <tr key={i}>
                    <td className={styles.name_container}>
                      <div className={styles.name_content}>
                        <img src={bider?.user?.imageUrl} alt="" className={styles.img} />
                        <p
                          className={`${styles.name} ${darkMode ? styles.nameDark : ""}`}
                        >
                          {bider?.user?.username}
                        </p>
                      </div>
                    </td>
                    <td className={`${styles.amount} ${darkMode ? styles.amountDark : ""}`}>
                      ${bider?.amount}
                    </td>
                    <td className={`${styles.time} ${darkMode ? styles.timeDark : ""}`}>
                      {formattedDate}
                    </td>
                  </tr>
                );
              }
              return null;
            })
          )}
          <tr key={1}>
            <td className={styles.starting_container}>
              <div className={styles.starting_content}>
                <p
                  className={`${styles.starting} ${darkMode ? styles.startingDark : ""}`}
                >
                  {"Starting Price"}
                </p>
              </div>
            </td>
            <td className={`${styles.amount} ${darkMode ? styles.amountDark : ""}`}>
              ${price && price}
            </td>
            <td className={`${styles.time} ${darkMode ? styles.timeDark : ""}`}>
              {formattedDate && formattedDate}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default BiddersTable;
