import React, { useState,useEffect } from "react";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import { ReactComponent as Back } from "../../../../assests/svg/back.svg";
import { ReactComponent as BackDark } from "../../../../assests/svg/arrowleftDark.svg";
import { ReactComponent as Phone } from "../../../../assests/svg/gray phone.svg";
import styles from "./styles.module.scss";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./styles.scss";
import { auth } from "../../../../firebase";
import { PhoneAuthProvider, RecaptchaVerifier, linkWithCredential, signInWithPhoneNumber } from "firebase/auth";
import axios from "axios";
import { loginGoogle } from "../../../../store/actions/authActions";
import { useDispatch ,useSelector} from "react-redux";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const LoginWithCode = ({ setStep, closeHandler }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [sucmsg, setSucmsg] = useState('')
  const [ermsg, setErmsg] = useState('')
  const [errmsg, setErrmsg] = useState('')
  const [value, setValue] = useState();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [confirmation, setConfirmation] = useState(null);
  //changed
  const [clicked, setClicked] = useState(false);
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => { },
        },
        auth
      );
    }
  }
  const userid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`https://backend.la7i27alak.com/user/${userid}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setPhone(response.data.user.phone)
      })
      .catch((error) => console.error(error));
  }, [userid, token]);
  const baseUrl = 'https://backend.la7i27alak.com'
  function onSignup() {
    setClicked(true)
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        setConfirmation(confirmationResult);
        window.confirmationResult = confirmationResult;
        setSucmsg(`Verification Code Sucssesfully Sent To ${phone}`)
      })
      .catch((error) => {
        // console.log(error);
        // setErrmsg(`Error Sending Code To  ${phone}`);
      });
  }
   function onOTPVerify() {
    if (!otp) {
      // Handle case when OTP is not entered
      setErmsg('Please enter the verification code');
      return;
    }
  
    if (confirmation) {
      try {
        const credential = PhoneAuthProvider.credential(
          confirmation.verificationId,
          otp,
        );
  
        // Now, we link the user with the phone credential
       linkWithCredential(auth.currentUser,credential) 
        .then((usercred) => {
          const user = usercred.user;
        }).catch((error) => {
          // // // console.log("Account linking error", error);
        })
      } catch (error) {
        setErmsg('Incorrect verification code. Please enter the correct verification code.');
        // console.log('err', error);
      }
    } else {
      // console.log('No confirmation result');
    }
  }
  
  const handlepassInputFocus = () => {
    setSucmsg('')
    setErmsg("");
  };
  //
  return (
    <>
    
    <div className={styles.container}>
      
     
          <div
            className={`${styles.skip} ${darkMode? styles.skipDark:''}`}
            onClick={() => {
              closeHandler()
            }}
          >Skip </div>
          
      <h1 className={styles.logo}>LOGO</h1>
      <div className={styles.form_container}>
        <label htmlFor="phone" className={`${styles.label} ${darkMode? styles.labelDark:''}`}>
          Enter phone number
        </label>
        <div className={styles.phone_input_container}>
          <div className="big--phone">
          <PhoneInput
                  defaultCountry="LB"
                  placeholder="Phone number"
                  value={phone}
                  disabled={true}
                  autoFocus
                  className={`${styles.phonei} ${darkMode?styles.phoneiDark:''}`}
                 />
          </div>
          <Phone className={styles.phone} />
        </div>
        <div className={styles.code_container}>
          <input
            type="number"
            className={`${styles.code_input} ${darkMode? styles.code_inputDark:''}`}
            placeholder="Enter 4-digit code"
            value={otp}
            onChange={(event) => setOtp(event.target.value)}
            onFocus={handlepassInputFocus}
          />
          <button
            className={`${styles.send_btn} ${darkMode? styles.send_btnDark:''}`}
            // onClick={() => console.log(value)}
            onClick={onSignup}//
          >
            {clicked ? 'Resend Code' : 'Send Code'}
          </button>
        </div>
        <div id="recaptcha-container" style={{display:'none'}}></div>
        <div style={{color:'red'}}>{errmsg}</div>
        <div style={{color:'red'}}>{ermsg}</div>
        <div style={{color:'#0298a6'}}>{sucmsg}</div>
        <button onClick={onOTPVerify} className={`${styles.confirm_btn} ${darkMode? styles.confirm_btnDark:''}`}>Confirm</button>
      </div>
      <p className={`${styles.register_text} ${darkMode? styles.register_textDark:''}`}>
        Don't have an account?{" "}
        <span className={styles.register} onClick={() => setStep("register")}>
          Register
        </span>
      </p>
    </div>
    <style>
      {`
    ${darkMode ? '.big--phone .PhoneInputInput  { background: transparent;color:#a6c0c8 }' : ''}
    `}
    </style>
    </>
  );
};
export default LoginWithCode;
