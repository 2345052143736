import React from "react";
import styles from "./Request.module.scss";
import { ReactComponent as ComingSure } from "../../../assests/svg/coming sure.svg";
import { ReactComponent as Coming } from "../../../assests/svg/coming.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  acceptWaitingListHandler,
  ifanyuseracceptedHandler,
} from "../../../store/actions/waitingListActions";
import {
  AcceptOffer,
  RejectOffer
} from "../../../store/actions/bidActions";
import { GetProductOffer } from "../../../store/actions/bidActions";
const Request = ({ offerId, sure, image, username, userId, productId, time }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = { "productId": productId, "offerId": offerId }
  async function acceptOffer() {
    try {
      const response = await dispatch(AcceptOffer(data));
       dispatch(GetProductOffer(productId))
    } catch (error) {
      console.error(error);
    }
  }
  async function rejectOffer() {
    try {
      const response = await dispatch(RejectOffer(data));
       dispatch(GetProductOffer(productId))
    } catch (error) {
      console.error(error);
    }
  }
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <li className={styles.item}>
      <div className={styles.line}></div>
      <img src={image} alt="profile" className={styles.image} />
      <div className={styles.info_container}>
        <h1
          className={`${styles.name} ${darkMode? styles.nameDark:''}`}
          onClick={() =>
            navigate(`/user-review/${username.replace(" ","-")}/${userId}`, {
              state: {
                id: userId,
              },
            })
          }
        >
          {username}
        </h1>
        {sure ? (
          <p
            className={`${styles.text} ${sure ? styles.sure : styles.notsure}`}
          >
            <ComingSure className={styles.icon} />I am coming now For Sure
          </p>
        ) : (
          <p
            className={`${styles.text} ${sure ? styles.sure : styles.notsure}`}
          >
            <Coming className={styles.icon} />I am coming now
          </p>
        )}
        <div className={styles.button_container}>
          <button
            className={styles.accept}
            onClick={() => acceptOffer()}
          >
            Accept
          </button>
          <button
            className={styles.reject}
            onClick={() => rejectOffer()}
          >
            Reject</button>
        </div>
      </div>
      <div className={styles.stats_container}>
        <p className={`${styles.time} ${darkMode? styles.timeDark:''}`}>   {time === "now" ? 'now' : `${time} ago`}</p>
      </div>
    </li>
  );
};
export default Request;
