import {
    GET_PRODUCT_CAT_SUCCESS,
    GET_PRODUCT_CAT_FAIL,
    GET_PRODUCT_CAT_REQUEST,
} from "../constants/productDetailsConstants";
import{
GET_ALL_CATEGORIES_SUCCESS,
GET_ALL_CATEGORIES_REQUEST,
GET_ALL_CATEGORIES_FAIL,
} from "../constants/categoryConstants"
const initialState = {
    cat: [],
    error: null,
    loading: false,
};
export const categSkelReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_CAT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_PRODUCT_CAT_SUCCESS:
            return {
                ...state,
                cat: action.payload,
                error: null,
                loading: false,
            };
        case GET_PRODUCT_CAT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
//in home page
const initialState1 = {
    allcat: [],
    error: null,
    loading: false,
};
export const allcategSkelReducer = (state = initialState1, action) => {
    switch (action.type) {
        case GET_ALL_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_CATEGORIES_SUCCESS:
            return {
                ...state,
                cat: action.payload,
                error: null,
                loading: false,
            };
        case GET_ALL_CATEGORIES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
