import React, { useEffect, useState } from "react";
import EmptyWishList from "./components/EmptyWishlist/EmptyWishList";
import NotEmptyWishList from "./components/NotEmptyWishList/NotEmptyWishList";
import Header from "../../common/components/Header/Header";
import Footer from "../../common/components/Footer/Footer";
import CurrentPath from "../../common/components/CurrentPath/CurrentPath";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { listMyWhishlist } from "../../store/actions/whishlistActions";
import Loader from "../../common/components/Loader/Loader";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { REMOVE_ITEM_WHISHLIST_RESET } from "../../store/constants/wishlistConstants";
import { handleFilterIcon } from "../../store/actions/filterActions";
import { GetWishList, GetWishlistSuccess } from "../../store/actions/whishlistActions";
import { Helmet } from "react-helmet";
import { GetWishListService } from "../../store/actions/whishlistActions";
import { GetWishlistServiceSuccess } from "../../store/actions/whishlistActions";
import { GetServicesWishlist } from "../../store/actions/servicesActions";
const WishList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch({ type: "CLEAR_BREADCRUMBS" })
    const pageName = "Wishlist"; // Replace this with the actual page name
    const pageUrl = "/wishlist"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const userById = useSelector((state) => state.userById);
  const { user } = userById;
  const whishList = useSelector((state) => state.whishList);
  const { loading, error, whishlist } = whishList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const WishListService = useSelector((state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList)
  const Wishlist = useSelector((state) => state.Wishlist?.Wishlist?.data?.wishList)
  const removeFromWishList = useSelector((state) => state.removeFromWishList);
  const { success } = removeFromWishList;
  useEffect(() => {
    dispatch(GetWishList())
    dispatch(GetServicesWishlist())
  }, [dispatch]);
  useEffect(() => {
    dispatch(handleFilterIcon(false));
    if (user !== null) {
      dispatch(listMyWhishlist(user.id));
    } else if (userInfo === null) {
      navigate("/");
    }
  }, [dispatch, user, userInfo, navigate, success]);
  const Page = () => {
    if (Wishlist && Wishlist.length === 0) {
      return <EmptyWishList />;
    } else {
      return <NotEmptyWishList whishlist={Wishlist} WishListService={WishListService} />;
    }
  };
  return (
    <>
      <Helmet>
        <title>WishList</title>
        <meta name="Wishlist" content="Favorite Products" />
        <meta
          name="keywords"
          content={`Wishlist, la7i27alak`}
        />
      </Helmet>
      <Header />
      <div className={`${styles.container} ${darkMode ? styles.containerDark : ''}`}>
        <CurrentPath />
        {loading ? (
          <div className="center">
            <Loader />
          </div>
        ) : error ? (
          <div className="center">
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        ) : (
          Page()
        )}
      </div>
      <Footer />
    </>
  );
};
export default WishList;
