import React, { useState, useEffect } from "react";
import styles from "./AdminLayout.module.scss";
import { TbLockOpen, TbMenu2 } from "react-icons/tb";
import { AiFillHome } from "react-icons/ai";
import { BiCalendar, BiCategory, BiLogOut } from "react-icons/bi";
import { BsChatQuote, BsFillBellFill } from "react-icons/bs";
import { CiCirclePlus } from "react-icons/ci";
import { VscReport } from "react-icons/vsc";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
  FaUsers,
} from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { logout, logoutadm } from "../../../store/actions/authActions";
import { Helmet } from "react-helmet";
import logo from '../../../assests/svg/La7i2_7alak1.1.svg'
const menuItem = [
  {
    path: "/admin/dashboard",
    name: "Dashboard",
    icon: <FaTh />,
  },
  {
    path: "/admin/adminCategories",
    name: "Categories",
    icon: <BiCategory />,
  },
  {
    path: "/admin/products",
    name: "Products",
    icon: <FaShoppingBag />,
  },
  {
    path: "/admin/catchoftheday",
    name: "Catch of the Day",
    icon: <BiCalendar />,
  },
  {
    path: "/admin/analytics",
    name: "User Analytics",
    icon: <FaUserAlt />,
  },
  {
    path: "/admin/dashboardUser",
    name: "Dashboard User",
    icon: <FaUsers />,
  },
  {
    path: "/admin/notification",
    name: "Contact us",
    icon: <BsFillBellFill />,
  },
  { 
    path: "/admin/reports",
    name: "All Reports",
    icon: <VscReport />,
  },
    { 
    path: "/admin/deals",
    name: "All Deals",
    icon: <VscReport />,
  },
  {
    path: "/admin/sets",
    name: "Settings",
    icon: <CiCirclePlus />,
  },
  {
    path: "/admin/adminchat",
    name: "Chats",
    icon: <BsChatQuote />,
  },
  {
    path: "/",
    name: "Home Page",
    icon: <BiLogOut />,
  },
];
const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen((prevState) => !prevState);
  };
  const navigate = useNavigate()
  const [username, setUsername] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const handlelogout = () => {
    dispatch(logoutadm());
    // localStorage.removeItem("admInfo");
    navigate('/admin/login')
  };
  const gotoprofile = () => {
    navigate('/admin/profile')
  };
  const userid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`https://backend.la7i27alak.com/user/${userid}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setUsername(response.data.username);
        setImageUrl(response.data.imageUrl);
      })
      .catch((error) => console.error(error));
  }, [userid, token]);
  const baseUrl = 'https://backend.la7i27alak.com'
  return (
    <>
      <Helmet>
        <title>Admin</title>
      </Helmet>
      <div className={styles.wrapper}>
        <div
          style={{ width: open ? "200px" : "50px" }}
          className={styles.sidebar}
        >
          <div className={styles.top_section}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={logo}
                alt="logo"
                style={{ display: open ? "block" : "none" }}
                className={styles.logo}
              />
            </div>
            <div
              style={{ marginLeft: open ? "50px" : "0px" }}
              className={styles.bars}
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className={styles.link}
              activeclassName={styles.active_link}
            // activeclassName="activeroute"
            >
              <div className={styles.icon}>{item.icon}</div>
              <div
                style={{ display: open ? "block" : "none" }}
                className={styles.link_text}
              >
                {item.name}
              </div>
            </NavLink>
          ))}
        </div>
        <div className={styles.main}>{children}</div>
      </div>
    </>
  );
};
export default AdminLayout;