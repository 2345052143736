import React, { useState, useEffect } from "react";
import styles from "./CurrentPath.module.scss";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
const CurrentPath = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const breadcrumbs = useSelector((state) => state.breadcrumbs);
  const handleBreadcrumbClick = (index) => {
    // Dispatch an action to remove breadcrumbs after the clicked one
    dispatch({ type: "REMOVE_BREADCRUMBS", payload: index });
  };
  useEffect(() => {
    const handlePopstate = () => {
      if (breadcrumbs.length > 0) {
        dispatch({ type: "REMOVE_BREADCRUMBS", payload: breadcrumbs.length - 1 });
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [breadcrumbs, dispatch]);
  return (
    <div className={styles.path_container}>
      {breadcrumbs.map((a, index) => (
        <span key={index}>
          <a href={a.url}
            style={{ textDecoration: 'none' }}
            onClick={(e) => {
              e.preventDefault();
              if(index !== breadcrumbs.length - 1) {
                handleBreadcrumbClick(index);
                navigate(a.url);
              }
            }}>
            <span className={`${styles.curent_path} ${darkMode ? styles.curent_pathDark : ''}`}>
              {a.name}
              {index !== breadcrumbs.length - 1 && (
                <AiOutlineRight className={`${styles.icon} ${darkMode ? styles.iconDark : ""}`} />
              )}
            </span>
          </a>
        </span>
      ))}
    </div>
  );
};
export default CurrentPath;
