import {
    GET_SERVICES_WISHLIST_SUCCESS,
    GET_SERVICES_WISHLIST_FAIL
} from "../constants/servicesConstants";
const initialState = {
    ServicesWishlist: [],
    error: null,
    loading: false,
};
export const GetServicesWishlist = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICES_WISHLIST_SUCCESS:
            return {
                ...state,
                ServicesWishlist: action.payload,
                error: null,
                loading: false,
            };
        case GET_SERVICES_WISHLIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
