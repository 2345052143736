import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  intervalToDuration,
} from "date-fns";
export const timePosted = (time) => {
  let diffDays = differenceInDays(new Date(), new Date(time));
  let diffHrs = differenceInHours(new Date(), new Date(time));
  let diffMins = differenceInMinutes(new Date(), new Date(time));
  if (diffDays !== 0) {
    if (diffDays > 1) {
      return `${diffDays} days`;
    } else {
      return `${diffDays} day`;
    }
  }
  if (diffHrs >= 1) {
    return `${diffHrs} h`;
  }
  if (diffMins >= 1) {
    return `${diffMins} min`;
  }
  return 'now';
};
export const timeLeft = (startTime, endTime) => {
  let diff = intervalToDuration({
    start: new Date(startTime),
    end: new Date(endTime),
  });
  if (diff.years !== 0) {
    return `${diff.years} years ${diff.month >= 1 ? `${diff.month} month` : ""
      } left`;
  } else if (diff.months !== 0 && diff.years === 0) {
    return `${diff.months} months ${diff.days >= 1 ? `${diff.days}d` : ""
      }  left`;
  } else if (diff.days !== 0 && diff.months === 0) {
    return `${diff.days}d ${diff.hours >= 1 ? `${diff.hours}h` : ``} left`;
  } else if (diff.hours !== 0 && diff.days === 0) {
    return `${diff.hours}h ${diff.minutes >= 1 ? `${diff.minutes}m` : ``} left`;
  }
};
export const countDown = (endDate) => {
  if (!endDate) {
    return `Invalid date`;
  }
  const currentDate = new Date().toISOString();
  const end = new Date(endDate).toISOString();
  const currentDate1 = new Date(currentDate);
  const end1 = new Date(end);
  const timeDifference = end1 - currentDate1;
  if (isNaN(timeDifference) || timeDifference <= 0) {
    return `Expired`;
  } else {
    let remainingTime = timeDifference;
    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    remainingTime -= days * (1000 * 60 * 60 * 24);
    const hours = Math.floor(remainingTime / (1000 * 60 * 60));
    remainingTime -= hours * (1000 * 60 * 60);
    const minutes = Math.floor(remainingTime / (1000 * 60));
    remainingTime -= minutes * (1000 * 60);
    if (days !== 0) {
      return `${days}d ${hours}h left`;
    } else if (hours !== 0) {
      return `${hours}h ${minutes}m left`;
    } else {
      return `${minutes}m left`; // Only minutes left
    }
  }
};
