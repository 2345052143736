export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const adm_LOGOUT = "adm_LOGOUT";
export const USER_ERROR_RESET = "USER_ERROR_RESET";
export const ADM_LOGIN_REQUEST = "ADM_LOGIN_REQUEST";
export const ADM_LOGIN_SUCCESS = "ADM_LOGIN_SUCCESS";
export const ADM_LOGIN_FAIL = "ADM_LOGIN_FAIL";
export const ADM_LOGIN_RESET = "ADM_LOGIN_RESET";
export const SET_ADM_INFO = "SET_ADM_INFO";
export const ADM_LOGOUT = "ADM_LOGOUT";
export const ADM_ERROR_RESET = "ADM_ERROR_RESET";
export const ADD_TOKEN_REQUEST = "ADD_TOKEN_REQUEST";
export const ADD_TOKEN_SUCCESS = "ADD_TOKEN_SUCCESS";
export const ADD_TOKEN_FAIL = "ADD_TOKEN_FAIL";
export const ADD_TOKEN_REST = "ADD_TOKEN_REST";
export const RESEND_CODE_SUCCESS = "RESEND_CODE_SUCCESS";
export const RESEND_CODE_FAIL = "RESEND_CODE_FAIL";
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";
export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_FAIL = "EMAIL_VERIFICATION_FAIL";
export const EMAIL_VERIFICATION_RESET = "EMAIL_VERIFICATION_RESET";
export const CODE_VERIFICATION_SEND_SUCCESS = "CODE_VERIFICATION_SEND_SUCCESS";
export const CODE_VERIFICATION_SEND_FAILED = "CODE_VERIFICATION_SEND_FAILED";
export const ACTIVATE_USER_REQUEST = "ACTIVATE_USER_REQUEST";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAIL = "ACTIVATE_USER_FAIL";
export const ACTIVATE_USER_RESET = "ACTIVATE_USER_RESET";
export const FORGET_PASSWORD_REQUEST = "FORGET_PASSWORD_REQUEST";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";
export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAIL = "GOOGLE_LOGIN_FAIL";
export const FACEBOOK_LOGIN_REQUEST = "FACEBOOK_LOGIN_REQUEST";
export const FACEBOOK_LOGIN_SUCCESS = "FACEBOOK_LOGIN_SUCCESS";
export const FACEBOOK_LOGIN_FAIL = "FACEBOOK_LOGIN_FAIL";
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";
export const FIREBASE_REGISTER_REQUEST = "FIREBASE_REGISTER_REQUEST";
export const FIREBASE_REGISTER_SUCCESS = "FIREBASE_REGISTER_SUCCESS";
export const FIREBASE_REGISTER_FAIL = "FIREBASE_REGISTER_FAIL";
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET';
export const GET_ADMIN_CHATS_SUCCESS = "GET_ADMIN_CHATS_SUCCESS";
export const GET_ADMIN_CHATS_FAIL = "GET_ADMIN_CHATS_FAIL";
