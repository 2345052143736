import {
  FEATURED_LIST_REQUEST,
  FEATURED_LIST_SUCCESS,
  FEATURED_LIST_FAIL,
  FEATURED_LIST_RESET,
} from "../constants/featuredConstants";
export const featuredListReducer = (
  state = { featuredList: [], error: null, loading: false },
  action
) => {
  switch (action.type) {
    case FEATURED_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FEATURED_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        featuredList: action.payload,
      };
    case FEATURED_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FEATURED_LIST_RESET:
      return { ...state, featuredList: [], error: null, loading: false };
    default:
      return state;
  }
};
