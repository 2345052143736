import React, {useState} from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import './ColorList.css'
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
function ColorDropdown(props) {
  const [isOpen, setIsOpen] = useState(false);
const handleChange = (color) => {
props.onChange(color);
setIsOpen(false);
};
const colorNames=[]
const toggleDropdown = () => {
  setIsOpen(!isOpen);
};
const [isDown, setIsDown] = useState(false);
  const handleArrow = () => {
    setIsDown(!isDown);
  };
return (
<div >
<button className="listb"onClick={toggleDropdown} >
        Select a color
      </button>
      {isOpen && (
        <div className="drop" >
          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={200}>
            {colorNames.map((color, index) => (
              <Dropdown.Item key={index} onClick={() => handleChange(color)} style={{ backgroundColor: 'white' }}>
                
                {color}
                
                {isDown ? <AiOutlineDown /> : <AiOutlineRight />}
              </Dropdown.Item>
            ))}
          </Scrollbars>
        </div>
      )}
</div>
);
}
export default ColorDropdown;
