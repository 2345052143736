import React from "react";
import './DateCont.scss'
import { format } from "date-fns";
import { useSelector } from "react-redux";
const DateCont = ({selectedDay}) => {
  const formattedDay = format(selectedDay, "d");
  const formattedFullDate = format(selectedDay, "EEE, MMMM yyyy");
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <div className='date_container'>
      <div className='wrapper'>
        <div className='flex'>
          <h1 className='title'>{formattedDay}</h1>
        <p className='full_date'>{formattedFullDate}</p>
        </div>
      </div>
    </div>
  );
};
export default DateCont;
