import React, { useEffect, useState } from "react";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import styles from './EditServiceCategory.module.scss'
import { Form as BootstrapForm } from "react-bootstrap";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from 'yup';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { GetTypeById } from "../../store/actions/servicesActions";
import { useDispatch, useSelector } from "react-redux";
import { EditServiceType } from "../../store/actions/servicesActions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
const EditServiceCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [subcategorySearch, setSubcategorySearch] = useState("");
  const dropdownOptions = ["list", "boolean", "string", "number"];
  const typeById = useSelector((state) => state.typeById?.typeById?.data?.type);
  useEffect(() => {
    dispatch(GetTypeById(id));
  }, [dispatch, id]);
  useEffect(() => {
    // Initialize the inputValue state when typeById.title changes
    setInputValue(typeById?.title || "");
    // Initialize the form field values when typeById.fields change
    setFormFields(
      typeById?.fields.map((field) => ({
        type: field.type,
        title: field.title,
        possibleValues: Array.isArray(field.possibleValues) ? field.possibleValues.join(', ') : '',
      })) || []
    );
  }, [typeById?.title, typeById?.fields]);
  const [formFields, setFormFields] = useState([]);
  const [inputValue, setInputValue] = useState(""); // Move the initialization here
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  return (
    <AdminLayout>
      <div className={styles.container}>
        <div className={styles.title}>
          <h4>Service Title</h4>
        </div>
        <div className={styles.input_container}>
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            className={styles.input}
          />
        </div>
        <div className={styles.fields_container}>
          <Formik
            enableReinitialize // Enable reinitialization of form values
            initialValues={{
              field: formFields, // Use the state value for the form fields
            }}
            validationSchema={Yup.object({
              field: Yup.array().of(
                Yup.object().shape({
                  type: Yup.string().required("Type is required"),
                  title: Yup.string().required("Title is required"),
                  possibleValues: Yup.string().when("type", {
                    is: (type) => ["list", "boolean"].includes(type),
                    then: Yup.string().required("Possible Values are required"),
                    otherwise: Yup.string().strip()
                  })
                })
              )
            })}
            onSubmit={(values, { resetForm }) => {
              const processedValues = values.field.map(field => {
                if (["list", "boolean"].includes(field.type)) {
                  const possibleValuesArray = field.possibleValues
                    ? field.possibleValues.split(',').map(value => value.trim())
                    : [];
                  return { ...field, possibleValues: possibleValuesArray };
                }
                const { possibleValues, ...rest } = field;
                return rest;
              });
              const combinedObject = {
                title: inputValue,
                fields: processedValues,
              };
              try {
                const response = dispatch(EditServiceType(combinedObject, id));
                if (response) {
                  toast.success("Service type edited successfully.")
                  navigate('/admin/adminCategories')
                }
              } catch (error) {
                // console.log(error);
              }
            }}
            render={({ values, handleSubmit }) => (
              <Form>
                <FieldArray
                  name="field"
                  render={arrayHelpers => {
                    const field = values.field;
                    return (
                      <div>
                        {field && field.length > 0
                          ? field.map((user, index) => (
                            <div key={index}>
                              <div className={styles.subtitle}> Field </div>
                              <div className={styles.CategName}>
                                <Field
                                  type="text"
                                  id={`field.${index}.title`}
                                  name={`field.${index}.title`}
                                  className={styles.FieldInput}
                                  placeholder="Enter Field title"
                                />
                                <ErrorMessage name={`field.${index}.title`} />
                                <Field
                                  as="select"
                                  id={`field.${index}.type`}
                                  name={`field.${index}.type`}
                                  className={styles.FieldDropdown}
                                >
                                  <option value="">Select an option</option>
                                  {dropdownOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </Field>
                                {values.field[index].type === 'list' || values.field[index].type === 'boolean' ? (
                                  <Field
                                    type="text"
                                    id={`field.${index}.possibleValues`}
                                    name={`field.${index}.possibleValues`}
                                    className={styles.possibleValues}
                                    placeholder="Enter Possible Values separated with a comma"
                                  />
                                ) : null}
                                <ErrorMessage
                                  name={`field.${index}.type`}
                                  component="div"
                                  className={styles.error}
                                />
                                <ErrorMessage
                                  name={`field.${index}.possibleValues`}
                                  component="div"
                                  className={styles.error}
                                />
                                <br />
                              </div>
                              <div className={styles.plus_button_container}>
                                <button
                                  type="button"
                                  className={styles.plus_button}
                                  onClick={() => arrayHelpers.remove(index)} // remove a field from the list
                                >
                                  -
                                </button>
                              </div>
                              <br />
                              <br />
                            </div>
                          ))
                          : null}
                        <div className={styles.plus_button_container}>
                          <button type="button" className={styles.plus_button} onClick={() =>
                            arrayHelpers.push({
                              type: "",
                              title: ""
                            })}>+</button>
                          <span>Add Specific Field</span>
                        </div>
                        <div className={styles.button_row}>
                          <button className={styles.AddSub} type="submit" onClick={handleSubmit}>Edit Service</button>
                        </div>
                      </div>
                    );
                  }}
                />
              </Form>
            )}
          />
        </div>
      </div>
    </AdminLayout>
  )
}
export default EditServiceCategory