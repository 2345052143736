import axios from "axios";
import { url } from "../../common/constants/url";
import {
  COMPANY_INDUSTRY_LIST_FAIL,
  COMPANY_INDUSTRY_LIST_REQUEST,
  COMPANY_INDUSTRY_LIST_SUCCESS,
  COMPANY_SIZE_LIST_FAIL,
  COMPANY_SIZE_LIST_REQUEST,
  COMPANY_SIZE_LIST_SUCCESS,
  POST_INDUSTRY_TYPE_SUCCESS,
  POST_INDUSTRY_TYPE_FAIL,
  POST_INDUSTRY_SIZE_SUCCESS,
  POST_INDUSTRY_SIZE_FAIL,
  GET_INDUSTRY_SIZE_SUCCESS,
  GET_INDUSTRY_SIZE_FAIL,
  GET_INDUSTRY_TYPE_SUCCESS,
  GET_INDUSTRY_TYPE_FAIL,
  POST_FAQ_SUCCESS,
  POST_FAQ_FAIL,
  GET_FAQ_SUCCESS,
  GET_FAQ_FAIL
} from "../constants/companyConstants";
export const listCompanySize = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPANY_SIZE_LIST_REQUEST,
    });
    const { data } = await axios.get(`${url}/company_size/getcompany_size`);
    dispatch({
      type: COMPANY_SIZE_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: COMPANY_SIZE_LIST_FAIL,
      payload: message,
    });
  }
};
export const listCompanyIndustry = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPANY_INDUSTRY_LIST_REQUEST,
    });
    const { data } = await axios.get(`${url}/industry_type/getindustry_type`);
    dispatch({
      type: COMPANY_INDUSTRY_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: COMPANY_INDUSTRY_LIST_FAIL,
      payload: message,
    });
  }
};
export const PostIndustryType = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.post(`${url}/industryTypes`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      
      dispatch(PostIndustryTypeSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(PostIndustryTypeFail(error));
      // console.log(error)
    }
  };
};
export const PostIndustryTypeSuccess = (categories) => {
  return {
    type: POST_INDUSTRY_TYPE_SUCCESS,
    payload: categories,
  };
};
const PostIndustryTypeFail = (error) => ({
  type: POST_INDUSTRY_TYPE_FAIL,
  payload: error
});
export const PostIndustrySize = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.post(`${url}/industrySizes`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      
      dispatch(PostIndustrySizeSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(PostIndustrySizeFail(error));
      // console.log(error)
    }
  };
};
export const PostIndustrySizeSuccess = (categories) => {
  return {
    type: POST_INDUSTRY_SIZE_SUCCESS,
    payload: categories,
  };
};
const PostIndustrySizeFail = (error) => ({
  type: POST_INDUSTRY_SIZE_FAIL,
  payload: error
});
export const GetIndustrySize = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.get(`${url}/getIndustrySizes`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      
      dispatch(GetIndustrySizeSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetIndustrySizeFail(error));
      // console.log(error)
    }
  };
};
export const GetIndustrySizeSuccess = (categories) => {
  return {
    type: GET_INDUSTRY_SIZE_SUCCESS,
    payload: categories,
  };
};
const GetIndustrySizeFail = (error) => ({
  type: GET_INDUSTRY_SIZE_FAIL,
  payload: error
});
export const GetIndustryType = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.get(`${url}/getIndustryTypes`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      
      dispatch(GetIndustryTypeSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetIndustryTypeFail(error));
      // console.log(error)
    }
  };
};
export const GetIndustryTypeSuccess = (categories) => {
  return {
    type: GET_INDUSTRY_TYPE_SUCCESS,
    payload: categories,
  };
};
const GetIndustryTypeFail = (error) => ({
  type: GET_INDUSTRY_TYPE_FAIL,
  payload: error
});
export const PostFAQ = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.post(`${url}/faq`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      
      dispatch(PostFAQSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(PostFAQFail(error));
      // console.log(error)
    }
  };
};
export const PostFAQSuccess = (categories) => {
  return {
    type: POST_FAQ_SUCCESS,
    payload: categories,
  };
};
const PostFAQFail = (error) => ({
  type: POST_FAQ_FAIL,
  payload: error
});
export const GetFAQ = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.get(`${url}/faq/getall`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      
      dispatch(GetFAQSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetFAQFail(error));
      // console.log(error)
    }
  };
};
export const GetFAQSuccess = (categories) => {
  return {
    type: GET_FAQ_SUCCESS,
    payload: categories,
  };
};
const GetFAQFail = (error) => ({
  type: GET_FAQ_FAIL,
  payload: error
});