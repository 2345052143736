import React, { useState, useEffect, useRef } from "react";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap-submenu/dist/index.css";
import "./CategoriesDropDown.css"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styles from './index.scss'
function CategoriesDropDown({ handleSubCategoryChange }) {
  const navigate = useNavigate();
  const categories = useSelector((state) => state.categories?.categories?.data?.categories || []);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const handleClick = (category, subCategory) => {
    const categorySlug = category.title.replaceAll(" ", "-").replaceAll("&", "And").replaceAll(",", "");
    const CategoryId = category._id
    const subCategorySlug = subCategory.title.replaceAll(" ", "-").replaceAll("&", "And").replaceAll(",", "");
    navigate(`/categories/${categorySlug}/${subCategorySlug}/${subCategory._id}/${CategoryId}`);
    handleSubCategoryChange(subCategory._id);
  }
  return (
    <NavDropdown
      id="nav-dropdown-dark-example"
      title="All Categories"
      menuVariant={darkMode ? "dark" : "white"}
      className={`text ${darkMode ? 'textDark' : ''}`}
    >
      {categories.map((category) => (
        <DropdownSubmenu
          key={category.title}
          href={category.title}
          title={category.title}
        >
          {category.subCategories.map((subCategory) => (
            <NavDropdown.Item
              key={subCategory._id}
              style={darkMode ? { background: "#353844", color: "lightgray", marginTop: '-8px', marginBottom: '-8px' } : {}}
              onClick={() => handleClick(category, subCategory)}
            >
              {subCategory.title}
            </NavDropdown.Item>
          ))}
        </DropdownSubmenu>
      ))}
    </NavDropdown>
  );
}
export default CategoriesDropDown;
