import React, { useState, useEffect, useRef } from "react";
import styles from "./RegisterModal.module.scss";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as Back } from "../../../../assests/svg/back.svg";
import { ReactComponent as BackDark } from "../../../../assests/svg/arrowleftDark.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import { ReactComponent as Plus } from "../../../../assests/svg/Plus Sign2.svg";
import { BiHide, BiShow, BiLockAlt } from "react-icons/bi";
import Profile from "../../../../assests/svg/Icon material-person.svg";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import registerUser from '../../../../firebase'
import firebase from 'firebase/compat/app';
import axios from 'axios';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AiOutlineCamera,
  AiOutlineUpload,
  AiOutlineMail,
  AiOutlineLock,
  AiOutlineUser,
  AiOutlineCalendar,
} from "react-icons/ai";
import { Modal, Button } from 'react-bootstrap'
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../../../store/actions/authActions";
import Loader from "../../Loader/Loader";
import { auth } from "../../../../firebase";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { passwordRules } from "../../../constants/regex";
import { toast } from "react-toastify";
const RegisterModal = ({ setStep, closeHandler, setFormData }) => {
  const dispatch = useDispatch();
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // Months are zero-indexed
    let day = today.getDate();
    // Ensure the month and day are formatted with leading zeros if needed
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`;
  }
  const userRegister = useSelector((state) => state.userRegister);
  const { error, userData } = userRegister;
  const [loading, setIsLoading] = useState(false);
  const profilePicture = useSelector((state) => state.profilePicture);
  const { loading: loadingImage, error: errorImage } = profilePicture;
  const [fileUploaded, setFileUploaded] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  //eyeToggle
  const [password, setPassword] = useState(false);
  const [Cpassword, setCPassword] = useState(false);
  //dropdown
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);//
  let menuRef = useRef();
  // file uploade
  const hiddenFileInput = React.useRef(null);
  const [preview, setPreview] = useState(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    reader.onloadend = () => {
      setPreview(reader.result); // set preview state to base64-encoded image string
    };
    setOpen(false);
  };
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  ///////////////
  async function registerUser(email, password) {
    try {
      // Create user with email and password
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      // Send email verification
      await userCredential.user.sendEmailVerification();
      // Get token for email verification
      const token = await userCredential.user.getIdToken();
      // Return user object and token
      return { user: userCredential.user, token };
    } catch (error) {
      console.error('Registration failed:', error);
      throw error;
    }
  }
  const [errorMessage, setErrorMessage] = useState(null);
  const [failMessage, setFailMessage] = useState('');
  const handleInputBlur = () => {
    if (failMessage !== '') {
      setFailMessage('');
    }
  }
  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    if (selectedDate > currentDate) {
      formik.setFieldValue('dateofbirth', currentDate.toISOString().substr(0, 10));
    } else {
      formik.setFieldValue('dateofbirth', e.target.value);
    }
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      phone: "",
      dateofbirth: getCurrentDate(),
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values, fileUploaded) => {
      const { email, password, phone, username, USER, dateofbirth } = values;
      try {
        setIsLoading(true)
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const token = await userCredential.user.getIdToken();
        localStorage.setItem('token', token);
        localStorage.setItem("uid", userCredential.user.uid);
        const formData = new FormData();
        formData.append('username', username);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('role', 'USER');
        formData.append('dob', dateofbirth)
        if (image) {
          formData.append('image', image);
        }
        if (phone && phone.length < 10) {
          toast.warning("Please write a valid phone number.")
        } else {
          const response = await axios.post('https://backend.la7i27alak.com/user', formData, {
            headers: {
              'Authorization': `${token}`,
              'Content-Type': 'multipart/form-data'
            }
          });
          const newImageUrl = response.data.imageUrl;
          setImage(newImageUrl);
          setStep('verify email')
          setIsLoading(false)
        }
      } catch (error) {
        console.error(error);
        if (error.message.includes("email-already-in-use")) {
          toast.error("This email is already registered. Please use a different email or try to login.")
          setIsLoading(false)
        } else {
          setErrorMessage(error.message);
        }
      }
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .max(15, "Must be 15 charachter or less")
        .required("Username is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
      phone: Yup.string()
        .required("Phone number is required"),
      dateofbirth: Yup.date().required("Date of birth is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(passwordRules, {
          message: "The password must include one digit, lowercase letter, and uppercase letter.",
        }),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
  });
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <>
      <div
        className={`${styles.register_container} ${darkMode ? styles.register_containerDark : ''}`}
      >
        {/* {showModal && <div className={styles.modal}></div>} */}
        {darkMode ?
          (<CloseDark
            className={`${styles.close}`}
            onClick={() => {
              closeHandler();
              setStep("login");
            }}
          />) : (<Close
            className={`${styles.close}`}
            onClick={() => {
              closeHandler();
              setStep("login");
            }}
          />)}
        {darkMode ?
          (<BackDark
            className={`${styles.back}`}
            onClick={() => setStep("first")} />) : (
            <Back
              className={`${styles.back}`}
              onClick={() => setStep("first")} />
          )}
        <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}
        >Register</h1>
        <h3 className={`${styles.subtitle} ${darkMode ? styles.subtitleDark : ''}`}
        >Let's get Started!</h3>
        <form className={styles.container} onSubmit={formik.handleSubmit}>
          <div className={styles.picture_container}>
            <div className={`${styles.pp} ${darkMode ? styles.ppDark : ''}`} ref={menuRef}>
              <img
                src={preview ? preview : Profile}
                className={preview ? styles.preview_img : styles.default_img}
                alt="profile"
              />
              <Plus
                className={styles.plus_icon}
                //onClick={() => {
                //setOpen(!open);
                //setShowModal(open);
                //}}
                onClick={handleClick}
              />
              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <div
                className={`${styles.dropdown} ${open ? styles.active : styles.inactive} ${darkMode ? styles.dropdownDark : ''}`}
              >
                <ul>
                  <li className={styles.item} onClick={handleClick}>
                    <AiOutlineUpload className={styles.icon} /> Upload Image from
                    Computer
                  </li>
                  {/* <li className={styles.item}>
                  <AiOutlineCamera className={styles.icon} />
                  Camera
                </li> */}
                </ul>
              </div>
              {open && <div className={styles.modal}></div>}
            </div>
            <h3 className={`${styles.upload_text} ${darkMode ? styles.upload_textDark : ''}`}>Upload Image</h3>
          </div>
          <div className={styles.line}></div>
          <div className={styles.form_container}>
            <div
              className={`${styles.input_container} ${formik.errors.username && formik.touched.username
                ? styles.error_input
                : ""
                }`}
            >
              <input
                type="text"
                name="username"
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                placeholder="Username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
              />
              <AiOutlineUser className={styles.input_icon} />
              {formik.errors.username && formik.touched.username && (
                <p className={styles.error_message}>{formik.errors.username}</p>
              )}
            </div>
            <div
              className={`${styles.input_container} ${formik.errors.email && formik.touched.email
                ? styles.error_input
                : ""
                }`}
            >
              <input
                type="email"
                name="email"
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                placeholder="Email address"
                onChange={formik.handleChange}
                onBlur={(e) => { formik.handleBlur(e); handleInputBlur(); }}
                value={formik.values.email}
              />
              <AiOutlineMail className={styles.input_icon} />
              {formik.errors.email && formik.touched.email && (
                <p className={styles.error_message}>{formik.errors.email}</p>
              )}
            </div>
            <div className={`${styles.input_container} ${formik.errors.phone && formik.touched.phone ? styles.error_input : ""}`}>
              <PhoneInput
                defaultCountry="LB"
                placeholder="Phone number"
                value={formik.values.phone}
                onChange={(value) => formik.setFieldValue('phone', value)}
                onBlur={formik.handleBlur}
                className={`${styles.inputph} ${darkMode ? styles.inputphDark : ''}`}
              />
              <p className={styles.error_message}>{formik.errors.phone}</p>
              <style>
                {`
      ${darkMode ? '.PhoneInputInput { background: transparent;color:#a6c0c8 }' : ''}
    `}
              </style>
            </div>
            <div
              className={`${styles.input_container} ${formik.errors.date && formik.touched.date
                ? styles.error_input
                : ""
                }`}
            >
              <input
                type="date"
                name="dateofbirth"
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                onChange={handleDateChange}
                placeholder="Date of birth"
                onBlur={formik.handleBlur}
                value={formik.values.dateofbirth}
              />
              <AiOutlineCalendar className={styles.input_icon} />
              {formik.errors.dateofbirth && formik.touched.dateofbirth && (
                <p className={styles.error_message}>
                  {formik.errors.dateofbirth}
                </p>
              )}
            </div>
            <div
              className={`${styles.input_container} ${formik.errors.password && formik.touched.password
                ? styles.error_input
                : ""
                }`}
            >
              <input
                type={password ? 'text' : 'password'}
                name="password"
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                placeholder="Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {!password && (
                <BiHide
                  className={styles.pass_icon}
                  onClick={() => setPassword(true)}
                />
              )}
              {password && (
                <BiShow
                  className={styles.pass_icon}
                  onClick={() => setPassword(false)}
                />
              )}
              <AiOutlineLock className={styles.input_icon} />
              {formik.errors.password && formik.touched.password && (
                <p className={styles.error_message}>{formik.errors.password}</p>
              )}
            </div>
            <div
              className={`${styles.input_container} ${formik.errors.confirmPassword && formik.touched.confirmPassword
                ? styles.error_input
                : ""
                }`}
            >
              <input
                type={Cpassword ? 'text' : 'password'}
                name="confirmPassword"
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                placeholder="Confirm Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
              {!Cpassword && (
                <BiHide
                  className={styles.pass_icon}
                  onClick={() => setCPassword(true)}
                />
              )}
              {Cpassword && (
                <BiShow
                  className={styles.pass_icon}
                  onClick={() => setCPassword(false)}
                />
              )}
              <AiOutlineLock className={styles.input_icon} />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <p className={styles.error_message}>
                    {formik.errors.confirmPassword}
                  </p>
                )}
            </div>
            {error && (
              <div className={styles.message}>
                <ErrorMessage>{error}</ErrorMessage>
              </div>
            )}
            {errorMessage && <div>{errorMessage}</div>}
            {loading ? (
              <button className={`${styles.registerload_btn} ${darkMode ? styles.register_btnDark : ''}`} disabled={loading}>
                Register...
              </button>
            ) : (
              <>
                {failMessage && <p style={{ color: 'red', fontSize: '10px', marginBottom: '0px' }}>{failMessage}</p>}
                <button className={`${styles.register_btn} ${darkMode ? styles.register_btnDark : ''}`} type={"submit"}>
                  Register
                </button>
              </>
            )}
          </div>
        </form>
        <p className={`${styles.account_text} ${darkMode ? styles.account_textDark : ''}`}>
          Already have an account?{" "}
          <span className={styles.login_text} onClick={() => setStep("login")}>
            Login
          </span>
        </p>
      </div>
    </>
  );
};
export default RegisterModal;