import React, { useState, useEffect } from "react";
import styles from './PaymentHistory.module.scss'
import img from '../../assests/svg/notification-bell.svg'
import nochat from '../../assests/svg/undraw_account_re_o7id 1.svg'
import Loader from "../../common/components/Loader/Loader";
import { AiOutlineBell } from "react-icons/ai";
import Layout from "../../common/components/Layouts/Layout";
import { GetPaymentHistory } from "../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { timePosted } from "../../common/Utils";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Img from "../../assests/svg/order history empty.svg";
const PaymentHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const history = useSelector((state) => state.history?.history?.payments)
  const loading = useSelector((state) => state.history?.loading)
  useEffect(() => {
    dispatch(GetPaymentHistory());
    dispatch({ type: "CLEAR_BREADCRUMBS" })
    const pageName = "Payment history"; // Replace this with the actual page name
    const pageUrl = "/user/payment-history"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, [])
  const handleNotificationClick = (url) => {
    const newUrl = url ? new URL(url) : null;
    const path = newUrl?.pathname;
    navigate(path)
  };
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <>
      <Layout title={'Payment History'}>
      {loading ? (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
  <Loader />
  </div>
) : (
  history && history.length > 0 ? (
    <div className={styles.container}>
      {history.map((notification, index) => (
        <div key={index} className={styles.notification}>
          <div className={styles.imgnot}>
            {/* <img src={inbox} className={styles.imgn} alt='notification' /> */}
          </div>
          <div className={styles.notifyCont}>
            <div className={styles.notificationTitle}>
              {notification.title}
            </div>
            <div className={styles.notificationContent}>
              {notification.body}
            </div>
          </div>
          <div className={styles.time}>
            {timePosted(notification.date)}
          </div>
        </div>
      ))}
    </div>
  ) : (
<>
<div className={styles.no_payment}>
    <img src={Img} alt="no notification" className={styles.img} />
    <h3 className={`${styles.subtitle} ${darkMode ? styles.subtitleDark : ''}`}>No Payment History</h3>
    <button className={`${styles.btn} ${darkMode ? styles.btnDark : ''}`} onClick={() => navigate("/")}>
      Back
    </button>
    </div>
  </>
  )
)}
      </Layout>
    </>
  )
}
export default PaymentHistory