export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_LIST_FAIL";
export const PRODUCT_DETAILS_RESET = "PRODUCT_DETAILS_RESET";
export const GET_ALL_PRODUCT_REQUEST = "GET_ALL_PRODUCT_REQUEST";
export const GET_ALL_PRODUCT_SUCCESS = "GET_ALL_PRODUCT_SUCCESS";
export const GET_ALL_PRODUCT_FAIL = "GET_ALL_PRODUCT_FAIL";
export const GET_ALL_FEATURED_PRODUCT_REQUEST = "GET_ALL_FEATURED_PRODUCT_REQUEST";
export const GET_ALL_FEATURED_PRODUCT_SUCCESS = "GET_ALL_FEATURED_PRODUCT_SUCCESS";
export const GET_ALL_FEATURED_PRODUCT_FAIL = "GET_ALL_FEATURED_PRODUCT_FAIL";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";
export const GET_ALL_SEARCH_PRODUCT_SUCCESS = "GET_ALL_SEARCH_PRODUCT_SUCCESS ";
export const GET_ALL_SEARCH_PRODUCT_FAIL = " GET_ALL_SEARCH_PRODUCT_FAIL";
export const GET_ALL_PENDING_PRODUCT_SUCCESS = "GET_ALL_PENDING_PRODUCT_SUCCESS ";
export const GET_ALL_PENDING_PRODUCT_FAIL = " GET_ALL_PENDING_PRODUCT_FAIL";
export const GET_ALL_CONFIRMED_PRODUCT_SUCCESS = "GET_ALL_CONFIRMED_PRODUCT_SUCCESS ";
export const GET_ALL_CONFIRMED_PRODUCT_FAIL = " GET_ALL_CONFIRMED_PRODUCT_FAIL";
export const GET_PRODUCT_BYSUBCAT_SUCCESS = "GET_PRODUCT_BYSUBCAT_SUCCESS";
export const GET_PRODUCT_BYSUBCAT_FAIL = "GET_PRODUCT_BYSUBCAT_FAIL";
export const GET_PRODUCT_BYSUBCAT_REQUEST = "GET_PRODUCT_BYSUBCAT_REQUEST";
export const GET_PRODUCT_CAT_SUCCESS = "GET_PRODUCT_CAT_SUCCESS";
export const GET_PRODUCT_CAT_FAIL = "GET_PRODUCT_CAT_FAIL";
export const GET_PRODUCT_CAT_REQUEST = "GET_PRODUCT_CAT_REQUEST";
export const SEARCH_PRODUCT_SUCCESS = "SEARCH_PRODUCT_SUCCESS ";
export const SEARCH_PRODUCT_FAIL = "SEARCH_PRODUCT_FAIL";
export const SEARCH_PRODUCT_REQUEST = "SEARCH_PRODUCT_REQUEST"
export const SEARCH_SUCCESS = "SEARCH_SUCCESS ";
export const SEARCH_FAIL = "SEARCH_FAIL";
export const GET_RELATED_PRODUCT_SUCCESS = "GET_RELATED_PRODUCT_SUCCESS ";
export const GET_RELATED_PRODUCT_FAIL = "GET_RELATED_PRODUCT_FAIL";
export const GET_ALL_BIDS_SUCCESS = "GET_ALL_BIDS_SUCCESS ";
export const GET_ALL_BIDS_FAIL = "GET_ALL_BIDS_FAIL";
export const SEARCH_SERVICE_SUCCESS = "SEARCH_SERVICE_SUCCESS ";
export const SEARCH_SERVICE_FAIL = "SEARCH_SERVICE_FAIL";
export const GET_SERVICE_BY_TYPE_SUCCESS = "GET_SERVICE_BY_TYPE_SUCCESS ";
export const GET_SERVICE_BY_TYPE_FAIL = "GET_SERVICE_BY_TYPE_FAIL";
export const GET_SERVICE_BY_TYPE_REQUEST = "GET_SERVICE_BY_TYPE_REQUEST";
export const SEARCH_ALL_SERVICE_SUCCESS = "SEARCH_ALL_SERVICE_SUCCESS ";
export const SEARCH_ALL_SERVICE_FAIL = "SEARCH_ALL_SERVICE_FAIL";
export const GET_RELATED_SERVICE_SUCCESS = "GET_RELATED_SERVICE_SUCCESS ";
export const GET_RELATED_SERVICE_FAIL = "GET_RELATED_SERVICE_FAIL";
export const SEARCH_AUTOCOMPLETE_SUCCESS = "SEARCH_AUTOCOMPLETE_SUCCESS ";
export const SEARCH_AUTOCOMPLETE_FAIL = "SEARCH_AUTOCOMPLETE_FAIL";
export const SEARCH_AUTOCOMPLETE_ADMIN_SUCCESS = "SEARCH_AUTOCOMPLETE_ADMIN_SUCCESS ";
export const SEARCH_AUTOCOMPLETE_ADMIN_FAIL = "SEARCH_AUTOCOMPLETE_ADMIN_FAIL";


