import {
    GET_TYPE_BY_ID_SUCCESS,
    GET_TYPE_BY_ID_FAIL
} from "../constants/servicesConstants";
const initialState = {
    typeById: [],
    error: null,
    loading: false,
};
export const GetTypeById = (state = initialState, action) => {
    switch (action.type) {
        case GET_TYPE_BY_ID_SUCCESS:
            return {
                ...state,
                typeById: action.payload,
                error: null,
                loading: false,
            };
        case GET_TYPE_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
