import {
    GET_ALL_SERVICES_CONFIRMED_FAIL,
    GET_ALL_SERVICES_CONFIRMED_SUCCESS
} from "../constants/adminDashboardConstants";
const initialState = {
    ConfirmedServicesArray: [],
    error: null,
    loading: false,
};
export const GetServicesConfirmed = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SERVICES_CONFIRMED_SUCCESS:
            return {
                ...state,
                ConfirmedServicesArray: action.payload,
                error: null,
                loading: false,
            };
        case GET_ALL_SERVICES_CONFIRMED_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
