import {
    CREATE_SERVICE_SUCCESS,
    CREATE_SERVICE_FAIL,
    DELETE_SERVICE_BY_ID_FAIL,
    DELETE_SERVICE_BY_ID_SUCCESS,
    EDIT_SERVICE_BY_ID_FAIL,
    EDIT_SERVICE_BY_ID_SUCCESS,
    CONFIRM_SERVICE_BY_ID_FAIL,
    CONFIRM_SERVICE_BY_ID_SUCCESS,
    UNCONFIRM_SERVICE_BY_ID_FAIL,
    UNCONFIRM_SERVICE_BY_ID_SUCCESS,
    FEATURE_SERVICE_FAIL,
    FEATURE_SERVICE_SUCCESS,
    UNFEATURE_SERVICE_FAIL,
    UNFEATURE_SERVICE_SUCCESS,
    CREATE_SERVICE_TYPE_SUCCESS,
    CREATE_SERVICE_TYPE_FAIL,
    GET_ALL_TYPES_SUCCESS,
    GET_ALL_TYPES_FAIL,
    GET_ALL_SERVICES_SUCCESS,
    GET_ALL_SERVICES_FAIL,
    CREATE_SERVICE_REQUEST,
    GET_SINGLE_SERVICE_SUCCESS,
    GET_SINGLE_SERVICE_FAIL,
    GET_ALL_SERVICES_BY_TYPE_SUCCESS,
    GET_ALL_SERVICES_BY_TYPE_FAIL,
    GET_TYPE_BY_ID_SUCCESS,
    GET_TYPE_BY_ID_FAIL,
    GET_ALL_OFFERED_SERVICES_SUCCESS,
    GET_ALL_OFFERED_SERVICES_FAIL,
    GET_SERVICES_WISHLIST_SUCCESS,
    GET_SERVICES_WISHLIST_FAIL,
    GET_MY_SERVICES_SUCCESS,
    GET_MY_SERVICES_FAIL,
    REMOVE_SERVICE_WHISHLIST_SUCCESS,
    REMOVE_SERVICE_WHISHLIST_FAIL,
    EDIT_SERVICE_TYPE_SUCCESS,
    EDIT_SERVICE_TYPE_FAIL,
    DELETE_SERVICE_TYPE_BY_ID_SUCCESS,
    DELETE_SERVICE_TYPE_BY_ID_FAIL
} from "../constants/servicesConstants";
import axios from "axios";
import { url } from "../../common/constants/url";
//CREATE SERVICE
export const CreateService = (formData) => {
    return async (dispatch) => {
        try {
            dispatch(CreateServiceRequest())
            const token = localStorage.getItem('token')
            
            const response = await axios.post(`${url}/service`, formData, {
                headers: {
                    'authorization': `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });
            
            dispatch(CreateServiceSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(CreateServiceFail(error));
            // console.log(error)
        }
    };
};
export const CreateServiceRequest = () => {
    return {
        type: CREATE_SERVICE_REQUEST,
    };
};
export const CreateServiceSuccess = (services) => {
    return {
        type: CREATE_SERVICE_SUCCESS,
        payload: services,
    };
};
const CreateServiceFail = (error) => ({
    type: CREATE_SERVICE_FAIL,
    payload: error
});
//DELETE SERVICE
export const DeleteServiceById = (id) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.delete(`${url}/service/${id}`, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            
            dispatch(DeleteServiceByIdSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(DeleteServiceByIdFail(error));
            // console.log(error)
        }
    };
};
export const DeleteServiceByIdSuccess = (service) => {
    return {
        type: DELETE_SERVICE_BY_ID_SUCCESS,
        payload: service,
    };
};
const DeleteServiceByIdFail = (error) => ({
    type: DELETE_SERVICE_BY_ID_FAIL,
    payload: error
});
//EDIT SERVICE BY ID
export const EditServiceById = (id, formData) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.put(`${url}/service/${id}`, formData, {
                headers: {
                    'authorization': `${token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });
            
            dispatch(EditServiceSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(EditServiceFail(error));
            // console.log(error)
        }
    };
};
export const EditServiceSuccess = (service) => {
    return {
        type: EDIT_SERVICE_BY_ID_SUCCESS,
        payload: service,
    };
};
const EditServiceFail = (error) => ({
    type: EDIT_SERVICE_BY_ID_FAIL,
    payload: error
});
//CONFIRM SERVICE
export const ConfirmServiceById = (id) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.put(`${url}/service/${id}/confirm`, {}, {
                headers: {
                    'authorization': `${token}`
                },
            });
            
            dispatch(ConfirmServiceByIdSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(ConfirmServiceByIdFail(error));
            // console.log(error)
        }
    };
};
export const ConfirmServiceByIdSuccess = (service) => {
    return {
        type: CONFIRM_SERVICE_BY_ID_SUCCESS,
        payload: service,
    };
};
const ConfirmServiceByIdFail = (error) => ({
    type: CONFIRM_SERVICE_BY_ID_FAIL,
    payload: error
});
//UNCONFIRM SERVICE
export const UnConfirmServiceById = (id) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.put(`${url}/service/${id}/unconfirm`, {}, {
                headers: {
                    'authorization': `${token}`
                },
            });
            
            dispatch(UnConfirmServiceByIdSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(UnConfirmServiceByIdFail(error));
            // console.log(error)
        }
    };
};
export const UnConfirmServiceByIdSuccess = (service) => {
    return {
        type: UNCONFIRM_SERVICE_BY_ID_SUCCESS,
        payload: service,
    };
};
const UnConfirmServiceByIdFail = (error) => ({
    type: UNCONFIRM_SERVICE_BY_ID_FAIL,
    payload: error
});
//FEATURE SERVICE
export const FeatureService = (id) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.put(`${url}/service/${id}/feature`, {}, {
                headers: {
                    'authorization': `${token}`
                },
            });
            
            dispatch(FeatureServiceSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(FeatureServiceFail(error));
            // console.log(error)
        }
    };
};
export const FeatureServiceSuccess = (service) => {
    return {
        type: FEATURE_SERVICE_SUCCESS,
        payload: service,
    };
};
const FeatureServiceFail = (error) => ({
    type: FEATURE_SERVICE_FAIL,
    payload: error
});
//UNFEATURE SERVICE
export const UnFeatureService = (id) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.put(`${url}/service/${id}/unfeature`, {}, {
                headers: {
                    'authorization': `${token}`
                },
            });
            
            dispatch(UnFeatureServiceSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(UnFeatureServiceFail(error));
            // console.log(error)
        }
    };
};
export const UnFeatureServiceSuccess = (service) => {
    return {
        type: UNFEATURE_SERVICE_SUCCESS,
        payload: service,
    };
};
const UnFeatureServiceFail = (error) => ({
    type: UNFEATURE_SERVICE_FAIL,
    payload: error
});
//CREATE SERVICE TYPE
export const CreateServiceType = (rawData) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.post(`${url}/createServiceType`, rawData, {
                headers: {
                    'authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
            });
            
            dispatch(CreateServiceTypeSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(CreateServiceTypeFail(error));
            // console.log(error)
        }
    };
};
export const CreateServiceTypeSuccess = (services) => {
    return {
        type: CREATE_SERVICE_TYPE_SUCCESS,
        payload: services,
    };
};
const CreateServiceTypeFail = (error) => ({
    type: CREATE_SERVICE_TYPE_FAIL,
    payload: error
});
// GET ALL TYPES
export const GetAllTypes = () => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.get(`${url}/getTypes`, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            dispatch(GetAllTypesSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(GetAllTypesFail(error));
            // console.log(error)
        }
    };
};
export const GetAllTypesSuccess = (subCategories) => {
    return {
        type: GET_ALL_TYPES_SUCCESS,
        payload: subCategories,
    };
};
const GetAllTypesFail = (error) => ({
    type: GET_ALL_TYPES_FAIL,
    payload: error
});
//GET ALL CONFIRMED SERVICES
export const GetAllServices = ({ pageSize, page, rawData }) => {
    return async (dispatch) => {
  
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.post(`${url}/service/search/get?page=${page}&pageSize=${pageSize}`, rawData, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            dispatch(GetAllServicesSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetAllServicesFail(error));
            // console.log(error);
        }
    };
};
export const GetAllServicesSuccess = (Product) => {
    return {
        type: GET_ALL_SERVICES_SUCCESS,
        payload: Product,
    };
};
const GetAllServicesFail = (error) => ({
    type: GET_ALL_SERVICES_FAIL,
    payload: error
});
//GET SINGLE SERVICE 
export const GetSingleService = (Id) => {
    
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.get(`${url}/service/get/${Id}`, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            //const product = response?.data?.data?.product || []
            dispatch(GetSingleServiceSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(GetSingleServiceFail(error));
            // console.log(error)
        }
    };
};
export const GetSingleServiceSuccess = (product) => {
    return {
        type: GET_SINGLE_SERVICE_SUCCESS,
        payload: product,
    };
};
export const GetSingleServiceFail = (error) => ({
    type: GET_SINGLE_SERVICE_FAIL,
    payload: error
});
//GET ALL SERVICES BY TYPE OF SERVICES 
export const GetAllServicesByType = ({ pageSize, page, rawData, type,sort }) => {
    return async (dispatch) => {
   
        try {
            const token = localStorage.getItem('token')
            
            if (token) {
                const response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&sort=${sort}&typeOfService=${type}`, rawData, {
                    headers: {
                        'authorization': `${token}`,
                    },
                });
                dispatch(GetAllServicesByTypeSuccess(response.data));
                return response.data;
            } else {
                const response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&sort=${sort}&typeOfService=${type}`, rawData, {
                });
                dispatch(GetAllServicesByTypeSuccess(response.data));
                return response.data;
            }
        } catch (error) {
            dispatch(GetAllServicesByTypeFail(error));
            // console.log(error);
        }
    };
};
export const GetAllServicesByTypeSuccess = (Product) => {
    return {
        type: GET_ALL_SERVICES_BY_TYPE_SUCCESS,
        payload: Product,
    };
};
const GetAllServicesByTypeFail = (error) => ({
    type: GET_ALL_SERVICES_BY_TYPE_FAIL,
    payload: error
});
//GET ALL OFFERED SERVICES
export const GetAllOfferedServices = ({ pageSize, page, rawData, sort }) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            if (token) {
                const response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&sort=${sort}&typeOfService=offer`, rawData, {
                    headers: {
                        'authorization': `${token}`,
                    },
                });
                dispatch(GetAllOfferedServicesSuccess(response.data));
                return response.data;
            } else {
                const response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&sort=${sort}&typeOfService=offer`, rawData, {
                });
                dispatch(GetAllOfferedServicesSuccess(response.data));
                return response.data;
            }
        } catch (error) {
            dispatch(GetAllOfferedServicesFail(error));
            // console.log(error);
        }
    };
};
export const GetAllOfferedServicesSuccess = (Product) => {
    return {
        type: GET_ALL_OFFERED_SERVICES_SUCCESS,
        payload: Product,
    };
};
const GetAllOfferedServicesFail = (error) => ({
    type: GET_ALL_OFFERED_SERVICES_FAIL,
    payload: error
});
//GET TYPE BY ID
export const GetTypeById = (Id) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.get(`${url}/getTypeById/${Id}`, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            dispatch(GetTypeByIdSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetTypeByIdFail(error));
            // console.log(error);
        }
    };
};
export const GetTypeByIdSuccess = (product) => {
    return {
        type: GET_TYPE_BY_ID_SUCCESS,
        payload: product,
    };
};
export const GetTypeByIdFail = (error) => ({
    type: GET_TYPE_BY_ID_FAIL,
    payload: error
});
//GET SERVICES WISHLIST 
export const GetServicesWishlist = () => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.get(`${url}/user/servicewishList/`, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            dispatch(GetServicesWishlistSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetServicesWishlistFail(error));
           
        }
    };
};
export const GetServicesWishlistSuccess = (Product) => {
    return {
        type: GET_SERVICES_WISHLIST_SUCCESS,
        payload: Product,
    };
};
const GetServicesWishlistFail = (error) => ({
    type: GET_SERVICES_WISHLIST_FAIL,
    payload: error
});
//GET MY SERVICES
export const GetMyServices = () => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.get(`${url}/myservices`, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            dispatch(GetMyServicesSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(GetMyServicesFail(error));
            // console.log(error);
        }
    };
};
export const GetMyServicesSuccess = (Product) => {
    return {
        type: GET_MY_SERVICES_SUCCESS,
        payload: Product,
    };
};
const GetMyServicesFail = (error) => ({
    type: GET_MY_SERVICES_FAIL,
    payload: error
});
//REMOVE SERVICES FROM WISHLIST
export const RemoveServiceWishList = (ServiceId) => {
    return async (dispatch) => {
        try {
            const token = await localStorage.getItem('token')
            
            const response = await axios.delete(`${url}/user/deleteservicewishList/${ServiceId}`, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            
            dispatch(RemoveServiceWishListSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(RemoveServiceWishListFail(error));
            // console.log(error)
        }
    };
};
export const RemoveServiceWishListSuccess = (status) => {
    return {
        type: REMOVE_SERVICE_WHISHLIST_SUCCESS,
        payload: status,
    };
};
const RemoveServiceWishListFail = (error) => ({
    type: REMOVE_SERVICE_WHISHLIST_FAIL,
    payload: error
});
//EDIT SERVICE TYPE
export const EditServiceType = (rawData, id) => {
    return async (dispatch) => {
        try {
            const token = await localStorage.getItem('token')
            
            const response = await axios.put(`${url}/updateType/${id}`, rawData, {
                headers: {
                    'authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
            });
            
            dispatch(EditServiceTypeSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(EditServiceTypeFail(error));
            // console.log(error)
        }
    };
};
export const EditServiceTypeSuccess = (status) => {
    return {
        type: EDIT_SERVICE_TYPE_SUCCESS,
        payload: status,
    };
};
const EditServiceTypeFail = (error) => ({
    type: EDIT_SERVICE_TYPE_FAIL,
    payload: error
});
//DELETE SERVICE TYPE
export const DeleteServiceTypeById = (typeId) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.delete(`${url}/deleteServiceType/${typeId}`, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            
            dispatch(DeleteServiceTypeByIdSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(DeleteServiceTypeByIdFail(error));
            // console.log(error)
        }
    };
};
export const DeleteServiceTypeByIdSuccess = (service) => {
    return {
        type: DELETE_SERVICE_TYPE_BY_ID_SUCCESS,
        payload: service,
    };
};
const DeleteServiceTypeByIdFail = (error) => ({
    type: DELETE_SERVICE_TYPE_BY_ID_FAIL,
    payload: error
});
