import {
  PLACE_BID_REQUEST,
  PLACE_BID_FAIL,
  PLACE_BID_SUCCESS,
  GET_BIDERS_REQUEST,
  GET_BIDERS_FAIL,
  GET_BIDERS_SUCCESS,
  JOIN_BID_SUCCESS,
  JOIN_BID_FAIL,
  SEND_OFFER_FOR_PRODUCT_SUCCESS,
  SEND_OFFER_FOR_PRODUCT_FAIL,
  ACCEPT_OFFER_SUCCESS,
  ACCEPT_OFFER_FAIL,
  REJECT_OFFER_FAIL,
  REJECT_OFFER_SUCCESS,
  GET_PRODUCT_OFFER_SUCCESS,
  GET_PRODUCT_OFFER_FAIL,
  GET_ALLOWED_BIDERS_SUCCESS,
  GET_ALLOWED_BIDERS_FAIL,
  CHECK_USER_IN_BID_SUCCESS,
  CHECK_USER_IN_BID_FAIL,
  SEND_OFFER_FOR_PRODUCT_REQUEST,
  GET_LIVE_BIDS_SUCCESS,
  GET_LIVE_BIDS_FAIL,
  GET_LIVE_BIDS_REQUEST,
  END_ONE_BID_SUCCESS,
  END_ONE_BID_FAIL,
  END_ALL_BID_SUCCESS,
  END_ALL_BID_FAIL
} from "../constants/bidConstants";
import { url } from "../../common/constants/url";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const placeBidHandler =
  (user_id, product_id, bidderamount) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PLACE_BID_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo}`,
        },
      };
      let body = {
        product_id,
        user_id,
        bidderamount,
      };
      // console.log(body);
      await axios.post(`${url}/bid/placebid`, JSON.stringify(body), config);
      dispatch({
        type: PLACE_BID_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: PLACE_BID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getBidersHandler = (productid) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_BIDERS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    };
    const { data } = await axios.get(
      `${url}/bid/${productid}/getallusersbybid`,
      config
    );
    dispatch({
      type: GET_BIDERS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_BIDERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const JoinBid = (data) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/product/bid/join`, data, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(joinBidSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(joinBidFail(error));
      // console.log(error)
    }
  };
};
const joinBidSuccess = (data) => ({
  type: JOIN_BID_SUCCESS,
  payload: data
});
const joinBidFail = (error) => ({
  type: JOIN_BID_FAIL,
  payload: error
});
export const SendOfferForProduct = (data) => {
  return async (dispatch) => {
    dispatch(SendOfferForeProductRequest())
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${url}/product/waitingList/join`, data, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(SendOfferForeProductSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(SendOfferForeProductFail(error));
      if (error.response && error.response.data && error.response.data.message === "Cannot read properties of undefined (reading 'default_payment_method')") {
        toast.error("Please add a payment method before proceeding.");
        return "Cannot read properties of undefined (reading 'default_payment_method')"
      } else if (error.response && error.response.data) {
        if (error.response.data.message === "User already sent an offer to this") {
          toast.error("Already sent offer.");
          return "User already sent an offer to this"
        } else {
          // console.log("Error:", error.response.data.message);
        }
      } else {
        // console.log("Error:", error);
        // Add additional error handling message here
      }
    }
  };
};
const SendOfferForeProductRequest = () => ({
  type: SEND_OFFER_FOR_PRODUCT_REQUEST,
});
const SendOfferForeProductSuccess = (data) => ({
  type: SEND_OFFER_FOR_PRODUCT_SUCCESS,
  payload: data
});
const SendOfferForeProductFail = (error) => ({
  type: SEND_OFFER_FOR_PRODUCT_FAIL,
  payload: error
});
export const AcceptOffer = (data) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(`${url}/product/offer/accept`, data, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(AcceptOfferSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(AcceptOfferFail(error));
      // console.log(error)
    }
  };
};
const AcceptOfferSuccess = (data) => ({
  type: ACCEPT_OFFER_SUCCESS,
  payload: data
});
const AcceptOfferFail = (error) => ({
  type: ACCEPT_OFFER_FAIL,
  payload: error
});
export const RejectOffer = (data) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(`${url}/product/offer/reject`, data, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(RejectOfferSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(RejectOfferFail(error));
      // console.log(error)
    }
  };
};
const RejectOfferSuccess = (data) => ({
  type: REJECT_OFFER_SUCCESS,
  payload: data
});
const RejectOfferFail = (error) => ({
  type: REJECT_OFFER_FAIL,
  payload: error
});
export const GetProductOffer = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/product/offers/${id}`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetProductOfferSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetProductOfferFail(error));
      // console.log(error)
    }
  };
};
const GetProductOfferSuccess = (data) => ({
  type: GET_PRODUCT_OFFER_SUCCESS,
  payload: data
});
const GetProductOfferFail = (error) => ({
  type: GET_PRODUCT_OFFER_FAIL,
  payload: error
});
export const GetAllowedBidders = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/get/allowedBidders/${id}`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetAllowedBiddersSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetAllowedBiddersFail(error));
      // console.log(error)
    }
  };
};
const GetAllowedBiddersSuccess = (data) => ({
  type: GET_ALLOWED_BIDERS_SUCCESS,
  payload: data
});
const GetAllowedBiddersFail = (error) => ({
  type: GET_ALLOWED_BIDERS_FAIL,
  payload: error
});
export const CheckUserInBid = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/product/bid/checkuser/${id}`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(CheckUserInBidSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(CheckUserInBidFail(error));
      // console.log(error)
    }
  };
};
const CheckUserInBidSuccess = (data) => ({
  type: CHECK_USER_IN_BID_SUCCESS,
  payload: data
});
const CheckUserInBidFail = (error) => ({
  type: CHECK_USER_IN_BID_FAIL,
  payload: error
});
export const GetLiveBids = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('token')
    try {
      dispatch(GetLiveBidsRequest())
      const response = await axios.get(`${url}/products/myBids`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetLiveBidsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetLiveBidsFail(error));
      // console.log(error)
    }
  };
};
const GetLiveBidsRequest = () => ({
  type: GET_LIVE_BIDS_REQUEST,
});
const GetLiveBidsSuccess = (data) => ({
  type: GET_LIVE_BIDS_SUCCESS,
  payload: data
});
const GetLiveBidsFail = (error) => ({
  type: GET_LIVE_BIDS_FAIL,
  payload: error
});
export const EndOneBid = (rawData) => {
  return async (dispatch) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(`${url}/product/endBid`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(EndOneBidSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(EndOneBidFail(error));
      // console.log(error)
    }
  };
};
const EndOneBidSuccess = (data) => ({
  type: END_ONE_BID_SUCCESS,
  payload: data
});
const EndOneBidFail = (error) => ({
  type: END_ONE_BID_FAIL,
  payload: error
});
export const EndAllBid = (rawData) => {
  return async (dispatch) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.post(`${url}/product/endBidAllQuantities`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(EndAllBidSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(EndAllBidFail(error));
      // console.log(error)
    }
  };
};
const EndAllBidSuccess = (data) => ({
  type: END_ALL_BID_SUCCESS,
  payload: data
});
const EndAllBidFail = (error) => ({
  type: END_ALL_BID_FAIL,
  payload: error
});
