import { url } from "../../common/constants/url";
import axios from "axios";
import {
  ADD_FCM_TOKEN_FAIL,
  ADD_FCM_TOKEN_SUCCESS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  CHECK_UNREAD_NOTIFICATION_SUCCESS,
  CHECK_UNREAD_NOTIFICATION_FAIL,
  ENABLE_NOTIFICATION_SUCCESS,
  ENABLE_NOTIFICATION_FAIL
} from "../constants/NotificationConstants";
export const AddFcmToken = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.put(`${url}/notifications/token`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      //const product = response?.data?.data?.product || []
      dispatch(AddFcmTokenSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(AddFcmTokenFail(error));
      // console.log(error)
    }
  };
};
export const AddFcmTokenSuccess = (product) => {
  return {
    type: ADD_FCM_TOKEN_SUCCESS,
    payload: product,
  };
};
const AddFcmTokenFail = (error) => ({
  type: ADD_FCM_TOKEN_FAIL,
  payload: error
});
export const GetNotification = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.get(`${url}/notifications/user`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      //const product = response?.data?.data?.product || []
      dispatch(GetNotificationSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetNotificationFail(error));
     
    }
  };
};
export const GetNotificationSuccess = (product) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: product,
  };
};
const GetNotificationFail = (error) => ({
  type: GET_NOTIFICATIONS_FAIL,
  payload: error
});
export const CheckUnreadNotification = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.get(`${url}/notifications/user/unread`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      //const product = response?.data?.data?.product || []
      dispatch(CheckUnreadNotificationSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(CheckUnreadNotificationFail(error));
      
    }
  };
};
export const CheckUnreadNotificationSuccess = (product) => {
  return {
    type: CHECK_UNREAD_NOTIFICATION_SUCCESS,
    payload: product,
  };
};
const CheckUnreadNotificationFail = (error) => ({
  type: CHECK_UNREAD_NOTIFICATION_FAIL,
  payload: error
});
export const enableNotification = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.put(`${url}/notifications/status`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-type': 'application/json'
        },
      });
      //const product = response?.data?.data?.product || []
      dispatch(enableNotificationSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(enableNotificationFail(error));
      // console.log(error)
    }
  };
};
export const enableNotificationSuccess = (product) => {
  return {
    type: ENABLE_NOTIFICATION_SUCCESS,
    payload: product,
  };
};
const enableNotificationFail = (error) => ({
  type: ENABLE_NOTIFICATION_FAIL,
  payload: error
});
