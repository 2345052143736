import { GET_ALL_SUB_CATEGORIES_FAIL,
  GET_ALL_SUB_CATEGORIES_SUCCESS }
   from "../constants/categoryConstants";
const initialState = {
    subCategories: [],
    error: null,
    loading: false
  };
  
  export const subcategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_ALL_SUB_CATEGORIES_SUCCESS:
        return {
          ...state,
          subCategories: action.payload,
          loading: false,
          error: null,
        };
      case GET_ALL_SUB_CATEGORIES_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };