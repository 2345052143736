import React, { useEffect, useState } from "react";
import styles from "./Card.module.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { url } from "../../../common/constants/url";
import { GetAllCategories } from "../../../store/actions/categoryAction";
import { GetAllCategoriesSuccess } from "../../../store/actions/categoryAction";
import { GetAllSubCategories } from "../../../store/actions/categoryAction";
import { GetAllSubCategoriesSuccess } from "../../../store/actions/categoryAction";
const Card = ({ image, categoryId, title, subCategoryId, subCategory }) => {
  const [subCategories, setSubCategories] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllSubCategories(categoryId))
      .then((data) => {
        setSubCategories(data.data?.subCategories);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [categoryId, dispatch]);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <div className={styles.card_container}>
      <div className={`${styles.card} ${darkMode ? styles.cardDark : ''}`}>
        <img src={image} alt={title} className={styles.card_image} />
        <div className={styles.subcategories_container}>
          {Array.isArray(subCategory) && subCategory.length > 0 &&
            subCategory.map((item) => (
              <div className={styles.subcategories_item} key={item.title}>
                <Link
                  className={`${styles.subcategories_title} ${darkMode ? styles.subcategories_titleDark : ''
                    }`}
                  to={`/sell/products/${item.title?.replaceAll(" ", "-").replaceAll("&", "And").replaceAll(",", "")}/${item._id}`}
                >
                  {item.title}
                </Link>
              </div>
            ))
          }
        </div>
      </div>
      <div className={styles.title_container}>
        <h1 className={`${styles.card_title} ${darkMode ? styles.card_titleDark : ''}`}>{title}</h1>
      </div>
    </div>
  );
};
export default Card;
