import { url } from "../../common/constants/url";
import axios from "axios";
import {
  MYPRODUCTS_REQUEST,
  MYPRODUCTS_SUCCESS,
  MYPRODUCTS_FAIL,
  USER_PRODUCTS_REQUEST,
  USER_PRODUCTS_SUCCESS,
  USER_PRODUCTS_FAIL,
  GET_USER_PRODUCTS_FAIL,
  GET_USER_PRODUCTS_SUCCESS,
  GET_USER_PRODUCTS_REQUEST,
  GET_SINGLE_PRODUCTS_FAIL,
  GET_SINGLE_PRODUCTS_SUCCESS,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAIL,
  EDIT_PRODUCT_REQUEST
} from "../constants/myProductsContants";
export const listMyProducts = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MYPRODUCTS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    };
    const { data } = await axios.get(
      `${url}/products/${id}/getallmyoffers`,
      config
    );
    dispatch({
      type: MYPRODUCTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: MYPRODUCTS_FAIL,
      payload: message,
    });
  }
};
export const listUserProducts = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PRODUCTS_REQUEST,
    });
    const { data } = await axios.get(`${url}/products/${id}/getalluseroffers`);
    dispatch({
      type: USER_PRODUCTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_PRODUCTS_FAIL,
      payload: message,
    });
  }
};
export const GetUserProduct = ({ UserId, rawData }) => {
  return async (dispatch) => {
    if (rawData) {
      try {
        dispatch(GetUserProductRequest())
        const token = localStorage.getItem('token')
        const response = await axios.post(`${url}/user/product/${UserId}?page=1&pageSize=200`, rawData, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json'
          },
        });
        const products = response?.data?.data?.products || []
        dispatch(GetUserProductSuccess(products));
        return products
      } catch (error) {
        dispatch(GetUserProductFail(error));
        // console.log(error)
      }
    } else {
      try {
        dispatch(GetUserProductRequest())
        const token = localStorage.getItem('token')
        
        const response = await axios.get(`${url}/user/product/${UserId}`, {
          headers: {
            'Authorization': `${token}`,
          },
        });
        const products = response?.data?.data?.products || []
        dispatch(GetUserProductSuccess(products));
        return products
      } catch (error) {
        dispatch(GetUserProductFail(error));
        // console.log(error)
      }
    };
  }
};
export const GetUserProductSuccess = (product) => {
  return {
    type: GET_USER_PRODUCTS_SUCCESS,
    payload: product,
  };
};
export const GetUserProductRequest = () => {
  return {
    type: GET_USER_PRODUCTS_REQUEST,
  };
};
const GetUserProductFail = (error) => ({
  type: GET_USER_PRODUCTS_FAIL,
  payload: error
});
export const GetSingleProduct = (Id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.post(`${url}/product/get/${Id}`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      //const product = response?.data?.data?.product || []
      dispatch(GetSingleProductSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetSingleProductFail(error));
      // console.log(error)
    }
  };
};
export const GetSingleProductSuccess = (product) => {
  return {
    type: GET_SINGLE_PRODUCTS_SUCCESS,
    payload: product,
  };
};
export const GetSingleProductFail = (error) => ({
  type: GET_SINGLE_PRODUCTS_FAIL,
  payload: error
});
export const EditProducts = (Id, formData) => {
  return async (dispatch) => {
    try {
      dispatch(EditProductRequest())
      const token = localStorage.getItem('token')
      
      const response = await axios.put(`${url}/product/${Id}`, formData, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      //const product = response?.data?.data?.product || []
      dispatch(EditProductSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(EditProductFail(error));
      // console.log(error)
    }
  };
};
export const EditProductRequest = () => {
  return {
    type: EDIT_PRODUCT_REQUEST,
  };
};
export const EditProductSuccess = (product) => {
  return {
    type: EDIT_PRODUCT_SUCCESS,
    payload: product,
  };
};
const EditProductFail = (error) => ({
  type: EDIT_PRODUCT_FAIL,
  payload: error
});
