import React from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Card = ({ img, name,id }) => {
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <div
      className={styles.container}
      onClick={() => navigate(`/categories/${name
        .replaceAll(" ", "-")
        .replaceAll("&", "And")
        .replaceAll(",", "")}`,{state:{id}})}
    >
      <div className={`${styles.card} ${darkMode ? styles.cardDark : ''}`}>
        <img src={img} alt={name} className={styles.image} />
      </div>
      <h3 className={`${styles.name} ${darkMode ? styles.nameDark : ''}`}>{name}</h3>
    </div>
  );
};
export default Card;
