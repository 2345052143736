import React, { useEffect } from "react";
import ReactDom from "react-dom";
import styles from "./BidModal.module.scss";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { useNavigate } from "react-router-dom";
import { CgNametag } from "react-icons/cg";
import ProductName from "../../../AdminProducts/components/ProductName/ProductName";
import { useDispatch, useSelector } from "react-redux";
import { GetPaymentAmount } from "../../../../store/actions/userActions";
const BidModal = ({ open, closeHandler, joinBidHandler, name, loadingBid }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const amount = useSelector((state) => state.amount?.amount)
  useEffect(() => {
    dispatch(GetPaymentAmount("JoinBid"))
  }, [])
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}>
        <div className={styles.wrapper}>
          <Close className={styles.close} onClick={closeHandler} />
          <h1 className={styles.title}>
            Joining a bid requires paying a fees of:
          </h1>
          {amount && amount.amount === 0 ? (
            <h3 className={`${styles.subtitle} `}>${amount && amount.amount}(Free)</h3>
          ) : (
            <h3 className={`${styles.subtitle} `}>${amount && amount.amount}</h3>
          )}          <div className={styles.btn_container}>
            <button
              className={`${styles.accept} ${loadingBid ? styles.loading : ''}`}
              onClick={async () => {
                await joinBidHandler();
              }}
              disabled={loadingBid}
            >
              {loadingBid ? 'Accept...' : 'Accept'}
            </button>
            <button className={styles.decline} onClick={closeHandler}>
              Decline
            </button>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default BidModal;
