import React, { useEffect, useState } from "react";
import styles from "./DetailsTables.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { getProdDetails } from "../../../../store/actions/productDetailsAction";
import { timeLeft } from "../../../../common/Utils";
import { GetSingleProduct } from "../../../../store/actions/myProductsActions";
import { GetAllowedBidders } from "../../../../store/actions/bidActions";
const DetailsTables = ({
  bidDetails,
  productId,
  bidList,
  quantity,
  bidders,
}) => {
  const dispatch = useDispatch();
  const [productDetails, setProductDetails] = useState([]);
  const [transformedTimestamp, setTransformedTimestamp] = useState(null);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const allowedBidders = useSelector(
    (state) => state.allowedBidders?.allowedBidders
  );
  const getBiders = useSelector((state) => state.getBiders);
  const { biders, error, loading } = getBiders;
  const [formattedDate, setFormattedDate] = useState(null);
  const [daysDiff, setDaysDiff] = useState(null);
  const [expired, setExpired] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [number, setNumber] = useState(null);
  useEffect(() => {
    if (bidders > allowedBidders.allowedBiddersCount) {
      setNumber(bidders);
    } else {
      setNumber(allowedBidders.allowedBiddersCount);
    }
  }, [bidders, allowedBidders]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    // Add event listener to update the screenWidth state on window resize
    window.addEventListener("resize", updateScreenWidth);
    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(GetSingleProduct(productId));
      dispatch(GetAllowedBidders(productId));
      setProductDetails(response.data?.product);
      const timer = response.data?.product?.timer?.enabled;
      const deadline = response.data?.product?.timer?.deadline;
      if (timer && deadline) {
        const endDate = new Date(deadline);
        if (!isNaN(endDate)) {
          setFormattedDate(endDate.toISOString());
        } else {
          // Handle invalid date format
          console.error(`Invalid date format for end: ${deadline}`);
        }
      }
      // Calculate the difference in milliseconds and set the daysDiff state
      if (formattedDate && productDetails.createdAt) {
        const startDate = new Date(productDetails.createdAt);
        const endDate = new Date(formattedDate);
        const timeDiff = endDate - startDate;
        const calculatedDaysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        setDaysDiff(calculatedDaysDiff);
      }
    };
    const currentDate = new Date().toISOString();
    const currentDate1 = new Date(currentDate);
    const deadlineDate = new Date(formattedDate);
    const timeDifference = deadlineDate - currentDate1;
    if (timeDifference <= 0) {
      setExpired(true);
    } else {
      setExpired(false);
      let remainingTime = timeDifference;
      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      remainingTime -= days * (1000 * 60 * 60 * 24);
      const hours = Math.floor(remainingTime / (1000 * 60 * 60));
      remainingTime -= hours * (1000 * 60 * 60);
      const minutes = Math.floor(remainingTime / (1000 * 60));
      remainingTime -= minutes * (1000 * 60);
      const seconds = Math.floor(remainingTime / 1000);
      if (screenWidth < 450) {
        setTimeLeft(`${days} D, ${hours} H ,${minutes} M`);
      } else {
        setTimeLeft(`${days} days, ${hours} hours, ${minutes} minutes`);
      }
    }
    fetchData();
  }, [dispatch, productId, formattedDate, productDetails.createdAt]);
  return (
    <div
      className={`${styles.table_container} ${
        darkMode ? styles.table_containerDark : ""
      }`}
    >
      <table>
        <thead>
          <tr>
            <td>
              <span
                className={`${styles.title} ${
                  darkMode ? styles.titleDark : ""
                }`}
              >
                Current Bid
              </span>
              {bidList && bidList.length > 0 ? (
                <span
                  className={`${styles.price} ${
                    darkMode ? styles.priceDark : ""
                  }`}
                >
                  {bidList[0]?.amount}$
                </span>
              ) : (
                <span
                  className={`${styles.price} ${
                    darkMode ? styles.priceDark : ""
                  }`}
                >
                  {productDetails.pricing?.price}
                </span>
              )}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span
                className={`${styles.left} ${darkMode ? styles.leftDark : ""}`}
              >
                Number of bids
              </span>
              <span
                className={`${styles.right} ${
                  darkMode ? styles.rightDark : ""
                }`}
              >
                {bidList ? bidList.length : 0}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span
                className={`${styles.left} ${darkMode ? styles.leftDark : ""}`}
              >
                Number of bidders
              </span>
              <span
                className={`${styles.right} ${
                  darkMode ? styles.rightDark : ""
                }`}
              >
                {number} bidders
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span
                className={`${styles.left} ${darkMode ? styles.leftDark : ""}`}
              >
                Quantity
              </span>
              <span
                className={`${styles.right} ${
                  darkMode ? styles.rightDark : ""
                }`}
              >
                {quantity}
              </span>
            </td>
          </tr>
          {productDetails && productDetails.timer?.enabled && (
            <>
              <tr>
                <td>
                  <span
                    className={`${styles.left} ${
                      darkMode ? styles.leftDark : ""
                    }`}
                  >
                    Time left
                  </span>
                  <span
                    className={`${styles.right} ${
                      darkMode ? styles.rightDark : ""
                    }`}
                  >
                    {expired ? "Bid expired" : timeLeft}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    className={`${styles.left} ${
                      darkMode ? styles.leftDark : ""
                    }`}
                  >
                    Duration of bid
                  </span>
                  <span
                    className={`${styles.right} ${
                      darkMode ? styles.rightDark : ""
                    }`}
                  >
                    {daysDiff === 0 ? "1" : daysDiff} days
                  </span>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default DetailsTables;
