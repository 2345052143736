import React from "react";
import styles from './ProdCardSkel.scss'
const ProdCardSkel = () => {
    return (
        <div className="cardSkelContainerr ">
            <div className='cardSkell'>
                <div className='cardHeaderSkell'></div>
                <div className='cardBodySkell'>
                    <div className='cardTitleSkell'></div>
                    <div className='cardDescriptionSkell'></div>
                </div>
            </div>
            <div className='cardSkell'>
                <div className='cardHeaderSkell'></div>
                <div className='cardBodySkell'>
                    <div className='cardTitleSkell'></div>
                    <div className='cardDescriptionSkell'></div>
                </div>
            </div>
            <div className='cardSkell'>
                <div className='cardHeaderSkell'></div>
                <div className='cardBodySkell'>
                    <div className='cardTitleSkell'></div>
                    <div className='cardDescriptionSkell'></div>
                </div>
            </div>
            <div className='cardSkell'>
                <div className='cardHeaderSkell'></div>
                <div className='cardBodySkell'>
                    <div className='cardTitleSkell'></div>
                    <div className='cardDescriptionSkell'></div>
                </div>
            </div>
            <div className='cardSkell'>
                <div className='cardHeaderSkell'></div>
                <div className='cardBodySkell'>
                    <div className='cardTitleSkell'></div>
                    <div className='cardDescriptionSkell'></div>
                </div>
            </div>
            <div className='cardSkell'>
                <div className='cardHeaderSkell'></div>
                <div className='cardBodySkell'>
                    <div className='cardTitleSkell'></div>
                    <div className='cardDescriptionSkell'></div>
                </div>
            </div>
        </div>
    )
}
export default ProdCardSkel;
