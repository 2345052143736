const initialState = [];
const filterValue = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_FILTER":
            return action.payload
        case "CLEAR_VALUE":
            return initialState
        default:
            return state;
    }
};
export default filterValue;
