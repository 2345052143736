import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_LOGIN_RESET,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  EMAIL_VERIFICATION_REQUEST,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_FAIL,
  ACTIVATE_USER_REQUEST,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAIL,
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAIL,
  FACEBOOK_LOGIN_REQUEST,
  FACEBOOK_LOGIN_SUCCESS,
  FACEBOOK_LOGIN_FAIL,
  USER_ERROR_RESET,
  EMAIL_VERIFICATION_RESET,
  ACTIVATE_USER_RESET,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  FIREBASE_REGISTER_REQUEST,
  FIREBASE_REGISTER_SUCCESS,
  FIREBASE_REGISTER_FAIL,
  ADM_LOGIN_REQUEST,
  ADM_LOGIN_SUCCESS,
  ADM_LOGIN_FAIL,
  ADM_LOGIN_RESET,
  SET_ADM_INFO,
} from "../constants/authConstants";
const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;
const admInfoFromStorage = localStorage.getItem('admInfo')
  ? JSON.parse(localStorage.getItem('admInfo'))
  : null;
  export const googleLoginSuccess = (user) => ({
    type: GOOGLE_LOGIN_SUCCESS,
    payload: user
  });
  
export const userLoginReducer = (
  state = { loading: false, error: null, user: userInfoFromStorage  },
  action
) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { ...state, loading: true };
    case USER_LOGIN_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    
      case USER_LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload };
      case SET_ADM_INFO:
  return { ...state };
    case USER_LOGIN_RESET:
      return { loading: false, error: null, user: null };
    default:
      return state;
  }
};
export const admLoginReducer = (
  state = { loading: false, error: null, adm: admInfoFromStorage  },
  action
) => {
  switch (action.type) {
    case ADM_LOGIN_REQUEST:
      return { ...state, loading: true };
    case ADM_LOGIN_SUCCESS:
      return { ...state, loading: false, adm: action.payload };
    
      case ADM_LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload };
      case SET_ADM_INFO:
      return { ...state, adm: action.payload };
    case ADM_LOGIN_RESET:
      return { loading: false, error: null, adm: null };
    default:
      return state;
  }
};
export const userLogoutReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};
export const userRegisterReducer = (state = { userData: null }, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { ...state, loading: true };
    case USER_REGISTER_SUCCESS:
      return { ...state, loading: false, userData: action.payload };
    case USER_REGISTER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return { userData: null };
    default:
      return state;
  }
};
export const emailVerificationReducer = (
  state = { loading: false, error: null, code: null },
  action
) => {
  switch (action.type) {
    case EMAIL_VERIFICATION_REQUEST:
      return { ...state, loading: true };
    case EMAIL_VERIFICATION_SUCCESS:
      return { ...state, loading: false, code: action.payload };
    case EMAIL_VERIFICATION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case EMAIL_VERIFICATION_RESET:
      return { loading: false, error: null, code: null };
    default:
      return state;
  }
};
export const activateUserReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case ACTIVATE_USER_REQUEST:
      return { ...state, loading: true };
    case ACTIVATE_USER_SUCCESS:
      return { ...state, loading: false, success: true };
    case ACTIVATE_USER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case ACTIVATE_USER_RESET:
      return { loading: false, error: null, success: false };
    default:
      return state;
  }
};
export const forgetPasswordReducer = (
  state = { code: null, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case FORGET_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case FORGET_PASSWORD_SUCCESS:
      return { ...state, loading: false, code: action.payload };
    case FORGET_PASSWORD_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const updatePasswordReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case UPDATE_PASSWORD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_PASSWORD_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const googleLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_LOGIN_REQUEST:
      return { loading: true };
    case GOOGLE_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case GOOGLE_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const facebookLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case FACEBOOK_LOGIN_REQUEST:
      return { loading: true };
    case FACEBOOK_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case FACEBOOK_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const firebaseRegisterReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case FIREBASE_REGISTER_REQUEST:
      return { ...state, loading: true };
    case FIREBASE_REGISTER_SUCCESS:
      return { ...state, loading: false, success: true };
    case FIREBASE_REGISTER_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
