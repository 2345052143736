import {
    GET_PRODUCT_BYSUBCAT_FAIL,
    GET_PRODUCT_BYSUBCAT_SUCCESS,
    GET_PRODUCT_BYSUBCAT_REQUEST,
} from "../constants/productDetailsConstants";
const initialState = {
    Subcat: [],
    error: null,
    loading: false,
};
export const SubCatReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_BYSUBCAT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_PRODUCT_BYSUBCAT_SUCCESS:
            return {
                ...state,
                Subcat: action.payload,
                error: null,
                loading: false,
            };
        case GET_PRODUCT_BYSUBCAT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
