import React, { useState, useRef, useCallback, useEffect } from "react";
// import styles from "../../../common/components/SellModal/SellModal.module.scss";
import styles from './MapModal.module.scss'
import ReactDom from "react-dom";
import { ReactComponent as Close } from "../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../assests/svg/canceldark.svg";
import cancelDark from "../../../assests/svg/product.svg";
import AddAddressPage from "../../AddAddressPage/AddAddressPage";
import { ReactComponent as Search } from "../../../assests/svg/search icon.svg";
import map from "../../../assests/images/map.png";
import Switch from "../../../common/components/Switch/Switch";
import { GoogleMap, useLoadScript, MarkerF, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../../../store/actions/addAddressActions";
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { google } from 'google-maps';
import { useActionData, useNavigate } from "react-router-dom";
import { makeDefault } from "../../../store/actions/addAddressActions";
import { CreateAddressReducer } from "../../../store/reducers/AddAddressReducer";
import { getAddressByUser } from "../../../store/actions/addAddressActions";
import { toast } from "react-toastify";
const libraries = ["places"];
const MapModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [center, setCenter] = useState({ lat: 33.8547, lng: 35.8623 });
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          // Handle error
        }
      );
    } else {
      // console.log('error in geo')
    }
  }, []); const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {
        lat: () => 43.6532,
        lng: () => -79.3832,
      },
      radius: 200 * 1000,
    },
  });
  const [isDefault, setIsDefault] = useState(false);
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCLlu4lwcE0fWQFAOf50EUql1LwAmNRaPk",
    libraries,
  });
  const [markers, setMarkers] = useState({});
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [selected, setSelected] = useState(null);
  const [newPlace, setNewPlace] = useState(null);
  const [added, setAdded] = useState(null);
  let markerAddress = [];
  //autocomplte
  const [addresss, setAddress] = useState(null);
  const [markerLocation, setMarkerLocation] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const handlePlaceSelect = () => {
    const inputElement = document.getElementById('autocomplete-input');
    const autocomplete = new window.google.maps.places.Autocomplete(inputElement);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      setSelectedPlace(place);
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    });
   };
  React.useEffect(() => {
    if (selectedPlace) {
      const lat = selectedPlace.geometry.location.lat();
      const lng = selectedPlace.geometry.location.lng();
 
    }
  }, [selectedPlace]);
  const getAddress = async (event) => {
    Geocode.setApiKey("AIzaSyCLlu4lwcE0fWQFAOf50EUql1LwAmNRaPk");
    Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
      async (response) => {
        const address = response.results[0].formatted_address;
        markerAddress[0] = address;
        const newMarker = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
          address: address,
          time: new Date(),
        };
        if (markerAddress.length > 0) {
          setMarkerLocation(newMarker);
        }
      },
      (error) => { }
    );
  };
  const [vvalue, setVvalue] = useState(null);
  const onMapClick = (event) => {
    setCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };
  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);
  const navigate = useNavigate();
  const display = () => {
    if (!isLoaded) return <div>Loading...</div>;
    return (
      <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName={styles.map}
        onLoad={onMapLoad}
        onClick={onMapClick}
      >
        <MarkerF
          position={center}
          onClick={() => {
            setSelected(null);
          }}
          draggable={true}
          onDragEnd={onMapClick}
        />
      </GoogleMap>
    );
  };
   const coord = { lat: center.lat, long: center.lng }
   const loading = useSelector(
    (state) => state.CreatedAddress?.loading
  );
   const [successMessage, setSuccessMessage] = useState('');
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const data = { title: title, coordinates: coord, description: description };
      const jsonData = JSON.stringify(data);
      const response = await dispatch(addAddress(jsonData));
       if (isDefault) {
        dispatch(makeDefault(response._id));
      }
      if (response && response._id) {
        setSuccessMessage('Address added successfully');
        toast.success('Address added successfully')
        dispatch(getAddressByUser());
        setTimeout(() => {
          setSuccessMessage('');
          // Invoke onClose to close the modal
        }, 3000);
        onClose();
      } else {
        toast.error('Something went wrong while creating this address')
        setSuccessMessage('Something went wrong while creating this address')
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
        <div className={styles.title}>
        <h1>Add Address</h1>
        </div>
        <div className={styles.container}>
          {darkMode ? (
            <CloseDark className={styles.close} onClick={onClose} />
            ) : (
              <Close className={styles.close} onClick={onClose} />
              )}
          <div className={styles.container}>
             
            <div className={`${styles.wrapper} ${darkMode ? styles.wrapperDark : ''}`}>
              <div className={styles.input_container}>
                <input
                  type="text"
                  placeholder="Type Address Nickname"
                  className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                // disabled={!ready}
                />
                <Search className={styles.icon} />
              </div>
              <div className={styles.input_container}>
                <input
                  type="text"
                  placeholder="Type Description"
                  className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                // disabled={!ready}
                />
                <Search className={styles.icon} />
              </div>
              <div className={styles.input_container}>
                <input
                  id="autocomplete-input"
                  type="text"
                  placeholder="Enter a location"
                  className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                  onFocus={handlePlaceSelect}
                />
                <Search className={styles.icon} />
              </div>
              <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Select The location from the map</h1>
              {/* <img src={map} alt="map" className={styles.map} /> */}
              {display()}
              <div className={styles.default_container}>
                <h3 className={`${styles.subtitle} ${darkMode ? styles.subtitleDark : ''}`}>Set as the default address</h3>
                <Switch
                  isSelected={isDefault}
                  setIsSelected={() => setIsDefault(!isDefault)}
                />
              </div>
              {/* {successMessage && <p style={{ color: '#0298a6', fontWeight: '600' }}>{successMessage}</p>} */}
              {loading ? (
                <button className={styles.saveload_btn}>Save Address</button>
              ) : (
                <button className={`${styles.save_btn} ${darkMode ? styles.save_btnDark : ''}`} onClick={handleSubmit}>Save Address</button>
              )
              }
            </div>
          </div>
        </div>
      </div>
      <style>
        {`.pac-container {
          z-index: 10000 !important;
        }`}
      </style>
    </>,
    document.getElementById("portal")
  );
};
export default MapModal;
