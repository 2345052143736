import React from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./TermOfUse.module.scss";
const TermOfUse = () => {
  return (
    <Layout title={"Term Of Use"}>
      <div className={styles.container}>
      <h1>Term Of Use</h1>
      </div>
      </Layout>
      )
    }
 export default TermOfUse;