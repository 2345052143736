import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import Cards from "../../../../common/components/Cards/Cards";
import { BsArrowRight } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { GetAllFeaturedProduct, GetAllFeaturedProductSuccess } from "../../../../store/actions/productDetailsAction";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
//import "./styles.css";
// import required modules
import { Grid, Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../../../common/components/ErrorMessage/ErrorMessage";
import Loader from "../../../../common/components/Loader/Loader";
import { featuredListHandler } from "../../../../store/actions/featuredActions";
import { FEATURED_LIST_RESET } from "../../../../store/constants/featuredConstants";
import Car from "../../../../assests/images/Car.png";
import SwiperCore, { Navigation } from "swiper";
import CardSkel from "../../../../common/components/Cards/CardSkel";
import { GetServicesWishlist } from "../../../../store/actions/servicesActions";
import ProdCardSkel from "./ProdCardSkel";
import { GetWishList } from "../../../../store/actions/whishlistActions";
import { Helmet } from "react-helmet";
SwiperCore.use([Navigation]);
const FeaturedProduct = () => {
  const dispatch = useDispatch();
  const featuredList = useSelector((state) => state.featuredList);
  const { featuredList: list, error } = featuredList;
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const featuredproducts = useSelector((state) => state.featuredproducts?.featuredproducts?.data?.products)
  const [featuredProduct, setFeaturedProduct] = useState([]);
  useEffect(() => {
    dispatch(GetAllFeaturedProduct({ pageSize: 10, page: 1 }))
      .then((data) => {
        dispatch(GetAllFeaturedProductSuccess(data));
        setFeaturedProduct(data.data?.products)
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isSmallScreen = windowWidth < 768;
  const screenWidth = window.innerWidth;
  let slidesPerView;
  if (screenWidth > 1900) {
    slidesPerView = 6;
  }
  else if (screenWidth < 768) {
    slidesPerView = 'auto';
  } else {
    slidesPerView = 4;
  }
  //   let SpaceBetween;
  //  if (screenWidth > 1600) {
  //   SpaceBetween = 0;
  //   } else {
  //     SpaceBetween = 30;
  //   }
  const loading = useSelector(
    (state) => state.featuredproducts?.loading
  );
  const ServicesWishlist = useSelector((state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList)
  const wishList = useSelector((state) => state.Wishlist?.Wishlist?.data?.wishList)
  useEffect(() => {
    dispatch(GetServicesWishlist())
    dispatch(GetWishList())
  }, [dispatch]);

  return (
    <>
      <section className={`${styles.section} ${darkMode ? styles.sectionDark : ''}`} >
        <div className={styles.header}>
          <h2 className={`${styles.header__title} ${darkMode ? styles.header__titleDark : ''}`}>Featured Products</h2>
          <h2 className={styles.all}>
            <Link className={`${styles.S_all} ${darkMode ? styles.S_allDark : ''}`} to={"/featured-product"}>
              See all <BsArrowRight className={styles.arrow} />
            </Link>
          </h2>
        </div>
        <div className={styles.swiper__section}>
          <Swiper
            freeMode
            navigation={!isSmallScreen}
            modules={[Navigation]}
            spaceBetween={30}
            slidesPerView={slidesPerView}
          >
            {loading ? (
              <div>
                <ProdCardSkel />
              </div>
            ) : (
              featuredproducts && featuredproducts
                .filter((product) => product.status === "confirmed"  && product.featured)
                .reduce((acc, product, i, arr) => {
                  if (i % 2 === 0) {
                    const nextProduct = arr[i + 1];
                    acc.push(
                      <SwiperSlide className={styles.swiperslide} key={product._id}>
                        <div className={styles.cardsContainer}>
                          <Cards
                            title={product.title}
                            price={product.pricing?.price}
                            mainimage={product.imageUrls[0]}
                            id={product._id}
                            featured={product.featured}
                            time={product.createdAt}
                            end={product.timer?.deadline}
                            timer={product.timer?.enabled}
                            old={product.condition}
                            location={product.pickUpAddress?.region}
                            discounted={product.discount?.enabled}
                            percentage={product.discount?.percentage}
                            isService={product.isService}
                            ServicesWishlist={ServicesWishlist}
                            wishList={wishList}
                            sold={product && product.status === "sold"}
                          />
                          {nextProduct && (
                            <Cards
                              title={nextProduct.title}
                              price={nextProduct.pricing?.price}
                              mainimage={nextProduct.imageUrls[0]}
                              id={nextProduct._id}
                              featured={nextProduct.featured}
                              time={nextProduct.createdAt}
                              end={nextProduct.timer?.deadline}
                              old={nextProduct.condition}
                              timer={nextProduct.timer?.enabled}
                              location={nextProduct.pickUpAddress?.region}
                              discounted={nextProduct.discount?.enabled}
                              percentage={nextProduct.discount?.percentage}
                              isService={nextProduct.isService}
                              ServicesWishlist={ServicesWishlist}
                              wishList={wishList}
                              sold={nextProduct && nextProduct.status === "sold"}

                            />
                          )}
                        </div>
                      </SwiperSlide>
                    );
                  }
                  return acc;
                }, [])
            )}
          </Swiper>
          <style>
            {`
                    .swiper-button-next { color: #0298a6; }
                    .swiper-button-prev { color: #0298a6;}
                    `}
          </style>

        </div>
      </section>
    </>
  );
};
export default FeaturedProduct;
