import React from "react";
import styles from "./Card.module.scss";
import Electronics from "../../../assests/images/Electronics.png";
import Car from "../../../assests/images/Car.png";
import service from "../../../assests/images/Services.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const RequestCard = ({ allTypes }) => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <div className={styles.card_container}>
      <div className={`${styles.card} ${darkMode ? styles.cardDark : ''}`}>
        <img src={service} alt="serices" className={styles.card_image} />
        <div className={styles.subcategories_container}>
        {Array.isArray(allTypes) && allTypes.length > 0 &&
            allTypes.map((type) => (
            <div className={styles.subcategories_item}>
              <Link
                key={type.id}
                className={`${styles.subcategories_title} ${darkMode ? styles.subcategories_titleDark : ""}`}
                to={`/sell/services/request/${type.title
                  .replaceAll(" ", "-")
                  .replaceAll("&", "And")
                  .replaceAll(",", "")}/${type._id}`}
              >
                {type.title}
              </Link>
            </div>
          ))}
        </div>
      </div>
      <h1 className={`${styles.card_title} ${darkMode ? styles.card_titleDark : ''}`}>Services</h1>
    </div>
  );
};
export default RequestCard;
