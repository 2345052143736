import React, { useState, useEffect } from "react";
import "./chatHeader.scss";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BsFillTelephoneFill, BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineFlag } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { BiNoEntry, BiCheckCircle } from "react-icons/bi";
import ReportModal from "./reportModal";
import { timePosted } from "../../../../common/Utils";
import { db } from "../../../../firebase";
import {
  get,
  ref,
  update,
  runTransaction,
  push,
  set,
  remove,
} from "firebase/database";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  PostBlock,
  PostReport,
  PostUnBlock,
} from "../../../../store/actions/userActions";
import { GetUser } from "../../../../store/actions/userActions";
import { IsBlocked } from "../../../../store/actions/userActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../../../common/components/ConfirmModal/ConfirmModal";
const ChatHeader = ({ selectedUser, lastMessage, onDeleteChat }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const specificUserId = localStorage.getItem("uid");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showThreeDotsDropdown, setShowThreeDotsDropdown] = useState(false);
  const isUserBlocked = useSelector(
    (state) => state.isUserBlocked?.isUserBlocked?.blocked
  );
  const rawData = {
    userIdToBlock: selectedUser.id,
  };
  const data = {
    userIdToRemove: selectedUser.id,
  };
  const toggleThreeDotsDropdown = () => {
    setShowThreeDotsDropdown(!showThreeDotsDropdown);
    setShowDropdown(false);
  };
  useEffect(() => {
    dispatch(IsBlocked(rawData));
  });
  const [report, setReport] = useState(false);
  const openReport = () => {
    setReport(true);
  };
  const handleEmailClick = () => {
    const mailto = selectedUser.email;
    window.location.href = `mailto:${mailto}`;
  };
  const [phoneNber, setphoneNumber] = useState("");
  useEffect(() => {
    const fetchUser = async () => {
      const response = await dispatch(GetUser(selectedUser.id));
      if (response && response.data?.phone) {
        setphoneNumber(response.data?.phone);
      }
    };
    fetchUser();
  }, [dispatch, selectedUser.id]);
  function initiatePhoneCall() {
    const url = `tel:${phoneNber}`;
    window.location.href = url;
  }
  const handleDeleteMessages = () => {
    remove(
      ref(db, "/chatlist/" + specificUserId + "/" + selectedUser.id)
    ).catch((error) => {
      // console.log("error", error);
    });
    onDeleteChat();
    navigate("/chat");
  };
  const handleBlockUser = async (e) => {
    e.preventDefault();
    if (isUserBlocked) {
      try {
        const response = await dispatch(PostUnBlock(data));
        if (response) {
          toast.success("User unblocked successfully.");
        }
      } catch (error) {
        // console.log("blk", error);
      }
      dispatch(IsBlocked(rawData));
    } else {
      try {
        const response = await dispatch(PostBlock(rawData));
        if (response) {
          toast.success("User blocked successfully.");
        }
      } catch (error) {
        // console.log("blk", error);
      }
      dispatch(IsBlocked(rawData));
    }
  };
  return (
    <>
      <ReportModal
        open={report}
        onClose={() => {
          setReport(false);
        }}
        selectedUser={selectedUser.id}
      />
      <div className={darkMode ? "chatheaderDark" : "chatheader"}>
        <div
          onClick={()=>navigate(
            `/user-review/${selectedUser.name.replace(" ", "-")}/${
              selectedUser.id
            }`
          )}
        >
          <img
            className="profile-pic-header"
            src={selectedUser.image}
            alt="Profile"
          />
        </div>
        <div className="content-container">
          <div className="detailsheader">
            <p className={darkMode ? "nameheaderDark" : "nameheader"}>
              {selectedUser
                ? selectedUser.name && selectedUser.name.length > 15
                  ? selectedUser.name.substring(0, 15) + "..."
                  : selectedUser.name
                : ""}
            </p>
            {/* {lastMessage && lastMessage.sendTime && (
              <p className={darkMode ? "last-seenheaderDark" : "last-seenheader"}>Last seen: {timePosted(lastMessage.sendTime)} ago</p>
            )} */}
          </div>
        </div>
        <div className="icons-containerh">
          <AiOutlinePhone
            onClick={initiatePhoneCall}
            className={darkMode ? "iconhDark" : "iconh"}
          />
          <AiOutlineMail
            onClick={handleEmailClick}
            className={darkMode ? "iconhDark" : "iconh"}
          />
          <div className="threedots" onClick={toggleThreeDotsDropdown}>
            <button>
              <BsThreeDotsVertical
                className={darkMode ? "iconhDark" : "iconh"}
              />
            </button>
            {showThreeDotsDropdown && (
              <div className="dotDrop">
                <button onClick={openReport}>
                  <p className="edit">
                    <AiOutlineFlag className="ic" /> Report user
                  </p>
                </button>
                <hr />
                <button onClick={(e) => handleBlockUser(e)}>
                  <p className="edit">
                    {isUserBlocked ? (
                      <>
                        <BiCheckCircle className="ic" /> Unblock user
                      </>
                    ) : (
                      <>
                        <BiNoEntry className="ic" /> Block user
                      </>
                    )}
                  </p>
                </button>
                <hr />
                <button onClick={() => setIsOpen(true)}>
                  <p className="delete">
                    <BsFillTrashFill className="icd" /> Delete Chat
                  </p>
                </button>
                <hr />
                <button>
                  <p className="edit">
                    <div className="ic"></div> Cancel
                  </p>
                </button>
              </div>
            )}
          </div>
        </div>
        <ConfirmModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <h1 className={`confirm_title`}>Confirmation</h1>
          <p className={`confirm_message`}>
            Are you sure you want to <span>delete</span> Chat?
          </p>
          <div className={`buttons_container`}>
            <button className={`cancel`} onClick={handleDeleteMessages}>
              Delete
            </button>
            <button className={`confirm`} onClick={() => setIsOpen(false)}>
              Keep
            </button>
          </div>
        </ConfirmModal>
      </div>
    </>
  );
};
export default ChatHeader;
