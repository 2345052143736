import React from "react";
import styles from "./styles.module.scss";
import background from "../../../../assests/images/Groupee.png";
import GooglePlay from "../../../../assests/images/googleplay.png";
import AppleStore from "../../../../assests/images/appstore.png";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
const DownloadTheApp = () => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <section className={styles.section} >
      {isSmallScreen?(
        <>
        <img src='/VerticalBanner.png' alt="catch of the day" className={`${styles.img} ${darkMode ? styles.imgDark : ''}`} />
        <div className={styles.infomb}>
          <div className={styles.iconsmb}>
            <img
              className={styles.iconmb}
              src='/googleplay.png'
              alt="Google Play Store"
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.la72halk', '_blank')}
            />
            <img className={styles.iconmb} onClick={() => window.open('https://apps.apple.com/lb/app/la7i27alak/id1660383776', '_blank')} src='/appstore.png' alt="Apple Store" />
          </div>
        </div>
        </>
      ):(
        <>
        <img src='/DownloadBanner.png' alt="catch of the day" className={`${styles.img} ${darkMode ? styles.imgDark : ''}`} />
        <div className={styles.info}>
          <div className={styles.icons}>
            <img
              className={styles.icon}
              src='/googleplay.png'
              alt="Google Play Store"
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.la72halk', '_blank')}
            />
            <img className={styles.icon} onClick={() => window.open('https://apps.apple.com/lb/app/la7i27alak/id1660383776', '_blank')} src='/appstore.png' alt="Apple Store" />
          </div>
        </div>
        </>
      )}
  
    </section>
  );
};
export default DownloadTheApp;
