import React,{useState} from "react";
import { Switch } from "antd";
import "./Enable.scss";
const Enable = () => {
    const [isOn, setIsOn] = useState(true);
    const handleStateToggle = (checked) => {
        setIsOn(checked);
      };
  return (
    <div className="enable-container">
          <Switch
            defaultChecked={false}
            checked={isOn}
            onChange={handleStateToggle}
            className={isOn ? "switch-on" : "switch-off"}
          />
        </div>
  );
};
export default Enable;