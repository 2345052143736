import React, { useState, useEffect } from "react";
import { Switch } from "antd";
import "./Verified.scss";
const Verified = ({ isChecked }) => {
  const [isOn, setIsOn] = useState(isChecked);
  useEffect(() => {
    setIsOn(isChecked);
  }, [isChecked]);
  const handleStateToggle = (checked) => {
    setIsOn(checked);
  };
  return (
    <div className="enable-container">
      <Switch
        checked={isOn}
        onChange={handleStateToggle}
        className={isOn ? "switch-on" : "switch-off"}
        disabled={true}
      />
    </div>
  );
};
export default Verified;
