import React, { useState, useEffect } from "react";
import styles from "./ReviewCard.module.scss";
import { FaStar, FaRegStar, FaCamera } from "react-icons/fa";
import { timePosted } from "../../../common/Utils";
import { GetUser } from "../../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { url } from "../../../common/constants/url";
const ReviewCard = ({ from, text, star, time, id }) => {
  const dispatch = useDispatch();
  const [userReview, setUserReview] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  useEffect(() => {
    dispatch(GetUser(from))
      .then((response) => {
        setUserReview(response.data);
        setReviewsLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch, from]);
  const filledStars = (num) => {
    let stars = [];
    for (let i = 0; i < num; i++) {
      stars.push(<FaStar key={i} />);
    }
    for (let i = num; i < 5; i++) {
      stars.push(<FaRegStar key={i} />);
    }
    return stars;
  };
  return (
    <>
      <div className={styles.section}>
        <div className={styles.container}>
          <div className={styles.image_container}>
            <img
              src={from && from.imageUrl}
              alt="user"
              className={styles.image}
              loading="lazy"
            />
            <div className={styles.name_container}>
              <h3 className={`${styles.name} ${darkMode ? styles.nameDark : ''}`}>{from && from.username}</h3>
              <div className={styles.star}>
                {filledStars(star)}
              </div>
            </div>
          </div>
          <p className={`${styles.time} ${darkMode ? styles.timeDark : ''}`}>{timePosted(time)} ago</p>
        </div>
        <p className={`${styles.text} ${darkMode ? styles.textDark : ''}`}>
          {text}
        </p>
        <div className={`${styles.line} ${darkMode ? styles.lineDark : ''}`}></div>
      </div>
    </>
  );
};
export default ReviewCard;
