import axios from "axios";
import { url } from "../../common/constants/url";
import {
  CONFIRM_PRODUCT_FAIL,
  CONFIRM_PRODUCT_SUCCESS,
  FEATURE_PRODUCT_FAIL,
  FEATURE_PRODUCT_SUCCESS,
  POST_COD_PRODUCT_SUCCESS,
  POST_COD_PRODUCT_FAIL,
  POST_COD_SERVICE_SUCCESS,
  POST_COD_SERVICE_FAIL,
  CHECK_IF_USER_IN_WAITING_LIST_SUCCESS,
  CHECK_IF_USER_IN_WAITING_LIST_FAIL,
  CONFIRM_SERVICE_SUCCESS,
  CONFIRM_SERVICE_FAIL,
  SELL_PRODUCT_SUCCESS,
  SELL_PRODUCT_FAIL,
} from "../constants/myProductsContants";
export const ConfirmProduct = (ProductId) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("token");

      const response = await axios.put(
        `${url}/product/${ProductId}/confirm`,
        {},
        {
          headers: {
            authorization: token,
          },
        }
      );

      dispatch(ConfirmProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(ConfirmProductFail(error));
      // console.log(error);
    }
  };
};
export const ConfirmProductSuccess = (status) => {
  return {
    type: CONFIRM_PRODUCT_SUCCESS,
    payload: status,
  };
};
const ConfirmProductFail = (error) => ({
  type: CONFIRM_PRODUCT_FAIL,
  payload: error,
});
export const FeatureProduct = ({ ProductId, type }) => {
  return async (dispatch) => {
    try {
      if (type) {
        const token = await localStorage.getItem("token");

        const response = await axios.put(
          `${url}/service/${ProductId}/feature`,
          {},
          {
            headers: {
              authorization: token,
            },
          }
        );

        dispatch(FeatureProductSuccess(response.data));
        return response.data;
      } else {
        const token = await localStorage.getItem("token");

        const response = await axios.put(
          `${url}/product/${ProductId}/feature`,
          {},
          {
            headers: {
              authorization: token,
            },
          }
        );

        dispatch(FeatureProductSuccess(response.data));
        return response.data;
      }
    } catch (error) {
      dispatch(FeatureProductFail(error));
      // console.log(error);
    }
  };
};
export const FeatureProductSuccess = (status) => {
  return {
    type: FEATURE_PRODUCT_SUCCESS,
    payload: status,
  };
};
const FeatureProductFail = (error) => ({
  type: FEATURE_PRODUCT_FAIL,
  payload: error,
});
export const PostCODProduct = (ProductId, discount) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("token");

      const response = await axios.post(
        `${url}/admin/setProduct/catch/${ProductId}/${discount}`,
        {},
        {
          headers: {
            authorization: token,
          },
        }
      );

      dispatch(PostCODProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(PostCODProductFail(error));
      // console.log(error);
    }
  };
};
export const PostCODProductSuccess = (status) => {
  return {
    type: POST_COD_PRODUCT_SUCCESS,
    payload: status,
  };
};
const PostCODProductFail = (error) => ({
  type: POST_COD_PRODUCT_FAIL,
  payload: error,
});
export const PostCODService = (ServiceId, discount) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("token");

      const response = await axios.post(
        `${url}/admin/setService/catch/${ServiceId}/${discount}`,
        {},
        {
          headers: {
            authorization: token,
          },
        }
      );

      dispatch(PostCODServiceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(PostCODServiceFail(error));
      // console.log(error);
    }
  };
};
export const PostCODServiceSuccess = (status) => {
  return {
    type: POST_COD_SERVICE_SUCCESS,
    payload: status,
  };
};
const PostCODServiceFail = (error) => ({
  type: POST_COD_SERVICE_FAIL,
  payload: error,
});
export const CheckIfUserInWaitingList = (ProductId) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `${url}/offer/checkUserInWaitinglist/${ProductId}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );

      dispatch(CheckIfUserInWaitingListSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(CheckIfUserInWaitingListFail(error));
      // console.log(error);
    }
  };
};
export const CheckIfUserInWaitingListSuccess = (status) => {
  return {
    type: CHECK_IF_USER_IN_WAITING_LIST_SUCCESS,
    payload: status,
  };
};
const CheckIfUserInWaitingListFail = (error) => ({
  type: CHECK_IF_USER_IN_WAITING_LIST_FAIL,
  payload: error,
});
export const ConfirmService = (ProductId) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("token");

      const response = await axios.put(
        `${url}/service/${ProductId}/confirm`,
        {},
        {
          headers: {
            authorization: token,
          },
        }
      );

      dispatch(ConfirmServiceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(ConfirmServiceFail(error));
      // console.log(error);
    }
  };
};
export const ConfirmServiceSuccess = (status) => {
  return {
    type: CONFIRM_SERVICE_SUCCESS,
    payload: status,
  };
};
const ConfirmServiceFail = (error) => ({
  type: CONFIRM_SERVICE_FAIL,
  payload: error,
});
export const SellProduct = (ProductId) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.put(
        `${url}/product/${ProductId}/sell`,
        {},
        {
          headers: {
            authorization: token,
          },
        }
      );

      dispatch(SellProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(SellProductFail(error));
      // console.log(error);
    }
  };
};
export const SellProductSuccess = (status) => {
  return {
    type: SELL_PRODUCT_SUCCESS,
    payload: status,
  };
};
const SellProductFail = (error) => ({
  type: SELL_PRODUCT_FAIL,
  payload: error,
});
