export const GET_PRODUCT_BY_MONTH_SUCCESS = "GET_PRODUCT_BY_MONTH_SUCCESS";
export const GET_PRODUCT_BY_MONTH_FAIL = "GET_PRODUCT_BY_MONTH_FAIL";
export const GET_TOTAL_BIDS_SUCCESS = "GET_TOTAL_BIDS_SUCCESS";
export const GET_TOTAL_BIDS_FAIL = "GET_TOTAL_BIDS_FAIL";
export const GET_PRODUCT_DIFFERENCE_SUCCESS = "GET_PRODUCT_DIFFERENCE_SUCCESS";
export const GET_PRODUCT_DIFFERENCE_FAIL = "GET_PRODUCT_DIFFERENCE_FAIL";
export const GET_SERVICE_DIFFERENCE_SUCCESS = "GET_SERVICE_DIFFERENCE_SUCCESS";
export const GET_SERVICE_DIFFERENCE_FAIL = "GET_SERVICE_DIFFERENCE_FAIL";
export const GET_PRODUCT_COUNT_SUCCESS = "GET_PRODUCT_COUNT_SUCCESS";
export const GET_PRODUCT_COUNT_FAIL = "GET_PRODUCT_COUNT_FAIL";
export const GET_SERVICE_COUNT_SUCCESS = "GET_SERVICE_COUNT_SUCCESS";
export const GET_SERVICE_COUNT_FAIL = "GET_SERVICE_COUNT_FAIL";
export const GET_PRODUCT_COUNT_BY_DATE_SUCCESS =
  "GET_PRODUCT_COUNT_BY_DATE_SUCCESS";
export const GET_PRODUCT_COUNT_BY_DATE_FAIL = "GET_PRODUCT_COUNT_BY_DATE_FAIL";
export const GET_SERVICES_COUNT_BY_DATE_SUCCESS =
  "GET_SERVICES_COUNT_BY_DATE_SUCCESS";
export const GET_SERVICES_COUNT_BY_DATE_FAIL =
  "GET_SERVICES_COUNT_BY_DATE_FAIL";
export const GET_PRODUCTS_PENDING_SUCCESS = "GET_PRODUCTS_PENDING_SUCCESS";
export const GET_PRODUCTS_PENDING_FAIL = "GET_PRODUCTS_PENDING_FAIL";
export const GET_ALL_PRODUCTS_CONFIRMED_SUCCESS =
  "GET_ALL_PRODUCTS_CONFIRMED_SUCCESS";
export const GET_ALL_PRODUCTS_CONFIRMED_FAIL =
  "GET_ALL_PRODUCTS_CONFIRMED_FAIL";
export const GET_PRODUCTS_SOLD_SUCCESS = "GET_PRODUCTS_SOLD_SUCCESS";
export const GET_PRODUCTS_SOLD_FAIL = "GET_PRODUCTS_SOLD_FAIL";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";
export const GET_MERCHANT_SUCCESS = "GET_MERCHANT_SUCCESS";
export const GET_MERCHANT_FAIL = "GET_MERCHANT_FAIL";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAIL = "GET_ADMIN_FAIL";
export const UPDATE_CAROUSEL_SUCCESS = "UPDATE_CAROUSEL_SUCCESS";
export const UPDATE_CAROUSEL_FAIL = "UPDATE_CAROUSEL_FAIL";
export const GET_CAROUSEL_SUCCESS = "GET_CAROUSEL_SUCCESS";
export const GET_CAROUSEL_FAIL = "GET_CAROUSEL_FAIL";
export const CREATE_CATCH_OF_THE_DAY_PRODUCT_SUCCESS =
  "CREATE_CATCH_OF_THE_DAY_PRODUCT_SUCCESS";
export const CREATE_CATCH_OF_THE_DAY_PRODUCT_FAIL =
  "CREATE_CATCH_OF_THE_DAY_PRODUCT_FAIL";
export const GET_COD_SUCCESS = "GET_COD_SUCCESS";
export const GET_COD_FAIL = "GET_COD_FAIL";
export const GET_TOP_SELLING_USER_SUCCESS = "GET_TOP_SELLING_USER_SUCCESS";
export const GET_TOP_SELLING_USER_FAIL = "GET_TOP_SELLING_USER_FAIL";
export const GET_AVERAGE_DEAL_RESULTS_SUCCESS =
  "GET_AVERAGE_DEAL_RESULTS_SUCCESS";
export const GET_AVERAGE_DEAL_RESULTS_FAIL = "GET_AVERAGE_DEAL_RESULTS_FAIL";
export const GET_TOTAL_SALES_SUCCESS = "GET_TOTAL_SALES_SUCCESS";
export const GET_TOTAL_SALES_FAIL = "GET_TOTAL_SALES_FAIL";
export const CHANGE_ROLE_SUCCESS = "CHANGE_ROLE_SUCCESS";
export const CHANGE_ROLE_FAIL = "CHANGE_ROLE_FAIL";
export const GET_SERVICES_PENDING_SUCCESS = "GET_SERVICES_PENDING_SUCCESS";
export const GET_SERVICES_PENDING_FAIL = "GET_SERVICES_PENDING_FAIL";
export const GET_ALL_SERVICES_CONFIRMED_SUCCESS =
  "GET_ALL_SERVICES_CONFIRMED_SUCCESS";
export const GET_ALL_SERVICES_CONFIRMED_FAIL =
  "GET_ALL_SERVICES_CONFIRMED_FAIL";
export const GET_RANGE_FOR_ADMIN_SUCCESS = "GET_RANGE_FOR_ADMIN_SUCCESS";
export const GET_RANGE_FOR_ADMIN_FAIL = "GET_RANGE_FOR_ADMIN_FAIL";
export const GET_ONLINE_USERS_SUCCESS = "GET_ONLINE_USERS_SUCCESS";
export const GET_ONLINE_USERS_FAIL = "GET_ONLINE_USERS_FAIL";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL";
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS";
export const GET_PAYMENT_FAIL = "GET_PAYMENT_FAIL";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAIL = "CREATE_PAYMENT_FAIL";
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT_FAIL = "DELETE_PAYMENT_FAIL";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAIL = "UPDATE_PAYMENT_FAIL";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAIL = "DELETE_FAQ_FAIL";

export const DELETE_SIZE_SUCCESS = "DELETE_SIZE_SUCCESS";
export const DELETE_SIZE_FAIL = "DELETE_SIZE_FAIL";

export const DELETE_TYPE_SUCCESS = "DELETE_TYPE_SUCCESS";
export const DELETE_TYPE_FAIL = "DELETE_TYPE_FAIL";

export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const UPDATE_FAQ_FAIL = "UPDATE_FAQ_FAIL";

export const GET_ENDED_BIDS_SUCCESS = "GET_ENDED_BIDS_SUCCESS";
export const GET_ENDED_BIDS_FAIL = "GET_ENDED_BIDS_FAIL";
export const GET_ENDED_BIDS_REQUEST = "GET_ENDED_BIDS_REQUEST";

export const GET_RATE_EXPERIENCE_SUCCESS = "GET_RATE_EXPERIENCE_SUCCESS";
export const GET_RATE_EXPERIENCE_FAIL = "GET_RATE_EXPERIENCE_FAIL";
export const GET_RATE_EXPERIENCE_REQUEST = "GET_RATE_EXPERIENCE_REQUEST";


