import React, { useState, useEffect, useRef } from "react";
import styles from "./index.scss";
import Profile from "../../../../assests/images/user.png";
import {
  AiFillStar,
  AiOutlineCreditCard,
  AiOutlineStar,
  AiOutlineQuestionCircle,
  AiFillDelete,
  AiOutlineFieldTime,
  AiOutlineBlock
} from "react-icons/ai";
import { FaCoins, FaHistory, FaShoppingBag } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import {
  MdOutlineNotificationsNone,
  MdOutlineDarkMode,
  MdOutlineHeadphones,
  MdLogout,
} from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../store/actions/authActions";
import { getUserById } from "../../../../store/actions/userActions";
import { createCustomerStripe } from "../../../../store/actions/paymentActions";
import { listMyWhishlistID } from "../../../../store/actions/whishlistActions";
import { updategGolocationHandler } from "../../../../store/actions/locationActions";
import axios from "axios";
import { darkMode } from "../../../../store/actions/darkModeAction";
import { BiNoEntry } from "react-icons/bi";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BsCashCoin } from "react-icons/bs";
import ProfileSkel from "./ProfileSkel";
const ProfileDropDown = ({ children }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const handleToggleDarkMode = () => {
    dispatch({ type: 'TOGGLE_DARK_MODE' });
    setChecked(!checked);
    setOpen(false)
  };
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [errorLocation, setErrorLocation] = useState(null);
  const geolocationAPI = navigator.geolocation;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userById = useSelector((state) => state.userById);
  const { user, error} =userById;
  const navigate = useNavigate();
  const [loggedInUser, setLoggedInUser] = useState(userInfo ? true : false);
  useEffect(() => {
    setLoggedInUser(userInfo ? true : false);
  }, [userInfo]);
  useEffect(() => {
    const lgout = document.getElementById('lgout');
    lgout.addEventListener('click', () => {
      window.location.reload();
    });
    return () => {
      lgout.removeEventListener('click', () => {
        window.location.reload();
      });
    };
  }, []);
  const handleLogout = () => {
    dispatch(logout());
    setLoggedInUser(false);
    localStorage.removeItem("userInfo");
    localStorage.removeItem('token')
    localStorage.removeItem('isAdmin')
  };
  // let id = JSON.parse(atob(userInfo?.split(".")[1]))?.id || "";
  let id = "";
  try {
    const decodedUserInfo = atob(userInfo?.split(".")[1]);
    const userInfoObj = JSON.parse(decodedUserInfo);
    id = userInfoObj?.id ?? "";
  } catch (e) {
    // handle decoding or parsing errors here
  }
  const [open, setOpen] = useState(false);
  let menuRef = useRef();
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 901) {
        setOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const getUserCoordinates = () => {
      if (!geolocationAPI) {
        setErrorLocation("Geolocation API is not available in your browser!");
      } else {
        geolocationAPI.getCurrentPosition(
          (position) => {
            const { coords } = position;
            setLat(coords.latitude);
            setLong(coords.longitude);
            dispatch(
              updategGolocationHandler(id, coords.latitude, coords.longitude)
            );
          },
          (error) => {
            setErrorLocation("Something went wrong getting your position!");
          }
        );
      }
    };
    if (id && user === null) {
      dispatch(getUserById(id))
        .then(() => dispatch(listMyWhishlistID(id)))
        .then(() => getUserCoordinates());
      // .then(() => dispatch(updategeolocation(id, lat, long)));
    }
    if (error == "Token authentication failed") {
      dispatch(logout());
    }
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [id, dispatch, user, error, geolocationAPI, lat, long]);
  //display
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [image, setImage] = useState("");
  const [reviews, setReviews] = useState("")
  const userid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`https://backend.la7i27alak.com/user/${userid}`, {
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.user?.username) {
          setUsername(response.data?.user?.username);
        } else if (response.data.user?.companyName) {
          setUsername(response.data.user?.companyName);
        }
        setImage(response.data.user?.imageUrl)
        setReviews(response.data.user?.reviews.average)
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false); 
      });  }, [userid, token]);
  const baseUrl = 'https://backend.la7i27alak.com'
  return (
    <div className="profile-menu-container" ref={menuRef}>
      <div
        className="menu-trigger"
        onClick={() => {
          setOpen(!open);
        }}
      >
        {children}
      </div>
      <div
        className={`dropdown-menu-info ${open ? "active" : "inactive"} ${darkMode ? 'dropdown-menu-infoDark' : ''}`}
      >
        {loading ? (
          <ProfileSkel/>
        ) : (
          <ul>
            <li>
              <div className="profile__container">
                <img
                  // src={user && (user.image ? user.image : Profile)}
                  src={`${image}`}
                  alt="profile"
                  className="profile__picture"
                />
                <div className="details__container">
                  <h3 className={`name ${darkMode ? 'nameDark' : ''}`}>
                    {username.length > 15 ? `${username.slice(0, 15)}..` : username}
                    <div className={`rating`}>
                      {reviews} <AiFillStar className="icon" />
                    </div>
                  </h3>
                  {/* <p className={`email ${darkMode ? 'emailDark' : ''}`}>{user && user.email}</p> */}
                  <Link to={"/user/edit-profile"} className="edit">
                    Edit profile
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <h2 className={`section__title_A ${darkMode ? 'section__title_ADark' : ''}`}>Account</h2>
              <ul className="AccUL">
                <DropDownItem>
                  {darkMode?(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} to={"/user/order-history"}>
                    <img src='/order History - white.svg' alt="OrderHistory" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Order History
                  </Link>
                  ):(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} to={"/user/order-history"}>
                    <img src='/order History.svg' alt="OrderHistory" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Order History
                  </Link>
                  )}
                  
                </DropDownItem>
                <DropDownItem>
                  {darkMode?(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} to={"/user/my-offers"}>
                    <img src='/My offers - white.svg' alt="Myoffers" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> My Offers
                  </Link>
                  ):(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} to={"/user/my-offers"}>
                    <img src='/My offers.svg' alt="Myoffers" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> My Offers
                  </Link>
                  )}
                  
                </DropDownItem>
                <DropDownItem>
                  {darkMode?(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} to={"/user/waiting-list"}>
                    <img src='/my waiting list - white.svg' alt="my waiting list" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> My Waiting List
                  </Link>
                  ):(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} to={"/user/waiting-list"}>
                    <img src='/my waiting list.svg' alt="my waiting list" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> My Waiting List
                  </Link>
                  )}
                  
                </DropDownItem>
                <DropDownItem>
                  {darkMode?(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} to={"/user/live-bids"}>
                    <img src='/live bids - white.svg' alt="live bids" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> My Live Bids
                  </Link>
                  ):(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} to={"/user/live-bids"}>
                    <img src='/live bids.svg' alt="live bids" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> My Live Bids
                  </Link>
                  )}
                  
                </DropDownItem>
                <DropDownItem>
                  {darkMode?(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} to={"/user/addresses"}>
                    <img src='/addresses - white.svg' alt="addresses" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} />{" "}
                    Addresses
                  </Link>
                  ):(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} to={"/user/addresses"}>
                    <img src='/addresses.svg' alt="addresses" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} />{" "}
                    Addresses
                  </Link>
                  )}
                  
                </DropDownItem>
                <DropDownItem>
                  {darkMode? (
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`}
                    to={"/user/payment"}
                  >
                    <img src='/payment - white.svg' alt="payment" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Payment
                  </Link>
                  ):(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`}
                    to={"/user/payment"}
                  >
                    <img src='/payment.svg' alt="payment" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Payment
                  </Link>
                  )}
                </DropDownItem>
                <DropDownItem>
                  {darkMode?(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`}
                    to={"/user/payment-history"}
                  >
                    <img src='/Group 5136 - white.svg' alt="payment history" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Payment History
                  </Link>
                  ):(
                    <Link className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`}
                    to={"/user/payment-history"}
                  >
                    <img src='/payment history.svg' alt="payment history" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Payment History
                  </Link>
                  )}
                  
                </DropDownItem>
                <DropDownItem>
                  {darkMode? (
                    <Link to={"/user/favorite-categories"} className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} >
                    <img src='/favorite categories - white.svg' alt="favorite categories" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Favorite Categories
                  </Link>
                  ):(
                    <Link to={"/user/favorite-categories"} className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} >
                    <img src='/favorite categories.svg' alt="favorite categories" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Favorite Categories
                  </Link>
                  )}
                </DropDownItem>
                <DropDownItem>
                  {darkMode?(
                    <Link to={"/user/blocked-accounts"} className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} >
                    <img src='/blocked users - white.svg' alt="blocked users" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Blocked Accounts
                  </Link>
                  ):(
                    <Link to={"/user/blocked-accounts"} className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} >
                    <img src='/blocked users.svg' alt="blocked users" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Blocked Accounts
                  </Link>
                  )}
                </DropDownItem>
                {/* <DropDownItem>
                  <Link to={"/user/delete-account"} className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`} >
                    <AiFillDelete className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} /> Delete Account
                  </Link>
                </DropDownItem> */}
              </ul>
            </li>
          </ul>
        )}
        <h2 className={`section__title title ${darkMode ? 'section__title titleDark' : ''}`}>Settings</h2>
        <ul>
          <DropDownItem>
            {darkMode?(
              <Link to={'/notifications-setting'} className={`link ${darkMode ? 'linkDark' : ''}`} >
              <img src='/notifications - white.svg' alt="notifications" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} />
              Notifications</Link>
            ):(
              <Link to={'/notifications-setting'} className={`link ${darkMode ? 'linkDark' : ''}`} >
              <img src='/notifications.svg' alt="notifications" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} />
              Notifications</Link>
            )}
          </DropDownItem>
          <DropDownItem>
            {darkMode?(
              <Link className={`link ${darkMode ? 'linkDark' : ''}`} >
              <img src='/Icon feather-moon - white.svg' alt="dark mode on"
                className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} />
              Dark Mode
              <Switch
                checked={darkMode}
                onChange={handleToggleDarkMode}
                color="primary"
                className='switchD'
              />
            </Link>
            ):(
              <Link className={`link ${darkMode ? 'linkDark' : ''}`} >
              <img src='/dark mode on.svg' alt="dark mode on"
                className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} />
              Dark Mode
              <Switch
                checked={darkMode}
                onChange={handleToggleDarkMode}
                color="primary"
                className='switchD'
              />
            </Link>
            )}
          </DropDownItem>
        </ul>
        <h2 className={`section__title title ${darkMode ? 'section__title titleDark' : ''}`}>Help and Support</h2>
        <ul>
          <DropDownItem>
            {darkMode?(
              <Link className={`link ${darkMode ? 'linkDark' : ''}`} to={"/contact-us"}>
              <img src='/contact us - white.svg' alt="contact us" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} />
              Contact Us</Link>
            ):(<Link className={`link ${darkMode ? 'linkDark' : ''}`} to={"/contact-us"}>
              <img src='/contact us.svg' alt="contact us" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} />
              Contact Us</Link>)}
          </DropDownItem>
          <DropDownItem>
            {darkMode? (
              <Link to={'/faq'} className={`link ${darkMode ? 'linkDark' : ''}`} >
              <img src='/FAQ - white.svg' alt="FAQ" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} />
              FAQ</Link>
            ):(
              <Link to={'/faq'} className={`link ${darkMode ? 'linkDark' : ''}`} >
              <img src='/FAQ.svg' alt="FAQs" className={`setting__icon ${darkMode ? 'setting__iconDark' : ''}`} />
              FAQ</Link>
            )}
          </DropDownItem>
        </ul>
        <h2 className={`section__title title ${darkMode ? 'section__title titleDark' : ''}`} id='lgout' onClick={handleLogout}>
          <img src='/log out.svg' alt="log out" className={`logout__icon ${darkMode ? 'logout__iconDark' : ''}`} /> Logout
        </h2>
      </div>
    </div>
  );
};
const DropDownItem = ({ children }) => {
  return (
    <li>
      <div className={`section__settings ${darkMode ? 'section__settingsDark' : ''}`}>{children}</div>
      <hr className="line" />
    </li>
  );
};
export default ProfileDropDown;
