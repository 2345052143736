// valueReducer.js
const initialState = {
    value: false,
};
const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SAVE_VALUE':
            return {
                ...state,
                value: action.payload,
            };
        default:
            return state;
    }
};
export default filterReducer;
