import {
    CHECK_USER_IN_BID_FAIL,
    CHECK_USER_IN_BID_SUCCESS
} from "../constants/bidConstants";
const initialState = {
    inBid: [],
    error: null,
    loading: false,
};
export const CheckUserInBid = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_USER_IN_BID_SUCCESS:
            return {
                ...state,
                inBid: action.payload,
                error: null,
                loading: false,
            };
        case CHECK_USER_IN_BID_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
