import React, { useState, useEffect } from "react";
import "./Owner.scss";
import { useDispatch, useSelector } from "react-redux";
import { GetUser } from "../../../../store/actions/userActions";
import { GetUserSuccess } from "../../../../store/actions/userActions";
const Owner = ({ uid }) => {
    const dispatch = useDispatch();
    const [name, setName] = useState({})
    useEffect(() => {
        dispatch(GetUser(uid))
            .then((data) => {
                setName(data.data)
            })
            .catch((error) => {
                // console.log(error);
            });
    }, [dispatch]);
    
    return (
        <div className="owner">
            {name && <p className="ownerText">{name.user?.username}</p>}
        </div>
    )
}
export default Owner