import { GET_CURRENT_LOCATION_SUCCESS,GET_CURRENT_LOCATION_FAIL } from "../constants/userConstants";

const initialState = {
    loc: [],
    error: null,
    loading: false,
};
export const GetLoc = (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_LOCATION_SUCCESS:
            return {
                ...state,
                loc: action.payload,
                error: null,
                loading: false,
            };
        case GET_CURRENT_LOCATION_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
