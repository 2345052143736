import React from "react";
import styles from "./PostSuccess.module.scss";
import ReactDom from "react-dom";
import img from "../../../assests/svg/post success.svg";
import { useNavigate } from "react-router-dom";
const ContactUsMessageSuccess = ({ open, onClose }) => {
  const navigate = useNavigate();
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_styles}>
        <img src={img} alt="post sucess" className={styles.img} />
        <h1 className={styles.title}>Message Successfully Sent </h1>
        <button
          className={styles.done}
          onClick={onClose}
        >
          Done
        </button>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default ContactUsMessageSuccess;
