import React, { useState, useEffect } from "react";
import styles from "./DashboardUsers.module.scss";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import img from '../../assests/svg/back person.svg'
import { Table } from "react-bootstrap";
import Role from "./components/Role/Role";
import { Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { GetAllUsers } from "../../store/actions/AdminDashboard";
import IsAdmin from "./components/Role/IsAdmin";
import { ChangeRole } from "../../store/actions/AdminDashboard";
import ThreeDots from "./components/threeDots/threeDots";
const DashboardUsers = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial value
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const allUsers = useSelector((state) => state.AllUser?.AllUser?.data)
  useEffect(() => {
    dispatch(GetAllUsers());
  }, [dispatch]);
  const numberOfUsers = allUsers && allUsers.length
  return (
    <AdminLayout>
      <div className={styles.header}>
        <h3>Dashboard Users</h3>
      </div>
      {isSmallScreen ? (
        <>
          <div style={{ color: '#0298a6', fontWeight: '500', marginLeft: '10px' }}>Users Count: {numberOfUsers} </div>
          {allUsers && allUsers.map((user, index) => (
            <div className={styles.table}>
              <Table striped bordered hover style={{ width: '250px' }}>
                <tbody>
                  <React.Fragment key={index}>
                    <tr>
                      <td className={styles.customername}>ID:</td>
                      <td className={styles.customername}>{user._id}</td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Profile Picture:</td>
                      <td className={styles.customername}>
                        <div className={styles.uInfo}>
                          <img src={user.imageUrl} alt="Customer" className={styles.customerImage} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Username:</td>
                      <td className={styles.customername}>{user.username}</td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Email:</td>
                      <td className={styles.customername}>{user.email}</td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Role:</td>
                      <td><Role state={user.isAdmin} /></td>
                    </tr>
                    {/* <tr>
                      <td className={styles.customername}>Is Admin:</td>
                      <td><IsAdmin state={user && user.isAdmin} /></td>
                    </tr> */}
                    <tr>
                      <td className={styles.customername}>Created Date:</td>
                      <td className={styles.customername}>{user.createdAt}</td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Change Role:</td>
                      <td><ThreeDots id={user._id} /></td>
                    </tr>
                  </React.Fragment>
                </tbody>
              </Table>
            </div>
          ))}
        </>
      ) : (
        <div className={styles.table}>
          <div style={{ color: '#0298a6', fontWeight: '500', marginLeft: '10px' }}>Users Count: {numberOfUsers} </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Profile Picture</th>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                {/* <th>Is Admin</th> */}
                <th>Created Date</th>
                <th>Change Role</th>
              </tr>
            </thead>
            <tbody>
              {allUsers && allUsers.map((user, index) => (
                <tr key={index}>
                  <td className={styles.customername}>{user._id}</td>
                  <td className={styles.customername}>
                    <div className={styles.uInfo}>
                      <img src={user.imageUrl} alt="Customer" className={styles.customerImage} />
                    </div>
                  </td>
                  <td className={styles.email}>
                    <div className={styles.email}>
                      {user.username}
                    </div>
                  </td>
                  <td className={styles.customername}>
                    {user.email}
                  </td>
                  <td><Role state={user.isAdmin} /></td>
                  {/* <td><IsAdmin state={user && user.isAdmin} /></td> */}
                  <td className={styles.customername}>{user.createdAt.slice(0, 10)}</td>
                  <td><ThreeDots id={user._id} /></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </AdminLayout>
  )
}
export default DashboardUsers;
