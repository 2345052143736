import React, { useEffect, useState } from "react";
import styles from "./WaitingListPage.module.scss";
import Layout from "../../common/components/Layouts/Layout";
import Request from "./Request/Request";
import RequestDone from "./Request/RequestDone";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getWaitingListHandler,
  ifanyuseracceptedHandler,
} from "../../store/actions/waitingListActions";
import Loader from "../../common/components/Loader/Loader";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import {
  ACCEPT_WAITINGLIST_RESET,
  GET_WAITINGLIST_RESET,
} from "../../store/constants/waitingListConstants";
import RequestAcceptedPage from "./Request Accepted/RequestAcceptedPage";
import { GetProductOffer } from "../../store/actions/bidActions";
import { timePosted } from "../../common/Utils";
import RequestRejected from "./RequestRejected/RequestRejected";
import RequestSold from "./Request Sold/RequestSold";
import Img from "../../assests/svg/order history empty.svg";
import { useParams } from "react-router-dom";
const WaitingListPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  useEffect(() => {
    const pageName = "Waiting-list"; // Replace this with the actual page name
    const pageUrl = `/user/my-offers/waiting-list/${params.id}`; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const navigate = useNavigate();
  const getWaitingList = useSelector((state) => state.getWaitingList);
  const { waitinglist, error, loading } = getWaitingList;
  const [waitingUser, setWaitingUser] = useState([]);
  const ifanyuseraccepted = useSelector((state) => state.ifanyuseraccepted);
  const { user } = ifanyuseraccepted;
  const waitingList = useSelector((state) => state.waitingList?.waitingList?.data)
  async function getProductOffer(id) {
    try {
      const response = await dispatch(GetProductOffer(id));
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    getProductOffer(params.id);
  }, [dispatch, params.id]);
  return (
    <Layout title={"Waiting List"}>
      <div className={styles.container}>
        <div className={`${styles.wrapper} ${darkMode ? styles.wrapperDark : ''}`}>
          <ul>
            {loading ? (
              <Loader />
            ) : error ? (
              <ErrorMessage>{error}</ErrorMessage>
            ) : user ? (
              <RequestAcceptedPage
                userId={user.id}
                productId={params.id}
              />
            ) : (
              waitingList && waitingList.length === 0 ? (
                <div className={styles.empty}>
                  <img src={Img} alt='empty' />
                  <h2 className={`${styles.txt} ${darkMode ? styles.txtDark : ''}`}>No one is in your waiting list.</h2>
                  <button className={`${styles.btn} ${darkMode ? styles.btnDark : ''}`} onClick={() => navigate("/")}>
                    Go Shopping
                  </button>
                </div>
              ) : (
                waitingList && waitingList.map((w) => {
                  if (w.status === "accepted") {
                    return (
                      <RequestDone
                        key={w._id}
                        offerId={w._id}
                        sure={w.comingForSure}
                        image={w.user?.imageUrl}
                        username={w.user?.username}
                        userId={w.user?._id}
                        productId={w.product?._id}
                        time={timePosted(w.updatedAt)}
                        price={w.product?.pricing?.price}
                        coordinatesBuyer={w.buyerLastKnown}
                        coordinatesSeller={w.sellerLastKnown}
                        user={w.user}
                      />
                    );
                  } else if (w.status === "waiting") {
                    return (
                      <Request
                        key={w._id}
                        offerId={w._id}
                        sure={w.comingForSure}
                        image={w.user?.imageUrl}
                        username={w.user?.username}
                        userId={w.user?._id}
                        productId={w.product?._id}
                        time={timePosted(w.updatedAt)}
                      />
                    );
                  } else if (w.status === "rejected") {
                    return (
                      <RequestRejected
                        key={w._id}
                        offerId={w._id}
                        sure={w.comingForSure}
                        image={w.user?.imageUrl}
                        username={w.user?.username}
                        userId={w.user?._id}
                        productId={w.product?._id}
                        time={timePosted(w.updatedAt)}
                      />
                    );
                  } else if (w.status === "sold") {
                    return (
                      <RequestSold
                        key={w._id}
                        offerId={w._id}
                        sure={w.comingForSure}
                        image={w.user?.imageUrl}
                        username={w.user?.username}
                        userId={w.user?._id}
                        productId={w.product?._id}
                        time={timePosted(w.updatedAt)}
                      />
                    );
                  }
                  return (
                    <div>
                      Nothing
                    </div>
                  );
                })
              )
            )}
          </ul>
        </div>
      </div>
    </Layout>
  );
}
export default WaitingListPage;
