import React, { useState, useEffect, useRef } from "react";
import Layout from "../../common/components/Layouts/Layout";
import ProfilePicture from "./components/ProfilePicture/ProfilePicture";
import ReviewsCard from "./components/ReviewsCard/ReviewsCard";
import RatingCard from "./components/RatingCard/RatingCard";
import ProductCard from "./components/ProductCard/ProductCard";
import styles from "./UserPage.module.scss";
import { Pagination } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { CgSortAz } from "react-icons/cg";
import { BsFillChatRightDotsFill } from "react-icons/bs";
import { AiOutlineLink, AiOutlineMore } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";
import ReviewModal from "./components/Review Modal/ReviewModal";
import { useDispatch, useSelector } from "react-redux";
import {
  FollowUser,
  GetUserFollowing,
  PostBlock,
  UnfollowUser,
  getUser,
  PostUnBlock,
} from "../../store/actions/userActions";
import Modal from "../../common/components/Modal/Modal";
import { GET_USER_RESET } from "../../store/constants/userConstants";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import Loader from "../../common/components/Loader/Loader";
import { format, parseISO } from "date-fns";
import {
  GetSingleProduct,
  GetSingleProductSuccess,
  listUserProducts,
} from "../../store/actions/myProductsActions";
import { GetUser } from "../../store/actions/userActions";
import { url } from "../../common/constants/url";
import { GetProductPerUser } from "../../store/actions/userActions";
import { GetReviewsByUID } from "../../store/actions/userActions";
import ReportModal from "../ChatPage/chats/chatHeader/reportModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetAverageReview } from "../../store/actions/userActions";
import { IsBlocked } from "../../store/actions/userActions";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ConfirmModal from "../../common/components/ConfirmModal/ConfirmModal";
import { GetProductPerUserId } from "../../store/actions/userActions";
const UserPage = () => {
  const { UserId, name } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const pageName = "User Profile"; // Replace this with the actual page name
    const pageUrl = `/user-review/${name}/${UserId}`; // Replace this with the actual page URL
    dispatch({
      type: "ADD_BREADCRUMB",
      payload: { name: pageName, url: pageUrl },
    });
  }, []);
  const containerRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activePage, setActivePage] = useState(1);
  const [product, setProduct] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [report, setReport] = useState(false);
  const id = UserId || "";
  const average = useSelector((state) => state.AverageReview?.AverageReview);
  const uid = localStorage.getItem("uid");
  const rawData = {
    userIdToBlock: UserId,
  };
  const data = {
    userIdToRemove: UserId,
  };
  useEffect(() => {
    const response = dispatch(GetAverageReview(id));
  }, [dispatch, id]);
  const openReport = () => {
    if (loggedInUser) {
      setReport(true);
    } else {
      toast.error("Please login first.");
      setIsOpen(true);
    }
  };
  const { user } = useSelector((state) => state.userLogin);
  const isUserBlocked = useSelector(
    (state) => state.isUserBlocked?.isUserBlocked?.blocked
  );
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);
  const user1 = useSelector((state) => state.user);
  const { userDetails, error, loading } = user1;
  const reviewsByUID = useSelector(
    (state) => state.reviewsByUID?.reviewsByUID?.data?.reviews
  );
  const userProducts = useSelector((state) => state.userProducts);
  const products = userProducts?.products || [];
  const errorProducts = userProducts?.error;
  const loadingProducts = userProducts?.loading;
  const [following, setFollowing] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [test1, setTest1] = useState(false);
  const prodUser = useSelector(
    (state) => state.getUserById?.getUserById?.data?.user
  );
  const handleViewAllClick = () => {
    setShowAll(!showAll);
  };
  useEffect(() => {
    const response = dispatch(IsBlocked(rawData));
    dispatch(GetUserFollowing())
      .then((response) => {
        setFollowing(response);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setTest1(true);
        const response = await dispatch(GetUser(UserId));
        setTest1(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [dispatch, UserId]);
  const [reviews, setReviews] = useState([]);
  const AddReview = () => {
    if (loggedInUser) {
      setAddReview(true);
    } else {
      toast.error("Please login first.");
      setIsOpen(true);
    }
  };
  useEffect(() => {
    dispatch(GetReviewsByUID(UserId))
      .then((response) => {
        setReviews(response.data?.reviews);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch, UserId]);
  const params = useParams();
  const ProductPerUser = useSelector(
    (state) => state.ProductPerUser?.ProductPerUser?.data?.products
  );
  const test = useSelector(
    (state) => state.ProductPerUser?.ProductPerUser?.data
  );
  const totalProducts = useSelector(
    (state) => state.productPerUserId?.productPerUserId?.data?.total
  );
  const pageSize = useSelector(
    (state) => state.productPerUserId?.productPerUserId?.data?.pageSize
  );
  const totalPages = Math.ceil(totalProducts / pageSize);
  const [prodLoading, setProdLoading] = useState(false);
  const productPerUserId = useSelector(
    (state) => state.productPerUserId?.productPerUserId?.data?.products
  );
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        setProdLoading(true);
        const parms = {
          uid: params.UserId,
          page: activePage,
          pageSize: 6,
        };
        const response = await dispatch(GetProductPerUserId(parms));
        if (response.data && response.data.products) {
          setProdLoading(false);
          setProduct(response.data.products);
        } else {
          setProdLoading(false);
        }
      } catch (error) {
        setProdLoading(false);
      }
    };

    fetchProductData();
  }, [dispatch, params.UserId]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const parms = { uid: UserId, page: pageNumber, pageSize: 6 };
    setTest1(true);
    dispatch(GetProductPerUserId(parms))
      .then((response) => {
        setProduct(response.data?.products);
      })
      .catch((error) => {
        // console.log(error);
      });
    setTest1(false);
  };

  const [more, setMore] = useState(false);
  const [addReview, setAddReview] = useState(false);
  const handleMore = () => {
    setMore((prevState) => !prevState);
  };
  const isFollowing = following && !!following.find((user) => user._id === id);
  const handleFollow = async () => {
    if (loggedInUser) {
      if (isFollowing) {
        const formData = new FormData();
        formData.append("fid", id);
        dispatch(UnfollowUser(formData)).then(() => {
          dispatch(GetUser(UserId));
          dispatch(GetProductPerUser(UserId))
            .then((response) => {
              setProduct(response.data?.products);
            })
            .catch((error) => {
              // console.log(error);
            });
          setFollowing((prevFollowing) =>
            prevFollowing.filter((user) => user._id !== id)
          );
        });
      } else {
        const formData = new FormData();
        formData.append("fid", id);
        dispatch(FollowUser(formData)).then(() => {
          dispatch(GetUser(UserId));
          dispatch(GetProductPerUser(UserId))
            .then((response) => {
              setProduct(response.data?.products);
            })
            .catch((error) => {
              // console.log(error);
            });
          const alreadyFollowing = following.some((user) => user._id === id);
          if (!alreadyFollowing) {
            setFollowing((prevFollowing) => [...prevFollowing, { _id: id }]);
          }
        });
      }
    } else {
      toast.error("Please login to follow this user.");
      setIsOpen(true);
    }
  };
  const handleBlockUser = async (e) => {
    e.preventDefault();
    if (loggedInUser) {
      if (isUserBlocked) {
        try {
          const response = await dispatch(PostUnBlock(data));
          if (response) {
            setMore((prevState) => !prevState)
            toast.success("User unblocked successfully.");
          }
        } catch (error) {
          // console.log("blk", error);
        }
        dispatch(IsBlocked(rawData));
      } else {
        try {
          const response = await dispatch(PostBlock(rawData));
          if (response) {
            setMore((prevState) => !prevState)
            dispatch(GetUser(UserId));
            toast.success("User blocked successfully.");
            const formData = new FormData();
            formData.append("fid", id);
            dispatch(UnfollowUser(formData)).then(() => {
              dispatch(GetProductPerUser(UserId))
                .then((response) => {
                  setProduct(response.data?.products);
                })
                .catch((error) => {
                  // console.log(error);
                });
              setFollowing((prevFollowing) =>
                prevFollowing.filter((user) => user._id !== id)
              );
            });
          }
        } catch (error) {
          // console.log("blk", error);
        }
        dispatch(IsBlocked(rawData));
      }
    } else {
      toast.error("Please login first.");
      setIsOpen(true);
    }
  };
  const handleCopyLink = () => {
    const currentUrl = `https://share.la7i27alak.com/share/profile/${prodUser.username.replace(
      " ",
      "-"
    )}/${id}`;
    const input = document.createElement("input");
    input.setAttribute("value", currentUrl);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    toast.success("Copied");
  };
  return (
    <>
      {/* <Helmet>
        <title>Seller Page</title>
        <meta name="Seller Page" content="Seller Page" />
        <meta
          name="keywords"
          content={`user, seller, buyer, la7i27alak`}
        />
      </Helmet> */}
      <ReportModal
        open={report}
        onClose={() => {
          setReport(false);
        }}
        selectedUser={prodUser && prodUser._id}
      />
      <Modal open={isOpen} closeHandler={() => setIsOpen(false)} />
      <Layout title={"User Review"}>
        {error ? (
          <div className="center">
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        ) : loading ? (
          <div className="center">
            <Loader />
          </div>
        ) : (
          prodUser && (
            <div className={styles.wrapper}>
              <aside className={styles.aside}>
                <ProfilePicture
                  image={prodUser.imageUrl}
                  id={UserId || ""}
                  blocked={isUserBlocked}
                />
                <RatingCard
                  reviews={reviews}
                  averageStar={prodUser && prodUser.reviews?.average}
                  id={UserId || ""}
                />
                <div className={styles.btn_container}>
                  <btn className={styles.share} onClick={handleCopyLink}>
                    Share Profile
                  </btn>
                  {UserId !== uid && (
                    <>
                      <button
                        onClick={() => setBlockModal(true)}
                        className={styles.block}
                      >
                        {isUserBlocked ? "Unblock User" : "Block User"}
                      </button>
                      <button onClick={openReport} className={styles.report}>
                        Report User
                      </button>
                    </>
                  )}
                  <div className={styles.shareCont}></div>
                </div>
              </aside>
              <div className={styles.main_section}>
                {/* beginning mobile */}
                <div className={styles.mobile_section}>
                  <div className={styles.more}>
                    <AiOutlineMore
                      className={`${styles.more_icon} ${
                        darkMode ? styles.more_iconDark : ""
                      }`}
                      onClick={handleMore}
                    />
                    <ul
                      className={`${styles.menu} ${
                        more ? styles.active : styles.inactive
                      }`}
                    >
                      <li>
                        <button
                          onClick={handleCopyLink}
                          className={styles.report_mobile}
                        >
                          Share Profile
                        </button>
                      </li>
                      {id !== uid && (
                        <>
                          <li>
                            <button
                              onClick={() => setBlockModal(true)}
                              className={styles.block_mobile}
                            >
                              {isUserBlocked ? "Unblock User" : "Block User"}
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={openReport}
                              className={styles.report_mobile}
                            >
                              Report User
                            </button>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                  <div className={styles.image_container}>
                    <img
                      src={prodUser.imageUrl}
                      alt=""
                      className={styles.image_mobile}
                    />
                    <div>
                      <div
                        className={`${styles.account_info} ${
                          darkMode ? styles.account_infoDark : ""
                        }`}
                      >
                        <div>
                          <p>
                            {totalProducts && totalProducts}{" "}
                            <span
                              className={`${styles.span} ${
                                darkMode ? styles.spanDark : ""
                              }`}
                            >
                              Posts
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            {prodUser &&
                              prodUser.followers &&
                              prodUser.followers.length}{" "}
                            <span
                              className={`${styles.span} ${
                                darkMode ? styles.spanDark : ""
                              }`}
                            >
                              Followers
                            </span>
                          </p>
                        </div>
                        <div>
                          <p>
                            {prodUser &&
                              prodUser.following &&
                              prodUser.following.length}{" "}
                            <span
                              className={`${styles.span} ${
                                darkMode ? styles.spanDark : ""
                              }`}
                            >
                              Followings
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.flex}>
                    <div>
                      <div className={styles.name_container_mobile}>
                        <h3
                          className={`${styles.name} ${
                            darkMode ? styles.nameDark : ""
                          }`}
                        >
                          {prodUser.username}
                        </h3>
                        <p
                          className={`${styles.rating} ${
                            darkMode ? styles.ratingDark : ""
                          }`}
                        >
                          {average && average.totalStarAverage ? (
                            <>
                              {prodUser && prodUser.reviews?.average}
                              <FaStar className={styles.rating_icon} />
                            </>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                      <p
                        className={`${styles.member_text_mobile} ${
                          darkMode ? styles.member_text_mobileDark : ""
                        }`}
                      >
                        Member since{" "}
                        {prodUser.createdAt
                          ? format(
                              parseISO(prodUser.createdAt, new Date()),
                              "MMMM yyyy"
                            )
                          : "N/A"}
                      </p>
                    </div>
                    {isUserBlocked
                      ? null
                      : id !== uid && (
                          <button
                            className={`${styles.follow_mobile} ${
                              darkMode ? styles.follow_mobileDark : ""
                            } ${isFollowing ? styles.btnUnflw : ""}`}
                            onClick={handleFollow}
                          >
                            {isFollowing ? "Unfollow" : "Follow"}
                          </button>
                        )}
                  </div>
                </div>
                {/* end mobile */}
                <div className={styles.user_info_container}>
                  <div>
                    <div className={styles.name_container}>
                      <h3
                        className={`${styles.name} ${
                          darkMode ? styles.nameDark : ""
                        }`}
                      >
                        {prodUser.username}
                      </h3>
                      <p
                        className={`${styles.rating} ${
                          darkMode ? styles.ratingDark : ""
                        }`}
                      >
                        {prodUser && prodUser.reviews?.average}
                        <FaStar className={styles.rating_icon} />
                      </p>
                    </div>
                    <p
                      className={`${styles.member_text} ${
                        darkMode ? styles.member_textDark : ""
                      }`}
                    >
                      Member since{" "}
                      {prodUser.createdAt
                        ? format(
                            parseISO(prodUser.createdAt, new Date()),
                            "MMMM yyyy"
                          )
                        : "N/A"}
                    </p>
                  </div>
                  <div
                    className={`${styles.account_info} ${
                      darkMode ? styles.account_infoDark : ""
                    }`}
                  >
                    <div>
                      <p>
                        {totalProducts && totalProducts}{" "}
                        <span
                          className={`${styles.span} ${
                            darkMode ? styles.spanDark : ""
                          }`}
                        >
                          Posts
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        {prodUser &&
                          prodUser.followers &&
                          prodUser.followers.length}
                        <span
                          className={`${styles.span} ${
                            darkMode ? styles.spanDark : ""
                          }`}
                        >
                          Followers
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        {prodUser &&
                          prodUser.following &&
                          prodUser.following.length}
                        <span
                          className={`${styles.span} ${
                            darkMode ? styles.spanDark : ""
                          }`}
                        >
                          Followings
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.line} ${
                    darkMode ? styles.lineDark : ""
                  }`}
                ></div>
                <div>
                  <div className={styles.container_header}>
                    <h3
                      className={`${styles.title} ${
                        darkMode ? styles.titleDark : ""
                      }`}
                    >
                      Published Products
                    </h3>
                    {/* <button
                    className={styles.see_all}
                    onClick={() => navigate("/user-review/products")}
                  >
                    See all
                  </button> */}
                  </div>
                  {prodLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader />
                    </div>
                  ) : (
                    <div className={styles.products_container}>
                      {productPerUserId &&
                        productPerUserId.map((p) => (
                          <ProductCard
                            id={p._id}
                            image={p.imageUrls[0]}
                            featured={p.featured}
                            title={p.title}
                            price={p.pricing?.price}
                            time={p.createdAt}
                            old={p.condition}
                            location={p.pickUpAddress?.region}
                            isService={p.isService}
                          />
                        ))}
                    </div>
                  )}
                  {totalProducts > 0 && (
                    <Pagination>
                      {activePage === 1 ? null : (
                        <Pagination.Prev
                          onClick={() => handlePageChange(activePage - 1)}
                          disabled={activePage === 1}
                        />
                      )}
                      {totalPages === 1 ? (
                        <Pagination.Item
                          linkStyle={{ background: "#0298a6", border: "none" }}
                          active
                        >
                          {activePage}
                        </Pagination.Item>
                      ) : (
                        <>
                          {activePage === 1 ? null : (
                            <Pagination.Item
                              onClick={() => handlePageChange(activePage - 1)}
                              active={activePage === 1}
                            >
                              {activePage - 1}
                            </Pagination.Item>
                          )}
                          <Pagination.Item
                            linkStyle={{
                              background: "#0298a6",
                              border: "none",
                            }}
                            active
                          >
                            {activePage}
                          </Pagination.Item>
                          {activePage === totalPages ? null : (
                            <Pagination.Item
                              onClick={() => handlePageChange(activePage + 1)}
                              active={activePage === totalPages}
                            >
                              {activePage === totalPages
                                ? activePage
                                : activePage + 1}
                            </Pagination.Item>
                          )}
                        </>
                      )}
                      {activePage === totalPages ? null : (
                        <Pagination.Next
                          onClick={() => handlePageChange(activePage + 1)}
                          disabled={activePage === totalPages}
                        />
                      )}
                    </Pagination>
                  )}
                </div>
                <div className={styles.line}></div>
                <div>
                  <div className={styles.container_header}>
                    <h3
                      className={`${styles.title} ${
                        darkMode ? styles.titleDark : ""
                      }`}
                    >
                      Reviews
                    </h3>
                    {prodUser && prodUser._id !== uid && (
                      <div className={styles.reviewsort}>
                        <button
                          className={`${styles.see_all} ${
                            darkMode ? styles.see_allDark : ""
                          }`}
                          onClick={() => AddReview()}
                        >
                          <BsFillChatRightDotsFill className={styles.revic} />
                          Add Review
                        </button>
                      </div>
                    )}
                  </div>
                  {reviewsByUID &&
                    reviewsByUID.map((review) => (
                      <div key={review.id} className={styles.review}>
                        <ReviewsCard
                          from={review.from}
                          id={review._id}
                          time={review.createdAt}
                          text={review.comment}
                          star={review.rating}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )
        )}
        <ReviewModal
          open={addReview}
          setOpen={() => setAddReview(false)}
          name={prodUser?.username}
          image={prodUser?.imageUrl}
          id={prodUser?._id}
          time={prodUser?.createdAt}
          star={prodUser?.reviews?.average}
          ID={UserId || ""}
        />
      </Layout>
      <ConfirmModal isOpen={blockModal} onClose={() => setBlockModal(false)}>
        <h1
          className={`${styles.confirm_title} ${
            darkMode ? styles.confirm_titleDark : ""
          }`}
        >
          Confirmation
        </h1>
        <p
          className={`${styles.confirm_message} ${
            darkMode ? styles.confirm_messageDark : ""
          }`}
        >
          Are you sure you want to{" "}
          <span>{isUserBlocked ? "Unblock" : "Block"}</span> this user?
        </p>
        <div className={styles.buttons_container}>
          <button
            className={styles.cancel}
            onClick={(e) => {
              handleBlockUser(e);

              setBlockModal(false);
            }}
          >
            {isUserBlocked ? "Unblock User" : "Block User"}
          </button>
          <button
            className={styles.confirm}
            onClick={() => setBlockModal(false)}
          >
            Cancel
          </button>
        </div>
      </ConfirmModal>
    </>
  );
};
export default UserPage;
