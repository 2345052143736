import React from "react";
import "./Action.scss";
import { ConfirmProduct, ConfirmService } from "../../../../store/actions/ProductStateAction";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPendingProduct,
  GetConfirmedProduct,
  GetPendingService,
  GetConfirmedService
} from "../../../../store/actions/AdminDashboard";
const Action = ({ action, productId, isService }) => {
  const dispatch = useDispatch();
  const handleStatusUpdate = async (productId) => {
    try {
      if (isService) {
        const updatedProduct = await dispatch(ConfirmService(productId))
        if (updatedProduct) {
          dispatch(GetPendingService())
          dispatch(GetConfirmedService())
        }
      } else {
        const updatedProduct = await dispatch(ConfirmProduct(productId))
        if (updatedProduct) {
          dispatch(GetPendingProduct())
          dispatch(GetConfirmedProduct())
        }
      }
    } catch (error) {
      // console.log(error)
    };
  }
  const renderButton = () => {
    if (action === 'a') {
      return <button className="accBut" onClick={() => handleStatusUpdate(productId)}>Accept</button>;
    } else if (action === 'r') {
      return <button className="resBut">Restore</button>;
    } else {
      return null;
    }
  };
  return (
    <div className="action">
      {renderButton()}
    </div>
  )
}
export default Action