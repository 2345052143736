import * as Yup from "yup";
export const VehiclesValidationSchema = Yup.object({
  title: Yup.string()
    .required("Product title is required")
    .max(20, "Must be 20 character or less")
    .min(6, "Must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .max(60, "Must be 60 character or less")
    .min(20, "Must be 20 character or more"),
  VehicleBrand: Yup.string(),
  BrandModule: Yup.string(),
  BodyType: Yup.string(),
  YearofManufacturing: Yup.string(),
  Vehiclecolor: Yup.string(),
  TransmissionType: Yup.string(),
  Deposit: Yup.string().required(),
  Driver: Yup.string().required(),
  Insurance: Yup.string().required(),
});
export const FurnitureValidationSchema = Yup.object({
  title: Yup.string()
    .required("Product title is required")
    .max(20, "Must be 20 character or less")
    .min(6, "Must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .max(60, "Must be 60 character or less")
    .min(20, "Must be 20 character or more"),
  LivingRoomType: Yup.string(),
  BedroomType: Yup.string(),
  DiningRoomType: Yup.string(),
  KitchenAndKitchenwareType: Yup.string(),
  BathroomType: Yup.string(),
  HomeDecorationAndAccessoriesType: Yup.string(),
  GardenAndOutdooType: Yup.string().required(),
});
export const FashionAndBeatyValidationSchema = Yup.object({
  title: Yup.string()
    .required("Product title is required")
    .max(20, "Must be 20 character or less")
    .min(6, "Must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .max(60, "Must be 60 character or less")
    .min(20, "Must be 20 character or more"),
  ClothingforMenType: Yup.string().required(),
  AccessoriesforMenType: Yup.string(),
  ClothingforWomenType: Yup.string(),
  AccessoriesforWomenType: Yup.string(),
  MakeupAndCosmeticsType: Yup.string(),
  JewelryAndFauxBijoux: Yup.string().required(),
  WatchesGender: Yup.string().required(),
});
export const PhonesAndTabletsValidationSchema = Yup.object({
  title: Yup.string()
    .required("Product title is required")
    .max(20, "Must be 20 character or less")
    .min(6, "Must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .max(60, "Must be 60 character or less")
    .min(20, "Must be 20 character or more"),
  PhonesAndTabletsBrand: Yup.string(),
  PhonesAccessoriesType: Yup.string(),
  MobileNumbersOperator: Yup.string(),
  SmartWatchesBrand: Yup.string(),
});
export const ElectronicsValidationSchema = Yup.object({
  title: Yup.string()
    .required("Product title is required")
    .max(20, "Must be 20 character or less")
    .min(6, "Must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .max(60, "Must be 60 character or less")
    .min(20, "Must be 20 character or more"),
  TVAndVideoBrand: Yup.string(),
  TVAndVideoBrandType: Yup.string(),
  HomeAudioAndSpeakersBrand: Yup.string(),
  KitchenEquipmentAndAppliancesType: Yup.string(),
  ACCoolingAndHeatingType: Yup.string(),
  CleaningAppliancesType: Yup.string(),
  LaptopsAndComputersBrand: Yup.string(),
  ComputerPartsAndITAccessoriesType: Yup.string(),
  CamerasType: Yup.string(),
  VideoGamesAndConsolesType: Yup.string(),
});
export const SportsAndEntertainmentValidationSchema = Yup.object({
  title: Yup.string()
    .required("Product title is required")
    .max(20, "Must be 20 character or less")
    .min(6, "Must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .max(60, "Must be 60 character or less")
    .min(20, "Must be 20 character or more"),
  BicyclesAndAccessoriesType: Yup.string(),
  OutdoorsAndCampingType: Yup.string(),
  GymFitnessAndFightingType: Yup.string(),
  BallSportsType: Yup.string(),
  BilliardAndSimilarGamesType: Yup.string(),
  WinterSportsType: Yup.string(),
  WaterSportsType: Yup.string(),
  TennisAndRacketSportsType: Yup.string(),
});
export const PetsValidationSchema = Yup.object({
  title: Yup.string()
    .required("Product title is required")
    .max(20, "Must be 20 character or less")
    .min(6, "Must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .max(60, "Must be 60 character or less")
    .min(20, "Must be 20 character or more"),
  HousePetsAnimalType: Yup.string(),
  LivestockAnimalType: Yup.string(),
  PetGender: Yup.string(),
  PetVaccinated: Yup.string(),
  PetAccessoriesAnimalType: Yup.string(),
});
export const BabiesAndKidsValidationSchema = Yup.object({
  title: Yup.string()
    .required("Product title is required")
    .max(20, "Must be 20 character or less")
    .min(6, "Must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .max(60, "Must be 60 character or less")
    .min(20, "Must be 20 character or more"),
  BabiesAndKidsAgeRange: Yup.string(),
  StrollersAndSeatsItemType: Yup.string(),
  BabiesAndKidsGender: Yup.string(),
  CribsAndBedroomFurnitureItemType: Yup.string(),
  FeedingAndNursingItemType: Yup.string(),
});
export const MusicArtBooksValidationSchema = Yup.object({
  title: Yup.string()
    .required("Product title is required")
    .max(20, "Must be 20 character or less")
    .min(6, "Must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .max(60, "Must be 60 character or less")
    .min(20, "Must be 20 character or more"),
  AntiquesAndCollectiblesType: Yup.string(),
  MusicalInstrumentsType: Yup.string(),
  BooksType: Yup.string(),
  BooksLanguage: Yup.string(),
});
export const PropertiesValidationSchema = Yup.object({
  title: Yup.string()
    .required("Product title is required")
    .max(60, "Must be 60 character or less")
    .min(6, "Must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  dynamicFields: Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .min(2, 'Title must be at least 2 characters'),
  }),
  vehicleBrand: Yup.string().required("Vehicle Brand is Required"),
  description: Yup.string()
    .required("Description is required")
    .max(1000, "Must be 1000 character or less")
    .min(20, "Must be 20 character or more"),
  PropertyType: Yup.string(),
  PaymentMethod: Yup.string(),
  Bedrooms: Yup.number(),
  Bathrooms: Yup.number(),
  Size: Yup.number(),
  Furnisher: Yup.string(),
  ConditionProperty: Yup.string(),
  Floor: Yup.number(),
  RentalDuration: Yup.string(),
  CommercialType: Yup.string(),
  Equipped: Yup.string(),
  Landtype: Yup.string(),
  ChaletType: Yup.string(),
  BuildingType: Yup.string(),
  LivingRoomType: Yup.string(),
  BedroomType: Yup.string(),
  DiningRoomType: Yup.string(),
  KitchenAndKitchenwareType: Yup.string(),
  BathroomType: Yup.string(),
  HomeDecorationAndAccessoriesType: Yup.string(),
  GardenAndOutdooType: Yup.string(),
});
export const OfferServieValidationSchema = Yup.object({
  title: Yup.string()
    .required("Title is required")
    .max(60, "Title must be 60 character or less")
    .min(6, "Title must be 6 character or more"),
  price: Yup.number().required("Price is required"),
  description: Yup.string()
    .required("Description is required")
    .max(1000, "Description must be 1000 character or less")
    .min(20, "Description must be 20 character or more")
});