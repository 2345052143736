import React, { useState,useEffect } from "react";
import ReactDom from "react-dom";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import { ReactComponent as Back } from "../../../../assests/svg/back.svg";
import { ReactComponent as BackDark } from "../../../../assests/svg/arrowleftDark.svg";
import { ReactComponent as Phone } from "../../../../assests/svg/gray phone.svg";
import styles from "./styles.module.scss";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./styles.scss";
import { auth } from "../../../../firebase";
import { PhoneAuthProvider, RecaptchaVerifier, linkWithCredential, signInWithPhoneNumber } from "firebase/auth";
import axios from "axios";
import { loginGoogle } from "../../../../store/actions/authActions";
import { useDispatch ,useSelector} from "react-redux";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import SellModal from "../../SellModal/SellModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../../../assests/svg/La7i2_7alak1.1.svg'
import { set } from "date-fns";
const LoginWithCodeModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [sucmsg, setSucmsg] = useState('')
  const [ermsg, setErmsg] = useState('')
  const [errmsg, setErrmsg] = useState('')
  const [value, setValue] = useState();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [confirmation, setConfirmation] = useState(null);
  const [service, setService] = useState(false);
  const [sell, setSell] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  //changed
  const [clicked, setClicked] = useState(false);
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => { },
        },
        auth
      );
    }
  }
  const userid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`https://backend.la7i27alak.com/user/${userid}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setPhone(response.data.user.phone)
      })
      .catch((error) => console.error(error));
  }, [userid, token]);
  const baseUrl = 'https://backend.la7i27alak.com'
  const [isResending, setIsResending] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [isVerificationSent, setIsVerificationSent] = useState(false); // New state variable

  useEffect(() => {
    let countdownInterval;

    if (isResending && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setIsResending(false);
      setCountdown(30);
      clearInterval(countdownInterval);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, [isResending, countdown]);

  function onSignup() {
    setClicked(true)
    onCaptchVerify();
    setLoading1(true)
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        setConfirmation(confirmationResult);
        window.confirmationResult = confirmationResult;
        toast.success(`Verification code successfully sent to ${phone}.`)
        setIsVerificationSent(true)
        setIsResending(true)
      })
      .catch((error) => {
        // console.log(error);
        if (error.code === 'auth/invalid-phone-number') {
          // Invalid phone number
          toast.error('Invalid phone number. Please provide a valid phone number.', 'error');
        } else if (error.code === 'auth/too-many-requests') {
          // Too many verification requests
          toast.error('Too many verification requests. Please try again later.', 'error');
        } else if (error.code === 'auth/quota-exceeded') {
          // SMS quota exceeded
          toast.error('SMS quota exceeded. Please try again later.', 'error');
        }     
       });
  }
   async function onOTPVerify() {
    if (!otp) {
      // Handle case when OTP is not entered
      setErmsg('Please enter the verification code');
      return;
    }
  
    if (confirmation) {
      setLoading(true)
      try {
        const credential = PhoneAuthProvider.credential(
          confirmation.verificationId,
          otp,
        );
        // Now, we link the user with the phone credential
        try {
          const usercred = await linkWithCredential(auth.currentUser, credential);
          const user = usercred.user;
          setSell(true);
          // onClose();
          setLoading(false)
          setOtp('');
        } catch (error) {
          if (error.code === 'auth/account-exists-with-different-credential') {
            toast.error("This phone number is linked to another email. Please use the appropriate login method.");
            setLoading(false)
          } else {
            toast.error('Incorrect verification code. Please enter the correct verification code.');
            setLoading(false)
          }
        }
      } catch (error) {
        setErmsg('Error occurred during account linking, please try again.');
        setLoading(false)
      }
    } 
  }
  
  useEffect(() => {
    setLoading1(true)
    if (open) {
      onSignup(); // Call onSignup function when the modal opens
    }
    setLoading1(false)
  }, [open]);
  const handlepassInputFocus = () => {
    setSucmsg('')
    setErmsg("");
  };
  //
  if (!open) return null;
  return ReactDom.createPortal(
    <>
    <SellModal
        open={sell}
        onClose={() => {
          onClose();
          setSell(false);
          setService(false);
        }}
        service={service}
        setService={() => setService(true)}
      />
      
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`} >    
    <div className={styles.container}>
      
    {darkMode ? (
            <CloseDark className={styles.close} onClick={onClose} />
          ) :
            (<Close className={styles.close} onClick={onClose} />)}
          
          <div className={styles.logoCont}>
      <img src={logo} alt='logo' className={styles.logo}/>
      </div>
      <div className={styles.form_container}>
        <label htmlFor="phone" className={`${styles.label} ${darkMode? styles.labelDark:''}`}>
          Verify Your Number
        </label>
        <div className={styles.phone_input_container}>
          <div className="big--phone">
          <PhoneInput
                  defaultCountry="LB"
                  placeholder="Phone number"
                  value={phone}
                  // disabled={true}
                  autoFocus
                  readOnly 
                  className={`${styles.phonei} ${darkMode?styles.phoneiDark:''}`}
                 />
          </div>
          <Phone className={styles.phone} />
        </div>
        <div className={styles.code_container}>
          <input
            type="number"
            className={`${styles.code_input} ${darkMode? styles.code_inputDark:''}`}
            placeholder="Enter 6-digit code"
            value={otp}
            onChange={(event) => {
              const input = event.target.value;
              const sanitizedInput = input.replace(/\D/g, '').slice(0, 6);
              setOtp(sanitizedInput);
            }}            
            onFocus={handlepassInputFocus}
            maxLength="6"
            pattern="\d{6}"
          />

        {isVerificationSent?( isResending?(
            <button
            className={styles.send_btnRsnd}
         
            disabled={isResending}
          >
            Resend in {countdown}s
          </button>
          ):(
            <button
            className={`${styles.send_btn} ${darkMode? styles.send_btnDark:''}`}
            onClick={onSignup}//
          >
            {'Resend Code'}
          </button>
          )):(
       null
        )}
          
          
        </div>
        <div id="recaptcha-container" style={{display:'none'}}></div>
        <div style={{color:'red'}}>{errmsg}</div>
        <div style={{color:'red'}}>{ermsg}</div>
        <div style={{color:'#0298a6'}}>{sucmsg}</div>
        {loading?(
        <button className={`${styles.confirm_ld} ${darkMode? styles.confirm_ldDark:''}`}>Confirm...</button>

        ):(
         <button onClick={onOTPVerify} className={`${styles.confirm_btn} ${darkMode? styles.confirm_btnDark:''}`}>Confirm</button>
        )}
      </div>
    </div>
    </div>
    <style>
      {`
    ${darkMode ? '.big--phone .PhoneInputInput  { background: transparent;color:#a6c0c8 }' : ''}
    `}
    </style>
    </>,
    document.getElementById('portal')
  );
};
export default LoginWithCodeModal;
