import React, { useEffect, useState } from "react";
import EmptyProducts from "./EmptyProducts/EmptyProducts";
import ProductsList from "./ProductsList/ProductsList";
import Layout from "../../common/components/Layouts/Layout";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate } from "react-router-dom";
import { listMyProducts } from "../../store/actions/myProductsActions";
import Loader from "../../common/components/Loader/Loader";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import { GetUserProduct } from "../../store/actions/myProductsActions";
import { Helmet } from "react-helmet";
import { GetMyServices } from "../../store/actions/servicesActions";
const MyProductsPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "CLEAR_BREADCRUMBS" })
    const pageName = "My product"; // Replace this with the actual page name
    const pageUrl = "/user/my-offers/"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const navigate = useNavigate();
  const ProductPerUser = useSelector((state) => state.ProductPerUser?.ProductPerUser)
  const UserProducts = useSelector((state)=>state.myProducts?.myProducts)
  const loading = useSelector((state) => state.myProducts?.loading)
  const error = useSelector((state) => state.ProductPerUser?.error)
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const MyServices = useSelector((state) => state.MyServices?.MyServices?.data?.service)
  const [myproducts, setMyProducts] = useState([])
  const userLogin = useSelector((state) => state.userLogin);
  const id = localStorage.getItem('uid')
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const rawData = {
          "onlyConfirmed": false
        }
        const parms = { UserId: id, rawData: rawData }
        const response = await dispatch(GetUserProduct(parms));
        const response1 = await dispatch(GetMyServices())
        await setMyProducts(response)
      } else {
        navigate("/");
      }
    };
    fetchData();
  }, [dispatch, id, navigate]);
  return (
    <>
      <Helmet>
        <title>My Product</title>
        <meta name="My Product" content="This is My Product Page" />
        <meta
          name="keywords"
          content={`My Product, la7i27alak`}
        />
      </Helmet>
      <Layout title={"My Offers"}>
        {loading ? (
          <div className="center">
            <Loader />
          </div>
        ) : error ? (
          <div className="center">
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        ) : UserProducts && UserProducts.length !== 0 ? (
          <ProductsList products={UserProducts} MyServices={MyServices} />
        ) : (
          <EmptyProducts />
        )}
      </Layout>
    </>
  );
};
export default MyProductsPage;
