const initialState = {
    ProductArray: [],
   
  };
  
  export const PreviewReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_PRODUCT_ARRAY":
        return {
            ...state,
            data: [...state.ProductArray, action.payload]
        };
      case "REMOVE_PRODUCT_ARRAY":
        return {
            ...state,
            ProductArray: state.ProductArray.filter(item => item.id !== action.payload)
          };
      default:
        return state;
    }
  };