import {
    GET_OFFER_BY_DATE_FAIL,
    GET_OFFER_BY_DATE_SUCCESS
} from "../constants/sendOfferConstants";
const initialState = {
    OfferByDate: [],
    error: null,
    loading: false,
};
export const GetOfferByDate = (state = initialState, action) => {
    switch (action.type) {
        case GET_OFFER_BY_DATE_SUCCESS:
            return {
                ...state,
                OfferByDate: action.payload,
                error: null,
                loading: false,
            };
        case GET_OFFER_BY_DATE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
