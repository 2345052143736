import {
  UPDATE_GEOLOCATION_REQUEST,
  UPDATE_GEOLOCATION_SUCCESS,
  UPDATE_GEOLOCATION_FAIL,
  GET_GEOLOCATION_REQUEST,
  GET_GEOLOCATION_SUCCESS,
  GET_GEOLOCATION_FAIL,
} from "../constants/locationConstants";
import axios from "axios";
import { url } from "../../common/constants/url";
export const getGeolocationHandler = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_GEOLOCATION_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const {
      userById: { user },
    } = getState();
    await axios.get(url + `/User/${user.id}/updategeolocation`, config);
    dispatch({
      type: GET_GEOLOCATION_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: GET_GEOLOCATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updategGolocationHandler =
  (id, latitude, longitude) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_GEOLOCATION_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let body = {
        latitude,
        longitude,
      };
      await axios.post(
        url + `/User/${id}/updategeolocation`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: UPDATE_GEOLOCATION_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_GEOLOCATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
