import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  AiOutlineDown,
  AiOutlineUp,
  AiOutlinePlus,
  AiOutlineSearch,
} from "react-icons/ai";
import PopUp from "../PopUp/PopUp";
import SearchComponent from "../../../../common/components/Search/Search";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCategories, GetAllCategoriesSuccess } from "../../../../store/actions/categoryAction";
import { GetAllTypes } from "../../../../store/actions/servicesActions";
const Search = () => {
  const dispatch = useDispatch();
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  useEffect(() => {
    dispatch(GetAllCategories())
      .then((data) => {
        dispatch(GetAllCategoriesSuccess(data));

      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch]);
  const types = useSelector((state) => state.types?.types?.data?.types)
  useEffect(() => {
    dispatch(GetAllTypes());
  }, [dispatch]);
  const categories = useSelector((state) => state.categories?.categories?.data?.categories);
  const [isOpen, setIsOpen] = useState(true);
  function handleCategoryChange(event) {
    const selectedValue = event.target.value;
    if (selectedValue === 'default') {
      setSelectedCategory("");
      setSelectedCategoryId(null)
      setSelectedSubCategoryId(null)
    } else { // Deselect the category
      const selectedCategory = categories.find((category) => category.title === selectedValue);
      setSelectedCategory(selectedValue);
      setSelectedCategoryId(selectedCategory ? selectedCategory._id : null);
    }
  }
  function handleTypeChange(event) {
    const selectedValue = event.target.value;
    if (selectedValue === 'default') {
      setSelectedCategory("");
      setSelectedCategoryId(null)
      setSelectedSubCategoryId(null)
    } else { // Deselect the category
      const selectedCategory = types.find((category) => category.title === selectedValue);
      setSelectedCategory(selectedValue);
      setSelectedCategoryId(selectedCategory ? selectedCategory._id : null);
    }
  }
  const handleSubCategoryChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'default') {
      setSelectedSubCategory("");
      setSelectedSubCategoryId(null)
    } else {
      const selectedSubCategory = filteredSubcategories.find((subcategory) => subcategory.title === selectedValue);
      setSelectedSubCategory(selectedValue);
      setSelectedSubCategoryId(selectedSubCategory ? selectedSubCategory._id : null);
    }
  };
  const filteredSubcategories = Array.isArray(categories)
    ? categories.find(category => category.title === selectedCategory)?.subCategories
    : null;
  const activateFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
  };
  const activateSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
  };
  const activateThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
  };
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <section
      className={`${styles.search__section} ${darkMode ? styles.search__sectionDark : ''}`}
    >
      {/* <PopUp open={isOpen} setOpen={() => setIsOpen(false)} /> */}
      <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Find What You Are Looking For</h1>
      <div className={`${styles.search__type} ${darkMode ? styles.search__typeDark : ''}`}>
        <button
          className={`${styles.button} ${first ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
          onClick={activateFirst}
        >
          Product
        </button>
        <button
          className={`${styles.button} ${second ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
          onClick={activateSecond}
        >
          Service
        </button>
        <button
          className={`${styles.button} ${third ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
          onClick={activateThird}
        >
          Bid
        </button>
      </div>
      <div className={styles.search__component}>
        <SearchComponent categoryId={selectedCategoryId} subCategoryId={selectedSubCategoryId} first={first} second={second} third={third} categoryName={selectedCategory} subCategoryName={selectedSubCategory}/>
      </div>
      {(first || third) && (
        <div className={styles.search__filter}>
          <div className={styles.select_container}>
            <select
              name="categories"
              className={`${styles.select} ${darkMode ? styles.selectDark : ''}`}
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="default">Category</option>
              {categories &&
                categories.map((category) => (
                  <option key={category._id} value={category.title}>
                    {category.title}
                  </option>
                ))}
            </select>
          </div>
          <div className={styles.select_container}>
            <select
              name="subcategories"
              className={`${styles.select} ${darkMode ? styles.selectDark : ''}`}
              value={selectedSubCategory}
              onChange={handleSubCategoryChange}
            >
              <option value="default">Subcategory</option>
              {filteredSubcategories &&
                filteredSubcategories.map((subcategory) => (
                  <option key={subcategory._id} value={subcategory.title}>
                    {subcategory.title}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}
      {second && (
        <div className={styles.search__filterS}>
          <div className={styles.select_containerS}>
            <select
              name="categories"
              className={`${styles.selectS} ${darkMode ? styles.selectSDark : ''}`}
              value={selectedCategory}
              onChange={handleTypeChange}
            >
              <option value="default">Service type</option>
              {types &&
                types.map((category) => (
                  <option key={category._id} value={category.title}>
                    {category.title}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}
    </section>
  );
};
export default Search;
