import {
    GET_USER_PRODUCTS_SUCCESS,
    GET_USER_PRODUCTS_FAIL,
    GET_USER_PRODUCTS_REQUEST
} from "../constants/myProductsContants";
const initialState = {
    ProductPerUser: [],
    error: null,
    loading: false,
};
export const GetProductPerUserr = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_PRODUCTS_REQUEST:
            return {
                ...state,
                ProductPerUser: action.payload,
                error: null,
                loading: true,
            };
        case GET_USER_PRODUCTS_SUCCESS:
            return {
                ...state,
                ProductPerUser: action.payload,
                error: null,
                loading: false,
            };
        case GET_USER_PRODUCTS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
