import React, { useState } from "react";
import styles from "./Cards.module.scss";
import { useSelector } from "react-redux";
const Card = ({ title, image, clickHandler, id,isSelected }) => {
  const [active, setActive] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <div
    className={`${styles.card} ${isSelected ? styles.active : ""}`}
      onClick={() => {
        setActive((prevState) => !prevState);
        clickHandler(id);
      }}
    >
      <div className={`${styles.image_container} ${darkMode? styles.image_containerDark:''} `}>
        <img src={image} alt={title} className={styles.img} />
      </div>
      <h3 className={styles.title}>{title}</h3>
    </div>
  );
};
export default Card;
