import React, { useState, useEffect } from "react";
import styles from "./RelatedProducts.module.scss";
import Cards from "../../common/components/Cards/Cards";
import { BsArrowRight } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import Car from "../../assests/images/Car.png";
import SwiperCore, { Navigation } from "swiper";
import { Helmet } from "react-helmet";
import { GetRelatedProduct } from "../../store/actions/productDetailsAction";
import { GetRelatedService } from "../../store/actions/productDetailsAction";
import ProdCardSkel from "../HomePage/components/FeaturedProduct/ProdCardSkel";
SwiperCore.use([Navigation]);
const RelatedProducts = ({ categoryId, productId, type, wishList, ServicesWishlist }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isSmallScreen = windowWidth < 768;
  const screenWidth = window.innerWidth;
  let slidesPerView;
  if (screenWidth < 580) {
    slidesPerView = 'auto';
  } else {
    slidesPerView = 4;
  }
  const loading = useSelector(
    (state) => state.RelatedProducts?.loading
  );
  const RelatedProduct = useSelector((state) => state.RelatedProducts?.RelatedProducts?.data?.products)
  const RelatedServices = useSelector((state) => state.RelatedServices?.RelatedServices?.services)

  useEffect(() => {
    if (type === 'service') {
      dispatch(GetRelatedService(categoryId))
    } else {
      dispatch(GetRelatedProduct({ pageSize: 25, page: 1, categoryID: categoryId }))
    }
  }, [dispatch, categoryId])
  function shuffleArray(array) {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  }
  const randomProducts = RelatedProduct && RelatedProduct.length > 0
    ? shuffleArray(RelatedProduct.filter((product) => product._id !== productId)).slice(0, 4)
    : [];

  return (
    <>
      <section className={`${styles.section} ${darkMode ? styles.sectionDark : ''}`} >
        <div className={styles.header}>
          <h2 className={`${styles.header__title} ${darkMode ? styles.header__titleDark : ''}`}>
            {type === 'service' ? 'Related Services' : 'Related Products'}
          </h2>
        </div>
        <div className={styles.swiper__section}>
          <Swiper
            freeMode
            navigation={!isSmallScreen}
            modules={[Navigation]}
            spaceBetween={30}
            slidesPerView={slidesPerView}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) =>  console.log(swiper)}
          >
            {loading ? (
              <div>
                <ProdCardSkel />
              </div>
            ) : (
              <>
                {type === 'service'
                  ? RelatedServices && RelatedServices.map((service) => (
                    <SwiperSlide key={service._id} className={styles.swiperslide}>
                      <div className={styles.cardsContainer}>
                        <Cards
                          id={service._id}
                          title={service.title}
                          price={service.pricing?.price}
                          mainimage={service.imageUrls && service.imageUrls[0]}
                          featured={service.featured}
                          time={service.createdAt}
                          end={service.timer?.deadline}
                          timer={service.timer?.enabled}
                          old={service.condition}
                          location={service.serviceAddress?.region}
                          isService={service.isService}
                          discounted={service.discount?.enabled}
                          percentage={service.discount?.percentage}
                          ServicesWishlist={ServicesWishlist}
                          wishList={wishList}
                        />
                      </div>
                    </SwiperSlide>
                  ))
                  : RelatedProduct && RelatedProduct.slice(0, 4).map((product) => (
                    <SwiperSlide key={product._id} className={styles.swiperslide}>
                      <div className={styles.cardsContainer}>
                        <Cards
                          id={product._id}
                          title={product.title}
                          price={product.pricing?.price}
                          mainimage={product.imageUrls && product.imageUrls[0]}
                          featured={product.featured}
                          time={product.createdAt}
                          end={product.timer?.deadline}
                          timer={product.timer?.enabled}
                          old={product.condition}
                          location={product.pickUpAddress?.region}
                          isService={product.isService}
                          ServicesWishlist={ServicesWishlist}
                          wishList={wishList}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
              </>
            )}
          </Swiper>
        </div>
      </section>
    </>
  );
};
export default RelatedProducts;
