import {
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  VEHICLE_BRAND_FAIL,
  VEHICLE_BRAND_REQUEST,
  VEHICLE_BRAND_SUCCESS,
  BRAND_MODULES_FAIL,
  BRAND_MODULES_REQUEST,
  BRAND_MODULES_SUCCESS,
  BODYTYPES_FAIL,
  BODYTYPES_REQUEST,
  BODYTYPES_SUCCESS,
  UPLOAD_MAIN_IMAGE_FAIL,
  UPLOAD_MAIN_IMAGE_REQUEST,
  UPLOAD_MAIN_IMAGE_RESET,
  UPLOAD_MAIN_IMAGE_SUCCESS,
  UPLOAD_IMAGES_REQUEST,
  UPLOAD_IMAGES_SUCCESS,
  UPLOAD_IMAGES_FAIL,
  UPLOAD_IMAGES_RESET,
  ADD_PRODUCT_RESET,
  CREATE_PRODUCT_FAIL,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_REQUEST,
} from "../constants/addProductConstants";
const initialState = {
  CreateProduct: [],
  error: null,
  loading: false,
};
export const CreateProductReducer = (state = initialState, action) => {
  switch (action.type) {
      case CREATE_PRODUCT_REQUEST:
          return {
              ...state,
              CreateProduct: action.payload,
              error: null,
              loading: true,
          };
      case CREATE_PRODUCT_SUCCESS:
          return {
              ...state,
              CreateProduct: action.payload,
              error: null,
              loading: false,
          };
      case CREATE_PRODUCT_FAIL:
          return {
              ...state,
              error: action.payload,
              loading: false,
          };
      default:
          return state;
  }
};
export const addproductReducer = (
  state = { success: false, loading: false, error: null, id: null },
  action
) => {
  switch (action.type) {
    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        id: action.payload,
      };
    case ADD_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_PRODUCT_RESET:
      return {
        success: false,
        loading: false,
        error: null,
        id: null,
      };
    default:
      return state;
  }
};
export const uploadMainImageReducer = (
  state = { success: false, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case UPLOAD_MAIN_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_MAIN_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case UPLOAD_MAIN_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_MAIN_IMAGE_RESET:
      return {
        success: false,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const uploadImagesReducer = (
  state = { success: false, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case UPLOAD_IMAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_IMAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case UPLOAD_IMAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_IMAGES_RESET:
      return {
        success: false,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const vehiclebrandReducer = (
  state = { vehiclebrandlist: [], loading: false, error: null },
  action
) => {
  switch (action.type) {
    case VEHICLE_BRAND_REQUEST:
      return { ...state, loading: true };
    case VEHICLE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        vehiclebrandlist: action.payload,
      };
    case VEHICLE_BRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const brandmoduleReducer = (
  state = { brandmodulelist: [], loading: false, error: null },
  action
) => {
  switch (action.type) {
    case BRAND_MODULES_REQUEST:
      return { ...state, loading: true };
    case BRAND_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        brandmodulelist: action.payload,
      };
    case BRAND_MODULES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const bodytypesReducer = (
  state = { bodytypeslist: [], loading: false, error: null },
  action
) => {
  switch (action.type) {
    case BODYTYPES_REQUEST:
      return { ...state, loading: true };
    case BODYTYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        bodytypeslist: action.payload,
      };
    case BODYTYPES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
