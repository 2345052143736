import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import styles from './EditProductCategory.module.scss'
import { Form as BootstrapForm } from "react-bootstrap";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import * as Yup from 'yup';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Camera from "../../assests/images/camera.png";
import { useLocation, useParams } from 'react-router-dom';
import { GetTypeById } from "../../store/actions/servicesActions";
import { useDispatch, useSelector } from "react-redux";
import { EditServiceType } from "../../store/actions/servicesActions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetAllSubCategories } from "../../store/actions/categoryAction";
import { EditCategory } from "../../store/actions/categoryAction";
import { GetSingleCategory } from "../../store/actions/categoryAction";
import { useNavigate } from "react-router-dom";
import { CreateServiceType } from "../../store/actions/servicesActions";
import { CreateCategory } from "../../store/actions/categoryAction";
import logo from "../../assests/svg/apple.svg"
const EditProductCategory = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null)
  const [data, setData] = useState([])
  const SubById = useSelector((state) => state.SubById?.SubById?.data?.subCategories)
  const [subcategorySearch, setSubcategorySearch] = useState("");
  const [icon, setIcon] = useState("")
  const [newIcon, setNewIcon] = useState([])
  const dropdownOptions = ["list", "boolean", "string", "number"]
  const [newTitle, setNewTitle] = useState("")
  const handleNewSubCatNameChange = (event) => {
    setNewTitle(event.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await dispatch(GetSingleCategory(id))
        const response = await dispatch(GetAllSubCategories(id));
        setData(response);
        setSubcategorySearch(response1.data?.category?.title)
        setIcon(response1.data?.category?.iconUrl)
      } catch (error) {
        // Handle any errors that occur during the dispatch or data retrieval process
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dispatch, id, id]);
  const [photoState, setPhotoState] = useState(null);
  const handleChange = (event) => {
    const file = event.target.files[0];
  
    setNewIcon(file);
  };
  const handleImageClick = () => {
    fileInputRef.current.click();
  };
  const handleEditCategory = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', subcategorySearch)
      formData.append('icon', newIcon)
      // if (newIcon.length > 0) {
      //   formData.append('icon', newIcon)
      // } else {
      //   formData.append('icon', icon)
      // }
      const response = await dispatch(EditCategory(formData, id));
      if (response) {
        toast.success("Category edited successfully.")
      }
    } catch (error) {
      // console.log(error);
    }
  }
  const handleChangeTitle = (event) => {
    setSubcategorySearch(event.target.value);
  };
  const [subcategories, setSubcategories] = useState(SubById);
  useEffect(() => {
    setSubcategories(SubById);
  }, [SubById]);
  const handleSubcategoryNameChange = (index, newValue) => {
    const updatedSubcategories = [...subcategories];
    updatedSubcategories[index].title = newValue;
    setSubcategories(updatedSubcategories);
  };
  const [numAddedSubcategories, setNumAddedSubcategories] = useState(0); // Step 1
  const handleAddSubcategory = () => {
    setNumAddedSubcategories(numAddedSubcategories + 1);
  };
  return (
    <AdminLayout>
      <div className={styles.container}>
        <div className={styles.title}>
          <h4>Edit Category</h4>
        </div>
        <div className={styles.input_container}>
          <div className={styles.Img_inp}>
            <input
              type="text"
              className={styles.input}
              value={subcategorySearch}
              onChange={handleChangeTitle}
            />
            <div className={styles.image_section}>
              <div className={styles.wrapper}>
                <div className={styles.image_container}>
                  <img
                    src={
                      newIcon && newIcon.length === 0
                        ? (icon
                          ? typeof icon === 'string' && icon.startsWith('https://storage.googleapis.com/')
                            ? icon
                            : URL.createObjectURL(newIcon)
                          : Camera)
                        : URL.createObjectURL(newIcon)
                    }
                    alt="camera"
                    className={`${icon ? styles.full_image : styles.image}`}
                    onClick={handleImageClick}
                  />
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg , image/svg+xml"
                    style={{ display: "none" }}
                    onChange={(event) => handleChange(event)}
                    ref={fileInputRef}
                  />
                </div>
              </div>
            </div>
          </div>
          <button className={styles.editButton} onClick={handleEditCategory}>
            Edit
          </button>
        </div>
        <MDBContainer className="mt-5" style={{background:'white', maxWidth: '1000px',maxHeight:'680px',overflowY:'auto' }}>
          <MDBAccordion >
            {SubById && SubById.map((subcategory, index) => (
              <MDBAccordionItem key={index} collapseId={index + 1} headerTitle={` Edit ${subcategory.title}`}>
                <div className={styles.input_container}>
                  <div className={styles.title}>
                    <h6>Subcategory Name</h6>
                  </div>
                  <input
                    type="text"
                    className={styles.input}
                    value={subcategories && subcategories[index]?.title}
                    onChange={(e) => handleSubcategoryNameChange(index, e.target.value)}
                  />
                </div>
                <Formik
                  enableReinitialize
                  initialValues={{
                    field: subcategory && subcategory.fields.map((field) => ({
                      type: field.type,
                      title: field.title,
                      possibleValues: Array.isArray(field.possibleValues) ? field.possibleValues.join(', ') : '',
                      id: field.id
                    })),
                  }}
                  validationSchema={Yup.object({
                    field: Yup.array().of(
                      Yup.object().shape({
                        type: Yup.string().required("Type is required"),
                        title: Yup.string().required("Title is required"),
                        possibleValues: Yup.string().when("type", {
                          is: (type) => ["list", "boolean"].includes(type),
                          then: Yup.string().required("Possible Values are required"),
                          otherwise: Yup.string().strip()
                        })
                      })
                    )
                  })}
                  onSubmit={(values, { resetForm }) => {
                    const processedValues = values.field.map(field => {
                      if (["list", "boolean"].includes(field.type)) {
                        const possibleValuesArray = field.possibleValues
                          ? field.possibleValues.split(',').map(value => value.trim())
                          : [];
                        return { ...field, possibleValues: possibleValuesArray };
                      }
                      const { possibleValues, ...rest } = field;
                      return rest;
                    });
                    const transformedData = { fields: processedValues }
                    try {
                      const formData = new FormData();
                      // formData.append('title', subcategories && subcategories[index].title);
                      formData.append('icon', icon);
                      formData.append('parentCategoryId', id);
                      formData.append("fields", JSON.stringify(transformedData));
              
                      dispatch(EditCategory(formData, subcategories && subcategories[index]._id))
                        .then(response => {
                  
                    
                          // Assuming the resolved value of the promise is an object containing the 'result' property
                          if (response && response) {
                            toast.success(`SubCategory ${index + 1} edited successfully.`);
                          } else {
                            toast.warning("Please try again.");
                          }
                        })
                        .catch(error => {
                          console.error(error);
                        });
                    } catch (error) {
                      console.error(error);
                    }
                    //resetForm();
                  }}
                  render={({ values, handleSubmit }) => (
                    <Form>
                      <FieldArray
                        name="field"
                        render={arrayHelpers => {
                          const field = values.field;
                          return (
                            <div>
                              {field && field.length > 0
                                ? field.map((user, index) => (
                                  <div key={index}>
                                    <div className={styles.subtitle}> Field </div>
                                    <div className={styles.CategName}>
                                      <Field
                                        type="text"
                                        id={`field.${index}.title`}
                                        name={`field.${index}.title`}
                                        className={styles.FieldInput}
                                        placeholder="Enter Field title"
                                      />
                                      <ErrorMessage name={`field.${index}.title`} />
                                      <Field
                                        as="select"
                                        id={`field.${index}.type`}
                                        name={`field.${index}.type`}
                                        className={styles.FieldDropdown}
                                      >
                                        <option value="">Select an option</option>
                                        {dropdownOptions.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </Field>
                                      {(values && values.field[index].type === 'list') || (values && values.field[index].type === 'boolean') ? (
                                        <Field
                                          type="text"
                                          id={`field.${index}.possibleValues`}
                                          name={`field.${index}.possibleValues`}
                                          className={styles.possibleValues}
                                          placeholder="Enter Possible Values separated with a comma"
                                        />
                                      ) : null}
                                      <ErrorMessage
                                        name={`field.${index}.type`}
                                        component="div"
                                        className={styles.error}
                                      />
                                      <ErrorMessage
                                        name={`field.${index}.possibleValues`}
                                        component="div"
                                        className={styles.error}
                                      />
                                      <br />
                                    </div>
                                    <div className={styles.plus_button_container}>
                                      <button
                                        type="button"
                                        className={styles.plus_button}
                                        onClick={() => arrayHelpers.remove(index)} // remove a field from the list
                                      >
                                        -
                                      </button>
                                    </div>
                                    <br />
                                    <br />
                                  </div>
                                ))
                                : null}
                              <div className={styles.plus_button_container}>
                                <button
                                  type="button"
                                  className={styles.plus_button}
                                  onClick={() => {
                                    arrayHelpers.push({
                                      type: "",
                                      title: "",
                                    });
                               
                                  }}
                                >
                                  +
                                </button>
                                <span>Add Specific Field</span>
                              </div>
                              <div className={styles.button_row}>
                                <button className={styles.AddSub} type="submit" onClick={handleSubmit}>Edit Subcategory {index + 1}</button>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </Form>
                  )}
                />
              </MDBAccordionItem>
            ))}
            {Array.from({ length: numAddedSubcategories }).map((_, index) => (
              <MDBAccordionItem key={SubById.length + index + 1} collapseId={SubById.length + index + 1} headerTitle="Add New Subcategory">
                <div>
                  <div className={styles.input_container}>
                    <div className={styles.title}>
                      <h6>Subcategory Name</h6>
                    </div>
                    <input
                      type="text"
                      className={styles.input}
                      onChange={handleNewSubCatNameChange}
                      value={newTitle} />
                  </div>
                  <Formik
                    initialValues={{
                      field: [{ type: "", title: "" }]
                    }}
                    validationSchema={Yup.object({
                      field: Yup.array().of(
                        Yup.object().shape({
                          type: Yup.string().required("Type is required"),
                          title: Yup.string().required("Title is required"),
                          possibleValues: Yup.string().when("type", {
                            is: (type) => ["list", "boolean"].includes(type),
                            then: Yup.string().required("Possible Values are required"),
                            otherwise: Yup.string().strip()
                          })
                        })
                      )
                    })}
                    onSubmit={(values, { resetForm }) => {
                      const processedValues = values.field.map(field => {
                        if (["list", "boolean"].includes(field.type)) {
                          const possibleValuesArray = field.possibleValues
                            ? field.possibleValues.split(',').map(value => value.trim())
                            : [];
                          return { ...field, possibleValues: possibleValuesArray };
                        }
                        const { possibleValues, ...rest } = field;
                        return rest;
                      });
                      // Perform your submission logic here
                      try {
                        const yourBlob = new Blob([logo], { type: 'image/svg+xml' });
                        const yourLastModifiedTimestamp = 1690624124000; // Actual timestamp
                        const yourFileObject = new File([yourBlob], 'electronics.svg', { type: 'image/svg+xml', lastModified: yourLastModifiedTimestamp });
                        const formData = new FormData();
                        formData.append('title', newTitle);
                        formData.append('icon', yourFileObject);
            
                        formData.append('parentCategoryId', id);
                        formData.append("fields", JSON.stringify(processedValues));
                
                        dispatch(CreateCategory(formData))
                          .then(response => {
                            if (response && response) {
                              toast.success(`SubCategory created successfully.`);
                            } else {
                              toast.warning("Please try again.");
                            }
                          })
                          .catch(error => {
                            console.error(error);
                          });
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                    render={({ values, handleSubmit }) => (
                      <Form>
                        <FieldArray
                          name="field"
                          render={arrayHelpers => {
                            const field = values.field;
                            return (
                              <div>
                                {field && field.length > 0
                                  ? field.map((user, index) => (
                                    <div key={index}>
                                      <div className={styles.subtitle}> Field </div>
                                      <div className={styles.CategName}>
                                        <Field
                                          type="text"
                                          id={`field.${index}.title`}
                                          name={`field.${index}.title`}
                                          className={styles.FieldInput}
                                          placeholder="Enter Field title"
                                        />
                                        <ErrorMessage name={`field.${index}.title`} />
                                        <Field
                                          as="select"
                                          id={`field.${index}.type`}
                                          name={`field.${index}.type`}
                                          className={styles.FieldDropdown}
                                        >
                                          <option value="">Select an option</option>
                                          {dropdownOptions.map((option) => (
                                            <option key={option} value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </Field>
                                        {values.field[index].type === 'list' || values.field[index].type === 'boolean' ? (
                                          <Field
                                            type="text"
                                            id={`field.${index}.possibleValues`}
                                            name={`field.${index}.possibleValues`}
                                            className={styles.possibleValues}
                                            placeholder="Enter Possible Values separated with a comma"
                                          />
                                        ) : null}
                                        <ErrorMessage
                                          name={`field.${index}.type`}
                                          component="div"
                                          className={styles.error}
                                        />
                                        <ErrorMessage
                                          name={`field.${index}.possibleValues`}
                                          component="div"
                                          className={styles.error}
                                        />
                                        <br />
                                      </div>
                                      <div className={styles.plus_button_container}>
                                        <button
                                          type="button"
                                          className={styles.plus_button}
                                          onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                        >
                                          -
                                        </button>
                                      </div>
                                      <br />
                                      <br />
                                    </div>
                                  ))
                                  : null}
                                <div className={styles.plus_button_container}>
                                  <button type="button" className={styles.plus_button} onClick={() =>
                                    arrayHelpers.push({
                                      type: "",
                                      title: ""
                                    })}>+</button>
                                  <span>Add Specific Field</span>
                                </div>
                                <div className={styles.button_row}>
                                  <button className={styles.AddSub} type="submit" onClick={handleSubmit}>Create Category</button>
                                </div>
                              </div>
                            );
                          }}
                        />
                        <hr />
                      </Form>
                    )}
                  />
                </div>
              </MDBAccordionItem>
            ))}
          </MDBAccordion>
        </MDBContainer>
        <div className={styles.add_Subcategory}>
          <button className={styles.AddSub} onClick={handleAddSubcategory}>
            Add new subCategory
          </button>
        </div>
      </div>
    </AdminLayout>
  )
}
export default EditProductCategory;