import {
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_RESET,
  PRODUCT_DETAILS_FAIL,
  GET_ALL_PRODUCT_FAIL,
  GET_ALL_PRODUCT_SUCCESS
} from "../constants/productDetailsConstants";
export const getProductDetailsReducer = (
  state = { productDetails: null, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        productDetails: action.payload,
      };
    case PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PRODUCT_DETAILS_RESET:
      return {
        productDetails: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
const initialState = {
  products: [],
  error: null,
  loading: false
};
export const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PRODUCT_SUCCESS":
      return {
        ...state,
        products: action.payload,
        error: null,
        loading: false,
      };
    case "GET_ALL_PRODUCT_FAIL":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};