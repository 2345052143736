import { GET_ALL_SUB_CATEGORIES_SUCCESS,GET_ALL_SUB_CATEGORIES_FAIL } from "../constants/categoryConstants";
const initialState = {
    SubById: [],
    error: null,
    loading: false,
};
export const GetSubById = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SUB_CATEGORIES_SUCCESS:
            return {
                ...state,
                SubById: action.payload,
                error: null,
                loading: false,
            };
        case GET_ALL_SUB_CATEGORIES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
