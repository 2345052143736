import React, { useEffect, useState,useRef } from "react";
import styles from "./AdminchatBody.module.scss";
import { BiCheckDouble } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux/es/exports";
import moment from 'moment';
import ProductCard from "../../../UserPage/components/ProductCard/ProductCard";
const AdminChatBody = ({ messages, selectedUser }) => {
  const [messageList, setMessageList] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const specificUserId = userLogin.user?.token
  const chatBodyRef = useRef(null);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  useEffect(() => {
    if (messages && Array.isArray(messages)) {
      setMessageList(messages);
    }
  }, [messages]);
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messageList]);
  const renderDateSeparator = (date) => {
    const today = moment().startOf('day').format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD');
    if (date === today) {
      return "Today";
    } else if (date === yesterday) {
      return "Yesterday";
    } else {
      return formatDate(date);
    }
  };
  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  return (
    <>
      <div className="chatBody" ref={chatBodyRef}>
        {messageList && Array.isArray(messageList) && messageList.length > 0 ? (
          messageList.map((message, index) => {
            const previousMessage = messageList[index - 1];
            const showDateSeparator =
              index === 0 || !moment(message.sendTime).isSame(previousMessage.sendTime, 'day');
            return (
             
              <React.Fragment key={message.id}>
                {showDateSeparator && (
                  <div className="dateSeparator">
                    {renderDateSeparator(moment(message.sendTime).format('YYYY-MM-DD'))}
                  </div>
                )}
                  {message.msgType === 'object' ? (
                <div className={`prod ${message.from === selectedUser.id ? 'incomingp' : 'sentp'}`}>
                    <div className="prodcd">
                    <ProductCard
                      image={message.offerimage}
                      featured={message.featured}
                      title={message.offername}
                      price={message.offerprice}
                      location={message.location}
                      old={message.condition}
                      id={message.productId}
                      imported={true}
                      time={message.date}
                      
                    />
                    </div>
                    </div>
                  ) : (
                    <div className={`message ${message.from === selectedUser.id ? 'incoming' : 'sent'}`}>
                    <>
                      {message.message}
                      
                      <div className="messageFooter">
                        <span className="messageTime">{message.timestamp}</span>
                        {message.type === 'sent' && <BiCheckDouble className="sentIcon" />}
                      </div>
                    </>
                </div>
                  )}
              </React.Fragment>
                
            );
          })
        ) : (
          <div className="emptyMessage">No messages available</div>
        )}
      </div>
    </>
  );
};
export default AdminChatBody;
