import {
    GET_MY_SERVICES_FAIL,
    GET_MY_SERVICES_SUCCESS
} from "../constants/servicesConstants";
const initialState = {
    MyServices: [],
    error: null,
    loading: false,
};
export const GetMyServices = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_SERVICES_SUCCESS:
            return {
                ...state,
                MyServices: action.payload,
                error: null,
                loading: false,
            };
        case GET_MY_SERVICES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
