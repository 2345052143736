import {
    GET_PRODUCT_PER_USER_SUCCESS,
    GET_PRODUCT_PER_USER_FAIL
} from "../constants/userConstants";
const initialState = {
    ProductPerUser: [],
    error: null,
    loading: false,
};
export const GetProductPerUser= (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT_PER_USER_SUCCESS:
            return {
                ...state,
                ProductPerUser: action.payload,
                error: null,
                loading: false,
            };
        case GET_PRODUCT_PER_USER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
