import React, {useState} from "react";
import styles from "./ForgetPassword.module.scss";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import { ReactComponent as Back } from "../../../../assests/svg/back.svg";
import { ReactComponent as BackDark } from "../../../../assests/svg/arrowleftDark.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { userForgetPassword } from "../../../../store/actions/authActions";
import Loader from "../../Loader/Loader";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { auth } from "../../../../firebase";
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { set } from "date-fns";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../../../assests/svg/La7i2_7alak1.1.svg'
const ForgetPassword = ({ setStep, closeHandler }) => {
  const dispatch = useDispatch();
  const forgetPassword = useSelector((state) => state.forgetPassword);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const { loading, error, code } = forgetPassword;
  const [clicked, setClicked] = useState(false);
  const [failMessage, setFailMessage] = useState('');
  const [sucMessage, setsucMessage] = useState('');
  const [verificationCodeEntered, setVerificationCodeEntered] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const auth = getAuth();
  const handleEmailInputFocus = () => {
    setsucMessage('')
    setFailMessage("");
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
  
      onSubmit: async (values) => {
        try {
          var actionCodeSettings = {
            url: 'https://la7i27alak.com/',
          };
          
          
          await sendPasswordResetEmail(auth, values.email,actionCodeSettings);
          setClicked(true)
          toast.success('Password reset email sent successfully.');
          setStep('login')
         
        } catch (error) {
          let errorMessage = "";
          switch (error.code) {
            case "auth/user-not-found":
              errorMessage = "User not found. Please check your email address and try again.";
              break;
            default:
              errorMessage = "An error occurred. Please try again later.";
          }
          setClicked(false)
          setFailMessage(errorMessage);
        }        
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
    }),
  });
  return (
    <div className={styles.container}>
    {darkMode ?
        (
          <CloseDark
            className={`${styles.close}`}
            onClick={() => {
              closeHandler();
            }}
          />
        ) : (
          <Close
            className={`${styles.close}`}
            onClick={() => {
              closeHandler();
            }}
          />
        )}
      {darkMode ?
        (
          <BackDark
            className={`${styles.back}`}
            onClick={() => {
              setStep("login");
            }}
          />
        ) : (
          <Back
            className={`${styles.back}`}
            onClick={() => {
              setStep("login");
            }}
          />
        )}
<div className={styles.logoCont}>
      <img src={logo} alt='logo' className={styles.logo}/>
      </div>      <form className={styles.form_container} onSubmit={formik.handleSubmit}>
        <label htmlFor="phone" className={`${styles.label} ${darkMode? styles.labelDark:''}`}>
          Enter email address
        </label>
        <div
          className={`${styles.email_container} ${
            formik.errors.email && formik.touched.email
              ? styles.error_input
              : ""
          }`}
        >
          <input
            type="email"
            name="email"
            className={`${styles.input} ${darkMode? styles.inputDark:''}`}
            placeholder="Email address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onFocus={handleEmailInputFocus}
          />
          {formik.errors.email && formik.touched.email && (
            <p className={styles.error_message}>{formik.errors.email}</p>
          )}
        </div>
            <div style={{color:'#0298a6'}}>
              {sucMessage}
            </div>
            <div style={{color:'red'}}>
              {failMessage}
            </div>
          <button className={`${styles.confirm_btn} ${darkMode? styles.confirm_btnDark:''}`}  type={"submit"}>
            {clicked ? 'Resend Code' : 'Send Code'}
          </button>
          {error && (
            <div className={styles.backend_error}>
              <ErrorMessage>{error}</ErrorMessage>
            </div>
          )}
      </form>
      <p className={`${styles.register_text} ${darkMode? styles.register_textDark:''}`}>
        Don't have an account?{" "}
        <span className={styles.register} onClick={() => setStep("register")}>
          Register
        </span>
      </p>
    </div>
  );
};
export default ForgetPassword;
