import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./Styles.module.scss";
import IMG from "../../../../assests/images/COD.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore, { Navigation, Pagination, EffectFade } from "swiper/core";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { GetCOD } from "../../../../store/actions/AdminDashboard";
import Moment from "react-moment";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import { animateScroll as scroll } from 'react-scroll';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import LazyLoad from 'react-lazyload'
SwiperCore.use([Navigation, Pagination, EffectFade]);
const CatchOfTheDay = () => {
  const navigate = useNavigate()
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const dispatch = useDispatch();
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);
  const cod = useSelector((state) => state.cod?.cod?.data?.catchOfTheDay);
  const [remainingTimes, setRemainingTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const myRef = useRef(null)
  {/*useLayoutEffect(() => {
    if (myRef) {
      myRef.current.scrollIntoView()
    }
  }, [myRef])*/}
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(GetCOD())
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
  }, [dispatch]);
  useEffect(() => {
    const interval = setInterval(() => {
      updateRemainingTimes();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const updateRemainingTimes = () => {
    const currentTime = Date.now();
    const updatedTimes = cod && cod.map((image) => {
      const endTime = image.catchOfTheDayRevertTime;
      const difference = endTime - currentTime;
      const remainingTime = difference >= 0 ? difference : 0;
      return remainingTime;
    });
    setRemainingTimes(updatedTimes);
  };
  const catchOfTheDayRef = useRef(null);
  const location = useLocation()
  useEffect(() => {
    if (window.location.hash === '#CatchOfTheDay') {
      catchOfTheDayRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);
  return (
    <>
      {cod && cod.length === 0 ? (
        null
      ) : (
        <div className={`${styles.section} ${darkMode ? styles.sectionDark : ""}`} name="CatchOfTheDay" ref={catchOfTheDayRef}>
          <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ""}`}>Catch Of The Day</h1>
          <div className={styles.carousel}>
            <Swiper
              navigation={{ prevEl, nextEl }}
              modules={[Navigation, Pagination, EffectFade]}
              slidesPerView={1}
              loop={true}
              pagination={{
                clickable: true,
              }}
              className={styles.myswiper}
            >
              {cod &&
                cod.map((image, index) => {
                  const remainingTime = remainingTimes && remainingTimes[index] ? remainingTimes[index] : 0;
                  if (remainingTime === 0) {
                    return null;
                  }
                  const hours = Math.floor(remainingTime / (1000 * 60 * 60));
                  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
                  const oldprice = image && image.pricing?.price;
                  const formattedOldPrice = typeof oldprice === 'number' ? Math.round(oldprice * 10) / 10 : '';
                  const newprice = image && image.discount?.newPrice;
                  const formattedNewPrice = typeof newprice === 'number' ? Math.round(newprice * 10) / 10 : '';
                  const handleClick = () => {
                    const id = image._id;
                    const isService = image.isService;
                    navigate(`/${isService ? "service" : "product"}/${image.title.replaceAll(" ", "-")}/${id}`, { state: { id, isService } })
                  };
                  const futureDate = new Date().getTime() + (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000);
                  return (
                    <SwiperSlide key={index} className={styles.swiperslide}>
                      {loading ? (
                        <img src='./graybg.png' alt="img" />
                      ) : (
                        <>
                          <div className={styles.imgcont}>
                            <LazyLoad height={504} offset={100}>

                              <img src={image.imageUrls[0]} alt="img" />

                            </LazyLoad>
                            <div className={styles.overlay} onClick={handleClick} ></div>
                          </div>
                          <h2 className={styles.product__title}>{image && image.title}</h2>
                          <div className={styles.view_details_container}>
                            <button onClick={handleClick} className={styles.view_details}>View Offer</button>
                          </div>
                          <div className={styles.time_container}>
                            <FlipClockCountdown renderMap={[false, true, true, true]} className={styles.flip_clock} to={futureDate} />
                          </div>
                          <div className={styles.price_container}>
                            <h3 className={`${styles.price} ${styles.old}`}>{formattedOldPrice}$</h3>
                            <AiOutlineArrowRight className={styles.arrow} />
                            <h3 className={`${styles.price} ${styles.new}`}>{formattedNewPrice}$</h3>
                          </div>
                        </>
                      )}

                    </SwiperSlide>
                  );
                })}
            </Swiper>
            <div className={styles.flex}>
              <div ref={(node) => setNextEl(node)} className={`${styles.btn} ${styles.left}`}>
                <AiOutlineArrowRight />
              </div>
              <div ref={(node) => setPrevEl(node)} className={`${styles.btn} ${styles.right}`}>
                <AiOutlineArrowLeft />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CatchOfTheDay;
