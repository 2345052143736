import axios from "axios";
import { url } from "../../common/constants/url";
import {
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  GET_ALL_PRODUCT_FAIL,
  GET_ALL_PRODUCT_REQUEST,
  GET_ALL_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_SUCCESS,
  GET_ALL_SEARCH_PRODUCT_FAIL,
  GET_ALL_SEARCH_PRODUCT_SUCCESS,
  GET_PRODUCT_BYSUBCAT_SUCCESS,
  GET_PRODUCT_BYSUBCAT_FAIL,
  GET_PRODUCT_BYSUBCAT_REQUEST,
  GET_PRODUCT_CAT_SUCCESS,
  GET_PRODUCT_CAT_REQUEST,
  GET_PRODUCT_CAT_FAIL,
  SEARCH_PRODUCT_SUCCESS,
  SEARCH_PRODUCT_FAIL,
  GET_ALL_FEATURED_PRODUCT_FAIL,
  GET_ALL_FEATURED_PRODUCT_SUCCESS,
  GET_ALL_FEATURED_PRODUCT_REQUEST,
  GET_ALL_PENDING_PRODUCT_FAIL,
  GET_ALL_PENDING_PRODUCT_SUCCESS,
  GET_ALL_CONFIRMED_PRODUCT_FAIL,
  GET_ALL_CONFIRMED_PRODUCT_SUCCESS,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  GET_RELATED_PRODUCT_SUCCESS,
  GET_RELATED_PRODUCT_FAIL,
  GET_ALL_BIDS_SUCCESS,
  GET_ALL_BIDS_FAIL,
  SEARCH_SERVICE_SUCCESS,
  SEARCH_SERVICE_FAIL,
  GET_SERVICE_BY_TYPE_SUCCESS,
  GET_SERVICE_BY_TYPE_FAIL,
  GET_SERVICE_BY_TYPE_REQUEST,
  SEARCH_ALL_SERVICE_SUCCESS,
  SEARCH_ALL_SERVICE_FAIL,
  GET_RELATED_SERVICE_SUCCESS,
  GET_RELATED_SERVICE_FAIL,
  SEARCH_PRODUCT_REQUEST,
  SEARCH_AUTOCOMPLETE_SUCCESS,
  SEARCH_AUTOCOMPLETE_FAIL,
  SEARCH_AUTOCOMPLETE_ADMIN_SUCCESS,
  SEARCH_AUTOCOMPLETE_ADMIN_FAIL
} from "../constants/productDetailsConstants";
export const getProdDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DETAILS_REQUEST,
    });
    const { data } = await axios.get(`${url}/products/${id}/getproductdetails`);
    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data.data[0],
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: message,
    });
  }
};
export const GetAllProduct = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/product`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      dispatch(GetAllProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetAllProductFail(error));
      // console.log(error);
    }
  };
};
export const GetAllProductSuccess = (Product) => {
  return {
    type: GET_ALL_PRODUCT_SUCCESS,
    payload: Product,
  };
};
const GetAllProductFail = (error) => ({
  type: GET_ALL_PRODUCT_FAIL,
  payload: error
});
export const DeleteProduct = (productId) => {
  return async (dispatch, getState) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${url}/product/${productId}`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      // Remove the deleted product from the store state
      const updatedProducts = getState().products.products.filter(product => product.id !== productId);
      dispatch(DeleteProductSuccess(updatedProducts));
    } catch (error) {
      dispatch(DeleteProductFail(error));
      // console.log(error)
    }
  }
}
export const DeleteProductSuccess = (updatedProducts) => {
  return {
    type: "DELETE_PRODUCT_SUCCESS",
    payload: updatedProducts,
  };
};
const DeleteProductFail = (error) => ({
  type: "DELETE_PRODUCT_FAIL",
  payload: error
});
export const DeleteProductUser = (productId) => {
  return async (dispatch, getState) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${url}/product/${productId}`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      // Remove the deleted product from the store state
      const updatedProducts = getState().products.products.filter(product => product.id !== productId);
      dispatch(DeleteProductSuccessUser(updatedProducts));
    } catch (error) {
      dispatch(DeleteProductFailUser(error));
      // console.log(error)
    }
  }
}
export const DeleteProductSuccessUser = (updatedProducts) => {
  return {
    type: "DELETE_PRODUCT_SUCCESS",
    payload: updatedProducts,
  };
};
const DeleteProductFailUser = (error) => ({
  type: "DELETE_PRODUCT_FAIL",
  payload: error
});
export const GetAllSearchProduct = ({ pageSize, page }) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      dispatch(GetAllSearchProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetAllSearchProductFail(error));
      // console.log(error);
    }
  };
};
export const GetAllSearchProductSuccess = (Product) => {
  return {
    type: GET_ALL_SEARCH_PRODUCT_SUCCESS,
    payload: Product,
  };
};
const GetAllSearchProductFail = (error) => ({
  type: GET_ALL_SEARCH_PRODUCT_FAIL,
  payload: error
});
export const GetAllPendingProduct = ({ pageSize, page, rawData }) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=`, rawData, {
        headers: {
          'Authorization': `${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(GetAllPendingProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetAllPendingProductFail(error));
      // console.log(error);
    }
  };
};
export const GetAllPendingProductSuccess = (Product) => {
  return {
    type: GET_ALL_PENDING_PRODUCT_SUCCESS,
    payload: Product,
  };
};
const GetAllPendingProductFail = (error) => ({
  type: GET_ALL_PENDING_PRODUCT_FAIL,
  payload: error
});
export const GetAllConfirmedProduct = ({ pageSize, page, rawData }) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=`, rawData, {
        headers: {
          'Authorization': `${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(GetAllConfirmedProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetAllConfirmedProductFail(error));
      // console.log(error);
    }
  };
};
export const GetAllConfirmedProductSuccess = (Product) => {
  return {
    type: GET_ALL_CONFIRMED_PRODUCT_SUCCESS,
    payload: Product,
  };
};
const GetAllConfirmedProductFail = (error) => ({
  type: GET_ALL_CONFIRMED_PRODUCT_FAIL,
  payload: error
});
export const GetProductBySubCat = ({ pageSize, page, SubCatId, rawData, sort }) => {
  return async (dispatch) => {
    if (rawData) {
      try {
        dispatch(GetProductBySubCatRequest());
        const token = localStorage.getItem('token')
        let response = {}
        if (token) {
          response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&subCategoryId=${SubCatId}&sort=${sort}`, rawData, {
            headers: {
              'Authorization': `${token}`,
              "Content-Type": "application/json",
            },
          });
        } else {
          response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&subCategoryId=${SubCatId}&sort=${sort}`, rawData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        }
        dispatch(GetProductBySubCatSuccess(response.data));
        return response.data;
      } catch (error) {
        dispatch(GetProductBySubCatFail(error));
        // console.log(error);
      }
    } else {
      try {
        dispatch(GetProductBySubCatRequest());
        const token = localStorage.getItem('token')
        let response = {}
        if (token) {
          response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&subCategoryId=${SubCatId}&sort=${sort}`, {
            headers: {
              'Authorization': `${token}`,
            },
          });
        } else {
          response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&subCategoryId=${SubCatId}&sort=${sort}`, {
          });
        }
        dispatch(GetProductBySubCatSuccess(response.data));
        return response.data;
      } catch (error) {
        dispatch(GetProductBySubCatFail(error));
        // console.log(error);
      }
    }
  };
};
export const GetProductBySubCatSuccess = (Product) => {
  return {
    type: GET_PRODUCT_BYSUBCAT_SUCCESS,
    payload: Product,
  };
};
const GetProductBySubCatFail = (error) => ({
  type: GET_PRODUCT_BYSUBCAT_FAIL,
  payload: error
});
export const GetProductBySubCatRequest = () => ({
  type: GET_PRODUCT_BYSUBCAT_REQUEST,
});
export const GetProductByCat = ({ pageSize, page, CatId, serviceCatId, sort, rawData }) => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    try {
      if (rawData) {
        if (serviceCatId) {
          if (token) {
            dispatch(GetProductByCatRequest());
            const response = await axios.post(
              `${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&categoryId=${serviceCatId}&sort=${sort}`,
              rawData,
              {
                headers: {
                  'Authorization': `${token}`,
                  'Content-Type': 'application/json'
                },
              }
            );
            dispatch(GetProductByCatSuccess(response.data));
            return response.data;
          } else {
            dispatch(GetProductByCatRequest());
            const response = await axios.post(
              `${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&categoryId=${serviceCatId}&sort=${sort}`,
              rawData,
              {
                headers: {
                  'Content-Type': 'application/json'
                },
              }
            );
            dispatch(GetProductByCatSuccess(response.data));
            return response.data;
          }
        } else {
          if (token) {
            dispatch(GetProductByCatRequest());
            const response = await axios.post(
              `${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&parentCategoryId=${CatId}&sort=${sort}`,
              rawData,
              {
                headers: {
                  'Authorization': `${token}`,
                  'Content-Type': 'application/json'
                },
              }
            );
            dispatch(GetProductByCatSuccess(response.data));
            return response.data;
          } else {
            dispatch(GetProductByCatRequest());
            const response = await axios.post(
              `${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&parentCategoryId=${CatId}&sort=${sort}`,
              rawData,
              {
                headers: {
                  'Content-Type': 'application/json'
                },
              }
            );
            dispatch(GetProductByCatSuccess(response.data));
            return response.data;
          }
        }
      } else {
        if (token) {
          dispatch(GetProductByCatRequest());
          const token = localStorage.getItem('token');
          const response = await axios.post(
            `${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&parentCategoryId=${CatId}&sort=${sort}`,
            {
              headers: {
                'Authorization': `${token}`,
              },
            }
          );
          dispatch(GetProductByCatSuccess(response.data));
          return response.data;
        } else {
          dispatch(GetProductByCatRequest());
          const token = localStorage.getItem('token');
          const response = await axios.post(
            `${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&parentCategoryId=${CatId}&sort=${sort}`,
            {
            }
          );
          dispatch(GetProductByCatSuccess(response.data));
          return response.data;
        }
      }
    } catch (error) {
      dispatch(GetProductByCatFail(error));
      // console.log(error);
    }
  };
};
export const GetProductByCatSuccess = (Product) => {
  return {
    type: GET_PRODUCT_CAT_SUCCESS,
    payload: Product,
  };
};
export const GetProductByCatRequest = () => ({
  type: GET_PRODUCT_CAT_REQUEST,
});
const GetProductByCatFail = (error) => ({
  type: GET_PRODUCT_CAT_FAIL,
  payload: error
});
export const SearchProduct = ({ pageSize, page, text, categoryId, subCategoryId, featured, rawData, sort }) => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    let response = {}
    try {
      dispatch(SearchProductRequest());
      if (text) {
        if (rawData) {
          let urlParams = `page=${page}&pageSize=${pageSize}&search=${text}`;
          if (subCategoryId) {
            urlParams += `&subCategoryId=${subCategoryId}`;
          } else if (categoryId) {
            urlParams += `&parentCategoryId=${categoryId}`;
          }
          if (featured) {
            urlParams += `&featured=${featured}`;
          } if (sort) {
            urlParams += `&sort=${sort}`
          }
          if (token) {
            response = await axios.post(`${url}/product/search/get?${urlParams}`, rawData, {
              headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
              },
            });
          } else {
            response = await axios.post(`${url}/product/search/get?${urlParams}`, rawData, {
              headers: {
                'Content-Type': 'application/json',
              }
            });
          }
          dispatch(SearchProductSuccess(response.data)); // Dispatch the SearchProductSuccess action with the products
          return response.data;
        } else {
          let urlParams = `page=${page}&pageSize=${pageSize}&search=${text}`;
          if (categoryId) {
            urlParams += `&parentCategoryId=${categoryId}`;
          }
          if (subCategoryId) {
            urlParams += `&subCategoryId=${subCategoryId}`;
          }
          if (featured) {
            urlParams += `&featured=${featured}`;
          }
          if (sort) {
            urlParams += `&sort=${sort}`
          }
          if (token) {
            response = await axios.post(`${url}/product/search/get?${urlParams}`, {
              headers: {
                'Authorization': token,
              },
            });
          } else {
            response = await axios.post(`${url}/product/search/get?${urlParams}`, {
            });
          }
          dispatch(SearchProductSuccess(response.data)); // Dispatch the SearchProductSuccess action with the products
          return response.data;
        }
      } else {
        let urlParams = `page=${page}&pageSize=${pageSize}&search=`;
        if (categoryId) {
          urlParams += `&parentCategoryId=${categoryId}`;
        }
        if (subCategoryId) {
          urlParams += `&subCategoryId=${subCategoryId}`;
        }
        if (featured) {
          urlParams += `&featured=${featured}`;
        }
        if (sort) {
          urlParams += `&sort=${sort}`
        }
        if (token) {
          if (rawData) {
            response = await axios.post(`${url}/product/search/get?${urlParams}`, rawData, {
              headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
              },
            });
          } else {
            response = await axios.post(`${url}/product/search/get?${urlParams}`, {
              headers: {
                'Authorization': token,
              },
            });
          }
        } else {
          if (rawData) {
            response = await axios.post(`${url}/product/search/get?${urlParams}`, rawData, {
              headers: {
                'Content-Type': 'application/json'
              },
            });
          } else {
            response = await axios.post(`${url}/product/search/get?${urlParams}`, {
            });
          }
        }
        dispatch(SearchProductSuccess(response.data)); // Dispatch the SearchProductSuccess action with the products
        return response.data;
      }
    } catch (error) {
      dispatch(SearchProductFail(error));
      // console.log(error);
    }
  };
};
export const SearchProductSuccess = (products) => {
  return {
    type: SEARCH_PRODUCT_SUCCESS,
    payload: products,
  };
};
export const SearchProductFail = (error) => ({
  type: SEARCH_PRODUCT_FAIL,
  payload: error,
});
export const SearchProductRequest = () => ({
  type: SEARCH_PRODUCT_REQUEST
})
export const SearchAction = ({ pageSize, page, text, categoryId, subCategoryId, featured }) => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    let response = {}
    try {
      let urlParams = `page=${page}&pageSize=${pageSize}&search=${text}`;
      if (categoryId) {
        urlParams += `&parentCategoryId=${categoryId}`;
      }
      if (subCategoryId) {
        urlParams += `&subCategoryId=${subCategoryId}`;
      }
      if (featured) {
        urlParams += `&featured=${featured}`;
      }
      if (token) {
        response = await axios.post(`${url}/product/search/get?${urlParams}`, {
          headers: {
            'Authorization': token,
          },
        });
      } else {
        response = await axios.post(`${url}/product/search/get?${urlParams}`, {
        });
      }
      dispatch(SearchSuccess(response.data.products)); // Dispatch the SearchProductSuccess action with the products
      return response.data;
    } catch (error) {
      dispatch(SearchFail(error));
      // console.log(error);
    }
  };
};
export const SearchSuccess = (products) => {
  return {
    type: SEARCH_SUCCESS,
    payload: products,
  };
};
export const SearchFail = (error) => ({
  type: SEARCH_FAIL,
  payload: error,
});
export const GetAllFeaturedProduct = ({ pageSize, page, sort, rawData }) => {
  return async (dispatch) => {
    try {
      dispatch(getAllFeaturedProductRequest());
      const token = localStorage.getItem('token');
      let response;
      if (token) {
        if (rawData) {
          response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&featured=true&search=&sort=${sort}`, rawData, {
            headers: {
              'Authorization': `${token}`,
              'Content-Type': 'application/json'
            },
          });
        } else {
          response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&featured=true&search=&sort=${sort}`, {
            headers: {
              'Authorization': `${token}`,
            },
          });
        }
      } else {
        if (rawData) {
          response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&featured=true&search=&sort=${sort}`, rawData, {
            headers: {
              'Content-Type': 'application/json'
            },
          });
        } else {
          response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&featured=true&search=&sort=${sort}`, {
          });
        }
      }
      dispatch(GetAllFeaturedProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetAllFeaturedProductFail(error));
      // console.log(error);
    }
  };
};
export const GetAllFeaturedProductSuccess = (Product) => {
  return {
    type: GET_ALL_FEATURED_PRODUCT_SUCCESS,
    payload: Product,
  };
};
const GetAllFeaturedProductFail = (error) => ({
  type: GET_ALL_FEATURED_PRODUCT_FAIL,
  payload: error
});
export const getAllFeaturedProductRequest = () => ({
  type: GET_ALL_FEATURED_PRODUCT_REQUEST,
});
//GET RELATED PRODUCT
export const GetRelatedProduct = ({ pageSize, page, categoryID }) => {
  return async (dispatch) => {
    try {
      dispatch(getAllFeaturedProductRequest());
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&subCategoryId=${categoryID}`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      dispatch(GetRelatedProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetRelatedProductFail(error));
      // console.log(error);
    }
  };
};
export const GetRelatedProductSuccess = (Product) => {
  return {
    type: GET_RELATED_PRODUCT_SUCCESS,
    payload: Product,
  };
};
const GetRelatedProductFail = (error) => ({
  type: GET_RELATED_PRODUCT_FAIL,
  payload: error
});
export const GetAllBids = ({ page, pageSize, sort, rawData }) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      if (token) {
        const response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&sort=${sort}`, rawData, {
          headers: {
            'authorization': `${token}`,
            "Content-Type": "application/json",
          },
        });
        dispatch(GetAllBidsSuccess(response.data));
        return response.data;
      } else {
        const response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&sort=${sort}`, rawData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        dispatch(GetAllBidsSuccess(response.data));
        return response.data;
      }
    } catch (error) {
      dispatch(GetAllBidsFail(error));
      // console.log(error)
    }
  };
};
export const GetAllBidsSuccess = (data) => ({
  type: GET_ALL_BIDS_SUCCESS,
  payload: data
});
const GetAllBidsFail = (error) => ({
  type: GET_ALL_BIDS_FAIL,
  payload: error
});
export const SearchActionService = ({ pageSize, page, text, categoryId, subCategoryId, featured }) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      if (text) {
        let urlParams = `page=${page}&pageSize=${pageSize}&search=${text}`;
        if (categoryId) {
          urlParams += `&parentCategoryId=${categoryId}`;
        }
        if (subCategoryId) {
          urlParams += `&subCategoryId=${subCategoryId}`;
        }
        if (featured) {
          urlParams += `&featured=${featured}`;
        }
        const response = await axios.post(`${url}/service/search/get?${urlParams}`, {
          headers: {
            'Authorization': token,
          },
        });
        dispatch(SearchServiceSuccess(response.data.products)); // Dispatch the SearchProductSuccess action with the products
        return response.data;
      } else {
        let urlParams = `page=${page}&pageSize=${pageSize}&search=`;
        if (categoryId) {
          urlParams += `&parentCategoryId=${categoryId}`;
        }
        if (subCategoryId) {
          urlParams += `&subCategoryId=${subCategoryId}`;
        }
        if (featured) {
          urlParams += `&featured=${featured}`;
        }
        const response = await axios.post(`${url}/service/search/get?${urlParams}`, {
          headers: {
            'Authorization': token,
          },
        });
        dispatch(SearchServiceSuccess(response.data.products)); // Dispatch the SearchProductSuccess action with the products
        return response.data;
      }
    } catch (error) {
      dispatch(SearchServiceFail(error));
      // console.log(error);
    }
  };
};
export const SearchServiceSuccess = (products) => {
  return {
    type: SEARCH_SERVICE_SUCCESS,
    payload: products,
  };
};
export const SearchServiceFail = (error) => ({
  type: SEARCH_SERVICE_FAIL,
  payload: error,
});
export const GetServicesByType = ({ pageSize, page, SubCatId, rawData, sort }) => {
  return async (dispatch) => {
    try {
      dispatch(GetServicesByTypeRequest());
      const token = localStorage.getItem('token')
      let response = {}
      if (token) {
        response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&search=&categoryId=${SubCatId}&sort=${sort}`, rawData, {
          headers: {
            'Authorization': `${token}`,
            "Content-Type": "application/json",
          },
        });
      } else {
        response = await axios.post(`${url}/product/search/get?page=${page}&pageSize=${pageSize}&&search=&categoryId=${SubCatId}&sort=${sort}`, rawData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }
      dispatch(GetServicesByTypeSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetServicesByTypeFail(error));
      // console.log(error);
    }
  };
};
export const GetServicesByTypeSuccess = (Product) => {
  return {
    type: GET_SERVICE_BY_TYPE_SUCCESS,
    payload: Product,
  };
};
const GetServicesByTypeFail = (error) => ({
  type: GET_SERVICE_BY_TYPE_FAIL,
  payload: error
});
export const GetServicesByTypeRequest = () => ({
  type: GET_SERVICE_BY_TYPE_REQUEST,
});
export const SearchAllService = ({ pageSize, page, text, categoryId, subCategoryId, featured, sort, rawData, type }) => {
  return async (dispatch) => {
    try {
      if (rawData) {
        const token = localStorage.getItem('token');
        if (text) {
          let urlParams = `page=${page}&pageSize=${pageSize}&search=${text}&sort=${sort}`;
          if (categoryId) {
            urlParams += `&categoryId=${categoryId}`;
          }
          if (subCategoryId) {
            urlParams += `&subCategoryId=${subCategoryId}`;
          }
          if (featured) {
            urlParams += `&featured=${featured}`;
          }
          if (type) {
            urlParams += `&typeOfService=${type}`;
          }
          const response = await axios.post(`${url}/product/search/get?${urlParams}`, rawData, {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json'
            },
          });
          dispatch(SearchAllServiceSuccess(response.data)); // Dispatch the SearchProductSuccess action with the products
          return response.data;
        } else {
          let urlParams = `page=${page}&pageSize=${pageSize}&search=&sort=${sort}`;
          if (categoryId) {
            urlParams += `&categoryId=${categoryId}`;
          }
          if (subCategoryId) {
            urlParams += `&subCategoryId=${subCategoryId}`;
          }
          if (featured) {
            urlParams += `&featured=${featured}`;
          }
          if (type) {
            urlParams += `&typeOfService=${type}`;
          }
          const response = await axios.post(`${url}/product/search/get?${urlParams}`, rawData, {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json'
            },
          });
          dispatch(SearchAllServiceSuccess(response.data)); // Dispatch the SearchProductSuccess action with the products
          return response.data;
        }
      } else {
        const token = localStorage.getItem('token');
        if (text) {
          let urlParams = `page=${page}&pageSize=${pageSize}&search=${text}&sort=${sort}`;
          if (categoryId) {
            urlParams += `&categoryId=${categoryId}`;
          }
          if (subCategoryId) {
            urlParams += `&subCategoryId=${subCategoryId}`;
          }
          if (featured) {
            urlParams += `&featured=${featured}`;
          }
          if (type) {
            urlParams += `&typeOfService=${type}`;
          }
          const response = await axios.post(`${url}/product/search/get?${urlParams}`, {
            headers: {
              'Authorization': token,
            },
          });
          dispatch(SearchAllServiceSuccess(response.data)); // Dispatch the SearchProductSuccess action with the products
          return response.data;
        } else {
          let urlParams = `page=${page}&pageSize=${pageSize}&search=&sort=${sort}`;
          if (categoryId) {
            urlParams += `&categoryId=${categoryId}`;
          }
          if (subCategoryId) {
            urlParams += `&subCategoryId=${subCategoryId}`;
          }
          if (featured) {
            urlParams += `&featured=${featured}`;
          }
          if (type) {
            urlParams += `&typeOfService=${type}`;
          }
          const response = await axios.post(`${url}/product/search/get?${urlParams}`, {
            headers: {
              'Authorization': token,
            },
          });
          dispatch(SearchAllServiceSuccess(response.data)); // Dispatch the SearchProductSuccess action with the products
          return response.data;
        }
      }
    } catch (error) {
      dispatch(SearchAllServiceFail(error));
      // console.log(error);
    }
  };
};
export const SearchAllServiceSuccess = (products) => {
  return {
    type: SEARCH_ALL_SERVICE_SUCCESS,
    payload: products,
  };
};
export const SearchAllServiceFail = (error) => ({
  type: SEARCH_ALL_SERVICE_FAIL,
  payload: error,
});
export const GetRelatedService = (typeId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/service/getRelated/byType/${typeId}`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetRelatedServiceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetRelatedServiceFail(error));
      // console.log(error);
    }
  };
};
export const GetRelatedServiceSuccess = (Product) => {
  return {
    type: GET_RELATED_SERVICE_SUCCESS,
    payload: Product,
  };
};
const GetRelatedServiceFail = (error) => ({
  type: GET_RELATED_SERVICE_FAIL,
  payload: error
});
export const SearchAutocomplete = ({ text, categoryId, subCategoryId, featured, rawData, type,ServiceCategory }) => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    let response = {}
    try {
      let urlParams = `search=${text}`;
      if (subCategoryId) {
        urlParams += `&subCategoryId=${subCategoryId}`;
      } else if (categoryId) {
        urlParams += `&parentCategoryId=${categoryId}`;
      }
      if (featured) {
        urlParams += `&featured=${featured}`;
      }
      if (type) {
        urlParams += `&typeOfService=${type}`;
      }
      if(ServiceCategory){
        urlParams+=`&categoryId=${ServiceCategory}`
      }
      if (token) {
        if (rawData) {
          response = await axios.post(`${url}/search/autocomplete?${urlParams}`, rawData, {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json'
            },
          });
        } else {
          response = await axios.post(`${url}/search/autocomplete?${urlParams}`, {
            headers: {
              'Authorization': token,
            },
          });
        }
      } else {
        if (rawData) {
          response = await axios.post(`${url}/search/autocomplete?${urlParams}`, rawData, {
            headers: {
              'Content-Type': 'application/json'
            },
          });
        } else {
          response = await axios.post(`${url}/search/autocomplete?${urlParams}`, {
          });
        }
      }
      dispatch(SearchAutocompleteSuccess(response.data.products)); // Dispatch the SearchProductSuccess action with the products
      return response.data;
    } catch (error) {
      dispatch(SearchAutocompleteFail(error));
      // console.log(error);
    }
  };
};
export const SearchAutocompleteSuccess = (products) => {
  return {
    type: SEARCH_AUTOCOMPLETE_SUCCESS,
    payload: products,
  };
};
export const SearchAutocompleteFail = (error) => ({
  type: SEARCH_AUTOCOMPLETE_FAIL,
  payload: error,
});


export const SearchCODAutocomplete = ({ text, categoryId, subCategoryId, featured, rawData, type,ServiceCategory }) => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    let response = {}
    try {
      let urlParams = `search=${text}`;
      if (subCategoryId) {
        urlParams += `&subCategoryId=${subCategoryId}`;
      } else if (categoryId) {
        urlParams += `&parentCategoryId=${categoryId}`;
      }
      if (featured) {
        urlParams += `&featured=${featured}`;
      }
      if (type) {
        urlParams += `&typeOfService=${type}`;
      }
      if(ServiceCategory){
        urlParams+=`&categoryId=${ServiceCategory}`
      }
      if (token) {
        if (rawData) {
          response = await axios.post(`${url}/search/autocomplete/admin?${urlParams}`, rawData, {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json'
            },
          });
        } else {
          response = await axios.post(`${url}/search/autocomplete/admin?${urlParams}`, {
            headers: {
              'Authorization': token,
            },
          });
        }
      } else {
        if (rawData) {
          response = await axios.post(`${url}/search/autocomplete/admin?${urlParams}`, rawData, {
            headers: {
              'Content-Type': 'application/json'
            },
          });
        } else {
          response = await axios.post(`${url}/search/autocomplete/admin?${urlParams}`, {
          });
        }
      }
      dispatch(SearchCODAutocompleteSuccess(response.data.products)); // Dispatch the SearchProductSuccess action with the products
      return response.data;
    } catch (error) {
      dispatch(SearchCODAutocompleteFail(error));
      // console.log(error);
    }
  };
};
export const SearchCODAutocompleteSuccess = (products) => {
  return {
    type: SEARCH_AUTOCOMPLETE_ADMIN_SUCCESS,
    payload: products,
  };
};
export const SearchCODAutocompleteFail = (error) => ({
  type: SEARCH_AUTOCOMPLETE_ADMIN_FAIL,
  payload: error,
});
