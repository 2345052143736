import {
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAIL
} from "../constants/NotificationConstants";
const initialState = {
    notification: [],
    error: null,
    loading: false,
};
export const GetNotification = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notification: action.payload,
                error: null,
                loading: false,
            };
        case GET_NOTIFICATIONS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
