import React from "react"
import styles from './Profileskel.module.scss'
const ProfileSkel=()=>{
return(
    <div>
  <div className={styles.profile__container}>
    <div
      // src={user && (user.image ? user.image : Profile)}
      alt="profile"
      className={styles.profile__picture}
    ></div>
    <div className={styles.details__container}>
      <div className={styles.name}>
        <div className={styles.rating}>
        </div>
      </div>
      {/* <p className={`email ${darkMode ? 'emailDark' : ''}`}>{user && user.email}</p> */}
      <div className="edit">
      </div>
    </div>
  </div>
</div>
)}
export default ProfileSkel;