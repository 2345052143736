import React, { useState, useEffect } from "react";
import styles from "./Card.module.scss";
import BMW from "../../../../assests/images/bmw.png";
import { MdLocationPin, MdTimer } from "react-icons/md";
import { ReactComponent as FilledHeart } from "../../../../assests/svg/Filled Heart.svg";
import { ReactComponent as New } from "../../../../assests/svg/New wideCard.svg";
import { timePosted } from "../../../../common/Utils";
import green from "../../../../assests/svg/green.svg";
import red from "../../../../assests/svg/red.svg";
import { useDispatch, useSelector } from "react-redux";
import { GetWishList, GetWishlistSuccess } from "../../../../store/actions/whishlistActions";
import { AddWishlist } from "../../../../store/actions/whishlistActions";
import { AiFillHeart, AiOutlineHeart, AiFillBell } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Card = ({ image, featured, price, name, date, condition, id, location }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [wishList, setWishList] = useState([])
  const { user } = useSelector((state) => state.userLogin);
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);
  useEffect(() => {
    dispatch(GetWishList())
      .then((data) => {
        dispatch(GetWishlistSuccess(data));
        setWishList(data.data.wishList)
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const whishlistArray = useSelector((state) => state.whishlistArray);
  const { whishlistID, error, loading } = whishlistArray;
  const isWishlist = Array.isArray(whishlistArray) && whishlistArray.includes(id);
  const removeFromWishList = useSelector((state) => state.removeFromWishList);
  const {
    success,
    error: errorRemove,
    loading: loadingRemove,
  } = removeFromWishList;
  let newTitle = name ? name.replaceAll(" ", "-") : '';
  const isProductInWishlist = wishList.some(product => product._id === id);
   const handleHeartClick = () => {
    if (loggedInUser) {
      if (isProductInWishlist) {
        setIsOpen(true);
      } else {
        const formData = new FormData();
        formData.append("productId", id);
        dispatch(AddWishlist(formData)).then(() => {
          setWishList([...wishList, { _id: id }]);
        });
      }
    } else {
      toast.error("Please login to add this product to your wishlist.");
      setIsOpenModal(true);
    }
  };
  return (
    <div className={`${styles.card_section} ${darkMode? styles.card_sectionDark:''}`}>
      <div className={styles.image_container}>
        <img src={image} alt="product" className={styles.img} />
        <div className={styles.featured_containerr}>
          <p className={styles.featured_textt}>Featured</p>
        </div>
      </div>
      <div className={styles.details_containerr}>
        <p  className={`${styles.pricee} ${darkMode? styles.priceeDark:''}`}>${price}</p>
        <p  className={`${styles.titlee} ${darkMode? styles.titleeDark:''}`}>{name.length > 20 ? name.substring(0, 20) + '...' : name}</p>
        <p  className={`${styles.locationn} ${darkMode? styles.locationnDark:''}`}>
          <MdLocationPin className={styles.icon} />
          {location}
        </p>
        <p  className={`${styles.timee} ${darkMode? styles.timeeDark:''}`}>
          <MdTimer className={styles.icon} />
          {timePosted(date)} ago
        </p>
        {isProductInWishlist ? (
          <AiFillHeart className={styles.heart_icon}
            onClick={() =>
              handleHeartClick()} />
        ) : (
          <AiOutlineHeart className={styles.heart_icon} onClick={handleHeartClick} />
        )}
        {condition ? (
          <div className={styles.condition_containerr}>
            <p className={styles.conditionn}>New</p>
            <img src={green} alt="new" className={styles.condition_image} />
          </div>
        ) : (
          <div className={styles.condition_containerr}>
            <p className={styles.conditionn}>Used</p>
            <img src={red} alt="used" className={styles.condition_image} />
          </div>
        )}
      </div>
    </div>
  );
};
export default Card;
