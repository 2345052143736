export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAIL = "CREATE_CUSTOMER_FAIL";
export const CREATE_CUSTOMER_RESET = "CREATE_CUSTOMER_RESET";
export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAIL = "GET_CUSTOMER_FAIL";
export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL";
export const DEFAULT_CUSTOMER_SUCCESS = "DEFAULT_CUSTOMER_SUCCESS";
export const DEFAULT_CUSTOMER_FAIL = "DEFAULT_CUSTOMER_FAIL";
export const CREATE_CARD_REQUEST = "CREATE_CARD_REQUEST";
export const CREATE_CARD_SUCCESS = "CREATE_CARD_SUCCESS";
export const CREATE_CARD_FAIL = "CREATE_CARD_FAIL";
export const CREATE_CARD_RESET = "CREATE_CARD_RESET";
export const ADD_CARD_TO_DB_REQUEST = "ADD_CARD_TO_DB_REQUEST";
export const ADD_CARD_TO_DB_SUCCESS = "ADD_CARD_TO_DB_SUCCESS";
export const ADD_CARD_TO_DB_FAIL = "ADD_CARD_TO_DB_FAIL";
export const ADD_CARD_TO_DB_RESET = "ADD_CARD_TO_DB_RESET";
export const ADD_CARD_TO_CUSTOMER_REQUEST = "ADD_CARD_TO_CUSTOMER_REQUEST";
export const ADD_CARD_TO_CUSTOMER_SUCCESS = "ADD_CARD_TO_CUSTOMER_SUCCESS";
export const ADD_CARD_TO_CUSTOMER_FAIL = "ADD_CARD_TO_CUSTOMER_FAIL";
export const ADD_CARD_TO_CUSTOMER_RESET = "ADD_CARD_TO_CUSTOMER_RESET";
export const CARDS_LIST_REQUEST = "CARDS_LIST_REQUEST";
export const CARDS_LIST_SUCCESS = "CARDS_LIST_SUCCESS";
export const CARDS_LIST_FAIL = "CARDS_LIST_FAIL";
export const CARDS_LIST_RESET = "CARDS_LIST_RESET";
// DEFAULT PAYMENT
export const DEFAULT_CARD_REQUEST = "DEFAULT_CARD_REQUEST";
export const DEFAULT_CARD_SUCCESS = "DEFAULT_CARD_SUCCESS";
export const DEFAULT_CARD_FAIL = "DEFAULT_CARD_FAIL";
export const DEFAULT_CARD_RESET = "DEFAULT_CARD_RESET";
export const UPDATE_DEFAULT_CARD_REQUEST = "UPDATE_DEFAULT_CARD_REQUEST";
export const UPDATE_DEFAULT_CARD_SUCCESS = "UPDATE_DEFAULT_CARD_SUCCESS";
export const UPDATE_DEFAULT_CARD_FAIL = "UPDATE_DEFAULT_CARD_FAIL";
export const UPDATE_DEFAULT_CARD_RESET = "UPDATE_DEFAULT_CARD_RESET";
