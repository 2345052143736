import React, { useState, useEffect } from "react";
import styles from "./DeleteAccountPage.module.scss";
import Layout from "../../common/components/Layouts/Layout";
import ConfirmModal from "../../common/components/ConfirmModal/ConfirmModal";
import DeleteModal from "./DeleteModal";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
const DeleteAccountPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const pageName = "Delete Account"; // Replace this with the actual page name
    const pageUrl = "/user/delete-account"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenmd, setIsOpenmd] = useState(false)
  const handlemd = () => {
    setIsOpenmd(true);
    setIsOpen(false)
  };
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <>
      <Helmet>
        <title>Delete Account</title>
        <meta name='Delete Account' content='Delete Account' />
      </Helmet>
      <DeleteModal
        open={isOpenmd}
        onClose={() => setIsOpenmd(false)} />
      <Layout title={"Delete Account"}>
        <div className={styles.container}>
          <div className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>
            <h5>Are you sure you want to delete your account?</h5>
          </div>
          <div className={`${styles.info} ${darkMode ? styles.infoDark : ''}`}>
            <p>
              1. Deleting your account is permanent and irreversible.
            </p>
            <p>
              2. Any Products or services you uploaded will be set to inactive upon deleting your account.
            </p>
            <p>
              3. All personal data, including payment details and messages, will be permanently removed.
            </p>
            <p>
              4. You transaction history,purchases, sales, and reviews will no longer be accessible.
            </p>
            <p>
              5. Deleted accounts cannot be reactivated or recovered.
            </p>
          </div>
          <div className={styles.btnCont}>
            <button onClick={() => setIsOpen(true)} className={styles.btn}>Delete Account</button>
          </div>
        </div>
        <ConfirmModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <h1 className={`${styles.confirm_title} ${darkMode ? styles.confirm_titleDark : ''}`}>Confirmation</h1>
          <p className={`${styles.confirm_message} ${darkMode ? styles.confirm_messageDark : ''}`}>
            Are you sure you want to <span>delete</span> your account?
          </p>
          <div className={styles.buttons_container}>
            <button className={styles.cancel}
              onClick={handlemd}
            >
              Delete
            </button>
            <button
              className={styles.confirm}
              onClick={() => setIsOpen(false)}
            >
              Keep
            </button>
          </div>
        </ConfirmModal>
      </Layout>
    </>
  )
}
export default DeleteAccountPage;