import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPhoneNumber, PhoneAuthProvider, getIdToken, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging, onMessage, isSupported, getToken } from "firebase/messaging";
import { getDatabase, ref, push, set } from "firebase/database";
import { toast } from "react-toastify";
const firebaseConfig = {
  apiKey: "AIzaSyC3pdaXdaIXd4ukXAoR8yL3tXjdQZqP7II",
  authDomain: "la7i27alak-chat-a7970.firebaseapp.com",
  databaseURL: "https://la7i27alak-chat-a7970-default-rtdb.firebaseio.com",
  projectId: "la7i27alak-chat-a7970",
  storageBucket: "la7i27alak-chat-a7970.appspot.com",
  messagingSenderId: "92663080404",
  appId: "1:92663080404:web:77149cc763ac01504d6241",
  measurementId: "G-FTHTPHEH2Y"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
const db = getDatabase(app);
export { auth, db, storage, messaging, app, ref, push, signInWithPhoneNumber, PhoneAuthProvider };
export const onForegroundMessage = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
export const initializeMessaging = async () => {
  if (localStorage.getItem('uid')) {
    if (!isSupported()) {
      // console.log('Notifications are not supported in this browser.');
      return;
    }
    try {
      const messaging = getMessaging(app);
      const currentToken = await getToken(messaging, { vapidKey: 'BExWqY3AWRQPXdBAVUQ7U_BIQKa0eLnCoX1GTni6ku4AjnW6gdFpxCqQ-_1yHit1H2TeOq3qj-uQfOFuI8_633M' });
      if (currentToken) {
        const fcmTokenRef = ref(db, `fcmTokens/${localStorage.getItem('uid')}`);
        await set(fcmTokenRef, currentToken);
      } else {
        // console.log('No registration token available. Request permission to generate one.');
      }
    } catch (err) {
      // console.log('An error occurred while retrieving token:', err);
    }
  } else {
    // console.log('uid not found in localStorage. Not requesting permission.');
  }
};
async function refreshToken() {
  try {
    const user = auth.currentUser;
    const uid = localStorage.getItem('uid')
    if (uid) {
      if (user) {
        const token = await getIdToken(user, true);
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
    } else {
     // console.log("erro")
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
  }
}
onAuthStateChanged(auth, (user) => {
  if (user) {
    refreshToken();
  } else {
    localStorage.removeItem('token');
  }
});
setInterval(refreshToken, 30 * 60 * 1000);
export default app;
