import {
    RATE_EXPERIENCE_FAIL,
    RATE_EXPERIENCE_SUCCESS,
    RATE_EXPERIENCE_REQUEST
} from "../constants/userConstants";
const initialState = {
    rate: [],
    error: null,
    loading: false,
};
export const RateExperienceReducer = (state = initialState, action) => {
    switch (action.type) {
        case RATE_EXPERIENCE_REQUEST:
            return {
                ...state,
                rate: action.payload,
                error: null,
                loading: true,
            };
        case RATE_EXPERIENCE_SUCCESS:
            return {
                ...state,
                rate: action.payload,
                error: null,
                loading: false,
            };
        case RATE_EXPERIENCE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};