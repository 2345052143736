import React, { useState, useEffect, useRef } from "react";
import styles from "./AddressesList.module.scss";
import { useNavigate } from "react-router-dom";
import AddressComponent from "../AddressComponent/AddressComponent";
import { getAddressByUser, getAddressByUserSuccess } from "../../../store/actions/addAddressActions";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
const AddressesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addresses, setAddresses] = useState([]);
  const address = useSelector((state) => state.address?.address);
  const DefaultAddress = useSelector((state) => state.DefaultAddress?.DefaultAddress?.data?.address?.isDefault);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  useEffect(() => {
    dispatch(getAddressByUser());
  }, [dispatch]);
  return (
    <>
     <Helmet>
      <title>Address Page</title>
    </Helmet>
    <div className={styles.container}>
      <ul className={styles.list}>
        {address &&
          [
            ...address.filter((addr) => addr.isDefault),
            ...address.filter((addr) => !addr.isDefault),
          ].map((addr) => (
            <AddressComponent key={addr._id} defaultAddress={addr.isDefault} title={addr.title} description={addr.description} id={addr._id} />
          ))}
      </ul>
      <button
        className={`${styles.add_btn} ${darkMode? styles.add_btnDark:''}`}
        onClick={() => navigate("/user/addresses/add-address")}
      >
        Add Addresses
      </button>
    </div>
    </>
  );
};
export default AddressesList;