import React, { useState, useEffect } from "react";
import Layout from "../../common/components/Layouts/Layout";
import UserCard from "./UserCard/UserCard";
import img from '../../assests/images/PS5 1.png'
import { GetBlockedUsers } from "../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import Img from "../../assests/svg/order history empty.svg";
import { useNavigate } from "react-router-dom";
import styles from './BlockedAccount.module.scss'
import { Helmet } from "react-helmet";
const BlockedAccount = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    const navigate=useNavigate();
    useEffect(() => {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Blocked account"; // Replace this with the actual page name
      const pageUrl = "/user/blocked-accounts"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }, []);
    const blockedUsers = useSelector((state) => state.blockedUsers?.blockedUsers?.blockedUsers?.blockedUsers);
    useEffect(() => {
        dispatch(GetBlockedUsers());
    }, [dispatch]);
    return (
      <>
      <Helmet>
        <title>Blocked Accounts</title>
        <meta name='Blocked Accounts' content="Blocked Accounts" />
      </Helmet>
        <Layout title={'Blocked Accounts'}>
            <div>
            {blockedUsers && blockedUsers.length > 0 ? (
  blockedUsers.map((user) => (
    <UserCard
      key={user._id}
      id={user._id}
      name={user.username}
      image={user.imageUrl}
    />
  ))
) : (
    <div className={styles.empty}>
    <img src={Img} alt='empty'/>
   <h2 className={`${styles.txt} ${darkMode? styles.txtDark:''}`}>No Blocked Accounts.</h2>
   <button className={`${styles.btn} ${darkMode? styles.btnDark:''}`} onClick={() => navigate("/")}>
      Go Shopping
    </button>
  </div>
)}
            </div>
        </Layout>
        </>
    );
};
export default BlockedAccount;
