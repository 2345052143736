import React, { useState, useRef, useEffect, useCallback } from "react";
import styles from "./Requestaccepted.module.scss";
import UserContainer from "../../ProductDescriptionPage/components/UserContainer/UserContainer";
import img from "../../../assests/images/map.png";
import { cancelWaitingListHandler } from "../../../store/actions/waitingListActions";
import { useDispatch, useSelector } from "react-redux";
import { IF_ANY_USER_ACCEPTED_RESET } from "../../../store/constants/waitingListConstants";
import Layout from "../../../common/components/Layouts/Layout";
import { GoogleMap, useLoadScript, MarkerF, Marker, InfoBox, InfoWindow, InfoWindowF } from "@react-google-maps/api";
import { useLocation, useNavigate } from "react-router-dom";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { GetLastSeen } from "../../../store/actions/waitingListActions";
import { io } from "socket.io-client";
import Loader from "../../../common/components/Loader/Loader";
const libraries = ["places"];
const RequestAcceptedPage = ({ userId, productId }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [sure, setSure] = useState(true);
  useEffect(() => {
    const pageName = "Track Seller"; // Replace this with the actual page name
    const pageUrl = "/user/waiting-list/track-seller"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const [selected, setSelected] = useState(null);
  const [sellerCoordinates, setSellerCoordinates] = useState([])
  const [buyerCoordinates, setBuyerCoordinates] = useState([])
  const [user, setUser] = useState([])
  const offerId = location.state?.id
  const mapContainer = useRef(null);
  const navigate = useNavigate()
  const buyerCoordinatess = { lat: 37.456, lng: -122.789 };
  const clickHanlder = () => {
    dispatch(cancelWaitingListHandler(productId, userId));
    dispatch({ type: IF_ANY_USER_ACCEPTED_RESET });
    navigate(-1)
  };
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const buyer = true
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [center, setCenter] = useState({ lat: 33.8547, lng: 35.8623 });
  const id = location.state?.id
  const lastSeen = useSelector((state) => state.lastSeen?.lastSeen)
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(GetLastSeen(id))
    }
    fetchData();
  }, [dispatch]);
  const seller = {
    lat: Number(lastSeen && lastSeen.sellerLastKnown?.lat),
    lng: Number(lastSeen && lastSeen.sellerLastKnown?.long),
  };
  const coorBuyer = {
    lat: Number(lastSeen && lastSeen.buyerLastKnown?.lat),
    lng: Number(lastSeen && lastSeen.buyerLastKnown?.long),
  };
  useEffect(() => {
    const fetchLocation = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const socket = io('https://backend.la7i27alak.com/', {
          transports: ['websocket'],
          query: {
            type: 'liveTracking',
            offerId: offerId,
            authorization: token,
          },
        });
        socket.on('connect', () => { });
        socket.on('liveCoordinates', data => {
          const coordinates = { lat: parseFloat(data.lat), lng: parseFloat(data.long) };
          if (data.isSeller) {
            setSellerCoordinates(coordinates);
            setUser(data.user)
          } else {
            setBuyerCoordinates(coordinates);
          }
          if (data?.status === "sold") {
            navigate("/user/order-history")
          }
        });
        socket.on('connect_error', err => { });
        return () => {
          socket.disconnect();
        };
      }
    };
    fetchLocation();
  }, []);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {
        lat: () => 43.6532,
        lng: () => -79.3832,
      },
      radius: 200 * 1000,
    },
  });
  const onMapClick = (event) => {
    setCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };
  const [isSellerInfoWindowOpen, setIsSellerInfoWindowOpen] = useState(false);
  const [isBuyerInfoWindowOpen, setIsBuyerInfoWindowOpen] = useState(false);
  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCLlu4lwcE0fWQFAOf50EUql1LwAmNRaPk",
    libraries,
  }); const display = () => {
    if (!isLoaded) return <div>Loading...</div>;
    return (
      <GoogleMap
        zoom={10}
        center={(sellerCoordinates && sellerCoordinates.lenght === 0) || (buyerCoordinates && buyerCoordinates.length === 0) ? seller : sellerCoordinates}
        mapContainerClassName={styles.map}
        onLoad={onMapLoad}
        onClick={onMapClick}
      >
        <MarkerF
          position={(sellerCoordinates && sellerCoordinates.lenght === 0) || (buyerCoordinates && buyerCoordinates.length === 0) ? seller : sellerCoordinates}
          onClick={() => { setIsSellerInfoWindowOpen(true) }}
          draggable={false}
          onDragEnd={onMapClick}
        />
        <MarkerF
          position={(sellerCoordinates && sellerCoordinates.lenght === 0) || (buyerCoordinates && buyerCoordinates.length === 0) ? coorBuyer : buyerCoordinates}
          onClick={() => { setIsBuyerInfoWindowOpen(true) }}
          draggable={false}
          onDragEnd={onMapClick}
        />
        {isSellerInfoWindowOpen && (
          <InfoWindow
            position={(sellerCoordinates && sellerCoordinates.lenght === 0) || (buyerCoordinates && buyerCoordinates.length === 0) ? seller : sellerCoordinates}
            onCloseClick={() => setIsSellerInfoWindowOpen(false)}
            zIndex={1}
          >
            <div style={{ width: '45px', height: '20px' }}>
              <h6 style={{ color: '#0298a6' }}>Seller</h6>
            </div>
          </InfoWindow>
        )}
        {isBuyerInfoWindowOpen && (
          <InfoWindow
            position={(sellerCoordinates && sellerCoordinates.lenght === 0) || (buyerCoordinates && buyerCoordinates.length === 0) ? coorBuyer : buyerCoordinates}
            onCloseClick={() => setIsBuyerInfoWindowOpen(false)}
            zIndex={1}
          >
            <div style={{ width: '45px', height: '20px' }}>
              <h6 style={{ color: '#0298a6' }}>Buyer</h6>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  };
  return (
    <Layout>
      <div className={styles.container}>
        <div className={`${styles.wrapper} ${darkMode ? styles.wrapperDark : ''}`}>
          {buyer ? (
            <>
              <h3 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>
                <span>Congratulations!</span> You've been chosen to get this product
                from:
              </h3>
              {user.length === 0 ? (
                <Loader />
              ) : (
                <UserContainer
                  image={user.imageUrl}
                  name={user.username}
                  rate={user.reviews?.average}
                  date={user.createdAt}
                  id={user && user._id}
                />
              )}

              <div className={styles.line}></div>
              <h3 className={`${styles.subtitle} ${darkMode ? styles.subtitleDark : ''}`}>
                The Seller is waiting for you, get to them as soon as possible.
              </h3>
              <div className={styles.mapContainer}>
                {display()}
              </div>
              <p className={styles.text}>
                If the seller sees no progress regarding your location the deal will
                be canceled, which would affect your overall rating.
                {/* {sure && <span> and a % percentage will be deducted from you.</span>} */}
              </p>
            </>
          ) : (
            <>
              <h3 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>
                Buyer is now on there way to you
              </h3>
              <UserContainer
                image={''}
                name={'jp'}
                rate={4}
                date={'121212'}
              // id={productDetails.offer[0].uid}
              />
              <div className={styles.line}></div>
              <h3 className={`${styles.subtitle} ${darkMode ? styles.subtitleDark : ''}`}>
                Track there progress       </h3>
              <div className={styles.mapContainer}>
                {display()}
              </div>
              <p className={styles.text}>
                If the Buyer takes too long, or doesn't show up you can choose another person from the waiting list
              </p>
            </>
          )}
          <button className={`${styles.cancel} ${darkMode ? styles.cancelDark : ''}`} onClick={clickHanlder}>
            Cancel
          </button>
        </div>
      </div>
    </Layout>
  );
};
export default RequestAcceptedPage;
