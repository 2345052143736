import React, { useState, useEffect, useRef } from "react";
import DownloadTheApp from "./components/Download The App/DownloadTheApp";
import Categories from "./components/Categories/Categories";
import Carousel from "./components/Carousel/Carousel";
import Search from "./components/Search/Search";
import CatchOfTheDay from "./components/Cath of the Day/CatchOfTheDay";
import Header from "../../common/components/Header/Header";
import Footer from "../../common/components/Footer/Footer";
import FeaturedProduct from "./components/FeaturedProduct/FeaturedProduct";
import RecommendedForYou from "./components/RecommendedForYou/RecommendedForYou";
import { useDispatch } from "react-redux";
import { handleFilterIcon } from "../../store/actions/filterActions";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import OfferedService from "./components/Offered Service/OfferedService";
import RequestedServices from "./components/Requested Services/RequestedServices";
import Error404 from "../../common/components/Error404/Error404";
import { GetServicesWishlist } from "../../store/actions/servicesActions";
import { GetWishList } from "../../store/actions/whishlistActions";
import { styles } from "dom7";

const HomePage = () => {
  const dispatch = useDispatch();
 

  useEffect(() => {
    const pageName = "Home";
    const pageUrl = "/";
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);

  useEffect(() => {
    dispatch(handleFilterIcon(false));
  }, [dispatch]);

  const { user } = useSelector((state) => state.userLogin);
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);

  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);

  const ServicesWishlist = useSelector((state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList)
  const wishList = useSelector((state) => state.Wishlist?.Wishlist?.data?.wishList)

  useEffect(() => {
    dispatch(GetServicesWishlist())
    dispatch(GetWishList())
  }, [dispatch]);

 

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name="Home" content="This is Home Page" />
        <meta httpEquiv="Cache-Control" content="max-age=31536000" />
        <meta
          name="keywords"
          content={`La7i27alak Home, Home, Homepage la7i27alak, HomePage, Home Page, La7i27alak, la7i27alak, all products, catch of the day, Catch of the day La7i27alak, catch of the day la7i27alak`}
        />
      </Helmet>
      <Header />
      <Carousel />
      <Search />
      <Categories />
      <section id='cod'>
      <CatchOfTheDay  />
      </section>
      <FeaturedProduct ServicesWishlist={ServicesWishlist} wishList={wishList} />
      <div style={{ borderTop: '1px solid #ccc' }}></div>
      <OfferedService ServicesWishlist={ServicesWishlist} wishList={wishList} />
      <div style={{ borderTop: '1px solid #ccc' }}></div>
      <RequestedServices ServicesWishlist={ServicesWishlist} wishList={wishList} />
      <div style={{ borderTop: '1px solid #ccc' }}></div>
      {loggedInUser ? <RecommendedForYou ServicesWishlist={ServicesWishlist} wishList={wishList} /> : null}
      <DownloadTheApp />
      <Footer />
    </>
  );
};

export default HomePage;
