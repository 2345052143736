import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import Cards from "../../../../common/components/Cards/Cards";
import { useDispatch, useSelector } from "react-redux";
import { removeFromWishlist } from "../../../../store/actions/whishlistActions";
import EmptyWishList from "../EmptyWishlist/EmptyWishList";
import WishListCard from "../../../../common/components/Cards/WishListCard";
const NotEmptyWishList = ({ whishlist, WishListService }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [all, setAll] = useState(true);
  const [offers, setOffers] = useState(true);
  const [bids, setBids] = useState(false);
  const [services, setServices] = useState(false);
  const [myWishlist, setMyWishlist] = useState(whishlist);
  const activateOffers = () => {
    setAll(false);
    setOffers(true);
    setBids(false);
    setServices(false);
    setMyWishlist(whishlist);
  };
  const activateBids = () => {
    setAll(false);
    setOffers(false);
    setBids(true);
    setServices(false);
    setMyWishlist(
      whishlist && whishlist.filter(function (el) {
        return el.bidding?.enabled === true;
      })
    );
  };
  const activateServices = () => {
    setAll(false);
    setOffers(false);
    setBids(false);
    setServices(true);
    setMyWishlist(
      WishListService && WishListService.filter(function (el) {
        return el.isService === true;
      })
    );
  };
  return (
    <div className={styles.container}>
      <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Wishlist</h1>
      <ul className={styles.category__container}>
        <li
          className={`${styles.category__item} ${offers ? styles.active : ""} ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ''}`}
          onClick={activateOffers}
        >
          Products {/* type = product and bid = 0 */}
        </li>
        <li
          className={`${styles.category__item} ${bids ? styles.active : ""} ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ''}`}
          onClick={activateBids}
        >
          Bids
        </li>
        <li
          className={`${styles.category__item} ${services ? styles.active : ""
            } ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ''}`}
          onClick={activateServices}
        >
          Services
        </li>
      </ul>
      <div className={styles.cards__container}>
        {myWishlist && myWishlist.length === 0 ? (
          <EmptyWishList />
        ) : (
          myWishlist && myWishlist.map((w) => (
            <WishListCard
              key={w._id}
              title={w.title}
              price={w.pricing.price}
              mainimage={w.imageUrls[0]}
              id={w._id}
              featured={w.featured}
              time={w.createdAt}
              start={w.startdatetimer}
              location={w.pickUpAddress?.region || w.serviceAddress?.region}
              isService={w.isService}
              old={w.condition}
              discounted={w.discount?.enabled}
              percentage={w.discount?.percentage}
            />
          ))
        )}
      </div>
    </div>
  );
};
export default NotEmptyWishList;
