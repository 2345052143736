import {
  ADD_SERVICE_REQUEST,
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAIL,
  ADD_SERVICE_RESET,
  UPLOAD_IMAGES_SERVICE_FAIL,
  UPLOAD_IMAGES_SERVICE_REQUEST,
  UPLOAD_IMAGES_SERVICE_RESET,
  UPLOAD_IMAGES_SERVICE_SUCCESS,
  UPLOAD_MAIN_IMAGE_SERVICE_FAIL,
  UPLOAD_MAIN_IMAGE_SERVICE_REQUEST,
  UPLOAD_MAIN_IMAGE_SERVICE_RESET,
  UPLOAD_MAIN_IMAGE_SERVICE_SUCCESS,
} from "../constants/addServiceContants";
export const addServiceReducer = (
  state = { success: false, loading: false, error: null, id: null },
  action
) => {
  switch (action.type) {
    case ADD_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        id: action.payload,
      };
    case ADD_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_SERVICE_FAIL:
      return {
        success: false,
        loading: false,
        error: null,
        id: null,
      };
    case ADD_SERVICE_RESET:
      return {
        success: false,
        loading: false,
        error: null,
        id: null,
      };
    default:
      return state;
  }
};
export const uploadMainImageServiceReducer = (
  state = { success: false, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case UPLOAD_MAIN_IMAGE_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_MAIN_IMAGE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case UPLOAD_MAIN_IMAGE_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_MAIN_IMAGE_SERVICE_RESET:
      return {
        success: false,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
export const uploadImagesServiceReducer = (
  state = { success: false, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case UPLOAD_IMAGES_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_IMAGES_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case UPLOAD_IMAGES_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_IMAGES_SERVICE_RESET:
      return {
        success: false,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
