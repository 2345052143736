import React, { useEffect, useState } from "react";
import Layout from "../../common/components/Layouts/Layout";
import bg from "../../assests/svg/empty chat.svg";
import styles from "./AdminChatPage.module.scss"
import nochat from '../../assests/svg/undraw_account_re_o7id 1.svg'
import { BiArrowBack } from "react-icons/bi";
import Adminchats from "./Adminchats/Adminchats";
import AdminSearchChat from "./Adminchats/AdminSearchChat/AdminSearchChat";
import AdminChatHeader from "./Adminchats/AdminchatHeader/AdminchatHeader";
import AdminChatBody from "./Adminchats/AdminBody/AdminchatBody";
import AdminChatInput from "./Adminchats/Admininput/AdminChatInput";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import { db, ref } from "../../firebase";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Navigate, useNavigate } from "react-router-dom";
import { timePosted } from "../../common/Utils";
import { set } from 'firebase/database';
import { Helmet } from "react-helmet";
import { onValue } from 'firebase/database';
const AdminChatPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [lastFriendMessage, setLastFriendMessage] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesdb, setMessagesdb] = useState([])
  const [ConvoRoomID, setConvoRoomId] = useState("");
  const [users, setUsers] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const specificUserId = localStorage.getItem('uid')
  const addMessage = (newMessage) => {
    setMessages([...messages, newMessage]);
  };
  const [selectedUser, setSelectedUser] = useState(null);
  const [leftHidden, setLeftHidden] = useState(false);
  const handleGoBack = () => {
    setShowChat(false);
    setLeftHidden(false);
  };
  const [isCardVisible, setIsCardVisible] = useState(true);
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };
  useEffect(() => {
    const chatListRef = ref(db, 'chatlist/' + specificUserId);
    const unsubscribe = onValue(chatListRef, (snapshot) => {
      const chatListData = snapshot.val();
      const userIDs = chatListData ? Object.keys(chatListData) : [];
 
      const fetchUserProfiles = async () => {
        const userProfilePromises = userIDs.map((uid) =>
          new Promise((resolve, reject) => {
            const userRef = ref(db, 'chatlist/' + specificUserId + '/' + uid);
            onValue(userRef, (snapshot) => {
              const userData = snapshot.val();
              resolve(userData);
            }, (error) => {
              reject(error);
            });
          })
        );
        try {
          const userProfilesData = await Promise.all(userProfilePromises);
          // Sort user profiles based on the timePosted
          const sortedUserProfiles = userProfilesData.sort((a, b) => {
            const timeA = timePosted(a.sendTime);
            const timeB = timePosted(b.sendTime);
            if (timeA < timeB) return -1;
            if (timeA > timeB) return 1;
            return 0;
          });
          setUserProfiles(sortedUserProfiles);
        } catch (error) {
          // console.log('Error fetching user data:', error);
        }
      };
      fetchUserProfiles();
    });
    return () => {
      unsubscribe();
    };
  }, []);
  const handleChatClick = (user) => {
    setConvoRoomId(user.roomId);
    setSelectedUser(user);
    setShowChat(true);
    setLeftHidden(true);
    const chatListMessages = ref(db, 'messages/' + user.roomId);
    const unsubscribe = onValue(chatListMessages, (snapshot) => {
      const chatListData = snapshot.val();
      const userIDs = chatListData ? Object.keys(chatListData) : [];
      const userIDsGlobal = userIDs;
      const fetchMessages = async () => {
        const messagesPromises = userIDsGlobal.map((uid) =>
          new Promise((resolve, reject) => {
            const userRef = ref(db, 'messages/' + user.roomId + '/' + uid);
            onValue(userRef, (snapshot) => {
              const userData = snapshot.val();
              resolve(userData);
            }, (error) => {
              reject(error);
            });
          })
        );
        try {
          const messagesData = await Promise.all(messagesPromises);
          setMessagesdb(messagesData);
          const filteredMessage = Object.values(messagesData).filter(
            (message) => message.from === user.id
          );
          const lastMessage = filteredMessage[filteredMessage.length - 1];
          setLastFriendMessage(lastMessage);
          const chatListRefA = ref(
            db,
            '/chatlist/' + specificUserId + '/' + user.id + '/unread'
          );
          set(chatListRefA, 0)
            .then(() => {
              // console.log('Unread count set to 0 for User A.');
            })
            .catch((error) => {
              console.error('Error updating unread count for User A:', error);
            });
        } catch (error) {
          // console.log('Error fetching user data:', error);
        }
      };
      fetchMessages();
    });
  };
  return (
    <>
      <AdminLayout>
        {userProfiles.length === 0 ? (
          <div className="noconv">
            <img className="nochatp" src={nochat} alt="nochat" />
            <h4 className={darkMode ? "startchatDark" : "startchat"}>No Messages Yet?</h4>
            <p className={darkMode ? "findtxtDark" : "findtxt"}>Find a product you like and start a conversation!</p>
            <button className={darkMode ? "goshopDark" : "goshop"} onClick={() => navigate("/")}>
              Go Shopping
            </button>
          </div>
        ) :
          (
            <div className="chat_page">
              <div className={leftHidden ? `left ${darkMode ? 'leftDark' : ''} hidden` : `left ${darkMode ? 'leftDark' : ''}`}
              >
                <AdminSearchChat />
                {userProfiles.map((user, index) => (
                  <Adminchats key={index} onClick={handleChatClick} user={user} />
                ))}
              </div>
              <div className={showChat ? 'right' : 'right_open'}>
                {showChat ? (
                  <>
                    <BiArrowBack className="go_back_button" onClick={handleGoBack} />
                    <AdminChatHeader selectedUser={selectedUser} lastMessage={lastFriendMessage} />
                    <AdminChatBody messages={messagesdb} selectedUser={selectedUser} />
                    <AdminChatInput addMessage={addMessage} selectedUser={selectedUser} ConvoRoomID={ConvoRoomID} specificUserId={specificUserId} />
                  </>
                ) : (
                  <>
                    <img className="nochat" src={nochat} alt="nochat" />
                    <h4 className={darkMode ? "startchatDark" : "startchat"}>Start Your Chat!</h4>
                  </>
                )}
              </div>
            </div>
          )}
      </AdminLayout>
    </>
  );
};
export default AdminChatPage;
