import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import RootReducer from './reducersIndex';
import { configureStore } from "@reduxjs/toolkit";
export const Store = configureStore(
  {
    reducer: RootReducer,
    // initialState,
    middleware: [thunk]
  }
);
export const persistor = persistStore(Store);