import React, { useState } from "react";
import styles from './reportModal.scss'
import ReactDom from "react-dom";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PostReport } from "../../../../store/actions/userActions";
const ReportModal = ({ open, onClose, selectedUser }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [additionalComments, setAdditionalComments] = useState("");
  const handleReasonChange = (event) => {
    const { value } = event.target;
    // Check if the value is already in the selectedReasons array
    const index = selectedReasons.indexOf(value);
    if (index === -1) {
      // If not found, add it to the array
      setSelectedReasons([...selectedReasons, value]);
    } else {
      // If found, remove it from the array
      setSelectedReasons(selectedReasons.filter((reason) => reason !== value));
    }
  };
  const handleCommentsChange = (event) => {
    setAdditionalComments(event.target.value);
  };
  const reasons = [
    "Spam",
    "Fraud",
    "Inappropriate profile content",
    "Threatening",
    "Using inappropriate language",
    "Other",
  ];
  const data = [...selectedReasons, additionalComments];
  const rawData = {
    to: selectedUser,
    comments: data
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(PostReport(rawData));
      if (response) {
        onClose();
      }
    } catch (error) {
      // console.log(error);
    }
  }
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className="Roverlay" onClick={onClose}></div>
      <div className={darkMode ? "Rmodal_stylesDark" : "Rmodal_styles"}>
        <div className="Rcontainer">
          {darkMode ? (
            <CloseDark className="Rclose" onClick={onClose} />
          ) :
            (<Close className="Rclose" onClick={onClose} />)}
          <h2 className={darkMode ? "RtitleDark" : "Rtitle"}>Report User</h2>
          <div className="checkbox-group">
            {reasons.map((reason) => (
              <label key={reason} className={darkMode ? "RlbDark" : "Rlb"}>
                <input
                  type="checkbox"
                  name="reason"
                  value={reason}
                  checked={selectedReasons.includes(reason)}
                  onChange={handleReasonChange}
                />
                {reason}
              </label>
            ))}
          </div>
          <input
            type="text"
            className={darkMode ? "inpDark" : "inp"}
            placeholder="Additional Comments"
            value={additionalComments}
            onChange={handleCommentsChange}
          />
          <label className={darkMode ? "RlbDark" : "Rlb"}>
            <input type="checkbox" className="checkb" name="terms" />
            I also want to block this user
          </label>
          <button className={darkMode ? "submitDark" : "submit"} type="submit" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </>,
    document.getElementById('portal')
  );
};
export default ReportModal;
