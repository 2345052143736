import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import SwiperCore, { Pagination } from "swiper/core";
import { Navigation, EffectFade } from "swiper";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import 'swiper/css/pagination';
import { useDispatch, useSelector } from "react-redux";
import { GetCarousel } from "../../../../store/actions/AdminDashboard";
import LazyLoad from 'react-lazyload';
import img from '../../../../assests/images/La7i2_7alak1.1.png'
SwiperCore.use([Navigation, Pagination, EffectFade]);
const Carousel = () => {
  const dispatch = useDispatch();
  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const carousel = useSelector((state) => state.carousel?.carousel?.imageUrls)
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(GetCarousel())
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
  }, [dispatch]);
  return (
    <div className={styles.carousel}>
      <Swiper
        navigation={{ prevEl, nextEl }}
        modules={[Navigation, Pagination, EffectFade]}
        slidesPerView={1}
        loop={true}
        pagination={{
          clickable: true,
        }}
        className={styles.myswiper}
      >
        {carousel && carousel.map((image, index) => (
          <SwiperSlide key={index} className={styles.swiperslide}>
            <LazyLoad height={515} offset={100}>
              {loading ? (
                <img src='./graybg.png' alt="img" />) : (
                <img src={image} alt="img" />
              )}

            </LazyLoad>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.flex}>
        <div
          ref={(node) => setNextEl(node)}
          className={`${styles.btn} ${styles.left}`}
        >
          <AiOutlineArrowRight />
        </div>
        <div
          ref={(node) => setPrevEl(node)}
          className={`${styles.btn} ${styles.right}`}
        >
          <AiOutlineArrowLeft />
        </div>
      </div>
      <style>
        {`
                    .swiper-pagination-bullet-active { background: #0298a6; }
                    `}
      </style>
    </div>
  );
};
export default Carousel;
