import {
    GET_AVERAGE_REVIEW_FAIL,
    GET_AVERAGE_REVIEW_SUCCESS
} from "../constants/userConstants";
const initialState = {
    AverageReview: [],
    error: null,
    loading: false,
};
export const GetAverageReview = (state = initialState, action) => {
    switch (action.type) {
        case GET_AVERAGE_REVIEW_SUCCESS:
            return {
                ...state,
                AverageReview: action.payload,
                error: null,
                loading: false,
            };
        case GET_AVERAGE_REVIEW_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
