import React, { useState, useEffect } from "react";
import styles from "./ProfilePicture.module.scss";
import {
  FollowUser,
  GetUserFollowing,
  UnfollowUser,
} from "../../../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../common/components/Modal/Modal";
import { ToastContainer, toast } from "react-toastify";
import { GetUser } from "../../../../store/actions/userActions";
import "react-toastify/dist/ReactToastify.css";
const ProfilePicture = ({ image, id, blocked }) => {
  const dispatch = useDispatch();
  const [following, setFollowing] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSelector((state) => state.userLogin);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const uid = localStorage.getItem("uid");
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);

  useEffect(() => {
    dispatch(GetUserFollowing())
      .then((response) => {
        setFollowing(response);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch]);

  const isFollowing = following && !!following.find((user) => user._id === id);
  const handleSubmit = () => {
    if (loggedInUser) {
      if (isFollowing) {
        const formData = new FormData();
        formData.append("fid", id);
        dispatch(UnfollowUser(formData)).then(() => {
          dispatch(GetUser(id));
          setFollowing((prevFollowing) =>
            prevFollowing.filter((user) => user._id !== id)
          );
        });
      } else {
        const formData = new FormData();
        formData.append("fid", id);
        dispatch(FollowUser(formData)).then(() => {
          dispatch(GetUser(id));
          const alreadyFollowing = following.some((user) => user._id === id);
          if (!alreadyFollowing) {
            setFollowing((prevFollowing) => [...prevFollowing, { _id: id }]);
          }
        });
      }
    } else {
      toast.error("Please login to follow this user.");
      setIsOpen(true);
    }
  };
  return (
    <>
      <Modal open={isOpen} closeHandler={() => setIsOpen(false)} />
      <div className={styles.container}>
        <img src={image} alt="profile" className={styles.image} />
        {blocked
          ? null
          : id !== uid && (
              <button
                className={`${styles.btn} ${darkMode ? styles.btnDark : ""} ${
                  isFollowing ? styles.btnUnflw : ""
                }`}
                onClick={handleSubmit}
              >
                {isFollowing ? "Unfollow" : "Follow"}
              </button>
            )}
      </div>
    </>
  );
};
export default ProfilePicture;
