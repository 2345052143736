import React, { useState, useRef } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./AddCard.module.scss";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  AddressElement,
  useElements
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { createCustomerStripe } from "../../store/actions/paymentActions";
import { json, useNavigate } from "react-router-dom";
import Modal from "../../common/components/Modal/Modal";
import FavoriteCategories from "../../common/components/Modal/Favorite Categories/FavoriteCategories";
import { CountryDropdown } from 'react-country-region-selector';
import { useEffect } from "react";
export default function CheckoutForm({ newp = true, setStep }) {
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const formRef = useRef();
  const handleChange = event => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };
  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true)
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    const address = elements.getElement(AddressElement);
    const { error, token } = await stripe.createToken(card, {
      address_country: event.target.country.value,
      name: event.target.cardHolderName.value,
    }
    );
    if (error) {
      setLoading(false)
      setError(error.message);
    } else {
      setError(null);
      await dispatch(createCustomerStripe({ token: token.id }));
      setLoading(false)
      if (newp) {
        navigate(-1)
      }
      else {
        setIsOpen(true)
      }
    }

  };
  const [country, setCountry] = useState("");
  const selectCountry = (val) => {
    setCountry(val);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 440);
    };
    window.addEventListener('resize', handleResize);
    // Initial check
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <Modal
        open={isOpen}
        fav={true}
        closeHandler={() => setIsOpen(false)}
      />
      <div className={styles.container}>
        <form
          onSubmit={handleSubmit}
          className={styles.wrapper}
          innerRef={formRef}
        >
          <div className={`${styles.input_container} ${darkMode ? styles.input_containerDark : ''}`}>
            <input
              className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
              onChange={handleChange}
              placeholder="Card Holder Name"
              name="cardHolderName"
            />
          </div>
          <div className={`${styles.input_container} ${darkMode ? styles.input_containerDark : ''}`}>
            <label>
              Card number
              <CardNumberElement
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                onChange={handleChange}
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      lineHeight: isSmallScreen ? '40px' : '55px',
                      color: darkMode ? 'rgb(207,203,203)' : 'black',
                    },
                  }
                }}
              />
            </label>
          </div>
          <div className={styles.small_input_container}>
            <div className={`${styles.input_container} ${darkMode ? styles.input_containerDark : ''}`}>
              <label>
                Expiration date
                <CardExpiryElement
                  className={`${styles.small_input} ${darkMode ? styles.small_inputDark : ''}`}
                  onChange={handleChange}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        lineHeight: isSmallScreen ? '40px' : '55px',
                        color: darkMode ? 'rgb(207,203,203)' : 'black',
                      },
                    }
                  }}
                />
              </label>
            </div>
            <div className={`${styles.input_container} ${darkMode ? styles.input_containerDark : ''}`}>
              <label>
                CVC
                <CardCvcElement
                  className={`${styles.small_input} ${darkMode ? styles.small_inputDark : ''}`}
                  onChange={handleChange}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        lineHeight: isSmallScreen ? '40px' : '55px',
                        color: darkMode ? 'rgb(207,203,203)' : 'black',
                      },
                    }
                  }}
                />
              </label>
            </div>
          </div>
          <div className={`${styles.input_container} ${darkMode ? styles.input_containerDark : ''}`}>
            <CountryDropdown
              value={country}
              className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
              name="country"
              onChange={selectCountry}
            />
          </div>
          <div>{error}</div>
          {/* <div className={styles.checkbox_container}>
            <input
              type="checkbox"
              name="default"
              id="default"
              className={styles.checkbox}
            />
            <label className={styles.label} htmlFor="default">
              Set as default payment method
            </label>
          </div>
           */}
          {loading ? (
            <button disabled={true} onClick={(e) => {
              e.preventDefault();
            }} className={styles.saveload_btn}>Adding Card...</button>
          ) : (
            <button type="submit" className={`${styles.add} ${darkMode ? styles.addDark : ''}`} disabled={!stripe || !elements}>
              Add Card
            </button>
          )
          }
        </form>
      </div>
    </>
  );
}
