import axios from "axios";
import {
  UPLOAD_PROFILE_PICUTRE_FAIL,
  UPLOAD_PROFILE_PICUTRE_REQUEST,
  UPLOAD_PROFILE_PICUTRE_SUCCESS,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  UPLOAD_PROFILE_PICUTRE_RESET,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  GET_USERCARD_BY_ID_REQUEST,
  GET_USERCARD_BY_ID_SUCCESS,
  GET_USERCARD_BY_ID_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_GEOLOCATION_REQUEST,
  UPDATE_GEOLOCATION_FAIL,
  UPDATE_GEOLOCATION_SUCCESS,
  GET_USER_CATEGORY_SUCCESS,
  GET_USER_CATEGORY_FAIL,
  GET_PRODUCT_PER_USER_SUCCESS,
  GET_PRODUCT_PER_USER_FAIL,
  FOLLOW_USER_SUCCESS,
  FOLLOW_USER_FAIL,
  UNFOLLOW_USER_SUCCESS,
  UNFOLLOW_USER_FAIL,
  GET_USER_FOLLOWING_SUCCESS,
  GET_USER_FOLLOWING_FAIL,
  ADD_REVIEW_SUCCESS,
  ADD_REVIEW_FAIL,
  GET_REVIEW_BY_UID_SUCCESS,
  GET_REVIEW_BY_UID_FAIL,
  POST_REPORT_SUCCESS,
  POST_REPORT_FAIL,
  POST_CONTACT_US_SUCCESS,
  POST_CONTACT_US_FAIL,
  POST_BLOCK_SUCCESS,
  POST_BLOCK_FAIL,
  IS_BLOCKED_SUCCESS,
  IS_BLOCKED_FAIL,
  GET_CURRENT_LOCATION_SUCCESS,
  GET_CURRENT_LOCATION_FAIL,
  GET_AVERAGE_REVIEW_SUCCESS,
  GET_AVERAGE_REVIEW_FAIL,
  GET_BOUGHT_HISTORY_SUCCESS,
  GET_BOUGHT_HISTORY_FAIL,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL,
  GET_BLOCKED_USERS_SUCCESS,
  GET_BLOCKED_USERS_FAIL,
  UNBLOCK_USER_SUCCESS,
  UNBLOCK_USER_FAIL,
  RATE_EXPERIENCE_SUCCESS,
  RATE_EXPERIENCE_FAIL,
  RATE_EXPERIENCE_REQUEST,
  POST_UNBLOCK_SUCCESS,
  POST_UNBLOCK_FAIL,
  SEND_MESSAGE_NOTIFICATION_SUCCESS,
  SEND_MESSAGE_NOTIFICATION_FAIL,
  GET_ALL_CONTACT_US_SUCCESS,
  GET_ALL_CONTACT_US_FAIL,
  GET_BOUGHT_HISTORY_REQUEST,
  GET_PAYMENT_AMOUNTS_SUCCESS,
  GET_PAYMENT_AMOUNTS_FAIL,
  GET_PAYMENT_HISTORY_SUCCESS,
  GET_PAYMENT_HISTORY_FAIL,
  GET_PAYMENT_HISTORY_REQUEST,
  GET_PRODUCT_PER_USER_ID_SUCCESS,
  GET_PRODUCT_PER_USER_ID_FAIL
} from "../constants/userConstants";
import { url } from "../../common/constants/url";
import { logout } from "./authActions";
export const uploadProfilePicture = (file) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPLOAD_PROFILE_PICUTRE_REQUEST,
    });
    let formData = new FormData();
    formData.append("file", file);
    const {
      userRegister: { userData },
    } = getState();
    // console.log(userData);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": file.type,
        // 'content-length': `${file.size}`,
      },
    };
    const { data } = await axios.post(
      `${url}/User/${userData.id}/uploadFilemain`,
      formData,
      config
    );
    dispatch({
      type: UPLOAD_PROFILE_PICUTRE_SUCCESS,
      payload: data.body,
    });
  } catch (error) {
    dispatch({
      type: UPLOAD_PROFILE_PICUTRE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
// change in edit profile
export const changeProfilePicture = (file) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPLOAD_PROFILE_PICUTRE_REQUEST,
    });
    let formData = new FormData();
    formData.append("file", file);
    const {
      userById: { user },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const { data } = await axios.post(
      `${url}/User/${user.id}/uploadFilemain`,
      formData,
      config
    );
    dispatch({
      type: UPLOAD_PROFILE_PICUTRE_SUCCESS,
      payload: data.body,
    });
  } catch (error) {
    dispatch({
      type: UPLOAD_PROFILE_PICUTRE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const reset_image = () => (dispatch) => {
  dispatch({ type: UPLOAD_PROFILE_PICUTRE_RESET });
};
export const getUserById = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USER_BY_ID_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo}`,
      },
    };
    const { data } = await axios.get(`${url}/User/getUserById`, config, userId);
    dispatch({
      type: GET_USER_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateProfile =
  (username, email, dateofbirth, phonenumber, image, id) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: UPDATE_USER_REQUEST,
        });
        const {
          userLogin: { userInfo },
        } = getState();
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo}`,
          },
        };
        let body = {
          username,
          email,
          dateofbirth,
          phonenumber,
          image,
        };
        await axios.post(
          `${url}/User/${id}/updateUser`,
          JSON.stringify(body),
          config
        );
        dispatch({
          type: UPDATE_USER_SUCCESS,
        });
      } catch (error) {
        dispatch({
          type: UPDATE_USER_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };
// update whole profile after changeProfilePicture is called
export const updateProfileWithImage =
  (username, email, dateofbirth, phonenumber, id) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: UPDATE_USER_REQUEST,
        });
        const {
          userLogin: { userInfo },
        } = getState();
        const {
          profilePicture: { image },
        } = getState();
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo}`,
          },
        };
        let body = {
          username,
          email,
          dateofbirth,
          phonenumber,
          image,
        };
        await axios.patch(
          `${url}/User/${id}/updateUser`,
          JSON.stringify(body),
          config
        );
        dispatch({
          type: UPDATE_USER_SUCCESS,
        });
      } catch (error) {
        dispatch({
          type: UPDATE_USER_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };
export const listUserRates = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USERCARD_BY_ID_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo}`,
      },
    };
    const { data } = await axios.get(
      url + `/usercardrates/${userId}/getusercardrates`,
      config
    );
    dispatch({
      type: GET_USERCARD_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USERCARD_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updatePassword =
  (passwordold, password, userId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CHANGE_PASSWORD_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo}`,
        },
      };
      let body = {
        password,
        passwordold,
      };
      await axios.patch(
        url + `/User/${userId}/changepassword`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: CHANGE_PASSWORD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
// when you visit a user
export const getUser = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USER_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      `${url}/User/${userId}/viewuserbyid`,
      config
    );
    dispatch({
      type: GET_USER_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const GetUser = (uid) => {
  return async (dispatch) => {
    if (uid !== null) {
      try {
        const token = localStorage.getItem('token')
        const response = await axios.get(`${url}/user/${uid}`, {
          headers: {
            'Authorization': `${token}`,
          },
        });
        dispatch(GetUserSuccess(response));
        return response
      } catch (error) {
        dispatch(GetUserFail(error));
        // console.log(error)
      }
    }
  };
};
export const GetUserSuccess = (categories) => {
  return {
    type: GET_USER_CATEGORY_SUCCESS,
    payload: categories,
  };
};
const GetUserFail = (error) => ({
  type: GET_USER_CATEGORY_FAIL,
  payload: error
});
export const GetProductPerUser = ({ uid, page, pageSize }) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/user/product/${uid}?page=${page}&pageSize=${pageSize}`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetProductPerUserSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetProductPerUserFail(error));
      // console.log(error)
    }
  };
};
export const GetProductPerUserSuccess = (categories) => {
  return {
    type: GET_PRODUCT_PER_USER_SUCCESS,
    payload: categories,
  };
};
const GetProductPerUserFail = (error) => ({
  type: GET_PRODUCT_PER_USER_FAIL,
  payload: error
});
export const FollowUser = (formData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(`${url}/user/follow`, formData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      dispatch(FollowUserSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(FollowUserFail(error));
      // console.log(error)
    }
  };
};
export const FollowUserSuccess = (categories) => {
  return {
    type: FOLLOW_USER_SUCCESS,
    payload: categories,
  };
};
const FollowUserFail = (error) => ({
  type: FOLLOW_USER_FAIL,
  payload: error
});
export const GetUserFollowing = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/user/following`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      dispatch(GetUserFollowingSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetUserFollowingFail(error));
      // console.log(error)
    }
  };
};
export const GetUserFollowingSuccess = (categories) => {
  return {
    type: GET_USER_FOLLOWING_SUCCESS,
    payload: categories,
  };
};
const GetUserFollowingFail = (error) => ({
  type: GET_USER_FOLLOWING_FAIL,
  payload: error
});
export const UnfollowUser = (formData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(`${url}/user/unfollow`, formData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      dispatch(UnfollowUserSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(UnfollowUserFail(error));
      // console.log(error)
    }
  };
};
export const UnfollowUserSuccess = (categories) => {
  return {
    type: UNFOLLOW_USER_SUCCESS,
    payload: categories,
  };
};
const UnfollowUserFail = (error) => ({
  type: UNFOLLOW_USER_FAIL,
  payload: error
});
export const AddReview = (formData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/review`, formData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(AddReviewSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(AddReviewFail(error));
      // console.log(error)
    }
  };
};
export const AddReviewSuccess = (categories) => {
  return {
    type: ADD_REVIEW_SUCCESS,
    payload: categories,
  };
};
const AddReviewFail = (error) => ({
  type: ADD_REVIEW_FAIL,
  payload: error
});
export const GetReviewsByUID = (uid) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/review/user/${uid}`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetReviewsByUIDSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetReviewsByUIDFail(error));
      // console.log(error)
    }
  };
};
export const GetReviewsByUIDSuccess = (categories) => {
  return {
    type: GET_REVIEW_BY_UID_SUCCESS,
    payload: categories,
  };
};
const GetReviewsByUIDFail = (error) => ({
  type: GET_REVIEW_BY_UID_FAIL,
  payload: error
});
export const PostReport = (formData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/report`, formData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(PostReportSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(PostReportFail(error));
      // console.log(error)
    }
  };
};
export const PostReportSuccess = (categories) => {
  return {
    type: POST_REPORT_SUCCESS,
    payload: categories,
  };
};
const PostReportFail = (error) => ({
  type: POST_REPORT_FAIL,
  payload: error
});
export const PostBlock = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.put(`${url}/user/blockUser`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(PostBlockSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(PostBlockFail(error));
      // console.log(error)
    }
  };
};
export const PostBlockSuccess = (categories) => {
  return {
    type: POST_BLOCK_SUCCESS,
    payload: categories,
  };
};
const PostBlockFail = (error) => ({
  type: POST_BLOCK_FAIL,
  payload: error
});
export const PostUnBlock = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/user/removeBlock`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(PostUnBlockSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(PostUnBlockFail(error));
      // console.log(error)
    }
  };
};
export const PostUnBlockSuccess = (categories) => {
  return {
    type: POST_UNBLOCK_SUCCESS,
    payload: categories,
  };
};
const PostUnBlockFail = (error) => ({
  type: POST_UNBLOCK_FAIL,
  payload: error
});
export const IsBlocked = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/user/checkIfBlocked`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(IsBlockedSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(IsBlockedFail(error));
      // console.log(error)
    }
  };
};
export const IsBlockedSuccess = (categories) => {
  return {
    type: IS_BLOCKED_SUCCESS,
    payload: categories,
  };
};
const IsBlockedFail = (error) => ({
  type: IS_BLOCKED_FAIL,
  payload: error
});
export const PostContactUsForm = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/contactus`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(PostContactUsFormSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(PostContactUsFormFail(error));
      // console.log(error)
    }
  };
};
export const PostContactUsFormSuccess = (categories) => {
  return {
    type: POST_CONTACT_US_SUCCESS,
    payload: categories,
  };
};
const PostContactUsFormFail = (error) => ({
  type: POST_CONTACT_US_FAIL,
  payload: error
});
export const GetCurrentLocation = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/address/location`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(GetCurrentLocationSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetCurrentLocationFail(error));
      // console.log(error)
    }
  };
};
export const GetCurrentLocationSuccess = (categories) => {
  return {
    type: GET_CURRENT_LOCATION_SUCCESS,
    payload: categories,
  };
};
const GetCurrentLocationFail = (error) => ({
  type: GET_CURRENT_LOCATION_FAIL,
  payload: error
});
export const GetAverageReview = (userId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/review/userAverage/${userId}`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetAverageReviewSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetAverageReviewFail(error));
      // console.log(error)
    }
  };
};
export const GetAverageReviewSuccess = (categories) => {
  return {
    type: GET_AVERAGE_REVIEW_SUCCESS,
    payload: categories,
  };
};
const GetAverageReviewFail = (error) => ({
  type: GET_AVERAGE_REVIEW_FAIL,
  payload: error
});
export const GetBoughtHistory = () => {
  return async (dispatch) => {
    try {
      dispatch(GetBoughtHistoryRequest())
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/user/getBought`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetBoughtHistorySuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetBoughtHistoryFail(error));
      // console.log(error)
    }
  };
};
export const GetBoughtHistoryRequest = () => {
  return {
    type: GET_BOUGHT_HISTORY_REQUEST,
  };
};
export const GetBoughtHistorySuccess = (categories) => {
  return {
    type: GET_BOUGHT_HISTORY_SUCCESS,
    payload: categories,
  };
};
const GetBoughtHistoryFail = (error) => ({
  type: GET_BOUGHT_HISTORY_FAIL,
  payload: error
});
export const DeleteAccount = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(`${url}/user/delete`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(DeleteAccountSuccess(response.data));
      dispatch(logout())
      window.location.reload()
      return response.data
    } catch (error) {
      dispatch(DeleteAccountFail(error));
      // console.log(error)
    }
  };
};
export const DeleteAccountSuccess = (categories) => {
  return {
    type: DELETE_ACCOUNT_SUCCESS,
    payload: categories,
  };
};
const DeleteAccountFail = (error) => ({
  type: DELETE_ACCOUNT_FAIL,
  payload: error
});
export const GetBlockedUsers = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/user/getBlockedUsers`, null, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetBlockedUsersSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetBlockedUsersFail(error));
      // console.log(error)
    }
  };
};
export const GetBlockedUsersSuccess = (categories) => {
  return {
    type: GET_BLOCKED_USERS_SUCCESS,
    payload: categories,
  };
};
const GetBlockedUsersFail = (error) => ({
  type: GET_BLOCKED_USERS_FAIL,
  payload: error
});
export const UnblockUser = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/user/removeBlock`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(UnblockUserSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(UnblockUserFail(error));
      // console.log(error)
    }
  };
};
export const UnblockUserSuccess = (categories) => {
  return {
    type: UNBLOCK_USER_SUCCESS,
    payload: categories,
  };
};
const UnblockUserFail = (error) => ({
  type: UNBLOCK_USER_FAIL,
  payload: error
});
export const RateExperienceUser = (rawData) => {
  return async (dispatch) => {
    dispatch(RateExperienceRequest())
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/rating`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(RateExperienceSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(RateExperienceFail(error));
      // console.log(error)
    }
  };
};
export const RateExperienceSuccess = (categories) => {
  return {
    type: RATE_EXPERIENCE_SUCCESS,
    payload: categories,
  };
};
const RateExperienceFail = (error) => ({
  type: RATE_EXPERIENCE_FAIL,
  payload: error
});
const RateExperienceRequest = () => ({
  type: RATE_EXPERIENCE_REQUEST,
});
export const SendMessageNotification = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/notifications/user/chat`, rawData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'application/json'
        },
      });
      dispatch(SendMessageNotificationSuccess(response));
      return response
    } catch (error) {
      dispatch(SendMessageNotificationFail(error));
      // console.log(error)
    }
  };
};
export const SendMessageNotificationSuccess = (categories) => {
  return {
    type: SEND_MESSAGE_NOTIFICATION_SUCCESS,
    payload: categories,
  };
};
const SendMessageNotificationFail = (error) => ({
  type: SEND_MESSAGE_NOTIFICATION_FAIL,
  payload: error
});

export const GetAllContactUs = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/contactus/getAll`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetAllContactUsSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetAllContactUsFail(error));
      // console.log(error)
    }
  };
};
export const GetAllContactUsSuccess = (categories) => {
  return {
    type: GET_ALL_CONTACT_US_SUCCESS,
    payload: categories,
  };
};
const GetAllContactUsFail = (error) => ({
  type: GET_ALL_CONTACT_US_FAIL,
  payload: error
});
export const GetPaymentAmount = (title) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/getPayment/${title}`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetPaymentAmountSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetPaymentAmountFail(error));
      // console.log(error)
    }
  };
};
export const GetPaymentAmountSuccess = (categories) => {
  return {
    type: GET_PAYMENT_AMOUNTS_SUCCESS,
    payload: categories,
  };
};
const GetPaymentAmountFail = (error) => ({
  type: GET_PAYMENT_AMOUNTS_FAIL,
  payload: error
});
export const GetPaymentHistory = () => {
  return async (dispatch) => {
    try {
      dispatch(GetPaymentHistoryRequest())
      const token = localStorage.getItem('token')
      const response = await axios.get(`${url}/paymentsHistory/user`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetPaymentHistorySuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetPaymentHistoryFail(error));
      // console.log(error)
    }
  };
};
export const GetPaymentHistoryRequest = (categories) => {
  return {
    type: GET_PAYMENT_HISTORY_REQUEST,
    payload: categories,
  };
};
export const GetPaymentHistorySuccess = (categories) => {
  return {
    type: GET_PAYMENT_HISTORY_SUCCESS,
    payload: categories,
  };
};
const GetPaymentHistoryFail = (error) => ({
  type: GET_PAYMENT_HISTORY_FAIL,
  payload: error
});





export const GetProductPerUserId = ({ uid, page, pageSize }) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.post(`${url}/user/product/${uid}?page=${page}&pageSize=${pageSize}`, {
        headers: {
          'authorization': `${token}`,
        },
      });
      dispatch(GetProductPerUserIdSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetProductPerUserIdFail(error));
      // console.log(error)
    }
  };
};
export const GetProductPerUserIdSuccess = (categories) => {
  return {
    type: GET_PRODUCT_PER_USER_ID_SUCCESS,
    payload: categories,
  };
};
const GetProductPerUserIdFail = (error) => ({
  type: GET_PRODUCT_PER_USER_ID_FAIL,
  payload: error
});