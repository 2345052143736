import React, { useState, useEffect } from "react";
import styles from "./SiteMap.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Layout from "../../common/components/Layouts/Layout";
import { Helmet } from "react-helmet";
import Modal from "../../common/components/Modal/Modal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GetAllCategories } from "../../store/actions/categoryAction";
import { HashLink } from "react-router-hash-link";
const SiteMap = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch({ type: "CLEAR_BREADCRUMBS" })
    const pageName = "Site Map"; // Replace this with the actual page name
    const pageUrl = "/site-map"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const categories = useSelector((state) => state.categories?.categories?.data?.categories || []);
  const [isOpen, setIsOpen] = useState(false)
  const uid = localStorage.getItem('uid')
  const offerState = true
  useEffect(() => {
    dispatch(GetAllCategories())
  }, [dispatch])
  const handleSpecialOffersClick = () => {
    navigate('/special-offers', { state: { "offerState": true } });
  };
  const handleCatchOfTheDayClick = () => {
    navigate('/#CatchOfTheDay');
  };
  return (
    <>
      <Helmet>
        <title>Site Map</title>
        <meta name="Site Map" content="Site Map" />
        <meta
          name="keywords"
          content={`Site Map, La7i27alak Site Map`}
        />
      </Helmet>
      <Modal
        open={isOpen}
        closeHandler={() => setIsOpen(false)}
      />
      <Layout>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4" style={{ paddingBottom: '30px' }}>
          <div>
            <li>
              <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`} onClick={() => navigate('/')}>
                Home
              </button>
            </li>
            <li>
              {/* main one */}
              <button
                className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                onClick={() => navigate('/categories', { state: { "isCategory": true } })}
              >
                All Categories
              </button>
              {/* inside the main one */}
              <ul>
                {categories && categories.map(category => (
                  <li key={category.title}>
                    <button
                      className={`${styles.sub_Title} ${darkMode ? styles.sub_TitleDark : ''}`}
                      onClick={() => navigate(`/categories/${category.title.replaceAll(" ", "-")}`, { state: { "isCategory1": true, id: category._id } })}
                    >
                      {category.title}
                    </button>
                    <ul>
                      {category.subCategories.map(subcategory => (
                        <li key={subcategory.title}>
                          <button
                            className={`${styles.sub_sub_Title} ${darkMode ? styles.sub_sub_TitleDark : ''}`}
                            onClick={() => navigate(`/categories/${category.title.replaceAll(" ", "-")}/${subcategory.title.replaceAll(" ", "-")}/${subcategory._id}/${subcategory.parentCategoryId}`, { state: { "isSubCategory": true } })}
                          >
                            {subcategory.title}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`} onClick={() => navigate("/bids", { state: { "isBid": true } })}>
                Bids
              </button>
            </li>
            <li>
              <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`} onClick={() => navigate('/featured-product')}>
                Featured Products
              </button>
            </li>
            <li>
              <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`} onClick={() => navigate('/Offered-Services')}>
                Offered Services
              </button>
            </li>
            <li>
              <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`} onClick={() => navigate('/Requested-Services')}>
                Requested Services
              </button>
            </li>
            <li>
              
              <HashLink
            className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
            to='/#cod'
            smooth
            style={{textDecoration:'none'}}
          >
            Catch Of The Day
          </HashLink>
            </li>
            <li>
              <button
                className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                onClick={handleSpecialOffersClick}
              >
                Special Offers
              </button>
            </li>
          </div>
          <div>
            <li>
              <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                onClick={() => setIsOpen(true)}>
                Register/Login
              </button>
            </li>
            {uid ? (
              <>
                <li>
                  <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                    onClick={() => navigate(`/user/order-history`, { state: { "isOrder": true } })}>
                    Order History
                  </button>
                </li>
                <li>
                  <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                    onClick={() => navigate("/user/waiting-list", { state: { "isDropDown": true } })}>
                    My WaitingList
                  </button>
                </li>
                <li>
                  <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                    onClick={() => navigate("/user/addresses", { state: { "isAddress": true } })}>
                    Adresses
                  </button>
                </li>
                <li>
                  <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                    onClick={() => navigate("/user/payment", { state: { "isPayment": true } })}>
                    Payment
                  </button>
                </li>
              </>
            ) : (
              null
            )}
          </div>
          <div>
            <li>
              <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                onClick={() => navigate(`/help`, { state: { "isHelp": true } })}>
                Help
              </button>
            </li>
            <li>
              <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                onClick={() => navigate(`/FAQ`, { state: { "isFaq": true } })}>
                FAQs
              </button>
            </li>
            <li>
              <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                onClick={() => navigate(`/privacy-policy`, { state: { "isPolicy": true } })}>
                Privacy Policy
              </button>
            </li>
            <li>
              <button className={`${styles.main_Title} ${darkMode ? styles.main_TitleDark : ''}`}
                onClick={() => navigate(`/contact-us`, { state: { "isContact": true } })}>
                Contact Us
              </button>
            </li>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default SiteMap;
