import React from "react";
import styles from "./styles.module.scss";
import wishlist from "../../../../assests/svg/wishlist.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const EmptyWishList = () => {
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <div className={styles.container}>
     
      <img src={wishlist} alt="wishlist empty" className={styles.img} />
      <h3 className={`${styles.empty} ${darkMode? styles.emptyDark:''}`}>Oops, it's empty</h3>
      <p className={`${styles.text} ${darkMode? styles.textDark:''}`}>
        Find a product you like and add it to your wishlist
      </p>
      <button className={`${styles.btn} ${darkMode? styles.btnDark:''}`} onClick={() => navigate("/")}>
        Go Shopping
      </button>
    </div>
  );
};
export default EmptyWishList;
