import React from "react";
import "./Role.scss";
const IsAdmin = ({ state }) => {
  const role = state ? "ADMIN" : "USER";
  let color = state ? "red" : "lightb";
  return (
    <div className={`statediv ${color}`}>
      <span className="state">{role}</span>
    </div>
  );
};
export default IsAdmin;
