import React, { useState, useRef, useEffect } from 'react';
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsFillTrashFill } from "react-icons/bs";
import { DeleteProduct } from '../../../../store/actions/productDetailsAction';
import { useDispatch } from 'react-redux';
import './threeDots.scss';
import { DeleteServiceById } from '../../../../store/actions/servicesActions';
import {
  GetPendingProduct,
  GetConfirmedProduct,
  GetPendingService,
  GetConfirmedService
} from "../../../../store/actions/AdminDashboard";
const ThreeDots = ({ ProductId, isService }) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1050);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial value
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [hidden, setHidden] = useState(false);
  const [showThreeDotsDropdown, setShowThreeDotsDropdown] = useState(false);
  const toggleThreeDotsDropdown = () => {
    setShowThreeDotsDropdown(!showThreeDotsDropdown);
    setShowDropdown(false);
  };
  const handleDelete = async (ProductId) => {
    if (isService) {
      await dispatch(DeleteServiceById(ProductId))
      dispatch(GetPendingProduct())
      dispatch(GetConfirmedProduct())
      dispatch(GetConfirmedService())
      dispatch(GetPendingService())
    } else {
      await dispatch(DeleteProduct(ProductId));
      dispatch(GetPendingProduct())
      dispatch(GetConfirmedProduct())
      dispatch(GetConfirmedService())
      dispatch(GetPendingService())
    }
  };
  return hidden ? null : (
    <>
      {isSmallScreen ? (
        <>
          <div>
            <button onClick={() => handleDelete(ProductId)}>
              <p className='delete'>
                {isService ? (
                  " Delete service"
                ) : (
                  "Delete product"
                )}
              </p>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className='threedots' onClick={toggleThreeDotsDropdown}>
            <button>
              <BsThreeDotsVertical className='dots' />
            </button>
            {showThreeDotsDropdown && (
              <div className="dotDrop">
                <button onClick={() => handleDelete(ProductId)}>
                  <p className='delete'>
                    <BsFillTrashFill className='icd' />  {isService ? (
                      " Delete service"
                    ) : (
                      "Delete product"
                    )}
                  </p>
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}
export default ThreeDots