import React, { useState } from "react";
import styles from './UserCard.module.scss'
import img from '../../../assests/images/PS5 1.png'
import { useDispatch, useSelector } from "react-redux";
import { UnblockUser } from "../../../store/actions/userActions";
import { GetBlockedUsers } from "../../../store/actions/userActions";
const UserCard = ({ image, name, id }) => {
    const dispatch = useDispatch();
    const [blocked, setIsBlocked] = useState(true)
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    const data = { "userIdToRemove": id }
    const handleUnblock = async () => {
        try {
            const response = await dispatch(UnblockUser(data));
            if (response) {
                dispatch(GetBlockedUsers());
            }
        } catch (error) {
            // console.log(error);
        }
    };
    return (
        <section className={styles.secblk}>
            <div className={styles.container}>
                <div className={styles.imgcont}>
                    <img className={styles.img} src={image} alt='user profile' />
                    <p className={`${styles.name} ${darkMode ? styles.nameDark : ''}`}>{name}</p>
                </div>
                <div className={styles.btnCont}>
                    <button onClick={() => handleUnblock()} className={styles.unblock}>Unblock</button>
                </div>
            </div>
        </section>
    );
}
export default UserCard;