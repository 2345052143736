import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import Card from "./Card";
import styles from "./styles.module.scss";
import SwiperCore, { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import Car from "../../../../assests/images/Car.png";
import Properties from "../../../../assests/images/Properties.png";
import Furniture from "../../../../assests/images/Furniture.png";
import PhonesANDAccessories from "../../../../assests/images/PhonesANDAccessories.png";
import FashionANDBeauty from "../../../../assests/images/FashionANDBeauty.png";
import Electronics from "../../../../assests/images/Electronics.png";
import { GetAllCategories } from "../../../../store/actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCategoriesSuccess } from "../../../../store/actions/categoryAction";
import { url } from "../../../../common/constants/url";
import Loader from "../../../../common/components/Loader/Loader";
// Import Swiper styles
import 'swiper/css';
import CardSkel from "../../../../common/components/Cards/CardSkel";
import CatCardSkel from "./CatCardSkel";
SwiperCore.use([Navigation]);
export default function Categories() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const isSmallScreen = windowWidth < 768;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllCategories())
      .then((data) => {
        dispatch(GetAllCategoriesSuccess(data));
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch]);
  const categories = useSelector((state) => state.categories.categories);
  const loading = useSelector(
    (state) => state.allcat?.loading
  );
  const error = useSelector((state) => state.categories.error);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  let categoriesArray;
  if (categories && categories.length === 0) {
    categoriesArray = [];
  } else if (categories) {
    categoriesArray = Object.values(categories);
    // code that uses the categoriesArray variable
  } else {
    console.error('An error occurred while processing the categoriesArray: categories is null or undefined');
  }
  if (loading) {
    <div>
      <CatCardSkel />
    </div>
  }
  if (categories && categories.length === 0) {
    return <div>No categories found</div>;
  }
  const screenWidth = window.innerWidth;
  let slidesPerView;
 if (screenWidth>1535) {
    slidesPerView = 6;
  } else {
    slidesPerView = 4;
  }
  return (
    <section className={`${styles.section} ${darkMode ? styles.sectionDark : ''}`}>
      <div className={styles.flex}>
        <h2 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Categories</h2>
        <h2 className={styles.all}>
          <Link className={`${styles.S_all} ${darkMode ? styles.S_allDark : ''}`} to={"/categories"}>
            See all <BsArrowRight className={styles.arrow} />
          </Link>
        </h2>
      </div>
      <Swiper
        className={styles.swiperC}
        freeMode
        // navigation={true}
        modules={[Navigation]}
        navigation={!isSmallScreen}
        spaceBetween={10}
        slidesPerView={slidesPerView}>
        {loading ? (
          <div>
            <CatCardSkel />
          </div>
        ) : (
          categoriesArray && categoriesArray[0]?.categories && categoriesArray[0].categories.length > 0 &&
          <>
          {categoriesArray[0].categories.map((category) => (
            <SwiperSlide  key={category._id} className={styles.swiperS}>
              <Card name={category.title} img={category.iconUrl} id={category._id} />
            </SwiperSlide>
          ))}
          {/* Add your dummy card here */}
          <SwiperSlide className={styles.swiperS}>
          <div className={styles.dummy_content}></div>
          </SwiperSlide>
        </>
      )}
      </Swiper>
    </section>
  );
};
