import React, { useState } from 'react';
import styles from './AdminSearchChat.module.scss';
import { AiOutlineSearch } from "react-icons/ai";
import { useSelector } from 'react-redux';
const AdminSearchChat = () => {
  const [activeButton, setActiveButton] = useState('All');
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const handleButtonClick = (button) => {
    setActiveButton(button);
  };
return (
  <div className={styles.search_chat}>
    <div className={styles.search_input}>
    <AiOutlineSearch className={styles.searchic}  />
      <input type="text" className={`${darkMode ? styles.searchinpDark: styles.searchinp}`} placeholder="Search" style={{paddingLeft:50}}/>
    </div>
    <div className={`${darkMode ? styles.button_containerDark: styles.button_container}`}>
    <button
        className={`${activeButton === 'All' ? styles.active : styles.but}`}
        onClick={() => handleButtonClick('All')}
      >
        All
      </button>
      <button
        className={activeButton === 'Support' ? styles.active : styles.but}
        onClick={() => handleButtonClick('Support')}
      >
        Support
      </button>
      <button
        className={activeButton === 'Team' ? styles.active : styles.but}
        onClick={() => handleButtonClick('Team')}
      >
        Team
      </button>
    </div>
  </div>
);
};
export default AdminSearchChat;
