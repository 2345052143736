import React, { useEffect, useState } from "react";
import styles from "./LoginModal.module.scss";
import { ReactComponent as Apple } from "../../../../assests/svg/apple.svg";
import { ReactComponent as Facebook } from "../../../../assests/svg/facebook.svg";
import { ReactComponent as Google } from "../../../../assests/svg/google.svg";
import { ReactComponent as Phone } from "../../../../assests/svg/phone.svg";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import { signInWithEmailAndPassword, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { ReactComponent as Back } from "../../../../assests/svg/back.svg";
import { ReactComponent as BackDark } from "../../../../assests/svg/arrowleftDark.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineMail } from "react-icons/ai";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {
  login,
  loginWithGoogle,
  loginWithFacebook,
  AddTokenAction,
} from "../../../../store/actions/authActions";
import Loader from "../../Loader/Loader";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import {
  USER_ERROR_RESET,
  USER_LOGOUT,
  USER_REGISTER_RESET,
} from "../../../../store/constants/authConstants";
import { UPLOAD_PROFILE_PICUTRE_RESET } from "../../../../store/constants/userConstants";
import { getFirebaseToken, app } from "../../../../firebase";
import { BiHide, BiShow, BiLockAlt } from "react-icons/bi";
import { getAuth, OAuthProvider } from "firebase/auth";
import { auth } from "../../../../firebase";
import { useNavigate } from "react-router-dom";
import { loginGoogle } from "../../../../store/actions/authActions";
import logo from '../../../../assests/svg/La7i2_7alak1.1.svg'
const LoginModal = ({ setStep, closeHandler, setEmail }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState(false);
  const handleLogInWithApple = () => {
    const provider = new OAuthProvider('apple.com');
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        const user = result.user;
        try {
          const token = await user.getIdToken();
          localStorage.setItem("token", token);
          localStorage.setItem("uid", user.uid);
          dispatch(loginGoogle(user));
          const formData = new FormData();
          formData.append("username", user.displayName)
          formData.append("email", user.email)
          formData.append("role", "USER")
          formData.append("dob", 15)
          const response = await axios.post('https://backend.la7i27alak.com/user', formData, {
            headers: {
              'Authorization': `${token}`,
              'Content-Type': 'multipart/form-data'
            }
          });
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
          navigate("/editinfo", {
            state: {
              token,
              username: user.displayName,
              photoURL: user.photoURL,
            },
          });
        } catch (error) {
          // console.log("Error storing data in database:", error);
          // Handle error scenario
        }
      })
      .then(closeHandler)
      .catch((error) => {
        // console.log("Error signing in with Apple:", error);
        // Handle error scenario
      });
  };
  const navigate = useNavigate()
  //LOGIN WITH GOOGLE
  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        dispatch(loginGoogle(user));
        
        try {
          const token = await user.getIdToken();
          localStorage.setItem("token", token);
          localStorage.setItem("uid", user.uid);
          const formData = new FormData();
          formData.append("username", user.displayName);
          formData.append("email", user.email);
          formData.append("dob", 15);
          formData.append("role", "USER");
          const response = await axios.post(
            "https://backend.la7i27alak.com/user",
            formData,
            {
              headers: {
                Authorization: `${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("token", token);
          // Redirect to edit info page if signed in with Google
          if (provider.providerId === "google.com") {
            navigate("/editinfo", {
              state: {
                token,
                username: user.displayName,
                photoURL: user.photoURL,
              },
            });
          }
        } catch (error) {
          // console.log(error);
        }
      })
      .then(closeHandler)
      .catch((error) => {
        // console.log(error);
      });
  }
  //LOGIN WITH FACEBOOK
  const handleFacebookLogin = () => {
    // const auth = getAuth();
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const token = result.credential.accessToken;
        const user = result.user;
      })
      .catch((error) => {
        // console.log(error)
        if (error.code === "auth/cancelled-popup-request") {
          // User canceled the authentication popup
        } else {
          // Other sign-in errors
          console.error("Facebook sign-in error:", error);
        }
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = error.credential;
      });
  };
  const [failMessage, setFailMessage] = useState('');
  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem("email")
        ? JSON.parse(localStorage.getItem("email"))
        : "",
      password: localStorage.getItem("password")
        ? JSON.parse(localStorage.getItem("password"))
        : "",
      remember:
        localStorage.getItem("email") || localStorage.getItem("password")
          ? true
          : "",
    },
    onSubmit: async (values) => {
      if (values.remember === true) {
        localStorage.setItem("remember", JSON.stringify(values.remember));
        localStorage.setItem("email", JSON.stringify(values.email));
        localStorage.setItem("password", JSON.stringify(values.password));
      } else if (values.remember === false) {
        localStorage.removeItem("remember");
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }
      try {
        const auth = getAuth();
        const userCredential = await signInWithEmailAndPassword(
          auth,
          values.email,
          values.password
        );
        localStorage.setItem("uid", userCredential.user.uid);
        localStorage.setItem("token", await userCredential.user.getIdToken());
        const response = await axios.get(
          `https://backend.la7i27alak.com/user/${userCredential.user.uid}`,
          {
            headers: {
              Authorization: `${await userCredential.user.getIdToken()}`,
              "Content-Type": "application/json",
            },
          }
        );
        const isVerified = response.data.user.isVerified;
       
        if (isVerified) {
          dispatch(login(values.email, values.password));
          closeHandler()
        } else {
          toast.error('Verify your email.')
          setStep('verify email')
        }
      } catch (error) {
        let errorMessage = "";
        switch (error.code) {
          case "auth/user-not-found":
            toast.error("User not found. Please check your email address and try again.");
            break;
          case "auth/wrong-password":
            toast.error("Wrong password. Please try again.");
            break;
          default:
            toast.error("An error occurred. Please try again later.");
        }
        setFailMessage(errorMessage);
      }
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password is too short"),
    }),
  });
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const handleInputBlur = () => {
    if (failMessage !== '') {
      setFailMessage('');
    }
  }
  return (
    <div
      className={`${styles.login_container} ${darkMode ? styles.login_containerDark : ''}`}
    >
      {darkMode ?
        (
          <CloseDark
            className={`${styles.close}`}
            onClick={() => {
              dispatch({ type: USER_ERROR_RESET });
              closeHandler();
            }}
          />
        ) : (
          <Close
            className={`${styles.close}`}
            onClick={() => {
              dispatch({ type: USER_ERROR_RESET });
              closeHandler();
            }}
          />
        )}
      {darkMode ?
        (
          <BackDark
            className={`${styles.back}`}
            onClick={() => {
              dispatch({ type: USER_ERROR_RESET });
              setStep("first");
            }}
          />
        ) : (
          <Back
            className={`${styles.back}`}
            onClick={() => {
              dispatch({ type: USER_ERROR_RESET });
              setStep("first");
            }}
          />
        )}
      <div className={styles.logoCont}>
      <img src={logo} alt='logo' className={styles.logo}/>
      </div>
      <h2 className={`${styles.welcome} ${darkMode ? styles.welcomeDark : ''}`}
      >Welcome!</h2>
      {/* <p className={`${styles.slogan} ${darkMode ? styles.sloganDark : ''}`}
      >Slogan</p> */}
      <div className={styles.form_container}>
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <label htmlFor="email" className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>
            Email Address
          </label>
          <div
            className={`${styles.input_container} ${formik.errors.email && formik.touched.email
              ? styles.error_input
              : ""
              }`}
          >
            <input
              type="email"
              name="email"
              id="email"
              className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
              placeholder="Email address"
              onChange={formik.handleChange}
              onBlur={(e) => { formik.handleBlur(e); handleInputBlur(); }}
              value={formik.values.email}
            />
            <AiOutlineMail className={styles.input_icon} />
            {formik.errors.email && formik.touched.email && (
              <p className={styles.error_message}>{formik.errors.email}</p>
            )}
          </div>
          <label htmlFor="password" className={`${styles.label} ${darkMode ? styles.labelDark : ''}`}>
            Password
          </label>
          <div
            className={`${styles.input_container} ${formik.errors.password && formik.touched.password
              ? styles.error_input
              : ""
              }`}
          >
            <input
              type={password ? "text" : "password"}
              name="password"
              id="password"
              className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
              placeholder="Password"
              onChange={formik.handleChange}
              onBlur={(e) => { formik.handleBlur(e); handleInputBlur(); }}
              value={formik.values.password}
            />
            <BiLockAlt BiShow className={styles.input_icon} />
            {formik.errors.password && formik.touched.password && (
              <p className={styles.error_message}>{formik.errors.password}</p>
            )}
            {!password && (
              <BiHide
                className={styles.pass_icon}
                onClick={() => setPassword(true)}
              />
            )}
            {password && (
              <BiShow
                className={styles.pass_icon}
                onClick={() => setPassword(false)}
              />
            )}
          </div>
          <div className={styles.forget_container}>
            {error && (
              <div className={styles.message}>
                <ErrorMessage>{error}</ErrorMessage>
              </div>
            )}
            <div className={styles.remember_container}>
              <input
                type="checkbox"
                name="remember"
                id="remember"
                value={formik.values.remember}
                onChange={formik.handleChange}
                checked={formik.values.remember}
              />
              <label htmlFor="remember"
                className={`${styles.remember} ${darkMode ? styles.rememberDark : ''}`}
              >
                Remember Me
              </label>
            </div>
            <span
              className={styles.forget}
              onClick={() => setStep("forget password")}
            >
              Forgot Password?
            </span>
          </div>
          {loading ? (
            <div style={{ alignSelf: "center" }}>
              <Loader />
            </div>
          ) : (
            <>
              {/* {failMessage && <div><p style={{ color: 'red', fontSize: '10px', padding: '0px' }}>{failMessage}</p></div>} */}
              <button className={`${styles.login_btn} ${darkMode ? styles.login_btnDark : ''}`} type={"submit"}>
                Login
              </button>
            </>
          )}
        </form>
        <div className={styles.border}></div>
        <div className={styles.continue}>
          <button
            className={`${styles.continue_btn} ${darkMode ? styles.continue_btnDark : ''}`}
            onClick={handleGoogleLogin}
          >
            <Google className={styles.btn_icon} /> Continue With Google
          </button>
          <div
            className={`${styles.continue_btn} ${darkMode ? styles.continue_btnDark : ''}`}
            onClick={handleFacebookLogin}
          >
            <Facebook className={styles.btn_icon} />
            Continue With Facebook
          </div>
          <button
            className={`${styles.continue_btn} ${darkMode ? styles.continue_btnDark : ''}`}
            onClick={handleLogInWithApple}>
            <Apple className={styles.btn_icon} />
            Continue With Apple
          </button>
          {/* <button
            className={`${styles.continue_btn} ${darkMode ? styles.continue_btnDark : ''}`}
            onClick={() => setStep("login with code")}
          >
            <Phone className={styles.btn_icon} />
            Continue With Phone Number
          </button> */}
          <p className={`${styles.no_account} ${darkMode ? styles.no_accountDark : ''}`}>
            Don't have an account?{" "}
            <span
              className={styles.register_link}
              onClick={() => {
                dispatch({ type: UPLOAD_PROFILE_PICUTRE_RESET });
                dispatch({ type: USER_REGISTER_RESET });
                dispatch({ type: USER_ERROR_RESET });
                setStep("first");
              }}
            >
              Register
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
export default LoginModal;
