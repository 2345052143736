import {
  PLACE_BID_REQUEST,
  PLACE_BID_FAIL,
  PLACE_BID_SUCCESS,
  PLACE_BID_RESET,
  GET_BIDERS_REQUEST,
  GET_BIDERS_FAIL,
  GET_BIDERS_RESET,
  GET_BIDERS_SUCCESS,
  SEND_OFFER_FOR_PRODUCT_REQUEST,
  SEND_OFFER_FOR_PRODUCT_SUCCESS,
  SEND_OFFER_FOR_PRODUCT_FAIL,
} from "../constants/bidConstants";
export const placeBidReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case PLACE_BID_REQUEST:
      return { ...state, loading: true };
    case PLACE_BID_SUCCESS:
      return { ...state, loading: false, success: true };
    case PLACE_BID_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case PLACE_BID_RESET:
      return {
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};
const initialState = {
  acceptpmt: [],
  error: null,
  loading: false,
};
export const SendOfferForProductReducer = (state = initialState, action) => {
  switch (action.type) {
      case SEND_OFFER_FOR_PRODUCT_REQUEST:
          return {
              ...state,
              acceptpmt: action.payload,
              error: null,
              loading: true,
          };
      case SEND_OFFER_FOR_PRODUCT_SUCCESS:
          return {
              ...state,
              acceptpmt: action.payload,
              error: null,
              loading: false,
          };
      case SEND_OFFER_FOR_PRODUCT_FAIL:
          return {
              ...state,
              error: action.payload,
              loading: false,
          };
      default:
          return state;
  }
};
export const getBidersReducer = (
  state = { loading: false, error: null, biders: [] },
  action
) => {
  switch (action.type) {
    case GET_BIDERS_REQUEST:
      return { ...state, loading: true };
    case GET_BIDERS_SUCCESS:
      return { ...state, loading: false, biders: action.payload };
    case GET_BIDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_BIDERS_RESET:
      return {
        loading: false,
        error: null,
        biders: [],
      };
    default:
      return state;
  }
};
