import React, { useState, useEffect } from "react";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import styles from "./AdminCOD.scss";
import DateCont from "./DateCont/DateCont";
import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";
import IMG from "../../assests/images/COD.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import Search from "../../common/components/Search/Search";
import { ref, set } from "firebase/database";
import { db } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { GetSingleProduct } from "../../store/actions/myProductsActions";
import { GetSingleService } from "../../store/actions/servicesActions";
import img from "../../assests/images/Car.png";
import { SearchCODAutocomplete } from "../../store/actions/productDetailsAction";
import {
  CreateCatchOfTheDayProduct,
  GetCOD,
} from "../../store/actions/AdminDashboard";
import { useDispatch, useSelector } from "react-redux";
import {
  PostCODProduct,
  PostCODService,
} from "../../store/actions/ProductStateAction";
import {
  SearchAction,
  SearchAllService,
} from "../../store/actions/productDetailsAction";
import { AiOutlineSearch } from "react-icons/ai";
import { SearchActionService } from "../../store/actions/productDetailsAction";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from "date-fns";
import CatchOfTheDay from "../HomePage/components/Cath of the Day/CatchOfTheDay";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let colStartClasses = [
  "",
  "col-start-1",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const AdminCOD = () => {
  const dispatch = useDispatch();
  let today = startOfToday();
  let [selectedDay, setSelectedDay] = useState(today);
  let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());
  const [isMobile, setIsMobile] = useState(false);
  const imported = false;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });
  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }
  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }
  //timer
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("COD");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [startTDate, setStartTDate] = useState("");
  const [endTDate, setEndTDate] = useState("");
  const [timeLeft, setTimeLeft] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  //PRICES
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const [bid, setBid] = useState(false);
  const [discount, setDiscount] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [error, setError] = useState("");
  const originalPriceValue = 1000;
  const handleDiscountChange = (event) => {
    const discountValue = parseFloat(event.target.value);
    setDiscount(event.target.value);
    setError("");
    if (!isNaN(discountValue) && discountValue > 0 && discountValue < 100) {
      const discountAmount = (discountValue / 100) * selectedPrice;
      const calculatedNewPrice = selectedPrice - discountAmount;
      setNewPrice(calculatedNewPrice.toFixed(2));
    } else {
      setNewPrice("");
      setError("Discount should be between 0 and 100");
    }
  };
  const handleOriginalPriceChange = (event) => {
    setOriginalPrice(event.target.value);
  };
  const handleSetRelease = async (e) => {
    e.preventDefault();
    if (first) {
      try {
        setIsLoading(true);
        if (bid) {
          toast.warning("Cannot release a bid as catch of the day.");
        } else {
          const response = await dispatch(PostCODProduct(selectedId, discount));
          if (response) {
            setSelectedPrice([]);
            setSelectedId([]);
            setNewPrice("");
            setDiscount("");
            setSelectedChips(null);
            setIsProductSelected(false);
            toast.success("Catch of the day added successfully.");
            setBid(false);
            dispatch(GetCOD());
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        setIsLoading(false); // Hide the loader after the API call
      }
    } else {
      try {
        setIsLoading(true);
        const response = await dispatch(PostCODService(selectedId, discount));
        if (response) {
          setSelectedPrice([]);
          setSelectedId([]);
          setNewPrice("");
          setDiscount("");
          setSelectedChips(null);
          setIsProductSelected(false);
          toast.success("Catch of the day added successfully.");
          dispatch(GetCOD());
        }
      } catch (error) {
        // console.log(error);
      } finally {
        setIsLoading(false); // Hide the loader after the API call
      }
    }
  };
  const [activeButton, setActiveButton] = useState("name"); // Initialize the active button

  const handleButtonClick = (buttonId) => {
    setSearchResults([]);
    setSelectedChips(null);
    setSearchTerm("");
    setDiscount("");
    setNewPrice("");
    setSelectedPrice(null);
    setPhoto(null);
    setActiveButton(buttonId);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedChips, setSelectedChips] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [price, setPrice] = useState(null);
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  useEffect(() => {
    if (searchTerm !== "") {
      const trimmedSearchTerm = searchTerm.trim();
      if (first) {
        if (trimmedSearchTerm !== "") {
          const fetchSearchResults = async () => {
            if (activeButton === "id") {
              if (searchTerm.length === 24) {
                setSearchResults([]);
                const data = await dispatch(GetSingleProduct(searchTerm));
                if (data) {
                  const updatedResults = [data.data?.product];
                  setSearchResults(updatedResults);
                }
              }
            } else {
              const data = { isService: false };
              let params = { text: trimmedSearchTerm, rawData: data };
              const response = await dispatch(SearchCODAutocomplete(params));
              setSearchResults(response.suggestions);
            }
          };
          fetchSearchResults();
        } else {
          setSearchResults([]);
        }
      } else {
        if (trimmedSearchTerm !== "") {
          const fetchSearchResults = async () => {
            if (activeButton === "id") {
              if (searchTerm.length === 24) {
                setSearchResults([]);
                const data = await dispatch(GetSingleService(searchTerm));
                if (data) {
                  const updatedResults = [data?.data?.service];
                  setSearchResults(updatedResults);
                }
              }
            } else {
              const data = { isService: true };
              let params = { text: trimmedSearchTerm, rawData: data };
              const response = await dispatch(SearchCODAutocomplete(params));
              setSearchResults(response.suggestions);
            }
          };
          fetchSearchResults();
        } else {
          setSearchResults([]);
        }
      }
    }
  }, [searchTerm, selectedChips, activeButton]);
  const handleSearchSubmit = () => {
    if (searchTerm.trim() !== "") {
      setSelectedChips([...selectedChips, searchTerm.trim()]);
      setSearchTerm("");
    }
  };
  const [isProductSelected, setIsProductSelected] = useState(false);
  const handleChipClick = (chipIndex) => {
    setSelectedChips(selectedChips.filter((_, index) => index !== chipIndex));
  };
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const activateFirst = () => {
    setFirst(true);
    setSecond(false);
    setSearchResults([]);
    setSelectedChips(null);
    setSearchTerm("");
    setDiscount("");
    setNewPrice("");
    setSelectedPrice(null);
    setPhoto(null);
  };
  const activateSecond = () => {
    setFirst(false);
    setSecond(true);
    setSearchResults([]);
    setSelectedChips(null);
    setSearchTerm("");
    setDiscount("");
    setNewPrice("");
    setSelectedPrice(null);
    setPhoto(null);
  };
  return (
    <AdminLayout>
      <div className="wrapper">
        <div className="container">
          <section className="calender_section">
            <div>
              <h1 className="title">Catch Of The Day</h1>
              <DateCont selectedDay={selectedDay} />
            </div>
            <div
              className="md:pr-20"
              style={
                isMobile
                  ? { width: "250px", backgroundColor: "white" }
                  : { backgroundColor: "white" }
              }
            >
              <div
                style={isMobile ? { width: "250px" } : null}
                className="flex items-center"
              >
                <h2
                  className={`flex-auto font-semibold ${
                    darkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  {format(firstDayCurrentMonth, "MMMM yyyy")}
                </h2>
                <button
                  type="button"
                  onClick={previousMonth}
                  className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Previous month</span>
                  <HiOutlineChevronLeft
                    className="w-5 h-5"
                    aria-hidden="true"
                  />
                </button>
                <button
                  onClick={nextMonth}
                  type="button"
                  className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Next month</span>
                  <HiOutlineChevronRight
                    className="w-5 h-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div
                className={`grid grid-cols-7 mt-15 text-md leading-6 text-center ${
                  darkMode ? "text-white" : "text-gray-900"
                }`}
              >
                <div>Mo</div>
                <div>Tu</div>
                <div>We</div>
                <div>Th</div>
                <div>Fr</div>
                <div>Sa</div>
                <div>Su</div>
              </div>
              <div className="grid grid-cols-7 mt-15 text-sm">
                {days.map((day, dayIdx) => (
                  <div
                    key={day.toString()}
                    className={classNames(
                      dayIdx === 0 && colStartClasses[getDay(day)],
                      "py-1.5"
                    )}
                  >
                    <button
                      type="button"
                      onClick={() => setSelectedDay(day)}
                      className={classNames(
                        isEqual(day, selectedDay) && "text-white",
                        !isEqual(day, selectedDay) &&
                          isToday(day) &&
                          "border-2 border-gray-900 ",
                        !isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          isSameMonth(day, firstDayCurrentMonth) &&
                          darkMode
                          ? "text-white"
                          : "text-gray-900",
                        !isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          !isSameMonth(day, firstDayCurrentMonth) &&
                          "text-gray-900",
                        isEqual(day, selectedDay) &&
                          isToday(day) &&
                          "bg-blue-400",
                        isEqual(day, selectedDay) &&
                          !isToday(day) &&
                          "bg-blue-400",
                        !isEqual(day, selectedDay) && "hover:bg-gray-200",
                        (isEqual(day, selectedDay) || isToday(day)) &&
                          "font-semibold",
                        "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                      )}
                    >
                      <time dateTime={format(day, "yyyy-MM-dd")}>
                        {format(day, "d")}
                      </time>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <h3 className="ctitle">Catch of the Day Preview</h3>
            {photo && price && newPrice && name && (
              <div
                style={
                  isMobile
                    ? {
                        backgroundImage: `url(${photo})`,
                        height: "150px",
                        width: "250px",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        opacity: 1,
                        backdropFilter: "2px",
                        position: "relative",
                        borderRadius: "10px",
                      }
                    : {
                        backgroundImage: `url(${photo})`,
                        height: "150px",
                        width: "400px",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        opacity: 1,
                        backdropFilter: "2px",
                        position: "relative",
                        borderRadius: "10px",
                      }
                }
              >
                <h2 className="cproduct__title">{name}</h2>
                <div className="ctime_container">
                  <div className="ctime_square ctext">05</div>
                  <div className="ctext ccenter">:</div>
                  <div className="ctime_square ctext">05</div>
                  <div className="ctext ccenter">:</div>
                  <div className="ctime_square ctext">05</div>
                </div>
                <div className="cprice_container">
                  <h3 className="cprice cold">{price}$</h3>
                  <AiOutlineArrowRight className="carrow" />
                  <h3 className="cprice cnew">{newPrice}$</h3>
                </div>
              </div>
            )}
          </section>
          <section className="productDetail">
            <h4>Offer Details</h4>
            <div className="searchType__container">
              <div className="search__typecd">
                <button
                  className={`buttoncd ${first ? "activecd" : ""}`}
                  onClick={activateFirst}
                >
                  Product
                </button>
                <button
                  className={`buttoncd ${second ? "activecd" : ""}`}
                  onClick={activateSecond}
                >
                  Service
                </button>
              </div>
            </div>
            <div className="inputPairsrch">
              <div className="toggle-button-container">
                <label className="labelinsrch">Search by:</label>
                <button
                  className={`toggle-button ${
                    activeButton === "name" ? "active" : ""
                  }`}
                  onClick={() => handleButtonClick("name")}
                >
                  Name
                </button>
                <button
                  className={`toggle-button ${
                    activeButton === "id" ? "active" : ""
                  }`}
                  onClick={() => handleButtonClick("id")}
                >
                  ID
                </button>
              </div>

              <div className={styles.search_container}>
                <div className={styles.center}>
                  <div className={styles.chips_container}></div>
                  <div className={styles.input_container}>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      className="inpofs"
                      onChange={handleSearchTermChange}
                    />
                  </div>
                </div>
                {searchResults &&
                  searchResults.length > 0 &&
                  searchTerm !== "" && (
                    <div className="codrop">
                      {searchResults.map((product) => (
                        <div
                          onClick={() => {
                            setSelectedChips(product.title);
                            setSelectedId(product._id);
                            setSelectedPrice(product.pricing?.price);
                            setSearchTerm(product.title);
                            setSearchResults([]);
                            setName(product.title);
                            setPrice(product.pricing?.price);
                            setDescription(product.description);
                            setIsProductSelected(true);
                            setPhoto(product.imageUrls[0]);
                            setBid(product.bidding?.enabled);
                          }}
                        >
                          <img
                            src={product?.imageUrls[0]}
                            className="srchimg"
                            alt="product image"
                          />

                          <div key={product?._id} className="codres">
                            <span style={{ fontWeight: "bolder" }}>
                              Title:{" "}
                            </span>
                            {product?.title}
                          </div>
                          <p>
                            <span style={{ fontWeight: "bolder" }}>ID: </span>
                            {product?._id}
                          </p>
                          <p style={{ borderBottom: "2px solid #0298a62b" }}>
                            <span style={{ fontWeight: "bolder" }}>
                              Owner:{" "}
                            </span>
                            {product?.user?.username}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            </div>
            <div className="inputLine">
              <div className="inputPair">
                <label className="labelin">Type</label>
                <select
                  className="inpof"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="COD">Catch Of The Day</option>
                  <option value="DISC">Discount</option>
                </select>
              </div>
              <div className="inputPair">
                <label className="labelin">Discount(%)</label>
                <input
                  className="inpof"
                  type="number"
                  placeholder="Discount"
                  id="discountInput"
                  value={discount}
                  onChange={handleDiscountChange}
                />
              </div>
            </div>
            <p style={{ color: "red" }}>{error}</p>
            <div className="inputLine">
              <div className="inputPair">
                <label className="labelin">Original Price</label>
                <input
                  className="inpof"
                  type="text"
                  id="originalPriceInput"
                  value={selectedPrice}
                  disabled
                />
              </div>
              <div className="inputPair">
                <label className="labelin">New Price</label>
                <input
                  className="inpof"
                  type="text"
                  placeholder="After Discount"
                  id="newPriceInput"
                  value={newPrice}
                  disabled
                />
              </div>
            </div>
            <div className={`butdiv ${isLoading ? "loading" : ""}`}>
              <button
                className={`submitBut`}
                onClick={handleSetRelease}
                disabled={isLoading}
              >
                {isLoading ? "Set to release..." : "Set to release"}
              </button>
            </div>
          </section>
        </div>
      </div>
    </AdminLayout>
  );
};
export default AdminCOD;
