import {
    ADD_ADDRESS_REQUEST,
    ADD_ADDRESS_SUCCESS,
    ADD_ADDRESS_FAIL,
    MAKE_DEFAULT_FAIL,
    MAKE_DEFAULT_REQUEST,
    MAKE_DEFAULT_SUCCESS,
    GET_ADDRESS_BY_USER_FAIL,
    GET_ADDRESS_BY_USER_REQUEST,
    GET_ADDRESS_BY_USER_SUCCESS,
    DELETE_ADDRESS_FAIL,
    DELETE_ADDRESS_SUCCESS
} from "../constants/addAddressConstants";
import { url } from "../../common/constants/url";
import axios from "axios";
export const addAddress = (rawData) => {
    return async (dispatch) => {
        dispatch(addAddressRequest());
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${url}/address/`, rawData, {
                headers: {
                    'Authorization': `${token}`,
                    'Content-Type': 'application/json'
                },
            });
            
            dispatch(addAddressSuccess(response.data?.data?.address));
            return response.data?.data?.address
        } catch (error) {
            dispatch(addAddressFailure(error));
            // console.log(error);
        }
    };
};
const addAddressRequest=()=> ({
    type: ADD_ADDRESS_REQUEST,
});
const addAddressSuccess = (address) => ({
    type: ADD_ADDRESS_SUCCESS,
    payload: address,
});
const addAddressFailure = (error) => ({
    type: ADD_ADDRESS_FAIL,
    payload: error,
});
export const makeDefault = (addressId) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token');
            
            const response = await axios.get(`${url}/address/default/${addressId}`, {
                headers: {
                    'Authorization': `${token}`
                },
            });
            
            dispatch(makeDefaultSuccess(response));
            return response 
        } catch (error) {
            dispatch(makeDefaultFailure(error));
            // console.log(error);
        }
    };
};
export const makeDefaultSuccess = (id) => {
    return {
      type: MAKE_DEFAULT_SUCCESS,
      payload: id,
    };
  };
const makeDefaultFailure = (error) => ({
    type: MAKE_DEFAULT_FAIL,
    payload: error,
});
export const getAddressByUser = () => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token');
            
            const response = await axios.get(`${url}/address`, {
                headers: {
                    'Authorization': `${token}`
                },
            });
            dispatch(getAddressByUserSuccess(response.data?.data?.addresses));
            return response.data?.data?.addresses
        } catch (error) {
            dispatch(getAddressByUserFail(error));
            // console.log(error);
        }
    };
};
export const getAddressByUserSuccess = (address) => ({
    type: GET_ADDRESS_BY_USER_SUCCESS,
    payload: address,
});
const getAddressByUserFail = (error) => ({
    type: GET_ADDRESS_BY_USER_FAIL,
    payload: error,
});
export const DeleteAddress = (id) => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token');
            
            const response = await axios.delete(`${url}/address/${id}`, {
                headers: {
                    'Authorization': `${token}`
                },
            });
            dispatch(DeleteAddressSuccess(response));
            return response
        } catch (error) {
            dispatch(DeleteAddressFail(error));
            // console.log(error);
        }
    };
};
export const DeleteAddressSuccess = (address) => ({
    type: DELETE_ADDRESS_SUCCESS,
    payload: address,
});
const DeleteAddressFail = (error) => ({
    type: DELETE_ADDRESS_FAIL,
    payload: error,
});