import {
    GET_ADDRESS_BY_USER_FAIL,
    GET_ADDRESS_BY_USER_REQUEST,
    GET_ADDRESS_BY_USER_SUCCESS
} from "../constants/addAddressConstants";
const initialState = {
    address: [],
    error: null,
    loading: false,
};
export const GetAddressreducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADDRESS_BY_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_ADDRESS_BY_USER_SUCCESS:
            return {
                ...state,
                address: action.payload,
                error: null,
                loading: false,
            };
        case GET_ADDRESS_BY_USER_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
