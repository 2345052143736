import {
  GET_PRODUCT_BY_MONTH_SUCCESS,
  GET_PRODUCT_BY_MONTH_FAIL,
  GET_TOTAL_BIDS_SUCCESS,
  GET_TOTAL_BIDS_FAIL,
  GET_PRODUCT_DIFFERENCE_SUCCESS,
  GET_PRODUCT_DIFFERENCE_FAIL,
  GET_SERVICE_DIFFERENCE_SUCCESS,
  GET_SERVICE_DIFFERENCE_FAIL,
  GET_PRODUCT_COUNT_SUCCESS,
  GET_PRODUCT_COUNT_FAIL,
  GET_SERVICE_COUNT_SUCCESS,
  GET_SERVICE_COUNT_FAIL,
  GET_PRODUCT_COUNT_BY_DATE_SUCCESS,
  GET_PRODUCT_COUNT_BY_DATE_FAIL,
  GET_SERVICES_COUNT_BY_DATE_SUCCESS,
  GET_SERVICES_COUNT_BY_DATE_FAIL,
  GET_PRODUCTS_PENDING_SUCCESS,
  GET_PRODUCTS_PENDING_FAIL,
  GET_ALL_PRODUCTS_CONFIRMED_SUCCESS,
  GET_ALL_PRODUCTS_CONFIRMED_FAIL,
  GET_PRODUCTS_SOLD_SUCCESS,
  GET_PRODUCTS_SOLD_FAIL,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_MERCHANT_SUCCESS,
  GET_MERCHANT_FAIL,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAIL,
  UPDATE_CAROUSEL_SUCCESS,
  UPDATE_CAROUSEL_FAIL,
  GET_CAROUSEL_SUCCESS,
  GET_CAROUSEL_FAIL,
  CREATE_CATCH_OF_THE_DAY_PRODUCT_SUCCESS,
  CREATE_CATCH_OF_THE_DAY_PRODUCT_FAIL,
  GET_COD_SUCCESS,
  GET_COD_FAIL,
  GET_TOP_SELLING_USER_SUCCESS,
  GET_TOP_SELLING_USER_FAIL,
  GET_AVERAGE_DEAL_RESULTS_SUCCESS,
  GET_AVERAGE_DEAL_RESULTS_FAIL,
  GET_TOTAL_SALES_SUCCESS,
  GET_TOTAL_SALES_FAIL,
  CHANGE_ROLE_SUCCESS,
  CHANGE_ROLE_FAIL,
  GET_SERVICES_PENDING_SUCCESS,
  GET_SERVICES_PENDING_FAIL,
  GET_ALL_SERVICES_CONFIRMED_SUCCESS,
  GET_ALL_SERVICES_CONFIRMED_FAIL,
  GET_RANGE_FOR_ADMIN_SUCCESS,
  GET_RANGE_FOR_ADMIN_FAIL,
  GET_ONLINE_USERS_SUCCESS,
  GET_ONLINE_USERS_FAIL,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_FAIL,
  CREATE_PAYMENT_SUCCESS,
  CREATE_PAYMENT_FAIL,
  DELETE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_FAIL,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAIL,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAIL,
  DELETE_TYPE_SUCCESS,
  DELETE_TYPE_FAIL,
  DELETE_SIZE_SUCCESS,
  DELETE_SIZE_FAIL,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAIL,
  GET_ENDED_BIDS_SUCCESS,
  GET_ENDED_BIDS_FAIL,
  GET_ENDED_BIDS_REQUEST,
  GET_RATE_EXPERIENCE_REQUEST,
  GET_RATE_EXPERIENCE_SUCCESS,
  GET_RATE_EXPERIENCE_FAIL
} from "../constants/adminDashboardConstants";
import { url } from "../../common/constants/url";
import axios from "axios";
//GET PRODUCT BY MONTH
export const GetProductByMonth = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/products/months`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetProductByMonthSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetProductByMonthFail(error));
      // console.log(error);
    }
  };
};
export const GetProductByMonthSuccess = (product) => {
  return {
    type: GET_PRODUCT_BY_MONTH_SUCCESS,
    payload: product,
  };
};
export const GetProductByMonthFail = (error) => ({
  type: GET_PRODUCT_BY_MONTH_FAIL,
  payload: error,
});
//GET TOTAL BIDS
export const GetTotalBids = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/products/bids`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetTotalBidsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetTotalBidsFail(error));
      // console.log(error);
    }
  };
};
export const GetTotalBidsSuccess = (product) => {
  return {
    type: GET_TOTAL_BIDS_SUCCESS,
    payload: product,
  };
};
export const GetTotalBidsFail = (error) => ({
  type: GET_TOTAL_BIDS_FAIL,
  payload: error,
});
//GET PRODUCT DIFFERENCE
export const GetProductDifference = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/products/compare`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetProductDifferenceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetProductDifferenceFail(error));
      // console.log(error);
    }
  };
};
export const GetProductDifferenceSuccess = (product) => {
  return {
    type: GET_PRODUCT_DIFFERENCE_SUCCESS,
    payload: product,
  };
};
export const GetProductDifferenceFail = (error) => ({
  type: GET_PRODUCT_DIFFERENCE_FAIL,
  payload: error,
});
//GET SERVICE DIFFERENCE
export const GetServiceDifference = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/services/compare`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetServiceDifferenceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetServiceDifferenceFail(error));
      // console.log(error);
    }
  };
};
export const GetServiceDifferenceSuccess = (product) => {
  return {
    type: GET_SERVICE_DIFFERENCE_SUCCESS,
    payload: product,
  };
};
export const GetServiceDifferenceFail = (error) => ({
  type: GET_SERVICE_DIFFERENCE_FAIL,
  payload: error,
});
//GET PRODUCTS COUNT
export const GetProductsCount = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/products/count`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetProductsCountSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetProductsCountFail(error));
      // console.log(error);
    }
  };
};
export const GetProductsCountSuccess = (product) => {
  return {
    type: GET_PRODUCT_COUNT_SUCCESS,
    payload: product,
  };
};
export const GetProductsCountFail = (error) => ({
  type: GET_PRODUCT_COUNT_FAIL,
  payload: error,
});
//GET SERVICE COUNT
export const GetServiceCount = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/services/count`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetServicesCountSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetServicesCountFail(error));
      // console.log(error);
    }
  };
};
export const GetServicesCountSuccess = (product) => {
  return {
    type: GET_SERVICE_COUNT_SUCCESS,
    payload: product,
  };
};
export const GetServicesCountFail = (error) => ({
  type: GET_SERVICE_COUNT_FAIL,
  payload: error,
});
//GET PRODUCTS COUNT BY DATE
export const GetProductsCountByDate = (date) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/products/count/${date}`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetProductsCountByDateSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetProductsCountByDateFail(error));
      // console.log(error);
    }
  };
};
export const GetProductsCountByDateSuccess = (product) => {
  return {
    type: GET_PRODUCT_COUNT_BY_DATE_SUCCESS,
    payload: product,
  };
};
export const GetProductsCountByDateFail = (error) => ({
  type: GET_PRODUCT_COUNT_BY_DATE_FAIL,
  payload: error,
});
//GET SERVICES COUNT BY DATE
export const GetServicesCountByDate = (date) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/services/count/${date}`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetServicesCountByDateSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetServicesCountByDateFail(error));
      // console.log(error);
    }
  };
};
export const GetServicesCountByDateSuccess = (product) => {
  return {
    type: GET_SERVICES_COUNT_BY_DATE_SUCCESS,
    payload: product,
  };
};
export const GetServicesCountByDateFail = (error) => ({
  type: GET_SERVICES_COUNT_BY_DATE_FAIL,
  payload: error,
});
//GET PENDING PRODUCT
export const GetPendingProduct = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/products/pending`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetProductsPendingSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetProductsPendingFail(error));
      // console.log(error);
    }
  };
};
export const GetProductsPendingSuccess = (product) => {
  return {
    type: GET_PRODUCTS_PENDING_SUCCESS,
    payload: product,
  };
};
export const GetProductsPendingFail = (error) => ({
  type: GET_PRODUCTS_PENDING_FAIL,
  payload: error,
});
//GET CONFIRMED PRODUCT
export const GetConfirmedProduct = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/products/confirmed`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetProductsConfirmedSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetProductsConfirmedFail(error));
      // console.log(error);
    }
  };
};
export const GetProductsConfirmedSuccess = (product) => {
  return {
    type: GET_ALL_PRODUCTS_CONFIRMED_SUCCESS,
    payload: product,
  };
};
export const GetProductsConfirmedFail = (error) => ({
  type: GET_ALL_PRODUCTS_CONFIRMED_FAIL,
  payload: error,
});
//GET SOLD PRODUCTS
export const GetSoldProduct = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/products/sold`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetProductsSoldSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetProductsSoldFail(error));
      // console.log(error);
    }
  };
};
export const GetProductsSoldSuccess = (product) => {
  return {
    type: GET_PRODUCTS_SOLD_SUCCESS,
    payload: product,
  };
};
export const GetProductsSoldFail = (error) => ({
  type: GET_PRODUCTS_SOLD_FAIL,
  payload: error,
});
//GET USERS
export const GetUsers = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/get/users/user`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetUsersSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetUsersFail(error));
      // console.log(error);
    }
  };
};
export const GetUsersSuccess = (product) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: product,
  };
};
export const GetUsersFail = (error) => ({
  type: GET_USERS_FAIL,
  payload: error,
});
//GET ALL USERS
export const GetAllUsers = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/get/users`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetAllUsersSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetAllUsersFail(error));
      // console.log(error);
    }
  };
};
export const GetAllUsersSuccess = (product) => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: product,
  };
};
export const GetAllUsersFail = (error) => ({
  type: GET_ALL_USERS_FAIL,
  payload: error,
});
//GET MERCHANTS
export const GetMerchant = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/get/users/merchants`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetMerchantSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetMerchantFail(error));
      // console.log(error);
    }
  };
};
export const GetMerchantSuccess = (product) => {
  return {
    type: GET_MERCHANT_SUCCESS,
    payload: product,
  };
};
export const GetMerchantFail = (error) => ({
  type: GET_MERCHANT_FAIL,
  payload: error,
});
//GET ADMINS
export const GetAdmin = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/get/users/admins`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetAdminSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetAdminFail(error));
      // console.log(error);
    }
  };
};
export const GetAdminSuccess = (product) => {
  return {
    type: GET_ADMIN_SUCCESS,
    payload: product,
  };
};
export const GetAdminFail = (error) => ({
  type: GET_ADMIN_FAIL,
  payload: error,
});
//UPDATE CAROUSEL
export const UpdateCarousel = (formData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(`${url}/updateCarousel`, formData, {
        headers: {
          authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(UpdateCarouselSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(UpdateCarouselFail(error));
      // console.log(error);
    }
  };
};
export const UpdateCarouselSuccess = (product) => {
  return {
    type: UPDATE_CAROUSEL_SUCCESS,
    payload: product,
  };
};
export const UpdateCarouselFail = (error) => ({
  type: UPDATE_CAROUSEL_FAIL,
  payload: error,
});
//GET CAROUSEL
export const GetCarousel = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      let response = {};
      if (token) {
        response = await axios.get(`${url}/getCarousel`, {
          headers: {
            authorization: `${token}`,
          },
        });
      } else {
        response = await axios.get(`${url}/getCarousel`, {});
      }
      dispatch(GetCarouselSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetCarouselFail(error));
      // console.log(error);
    }
  };
};
export const GetCarouselSuccess = (product) => {
  return {
    type: GET_CAROUSEL_SUCCESS,
    payload: product,
  };
};
export const GetCarouselFail = (error) => ({
  type: GET_CAROUSEL_FAIL,
  payload: error,
});
//ADD CATCH OF THE DAY PRODUCT
export const CreateCatchOfTheDayProduct = (ProductId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${url}/admin/setProduct/catch/${ProductId}`,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      dispatch(CreateCatchOfTheDayProductSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(CreateCatchOfTheDayProductFail(error));
      // console.log(error);
    }
  };
};
export const CreateCatchOfTheDayProductSuccess = (product) => {
  return {
    type: CREATE_CATCH_OF_THE_DAY_PRODUCT_SUCCESS,
    payload: product,
  };
};
export const CreateCatchOfTheDayProductFail = (error) => ({
  type: CREATE_CATCH_OF_THE_DAY_PRODUCT_FAIL,
  payload: error,
});
//GET CATCH OF THE DAY
export const GetCOD = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      let response = {};
      if (token) {
        response = await axios.get(`${url}/getCatchOfTheDay`, {
          headers: {
            authorization: `${token}`,
          },
        });
      } else {
        response = await axios.get(`${url}/getCatchOfTheDay`, {});
      }
      dispatch(GetCODSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetCODFail(error));
      // console.log(error);
    }
  };
};
export const GetCODSuccess = (product) => {
  return {
    type: GET_COD_SUCCESS,
    payload: product,
  };
};
export const GetCODFail = (error) => ({
  type: GET_COD_FAIL,
  payload: error,
});
export const GetTopSellingUserOfTheDay = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${url}/admin/getTopSelling`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetTopSellingUserOfTheDaySuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetTopSellingUserOfTheDayFail(error));
      // console.log(error);
    }
  };
};
export const GetTopSellingUserOfTheDaySuccess = (product) => {
  return {
    type: GET_TOP_SELLING_USER_SUCCESS,
    payload: product,
  };
};
export const GetTopSellingUserOfTheDayFail = (error) => ({
  type: GET_TOP_SELLING_USER_FAIL,
  payload: error,
});
export const GetAverageDealResults = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${url}/admin/offer/statusByDay`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetAverageDealResultsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetAverageDealResultsFail(error));
      // console.log(error);
    }
  };
};
export const GetAverageDealResultsSuccess = (product) => {
  return {
    type: GET_AVERAGE_DEAL_RESULTS_SUCCESS,
    payload: product,
  };
};
export const GetAverageDealResultsFail = (error) => ({
  type: GET_AVERAGE_DEAL_RESULTS_FAIL,
  payload: error,
});
export const GetTotalSales = (rawData) => {
  return async (dispatch) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");

    // Check if the token is available
    if (!token) {
      // Handle the case where the token is missing
      const errorMessage = "Authentication token is missing or invalid.";
      dispatch(GetTotalSalesFail(errorMessage));
      console.error(errorMessage);
      return; // Return early to prevent making the API request
    }

    try {
      const response = await axios.post(`${url}/product/getSoldProducts`, rawData, {
        headers: {
          Authorization: `${token}`, // Update the header format
          'Content-Type': 'application/json',
        },
      });
      dispatch(GetTotalSalesSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetTotalSalesFail(error));
      console.error(error);
    }
  };
};

export const GetTotalSalesSuccess = (product) => {
  return {
    type: GET_TOTAL_SALES_SUCCESS,
    payload: product,
  };
};
export const GetTotalSalesFail = (error) => ({
  type: GET_TOTAL_SALES_FAIL,
  payload: error,
});
export const ChangeRole = (rawData) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(`${url}/admin/changeRole`, rawData, {
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(ChangeRoleSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(ChangeRoleFail(error));
      // console.log(error);
    }
  };
};
export const ChangeRoleSuccess = (product) => {
  return {
    type: CHANGE_ROLE_SUCCESS,
    payload: product,
  };
};
export const ChangeRoleFail = (error) => ({
  type: CHANGE_ROLE_FAIL,
  payload: error,
});
//GET PENDING service
export const GetPendingService = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/services/pending`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetServicesPendingSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetServicesPendingFail(error));
      // console.log(error);
    }
  };
};
export const GetServicesPendingSuccess = (product) => {
  return {
    type: GET_SERVICES_PENDING_SUCCESS,
    payload: product,
  };
};
export const GetServicesPendingFail = (error) => ({
  type: GET_SERVICES_PENDING_FAIL,
  payload: error,
});
//GET CONFIRMED SERVICE
export const GetConfirmedService = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/services/confirmed`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetServicesConfirmedSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetServicesConfirmedFail(error));
      // console.log(error);
    }
  };
};
export const GetServicesConfirmedSuccess = (product) => {
  return {
    type: GET_ALL_SERVICES_CONFIRMED_SUCCESS,
    payload: product,
  };
};
export const GetServicesConfirmedFail = (error) => ({
  type: GET_ALL_SERVICES_CONFIRMED_FAIL,
  payload: error,
});
export const GetRangeForAdmin = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${url}/admin/all/range`, rawData, {
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(GetRangeForAdminSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetRangeForAdminFail(error));
      // console.log(error);
    }
  };
};
export const GetRangeForAdminSuccess = (product) => {
  return {
    type: GET_RANGE_FOR_ADMIN_SUCCESS,
    payload: product,
  };
};
export const GetRangeForAdminFail = (error) => ({
  type: GET_RANGE_FOR_ADMIN_FAIL,
  payload: error,
});
export const GetOnlineUsers = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${url}/api/online-user-count`, {});
      dispatch(GetOnlineUsersSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetOnlineUsersFail(error));
      // console.log(error);
    }
  };
};
export const GetOnlineUsersSuccess = (product) => {
  return {
    type: GET_ONLINE_USERS_SUCCESS,
    payload: product,
  };
};
export const GetOnlineUsersFail = (error) => ({
  type: GET_ONLINE_USERS_FAIL,
  payload: error,
});
export const GetAllReports = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/report/getAllReports`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetAllReportsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetAllReportsFail(error));
      // console.log(error);
    }
  };
};
export const GetAllReportsSuccess = (product) => {
  return {
    type: GET_REPORTS_SUCCESS,
    payload: product,
  };
};
export const GetAllReportsFail = (error) => ({
  type: GET_REPORTS_FAIL,
  payload: error,
});
export const GetPayment = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/getAllPayments`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetPaymentSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetPaymentFail(error));
      // console.log(error);
    }
  };
};
export const GetPaymentSuccess = (product) => {
  return {
    type: GET_PAYMENT_SUCCESS,
    payload: product,
  };
};
export const GetPaymentFail = (error) => ({
  type: GET_PAYMENT_FAIL,
  payload: error,
});
export const CreatePayment = (rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(`${url}/createPaymentAmount`, rawData, {
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(CreatePaymentSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(CreatePaymentFail(error));
      // console.log(error);
    }
  };
};
export const CreatePaymentSuccess = (product) => {
  return {
    type: CREATE_PAYMENT_SUCCESS,
    payload: product,
  };
};
export const CreatePaymentFail = (error) => ({
  type: CREATE_PAYMENT_FAIL,
  payload: error,
});
export const DeletePayment = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${url}/delete/payment/${id}`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(DeletePaymentSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(DeletePaymentFail(error));
      // console.log(error);
    }
  };
};
export const DeletePaymentSuccess = (product) => {
  return {
    type: DELETE_PAYMENT_SUCCESS,
    payload: product,
  };
};
export const DeletePaymentFail = (error) => ({
  type: DELETE_PAYMENT_FAIL,
  payload: error,
});
export const UpdatePayment = (id, rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(`${url}/update/payment/${id}`, rawData, {
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(UpdatePaymentSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(UpdatePaymentFail(error));
      // console.log(error);
    }
  };
};
export const UpdatePaymentSuccess = (product) => {
  return {
    type: UPDATE_PAYMENT_SUCCESS,
    payload: product,
  };
};
export const UpdatePaymentFail = (error) => ({
  type: UPDATE_PAYMENT_FAIL,
  payload: error,
});

export const DeleteFAQ = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${url}/faq/delete/${id}`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(DeleteFAQSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(DeleteFAQFail(error));
      // console.log(error);
    }
  };
};
export const DeleteFAQSuccess = (product) => {
  return {
    type: DELETE_FAQ_SUCCESS,
    payload: product,
  };
};
export const DeleteFAQFail = (error) => ({
  type: DELETE_FAQ_FAIL,
  payload: error,
});

export const DeleteSize = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${url}/industrySizes/${id}`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(DeleteSizeSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(DeleteSizeFail(error));
      // console.log(error);
    }
  };
};
export const DeleteSizeSuccess = (product) => {
  return {
    type: DELETE_SIZE_SUCCESS,
    payload: product,
  };
};
export const DeleteSizeFail = (error) => ({
  type: DELETE_SIZE_FAIL,
  payload: error,
});

export const DeleteType = (id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${url}/industryTypes/${id}`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(DeleteTypeSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(DeleteTypeFail(error));
      // console.log(error);
    }
  };
};
export const DeleteTypeSuccess = (product) => {
  return {
    type: DELETE_TYPE_SUCCESS,
    payload: product,
  };
};
export const DeleteTypeFail = (error) => ({
  type: DELETE_TYPE_FAIL,
  payload: error,
});

export const UpdateFaq = (id, rawData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(`${url}/faq/update/${id}`, rawData, {
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      dispatch(UpdateFaqSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(UpdateFaqFail(error));
      // console.log(error);
    }
  };
};
export const UpdateFaqSuccess = (product) => {
  return {
    type: UPDATE_FAQ_SUCCESS,
    payload: product,
  };
};
export const UpdateFaqFail = (error) => ({
  type: UPDATE_FAQ_FAIL,
  payload: error,
});

export const GetEndedBids = () => {
  return async (dispatch) => {
    try {
      dispatch(GetEndedBidsRequest());
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/admin/getAllEndedBids`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetEndedBidsSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetEndedBidsFail(error));
      // console.log(error);
    }
  };
};
export const GetEndedBidsRequest = () => {
  return {
    type: GET_ENDED_BIDS_REQUEST,
  };
};
export const GetEndedBidsSuccess = (product) => {
  return {
    type: GET_ENDED_BIDS_SUCCESS,
    payload: product,
  };
};
export const GetEndedBidsFail = (error) => ({
  type: GET_ENDED_BIDS_FAIL,
  payload: error,
});


export const GetRateExperience = () => {
  return async (dispatch) => {
    try {
      dispatch(GetExperienceRequest());
      const token = localStorage.getItem("token");
      const response = await axios.get(`${url}/rating/getAllRatings`, {
        headers: {
          authorization: `${token}`,
        },
      });
      dispatch(GetRateExperienceSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(GetExperienceFail(error));
      // console.log(error);
    }
  };
};
export const GetExperienceRequest = () => {
  return {
    type: GET_RATE_EXPERIENCE_REQUEST,
  };
};
export const GetRateExperienceSuccess = (product) => {
  return {
    type: GET_RATE_EXPERIENCE_SUCCESS,
    payload: product,
  };
};
export const GetExperienceFail = (error) => ({
  type: GET_RATE_EXPERIENCE_FAIL,
  payload: error,
});
