import {
    GET_PAYMENT_AMOUNTS_SUCCESS,
    GET_PAYMENT_AMOUNTS_FAIL
} from "../constants/userConstants";
const initialState = {
    amount: [],
    error: null,
    loading: false,
};
export const GetPaymentAmount = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYMENT_AMOUNTS_SUCCESS:
            return {
                ...state,
                amount: action.payload,
                error: null,
                loading: false,
            };
        case GET_PAYMENT_AMOUNTS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
