import React, { useState } from "react";
import ReactDom from "react-dom";
import FavoriteCategories from "../../../Modal/Favorite Categories/FavoriteCategories";
import styles from "./test.module.scss";
const TestModal = ({ open, onClose }) => {
  const [step, setstep] = useState("login");
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div
        className={styles.overlay}
        onClick={() => {
          onClose();
        }}
      >
        {" "}
      </div>
      <div className={styles.modal_styles}>
        <FavoriteCategories setStep={setstep} closeHandler={onClose} />
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default TestModal;
