import React from "react";
import "./Status.scss";
const Status = ({ state }) => {
  let color = "";
  switch (state.toLowerCase()) {
    case "sold":
      color = "red";
      break;
    case "active":
      color = "blue";
      break;
    default:
      color = "gray";
  }
  return (
    <div className={`statediv ${color}`}>
      <span className="state">{state}</span>
    </div>
  );
};
export default Status;