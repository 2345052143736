import React, { useEffect, useState } from "react";
import "./ChatPage.scss";
import Layout from "../../common/components/Layouts/Layout";
import bg from "../../assests/svg/empty chat.svg";
import styles from "./ChatPage.scss"
import nochat from '../../assests/svg/undraw_account_re_o7id 1.svg'
import SearchChat from "./SearchChat/SearchChat";
import Chats from './chats/chats'
import ChatHeader from './chats/chatHeader/chatHeader'
import ChatBody from './chats/Body/chatBody'
import ChatInput from "./chats/input/ChatInput";
import { BiArrowBack } from "react-icons/bi";
import IMG from '../../assests/images/Babies&Kids.png'
import { db, ref } from "../../firebase";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { onValue } from 'firebase/database';
import { GetUser } from "../../store/actions/userActions";
import ProductCard from "../UserPage/components/ProductCard/ProductCard";
import { Navigate, useNavigate } from "react-router-dom";
import { timePosted } from "../../common/Utils";
import { set } from 'firebase/database';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";
const ChatPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.isProductDetails) {
      const pageName = "Chat "; // Replace this with the actual page name
      const pageUrl = "/chat"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Chat "; // Replace this with the actual page name
      const pageUrl = "/chat"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, []);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const { chatid } = useParams();
  const [lastFriendMessage, setLastFriendMessage] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesdb, setMessagesdb] = useState([])
  const [ConvoRoomID, setConvoRoomId] = useState("");
  const [users, setUsers] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const specificUserId = localStorage.getItem('uid')
  const addMessage = (newMessage) => {
    setMessages([...messages, newMessage]);
  };
  const [selectedUser, setSelectedUser] = useState(null);
  const [leftHidden, setLeftHidden] = useState(false);
  const handleGoBack = () => {
    setShowChat(false);
    setLeftHidden(false);
  };
  const [isCardVisible, setIsCardVisible] = useState(true);
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };
  useEffect(() => {
    const chatListRef = ref(db, 'chatlist/' + specificUserId);
    const unsubscribe = onValue(chatListRef, (snapshot) => {
      const chatListData = snapshot.val();
      const userIDs = chatListData ? Object.keys(chatListData) : [];
      const fetchUserProfiles = async () => {
        const userProfilePromises = userIDs.map((uid) =>
          new Promise((resolve, reject) => {
            const userRef = ref(db, 'chatlist/' + specificUserId + '/' + uid);
            onValue(userRef, (snapshot) => {
              const userData = snapshot.val();
              resolve(userData);
            }, (error) => {
              reject(error);
            });
          })
        );
        try {
          const userProfilesData = await Promise.all(userProfilePromises);
          // Sort user profiles based on the timePosted
          const sortedUserProfiles = userProfilesData.sort((a, b) => {
            const timeA = timePosted(a.sendTime);
            const timeB = timePosted(b.sendTime);
            if (timeA < timeB) return -1;
            if (timeA > timeB) return 1;
            return 0;
          });
          setUserProfiles(sortedUserProfiles);
        } catch (error) {
          // console.log('Error fetching user data:', error);
        }
      };
      fetchUserProfiles();
    });
    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    // Check if chatid is provided in the URL
    if (chatid) {
      // Find the user with the matching chatid from userProfiles
      const userWithChatId = userProfiles.find((user) => user.roomId === chatid);
      if (userWithChatId) {
        // If the user is found, open the chat room
        handleChatClick(userWithChatId);
      } else {
        // If the user is not found, handle the error or redirect to a not-found page
        // console.log("Chat room not found with chatid:", chatid);
        // Handle the error or redirect here...
      }
    }
  }, [chatid, userProfiles]);
  const resetChatState = () => {
    setConvoRoomId("");
    setSelectedUser(null);
    setShowChat(false);
    setLeftHidden(false);
  };
  const handleChatClick = (user) => {
    setConvoRoomId(user.roomId);
    setSelectedUser(user);
    setShowChat(true);
    setLeftHidden(true);
    const chatListMessages = ref(db, 'messages/' + user.roomId);
    const unsubscribe = onValue(chatListMessages, (snapshot) => {
      const chatListData = snapshot.val();
      const userIDs = chatListData ? Object.keys(chatListData) : [];
      const userIDsGlobal = userIDs;
      const fetchMessages = async () => {
        const messagesPromises = userIDsGlobal.map((uid) =>
          new Promise((resolve, reject) => {
            const userRef = ref(db, 'messages/' + user.roomId + '/' + uid);
            onValue(userRef, (snapshot) => {
              const userData = snapshot.val();
              resolve(userData);
            }, (error) => {
              reject(error);
            });
          })
        );
        try {
          const messagesData = await Promise.all(messagesPromises);
          setMessagesdb(messagesData);
          const filteredMessage = Object.values(messagesData).filter(
            (message) => message.from === user.id
          );
          const lastMessage = filteredMessage[filteredMessage.length - 1];
          setLastFriendMessage(lastMessage);
          const chatListRefA = ref(
            db,
            '/chatlist/' + specificUserId + '/' + user.id + '/unread'
          );
          set(chatListRefA, 0)
            .catch((error) => {
              console.error('Error updating unread count for User A:', error);
            });
        } catch (error) {
          // console.log('Error fetching user data:', error);
        }
      };
      fetchMessages();
    });
    navigate(`/chat/${user.roomId}`);
  };
  const sortedUserProfiles = userProfiles.sort((a, b) => {
    return new Date(b.sendTime) - new Date(a.sendTime);
  }); 
  const previousScrollY = window.scrollY;
  window.scrollTo(0, previousScrollY);
  useEffect(() => {
    const handlePopState = () => {
    navigate(-1)
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Chat Page</title>
        <meta name="Chat Page" content="Chat the seller" />
      </Helmet>
      <Layout title="Chat">
        {userProfiles.length === 0 ? (
          <div className="noconv">
            <img className="nochatp" src={nochat} alt="nochat" />
            <h4 className={darkMode ? "startchatDark" : "startchat"}>No Messages Yet?</h4>
            <p className={darkMode ? "findtxtDark" : "findtxt"}>Find a product you like and start a conversation!</p>
            <button className={darkMode ? "goshopDark" : "goshop"} onClick={() => navigate("/")}>
              Go Shopping
            </button>
          </div>
        ) :
          (
            <div className="chat_page">
              <div className={leftHidden ? `left ${darkMode ? 'leftDark' : ''} hidden` : `left ${darkMode ? 'leftDark' : ''}`}
              >
                <SearchChat />
                {sortedUserProfiles.map((user, index) => (
                  <Chats key={index} onClick={handleChatClick} user={user} />
                ))}
              </div>
              <div className={showChat ? 'right' : 'right_open'}>
                {showChat ? (
                  <>
                    <BiArrowBack className="go_back_button" onClick={handleGoBack} />
                    <ChatHeader selectedUser={selectedUser} lastMessage={lastFriendMessage} onDeleteChat={resetChatState} />
                    <ChatBody messages={messagesdb} selectedUser={selectedUser} />
                    <ChatInput addMessage={addMessage} selectedUser={selectedUser} ConvoRoomID={ConvoRoomID} specificUserId={specificUserId} />
                  </>
                ) : (
                  <>
                    <img className="nochat" src={nochat} alt="nochat" />
                    <h4 className={darkMode ? "startchatDark" : "startchat"}>Start Your Chat!</h4>
                  </>
                )}
              </div>
            </div>
          )}
      </Layout>
    </>
  );
};
export default ChatPage;
