import React, { useState, useEffect } from "react";
import styles from "./AdminAnalytics.module.scss";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import img from '../../assests/svg/back person.svg'
import { Table } from "react-bootstrap";
import Reports from './components/Reports/Reports'
import Verified from "./components/Verified/Verified";
import Status from "./components/Status/Status";
import { AiFillStar } from "react-icons/ai";
import { Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { GetAllUsers, GetUsers, GetMerchant } from "../../store/actions/AdminDashboard";
const AdminAnalytics = () => {
  const [activePage, setActivePage] = useState(1);
  const dispatch = useDispatch();
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const [activeButton, setActiveButton] = useState(1);
  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 980);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial value
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const allUsers = useSelector((state) => state.AllUser?.AllUser?.data)
  const user = useSelector((state) => state.userAdmin?.userAdmin?.data)
  const merchant = useSelector((state) => state.merchant?.merchant?.data)
  const displayedUsers = activeButton === 1 ? allUsers : (activeButton === 2 ? user : merchant);
  useEffect(() => {
    dispatch(GetAllUsers());
    dispatch(GetUsers())
    dispatch(GetMerchant())
  }, [dispatch]);
  const numberOfUsers = activeButton === 1 ? allUsers && allUsers.length : (activeButton === 2 ? user && user.length : merchant && merchant.length)
  return (
    <AdminLayout>
      <div className={styles.header}>
        <h3>User Analytics</h3>
      </div>
      <div className={styles.tableChoices}>
        <button
          className={activeButton === 1 ? styles.active : ""}
          onClick={() => handleButtonClick(1)}
        >
          All
        </button>
        <button
          className={activeButton === 2 ? styles.active : ""}
          onClick={() => handleButtonClick(2)}
        >
          User
        </button>
        <button
          className={activeButton === 3 ? styles.active : ""}
          onClick={() => handleButtonClick(3)}
        >
          Company
        </button>
      </div>
      {isSmallScreen ? (
        <>
          <div style={{ color: '#0298a6', fontWeight: '500', marginLeft: '10px' }}>Users Count: {numberOfUsers} </div>
          {displayedUsers && displayedUsers.map((user, index) => (
            <div className={styles.table}>
              <Table striped bordered hover style={{ width: '250px' }}>
                <tbody>
                  <React.Fragment key={index}>
                    <tr>
                      <td className={styles.customername}>Customer Name:</td>
                      <td className={styles.customername}>
                        <div className={styles.uInfo}>
                          <img src={user.imageUrl} alt="Customer" className={styles.customerImage} />
                          {user.username}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Email:</td>
                      <td className={styles.customername}>{user.email}</td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Phone Number:</td>
                      <td>
                        <div className={styles.customername}>{user.phone}</div>
                      </td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Join Date:</td>
                      <td className={styles.customername}>{user.createdAt.slice(0, 10)}</td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Verified:</td>
                      <td><Verified isChecked={user.isVerified} /></td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Status:</td>
                      <td><Status state={user.isDisabled} /></td>
                    </tr>
                    <tr>
                      <td className={styles.customername}>Rating:</td>
                      <td>
                        <div className={styles.rating}>
                          {user.reviews?.average} <AiFillStar className={styles.icon} />
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                </tbody>
              </Table>
            </div>
          ))}
        </>
      )
        : (
          <div className={styles.table}>
            <div style={{ color: '#0298a6', fontWeight: '500', marginLeft: '10px' }}>Users Count: {numberOfUsers} </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Customer Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Join Date</th>
                  <th>Verified</th>
                  <th>Status</th>
                  <th>Rating</th>
                </tr>
              </thead>
              <tbody>
                {displayedUsers && displayedUsers.map((user, index) => (
                  <tr key={index}>
                    <td className={styles.customername}>
                      <div className={styles.uInfo}>
                        <img src={user.imageUrl} alt="Customer" className={styles.customerImage} />
                        {user.username}
                      </div>
                    </td>
                    <td className={styles.customername}>{user.email}</td>
                    <td>
                      <div className={styles.customername}>{user.phone !== undefined && user.phone !== '' ? user.phone : 'xxx'}</div>
                    </td>
                    <td className={styles.customername}>{user.createdAt.slice(0, 10)}</td>
                    <td><Verified isChecked={user.isVerified} /></td>
                    <td><Status state={user.isDisabled} /></td>
                    <td>
                      <div className={styles.rating}>
                        {user.reviews?.average} <AiFillStar className={styles.icon} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
    </AdminLayout>
  )
}
export default AdminAnalytics;
