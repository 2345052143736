const initialState = [{ name: "Home", url: "/" }];
const breadcrumbReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_BREADCRUMB":
            const isDuplicate = state.some((breadcrumb) => breadcrumb.name === action.payload.name && breadcrumb.url === action.payload.url);
            if (action.payload.url === "/") {
                return initialState
            } else if (isDuplicate) {
                const findDuplicate = state.findIndex((breadcrumb) => breadcrumb.name === action.payload.name && breadcrumb.url === action.payload.url);
                return state.slice(0, findDuplicate + 1);
            } else {
                return [...state, action.payload];
            }
        case "CLEAR_BREADCRUMBS":
            return initialState;
        case "REMOVE_BREADCRUMBS":
            return state.slice(0, action.payload);
        case "BACK":
            return state.slice(0, - 1);
        default:
            return state;
    }
};
export default breadcrumbReducer;
