import React from "react";
import styles from "./EmptyProducts.module.scss";
import img from "../../../assests/svg/empty products.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const EmptyProducts = () => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const navigate=useNavigate()
  return (
    <div className={styles.container}>
      <img src={img} alt="empty products" className={styles.img} />
      <h1 className={`${styles.title} ${darkMode? styles.titleDark:''}`}>No Products Found</h1>
      <p className={styles.text}>You haven't posted any products yet</p>
      <button className={`${styles.button} ${darkMode? styles.buttonDark:''}`} onClick={()=>navigate('/')}>Go Shopping</button>
    </div>
  );
};
export default EmptyProducts;
