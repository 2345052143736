import React, { useState, useEffect } from "react";
import Layout from "../../../common/components/Layouts/Layout";
import styles from "../SellProductForm.module.scss";
import Car from "../../../assests/images/Car.png";
import Camera from "../../../assests/images/camera.png";
import LocationMarker from "../../../assests/svg/location marker.svg";
import { AiFillPlusCircle, AiOutlineRight } from "react-icons/ai";
import Switch from "react-switch";
import PostSuccess from "../../../common/components/PostSuccess/PostSuccess";
import { useFormik } from "formik";
import { PropertiesValidationSchema } from "../index";
import { useParams } from "react-router-dom";
import { addProductHandler } from "../../../store/actions/addProductActions";
import { useDispatch, useSelector } from "react-redux";
import { GetPendingProduct } from "../../../store/actions/AdminDashboard";
import { CreateProduct } from "../../../store/actions/addProductActions";
import { GetSingleCategory } from "../../../store/actions/categoryAction";
import { url } from "../../../common/constants/url";
import { GetAllSubCategories } from "../../../store/actions/categoryAction";
import ColorDropdown from "./ColorList";
import { Scrollbars } from "react-custom-scrollbars";
import { useNavigate } from "react-router-dom";
import EditModel from "./EditModel";
import { Link } from "react-router-dom";
import { addProductArray } from "../../../store/actions/PreviewActions";
import { AiOutlineDown } from "react-icons/ai";
import {
  getAddressByUser,
  makeDefault,
} from "../../../store/actions/addAddressActions";
import AddressComponent from "../../AddressPage/AddressComponent/AddressComponent";
import SellModal from "../../../common/components/SellModal/SellModal";
import PreviewModal from "./previewModal";
import MapModal from "./MapModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../common/components/Loader/Loader";
const Properties = () => {
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    const pageName = "Sell Product"; // Replace this with the actual page name
    const pageUrl = `/sell/products/${params.subtitle}/${params.id}`; // Replace this with the actual page URL
    dispatch({
      type: "ADD_BREADCRUMB",
      payload: { name: pageName, url: pageUrl },
    });
  }, []);
  const address = useSelector((state) => state.address?.address);
  const navigate = useNavigate();
  const defaultAddress = address.find((address) => address.isDefault);
  useEffect(() => {
    dispatch(getAddressByUser());
  }, [dispatch]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [openaddress, setOpenaddress] = useState(false);
  function handleLocationClick() {
    setShowDropdown(!showDropdown);
  }
  async function handleOptionClick(id) {
    const selectedAddress = address.find((add) => add._id === id);
    setSelectedAddress(selectedAddress);
    setShowDropdown(false);
    const response = await dispatch(makeDefault(id));
    await dispatch(getAddressByUser());
  }
  const { title, id, productSubCat, subtitle } = useParams();
  const [Category, setCategory] = useState([]);
  const [dynamicFieldsValues, setDynamicFieldsValues] = useState([]);
  const [dynamicFieldStates, setDynamicFieldStates] = useState([]);
  const [icon, setIcon] = useState("");
  const [isDown, setIsDown] = useState(false);
  const handleArrow = () => {
    setIsDown(!isDown);
  };
  useEffect(() => {
    dispatch(GetSingleCategory(id))
      .then((data) => {
        setCategory(data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [id, dispatch]);
  const CategoryArray = Category ? Object.values(Category) : [];
  const fieldsArray = CategoryArray.map((item) => item.category.fields);
  const updatedFields = fieldsArray.flat().map((field) => {
    const matchingObj = dynamicFieldsValues.find(
      (item) => item.id === field.id
    );
    let value = matchingObj ? matchingObj.value : null;
    // Check if the type is boolean and set value to 0 if it is
    if (field.type === "boolean") {
      value = value === null ? 0 : value; // If value is null, set it to 0, otherwise keep its original value
    }
    return { ...field, value };
  });
  const [selectedListStates, setSelectedListStates] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const userById = useSelector((state) => state.userById);
  const { user, error } = userById;
  const [showModal, setShowModal] = useState(false);
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [priceErr, setPriceErr] = useState("");
  const [quantityErr, setQuantityErr] = useState("");
  const booleanFields =
    CategoryArray &&
    CategoryArray.length > 0 &&
    CategoryArray[0].category?.fields?.filter((field) => field.type === "list");
  const numberOfBooleans = booleanFields.length;
  // Now you have the number of boolean fields in the "numberOfBooleans" variable.
  // You can use this value to initialize the "toggleStates" array with the correct length.
  const [toggleStates, setToggleStates] = useState({});
  const [initialToggleStates, setInitialToggleStates] = useState(0);
  const handlePriceChange = (event) => {
    const inputValue = event.target.value;
    const newPrice = inputValue !== "" ? parseFloat(inputValue) : null;
    if (isNaN(newPrice) || newPrice === null) {
      setPrice(null);
      formik.setFieldValue("price", null);
      setPriceErr("Price is required");
    } else {
      // Limit the newPrice to two decimal places
      const limitedPrice = parseFloat(newPrice.toFixed(2));
      setPrice(limitedPrice);
      formik.setFieldValue("price", limitedPrice);
      setPriceErr("");
    }
  };
  const handleQuantityChange = (event) => {
    const inputValue = event.target.value;
    const newQuantity = inputValue !== "" ? parseInt(inputValue) : null; // Use parseInt to get integer value
    if (isNaN(newQuantity) || newQuantity === null) {
      setQuantity(null);
      formik.setFieldValue("quantity", null);
      setQuantityErr("quantity is required");
    } else {
      setQuantity(newQuantity);
      formik.setFieldValue("quantity", newQuantity);
      setQuantityErr("");
    }
  };
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartTDate(newStartDate);
  };

  const handleStartDateBlur = () => {
    const newStartDate = startTDate; // Get the value from state
    const currentLocalTime = new Date();
    currentLocalTime.setMinutes(
      currentLocalTime.getMinutes() - currentLocalTime.getTimezoneOffset()
    );
    if (endTDate !== "") {
      if (newStartDate >= currentLocalTime.toISOString().slice(0, 16)) {
        const newEndDate = endTDate;
        if (newStartDate < newEndDate) {
          setEndTDate(newEndDate);
        } else {
          toast.error("Start date should be before end date.");
          setStartTDate(currentLocalTime.toISOString().slice(0, 16)); // Set to current date if there's an error
        }
      } else {
        toast.error("Start date cannot be in the past.");
        setStartTDate(currentLocalTime.toISOString().slice(0, 16)); // Set to current date if there's an error
      }
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndTDate(newEndDate);
  };

  const handleEndDateBlur = () => {
    const newEndDate = endTDate; // Get the value from state
    const currentLocalTime = new Date();
    currentLocalTime.setMinutes(
      currentLocalTime.getMinutes() - currentLocalTime.getTimezoneOffset()
    );

    if (newEndDate >= currentLocalTime.toISOString().slice(0, 16)) {
      if (newEndDate > startTDate) {
        // No need to update the state here since we're already updating it on change
      } else {
        toast.error("End date should be after start date.");
        setEndTDate(currentLocalTime.toISOString().slice(0, 16)); // Set to current date if there's an error
      }
    } else {
      toast.error("End date cannot be in the past.");
      setEndTDate(currentLocalTime.toISOString().slice(0, 16)); // Set to current date if there's an error
    }
  };

  const [isNegotiable, setIsNegotiable] = useState(false);
  const [isExchangeable, setIsExchangeable] = useState(false);
  const [isRefundable, setIsRefundable] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [conditionState, setConditionState] = useState({
    condition: "new",
  });
  const [loadingIcon, SetLoadingIcon] = useState(true);
  const [dynamicConditionState, setdynamicConditionState] = useState(null);
  const [dynamicListState, setdynamicListState] = useState(null);
  const [activeBool, setActiveBool] = useState(0);
  const handleBoolClick = (index) => {
    setActiveBool(index);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [selectedItem, setSelectedItem] = useState(null);
  function handleSelect(index, value) {
    setIsOpen(false);
    setSelectedItem(value);
  }
  const [fileUploaded, setFileUploaded] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [photoStates, setPhotoStates] = useState(new Array(16).fill(null));
  const handleClick = (index) => {
    hiddenFileInputs[index].current.click();
  };
  const handleChange = (event, index) => {
    const selectedFiles = Array.from(event.target.files);
    const newPhotoStates = [...photoStates];
    // Read each selected file and update the state
    selectedFiles.forEach((file, fileIndex) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (readerEvent) => {
          newPhotoStates[index + fileIndex] = {
            file,
            dataURL: readerEvent.target.result,
          };
          setPhotoStates([...newPhotoStates]);
        };
        reader.readAsDataURL(file);
      }
    });
  };
  const newphotoStates = photoStates.filter(
    (item) => item !== null && item !== ""
  );
  const fileNames = photoStates
    .map((file) => file && file.name)
    .filter(Boolean);
  const hiddenFileInputs = photoStates.map(() => React.createRef());
  const [vehicleBrand, setVehicleBrand] = useState("");
  const [timer, setTimer] = useState(false);
  const [bid, setBid] = useState(false);
  const [bids, setBids] = useState({ enabled: false, biddings: [] });
  const [success, setSuccess] = useState(false);
  const [startTDate, setStartTDate] = useState("");
  const [endTDate, setEndTDate] = useState("");
  const [deadlineTimer, setdeadlineTimer] = useState({
    enabled: false,
    deadline: null,
  });
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [startBDate, setStartBDate] = useState("");
  const [endBDate, setEndBDate] = useState("");
  useEffect(() => {
    if (timer) {
      const dateObject = new Date(endTDate);
      const milliseconds = dateObject.getTime();
      setdeadlineTimer({ enabled: true, deadline: milliseconds });
    } else {
      setdeadlineTimer({ enabled: false, deadline: null });
    }
  }, [startTDate, endTDate, timer]);
  useEffect(() => {
    if (bid) {
      setBids({ enabled: true, biddings: [] });
    } else {
      setBids({ enabled: false, biddings: [] });
    }
  }, [bid]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    type: "product",
    PropertyType: "",
    PaymentMethod: "",
    Bedrooms: "",
    Bathrooms: "",
    Size: "",
    Furnisher: "",
    ConditionProperty: "",
    RentalDuration: "",
    CommercialType: "",
    Equipped: "",
    Landtype: "",
    ChaletType: "",
    BuildingType: "",
    LivingRoomType: "",
    BedroomType: "",
    DiningRoomType: "",
    KitchenAndKitchenwareType: "",
    BathroomType: "",
    HomeDecorationAndAccessoriesType: "",
    GardenAndOutdooType: "",
    file: null,
    file1: null,
    dynamicFields: {},
  });
  const getConditionValue = () => {
    return conditionState.condition === "used" ? 0 : 1;
  };
  const pricing = {
    price,
    negotiable: isNegotiable,
    exchangeable: isExchangeable,
    refundable: isRefundable,
  };
  const jsonPricing = JSON.stringify(pricing, [
    "price",
    "negotiable",
    "exchangeable",
    "refundable",
  ]);
  function checkForEmptyValues(arr) {
    let isEmpty = false;
    for (const obj of arr) {
      if (obj.value === null || obj.value === "") {
        isEmpty = true;
        break;
      }
    }
    return isEmpty;
  }
  const formik = useFormik({
    initialValues: formData,
    onSubmit: (values) => {
      // console.log({ ...values, ...conditionState, seller: user.id });
      // dispatch(addProductHandler(values));
      // setSuccess(true);
    },
    validationSchema: PropertiesValidationSchema,
  });
  const loading = useSelector((state) => state.CreateProduct?.loading);
  const conditionValue = getConditionValue();
  const conditionString = conditionValue.toString();
  const handleEditClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!price || isNaN(price)) {
      toast.warning("Please fill in the price field.");
    } else if (checkForEmptyValues(updatedFields)) {
      toast.warning("Please fill all the fields.");
    } else if (newphotoStates.length === 0) {
      toast.warning("Add at least one picture.");
    } else if (defaultAddress === undefined) {
      toast.warning("Please add a location.");
    } else if (formik.errors.description) {
      toast.warning(formik.errors.description);
    } else if (formik.errors.title) {
      toast.warning(formik.errors.title);
    } else {
      try {
        const formData = new FormData();
        formData.append("categoryId", id);
        formData.append("title", formik.values.title);
        formData.append("description", formik.values.description);
        formData.append("condition", conditionString);
        formData.append("pricing", jsonPricing);
        formData.append("quantity", quantity);
        formData.append("fields", JSON.stringify(updatedFields));
        if (deadlineTimer.enabled) {
          formData.append("timer", JSON.stringify(deadlineTimer));
        }
        for (const file of newphotoStates) {
          formData.append("images", file.file);
        }
        if (selectedAddress == null) {
          formData.append("pickUpAddressId", defaultAddress._id);
        } else {
          formData.append("pickUpAddressId", selectedAddress._id);
        }
        formData.append("bidding", JSON.stringify(bids));
        const response = await dispatch(CreateProduct(formData));
        if (response) {
          dispatch(GetPendingProduct());
          setSuccess(true);
        }
      } catch (error) {
        // console.log(error);
      } 
    }
  };
  const ProductArray = [];
  const myObject = {
    id: id,
    title: formik.values.title,
    conditionString: conditionString,
    description: formik.values.description,
    Pricing: pricing,
    fields: updatedFields,
    timer: deadlineTimer,
    images: newphotoStates,
  };
  ProductArray.push(myObject);
  const ProductPreview = useSelector((state) => state.PreviewReducer);
  const newProductArray = [
    ...(ProductPreview?.ProductArray || []), // add previous products, default to empty array
    { ...ProductPreview, ...ProductArray }, // add new product object
  ];
  const response = dispatch(addProductArray(newProductArray));
  const [preview, setPreview] = useState(false);
  const openPreview = () => {
    setPreview(true);
  };
  const [isOpenList, setIsOpenList] = useState(
    Array(numberOfBooleans).fill(false)
  );
  const [selectedItems, setSelectedItems] = useState(
    Array(numberOfBooleans).fill(null)
  );
  useEffect(() => {
    SetLoadingIcon(true);
    if (
      Category &&
      Category.data &&
      Category.data?.category?.parentCategoryId
    ) {
      dispatch(
        GetSingleCategory(
          Category && Category.data && Category.data?.category?.parentCategoryId
        )
      )
        .then((data) => {
          if (data && data.data && data.data.category) {
            setIcon(data.data.category.iconUrl);
          }
          SetLoadingIcon(false);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [Category]);
  const handleDelete = (index) => {
    const updatedPhotoStates = [...photoStates];
    updatedPhotoStates[index] = null;
    setPhotoStates(updatedPhotoStates);
  };
  return (
    <>
      <MapModal
        open={openaddress}
        onClose={() => {
          setOpenaddress(false);
        }}
      />
      <PreviewModal
        open={preview}
        onClose={() => {
          setPreview(false);
        }}
        images={newphotoStates}
        description={formik.values.description}
        condition={conditionString}
        fields={updatedFields}
        pricing={pricing}
        title={formik.values.title}
        Negotiable={isNegotiable}
        Enabled={bid}
        defaultAddress={defaultAddress}
        selectedAddress={selectedAddress}
        createProduct={true}
        quantity={quantity}
      />
      <Layout title={"Product Information"}>
        <form className={styles.wrapper} onSubmit={formik.handleSubmit}>
          <div className={styles.category_container}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Selected Category
            </p>
            <div className={styles.category_input}>
              {loadingIcon ? (
                <Loader />
              ) : (
                <div className={styles.img_container}>
                  <img src={icon} alt="product" className={styles.img} />
                </div>
              )}
              <div>
                <p className={styles.subtitle}>
                  {" "}
                  {subtitle.replaceAll("-", " ").replaceAll("And", "&")}
                </p>
                {/* <button className={styles.edit_btn} onClick={handleEditClick}>Edit</button> */}
              </div>
            </div>
          </div>
          {showModal && <EditModel handleCloseModal={handleCloseModal} />}
          <div className={styles.title_container}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Product Title
            </p>
            <div className={styles.title_input_wrapper}>
              <input
                type="text"
                className={`${styles.input} ${
                  darkMode ? styles.inputDark : ""
                } ${
                  formik.errors.title && formik.touched.title
                    ? styles.error_input
                    : ""
                }`}
                placeholder="Name of product and related info"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
              />
              {formik.errors.title && formik.touched.title && (
                <p className={styles.error_message}>{formik.errors.title}</p>
              )}
            </div>
          </div>
          <div className={styles.desc_container}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Description
            </p>
            <div className={styles.description_wrapper}>
              <textarea
                className={`${styles.text_area} ${
                  darkMode ? styles.text_areaDark : ""
                } ${
                  formik.errors.description && formik.touched.description
                    ? styles.error_input
                    : ""
                }`}
                placeholder="Tell us more about your product"
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.errors.description && formik.touched.description && (
                <p className={styles.error_message}>
                  {formik.errors.description}
                </p>
              )}
            </div>
          </div>
          <div className={styles.condition_container}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Condition
            </p>
            <div className={styles.switch_container}>
              <div
                className={`${styles.switch_btn}  ${
                  darkMode ? styles.switch_btnDark : ""
                } ${conditionState.condition === "new" ? styles.active : ""} ${
                  darkMode ? styles.activeDark : ""
                }`}
                onClick={() => setConditionState({ condition: "new" })}
              >
                New
              </div>
              <div
                className={`${styles.switch_btn} ${
                  conditionState.condition === "used" ? styles.active : ""
                } ${darkMode ? styles.switch_btnDark : ""} ${
                  darkMode ? styles.activeDark : ""
                } `}
                onClick={() => setConditionState({ condition: "used" })}
              >
                Used
              </div>
            </div>
          </div>
          <div className={styles.price_container}>
            <label
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Price
            </label>
            <div className={styles.input_container}>
              <input
                type="number"
                className={`${styles.input} ${darkMode ? styles.inputDark : ""}
               ${
                 formik.errors.price && formik.touched.price
                   ? styles.error_input
                   : ""
               }`}
                placeholder="000,000"
                name="price"
                onChange={handlePriceChange}
                onWheel={(e) => e.target.blur()}
                onBlur={formik.handleBlur}
                value={price || ""}
              />
              {formik.errors.price && formik.touched.price && (
                <p className={styles.error_message}>{priceErr}</p>
              )}
              <div
                className={`${styles.currency} ${
                  darkMode ? styles.currencyDark : ""
                }`}
              >
                $
              </div>
            </div>
            <div className={styles.checkboxes}>
              <div className={styles.checkbox_container}>
                <input
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  type="checkbox"
                  name="negotiatable"
                  id="box1"
                  checked={isNegotiable}
                  onChange={(e) => setIsNegotiable(e.target.checked)}
                />
                <label
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  htmlFor="box1"
                >
                  Negotiatable
                </label>
              </div>
              <div className={styles.checkbox_container}>
                <input
                  type="checkbox"
                  name="Exchangable"
                  id="box1"
                  checked={isExchangeable}
                  onChange={(e) => setIsExchangeable(e.target.checked)}
                />
                <label
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  htmlFor="box1"
                >
                  Exchangable
                </label>
              </div>
              <div className={styles.checkbox_container}>
                <input
                  type="checkbox"
                  name="Refundable"
                  id="box1"
                  checked={isRefundable}
                  onChange={(e) => setIsRefundable(e.target.checked)}
                />
                <label
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  htmlFor="box1"
                >
                  Refundable
                </label>
              </div>
            </div>
          </div>
          <div className={styles.quantity_container}>
            <label
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Quantity
            </label>
            <div className={styles.input_container}>
              <input
                type="number"
                className={`${styles.input} ${darkMode ? styles.inputDark : ""}
               ${
                 formik.errors.quantity && formik.touched.quantity
                   ? styles.error_input
                   : ""
               }`}
                placeholder="Place your Quantity"
                name="quantity"
                onChange={handleQuantityChange}
                onBlur={formik.handleBlur}
                value={quantity || ""}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) => {
                  if (e.key === "." || e.key === ",") {
                    e.preventDefault();
                  }
                }}
              />
              {formik.errors.quantity && formik.touched.quantity && (
                <p className={styles.error_message}>{quantityErr}</p>
              )}
              <div
                className={`${styles.currency} ${
                  darkMode ? styles.currencyDark : ""
                }`}
              >
                Qt
              </div>
            </div>
          </div>
          <div className={styles.line}></div>
          {CategoryArray.map((item) =>
            item.category.fields.map((subCategory, index) => {
              const renderField = (subCategory) => {
                switch (subCategory.type) {
                  case "string":
                    return (
                      <div className={`${styles.fieldContainer}`}>
                        <label
                          className={`${styles.label} ${
                            darkMode ? styles.labelDark : ""
                          }`}
                        >
                          {subCategory.title}
                        </label>
                        <div className={styles.input_container}>
                          <input
                            type="text"
                            className={`${styles.inputString} ${
                              darkMode ? styles.inputStringDark : ""
                            }
                          ${
                            formik.errors[subCategory.title] &&
                            formik.touched[subCategory.title]
                              ? styles.error_input
                              : ""
                          }`}
                            placeholder={subCategory.title}
                            name={`dynamicFields.${subCategory.title}.${subCategory.type?.id}`}
                            onChange={(e) => {
                              const value = e.target.value;
                              const fieldName = subCategory.title;
                              const id = subCategory.id;
                              formik.setFieldValue(
                                `dynamicFields.${fieldName}`,
                                value
                              );
                              const existingObjectIndex =
                                dynamicFieldsValues.findIndex(
                                  (obj) => obj.id === id
                                );
                              if (existingObjectIndex !== -1) {
                                // If an object exists, update its value property
                                const updatedArray = [...dynamicFieldsValues];
                                updatedArray[existingObjectIndex].value = value;
                                setDynamicFieldsValues(updatedArray);
                              } else {
                                // If an object doesn't exist, add a new object to the array
                                setDynamicFieldsValues([
                                  ...dynamicFieldsValues,
                                  { id, value },
                                ]);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values.dynamicFields[subCategory.title]
                            }
                          />
                          {formik.errors[subCategory.title] &&
                            formik.touched[subCategory.title] && (
                              <p className={styles.error_message}>
                                {formik.errors[subCategory.title]}
                              </p>
                            )}
                        </div>
                      </div>
                    );
                  case "list":
                    // Function to toggle the dropdown for a specific list
                    const toggleDropdown = (index) => {
                      const updatedIsOpenList = [...isOpenList];
                      updatedIsOpenList[index] = !updatedIsOpenList[index];
                      setIsOpenList(updatedIsOpenList);
                    };
                    // Function to handle selecting an option in a specific list
                    const handleSelect = (index, value) => {
                      const updatedSelectedItems = [...selectedItems];
                      updatedSelectedItems[index] = value;
                      setSelectedItems(updatedSelectedItems);
                      const updatedIsOpenList = [...isOpenList];
                      updatedIsOpenList[index] = false;
                      setIsOpenList(updatedIsOpenList);
                    };
                    return (
                      <div>
                        <div key={index} className={`${styles.fieldContainer}`}>
                          {/* Render the content for each list */}
                          <label
                            className={`${styles.label} ${
                              darkMode ? styles.labelDark : ""
                            }`}
                          >
                            {subCategory.title}
                          </label>
                          <div className={styles.input_container}>
                            <div>
                              <button
                                className={`${styles.listb} ${
                                  darkMode ? styles.listbdark : ""
                                }`}
                                onClick={() => toggleDropdown(index)} // Pass the index of the list to toggleDropdown
                              >
                                {selectedItems[index]
                                  ? selectedItems[index]
                                  : `Select a ${subCategory.title}`}
                              </button>
                              {isOpenList[index] && (
                                <div
                                  className={`${styles.drop} ${
                                    darkMode ? styles.dropdark : ""
                                  }`}
                                >
                                  <Scrollbars
                                    autoHeight
                                    autoHeightMin={0}
                                    autoHeightMax={200}
                                  >
                                    {subCategory.possibleValues.map(
                                      (value, valueIndex) => (
                                        <div
                                          key={valueIndex}
                                          className={`${styles.droplist} ${
                                            darkMode ? styles.droplistdark : ""
                                          }`}
                                          onClick={() => {
                                            setdynamicListState(valueIndex);
                                            const fieldName = subCategory.title;
                                            const id = subCategory.id;
                                            formik.setFieldValue(
                                              `dynamicFields.${fieldName}`,
                                              valueIndex
                                            );
                                            const existingObjectIndex =
                                              dynamicFieldsValues.findIndex(
                                                (obj) => obj.id === id
                                              );
                                            if (existingObjectIndex !== -1) {
                                              const updatedArray = [
                                                ...dynamicFieldsValues,
                                              ];
                                              updatedArray[
                                                existingObjectIndex
                                              ].value = valueIndex;
                                              setDynamicFieldsValues(
                                                updatedArray
                                              );
                                            } else {
                                              setDynamicFieldsValues([
                                                ...dynamicFieldsValues,
                                                { id, value: valueIndex },
                                              ]);
                                            }
                                            handleSelect(index, value); // Pass the index and value of the selected item
                                          }}
                                          onMouseEnter={() => {
                                            setHoveredIndex(valueIndex);
                                          }}
                                          onMouseLeave={() => {
                                            setHoveredIndex(-1);
                                          }}
                                        >
                                          {value}
                                        </div>
                                      )
                                    )}
                                  </Scrollbars>
                                </div>
                              )}
                            </div>
                            {formik.errors[subCategory.title] &&
                              formik.touched[subCategory.title] && (
                                <p className={styles.error_message}>
                                  {formik.errors[subCategory.title]}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  case "number":
                    return (
                      <div className={`${styles.fieldContainer}`}>
                        <label
                          className={`${styles.label} ${
                            darkMode ? styles.labelDark : ""
                          }`}
                        >
                          {subCategory.title}
                        </label>
                        <div className={styles.input_container}>
                          <input
                            type="text"
                            className={`${styles.inputString} ${
                              darkMode ? styles.inputStringDark : ""
                            }
                              ${
                                formik.errors[subCategory.title] &&
                                formik.touched[subCategory.title]
                                  ? styles.error_input
                                  : ""
                              }`}
                            placeholder={subCategory.title}
                            name={`dynamicFields.${subCategory.title}.${subCategory.type?.id}`}
                            onChange={(e) => {
                              const value = e.target.value;
                              const fieldName = subCategory.type?.id;
                              const id = subCategory.id;
                              formik.setFieldValue(
                                `dynamicFields.${fieldName}`,
                                value
                              );
                              const existingObjectIndex =
                                dynamicFieldsValues.findIndex(
                                  (obj) => obj.id === id
                                );
                              if (existingObjectIndex !== -1) {
                                // If an object exists, update its value property
                                const updatedArray = [...dynamicFieldsValues];
                                updatedArray[existingObjectIndex].value = value;
                                setDynamicFieldsValues(updatedArray);
                              } else {
                                // If an object doesn't exist, add a new object to the array
                                setDynamicFieldsValues([
                                  ...dynamicFieldsValues,
                                  { id, value },
                                ]);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values.dynamicFields[subCategory.title]
                            }
                          />
                          {formik.errors[subCategory.title] &&
                            formik.touched[subCategory.title] && (
                              <p className={styles.error_message}>
                                {formik.errors[subCategory.title]}
                              </p>
                            )}
                        </div>
                      </div>
                    );
                  case "boolean":
                    const number = 0;
                    return (
                      <div className={styles.condition_container}>
                        <p
                          className={`${styles.label} ${
                            darkMode ? styles.labelDark : ""
                          }`}
                        >
                          {subCategory.title}
                        </p>
                        <div className={styles.switch_container}>
                          {subCategory.possibleValues.map((value, index) => (
                            <div
                              key={index}
                              className={`${styles.switch_btn} ${
                                (!toggleStates[subCategory.id] &&
                                  number === index) ||
                                toggleStates[subCategory.id] === index
                                  ? styles.active
                                  : ""
                              } ${darkMode ? styles.switch_btnDark : ""} ${
                                darkMode &&
                                !(
                                  (!toggleStates[subCategory.id] &&
                                    number === index) ||
                                  toggleStates[subCategory.id] === index
                                )
                                  ? styles.activeDark
                                  : ""
                              }`}
                              onClick={() => {
                                const newToggleStates = { ...toggleStates };
                                newToggleStates[subCategory.id] = index;
                                setToggleStates(newToggleStates);
                                setdynamicConditionState(index);
                                const fieldName = subCategory.title;
                                const id = subCategory.id;
                                formik.setFieldValue(
                                  `dynamicFields.${fieldName}`,
                                  index
                                );
                                const existingObjectIndex =
                                  dynamicFieldsValues.findIndex(
                                    (obj) => obj.id === id
                                  );
                                if (existingObjectIndex !== -1) {
                                  const updatedArray = [...dynamicFieldsValues];
                                  updatedArray[existingObjectIndex].value =
                                    index;
                                  setDynamicFieldsValues(updatedArray);
                                } else {
                                  setDynamicFieldsValues([
                                    ...dynamicFieldsValues,
                                    { id, value: index },
                                  ]);
                                }
                                handleBoolClick(index);
                              }}
                            >
                              {value}
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  default:
                    return null;
                }
              };
              return renderField(subCategory);
            })
          )}
          <div className={styles.line2}></div>
          <div className={styles.image_section}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Add Photos
            </p>
            <div className={styles.wrapper}>
              {photoStates.map((photoState, index) => (
                <div
                  className={`${styles.image_container} ${
                    darkMode ? styles.image_containerDark : ""
                  }`}
                  onClick={() => handleClick(index)}
                >
                  <img
                    src={photoState ? photoState.dataURL : Camera}
                    alt="camera"
                    className={`${
                      photoState ? styles.full_image : styles.image
                    }`}
                    file={formik.values.file}
                  />
                  {photoState && (
                    <div
                      className={styles.remove_button}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                    >
                      X
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    ref={hiddenFileInputs[index]}
                    onChange={(event) => handleChange(event, index)} // Pass the index to handleChange
                    style={{ display: "none" }}
                    multiple
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.line}></div>
          <p className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}>
            {selectedLocation || "Location"}
          </p>
          <div className={styles.location_section}>
            {address && address.length > 0 ? (
              address
                .filter((addr) => addr.isDefault)
                .map((addr) => (
                  <AddressComponent
                    defaultAddress={true}
                    title={addr.title}
                    description={addr.description}
                    threedots={false}
                  />
                ))
            ) : (
              <AddressComponent
                defaultAddress={false}
                title={"No Addresses"}
                description={"You can add a location"}
                threedots={false}
              />
            )}
            <div className={styles.vertical_line}></div>
            <div className={styles.new_address_container}>
              <p
                className={`${styles.new_address_text} ${
                  darkMode ? styles.new_address_textDark : ""
                }`}
              >
                Select a new address as the location
              </p>
              <div
                className={`${styles.location} ${
                  darkMode ? styles.locationDark : ""
                }`}
                onClick={handleLocationClick}
              >
                {selectedAddress ? selectedAddress.title : "Location"}{" "}
                <AiOutlineRight className={styles.icon} />
              </div>
              {showDropdown &&
                (address.length > 0 ? (
                  <div className={styles.dropdown}>
                    <ul>
                      {address.map((add) => (
                        <li
                          key={add._id}
                          className={styles.places}
                          onClick={() => handleOptionClick(add._id)}
                        >
                          {add.title}
                        </li>
                      ))}
                    </ul>
                    <div
                      className={styles.addloc}
                      onClick={() => setOpenaddress(true)}
                    >
                      <AiFillPlusCircle
                        className={styles.add_button}
                        onClick={() => setOpenaddress(true)}
                      />
                      <span>Add Location</span>
                    </div>
                  </div>
                ) : (
                  <div className={styles.dropdown}>
                    <div
                      className={styles.addloc}
                      onClick={() => setOpenaddress(true)}
                    >
                      <AiFillPlusCircle
                        className={styles.add_button}
                        onClick={() => setOpenaddress(true)}
                      />

                      <span>Add Location</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.line}></div>
          <div className={styles.last_section}>
            <div>
              <div className={styles.flex}>
                <p
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                >
                  Set a Timer
                </p>
                <Switch
                  checked={timer}
                  onChange={() => setTimer(!timer)}
                  onColor={"#0298a6"}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
              {timer && (
                <div className={styles.flexColumn}>
                  <div className={styles.flex}>
                    <label
                      className={`${styles.labelt} ${
                        darkMode ? styles.labeltDark : ""
                      }`}
                    >
                      Start Date:
                    </label>
                    {/* <input className={styles.datein} type="datetime-local" value={startTDate} onChange={handleStartDateChange} onBlur={handleStartDateBlur} /> */}
                    <label
                      className={`${styles.labelt} ${
                        darkMode ? styles.labeltDark : ""
                      }`}
                    >
                      Current date
                    </label>
                  </div>
                  <div className={styles.flex}>
                    <label
                      className={`${styles.labelt} ${
                        darkMode ? styles.labeltDark : ""
                      }`}
                    >
                      End Date:
                    </label>
                    <input
                      className={styles.datein}
                      type="datetime-local"
                      value={endTDate}
                      onChange={handleEndDateChange}
                      onBlur={handleEndDateBlur}
                    />
                  </div>
                </div>
              )}
              <p
                className={`${styles.text} ${darkMode ? styles.textDark : ""}`}
              >
                When active the product will be available only for a limited
                time, specified by you. (can be changed later)
              </p>
            </div>
            <div className={styles.vertical_line}></div>
            <div>
              <div className={styles.flex}>
                <p
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                >
                  Make Product a Bid
                </p>
                <Switch
                  checked={bid}
                  onColor={"#0298a6"}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={() => setBid(!bid)}
                />
              </div>
              <p
                className={`${styles.text} ${darkMode ? styles.textDark : ""}`}
              >
                When active the product will be published as a bid, people will
                compete for the product by offering higher prices for a limited
                time and the person with the highest bid wins. (Can be turned
                off from the my products section)
              </p>
            </div>
          </div>
          <div className={styles.button_container}>
            {loading ? (
              <button className={styles.post_nowld}>Posting...</button>
            ) : (
              <button
                className={`${styles.post_now} ${
                  darkMode ? styles.post_nowDark : ""
                }`}
                type={"submit"}
                onClick={handleSubmit}
              >
                Post Now
              </button>
            )}
            <button
              className={`${styles.preview} ${
                darkMode ? styles.previewDark : ""
              }`}
              onClick={openPreview}
            >
              Preview
            </button>
          </div>
        </form>
        <PostSuccess open={success} onClose={() => setSuccess(false)} />
      </Layout>
    </>
  );
};
export default Properties;
