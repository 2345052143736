import {
    GET_SERVICE_DIFFERENCE_SUCCESS,
    GET_SERVICE_DIFFERENCE_FAIL
} from "../constants/adminDashboardConstants";
const initialState = {
    ServiceDifference: [],
    error: null,
    loading: false,
};
export const GetServiceDifference = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_DIFFERENCE_SUCCESS:
            return {
                ...state,
                ServiceDifference: action.payload,
                error: null,
                loading: false,
            };
        case GET_SERVICE_DIFFERENCE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
