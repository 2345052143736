import React from 'react';
import styles from './Adminchats.module.scss';
import { timePosted } from '../../../common/Utils';
import { useSelector } from 'react-redux';
const Adminchats = ({ onClick, user, description }) => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <div className={`${darkMode ? styles.chat_pageDark : styles.chat_page}`} onClick={() => onClick(user)}>
      <div >
        <img className={styles.profile_pic} src={user.image} alt="Profile" />
      </div>
      <div className={styles.chat_details}>
        <div className={styles.header}>
          <p className={`${darkMode ? styles.nameDark : styles.name}`}>{user.name && user.name.length > 15
            ? user.name.substring(0, 15) + '...'
            : user.name}</p>
          <p className={`${darkMode ? styles.timeDark : styles.time}`}>{timePosted(user.sendTime)}</p>
        </div>
        <div className={styles.bothbod}>
          <div className={styles.bod}>
            <p className={styles.description}>{description}</p>
            <p className={`${darkMode ? styles.last_messageDark : styles.last_message}`}>
              {user.lastMsg && user.lastMsg.length > 20
                ? user.lastMsg.substring(0, 20) + '...'
                : user.lastMsg}
            </p>
          </div>
          <div>
            {user.unread !== undefined && user.unread !== 0 && (
              <div className={`${darkMode ? styles.unread_messagesDark : styles.unread_messages}`}>{user.unread}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Adminchats;
