import { url } from "../../common/constants/url";
import axios from "axios";
import {
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAIL,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  DEFAULT_CUSTOMER_SUCCESS,
  DEFAULT_CUSTOMER_FAIL,
  CREATE_CARD_REQUEST,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAIL,
  ADD_CARD_TO_DB_REQUEST,
  ADD_CARD_TO_DB_SUCCESS,
  ADD_CARD_TO_DB_FAIL,
  ADD_CARD_TO_CUSTOMER_REQUEST,
  ADD_CARD_TO_CUSTOMER_SUCCESS,
  ADD_CARD_TO_CUSTOMER_FAIL,
  CARDS_LIST_REQUEST,
  CARDS_LIST_SUCCESS,
  CARDS_LIST_FAIL,
  DEFAULT_CARD_REQUEST,
  DEFAULT_CARD_SUCCESS,
  DEFAULT_CARD_FAIL,
  UPDATE_DEFAULT_CARD_REQUEST,
  UPDATE_DEFAULT_CARD_SUCCESS,
  UPDATE_DEFAULT_CARD_FAIL,
} from "../constants/paymentConstants";
//CreateCard
  export const createCustomerStripe = (formData) => {
    return async (dispatch) => {
    try {
      dispatch({
        type: CREATE_CUSTOMER_REQUEST,
      });
      const token = localStorage.getItem('token')
      
      const response = await axios.post(`${url}/stripe/payment`, {
        ...formData
      }, {
        headers: {
          'authorization': `${token}`
        },
      });
      dispatch({
        type: CREATE_CUSTOMER_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: CREATE_CUSTOMER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
  };
  export const getCustomerStripe = () => {
    return async (dispatch) => {
      try {
        const token = localStorage.getItem('token')
        
        const response = await axios.get(`${url}/stripe/payment`, {
          headers: {
            'authorization': `${token}`,
          },
        });
        dispatch(getCustomerStripeSuccess(response.data));
        return response.data;
      } catch (error) {
        dispatch(getCustomerStripeFail(error));
        // console.log(error)
      }
    };
  };
  const getCustomerStripeSuccess = (data) => ({
    type: GET_CUSTOMER_SUCCESS,
    payload: data
  });
  const getCustomerStripeFail = (error) => ({
    type: GET_CUSTOMER_FAIL,
    payload: error
  });
  //delete
  export const deleteCustomerStripe = (id) => {
    return async (dispatch) => {
      try {
        dispatch({
          type: DELETE_CUSTOMER_REQUEST,
        });
        const token = localStorage.getItem('token')
        
        const response = await axios.delete(`${url}/stripe/payment/${id}`, {
          headers: {
            'authorization': `${token}`,
          },
        });
        dispatch(deleteCustomerStripeSuccess(response.data));
        return response.data;
      } catch (error) {
        dispatch(deleteCustomerStripeFail(error));
        // console.log(error)
      }
    };
  };
  const deleteCustomerStripeSuccess = (data) => {
    return {
      type: DELETE_CUSTOMER_SUCCESS,
      payload: data
    };
  };
  
  const deleteCustomerStripeFail = (error) => ({
    type: DELETE_CUSTOMER_FAIL,
    payload: error
  });
  //default
  export const defaultCustomerStripe = (id) => {
    return async (dispatch) => {
      try {
        
        const token = localStorage.getItem('token')
        
        const response = await axios.get(`${url}/stripe/payment/${id}/default`, {
          headers: {
            'authorization': `${token}`,
          },
        });
        dispatch(defaultCustomerStripeSuccess(response));
        return response.data;
      } catch (error) {
        dispatch(defaultCustomerStripeFail(error));
        // console.log('error make def',error)
      }
    };
  };
  const defaultCustomerStripeSuccess = (id) => {
    return {
      type: DEFAULT_CUSTOMER_SUCCESS,
      payload: id
    };
  };
  
  const defaultCustomerStripeFail = (error) => ({
    type: DEFAULT_CUSTOMER_FAIL,
    payload: error
  });
// create stripe after register company
export const createCustomerStripeRegisterCompany =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_CUSTOMER_REQUEST,
      });
      const {
        userRegister: { userData },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let user_id = userData.id;
      let email = userData.email;
      let name = userData.username;
      let body = {
        name,
        email,
        user_id,
      };
      const { data } = await axios.post(
        `${url}/Stripe_Card/createCustomer`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: CREATE_CUSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_CUSTOMER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const createCardStripe =
  (number, exp_month, exp_year, cvc) => async (dispatch) => {
    try {
      dispatch({
        type: CREATE_CARD_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let body = {
        number,
        exp_month,
        exp_year,
        cvc,
      };
      const { data } = await axios.post(
        `${url}/Stripe_Card/addtoken`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: CREATE_CARD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_CARD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const createCardInDataBase =
  (holdername, user_id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_CARD_TO_DB_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const {
        addCardToCustomer: { card_id },
      } = getState();
      let body = {
        user_id,
        card_id,
        holdername,
      };
      await axios.post(`${url}/cards/addcards`, JSON.stringify(body), config);
      dispatch({
        type: ADD_CARD_TO_DB_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ADD_CARD_TO_DB_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const addTokenAndCustomerID = (cid) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_CARD_TO_CUSTOMER_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const {
      createCard: { card_id },
    } = getState();
    let tid = card_id.id;
    let body = {
      cid,
      tid,
    };
    const { data } = await axios.post(
      `${url}/stripe_card/updateCreditcardCustomer`,
      JSON.stringify(body),
      config
    );
    dispatch({
      type: ADD_CARD_TO_CUSTOMER_SUCCESS,
      payload: data.id,
    });
  } catch (error) {
    dispatch({
      type: ADD_CARD_TO_CUSTOMER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listCustomerCards = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CARDS_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      createCustomer: { customer_id },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo}`,
      },
    };
    let cid = customer_id;
    let body = {
      cid,
    };
    const { data } = await axios.post(
      `${url}/stripe_card/getCustomerByCid`,
      JSON.stringify(body),
      config
    );
    dispatch({
      type: CARDS_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: CARDS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getDefaultCard = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEFAULT_CARD_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const {
      userById: { user },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    };
    const { data } = await axios.get(
      `${url}/cards/${user.id}/getdefaultcard`,
      config
    );
    dispatch({
      type: DEFAULT_CARD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DEFAULT_CARD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateDefaultCardHandler =
  (cardid) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_DEFAULT_CARD_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const {
        userById: { user },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo}`,
          "Content-Type": "application/json",
        },
      };
      let body = { cardid };
      await axios.post(
        `${url}/cards/${user.id}/updatedefaultcard`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: UPDATE_DEFAULT_CARD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_DEFAULT_CARD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
