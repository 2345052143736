import {
    GET_SERVICES_COUNT_BY_DATE_SUCCESS,
    GET_SERVICES_COUNT_BY_DATE_FAIL
} from "../constants/adminDashboardConstants";
const initialState = {
    ServicesCountByDate: [],
    error: null,
    loading: false,
};
export const GetServicesCountByDate = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICES_COUNT_BY_DATE_SUCCESS:
            return {
                ...state,
                ServicesCountByDate: action.payload,
                error: null,
                loading: false,
            };
        case GET_SERVICES_COUNT_BY_DATE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
