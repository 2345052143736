export const ADD_TO_WAITINGLIST_REQUEST = "ADD_TO_WAITINGLIST_REQUEST";
export const ADD_TO_WAITINGLIST_SUCCESS = "ADD_TO_WAITINGLIST_SUCCESS";
export const ADD_TO_WAITINGLIST_FAIL = "ADD_TO_WAITINGLIST_FAIL";
export const ADD_TO_WAITINGLIST_RESET = "ADD_TO_WAITINGLIST_RESET";
export const PAYMENT_WAITING_FOR_SURE_REQUEST =
  "PAYMENT_WAITING_FOR_SURE_REQUEST";
export const PAYMENT_WAITING_FOR_SURE_SUCCESS =
  "PAYMENT_WAITING_FOR_SURE_SUCCESS";
export const PAYMENT_WAITING_FOR_SURE_FAIL = "PAYMENT_WAITING_FOR_SURE_FAIL";
export const PAYMENT_WAITING_FOR_SURE_RESET = "PAYMENT_WAITING_FOR_SURE_RESET";
export const REMOVE_FROM_WAITINGLIST_REQUEST =
  "REMOVE_FROM_WAITINGLIST_REQUEST";
export const REMOVE_FROM_WAITINGLIST_SUCCESS =
  "REMOVE_FROM_WAITINGLIST_SUCCESS";
export const REMOVE_FROM_WAITINGLIST_FAIL = "REMOVE_FROM_WAITINGLIST_FAIL";
export const REMOVE_FROM_WAITINGLIST_RESET = "REMOVE_FROM_WAITINGLIST_RESET";
export const GET_WAITINGLIST_REQUEST = "GET_WAITINGLIST_REQUEST";
export const GET_WAITINGLIST_SUCCESS = "GET_WAITINGLIST_SUCCESS";
export const GET_WAITINGLIST_FAIL = "GET_WAITINGLIST_FAIL";
export const GET_WAITINGLIST_RESET = "GET_WAITINGLIST_RESET";
export const CHECK_USER_IN_WAITINGLIST_REQUEST =
  "CHECK_USER_IN_WAITINGLIST_REQUEST";
export const CHECK_USER_IN_WAITINGLIST_SUCCESS =
  "CHECK_USER_IN_WAITINGLIST_SUCCESS";
export const CHECK_USER_IN_WAITINGLIST_FAIL = "CHECK_USER_IN_WAITINGLIST_FAIL";
export const CHECK_USER_IN_WAITINGLIST_RESET =
  "CHECK_USER_IN_WAITINGLIST_RESET";
export const ACCEPT_WAITINGLIST_REQUEST = "ACCEPT_WAITINGLIST_REQUEST";
export const ACCEPT_WAITINGLIST_SUCCESS = "ACCEPT_WAITINGLIST_SUCCESS";
export const ACCEPT_WAITINGLIST_FAIL = "ACCEPT_WAITINGLIST_FAIL";
export const ACCEPT_WAITINGLIST_RESET = "ACCEPT_WAITINGLIST_RESET";
export const IF_ANY_USER_ACCEPTED_REQUEST = "IF_ANY_USER_ACCEPTED_REQUEST";
export const IF_ANY_USER_ACCEPTED_SUCCESS = "IF_ANY_USER_ACCEPTED_SUCCESS";
export const IF_ANY_USER_ACCEPTED_FAIL = "IF_ANY_USER_ACCEPTED_FAIL";
export const IF_ANY_USER_ACCEPTED_RESET = "IF_ANY_USER_ACCEPTED_RESET";
export const CANCEL_WAITINGLIST_REQUEST = "CANCEL_WAITINGLIST_REQUEST";
export const CANCEL_WAITINGLIST_SUCCESS = "CANCEL_WAITINGLIST_SUCCESS";
export const CANCEL_WAITINGLIST_FAIL = "CANCEL_WAITINGLIST_FAIL";
export const CANCEL_WAITINGLIST_RESET = "CANCEL_WAITINGLIST_RESET";
export const GET_MY_WAITING_LIST_SUCCESS = "GET_MY_WAITING_LIST_SUCCESS";
export const GET_MY_WAITING_LIST_REQUEST = "GET_MY_WAITING_LIST_REQUEST";
export const GET_MY_WAITING_LIST_FAIL = "GET_MY_WAITING_LIST_FAIL";

export const GET_LAST_SEEN_SUCCESS = "GET_LAST_SEEN_SUCCESS";
export const GET_LAST_SEEN_FAIL = "GET_LAST_SEEN_FAIL";
