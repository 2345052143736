import React from "react";
import styles from './CardSkel.scss'
const CardSkel = () => {
    return (
        <div className="cardSkelContainer ">
            <div className='cardSkel'>
                <div className='cardHeaderSkel'></div>
                <div className='cardBodySkel'>
                    <div className='cardTitleSkel'></div>
                    <div className='cardDescriptionSkel'></div>
                </div>
            </div>
            <div className='cardSkel'>
                <div className='cardHeaderSkel'></div>
                <div className='cardBodySkel'>
                    <div className='cardTitleSkel'></div>
                    <div className='cardDescriptionSkel'></div>
                </div>
            </div>
            <div className='cardSkel'>
                <div className='cardHeaderSkel'></div>
                <div className='cardBodySkel'>
                    <div className='cardTitleSkel'></div>
                    <div className='cardDescriptionSkel'></div>
                </div>
            </div>
            <div className='cardSkel'>
                <div className='cardHeaderSkel'></div>
                <div className='cardBodySkel'>
                    <div className='cardTitleSkel'></div>
                    <div className='cardDescriptionSkel'></div>
                </div>
            </div>
            <div className='cardSkel'>
                <div className='cardHeaderSkel'></div>
                <div className='cardBodySkel'>
                    <div className='cardTitleSkel'></div>
                    <div className='cardDescriptionSkel'></div>
                </div>
            </div>
            <div className='cardSkel'>
                <div className='cardHeaderSkel'></div>
                <div className='cardBodySkel'>
                    <div className='cardTitleSkel'></div>
                    <div className='cardDescriptionSkel'></div>
                </div>
            </div>
            <div className='cardSkel'>
                <div className='cardHeaderSkel'></div>
                <div className='cardBodySkel'>
                    <div className='cardTitleSkel'></div>
                    <div className='cardDescriptionSkel'></div>
                </div>
            </div>
            <div className='cardSkel'>
                <div className='cardHeaderSkel'></div>
                <div className='cardBodySkel'>
                    <div className='cardTitleSkel'></div>
                    <div className='cardDescriptionSkel'></div>
                </div>
            </div>
            <div className='cardSkel'>
                <div className='cardHeaderSkel'></div>
                <div className='cardBodySkel'>
                    <div className='cardTitleSkel'></div>
                    <div className='cardDescriptionSkel'></div>
                </div>
            </div>
        </div>
    )
}
export default CardSkel;
