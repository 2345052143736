import React from "react";
import ReactDom from "react-dom";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import styles from "./ChoosePaymentModal.module.scss";
import { useSelector } from "react-redux";
const ChoosePaymentModal = ({ open, closeHandler }) => {
  const getCustomerCards = useSelector((state) => state.getCustomerCards);
  const { cards, error, loading } = getCustomerCards;
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}>
        <div className={styles.wrapper}>
          <Close className={styles.close} onClick={closeHandler} />
          <h1 className={styles.title}>Choose your payment</h1>
          {cards &&
            cards.map((card) => (
              <h3 key={card.id} className={styles.subtitle}>
                last 4: {card.last4}
              </h3>
            ))}
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default ChoosePaymentModal;
