import React, { useState, useEffect } from "react";
import styles from "./SideDrawer.module.scss";
import { TbMenu2, TbDiscount2, TbWorld } from "react-icons/tb";
import { AiFillDelete, AiOutlineDown, AiOutlineFieldTime, AiOutlineRight,AiOutlineUserSwitch } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { FaCoins, FaHistory, FaShoppingBag } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import axios from "axios";
import {
  AiOutlineCreditCard,
  AiOutlineStar,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import {
  MdFiberNew,
  MdOutlineLocalOffer,
  MdOutlineNotificationsNone,
  MdOutlineDarkMode,
  MdOutlineHeadphones,
  MdOutlineQuestionAnswer,
  MdLogout,
} from "react-icons/md";
import { BiMessageRounded, BiHeart, BiNoEntry } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import User from "../../../../assests/images/user.png";
import { useSelector, useDispatch } from "react-redux";
import { getUserById } from "../../../../store/actions/userActions";
import { logout } from "../../../../store/actions/authActions";
import { getAuth, signOut } from "firebase/auth";
import Switch from '@material-ui/core/Switch';
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import { BsCashCoin } from "react-icons/bs";
import ProfileSkel from "../profile dropdown/ProfileSkel";
const SideDrawer = ({ sideDrawer, toggleSideDrawer, user }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [loggedInUser, setLoggedInUser] = useState(userInfo ? true : false);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [loading, setLoading] = useState(true);
  const [accountActive, setAccountActive] = useState(false);
  const accountClass = accountActive ? styles.show : styles.show;
  const navigate=useNavigate();
 
  const userById = useSelector((state) => state.userById);
  // const { user } = userById;
  let id = userInfo ? JSON.parse(atob(userInfo?.split(".")[1]))?.id : "";
  useEffect(() => {
    if (id && user === null) {
      dispatch(getUserById(id));
    }
  }, [dispatch, id, user]);
  const handleLogout = () => {
    dispatch(logout());
    setLoggedInUser(false);
    localStorage.removeItem("userInfo");
    window.location.reload()
  };
  const [checked, setChecked] = useState(false);
  const handleToggleDarkMode = () => {
    dispatch({ type: 'TOGGLE_DARK_MODE' });
    setChecked(!checked)
    toggleSideDrawer()
  };
    //display
    const [username, setUsername] = useState("");
    const [image, setImage] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState(false);
    const userid = localStorage.getItem("uid");
    const token = localStorage.getItem("token");
    useEffect(() => {
      axios
        .get(`https://backend.la7i27alak.com/user/${userid}`, {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
       setUsername(response.data.user.username);
       setImage(response.data.user.imageUrl);
       setEmail(response.data.user.email);
       setRole(response.data?.user.isAdmin);
       
       
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setLoading(false); 
        });
    }, [userid, token]);
    const baseUrl = 'https://backend.la7i27alak.com'
    const [isDown, setIsDown] = useState(false);
  const handleArrow = () => {
    setIsDown(!isDown);
  };
  return (
    <div
      
      className={`${styles.SideDrawer}  ${
        sideDrawer ? styles.show : styles.hide
      } ${darkMode ? styles.SideDrawerDark : ''}`}
    >
      
      {darkMode ? (
            <CloseDark className={styles.toggle__icon} onClick={toggleSideDrawer} />
          ) :
            (<Close className={styles.toggle__icon} onClick={toggleSideDrawer} />)} 
      {loading?(
        <ProfileSkel/>
      ):(
      <div className={`${styles.profile__container} ${darkMode ? styles.profile__containerDark : ''}`}>
      {user ? (
        <img
          src={`${image}`}
          alt="profile"
          className={styles.profile__picture}
        />):(
          <img
          src='/user.png'
          alt="profile"
          className={styles.profile__picture}
        />
        )}
        {user ? (
          <div className={styles.profile__details}>
            <h1 className={`${styles.name} ${darkMode ? styles.nameDark : ''}`}>{username}</h1>
            <p className={`${styles.email} ${darkMode ? styles.emailDark : ''}`}>{email}</p>
            <Link to={"/user/edit-profile"} className={styles.edit__link}>
              Edit Profile
            </Link>
          </div>
        ) : (
          <div className={styles.profile__details}>
            <h1 className={`${styles.name} ${darkMode ? styles.nameDark : ''}`}>Guest</h1>
          </div>
        )}
      </div>
      )}
      <div className={styles.line}></div>
      {user && (
        <div className={styles.menu__container}>
          <div
            className={styles.account__container__title}
            onClick={() => setAccountActive((prevState) => !prevState)}
          >
            <h1 className={`${styles.account__title} ${darkMode ? styles.account__titleDark : ''}`}>Account</h1>
            {/* <div className={styles.account__icon} onClick={handleArrow} style={{cursor:'pointer',fontSize:'28px'}}>
            {isDown ? <AiOutlineDown /> : <AiOutlineRight />}
            </div> */}
          </div>
          <ul className={styles.menu__container__list}>
          {role  && (
              <>
              <li
              className={styles.menu__container__item}
              onClick={() => navigate("/admin/dashboard")}
             >
              <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}>
              <AiOutlineUserSwitch className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`}/>     
                      Admin
                </Link>
            </li>
            </>
          )}
            <li className={styles.menu__container__item}>
              <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/user/order-history"}>
                <img src={darkMode ? '/order History - white.svg' : '/order History.svg'} alt="OrderHistory" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> Order History
              </Link>
            </li>
            <li className={styles.menu__container__item}>
              <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}  to={"/user/my-offers"}>
                <img src={darkMode ? '/My offers - white.svg' : '/My offers.svg'} alt="Myoffers" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> My Offers
              </Link>
            </li>
            <li className={styles.menu__container__item}>
              <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}  to={"/user/waiting-list"}>
                <img src={darkMode ? '/my waiting list - white.svg' : '/my waiting list.svg'} className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> My Waiting List
              </Link>
            </li>
            <li className={styles.menu__container__item}>
            <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/user/live-bids"}>
                    <img src={darkMode ? '/live bids - white.svg' : '/live bids.svg'} alt="live bids" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> My Live Bids
                  </Link>
            </li>
            <li  className={styles.menu__container__item}>
              <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/user/addresses"}>
                <img src={darkMode ? '/addresses - white.svg' : '/addresses.svg'} alt="addresses" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> Addresses
              </Link>
            </li>
            <li className={styles.menu__container__item}>
              <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/user/payment"}>
                <img src={darkMode ? '/payment - white.svg' : '/payment.svg'} alt="payment" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> Payment
              </Link>
            </li>
            <li className={styles.menu__container__item}>
              <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/user/payment-history"}>
                <img src={darkMode ? '/Group 5136 - white.svg' : '/payment history.svg'} className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> Payment History
              </Link>
            </li>
            <li className={styles.menu__container__item}>
              <Link to={'/user/favorite-categories'} className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}>
                <img src={darkMode ? '/favorite categories - white.svg' : '/favorite categories.svg'} alt="favorite categories" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> Favorite Categories
              </Link>
            </li>
            <li className={styles.menu__container__item}>
              <Link to={'/user/blocked-accounts'} className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}>
                <img src={darkMode ? '/blocked users - white.svg' : '/blocked users.svg'} alt="blocked users" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> Blocked Accounts
              </Link>
            </li>
            <li className={styles.menu__container__item}>
              <Link to={'/user/delete-account'} className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}>
                <img src={darkMode ? '/delete account - white.svg' : '/delete account.svg'} alt="delete account" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> Delete Account
              </Link>
            </li>
          </ul>
        </div>
      )}
      <div className={styles.menu__container}>
        <ul className={styles.menu__container__list}>
          {/* <li className={styles.menu__container__item}>
            <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}>
            <GoLocation className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} />
            Lebanon
            </Link>
          </li> */}
          {/* <li className={styles.menu__container__item}>
            <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}>
            <TbDiscount2 className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} />
            Catch Of the Day
            </Link>
          </li> */}
          {/* <li className={styles.menu__container__item}>
           <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}>
            <MdFiberNew className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} />
            New Arrivals
            </Link> 
          </li> */}
          <li className={styles.menu__container__item}>
            <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/special-offers"}>
            <img src={darkMode ? '/Special Offers - white.svg' : '/Special Offers.svg'} alt="FAQs" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`}/> 
            Special Offers
            </Link>
          </li>
          {user && (
            <>
              <li className={styles.menu__container__item}>
                <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/chat"}>
                <img src={darkMode ? '/Chat - white.svg' : '/Chat.svg'} alt="FAQs" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`}/>                 Chats
                </Link>
              </li>
              <li className={styles.menu__container__item}>
                <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/wishlist"}>
                <img src={darkMode ? '/Wishlist - white.svg' : '/Wishlist.svg'} alt="FAQs" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`}/>                   Wishlist
                </Link>
              </li>
              <li className={styles.menu__container__item}>
                <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/notifications"}>
                  <img src={darkMode ? '/notifications - white.svg' : '/notifications.svg'} alt="notifications" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} />
                  Notifications
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className={styles.setting__container}>
        <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Setting</h1>
        <ul>
          {user && (
          <li className={styles.list__item}>
            <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/notifications-setting"}>
            <img src={darkMode ? '/notifications - white.svg' : '/notifications.svg'} alt="notifications" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> Notifications Settings
            </Link>
          </li>
          )}
          <li className={styles.list__item} >
            <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}>
            <img src={darkMode ? '/Icon feather-moon - white.svg' : '/dark mode on.svg'} alt="dark mode on" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> Dark Mode{" "}
            <Switch
          checked={darkMode}
          onChange={handleToggleDarkMode}
          color="primary"
          className='switchD' 
        />
            </Link>
            <div className={styles.toggle}></div>
          </li>
          {/* <li className={styles.list__item}>
            <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}>
            <TbWorld className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`} /> Language
            </Link>
          </li> */}
        </ul>
      </div>
      <div className={styles.setting__container}>
        <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Help & Support</h1>
        <ul>
          <li className={styles.list__item}>
            <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/contact-us"}>
            <img src={darkMode ? '/contact us - white.svg' : '/contact us.svg'} alt="contact us" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`}/> Contact Us
            </Link>
          </li>
          <li className={styles.list__item}>
            <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/FAQ"} >
            <img src={darkMode ? '/FAQ - white.svg' : '/FAQ.svg'} alt="FAQs" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`}/> FAQ
            </Link>
          </li>
          <li className={styles.list__item}>
            <Link className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`} to={"/help"}>
            <img src={darkMode ? '/Help - white.svg' : '/Help.svg'} alt="FAQs" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`}/>  Help
            </Link>
          </li>
          {user && (
            <li id='lgt' className={styles.list__item} onClick={handleLogout} style={{cursor:'pointer'}}>
               <Link style={{color:'#ba2c2d'}}  className={`${styles.linkb} ${darkMode ? styles.linkbDark : ''}`}>
              <img src='/log out.svg' alt="log out" className={`${styles.icon} ${darkMode ? styles.iconDark : ''}`}/> Logout
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
export default SideDrawer;
