import React from "react";
import styles from "./index.module.scss";
import { format } from "date-fns";
import { useSelector } from "react-redux";
const DateContainer = ({ selectedDay, offerByDate }) => {
  const formattedDay = format(selectedDay, "d");
  const formattedFullDate = format(selectedDay, "EEE, MMMM yyyy");
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <div className={`${styles.date_container} ${darkMode ? styles.date_containerDark : ''}`}>
      <div className={styles.wrapper}>
        <div className={styles.flex}>
          <h1 className={styles.title}>{formattedDay}</h1>
          <div className={`${styles.offers_container} ${darkMode ? styles.offers_containerDark : ''}`}>
            <p>
              <span className={styles.bids_circle}></span>
              {offerByDate.productsWithBidCount > 1 ? `${offerByDate.productsWithBidCount} Bids` : `${offerByDate.productsWithBidCount} Bid`}
            </p>
            <p>
              <span className={styles.offers_circle}></span>
              {offerByDate.productsWithoutBidCount > 1 ? `${offerByDate.productsWithoutBidCount} Offers` : `${offerByDate.productsWithoutBidCount} Offer`}
            </p>
            <p>
              <span className={styles.services_circle}></span>
              {offerByDate.serviceCount > 1 ? `${offerByDate.serviceCount} Services` : `${offerByDate.serviceCount} Service`}
            </p>
          </div>
        </div>
        <p className={`${styles.full_date} ${darkMode ? styles.full_dateDark : ''}`}>{formattedFullDate}</p>
        <p className={styles.deals}>Done Deals: {offerByDate.soldProductCount}</p>
      </div>
    </div>
  );
};
export default DateContainer;
