import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Pagination } from 'react-bootstrap';
import styles from "./FeaturedProductPage.module.scss";
import Layout from "../../../../common/components/Layouts/Layout";
import CurrentPath from "../../../../common/components/CurrentPath/CurrentPath";
import Search from "../../../../common/components/Search/Search";
import Header from "../../../../common/components/Header/Header";
import Footer from "../../../../common/components/Footer/Footer";
import Cards from "../../../../common/components/Cards/Cards";
import { CgSortAz } from "react-icons/cg";
import { MdGridView } from "react-icons/md";
import { AiFillFilter, AiOutlineMenu } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../../../common/components/ErrorMessage/ErrorMessage";
import CardSkel from "../../../../common/components/Cards/CardSkel";
import { Helmet } from "react-helmet";
import { SearchActionService } from "../../../../store/actions/productDetailsAction";
import { useNavigate } from "react-router-dom";
import { SearchAllService } from "../../../../store/actions/productDetailsAction";
import FilterModal from "../../../../common/components/FilterModal/FilterModal";
import { GetServicesWishlist } from "../../../../store/actions/servicesActions";
import { GetWishList } from "../../../../store/actions/whishlistActions";
const AllServicesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activePage, setActivePage] = useState(1);
    const [Pro, setPro] = useState([])
    const [sort, setSort] = useState("")
    useEffect(() => {
        const pageName = "All services "; // Replace this with the actual page name
        const pageUrl = "/services"; // Replace this with the actual page URL
        dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }, []);
    const filterValue = useSelector((state) => state.filterValue?.convertedFilterValues)
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    const params = useParams();
    const location = useLocation();
    const [filterValues, setFilterValues] = useState({});
    const search = useSelector((state) => state.searchAllService?.searchAllService?.data?.services)
    const pageSize = useSelector((state) => state.searchAllService?.searchAllService?.data?.pageSize)
    const totalProducts = useSelector((state) => state.searchAllService?.searchAllService?.data?.serviceCount)
    const totalPages = Math.ceil(totalProducts / pageSize);
    const columnSize = window.innerWidth < 768 ? 10 : 12;
    const handleFilterInputChange = (event, key) => {
        const { value } = event.target;
        setFilterValues(prevValues => ({ ...prevValues, [key]: value }));
    }
    const handleFilterValuesUpdate = (newFilterValues) => {
        setFilterValues(newFilterValues);
    }
    const convertedFilterValues = Object.entries(filterValues).reduce((acc, [key, value]) => {
        let parsedValue = value;
        if (!isNaN(parseInt(value))) {
            parsedValue = parseInt(value);
        } else {
            try {
                parsedValue = JSON.parse(value);
            } catch {
                // If the value is not a valid JSON string, leave it as is
            }
        }
        return { ...acc, [key]: parsedValue };
    }, {});
    const handleCategoryFilter = () => {
        // console.log("filter")
    };
    const ProductArray = Pro && Pro.products
    const [first, setFirst] = useState(true);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const { products, error } = Pro;
    const loading = useSelector(
        (state) => state.cat?.loading
    );
    const activateFirst = () => {
        setFirst(true);
        setSecond(false);
        setThird(false);
    };
    const activateSecond = () => {
        setFirst(false);
        setSecond(true);
        setThird(false);
    };
    const activateThird = () => {
        setFirst(false);
        setSecond(false);
        setThird(true);
    };
    const handleSortChange = async (event) => {
        const selectedValue = event.target.value;
        // Save the selected value in the state variable "setSort"
        setTest(filterValue)
        if (selectedValue === 'A-Z') {
            setSort('az');
        } else if (selectedValue === 'Z-A') {
            setSort('za');
        } else if (selectedValue === 'Highest-to-lowest-price') {
            setSort('high');
        } else if (selectedValue === 'lowest-to-highest-price') {
            setSort('low'); // Handle other cases or the default value
        } else if (selectedValue === 'featured') {
            setSort('featured')
        } else if (selectedValue === 'default') {
            setSort("")
        }
    };
    const [test, setTest] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            let parms;
            try {
                if (location.state?.type) {
                    setTest(filterValue)
                    parms = { page: activePage, pageSize: columnSize, sort: sort, rawData: test, type: location.state?.type };
                } else {
                    setTest(filterValue)
                    parms = { page: activePage, pageSize: columnSize, sort: sort, rawData: test };
                }
                if (location.state?.categoryId) {
                    parms = { ...parms, categoryId: location.state?.categoryId };
                }
                const response = await dispatch(SearchAllService(parms));
            } catch (error) {
                // console.log(error);
            }
        };
        fetchData(); // Call the function to fetch data when the "sort" state changes
    }, [sort]);
    const [view, setView] = useState(false);
    useEffect(() => {
        function handleResize() {
            setView(window.innerWidth < 445);
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handlePageChange = async (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setActivePage(pageNumber);
        }
        if (sort) {
            let parms;
            if (location.state?.type) {
                setTest(filterValue)
                parms = { page: pageNumber, pageSize: columnSize, sort: sort, rawData: test, type: location.state?.type };
            } else {
                setTest(filterValue)
                parms = { page: pageNumber, pageSize: columnSize, sort: sort, rawData: test };
            }
            if (location.state?.categoryId) {
                parms = { ...parms, categoryId: location.state?.categoryId };
            }
            try {
                const response = await dispatch(SearchAllService(parms));
                navigate("/services");
            } catch (error) {
                // console.log(error);
            }
        } else {
            let parms;
            if (location.state?.type) {
                setTest(filterValue)
                parms = { page: pageNumber, pageSize: columnSize, rawData: test, type: location.state?.type };
            } else {
                setTest(filterValue)
                parms = { page: pageNumber, pageSize: columnSize, rawData: test };
            }
            if (location.state?.categoryId) {
                parms = { ...parms, categoryId: location.state?.categoryId };
            }
            try {
                const response = await dispatch(SearchAllService(parms));
                navigate("/services");
            } catch (error) {
                // console.log(error);
            }
        }
    };
    const [isOpen, setIsOpen] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const savedValue = useSelector((state) => state.value.value);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleFilter = () => {
        if (screenWidth > 900) {
            dispatch({ type: 'SAVE_VALUE', payload: !savedValue });
            setIsOpen(!savedValue)
        } else {
            dispatch({ type: 'SAVE_VALUE', payload: false });
            setIsFilterOpen(true)
        }
    };
    useEffect(() => {
        return () => {
            dispatch({ type: 'SAVE_VALUE', payload: false });
        };
    }, []);
    // Handle visibilitychange event
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                dispatch({ type: 'SAVE_VALUE', payload: false });
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    const ServicesWishlist = useSelector((state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList)
    const wishList = useSelector((state) => state.Wishlist?.Wishlist?.data?.wishList)
    useEffect(() => {
        dispatch(GetServicesWishlist())
        dispatch(GetWishList())
    }, [dispatch]);
    return (
        <>
            <Helmet>
                <title>Search Results</title>
                <meta name={"Search Page"} content="This is Search Page" />
                <meta
                    name="keywords"
                    content={`Services, All Services, La7i27alak Services, Offered services, requested services, la7i27alak`}
                />
            </Helmet>
            <Layout footer={true} isFilterIcon={true} handleFilterValuesUpdate={handleFilterValuesUpdate} handleFilterInputChange={handleFilterInputChange} allServices={true} typeId={location.state?.categoryId} onCategoryFilter={handleCategoryFilter} sort={sort}>
                <section className={`${styles.category_section} ${darkMode ? styles.category_sectionDark : ''}`}>
                    <div className={styles.result_container}>
                        <h1 className={`${styles.result_title} ${darkMode ? styles.result_titleDark : ''}`}>
                            {search && search.length} Results Found
                        </h1>
                        <div className={styles.result_options}>
                            <div onClick={handleFilter} className={styles.filter_container}>
                                <AiFillFilter className={styles.filter_icon} />
                                <button className={styles.filter_btn}>Filter</button>
                            </div>
                            <span>
                                <CgSortAz className={`${styles.sort_icon} ${darkMode ? styles.sort_iconDark : ''}`} />{" "}
                                <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`} htmlFor="sort">Sort:</label>
                                <select className={`${styles.select} ${darkMode ? styles.selectDark : ''}`} name="sort" id="sort" onChange={handleSortChange}>
                                    <option value="default" selected disabled={sort === "default"}>Sort</option>
                                    <option value="featured">Featured</option>
                                    <option value="A-Z">Alphabetically: A-Z</option>
                                    <option value="Z-A">Alphabetically: Z-A</option>
                                    <option value="Highest-to-lowest-price">Highest to Lowest Price</option>
                                    <option value="lowest-to-highest-price">Lowest to Highest Price</option>
                                </select>
                            </span>
                            <span className={`${styles.span} ${darkMode ? styles.spanDark : ''}`}
                                onClick={() => setView(!view)}
                            >
                                View:
                                {view ? (
                                    <AiOutlineMenu className={styles.view_icon}
                                    />
                                ) : (
                                    <MdGridView className={styles.view_icon} />
                                )}
                            </span>
                        </div>
                    </div>
                    {loading ? (
                        <div>
                            {/* <Loader /> */}
                            <CardSkel />
                        </div>
                    ) : error ? (
                        <div className="center">
                            <ErrorMessage>{error}</ErrorMessage>
                        </div>
                    ) : (
                        <div className={`grid gap-4
                    ${view ? 'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3'
                                :
                                'grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4'}`}>
                            {search && search.length > 0 ? (
                                search.filter((p) => p.status === 'confirmed').map((p) => (
                                    <Cards
                                        id={p._id}
                                        key={p._id}
                                        title={p.title}
                                        price={p.pricing.price}
                                        old={p.condition}
                                        time={p.createdAt}
                                        mainimage={p.imageUrls[0]}
                                        featured={p.featured}
                                        location={p.serviceAddress?.region}
                                        timer={p.timer?.enabled}
                                        end={p.timer?.deadline}
                                        discounted={p.discount?.enabled}
                                        percentage={p.discount?.percentage}
                                        mobile={view}
                                        isService={p.isService}
                                        ServicesWishlist={ServicesWishlist}  // Pass ServicesWishlist as prop
                                        wishList={wishList}
                                    />
                                ))
                            ) : (
                                <div className={styles.noresultsearch}>
                                    <h2>No Results Found</h2>
                                </div>
                            )}
                        </div>
                    )}
                    {totalProducts > 0 && (
                        <Pagination style={{ marginBottom: '0', paddingBottom: '20px' }}>
                            {activePage === 1 ? null : (
                                <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
                            )}
                            {totalPages === 1 ? (
                                <Pagination.Item linkStyle={{ background: '#0298a6', border: 'none' }} active>{activePage}</Pagination.Item>
                            ) : (
                                <>
                                    {activePage === 1 ? null : (
                                        <Pagination.Item onClick={() => handlePageChange(activePage - 1)} active={activePage === 1}>
                                            {activePage - 1}
                                        </Pagination.Item>
                                    )}
                                    <Pagination.Item linkStyle={{ background: '#0298a6', border: 'none' }} active>{activePage}</Pagination.Item>
                                    {activePage === totalPages ? null : (
                                        <Pagination.Item onClick={() => handlePageChange(activePage + 1)} active={activePage === totalPages}>
                                            {activePage === totalPages ? activePage : activePage + 1}
                                        </Pagination.Item>
                                    )}
                                </>
                            )}
                            {activePage === totalPages ? null : (
                                <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPages} />
                            )}
                        </Pagination>
                    )}
                </section>
            </Layout>
            <FilterModal
                open={isFilterOpen}
                onClose={() => setIsFilterOpen(false)}
                allServices={true}
                onCategoryFilter={handleCategoryFilter}
                sort={sort}
            />
        </>
    );
};
export default AllServicesPage;