import {
  EDIT_PRODUCT_FAIL,
  EDIT_PRODUCT_REQUEST,
  EDIT_PRODUCT_SUCCESS,
  GET_USER_PRODUCTS_FAIL,
  GET_USER_PRODUCTS_SUCCESS
} from "../constants/myProductsContants";
const initialState = {
  myProducts: [],
  error: null,
  loading: false
};
export const UserProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_PRODUCTS_REQUEST":
      return {
        ...state,
        myProducts: action.payload,
        loading: true,
        error: null,
      };
    case "GET_USER_PRODUCTS_SUCCESS":
      return {
        ...state,
        myProducts: action.payload,
        loading: false,
        error: null,
      };
    case "GET_USER_PRODUCTS_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
const initialStatedt = {
  edtProducts: [],
  error: null,
  loading: false
};
export const EditProductsReducer = (state = initialStatedt, action) => {
    switch (action.type) {
        case EDIT_PRODUCT_REQUEST:
            return {
                ...state,
                edtProducts: action.payload,
                error: null,
                loading: true,
            };
        case EDIT_PRODUCT_SUCCESS:
            return {
                ...state,
                edtProducts: action.payload,
                error: null,
                loading: false,
            };
        case EDIT_PRODUCT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
