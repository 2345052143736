import {
  WHISHLIST_FAIL,
  WHISHLIST_REQUEST,
  WHISHLIST_SUCCESS,
  BID_WHISHLIST_REQUEST,
  BID_WHISHLIST_FAIL,
  BID_WHISHLIST_SUCCESS,
  PRODUCT_WHISHLIST_REQUEST,
  PRODUCT_WHISHLIST_FAIL,
  PRODUCT_WHISHLIST_SUCCESS,
  SERVICE_WHISHLIST_REQUEST,
  SERVICE_WHISHLIST_SUCCESS,
  SERVICE_WHISHLIST_FAIL,
  REMOVE_ITEM_WHISHLIST_REQUEST,
  REMOVE_ITEM_WHISHLIST_SUCCESS,
  REMOVE_ITEM_WHISHLIST_FAIL,
  REMOVE_ITEM_WHISHLIST_RESET,
  WHISHLIST_IDS_REQUEST,
  WHISHLIST_IDS_SUCCESS,
  WHISHLIST_IDS_RESET,
  WHISHLIST_IDS_FAIL,
  ADD_ITEM_WHISHLIST_SUCCESS,
  ADD_ITEM_WHISHLIST_FAIL,
  ADD_ITEM_WHISHLIST_REQUEST,
  ADD_ITEM_WHISHLIST_RESET,
  ADD_WHISHLIST_SUCCESS,
  ADD_WHISHLIST_FAIL,
  REMOVE_WHISHLIST_FAIL,
  REMOVE_WHISHLIST_SUCCESS
} from "../constants/wishlistConstants";
export const whishListReducer = (state = { whishlist: [] }, action) => {
  switch (action.type) {
    case WHISHLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WHISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        whishlist: action.payload,
      };
    case WHISHLIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const removeFromWishlistReducer = (
  state = { success: false, loading: false, errro: null },
  action
) => {
  switch (action.type) {
    case REMOVE_ITEM_WHISHLIST_REQUEST:
      return { ...state, loading: true };
    case REMOVE_ITEM_WHISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case REMOVE_ITEM_WHISHLIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REMOVE_ITEM_WHISHLIST_RESET:
      return { success: false, loading: false, error: null };
    default:
      return state;
  }
};
export const whishListIDReducer = (
  state = { whishlistID: [], error: null, loading: false },
  action
) => {
  switch (action.type) {
    case WHISHLIST_IDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case WHISHLIST_IDS_SUCCESS:
      return {
        ...state,
        loading: false,
        whishlistID: action.payload,
      };
    case WHISHLIST_IDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case WHISHLIST_IDS_RESET:
      return {
        whishlistID: [],
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};
export const addToWishlistReducer = (
  state = { success: false, loading: false, errro: null },
  action
) => {
  switch (action.type) {
    case ADD_ITEM_WHISHLIST_REQUEST:
      return { ...state, loading: true };
    case ADD_ITEM_WHISHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ADD_ITEM_WHISHLIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_ITEM_WHISHLIST_RESET:
      return { success: false, loading: false, error: null };
    default:
      return state;
  }
};
// export const bidWhishListReducer = (state = { bidWhishList: [] }, action) => {
//   switch (action.type) {
//     case BID_WHISHLIST_REQUEST:
//       return {
//         loading: true,
//       };
//     case BID_WHISHLIST_SUCCESS:
//       return {
//         loading: false,
//         bidWhishList: action.payload,
//       };
//     case BID_WHISHLIST_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };
// export const productWhishListReducer = (
//   state = { productWhishList: [] },
//   action
// ) => {
//   switch (action.type) {
//     case PRODUCT_WHISHLIST_REQUEST:
//       return {
//         loading: true,
//       };
//     case PRODUCT_WHISHLIST_SUCCESS:
//       return {
//         loading: false,
//         productWhishList: action.payload,
//       };
//     case PRODUCT_WHISHLIST_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };
// export const serviceWhishListReducer = (
//   state = { serviceWhishList: [] },
//   action
// ) => {
//   switch (action.type) {
//     case SERVICE_WHISHLIST_REQUEST:
//       return {
//         loading: true,
//       };
//     case SERVICE_WHISHLIST_SUCCESS:
//       return {
//         loading: false,
//         serviceWhishList: action.payload,
//       };
//     case SERVICE_WHISHLIST_FAIL:
//       return {
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };
const initialState = {
  whishlistArray: [],
  error: null,
  loading: false
};
const wishlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_WHISHLIST_SUCCESS:
      return {
        ...state,
        whishlistArray: [...state.whishlistArray, action.payload],
        loading: false
      };
    case REMOVE_WHISHLIST_SUCCESS:
      return {
        ...state,
        whishlistArray: state.whishlistArray.filter(id => id !== action.payload),
        loading: false
      };
    default:
      return state;
  }
};
export default wishlistReducer;