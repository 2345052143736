import React from "react";
import styles from "./EmptyAddresses.module.scss";
import img from "../../../assests/svg/empty address.svg";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const EmptyAddresses = () => {
  const navigate = useNavigate();
  return (
    <>
    <Helmet>
      <title>Address Page</title>
    </Helmet>
    <div className={styles.container}>
      <img src={img} alt="empty address" />
      <h1 className={styles.title}>No Addresses Found</h1>
      <p className={styles.text}>
        Set one or more addresses where you can meet people for deals easier
      </p>
      <button
        className={styles.btn}
        onClick={() => navigate("/user/addresses/add-address")}
      >
        <HiOutlineLocationMarker className={styles.icon} />
        Add Addresses
      </button>
    </div>
    </>
  );
};
export default EmptyAddresses;
