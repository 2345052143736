import React, { useState, useEffect } from "react";
import "./AdminCategories.scss";
import styles from "./AdminCategories.scss";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import Calendar from "../../assests/svg/calender.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsCaretDown } from "react-icons/bs";
import { Button } from "bootstrap";
import ActiveCategory from "./ActiveCategory/ActiveCategory";
import DropFileInput from "./components/DragAndDrop";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { CreateCategory } from "../../store/actions/categoryAction";
import { GetAllCategories } from "../../store/actions/categoryAction";
import { GetAllCategoriesSuccess } from "../../store/actions/categoryAction";
import { Form as BootstrapForm } from "react-bootstrap";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import { BsCheckLg } from "react-icons/bs";
import { CreateServiceType, GetAllTypes } from "../../store/actions/servicesActions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AdminCategories = ({ setFormData }) => {
  const [fields, setFields] = useState([
    {
      type: " ",
      title: " ",
      possibleValues: [],
    },
  ]);
  const [categoryType, setCategoryType] = useState('Products');
  const handleCategoryTypeChange = (type) => {
    setCategoryType(type);
  };
  const handlepsButtonClick = (buttonNumber) => {
    setCategoryType(buttonNumber);
    // props.onCategoryTypeChange(categoryType);
  };
  const [subcategorySearch, setSubcategorySearch] = useState("");
  const dropdownOptions = ["list", "boolean", "string", "number"]
  const [field, setField] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [activeButton, setActiveButton] = useState(1);
  const dispatch = useDispatch();
  const [responses, setResponse] = useState([])
  const [inputCount, setInputCount] = useState(0);
  const [inputCount1, setInputCount1] = useState(0);
  const [subCount, setSubCount] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownState, setDropdownState] = useState({});
  ///for Sub categ and field
  const [step, setStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const nextStep = () => {
    setCompletedSteps([...completedSteps, step]);
    setStep(step + 1);
  };
  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleSubCatNameChange = (event) => {
    setSubcategorySearch(event.target.value);
  };
  const currentDate = new Date();
  const formattedDate = `${selectedDate.toLocaleString("default", {
    month: "short"
  })} ${selectedDate.getDate()}`;
  const formik = useFormik({
    initialValues: {
      categorysearch: '',
      Subcategorysearch: '',
      Field1search: '',
      ...Array.from({ length: inputCount1 }).reduce(
        (acc, _, index) => ({
          ...acc,
          [`Field1search${index + 1}`]: '',
        }),
        {}
      ),
      Subcategory2search: '',
      Field2search: '',
      ...Array.from({ length: inputCount }).reduce(
        (acc, _, index) => ({
          ...acc,
          [`Field2search${index + 1}`]: '',
        }),
        {}
      ),
      selectedFile: null,
    },
  });
  const handleSubmit = async (e) => {
    try {
      const formData = new FormData();
      formData.append("title", formik.values.categorysearch);
      formData.append("icon", formik.values.selectedFile);
      const response = await dispatch(CreateCategory(formData));
      setResponse(response.data)
      if (response) {
        toast.success("Category created successfully.")
      }
    } catch (error) {
      // console.log(error);
    } 
  }
  useEffect(() => {
    dispatch(GetAllTypes())
    dispatch(GetAllCategories())
      .then((data) => {
        dispatch(GetAllCategoriesSuccess(data));
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch]);
  const types = useSelector((state) => state.types?.types?.data?.types)
  const categories = useSelector((state) => state.categories?.categories?.data?.categories);
  const loading = useSelector((state) => state.categories.loading);
  const error = useSelector((state) => state.categories.error);
  if (loading) {
    return <div>Loading...</div>;
  }
  // if (categories && categories.length === 0) {
  //   return <div>No categories found</div>;
  // }
  return (
    <AdminLayout>
      <div className="container-c">
        <div className="header">
          <h3>Categories</h3>
          {/* <div className="calendar">
            <img src={Calendar} alt="" />
            Today <span className="date"> {formattedDate}</span>
            <button onClick={toggleDatePicker}>
              <BsCaretDown />
            </button>
            {showDatePicker && (
              <div className="datepicker-wrapper">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showPopperArrow={false}
                  dateFormat="MMM d"
                  inline
                />
              </div>
            )}
          </div> */}
        </div>
        {/* <div className="toolbar">
          <button className="filter-button">Filter</button>
          <div className="search_input">
            <input type="text" placeholder="Search..." />
            <button className="search_icon">Search</button>
          </div>
        </div> */}
        <div className="content">
          <div className="left-panel">
            {/* <div className="scrollable"> */}
            <div className="left-title">
              <h3>Active Categories</h3>
            </div>
            <hr className="hr" />
            <div className="threeChoices">
              <button
                className={activeButton === 1 ? "active" : ""}
                onClick={() => handleButtonClick(1)}
              >
                Products
              </button>
              <button
                className={activeButton === 2 ? "active" : ""}
                onClick={() => handleButtonClick(2)}
              >
                Services
              </button>
            </div>
            {activeButton === 1 ? (
              categories && categories.map((category) => (
                <ActiveCategory photo={category.iconUrl} text={category.title} categoryId={category._id} />
              ))
            ) : (
              types && types.map((category) => (
                <ActiveCategory text={category.title} ServiceTypeId={category._id} isService={true} />
              ))
            )}
            {/* </div> */}
          </div>
          <div class="right-panel">
            <div class="inner">
              <div className="fstdiv">
                <div class="row">
                  <div class="right-title">
                    <h3>Add Category</h3>
                  </div>
                  <div className="resetDiv">
                    <button className="reset" onClick={() => window.location.reload()}>Reset</button>
                  </div>
                </div>
              </div>
              <ProgressBar percent={((step - 1) / 2) * 100} filledBackground="#0298a6" strokeColor="#0298a6">
                <Step>
                  {({ accomplished }) => (
                    <div className={`step ${accomplished ? "completed" : null}`}>
                      <div style={{marginLeft:'30px'}} className="step-circle">
                        {completedSteps.includes(1) ? <BsCheckLg /> : 1}
                      </div>
                      <div style={{marginLeft:'20px'}} className="step-label">Create Category</div>
                    </div>
                  )}
                </Step>
                <Step>
                  {({ accomplished }) => (
                    <div className={`step ${accomplished ? "completed" : ""}`}>
                      <div className="step-circle">{2}</div>
                      <div className="step-label">Add SubCategories</div>
                    </div>
                  )}
                </Step>
              </ProgressBar>
              {/* step1 */}
              <div className='ProdServ'>
                <div className='catT'><p>Category type</p></div>
                <button
                  className={categoryType === "Products" ? "activeps" : ""}
                  onClick={() => handlepsButtonClick("Products")}
                >
                  Products
                </button>
                <button
                  className={categoryType === "Services" ? "activeps" : ""}
                  onClick={() => handlepsButtonClick("Services")}
                >
                  Services
                </button>
              </div>
              {categoryType === "Products" ? (
                <BootstrapForm onSubmit={formik.handleSubmit}>
                  <BootstrapForm.Group controlId="formStep1" style={{ display: step === 1 ? "block" : "none" }}>
                    <div class="subtitle">
                      <p>Category Name</p>
                    </div>
                    <div class="CategName">
                      <input type="text"
                        id="category-search"
                        name="categorysearch"
                        placeholder="Enter Category's Name Here"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.categorysearch} />
                    </div>
                    <div className="bigbox">
                      <div className="box">
                        <DropFileInput selectedFile={formik.values.selectedFile} onCategoryTypeChange={handleCategoryTypeChange} onFileChange={(updatedList) => {
                          formik.setFieldValue('selectedFile', updatedList[0]);
                        }} />
                      </div>
                    </div>
                    <button onClick={() => { nextStep(); handleSubmit() }} className="Create" type={"submit"} >Add Category</button>
                  </BootstrapForm.Group>
                  {/* step2 */}
                  <BootstrapForm.Group controlId="formStep2" style={{ display: step === 2 ? "block" : "none" }}>
                    <div class="subtitle">Subcategory</div>
                    <div class="CategName">
                      <input
                        type="text"
                        id="category-search"
                        name="Subcategorysearch"
                        placeholder="Enter Subcategory Name "
                        onChange={handleSubCatNameChange}
                        value={subcategorySearch} />
                    </div>
                    <Formik
                      initialValues={{
                        field: [{ type: "", title: "" }]
                      }}
                      validationSchema={Yup.object({
                        field: Yup.array().of(
                          Yup.object().shape({
                            type: Yup.string().required("Type is required"),
                            title: Yup.string().required("Title is required"),
                            possibleValues: Yup.string().when("type", {
                              is: (type) => ["list", "boolean"].includes(type),
                              then: Yup.string().required("Possible Values are required"),
                              otherwise: Yup.string().strip()
                            })
                          })
                        )
                      })}
                      onSubmit={(values, { resetForm }) => {
                        const processedValues = values.field.map(field => {
                          if (["list", "boolean"].includes(field.type)) {
                            const possibleValuesArray = field.possibleValues
                              ? field.possibleValues.split(',').map(value => value.trim())
                              : [];
                            return { ...field, possibleValues: possibleValuesArray };
                          }
                          const { possibleValues, ...rest } = field;
                          return rest;
                        });
                        // Perform your submission logic here
                        try {
                          const formData = new FormData();
                          formData.append("parentCategoryId", responses.category?._id)
                          formData.append("title", subcategorySearch)
                          formData.append("icon", formik.values.selectedFile)
                          formData.append("fields", JSON.stringify(processedValues))
                          const response = dispatch(CreateCategory(formData));
                          if (response) {
                            toast.success("Category created successfully.")
                          }
                          // Reset the form after successful submission
                          resetForm();
                          setSubcategorySearch("")
                        } catch (error) {
                          // console.log(error);
                        }
                      }}
                      render={({ values, handleSubmit }) => (
                        <Form>
                          <FieldArray
                            name="field"
                            render={arrayHelpers => {
                              const field = values.field;
                              return (
                                <div>
                                  {field && field.length > 0
                                    ? field.map((user, index) => (
                                      <div key={index}>
                                        <div class="subtitle">Specific Field to Subcategory</div>
                                        <div class="CategName">
                                          <Field
                                            type="text"
                                            id="title"
                                            name={`field.${index}.title`}
                                            className="FieldInput"
                                            placeholder="Enter Field title"
                                          />
                                          <div style={{ color: 'red', fontSize: '12px' }} >
                                            <ErrorMessage className="errormsg" name={`field.${index}.title`} />
                                          </div>
                                          <Field
                                            as="select"
                                            id={`field.${index}.type`}
                                            name={`field.${index}.type`}
                                            className="FieldDropdown"
                                          >
                                            <option value="">Select an option</option>
                                            {dropdownOptions.map((option) => (
                                              <option className='opt' key={option} value={option}>
                                                {option}
                                              </option>
                                            ))}
                                          </Field>
                                          {values.field[index].type === 'list' || values.field[index].type === 'boolean' ? (
                                            <Field
                                              type="text"
                                              id={`field.${index}.possibleValues`}
                                              name={`field.${index}.possibleValues`}
                                              className="possibleValues"
                                              placeholder="Enter Possible Values separated with a comma"
                                            />
                                          ) : null}
                                          <div style={{ color: 'red', fontSize: '12px' }} >
                                            <ErrorMessage
                                              name={`field.${index}.type`}
                                              component="div"
                                              className="error"
                                            />
                                          </div>
                                          <div style={{ color: 'red', fontSize: '12px' }} >
                                            <ErrorMessage
                                              name={`field.${index}.possibleValues`}
                                              component="div"
                                              className="error"
                                            />
                                          </div>
                                          <br />
                                        </div>
                                        <div class="plus-button-container">
                                          <button
                                            type="button"
                                            class="plus-button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                          >
                                            -
                                          </button>
                                        </div>
                                        <br />
                                        <br />
                                      </div>
                                    ))
                                    : null}
                                  <div class="plus-button-container">
                                    <button type="button" class="plus-button" onClick={() =>
                                      arrayHelpers.push({
                                        type: "",
                                        title: ""
                                      })}>+</button>
                                    <span>Add Specific Field</span>
                                  </div>
                                  <div className="button-row">
                                    <button className="AddSub" type="submit" onClick={handleSubmit}>Create Category</button>
                                  </div>
                                </div>
                              );
                            }}
                          />
                          <hr />
                        </Form>
                      )}
                    />
                  </BootstrapForm.Group>
                  {/* ...other code */}
                </BootstrapForm>
              ) : (
                <div>
                  <BootstrapForm.Group controlId="formStep2" style={{ display: step === 1 ? "block" : "none" }}>
                    <div class="subtitle">Title</div>
                    <div class="CategName">
                      <input
                        type="text"
                        id="category-search"
                        name="Subcategorysearch"
                        placeholder="Enter Subcategory Name "
                        onChange={handleSubCatNameChange}
                        value={subcategorySearch} />
                    </div>
                    <Formik
                      initialValues={{
                        field: [{ type: "", title: "" }]
                      }}
                      validationSchema={Yup.object({
                        field: Yup.array().of(
                          Yup.object().shape({
                            type: Yup.string().required("Type is required"),
                            title: Yup.string().required("Title is required"),
                            possibleValues: Yup.string().when("type", {
                              is: (type) => ["list", "boolean"].includes(type),
                              then: Yup.string().required("Possible Values are required"),
                              otherwise: Yup.string().strip()
                            })
                          })
                        )
                      })}
                      onSubmit={(values, { resetForm }) => {
                        const processedValues = values.field.map(field => {
                          if (["list", "boolean"].includes(field.type)) {
                            const possibleValuesArray = field.possibleValues
                              ? field.possibleValues.split(',').map(value => value.trim())
                              : [];
                            return { ...field, possibleValues: possibleValuesArray };
                          }
                          const { possibleValues, ...rest } = field;
                          return rest;
                        });
                        const combinedObject = {
                          title: subcategorySearch,
                          fields: processedValues,
                        };
                        // Perform your submission logic here
                        try {
                          const response = dispatch(CreateServiceType(combinedObject));
                          if (response) {
                            toast.success("Service type created successfully.")
                          }
                          // Reset the form after successful submission
                          resetForm();
                          setSubcategorySearch("")
                        } catch (error) {
                          // console.log(error);
                        }
                      }}
                      render={({ values, handleSubmit }) => (
                        <Form>
                          <FieldArray
                            name="field"
                            render={arrayHelpers => {
                              const field = values.field;
                              return (
                                <div>
                                  {field && field.length > 0
                                    ? field.map((user, index) => (
                                      <div key={index}>
                                        <div class="subtitle"> Field </div>
                                        <div class="CategName">
                                          <Field
                                            type="text"
                                            id="title"
                                            name={`field.${index}.title`}
                                            className="FieldInput"
                                            placeholder="Enter Field title"
                                          />
                                          <ErrorMessage name={`field.${index}.title`} />
                                          <Field
                                            as="select"
                                            id={`field.${index}.type`}
                                            name={`field.${index}.type`}
                                            className="FieldDropdown"
                                          >
                                            <option value="">Select an option</option>
                                            {dropdownOptions.map((option) => (
                                              <option key={option} value={option}>
                                                {option}
                                              </option>
                                            ))}
                                          </Field>
                                          {values.field[index].type === 'list' || values.field[index].type === 'boolean' ? (
                                            <Field
                                              type="text"
                                              id={`field.${index}.possibleValues`}
                                              name={`field.${index}.possibleValues`}
                                              className="possibleValues"
                                              placeholder="Enter Possible Values separated with a comma"
                                            />
                                          ) : null}
                                          <ErrorMessage
                                            name={`field.${index}.type`}
                                            component="div"
                                            className="error"
                                          />
                                          <ErrorMessage
                                            name={`field.${index}.possibleValues`}
                                            component="div"
                                            className="error"
                                          />
                                          <br />
                                        </div>
                                        <div class="plus-button-container">
                                          <button
                                            type="button"
                                            class="plus-button"
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                          >
                                            -
                                          </button>
                                        </div>
                                        <br />
                                        <br />
                                      </div>
                                    ))
                                    : null}
                                  <div class="plus-button-container">
                                    <button type="button" class="plus-button" onClick={() =>
                                      arrayHelpers.push({
                                        type: "",
                                        title: ""
                                      })}>+</button>
                                    <span>Add Specific Field</span>
                                  </div>
                                  <div className="button-row">
                                    <button className="AddSub" type="submit" onClick={handleSubmit}>Create Category</button>
                                  </div>
                                </div>
                              );
                            }}
                          />
                          <hr />
                        </Form>
                      )}
                    />
                  </BootstrapForm.Group>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};
export default AdminCategories;