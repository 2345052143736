import React, { useEffect, useState } from "react";
import styles from "./BuyerWaitList.module.scss";
import img from '../../assests/images/PS5 1.png'
import Card from "./Card/Card";
import BidCard from "./Card/bidCard";
import Layout from '../../common/components/Layouts/Layout'
import { useDispatch, useSelector } from "react-redux/es/exports";
import { GetLiveBids } from "../../store/actions/bidActions";
import { useNavigate } from "react-router-dom";
import Img from "../../assests/svg/order history empty.svg";
import { Helmet } from "react-helmet";
import Loader from "../../common/components/Loader/Loader";
const MyLiveBids = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "CLEAR_BREADCRUMBS" })
    const pageName = "My bids"; // Replace this with the actual page name
    const pageUrl = "/user/live-bids"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const liveBids = useSelector((state) => state.liveBids?.liveBids);
  const loading = useSelector((state) => state.liveBids?.loading);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(GetLiveBids());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>My Live Bids</title>
        <meta name='My Live Bids' content="My Live Bids" />
        <meta
          name="keywords"
          content={`My liuve bids, live bids, la7i27alak, products`}
        />
      </Helmet>
      <Layout title={'My Live Bids'}>
        <section className={styles.container}>
          <div>
            {loading?(
              <Loader/>
            ):(
            liveBids && liveBids.length > 0 ? (
              <div className={styles.card_container}>
                {liveBids.map((offer) => (
                  offer && (
                    <BidCard
                      key={offer._id}
                      id={offer._id}
                      title={offer.title}
                      featured={offer.featured}
                      image={offer.imageUrls[0]}
                      price={offer.pricing?.price}
                      location={offer.pickUpAddress?.region}
                      condition={offer.condition}
                      time={offer.createdAt}
                      isDiscount={offer.discount?.enabled}
                      newPrice={'555555'}
                      status={"Go To Bid"}
                      percentage={offer.discount?.percentage}
                    />
                  )
                ))}
              </div>
            ) : (
              <div className={styles.empty}>
                <img src={Img} alt='empty' />
                <h2 className={`${styles.txt} ${darkMode ? styles.txtDark : ''}`}>No Bids to show.</h2>
                <button className={`${styles.btn} ${darkMode ? styles.btnDark : ''}`} onClick={() => navigate("/")}>
                  Go Shopping
                </button>
              </div>
            ))}
          </div>
        </section>
      </Layout>
    </>
  );
};
export default MyLiveBids;
