import {
    GET_ALL_SEARCH_PRODUCT_FAIL,
    GET_ALL_SEARCH_PRODUCT_SUCCESS
  } from "../constants/productDetailsConstants";
  
  
  const initialState = {
    Searchproducts: [],
    error: null,
    loading: false
  };
  
  export const SearchProductReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_SEARCH_PRODUCT_SUCCESS":
        return {
          ...state,
          Searchproducts: action.payload,
          error: null,
          loading: false,
        };
      case "GET_ALL_SEARCH_PRODUCT_FAIL":
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      default:
        return state;
    }
  };