export const MYPRODUCTS_REQUEST = "MYPRODUCTS_REQUEST";
export const MYPRODUCTS_SUCCESS = "MYPRODUCTS_SUCCESS";
export const MYPRODUCTS_FAIL = "MYPRODUCTS_LIST_FAIL";
export const USER_PRODUCTS_REQUEST = "USER_PRODUCTS_REQUEST";
export const USER_PRODUCTS_SUCCESS = "USER_PRODUCTS_SUCCESS";
export const USER_PRODUCTS_FAIL = "USER_PRODUCTS_LIST_FAIL";
export const USER_PRODUCTS_RESET = "USER_PRODUCTS_LIST_RESET";
export const GET_USER_PRODUCTS_SUCCESS = "GET_USER_PRODUCTS_SUCCESS";
export const GET_USER_PRODUCTS_FAIL = "GET_USER_PRODUCTS_FAIL";
export const GET_USER_PRODUCTS_REQUEST = "GET_USER_PRODUCTS_REQUEST"
export const CONFIRM_PRODUCT_SUCCESS = "CONFIRM_PRODUCT_SUCCESS";
export const CONFIRM_PRODUCT_FAIL = "CONFIRM_PRODUCT_FAIL";
export const FEATURE_PRODUCT_SUCCESS = "FEATURE_PRODUCT_SUCCESS";
export const FEATURE_PRODUCT_FAIL = "FEATURE_PRODUCT_FAIL";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";
export const GET_SINGLE_PRODUCTS_SUCCESS = "GET_SINGLE_PRODUCTS_SUCCESS";
export const GET_SINGLE_PRODUCTS_FAIL = "GET_SINGLE_PRODUCTS_FAIL";
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCTS_SUCCESS";
export const EDIT_PRODUCT_FAIL = "EDIT_SINGLE_PRODUCTS_FAIL";
export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";
export const POST_COD_PRODUCT_SUCCESS = "POST_COD_PRODUCT_SUCCESS";
export const POST_COD_PRODUCT_FAIL = "POST_COD_PRODUCT_FAIL";
export const POST_COD_SERVICE_SUCCESS = "POST_COD_SERVICE_SUCCESS";
export const POST_COD_SERVICE_FAIL = "POST_COD_SERVICE_FAIL";
export const CHECK_IF_USER_IN_WAITING_LIST_SUCCESS = "CHECK_IF_USER_IN_WAITING_LIST_SUCCESS";
export const CHECK_IF_USER_IN_WAITING_LIST_FAIL = "CHECK_IF_USER_IN_WAITING_LIST_FAIL";
export const CONFIRM_SERVICE_SUCCESS = "CONFIRM_SERVICE_SUCCESS";
export const CONFIRM_SERVICE_FAIL = "CONFIRM_SERVICE_FAIL";
export const SELL_PRODUCT_SUCCESS = "SELL_PRODUCT_SUCCESS";
export const SELL_PRODUCT_FAIL = "SELL_PRODUCT_FAIL";
