import { FILTER_BAR, FILTER_ICON } from "../constants/filterConstant";
export const filterBarReducer = (state = { isFilterBarOn: false }, action) => {
  switch (action.type) {
    case FILTER_BAR:
      return {
        ...state,
        isFilterBarOn: action.payload,
      };
    default:
      return state;
  }
};
export const filterIconReducer = (state = { isFilterIcon: false }, action) => {
  switch (action.type) {
    case FILTER_ICON:
      return {
        ...state,
        isFilterIcon: action.payload,
      };
    default:
      return state;
  }
};
