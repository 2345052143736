import { GET_ENDED_BIDS_FAIL,GET_ENDED_BIDS_SUCCESS,GET_ENDED_BIDS_REQUEST } from "../constants/adminDashboardConstants";
const initialState = {
    endedBids: [],
    error: null,
    loading: false,
};
export const GetEndedBids = (state = initialState, action) => {
    switch (action.type) {
        case GET_ENDED_BIDS_REQUEST:
            return{
                ...state,
                endedBids: action.payload,
                error: null,
                loading:true
            }
        case GET_ENDED_BIDS_SUCCESS:
            return {
                ...state,
                endedBids: action.payload,
                error: null,
                loading: false,
            };
        case GET_ENDED_BIDS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
