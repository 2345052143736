import React, { useState } from "react";
import styles from "./OrderHistory.module.scss";
import Card from "../Card/Card";
import { useSelector } from "react-redux";
const OrderHistory = ({ orders }) => {
  const [filter, setFilter] = useState({
    offers: true,
    bids: false,
    services: false,
  });
  const [ordersHistory, setOrdersHistory] = useState(orders);
  const activateOffers = () => {
    setFilter({
      offers: true,
      bids: false,
      services: false,
    });
    setOrdersHistory(orders);
  };
  const activateBids = () => {
    setFilter({
      offers: false,
      bids: true,
      services: false,
    });
    setOrdersHistory(
      orders
        .filter(function (el) {
          return el.bidding?.enabled == "true";
        })
    );
  };
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const activateServices = () => {
    setFilter({
      offers: false,
      bids: false,
      services: true,
    });
    setOrdersHistory(
      orders.filter(function (el) {
        return el.isService == "true";
      })
    );
  };
  return (
    <section className={styles.container}>
      <ul className={styles.category__container}>
        <li
          className={`${styles.category__item} ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ""} ${filter.offers ? styles.active : ""
            }`}
          onClick={activateOffers}
        >
          Products
        </li>
        <li
          className={`${styles.category__item} ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ""} ${filter.bids ? styles.active : ""
            }`}
          onClick={activateBids}
        >
          Bids
        </li>
        <li
          className={`${styles.category__item} ${darkMode ? styles.category__itemDark : ''} ${darkMode ? styles.activeDark : ""} ${filter.services ? styles.active : ""
            }`}
          onClick={activateServices}
        >
          Services
        </li>
      </ul>
      <>
        <div className={styles.card_container}>
          {ordersHistory && ordersHistory
            .map((order) => {
              return (
                <Card
                  title={order.title}
                  key={order._id}
                  featured={order.featured}
                  image={order.imageUrls[0]}
                  price={order.pricing?.price}
                  location={order.pickUpAddress?.region}
                  condition={order.condition}
                  time={order.updatedAt}
                  isDiscount={order.discount?.enabled}
                  percentage={order.discount?.percentage}
                />
              );
            })}
        </div>
      </>
    </section >
  );
};
export default OrderHistory;
