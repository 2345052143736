import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import styles from "./CategoryPage.module.scss";
import CurrentPath from "../../common/components/CurrentPath/CurrentPath";
import Search from "../../common/components/Search/Search";
import Header from "../../common/components/Header/Header";
import Footer from "../../common/components/Footer/Footer";
import Cards from "../../common/components/Cards/Cards";
import { CgSortAz } from "react-icons/cg";
import { MdGridView } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { listMyProductsBySubCategory } from "../../store/actions/categoryAction";
import { PRODUCT_LIST_BY_SUBCATEGORY_RESET } from "../../store/constants/categoryConstants";
import Loader from "../../common/components/Loader/Loader";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import { handleFilterIcon } from "../../store/actions/filterActions";
import { GetProductBySubCat, GetProductBySubCatSuccess } from "../../store/actions/productDetailsAction";
import Layout from "../../common/components/Layouts/Layout";
import CardSkel from "../../common/components/Cards/CardSkel";
import { AiFillFilter, AiOutlineMenu } from "react-icons/ai";
import { Pagination } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import FilterModal from "../../common/components/FilterModal/FilterModal";
import { GetServicesWishlist } from "../../store/actions/servicesActions";
import { GetWishList } from "../../store/actions/whishlistActions";
const CategoryPageDropDown = () => {
  const dispatch = useDispatch();
  const [Pro, setPro] = useState([])
  const [sort, setSort] = useState("")
  const [view, setView] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const location = useLocation();
  const params = useParams();
  const filterValue = useSelector((state) => state.filterValue?.convertedFilterValues)
  useEffect(() => {
    if (location.state?.isSubCategory) {
      const pageName = `${params.subCategoryName.replace("-", " ")}`;
      let pageUrl;
      if (params.Categoryid) {
        pageUrl = `/categories/${params.categoryName}/${params.subCategoryName}/${params.categoryId}/${params.Categoryid}`;
      } else {
        pageUrl = `/categories/${params.categoryName}/${params.subCategoryName}/${params.categoryId}`;
      }// Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = `${params.subCategoryName.replace("-", " ")}`;
      let pageUrl;
      if (params.Categoryid) {
        pageUrl = `/categories/${params.categoryName}/${params.subCategoryName}/${params.categoryId}/${params.Categoryid}`;
      } else {
        pageUrl = `/categories/${params.categoryName}/${params.subCategoryName}/${params.categoryId}`;
      }
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, [params.subCategoryName]);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const categoryName = params.categoryName.split("-");
  const GetBySubCat = useSelector((state) => state.Subcat?.Subcat?.data?.products)
  const GetBySubCa1 = useSelector((state) => state.Subcat?.Subcat?.data)
  const pageSize = useSelector((state) => state.Subcat?.Subcat?.data?.pageSize)
  const totalProducts = useSelector((state) => state.Subcat?.Subcat?.data?.productCount)
  const totalPages = Math.ceil(totalProducts / pageSize);
  const columnSize = window.innerWidth < 768 ? 10 : 12;
  const handleSortChange = async (event) => {
    const selectedValue = event.target.value;
    setTest(filterValue)
    // Save the selected value in the state variable "setSort"
    if (selectedValue === 'A-Z') {
      setSort('az');
    } else if (selectedValue === 'Z-A') {
      setSort('za');
    } else if (selectedValue === 'Highest-to-lowest-price') {
      setSort('high');
    } else if (selectedValue === 'lowest-to-highest-price') {
      setSort('low');
    } else if (selectedValue === 'featured') {
      setSort('featured')
    } else if (selectedValue === 'default') {
      setSort("")
    }
  };
  const data = {
    "status": [
      "confirmed"
    ],
    "specialCriteria": [
      "products",
      "bids"
    ]
  }
  const [test, setTest] = useState([])
  useEffect(() => {
    if (params.categoryId) {
     
      if (first) {
        setTest(filterValue)
        dispatch(GetProductBySubCat({ pageSize: columnSize, page: 1, SubCatId: params.categoryId, rawData: test, sort: sort }))
          .then((data) => {
            dispatch(GetProductBySubCatSuccess(data));
            setPro(data.data?.products)
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        setTest(filterValue)
        const combined = {
          ...test,
          "status": [
            "confirmed"
          ],
          "specialCriteria": [
            "products",
            "bids"
          ]
        }
        dispatch(GetProductBySubCat({ pageSize: columnSize, page: 1, SubCatId: params.categoryId, rawData: combined, sort: sort }))
          .then((data) => {
            dispatch(GetProductBySubCatSuccess(data));
            setPro(data.data?.products)
          })
          .catch((error) => {
            // console.log(error);
          });
      }

    }
  }, [dispatch, params.categoryId, sort, first]);
  useEffect(() => {
    function handleResize() {
      setView(window.innerWidth < 445);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    if (filterValue) {
      setTest(filterValue)
      if (first) {
        dispatch(GetProductBySubCat({ pageSize: columnSize, page: pageNumber, SubCatId: params.categoryId, rawData: test, sort: sort }))
          .then((data) => {
            dispatch(GetProductBySubCatSuccess(data));
            setPro(data.data?.products)
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        const combined = {
          ...test,
          "status": [
            "confirmed"
          ],
          "specialCriteria": [
            "products",
            "bids"
          ]
        }
        dispatch(GetProductBySubCat({ pageSize: columnSize, page: pageNumber, SubCatId: params.categoryId, rawData: combined, sort: sort }))
          .then((data) => {
            dispatch(GetProductBySubCatSuccess(data));
            setPro(data.data?.products)
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    } else {
      if (first) {
        dispatch(GetProductBySubCat({ pageSize: columnSize, page: pageNumber, SubCatId: params.categoryId, sort: sort }))
          .then((data) => {
            dispatch(GetProductBySubCatSuccess(data));
            setPro(data.data?.products)
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        const combined = {
          "status": [
            "confirmed"
          ],
          "specialCriteria": [
            "products",
            "bids"
          ]
        }
        dispatch(GetProductBySubCat({ pageSize: columnSize, page: pageNumber, SubCatId: params.categoryId, sort: sort, rawData: combined }))
          .then((data) => {
            dispatch(GetProductBySubCatSuccess(data));
            setPro(data.data?.products)
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    }
  };
  const handleButtonClick = () => {
    const nextPage = activePage + 1;
    handlePageChange(nextPage);
  };
  const handleCategoryFilter = () => {
    setPro(GetBySubCat);
  };
  const ProductArray = Pro
  const [filterValues, setFilterValues] = useState({});
  const handleFilterInputChange = (event, key) => {
    const { value } = event.target;
    setFilterValues(prevValues => ({ ...prevValues, [key]: value }));
  }
  const handleFilterValuesUpdate = (newFilterValues) => {
    setFilterValues(newFilterValues);
  }
  const convertedFilterValues = Object.entries(filterValues).reduce((acc, [key, value]) => {
    let parsedValue = value;
    if (!isNaN(parseInt(value))) {
      parsedValue = parseInt(value);
    } else {
      try {
        parsedValue = JSON.parse(value);
      } catch {
        // If the value is not a valid JSON string, leave it as is
      }
    }
    return { ...acc, [key]: parsedValue };
  }, {});
  const { products, error } = Pro;
  const activateFirst = () => {
    setFirst(true);
    setSecond(false);
  };
  const activateSecond = () => {
    setFirst(false);
    setSecond(true);
  };
  const loading = useSelector((state) => state.Subcat?.loading)
  const [isOpen, setIsOpen] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const savedValue = useSelector((state) => state.value.value);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleFilter = () => {
    if (screenWidth > 900) {
      dispatch({ type: 'SAVE_VALUE', payload: !savedValue });
      setIsOpen(!savedValue)
    } else {
      dispatch({ type: 'SAVE_VALUE', payload: false });
      setIsFilterOpen(true)
    }
  };
  useEffect(() => {
    return () => {
      dispatch({ type: 'SAVE_VALUE', payload: false });
    };
  }, []);
  // Handle visibilitychange event
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        dispatch({ type: 'SAVE_VALUE', payload: false });
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  const ServicesWishlist = useSelector((state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList)
  const wishList = useSelector((state) => state.Wishlist?.Wishlist?.data?.wishList)
  useEffect(() => {
    dispatch(GetServicesWishlist())
    dispatch(GetWishList())
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>{params.subCategoryName}</title>
        <meta name={params.subCategoryName} content={params.subCategoryName} />
        <meta
          name="keywords"
          content={`${params.subCategoryName}, ${params.subCategoryName}, product, service, la7i27alak`}
        />
      </Helmet>
      {/* <Header /> */}
      <Layout footer={true} isFilterIcon={true} SubCategoryId={params.categoryId} onCategoryFilter={handleCategoryFilter} sort={sort} FeaturedProduct={first} FeaturedBid={second}>
        <section className={styles.category_section}>
          {/* <div className={styles.path}>
          <CurrentPath />
        </div> */}
          <div className={styles.search}>
            <Search subCategoryId={params.categoryId} first={true} categoryId={params.Categoryid} />
          </div>
          <div className={styles.title_container}>
            <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>
              {categoryName &&
                categoryName
                  .filter((c) => c !== "")
                  .map((c) => {
                    return c[0].toLocaleUpperCase() + c.substring(1);
                  })
                  .join(" ")}
            </h1>
            <div style={{ display: 'block' }}>
              <div className={`${styles.category__type} ${darkMode ? styles.category__typeDark : ""}`}>
                <button
                  className={`${styles.button} ${first ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
                  onClick={activateFirst}
                >
                  Product
                </button>
                <button
                  className={`${styles.button} ${second ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
                  onClick={activateSecond}
                >
                  Bid
                </button>
              </div>
            </div>
          </div>
          <div className={`${styles.result_container} ${darkMode ? styles.result_containerDark : ''}`}>
            <h1 className={`${styles.result_title} ${darkMode ? styles.result_titleDark : ''}`}>
              {totalProducts && totalProducts} Results Found
            </h1>
            <div className={styles.result_options}>
              <div onClick={handleFilter} className={styles.filter_container}>
                <AiFillFilter className={styles.filter_icon} />
                <button className={styles.filter_btn}>Filter</button>
              </div>
              <span>
                <CgSortAz className={`${styles.sort_icon} ${darkMode ? styles.sort_iconDark : ''}`} />{" "}
                <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`} htmlFor="sort">Sort:</label>
                <select className={`${styles.select} ${darkMode ? styles.selectDark : ''}`} name="sort" id="sort" onChange={handleSortChange}>
                  <option value="default" selected disabled={sort === "default"}>Sort</option>
                  <option value="featured">Featured</option>
                  <option value="A-Z">Alphabetically: A-Z</option>
                  <option value="Z-A">Alphabetically: Z-A</option>
                  <option value="Highest-to-lowest-price">Highest to Lowest Price</option>
                  <option value="lowest-to-highest-price">Lowest to Highest Price</option>
                </select>
              </span>
              <span className={`${styles.span} ${darkMode ? styles.spanDark : ''}`}
                onClick={() => setView(!view)}
              >
                View:
                {view ? (
                  <AiOutlineMenu className={styles.view_icon}
                  />
                ) : (
                  <MdGridView className={styles.view_icon} />
                )}
              </span>
            </div>
          </div>
          {loading ? (
            <div>
              <CardSkel />
            </div>
          ) : error ? (
            <div className="center">
              <ErrorMessage>{error}</ErrorMessage>
            </div>
          ) : (
            <div>
              {GetBySubCat ? (
                <div className={`grid gap-4
                ${view ? 'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3'
                    :
                    'grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4'}`}>
                  {GetBySubCat.map((p) => (
                    <Cards
                      id={p._id}
                      key={p._id}
                      title={p.title}
                      price={p.pricing.price}
                      old={p.condition}
                      time={p.createdAt}
                      mainimage={p.imageUrls[0]}
                      location={p && p.pickUpAddress?.region}
                      timer={p.timer?.enabled}
                      end={p.timer?.deadline}
                      featured={p.featured}
                      mobile={view}
                      isService={p.isService}
                      ServicesWishlist={ServicesWishlist}  // Pass ServicesWishlist as prop
                      wishList={wishList}
                      sold={p && p.status === "sold"}

                    />
                  ))}
                </div>
              ) : (
                <CardSkel />
              )}
            </div>
          )}
          {totalProducts > 0 && (
            <Pagination style={{ marginBottom: '0', paddingBottom: '20px' }}>
              {activePage === 1 ? null : (
                <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
              )}
              {totalPages === 1 ? (
                <Pagination.Item linkStyle={{ background: '#0298a6', border: 'none' }} active>{activePage}</Pagination.Item>
              ) : (
                <>
                  {activePage === 1 ? null : (
                    <Pagination.Item onClick={() => handlePageChange(activePage - 1)} active={activePage === 1}>
                      {activePage - 1}
                    </Pagination.Item>
                  )}
                  <Pagination.Item linkStyle={{ background: '#0298a6', border: 'none' }} active>{activePage}</Pagination.Item>
                  {activePage === totalPages ? null : (
                    <Pagination.Item onClick={() => handlePageChange(activePage + 1)} active={activePage === totalPages}>
                      {activePage === totalPages ? activePage : activePage + 1}
                    </Pagination.Item>
                  )}
                </>
              )}
              {activePage === totalPages ? null : (
                <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPages} />
              )}
            </Pagination>
          )}
        </section>
      </Layout>
      <FilterModal
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        SubCategoryId={params.categoryId} onCategoryFilter={handleCategoryFilter}
        sort={sort}
      />
    </>
  );
};
export default CategoryPageDropDown;
