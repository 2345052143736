import {
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAIL,
  CREATE_CUSTOMER_RESET,
  CREATE_CARD_REQUEST,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAIL,
  ADD_CARD_TO_DB_REQUEST,
  ADD_CARD_TO_DB_SUCCESS,
  ADD_CARD_TO_DB_FAIL,
  ADD_CARD_TO_CUSTOMER_REQUEST,
  ADD_CARD_TO_CUSTOMER_SUCCESS,
  ADD_CARD_TO_CUSTOMER_FAIL,
  CARDS_LIST_REQUEST,
  CARDS_LIST_SUCCESS,
  CARDS_LIST_FAIL,
  CARDS_LIST_RESET,
  CREATE_CARD_RESET,
  ADD_CARD_TO_DB_RESET,
  ADD_CARD_TO_CUSTOMER_RESET,
  DEFAULT_CARD_REQUEST,
  DEFAULT_CARD_SUCCESS,
  DEFAULT_CARD_FAIL,
  DEFAULT_CARD_RESET,
  UPDATE_DEFAULT_CARD_REQUEST,
  UPDATE_DEFAULT_CARD_SUCCESS,
  UPDATE_DEFAULT_CARD_FAIL,
  UPDATE_DEFAULT_CARD_RESET,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  GET_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  DEFAULT_CUSTOMER_SUCCESS,
  DEFAULT_CUSTOMER_FAIL,
} from "../constants/paymentConstants";
export const createCustomerReducer = (
  state = { loading: false, error: null, customer_id: null },
  action
) => {
  switch (action.type) {
    case CREATE_CUSTOMER_REQUEST:
      return { ...state, loading: true };
    case CREATE_CUSTOMER_SUCCESS:
      return { ...state, loading: false, customer_id: action.payload };
    case CREATE_CUSTOMER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_CUSTOMER_RESET:
      return { loading: false, error: null, customer_id: null };
    default:
      return state;
  }
};
const initialState = {
  getCards: [],
  error: null,
  loading: false,
};
  export const getCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_REQUEST:
      return { ...state, loading: true };
    case GET_CUSTOMER_SUCCESS:
      return { ...state, loading: false, getCards: action.payload };
    case GET_CUSTOMER_FAIL:
      return { ...state, loading: false, error: action.payload };
  
    default:
      return state;
  }
};
  export const DefaultCustomerReducer = (
    state = { loading: false, error: null, customer_id: null }, action) => {
  switch (action.type) {
    case  DEFAULT_CUSTOMER_SUCCESS:
      return { ...state, loading: false, getCards: action.payload };
    case  DEFAULT_CUSTOMER_FAIL:
      return { ...state, loading: false, error: action.payload };
  
    default:
      return state;
  }
};
const delinitialState = {
  delCards: [],
  error: null,
  loading: false,
};
  export const deleteCustomerReducer = (
    state = { loading: false, error: null, customer_id: null }, action) => {
  switch (action.type) {
    case DELETE_CUSTOMER_REQUEST:
      return { ...state, loading: true };
    case DELETE_CUSTOMER_SUCCESS:
      return { ...state, loading: false, delCards: action.payload };
    case DELETE_CUSTOMER_FAIL:
      return { ...state, loading: false, error: action.payload };
  
    default:
      return state;
  }
};
export const createCardReducer = (
  state = { loading: false, error: null, card_id: null },
  action
) => {
  switch (action.type) {
    case CREATE_CARD_REQUEST:
      return { ...state, loading: true };
    case CREATE_CARD_SUCCESS:
      return { ...state, loading: false, card_id: action.payload };
    case CREATE_CARD_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CREATE_CARD_RESET:
      return { loading: false, error: null, card_id: null };
    default:
      return state;
  }
};
export const createCardDBReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case ADD_CARD_TO_DB_REQUEST:
      return { ...state, loading: true };
    case ADD_CARD_TO_DB_SUCCESS:
      return { ...state, loading: false, success: true };
    case ADD_CARD_TO_DB_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ADD_CARD_TO_DB_RESET:
      return {
        loading: false,
        error: null,
        success: false,
      };
    default:
      return state;
  }
};
export const addCardToCustomerReducer = (
  state = { loading: false, error: null, card_id: null },
  action
) => {
  switch (action.type) {
    case ADD_CARD_TO_CUSTOMER_REQUEST:
      return { ...state, loading: true };
    case ADD_CARD_TO_CUSTOMER_SUCCESS:
      return { ...state, loading: false, card_id: action.payload };
    case ADD_CARD_TO_CUSTOMER_FAIL:
      return { ...state, loading: false, error: action.payload };
    case ADD_CARD_TO_CUSTOMER_RESET:
      return { loading: false, error: null, card_id: null };
    default:
      return state;
  }
};
export const getCustomerCardsReducer = (
  state = { loading: false, error: null, cards: [] },
  action
) => {
  switch (action.type) {
    case CARDS_LIST_REQUEST:
      return { ...state, loading: true };
    case CARDS_LIST_SUCCESS:
      return { ...state, loading: false, cards: action.payload };
    case CARDS_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CARDS_LIST_RESET:
      return { loading: false, error: null, cards: [] };
    default:
      return state;
  }
};
export const defaultCardReducer = (
  state = { loading: false, error: null, card_id: null },
  action
) => {
  switch (action.type) {
    case DEFAULT_CARD_REQUEST:
      return { ...state, loading: true };
    case DEFAULT_CARD_SUCCESS:
      return { ...state, loading: false, card_id: action.payload };
    case DEFAULT_CARD_FAIL:
      return { ...state, loading: false, error: action.payload };
    case DEFAULT_CARD_RESET:
      return { loading: false, error: null, card_id: null };
    default:
      return state;
  }
};
export const updateDefaultCardReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case UPDATE_DEFAULT_CARD_REQUEST:
      return { ...state, loading: true };
    case UPDATE_DEFAULT_CARD_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_DEFAULT_CARD_FAIL:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_DEFAULT_CARD_RESET:
      return { loading: false, error: null, success: false };
    default:
      return state;
  }
};
