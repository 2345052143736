import {
    GET_RECOMMENDED_PRODUCT_FAIL,
    GET_RECOMMENDED_PRODUCT_SUCCESS,
    GET_RECOMMENDED_PRODUCT_REQUEST,
} from "../constants/recommendedProductConstants";
import axios from "axios";
import { url } from "../../common/constants/url";
export const getRecommendedProduct = () => {
    return async (dispatch) => {
        try {
            const token = localStorage.getItem('token')
            
            const response = await axios.get(`${url}/recommendedProducts`, {
                headers: {
                    'authorization': `${token}`,
                },
            });
            
            dispatch(getRecommendedProductSuccess(response.data));
            return response.data
        } catch (error) {
            dispatch(getRecommendedProductFail(error));
            // console.log(error)
        }
    };
};
export const getRecommendedProductSuccess = (product) => {
    return {
        type: GET_RECOMMENDED_PRODUCT_SUCCESS,
        payload: product,
    };
};
export const getRecommendedProductFail = (error) => ({
    type: GET_RECOMMENDED_PRODUCT_FAIL,
    payload: error
});
