import {
    GET_SERVICE_COUNT_SUCCESS,
    GET_SERVICE_COUNT_FAIL
} from "../constants/adminDashboardConstants";
const initialState = {
    ServiceCount: [],
    error: null,
    loading: false,
};
export const GetServiceCount = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_COUNT_SUCCESS:
            return {
                ...state,
                ServiceCount: action.payload,
                error: null,
                loading: false,
            };
        case GET_SERVICE_COUNT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
