import React, { useState, useEffect } from "react";
import styles from "./StatusBar.module.scss";
import { BsPersonCircle, BsPlusLg } from "react-icons/bs";
import { BiMessageRounded, BiHeart } from "react-icons/bi";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import { TbWorld, TbMenu2 } from "react-icons/tb";
import NotiDropDown from "../notification dropdown/NotiDropDown";
import ProfileDropDown from "../profile dropdown/ProfileDropDown";
import Modal from "../../Modal/Modal";
import { useNavigate } from "react-router-dom";
import SellModal from "../../SellModal/SellModal";
import TestModal from "./test/TestModal";
import { USER_RESET } from "../../../../store/constants/userConstants";
import { useDispatch } from "react-redux";
import LoginModal from "../../Modal/loginModal/LoginModal";
import { logout } from "../../../../store/actions/authActions";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { CheckUnreadNotification } from "../../../../store/actions/NotificationAction";
import {
  MdOutlineDarkMode,
} from "react-icons/md";
import { GetCurrentLocation } from "../../../../store/actions/userActions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { onValue, ref } from 'firebase/database';
import { auth, db } from "../../../../firebase";
import LoginWithCodeModal from "../../Modal/Login with code/LoginWithCodeModal";
import logo from '../../../../assests/svg/La7i2_7alak1.1.svg'
import axios from "axios";
const StatusBar = ({ toggleSideDrawer, user }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userL } = useSelector((state) => state.userLogin);
  const [location, setLocation] = useState('')
  const [loggedInUser, setLoggedInUser] = useState(userL ? true : false);
  const uid = localStorage.getItem('uid')
  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [sell, setSell] = useState(false);
  const [service, setService] = useState(false);
  const [verified, setVerified] = useState(false);
  const [emailverified, setEmailVerified] = useState(true);
  const checkNotification = useSelector((state) => state.checkNotification?.checkNotification)
  const [teset, setTeset] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const handleToggleDarkMode = () => {
    dispatch({ type: 'TOGGLE_DARK_MODE' });
  };
  useEffect(() => {
    const response = dispatch(CheckUnreadNotification())
  }, [])
  const openSell = () => {
    if (loggedInUser) {
      const user = auth.currentUser;
      const providerData = user.providerData;
      if (user && user.providerData) {
        // Check if the providerData array contains the PhoneAuthCredential object
        const hasLinkedPhoneNumber = providerData.some(
          (provider) => provider.providerId === 'phone',
        );
        if (hasLinkedPhoneNumber) {
          setSell(true)
        } else {
          toast.error('Verify your number first, a verfication code will be sent to you.');
          setVerified(true)
        }
      } else {
        // console.log("User is not signed in or providerData is unavailable.");
      }
    } else {
      toast.error('Please login first.');
      setIsOpen(true);
    }
  };
  //unread
  const [unreadCount, setUnreadCount] = useState(0);
  useEffect(() => {
    const chatListRef = ref(db, 'chatlist');
    const unsubscribe = onValue(chatListRef, (snapshot) => {
      const chatListData = snapshot.val();
      let totalUnreadCount = 0;
      if (chatListData) {
        Object.values(chatListData).forEach(user => {
          Object.values(user).forEach(chat => {
            totalUnreadCount += chat.unread || 0;
          });
        });
      }
      setUnreadCount(totalUnreadCount);
    });
    return () => {
      unsubscribe();
    };

  }, []);


  const loc = useSelector((state) => state.loc?.loc)

  useEffect(() => {
    if (latitude === null && longitude === null) {
      const fetchCoordinates = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              const data = { lat: latitude, long: longitude };
              setLatitude(latitude);
              setLongitude(longitude);
              dispatch(GetCurrentLocation(data))
                .then(response => {
                  setLocation(response.data.currentLocation)
                })
                .catch(error => {
                  // console.log(error);
                });
            },
            error => {
              // console.log(error.message);
            }
          );
        } else {
          // console.log('Geolocation is not supported by this browser.');
        }
      }
      fetchCoordinates();
    } else {
      const data = { lat: latitude, long: longitude };
      dispatch(GetCurrentLocation(data))
        .then(response => {
          setLocation(response.data.currentLocation)
        })
        .catch(error => {
          // console.log(error);
        });
    }

  }, []);

  const [isnotificationUnread, setIsNotificationUnread] = useState(checkNotification && checkNotification.hasUnread)
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const token = localStorage.getItem("token");

  // Function to check if the screen is less than 900px
  const checkIsSmallScreen = () => {
    setIsSmallScreen(window.innerWidth <= 900);
  };
  useEffect(() => {
    // Add event listener to detect screen width changes
    window.addEventListener('resize', checkIsSmallScreen);
    // Initial check for screen width when the component mounts
    checkIsSmallScreen();
    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkIsSmallScreen);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`https://backend.la7i27alak.com/user/${uid}`, {
        headers: {
          authorization: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {

        setEmailVerified(response.data.user?.isVerified)

      })
      .catch((error) => console.error(error))
  }, [uid, token]);
  return (
    <>
      <SellModal
        open={sell}
        onClose={() => {
          setSell(false);
          setService(false);
        }}
        service={service}
        setService={() => setService(true)}
      />
      <TestModal
        open={teset}
        onClose={() => {
          setTeset(false);
        }}
      />
      <LoginWithCodeModal
        open={verified}
        onClose={() => setVerified(false)}
      />
      <Modal
        open={isOpen}
        closeHandler={() => setIsOpen(false)}
      />
      <div className={darkMode ? 'darkMode' : ''}>
        <div className={`${styles.container} ${darkMode ? styles.containerDark : ''}`}>
          <div className={styles.wrapper}>
            <div className={styles.logo__container}>
              <div className={styles.logo}>
                <TbMenu2
                  className={`${styles.menu_icon} ${darkMode ? styles.menu_iconDark : ''}`}
                  onClick={() => toggleSideDrawer()}
                />{" "}
                <img className={styles.logo_img} src={logo} alt='logo' />
                <div className={styles.clickableDiv} onClick={() => navigate("/")}></div>
              </div>
              <div className={styles.location_container}>
                <GoLocation className={styles.location_icon} />
                {loc && loc.data?.currentLocation}
              </div>
            </div>
            {/* )} */}
            <div className={styles.right__container}>
              {/* <div className={styles.language_container}>
                <TbWorld
                  className={`${styles.language_icon} ${darkMode ? styles.language_iconDark : ''}`}
                />
                <select
                  className={`${styles.language_select} ${darkMode ? styles.language_selectDark : ''}`}
                  name="location"
                  id="location"
                >
                  <option value="lebanon">EN</option>
                </select>
              </div> */}

              {uid && emailverified ? (
                <>
                  {isnotificationUnread ?
                    (
                      <div className={styles.unr}>
                        <NotiDropDown>
                          <MdOutlineNotificationsNone
                            className={`${styles.iconU} ${styles.hide} ${darkMode ? styles.iconUDark : ''}`}
                          />
                          <div className={`${styles.dot} ${styles.hide}`}></div>
                        </NotiDropDown>
                      </div>
                    ) : (
                      <NotiDropDown>
                        <MdOutlineNotificationsNone
                          className={`${styles.icon} ${styles.hide} ${darkMode ? styles.iconDark : ''}`}
                        />
                      </NotiDropDown>
                    )}
                  {/* {unreadCount > 0 ? (
                    <>
                    <div className={styles.unr}>
      <BiMessageRounded
        className={`${styles.iconU} ${styles.hide} ${darkMode ? styles.iconUDark : ''}`}
        onClick={() => navigate("/chat")}
      />
      <div className={`${styles.dot} ${styles.hide}`}></div>
      </div>
      </>
    ) : (
      <BiMessageRounded
        className={`${styles.icon} ${styles.hide} ${darkMode ? styles.iconDark : ''}`}
        onClick={() => navigate("/chat")}
      />
    )} */}
                  <BiMessageRounded
                    className={`${styles.icon} ${styles.hide} ${darkMode ? styles.iconDark : ''}`}
                    onClick={() => navigate("/chat", { state: { "isProductDetails": false } })}
                  />
                  <BiHeart
                    className={`${styles.icon} ${styles.hide} ${darkMode ? styles.iconDark : ''}`}
                    onClick={() => navigate("/wishlist")}
                  />
                  <ProfileDropDown>
                    <BsPersonCircle
                      className={`${styles.icon} ${styles.hide} ${darkMode ? styles.iconDark : ''}`}
                    />
                  </ProfileDropDown>
                </>
              ) : (
                <>
                  <div className={`${styles.DarkM} ${darkMode ? styles.DarkMDark : ''}`}>
                    <MdOutlineDarkMode onClick={handleToggleDarkMode} />
                  </div>
                  <button
                    className={`${styles.login} ${darkMode ? styles.loginDark : ''}`}
                    onClick={() => {
                      dispatch({ type: USER_RESET });
                      setIsOpen(true);
                    }}
                  >
                    Login
                  </button>
                </>
              )}
              <button className={`${styles.sell} ${darkMode ? styles.sellDark : ''}`}
                // onClick={() => setSell(true)}
                onClick={openSell}
              >
                Sell <BsPlusLg className={styles.plus} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StatusBar;
// {unreadCount > 0 && <div className="blue-dot"></div>}
