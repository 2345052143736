import React, { useEffect } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./index.module.scss";
import Card from "./card/Card";
import Footer from "../../common/components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { url } from "../../common/constants/url";
import SearchComponent from "../../common/components/Search/Search";
import { Helmet } from "react-helmet";
import Search from "../../common/components/Search/Search";
import { GetAllCategories } from "../../store/actions/categoryAction";
import { useLocation } from "react-router-dom";
const CategoriesPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.state?.isCategory) {
      const pageName = "Categories"; // Replace this with the actual page name
      const pageUrl = "/categories"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Categories"; // Replace this with the actual page name
      const pageUrl = "/categories"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, []);
  const categories = useSelector((state) => state.categories?.categories?.data?.categories);
  const loading = useSelector((state) => state.categories.loading);
  const error = useSelector((state) => state.categories.error);
  const filterBar = useSelector((state) => state.filterBar.isFilterBarOn);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(GetAllCategories());
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, [dispatch]);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (categories.length === 0) {
    return <div>No categories found</div>;
  }
  return (
    <>
      <Helmet>
        <title>Categories</title>
        <meta name="Categories" content="This is Categories Page" />
        <meta
          name="keywords"
          content={`La7i27alak, categories, products, services`}
        />
      </Helmet>
      <Layout footer={false} isFilterIcon={false} >
        <div
          className={`${styles.categories_container} ${darkMode ? styles.categories_containerDark : ''} ${filterBar ? styles.categories_containerBar : ''}`}
        >
          {categories.map((category) => (
            <Card
              key={category._id}
              title={category.title}
              image={category.iconUrl}
              categoryId={category._id}
              subCategory={category.subCategories}
            />
          ))}
        </div>
      </Layout>
      <Footer />
    </>
  );
};
export default CategoriesPage;
