import React from "react";
import styles from "../Request/RequestDone.module.scss"
import { ReactComponent as ComingSure } from "../../../assests/svg/coming sure.svg";
import { ReactComponent as Coming } from "../../../assests/svg/coming.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
    AcceptOffer,
    RejectOffer
} from "../../../store/actions/bidActions";
const RequestSold = ({ offerId, sure, image, username, userId, productId, time }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = { "productId": productId, "offerId": offerId }
   
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    return (
        <li className={styles.item}>
            <div className={styles.line}></div>
            <img src={image} alt="profile" className={styles.image} />
            <div className={styles.info_container}>
                <h1 className={`${styles.name} ${darkMode?styles.nameDark:''}`}>
                    <div className={styles.username_container}>
                        <span
                            onClick={() =>
                                navigate(`/user-review/${username.replace(" ","-")}/${userId}`, {
                                    state: {
                                        id: userId,
                                    },
                                })
                            }
                        >
                            {username}
                        </span>
                        
                    </div>
                </h1>
                {sure ? (
                    <p
                        className={`${styles.text} ${sure ? styles.sure : styles.notsure}`}
                    >
                        <ComingSure className={styles.icon} />I am coming now For Sure
                    </p>
                ) : (
                    <p
                        className={`${styles.text} ${sure ? styles.sure : styles.notsure}`}
                    >
                        <Coming className={styles.icon} />I am coming now
                    </p>
                )}
                <div className={styles.state_container}>
                        <p style={{color:'#ba2c2d'}} className={styles.state}>Sold</p>
                        </div>
            </div>
            <div className={styles.stats_container}>
                <p className={`${styles.time} ${darkMode?styles.timeDark:''}`}>  {time === "now" ? 'now' : `${time} ago`}</p>
            </div>
        </li>
    );
};
export default RequestSold;
