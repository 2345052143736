import React, { useState, useEffect } from "react";
import Layout from "../../common/components/Layouts/Layout";
import EmptyAddresses from "./EmptyAddresses/EmptyAddresses";
import AddressesList from "./AddressesList/AddressesList";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
const AddressPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.isAddress) {
      const pageName = "Address"; // Replace this with the actual page name
      const pageUrl = "/user/addresses"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Address"; // Replace this with the actual page name
      const pageUrl = "/user/addresses"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, []);
  const [empty, setEmpty] = useState(false);
  let component = <EmptyAddresses />;
  if (!empty) {
    component = <AddressesList />;
  }
  return <Layout title={"Addresses"}>{component}</Layout>;
};
export default AddressPage;
