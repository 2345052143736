import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  userLoginReducer,
  userRegisterReducer,
  emailVerificationReducer,
  activateUserReducer,
  forgetPasswordReducer,
  googleLoginReducer,
  facebookLoginReducer,
  updatePasswordReducer,
  firebaseRegisterReducer,
  admLoginReducer,
} from "./reducers/authReducers";
import { RateFinalExperience } from "./reducers/rateExperienceReducer";
import { GetEndedBids } from "./reducers/GetEndedBids";
import { autocompleteCOD } from "./reducers/autocompleteCOD";
import { GetPayment } from "./reducers/GetPayment";
import { GetProductPerUserId } from "./reducers/GetProductPerUserId";
import { GetLoc } from "./reducers/locationReducer";
import filterValue from "./reducers/FilterValue";
import { GetOnlineUsers } from "./reducers/GetOnlineUsers";
import { GetAllContactUs } from "./reducers/GetAllContactUs";
import { GetPaymentAmount } from "./reducers/GetPaymentAmounts";
import { GetPaymentHistory } from "./reducers/GetPaymentHistory";
import { GetSubById } from "./reducers/GetSubById";
import { GetServiceByType } from "./reducers/GetServicesByType";
import { GetLastSeen } from "./reducers/LastSeen";
import { GetRangeForAdmin } from "./reducers/GetRangeForAdmin";
import { GetRelatedService } from "./reducers/GetRelatedService";
import { GetTypeById } from "./reducers/GetTypeById";
import { CheckNotifications } from "./reducers/checkNotifications";
import { SearchAllServiceReducer } from "./constants/searchAllServices";
import { GetProductPerUserr } from "./reducers/GetMyProductsReducer";
import { SearchService } from "./reducers/SearchServiceReducer";
import { GetUserByIdReducer } from "./reducers/GetUserById";
import breadcrumbReducer from "./reducers/breadcrumb";
import { GetNotification } from "./reducers/GetNotifications";
import { GetServicesPending } from "./reducers/GetPendingServices";
import { GetServicesConfirmed } from "./reducers/GetConfirmedServices";
import { RemoveProductFromWishlist } from "./reducers/RemoveProductFromWishListReducer";
import { GetLiveBids } from "./reducers/GetLiveBids";
import { CheckIfBlocked } from "./reducers/CheckIfBlocked";
import { GetAverageDealResult } from "./reducers/GetAverageDealResults";
import { GetTopSellingUser } from "./reducers/GetTopSellingUserReducer";
import { GetTotalSales } from "./reducers/GetTotalSales";
import { GetBlockedUsers } from "./reducers/GetBlockedUsers";
import { GetMyWaitingList } from "./reducers/GetMyWaitingList";
import { GetBoughtHistory } from "./reducers/GetBoughtHistoryReducer";
import { confirmedServicesByTypeReducer } from "./reducers/GetAllServicesByTypeReducer";
import { RateExperienceReducer } from "./reducers/RateExperience";
import { GetOfferByDate } from "./reducers/GetOfferByDateReducer";
import { GetOfferedServicesReducer } from "./reducers/GetAllOfferedServicesReducer";
import { GetFavCategory } from "./reducers/GetFavCategoryReducer";
import { GetWaintingList } from "./reducers/GetWaitingListReducer";
import { GetAllowedBiddersReducer } from "./reducers/GetAllowedBidders";
import { GetProductPerUser } from "./reducers/GetProductPerUserReducer";
import { GetReviewByUID } from "./reducers/GetReviewByUIDReducer";
import { CheckUserInWaitingList } from "./reducers/CheckIfUserInWaitingListReducer";
import { CheckUserInBid } from "./reducers/CheckUserInBid";
import { GetAllBids } from "./reducers/GetAllBidsReducers";
import { GetCOD } from "./reducers/GetCodReducer";
import { GetAverageReview } from "./reducers/GetAverageReviewReducer";
import { GetTotalBids } from "./reducers/GetTotalBidsReducer";
import { GetServicesWishlist } from "./reducers/GetServicesWishlistReducer";
import { GetCarousel } from "./reducers/GetCarouselReducer";
import { GetWishlist } from "./reducers/GetWishlistReducer";
import { GetMyServices } from "./reducers/GetMyServicesReducer";
import { GetRelatedProduct } from "./reducers/GetRelatedProductReducer";
import { GetProductDifference } from "./reducers/GetProductDifferenceReducer";
import { GetServiceDifference } from "./reducers/GetServiceDifferenceReducer";
import { GetProductCount } from "./reducers/GetProductCountReducer";
import { GetServiceCount } from "./reducers/GetServiceCountReducer";
import { GetProductCountByDate } from "./reducers/GetProductCountByDateReducer";
import { GetServicesCountByDate } from "./reducers/GetServicesCountByDate";
import { GetProductPending } from "./reducers/GetPendingProductsReducer";
import { GetProductConfirmed } from "./reducers/GetConfirmedProductsReducer";
import { GetProductSold } from "./reducers/GetSoldProductReducer";
import { GetProductByMonthReducer } from "./reducers/GetProductByMonthReducer";
import { GetUsers } from "./reducers/GetUsersReducer";
import { GetAllUsers } from "./reducers/GetAllUsersReducer";
import { GetMerchant } from "./reducers/GetMerchantsReducer";
import { GetAdmin } from "./reducers/GetAdminReducer";
import { GetFAQ } from "./reducers/GetFAQReducer";
import { confirmedServicesReducer } from "./reducers/GetAllConfirmedServicesServices";
import { SearchProductReducer } from "./reducers/searchProductReducer";
import { SubCatReducer } from "./reducers/GetProductBySubCatReducers";
import { CreateAddressReducer } from "./reducers/AddAddressReducer";
import { GetAddressreducer } from "./reducers/getAddressByUserReducer";
import { GetConfirmedProduct } from "./reducers/GetAllConfirmedProductReducers";
import { SearchComponentReducer } from "./reducers/SearchComponentReducers";
import { CatReducer } from "./reducers/GetProductByCatReducers";
import { GetAllTypes } from "./reducers/GetAllTypesReducer";
import {
  whishListReducer,
  removeFromWishlistReducer,
  whishListIDReducer,
  addToWishlistReducer,
} from "./reducers/wishlistReducer";
import { DefaultAddressReducer } from "./reducers/makeDefaultAddressReducer";
import {
  userByIdReducer,
  profilePictureReducer,
  updateUserReducer,
  getusercardratesReducer,
  changePasswordReducer,
  userReducer,
  AddTokensReducer,
} from "./reducers/userReducers";
import { featuredProductReducer } from "./reducers/featuredProductReducers";
import {
  categSkelReducer,
  allcategSkelReducer,
} from "./reducers/categSkelReducers";
import { orderHistoryReducer } from "./reducers/orderReducer";
import {
  categoriesListReducer,
  productsListBySubCatReducer,
} from "./reducers/categoryReducers";
import { GetReportsReducer } from "./reducers/GetAllReports";
import {
  addFavoriteReducer,
  getCategoriesReducer,
} from "./reducers/favoriteReducers";
import {
  createCustomerReducer,
  createCardReducer,
  createCardDBReducer,
  addCardToCustomerReducer,
  getCustomerCardsReducer,
  defaultCardReducer,
  updateDefaultCardReducer,
  getCustomerReducer,
  deleteCustomerReducer,
  DefaultCustomerReducer,
} from "./reducers/paymentReducers";
import wishlistReducer from "./reducers/wishlistReducer";
import {
  companyIndustryListReducer,
  companySizeListReducer,
} from "./reducers/companyReducers";
import { getProductDetailsReducer } from "./reducers/productDetailsReducers";
import {
  joinBidReducer,
  checkUserPaymentBidReducer,
} from "./reducers/makePaymentReducers";
import { filterBarReducer, filterIconReducer } from "./reducers/filterReducers";
import {
  placeBidReducer,
  getBidersReducer,
  SendOfferForProductReducer,
} from "./reducers/bidReducers";
import { recommendedProductReducer } from "./reducers/recommendedProductReducer";
import {
  addproductReducer,
  uploadMainImageReducer,
  uploadImagesReducer,
  //
  vehiclebrandReducer,
  brandmoduleReducer,
  bodytypesReducer,
  CreateProductReducer,
} from "./reducers/addProductReducers";
import {
  addToWaitingListReducer,
  paymentComingForSureReducer,
  removeFromWaitingListReducer,
  getWaitingListReducer,
  checkUserInWaitingListReducer,
  acceptWaitingListReducer,
  ifanyuseracceptedReducer,
  cancelWaitingListReducer,
  GetMyWaitingListReducer,
} from "./reducers/waitingListReducers";
import {
  getGeolocationReducer,
  updateGeolocationReducer,
} from "./reducers/locationReducers";
import { GetIndustrySize } from "./reducers/GetIndustrySize";
import { GetIndustryType } from "./reducers/GetIndustryType";
import {
  addServiceReducer,
  uploadImagesServiceReducer,
  uploadMainImageServiceReducer,
} from "./reducers/addServiceReducers";
import { categoriesReducer } from "./reducers/categoryReducers";
import { subcategoriesReducer } from "./reducers/SubCategoryReducers";
import { featuredListReducer } from "./reducers/featuredReducers";
import { ProductReducer } from "./reducers/productDetailsReducers";
import { PreviewReducer } from "./reducers/PreviewReducer";
import {
  EditProductsReducer,
  UserProductReducer,
} from "./reducers/myProductsReducers";
import { userInfoReducer } from "./reducers/userReducers";
import darkModeReducer from "./reducers/darkModeReducer";
import { CreateServiceReducer } from "./reducers/servicesReducer";
import { SendOfferForProduct } from "./actions/bidActions";
import filterReducer from "./reducers/filter";
import { ShareLinkReducer } from "./reducers/shareLinkReducer";
const persistConfig = {
  key: "root",
  storage,
};
const userPersistConfig = {
  key: "userInfo",
  storage,
};
const admPersistConfig = {
  key: "admInfo",
  storage,
};
const tokenPersistConfig = {
  key: "fcmtoken",
  storage,
};
const RootReducer = combineReducers({
  // auth
  userLogin: persistReducer(userPersistConfig, userLoginReducer),
  admLogin: persistReducer(admPersistConfig, admLoginReducer),
  tokenReducer: persistReducer(tokenPersistConfig, AddTokensReducer),
  userRegister: userRegisterReducer,
  emailVerification: emailVerificationReducer,
  activateUser: activateUserReducer,
  forgetPassword: forgetPasswordReducer,
  googleLogin: googleLoginReducer,
  facebookLogin: facebookLoginReducer,
  updatePassword: updatePasswordReducer,
  // company
  companyIndustryList: companyIndustryListReducer,
  companySizeList: companySizeListReducer,
  // whishlist
  whishList: whishListReducer,
  breadcrumbs: breadcrumbReducer,
  isUserBlocked: CheckIfBlocked,
  removeFromWishList: removeFromWishlistReducer,
  whishListID: whishListIDReducer,
  addToWishlist: addToWishlistReducer,
  whishlistArray: wishlistReducer,
  // user
  userById: userByIdReducer,
  lastSeen: GetLastSeen,
  profilePicture: profilePictureReducer,
  updateUser: updateUserReducer,
  getusercardrates: getusercardratesReducer,
  changePassword: changePasswordReducer,
  user: userReducer, // when you visit a user
  // my products
  liveBids: GetLiveBids,
  myProducts: UserProductReducer,
  searchAllService: SearchAllServiceReducer,
  cod: GetCOD,
  AverageReview: GetAverageReview,
  SearchService: SearchService,
  notification: GetNotification,
  products: ProductReducer,
  featuredproducts: featuredProductReducer,
  cat: categSkelReducer,
  rate: RateExperienceReducer,
  allcat: allcategSkelReducer,
  finalRate: RateFinalExperience,
  Subcat: SubCatReducer,
  confirmedServices: confirmedServicesReducer,
  confirmedServicesByType: confirmedServicesByTypeReducer,
  OfferedServices: GetOfferedServicesReducer,
  address: GetAddressreducer,
  CreatedAddress: CreateAddressReducer,
  SubById: GetSubById,
  typeById: GetTypeById,
  // products of a visited a user
  //order
  orderHistory: orderHistoryReducer,
  //categories
  categoriesList: categoriesListReducer,
  SearchComponent: SearchComponentReducer,
  productsListBySubCa: productsListBySubCatReducer,
  categories: categoriesReducer,
  checkNotification: CheckNotifications,
  IndustrySize: GetIndustrySize,
  rangeAdmin: GetRangeForAdmin,
  IndustryType: GetIndustryType,
  loc: GetLoc,
  history: GetPaymentHistory,
  types: GetAllTypes,
  ProductPerUser: GetProductPerUserr,
  faq: GetFAQ,
  ProductPerUser: GetProductPerUser,
  waitingList: GetWaintingList,
  reviewsByUID: GetReviewByUID,
  TotalBids: GetTotalBids,
  ServicesWishlist: GetServicesWishlist,
  blockedUsers: GetBlockedUsers,
  Wishlist: GetWishlist,
  carousel: GetCarousel,
  ProductDifference: GetProductDifference,
  ServiceDifference: GetServiceDifference,
  ProductCount: GetProductCount,
  ServiceCount: GetServiceCount,
  getUserById: GetUserByIdReducer,
  getPayment: GetPayment,
  ProductCountByDate: GetProductCountByDate,
  ServicesCountByDate: GetServicesCountByDate,
  PendingProduct: GetProductPending,
  ConfirmedProductArray: GetProductConfirmed,
  RelatedProducts: GetRelatedProduct,
  ProductSold: GetProductSold,
  userAdmin: GetUsers,
  AllUser: GetAllUsers,
  OfferByDate: GetOfferByDate,
  merchant: GetMerchant,
  filterValue: filterValue,
  admin: GetAdmin,
  ProductByMonth: GetProductByMonthReducer,
  subCategories: subcategoriesReducer,
  GetByCat: CatReducer,
  productPerUserId: GetProductPerUserId,
  //favorite categories
  getCategories: getCategoriesReducer,
  addFavorite: addFavoriteReducer,
  ProductArray: PreviewReducer,
  userId: userInfoReducer,
  DefaultAddress: DefaultAddressReducer,
  endedBids: GetEndedBids,
  // payment
  createCustomer: createCustomerReducer,
  getCustomer: getCustomerReducer,
  delCustomer: deleteCustomerReducer,
  defCustomer: DefaultCustomerReducer,
  createCard: createCardReducer,
  createCardDB: createCardDBReducer,
  contactUs: GetAllContactUs,
  addCardToCustomer: addCardToCustomerReducer,
  getCustomerCards: getCustomerCardsReducer,
  defaultCard: defaultCardReducer,
  allowedBidders: GetAllowedBiddersReducer,
  updateDefaultCard: updateDefaultCardReducer,
  ConfirmedProduct: GetConfirmedProduct,
  PendingService: GetServicesPending,
  amount: GetPaymentAmount,
  autocompleteCOD: autocompleteCOD,
  // make payment product type bid
  acceptpmt: SendOfferForProductReducer,
  joinBid: joinBidReducer,
  checkUserPaymentBid: checkUserPaymentBidReducer, //check if user paid fees for bid
  //product details
  getProductDetails: getProductDetailsReducer,
  inBid: CheckUserInBid,
  serviceByType: GetServiceByType,
  topSellingUser: GetTopSellingUser,
  checkInWaitingList: CheckUserInWaitingList,
  ConfirmedServicesArray: GetServicesConfirmed,
  favCategory: GetFavCategory,
  value: filterReducer,
  Searchproducts: SearchProductReducer,
  recommendedProducts: recommendedProductReducer,
  // bids
  placeBid: placeBidReducer,
  getBiders: getBidersReducer,
  BoughtHistory: GetBoughtHistory,
  RelatedServices: GetRelatedService,
  // filter bar
  filterBar: filterBarReducer,
  filterIcon: filterIconReducer,
  // add product
  TotalSales: GetTotalSales,
  addproduct: addproductReducer,
  CreateProduct: CreateProductReducer,
  uploadMainImage: uploadMainImageReducer,
  uploadImages: uploadImagesReducer,
  //
  edtProducts: EditProductsReducer,
  online: GetOnlineUsers,
  vehiclebrand: vehiclebrandReducer,
  Allbids: GetAllBids,
  brandmodule: brandmoduleReducer,
  bodytypes: bodytypesReducer,
  averageResults: GetAverageDealResult,
  // product not bid
  MyServices: GetMyServices,
  addToWaitingList: addToWaitingListReducer,
  paymentComingForSure: paymentComingForSureReducer, // for sure fees
  removeFromWaitingList: removeFromWaitingListReducer,
  getWaitingList: getWaitingListReducer,
  checkUserInWaitingList: checkUserInWaitingListReducer,
  myWaitingList: GetMyWaitingList,
  acceptWaitingList: acceptWaitingListReducer,
  ifanyuseraccepted: ifanyuseracceptedReducer,
  cancelWaitingList: cancelWaitingListReducer,
  // location
  getGeolocation: getGeolocationReducer,
  updateGeolocation: updateGeolocationReducer,
  // chat
  firebaseRegister: firebaseRegisterReducer,
  GetReports: GetReportsReducer,
  // add service
  addService: addServiceReducer,
  CreateService: CreateServiceReducer,
  uploadImagesService: uploadImagesServiceReducer,
  uploadMainImageService: uploadMainImageServiceReducer,
  // featured products
  featuredList: featuredListReducer,
  removeWishlist: RemoveProductFromWishlist,
  //darkmode
  darkMode: darkModeReducer,
  //sharelink
  getLink: ShareLinkReducer,
  //mywaitlist
  myWaitList: GetMyWaitingListReducer,
});
export default persistReducer(persistConfig, RootReducer);
