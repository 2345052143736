const initialState = {
    darkMode: false
  }
  
  function darkModeReducer(state = initialState, action) {
    switch (action.type) {
      case "TOGGLE_DARK_MODE":
        return {
          ...state,
          darkMode: !state.darkMode
        };
      default:
        return state;
    }
  }
  
export default darkModeReducer;
