import { GET_LAST_SEEN_SUCCESS,GET_LAST_SEEN_FAIL } from "../constants/waitingListConstants";

const initialState = {
    lastSeen: [],
    error: null,
    loading: false,
};
export const GetLastSeen = (state = initialState, action) => {
    switch (action.type) {
        case GET_LAST_SEEN_SUCCESS:
            return {
                ...state,
                lastSeen: action.payload,
                error: null,
                loading: false,
            };
        case GET_LAST_SEEN_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
