import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import { useParams, useLocation } from "react-router-dom";
import styles from "./NavBar.module.scss";
import { MdOutlineExpandMore, MdMenu } from "react-icons/md";
import CategoriesDropDown from "../all categories dropdown/CategoriesDropDown";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { handleFilterBar } from "../../../../store/actions/filterActions";
import { MdOutlineFilterList, MdAdd, MdRemove } from "react-icons/md";
import { GetAllCategories, GetSingleCategory } from "../../../../store/actions/categoryAction";
import { Scrollbars } from 'react-custom-scrollbars';
import { NumberFormatBase as NumberFormat } from 'react-number-format';
import { GetProductByCat, SearchAllService, SearchProduct, SearchProductSuccess } from "../../../../store/actions/productDetailsAction";
import { GetProductByCatSuccess } from "../../../../store/actions/productDetailsAction";
import { GetProductBySubCat } from "../../../../store/actions/productDetailsAction";
import { GetProductBySubCatSuccess } from "../../../../store/actions/productDetailsAction";
import ServicesDropDown from "../Services Dropdown/servicesDropdown";
import axios from "axios";
import { GetAllBids } from "../../../../store/actions/productDetailsAction";
import { GetAllBidsSuccess } from "../../../../store/actions/productDetailsAction";
import {
  GetAllFeaturedProduct,
  GetAllFeaturedProductSuccess
} from "../../../../store/actions/productDetailsAction";
import { GetAllServicesByType } from "../../../../store/actions/servicesActions";
import { GetServicesByType, GetServicesByTypeSuccess } from "../../../../store/actions/productDetailsAction";
import { GetTypeById } from "../../../../store/actions/servicesActions";
import { animateScroll as scroll } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';

const NavBar = ({ active, isFilterIcon, SubCategoryId, CategoryId, onCategoryFilter, bids, featured, offered, requested, service, typeId, allProducts, sort, allServices, text, offer, searchCatId, searchSubId, type, searchBid, FeaturedProduct, FeaturedBid, search }) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const Location = useLocation();
  const [activePage, setActivePage] = useState(1);
  const [mergedObject, setMergedObject] = useState(null);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const filterBar = useSelector((state) => state.filterBar.isFilterBarOn);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [singleCategory, setSingleCategory] = useState([]);
  const [activeValue, setActiveValue] = useState(null);
  const [activeValue1, setActiveValue1] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [location, setLocation] = useState({});
  const [formData, setFormData] = useState([]);
  const savedValue = useSelector((state) => state.value.value);
  const columnSize = window.innerWidth < 768 ? 10 : 12;
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        error => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);
  const formattedLocationObj = {
    currentUserCoordinates: {
      lat: location.latitude,
      long: location.longitude
    }
  };
  const [openStates, setOpenStates] = useState([]);
  const distanceInputRef = useRef();
  const toggleElement = (index) => {
    setOpenStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };
  useEffect(() => {
    dispatch(GetAllCategories())
      .then((response) => {
        setAllCategories(response.data?.categories);
      })
      .catch((error) => {
        // console.log(error);
      });
    if (SubCategoryId) {
      dispatch(GetSingleCategory(SubCategoryId))
        .then((response) => {
          setSingleCategory(response.data?.category?.fields);
        })
        .catch((error) => {
          // console.log(error)
        });
    } else if (typeId) {
      dispatch(GetTypeById(typeId))
        .then((response) => {
          setSingleCategory(response.data?.type?.fields)
        })
        .catch((error) => {
          // console.log(error)
        });
    }
  }, [dispatch, typeId, SubCategoryId]);
  const handleSubCategoryChange = (subCategoryId) => {
    dispatch(GetSingleCategory(subCategoryId))
      .then((response) => {
        setSingleCategory(response.data?.category?.fields);
      })
      .catch((error) => {
        // console.log(error);
      });
  }
  const filterIcon = useSelector((state) => state.filterIcon);
  const [isOpen, setIsOpen] = useState(savedValue);
  const [open, setOpen] = useState(false)
  const [openFilter, setOpenFilter] = useState(true)
  const [distance, setDistance] = useState(0);
  const [distancefromProduct, setDistanceFromProduct] = useState([])
  const [role, setRole] = useState('')
  function handleChange(event) {
    const distance = parseInt(event.target.value);
    setDistance(distance);
    setDistanceFromProduct([0, distance]);
  }
  const formatDistance = (distance) => {
    return distance === "250" ? "250+" : distance;
  };
  const handleReset = (targets) => {
    if (targets && targets.length > 0) {
      document.getElementById("minInput").value = "";
      document.getElementById("maxInput").value = "";
      delete filterValues.minPrice;
      delete filterValues.maxPrice
      delete mergedObject.minPrice;
      delete mergedObject.maxPrice
      delete convertedFilterValues.minPrice;
      delete convertedFilterValues.maxPrice
      dispatch({ type: "ADD_FILTER", payload: { convertedFilterValues } });
    } else {
      document.getElementById("minInput").value = "";
      document.getElementById("maxInput").value = "";
      delete filterValues.minPrice;
      delete filterValues.maxPrice
      delete convertedFilterValues.minPrice;
      delete convertedFilterValues.maxPrice
      delete mergedObject.minPrice;
      delete mergedObject.maxPrice
      dispatch({ type: "ADD_FILTER", payload: { convertedFilterValues } });
    }
  };
  const usedRadioRef = useRef(null);
  const newRadioRef = useRef(null);
  const listRadioRef = useRef({});
  const BooleanRadioRef = useRef({});
  const StringRef = useRef({});
  const NumberRef = useRef({});
  const handleResetLocation = () => {
    setDistance(0);
    setDistanceFromProduct([])
    dispatch({ type: "ADD_FILTER", payload: { convertedFilterValues } });
  };
  const handleResetCondition = () => {
    usedRadioRef.current.checked = false;
    newRadioRef.current.checked = false;
    delete filterValues.condition;
    delete convertedFilterValues.condition
    delete mergedObject.condition
    dispatch({ type: "ADD_FILTER", payload: { convertedFilterValues } });
  };
  const handleResetList = (id) => {
    setFormData((prevFormData) => prevFormData.filter((item) => item.id !== id));
    const radioRefsForComponent = listRadioRef.current[id];
    if (radioRefsForComponent) {
      radioRefsForComponent.forEach((radio) => {
        if (radio) {
          radio.checked = false;
        }
      });
    }
  };
  const handleResetBoolean = (id) => {
    setFormData((prevFormData) => prevFormData.filter((item) => item.id !== id));
    const radioRefsForComponent = BooleanRadioRef.current[id];
    if (radioRefsForComponent) {
      radioRefsForComponent.forEach((radio) => {
        if (radio) {
          radio.checked = false;
        }
      });
    }
  };
  const handleResetString = (id) => {
    setFormData((prevFormData) => prevFormData.filter((item) => item.id !== id));
    const inputRef = StringRef.current[id];
    if (inputRef) {
      inputRef.value = "";
    }
  };
  const handleResetNumber = (id) => {
    setFormData((prevFormData) => prevFormData.filter((item) => item.id !== id));
    const inputRef = NumberRef.current[id];
    if (inputRef) {
      inputRef.value = "";
    }
  };
  const toggleSidebar = () => {
    dispatch({ type: 'SAVE_VALUE', payload: !savedValue });
    dispatch(handleFilterBar(!savedValue))
  }
  const handleInputChange1 = (event, field) => {
    const fieldId = field.id;
    const fieldType = field.type;
    let fieldValue = event.target.value;
    if (fieldType === "list" || "boolean") {
      fieldValue = Number(fieldValue);
    } else {
      fieldValue = fieldValue.trim();
    }
    setFormData(prevFormData => {
      const existingFieldIndex = prevFormData.findIndex((field) => field.id === fieldId);
      let updatedFormData;
      if (fieldValue !== '') {
        if (existingFieldIndex !== -1) {
          updatedFormData = prevFormData.map((field) => {
            if (field.id === fieldId) {
              return {
                id: fieldId,
                type: fieldType,
                value: fieldValue,
              };
            } else {
              return field;
            }
          });
        } else {
          updatedFormData = [
            ...prevFormData,
            {
              id: fieldId,
              type: fieldType,
              value: fieldValue,
            }
          ];
        }
      } else {
        if (existingFieldIndex !== -1) {
          updatedFormData = prevFormData.filter((field) => field.id !== fieldId);
        } else {
          updatedFormData = prevFormData;
        }
      }
      const output = { ...filterValues, fields: updatedFormData };
      return updatedFormData;
    });
  };
  const output1 = { ...formattedLocationObj, distanceFromProduct: distancefromProduct };
  const handleInputChange = (event, key) => {
    const { value } = event.target;
    setFilterValues(prevValues => ({ ...prevValues, [key]: value }));
  };
  {/*const scrollToCatchOfTheDay = () => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        scroll.scrollTo('CatchOfTheDay', {
          smooth: true,
          offset: -30,
        });
      }, 3000);
    } else {
      scroll.scrollTo('CatchOfTheDay', {
        smooth: true,
        offset: -30,
      });
    }
  };*/}



  useEffect(() => {
    const output = { ...filterValues, location: output1 };
    if (formData.length > 0) {
      output.fields = formData;
    }
    const mergedObject = {
      ...output,
      location: output1,
    };
    setMergedObject(mergedObject);
  }, [formData, filterValues, distancefromProduct]);
  let convertedFilterValues = mergedObject ? Object.entries(mergedObject).reduce((acc, [key, value]) => {
    let parsedValue = value;
    if (!isNaN(parseInt(value))) {
      parsedValue = parseInt(value);
    } else {
      try {
        parsedValue = JSON.parse(value);
      } catch {
      }
    }
    return { ...acc, [key]: parsedValue };
  }, {}) : {};
  useEffect(() => {
    dispatch({ type: "ADD_FILTER", payload: { convertedFilterValues } });
  }, [mergedObject]);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    const requestParams = {
      pageSize: columnSize,
      page: 1,
      rawData: convertedFilterValues,
      sort: sort
    };
    if (SubCategoryId) {
      if (FeaturedProduct) {
        requestParams.SubCatId = SubCategoryId;
        dispatch(GetProductBySubCat(requestParams))
          .then((data) => {
            dispatch(GetProductBySubCatSuccess(data));
          })
          .catch((error) => {
            // console.log(error);
          });
      } else if (CategoryId) {
        let parms = { page: pageNumber, pageSize: columnSize, sort: sort, rawData: convertedFilterValues, SubCatId: SubCategoryId };
        dispatch(GetProductBySubCat(parms))
          .then((data) => {
            dispatch(GetProductBySubCatSuccess(data));
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        const combined = {
          ...convertedFilterValues,
          "status": [
            "confirmed"
          ],
          "specialCriteria": [
            "products",
            "bids"
          ]
        }
        let parms = { page: pageNumber, pageSize: columnSize, sort: sort, rawData: combined, SubCatId: SubCategoryId };
        dispatch(GetProductBySubCat(parms))
          .then((data) => {
            dispatch(GetProductBySubCatSuccess(data));
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    } else if (CategoryId) {
      requestParams.categoryId = CategoryId;
      dispatch(SearchProduct(requestParams))
        .then((data) => {
          dispatch(SearchProductSuccess(data));
        })
        .catch((error) => {
          // console.log(error);
        });
    } else if (Location && Location.state && Location.state.id) {
      if (FeaturedProduct) {
        requestParams.CatId = Location.state.id;
        dispatch(GetProductByCat(requestParams))
          .then((data) => {
            dispatch(GetProductByCatSuccess(data));
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        const combined = {
          ...convertedFilterValues,
          "status": [
            "confirmed"
          ],
          "specialCriteria": [
            "products",
            "bids"
          ]
        }
        let parms = { page: pageNumber, pageSize: columnSize, sort: sort, rawData: combined, CatId: Location.state.id };
        dispatch(GetProductByCat(parms))
          .then((data) => {
            dispatch(GetProductByCatSuccess(data));
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    } else if (text) {
      if (offer === 'product') {
        if (searchBid) {
          const specialCriteria = { "specialCriteria": ["products", "bids"] }
          const combined = {
            ...specialCriteria,
            ...convertedFilterValues
          }
          let parms = { page: pageNumber, pageSize: columnSize, text: text.replace("-", " "), sort: sort, rawData: combined };
          if (searchSubId) {
            parms = { ...parms, subCategoryId: searchSubId };
            try {
              dispatch(SearchProduct(parms));
            } catch (error) {
              // console.log(error);
            }
          } else if (searchCatId) {
            parms = { ...parms, categoryId: searchCatId };
            try {
              dispatch(SearchProduct(parms));
            } catch (error) {
              // console.log(error);
            }
          } else {
            try {
              dispatch(SearchProduct(parms));
            } catch (error) {
              // console.log(error);
            }
          }
        } else {
          let parms = { page: pageNumber, pageSize: columnSize, text: text.replace("-", " "), sort: sort, rawData: convertedFilterValues };
          if (searchSubId) {
            parms = { ...parms, subCategoryId: searchSubId };
            try {
              dispatch(SearchProduct(parms));
            } catch (error) {
              // console.log(error);
            }
          } else if (searchCatId) {
            parms = { ...parms, categoryId: searchCatId };
            try {
              dispatch(SearchProduct(parms));
            } catch (error) {
              // console.log(error);
            }
          } else {
            try {
              dispatch(SearchProduct(parms));
            } catch (error) {
              // console.log(error);
            }
          }
        }
      } else {
        let parms;
        if (type) {
          if (typeId) {
            parms = { page: pageNumber, pageSize: columnSize, text: text.replace("-", " "), sort: sort, rawData: convertedFilterValues, typeOfService: type, categoryId: typeId };
          } else {
            parms = { page: pageNumber, pageSize: columnSize, text: text.replace("-", " "), sort: sort, rawData: convertedFilterValues, typeOfService: type };
          }
        } else {
          if (typeId) {
            parms = { page: pageNumber, pageSize: columnSize, text: text.replace("-", " "), sort: sort, rawData: convertedFilterValues, categoryId: typeId };
          } else {
            parms = { page: pageNumber, pageSize: columnSize, text: text.replace("-", " "), sort: sort, rawData: convertedFilterValues };
          }
        }
        try {
          dispatch(SearchAllService(parms));
        } catch (error) {
          // console.log(error);
        }
      }
    } else if (bids) {
      const data = {
        "status": [
          "confirmed"
        ],
        "specialCriteria": [
          "products",
          "bids"
        ]
      }
      const mergedObject = {
        ...data,
        ...convertedFilterValues
      };
      if (SubCategoryId) {
        dispatch(SearchProduct({ pageSize: columnSize, page: 1, rawData: mergedObject, sort: sort, subCategoryId: SubCategoryId }))
          .then((data) => {
            dispatch(SearchProductSuccess(data));
          })
          .catch((error) => {
            // console.log(error);
          });
      } else if (CategoryId) {
        dispatch(SearchProduct({ pageSize: columnSize, page: 1, rawData: mergedObject, sort: sort, categoryId: CategoryId }))
          .then((data) => {
            dispatch(SearchProductSuccess(data));
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        dispatch(SearchProduct({ pageSize: columnSize, page: 1, rawData: mergedObject, sort: sort }))
          .then((data) => {
            dispatch(SearchProductSuccess(data));
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    } else if (featured) {
      if (FeaturedProduct) {
        dispatch(GetAllFeaturedProduct({ pageSize: columnSize, page: 1, rawData: convertedFilterValues, sort: sort }))
          .then((data) => {
            dispatch(GetAllFeaturedProductSuccess(data));
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        const combined = {
          ...convertedFilterValues,
          "status": [
            "confirmed"
          ],
          "specialCriteria": [
            "products",
            "bids"
          ]
        }
        dispatch(GetAllFeaturedProduct({ pageSize: columnSize, page: 1, rawData: combined, sort: sort }))
          .then((data) => {
            dispatch(GetAllFeaturedProductSuccess(data));
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    } else if (offered) {
      const data = {
        "status": [
          "confirmed"
        ]
      }
      const mergedObject = {
        ...data,
        ...convertedFilterValues
      };
      dispatch(GetAllServicesByType({ pageSize: columnSize, page: 1, type: "offer", rawData: mergedObject, sort: sort }))
        .catch((error) => {
          // console.log(error);
        });
    } else if (requested) {
      const data = {
        "status": [
          "confirmed"
        ]
      }
      const mergedObject = {
        ...data,
        ...convertedFilterValues
      };
      dispatch(GetAllServicesByType({ pageSize: columnSize, page: 1, type: "request", rawData: mergedObject, sort: sort }))
        .catch((error) => {
          // console.log(error);
        });
    } else if (service) {
      const data = {
        "status": [
          "confirmed"
        ],
        "specialCriteria": [
          "services"
        ]
      }
      const mergedObject = {
        ...data,
        ...convertedFilterValues
      };
      dispatch(GetProductByCat({ pageSize: columnSize, page: 1, serviceCatId: params.categoryId, rawData: mergedObject, sort: sort }))
        .then((data) => {
          dispatch(GetProductByCatSuccess(data));
        })
        .catch((error) => {
          // console.log(error);
        });
    } else if (allProducts) {
      let parms = { page: 1, pageSize: columnSize, rawData: convertedFilterValues, sort: sort };
      try {
        dispatch(SearchProduct(parms));
      } catch (error) {
        // console.log(error);
      }
    } else {
      if (typeId) {
        let parms = { page: activePage, pageSize: columnSize, rawData: convertedFilterValues, sort: sort, categoryId: typeId };
        try {
          dispatch(SearchAllService(parms));
        } catch (error) {
          // console.log(error);
        }
      } else {
        let parms = { page: activePage, pageSize: columnSize, rawData: convertedFilterValues, sort: sort };
        try {
          dispatch(SearchAllService(parms));
        } catch (error) {
          // console.log(error);
        }
      }
    }
  };
  const userid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get(`https://backend.la7i27alak.com/user/${userid}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setRole(response.data?.user?.isAdmin)
      })
      .catch((error) => console.error(error));
  }, [userid, token]);
  const baseUrl = 'https://backend.la7i27alak.com'
  const hideElement = service || offered || requested || allServices || params.product==="service";
  const handleCatchOfTheDayClick = () => {
    if (location.pathname !== '/') {
      navigate('/#CatchOfTheDay');
    }
  };
  return (
    <nav
      className={`${styles.navbar} ${active ? styles.active : ""} ${darkMode ? styles.navbarDark : ''} ${filterBar ? styles.activeBar : ''}`}
    >
      <ul className={styles.navbar__items}>
        {isFilterIcon && (
          <MdMenu
            className={styles.filter_icon}
            onClick={toggleSidebar}
          />
        )}
        <div className={`${styles.sidebar} ${darkMode ? styles.sidebarDark : ''} ${savedValue ? styles.open : ""}`}>
          <div className={styles.filtercont}>
            <MdOutlineFilterList className={`${styles.filteric} ${darkMode ? styles.filtericDark : ''}`} />
            <span className={`${styles.filter} ${darkMode ? styles.filterDark : ''}`}>FILTER</span>
          </div>
          {/* <div className={styles.eleline}></div> */}
          {openFilter && (
            <div>
              {/* price */}
              <div className={styles.elementcont}>
                <div className={`${styles.element} ${darkMode ? styles.elementDark : ''}`}>
                  <p>Price</p>
                  {openStates[1] ? (
                    <MdRemove className={styles.elementad} onClick={() => toggleElement(1)} />
                  ) : (
                    <MdAdd className={styles.elementad} onClick={() => toggleElement(1)} />
                  )}
                </div>
                <div className={styles.subelements}>
                  {openStates[1] &&
                    <div className={`${styles.subelement} ${darkMode ? styles.subelementDark : ''}`} >
                      <div className={styles.select_input_row}>
                        <select id="currencySelect"
                          className={`${styles.select_option} ${darkMode ? styles.select_optionDark : ''}`}>
                          {/*<option value="">LBP</option>*/}
                          <option value="option2">USD</option>
                        </select>
                        <input id="minInput" type="number" className={`${styles.small_input} ${darkMode ? styles.small_inputDark : ''}`} placeholder="Minimum" onChange={event => handleInputChange(event, 'minPrice')} />
                        <div>-</div>
                        <input id="maxInput" type="number" className={`${styles.small_input} ${darkMode ? styles.small_inputDark : ''}`} placeholder="Maximum" onChange={event => handleInputChange(event, 'maxPrice')} />
                      </div>
                      <button onClick={() => handleReset(["currencySelect", "minInput", "maxInput"])} className={styles.reset}>Reset</button>
                    </div>
                  }
                  <div className={styles.subeleline}></div>
                </div>
              </div>
              {/* location */}
              <div className={styles.elementcont}>
                <div className={`${styles.element} ${darkMode ? styles.elementDark : ''}`}>
                  <p>Location</p>
                  {openStates[2] ? (
                    <MdRemove className={styles.elementad} onClick={() => toggleElement(2)} />
                  ) : (
                    <MdAdd className={styles.elementad} onClick={() => toggleElement(2)} />
                  )}
                </div>
                <div className={styles.subelements}>
                  {openStates[2] &&
                    <div className={`${styles.subelement} ${darkMode ? styles.subelementDark : ''}`} >
                      <input
                        type="range"
                        id="distance-range"
                        min="0"
                        max="250"
                        value={distance}
                        onChange={handleChange}
                        className={styles.distance}
                        ref={distanceInputRef}
                      />
                      <p id="disttext" className={styles.distanceText}>Distance:{formatDistance(distance)} km</p>
                      <button onClick={() => handleResetLocation()} className={styles.reset}>Reset</button>
                    </div>
                  }
                  <div className={styles.subeleline}></div>
                </div>
              </div>
              {/* condition */}
              {!hideElement && (
                <div className={styles.elementcont}>
                  <div className={`${styles.element} ${darkMode ? styles.elementDark : ''}`}>
                    <p>Condition</p>
                    {openStates[3] ? (
                      <MdRemove className={styles.elementad} onClick={() => toggleElement(3)} />
                    ) : (
                      <MdAdd className={styles.elementad} onClick={() => toggleElement(3)} />
                    )}
                  </div>
                  <div className={styles.subelements}>
                    {openStates[3] &&
                      <div className={`${styles.subelement} ${darkMode ? styles.subelementDark : ''}`} >
                        <label className={styles.condition}>
                          <input type="radio" name="condition" ref={usedRadioRef} id="used" value="false" onChange={event => handleInputChange(event, 'condition')} className={styles.radio} />
                          Used
                        </label>
                        <label className={styles.condition}>
                          <input type="radio" name="condition" ref={newRadioRef} id="new" value="true" onChange={event => handleInputChange(event, 'condition')} className={styles.radio} />
                          New
                        </label>
                        <button onClick={handleResetCondition} className={styles.reset}>Reset</button>
                      </div>
                    }
                    <div className={styles.subeleline}></div>
                  </div>
                </div>
              )}
              {singleCategory.length > 0 && (
                <>
                  {singleCategory.map((field, index) => (
                    <div className={styles.elementcont} key={field.id}>
                      <div className={`${styles.element} ${darkMode ? styles.elementDark : ''}`}>
                        <p>{field && field?.title}</p>
                        {openStates[index + 5] ? (
                          <MdRemove className={styles.elementad} onClick={() => toggleElement(index + 5)} />
                        ) : (
                          <MdAdd className={styles.elementad} onClick={() => toggleElement(index + 5)} />
                        )}
                      </div>
                      <div className={styles.subelements}>
                        {openStates[index + 5] && (
                          <div className={`${styles.subelement} ${darkMode ? styles.subelementDark : ''}`}>
                            {field.type === 'string' ? (
                              <div className={styles.subelements}>
                                <div className={`${styles.subelement} ${darkMode ? styles.subelementDark : ''}`}>
                                  <input
                                    type="text"
                                    id={field.id}
                                    className={`${styles.large_input} ${darkMode ? styles.large_inputDark : ''}`}
                                    placeholder={field.title}
                                    ref={(el) => {
                                      if (!StringRef.current[field.id]) {
                                        StringRef.current[field.id] = el;
                                      }
                                    }}
                                    onChange={(event) => handleInputChange1(event, field)}
                                  />
                                </div>
                                <button onClick={() => handleResetString(field.id)} className={styles.reset}>Reset</button>
                              </div>
                            ) : field.type === 'boolean' ? (
                              <div className={styles.switch_container}>
                                {field.possibleValues.map((value, valueIndex) => (
                                  <label key={valueIndex} className={styles.condition}>
                                    <input
                                      type="radio"
                                      name={field.title}
                                      className={styles.radio}
                                      value={valueIndex}
                                      ref={(el) => {
                                        if (!BooleanRadioRef.current[field.id]) {
                                          BooleanRadioRef.current[field.id] = [];
                                        }
                                        BooleanRadioRef.current[field.id][valueIndex] = el;
                                      }}
                                      onChange={(event) => handleInputChange1(event, field)}
                                    />
                                    {value}
                                  </label>
                                ))}
                                <button onClick={() => handleResetBoolean(field.id)} className={styles.reset}>Reset</button>
                              </div>
                            ) : field.type === 'number' ? (
                              <div className={styles.subelements}>
                                <div>
                                  <input
                                    className={`${styles.large_input} 
                                    ${darkMode ? styles.large_inputDark : ''}`}
                                    onChange={(event) => handleInputChange1(event, field)}
                                    placeholder="number"
                                    ref={(el) => {
                                      if (!NumberRef.current[field.id]) {
                                        NumberRef.current[field.id] = el;
                                      }
                                    }}
                                  />
                                </div>
                                <button onClick={() => handleResetNumber(field.id)} className={styles.reset}>Reset</button>
                              </div>
                            ) : field.type === 'list' && (
                              <div className={styles.switch_container}>
                                {field.possibleValues.map((value, valueIndex) => (
                                  <label key={valueIndex} className={styles.condition}>
                                    <input
                                      type="radio"
                                      name={field.title}
                                      value={valueIndex}
                                      ref={(el) => {
                                        // Store the radio button ref in listRadioRefs
                                        if (!listRadioRef.current[field.id]) {
                                          listRadioRef.current[field.id] = [];
                                        }
                                        listRadioRef.current[field.id][valueIndex] = el;
                                      }}
                                      className={styles.radio}
                                      onChange={(event) => handleInputChange1(event, field)}
                                    />
                                    {value}
                                  </label>
                                ))}
                                <button onClick={() => handleResetList(field.id)} className={styles.reset}>Reset</button>
                              </div>
                            )}
                          </div>
                        )}
                        <div className={styles.subeleline}></div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              <button className={styles.apply} onClick={() => {
                handlePageChange();
                toggleSidebar();
              }}>
                Apply
              </button>
            </div>
          )}
        </div>
        {role === true && (
          <>
            <li
              className={`${styles.link} ${darkMode ? styles.linkDark : ''}`}
              onClick={() => navigate("/admin/dashboard")}
            >
              Admin
            </li>
            <li className={styles.line}></li>
          </>
        )}
        <CategoriesDropDown handleSubCategoryChange={handleSubCategoryChange} />
        <li className={styles.line}></li>
        <ServicesDropDown />
        <li className={styles.line}></li>
        <li className={`${styles.catch}`}>
          <HashLink
            className={`${styles.link} ${darkMode ? styles.linkDark : ''}`}
            to='/#cod'
            smooth
          >
            Catch Of The Day
          </HashLink>
        </li>
        <li className={styles.line}></li>
        <li
          className={`${styles.link} ${darkMode ? styles.linkDark : ''}`}
          onClick={() => navigate("/special-offers")}>
          Special Offers
        </li>
        <li className={styles.line}></li>
        <li
          className={`${styles.link} ${darkMode ? styles.linkDark : ''}`}
          onClick={() => navigate("/bids")}>
          Bids
        </li>
        {/* <li className={styles.line}></li> */}
        {/* <li className={`${styles.link} ${darkMode ? styles.linkDark : ''}`}
        >New Arrivals</li> */}
        <li className={styles.line}></li>
        <li className={`${styles.link} ${darkMode ? styles.linkDark : ''}`}
          onClick={() => navigate("/help")}>
          Help
        </li>
      </ul>
    </nav>
  );
};
export default NavBar;