import {
    GET_ALL_TYPES_FAIL,
    GET_ALL_TYPES_SUCCESS
} from "../constants/servicesConstants";
const initialState = {
    types: [],
    error: null,
    loading: false,
};
export const GetAllTypes = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TYPES_SUCCESS:
            return {
                ...state,
                types: action.payload,
                error: null,
                loading: false,
            };
        case GET_ALL_TYPES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
