import React, { useState, useEffect } from 'react';
import styles from './AdminchatHeader.module.scss';
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BsFillTelephoneFill,BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineFlag } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { BiNoEntry } from "react-icons/bi";
import ReportModal from './reportModal';
import { useDispatch, useSelector } from "react-redux/es/exports";
import { get, ref, update, runTransaction, push, set, remove } from 'firebase/database';
import { db } from '../../../../firebase';
import { PostBlock, PostReport } from '../../../../store/actions/userActions';
import { GetUser } from '../../../../store/actions/userActions';
const AdminChatHeader = ({selectedUser }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const specificUserId = userLogin.user?.token
  const [showDropdown, setShowDropdown] = useState(false);
  const [showThreeDotsDropdown, setShowThreeDotsDropdown] = useState(false);
  const toggleThreeDotsDropdown = () => {
    setShowThreeDotsDropdown(!showThreeDotsDropdown);
    setShowDropdown(false);
  };
  const [report, setReport] = useState(false);
  const openReport = () => {
    setReport(true)
  };
  const handleEmailClick = () => {
    const mailto = selectedUser.email
    window.location.href = `mailto:${mailto}`;
  };
  const [phoneNber, setphoneNumber] = useState('');
  useEffect(() => {
    const fetchUser = async () => {
      const response = await dispatch(GetUser(selectedUser.id));
      if (response && response.data?.phone) {
        setphoneNumber(response.data?.phone);
      }
    };
    fetchUser();
  }, [dispatch, selectedUser.id]);
  function initiatePhoneCall() {
    const url = `tel:${phoneNber}`;
    window.location.href = url;
  }
  const handleDeleteMessages = () => {
      remove(ref(db, "/chatlist/" + specificUserId + "/" + selectedUser.id))
      .then(() => {
        
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };
  const rawData = {
    'uidToBlock': selectedUser.id
  };
  const handleBlockUser = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(PostBlock(rawData));
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <>
      <ReportModal
        open={report}
        onClose={() => {
          setReport(false);
        }}
        selectedUser={selectedUser.id}
      />
      <div className={darkMode ? "chatheaderDark" : "chatheader"}>
        <div>
          <img className="profile-pic-header" src={selectedUser.image} alt="Profile" />
        </div>
        <div className='content-container'>
          <div className="detailsheader">
            <p className={darkMode ? "nameheaderDark" : "nameheader"}>{selectedUser ? selectedUser.name && selectedUser.name.length > 15
              ? selectedUser.name.substring(0, 15) + '...'
              : selectedUser.name : ''}</p>
            {/* {lastMessage && lastMessage.sendTime && (
              <p className={darkMode ? "last-seenheaderDark" : "last-seenheader"}>Last seen: {timePosted(lastMessage.sendTime)} ago</p>
            )} */}
          </div>
        </div>
        <div className="icons-containerh">
          <AiOutlinePhone onClick={initiatePhoneCall} className={darkMode ? "iconhDark" : "iconh"} />
          <AiOutlineMail onClick={handleEmailClick} className={darkMode ? "iconhDark" : "iconh"} />
          <div className='threedots' onClick={toggleThreeDotsDropdown}>
            <button>
              <BsThreeDotsVertical className={darkMode ? "iconhDark" : "iconh"} />
            </button>
            {showThreeDotsDropdown && (
              <div className="dotDrop">
                <button onClick={openReport}>
                  <p className='edit'>
                    <AiOutlineFlag className='ic' /> Report user
                  </p>
                </button>
                <hr />
                <button onClick={(e) => handleBlockUser(e)}>
                  <p className='edit'>
                    <BiNoEntry className='ic' /> Block user
                  </p>
                </button>
                <hr />
                <button onClick={handleDeleteMessages}>
                  <p className='delete'>
                    <BsFillTrashFill className='icd' /> Delete Chat
                  </p>
                </button>
                <hr />
                <button >
                  <p className='edit'>
                    <div className='ic'></div> Cancel
                  </p>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminChatHeader;
