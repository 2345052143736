import React, { useState, useEffect } from 'react';
import styles from './ActiveCategory.module.scss';
import { Switch } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsPencil } from "react-icons/bs";
import { BsFillTrashFill } from "react-icons/bs";
import { DeleteCategory } from '../../../store/actions/categoryAction';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllSubCategories } from '../../../store/actions/categoryAction';
import { GetAllCategories } from '../../../store/actions/categoryAction';
import { GetAllCategoriesSuccess } from '../../../store/actions/categoryAction';
import { useNavigate } from "react-router-dom";
import { DeleteServiceTypeById } from '../../../store/actions/servicesActions';
import { GetAllTypes } from '../../../store/actions/servicesActions';
const ActiveCategory = ({ photo, text, categoryId, isService, ServiceTypeId }) => {
  const navigate = useNavigate();
  const [isActivated, setIsActivated] = useState(false);
  const [isOn, setIsOn] = useState(true);
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories?.categories?.data?.categories);
  const handleActivationToggle = () => {
    setIsActivated(!isActivated);
  };
  const handleStateToggle = (checked) => {
    setIsOn(checked);
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [showThreeDotsDropdown, setShowThreeDotsDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
    setShowThreeDotsDropdown(false);
  };
  const toggleThreeDotsDropdown = () => {
    setShowThreeDotsDropdown(!showThreeDotsDropdown);
    setShowDropdown(false);
  };
  const handleDelete = () => {
    if (isService) {
      dispatch(DeleteServiceTypeById(ServiceTypeId))
      dispatch(GetAllTypes())
    } else {
      dispatch(DeleteCategory(categoryId));
      setHidden(true)
    }
  };
  useEffect(() => {
    dispatch(GetAllCategories())
      .then((data) => {
        dispatch(GetAllCategoriesSuccess(data));
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [dispatch]);
  const handleClick = () => {
    if (isService) {
      const url = `/admin/edit-service-category/${ServiceTypeId}`;
      navigate(url, { state: { serviceId: ServiceTypeId } });
    } else {
      const url = `/admin/edit-product-category/${categoryId}`
      navigate(url, { state: { id: categoryId } });
    }
  };
  return hidden ? null : (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.image_container}>
          {!isService && (
            <img
              src={photo}
              alt="category image"
              className={styles.category_image}
            />
          )}
        </div>
        {text}
      </div>
      <div className={styles.right}>
        <div className={styles.threedots} onClick={toggleThreeDotsDropdown}>
          <button>
            <BsThreeDotsVertical className='dots' />
          </button>
          {showThreeDotsDropdown && (
            <div className={styles.dotDrop}>
              <button onClick={handleClick}>
                <p className={styles.edit}>
                  <BsPencil className={styles.ic} /> {isService ? 'Edit Service Type' : 'Edit Category'}
                </p>
              </button>
              <hr />
              <button onClick={() => handleDelete()}>
                <p className={styles.delete}>
                  <BsFillTrashFill className={styles.icd} />{isService ? 'Delete Service Type' : 'Delete Category'}
                </p>
              </button>
            </div>
          )}
        </div>
      </div>
      {showDropdown ? null : <hr className={styles.hrr} />}
    </div>
  );
};
export default ActiveCategory;
