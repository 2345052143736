import React, { useEffect, useState } from "react";
import styles from "./Card.module.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { url } from "../../../../common/constants/url";
import { GetAllCategories } from "../../../../store/actions/categoryAction";
import { GetAllCategoriesSuccess } from "../../../../store/actions/categoryAction";
import { GetAllSubCategories } from "../../../../store/actions/categoryAction";
import { GetAllSubCategoriesSuccess } from "../../../../store/actions/categoryAction";
const Card = ({ image, categoryId, title,subCategoryId }) => {
  const [subCategories, setSubCategories] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllSubCategories(categoryId))
      .then((data) => {
        setSubCategories(data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [categoryId, dispatch]);
  const subCategoriesArray = Object.values(subCategories)
  return (
    <div className={styles.card_container}>
      <div className={styles.card}>
        <img src={image} alt={title} className={styles.card_image} />
        <div className={styles.subcategories_container}>
          {subCategoriesArray.map((item) =>
            item.subCategories.map((subCategory) => (
              <div className={styles.subcategories_item} key={subCategory.title}>
                <Link
                  className={styles.subcategories_title}
                  to={`/sell/products/products-categories/product-information/${title
                    .replaceAll(" ", "-")
                    .replaceAll("&", "And")
                    .replaceAll(",", "")}/${subCategory.title.replaceAll(" ", "-").replaceAll("&", "And").replaceAll(",", "")}/${subCategory._id}`}
                >
                  {subCategory.title}
                </Link>
              </div>
            )))}
        </div>
      </div>
      <div className={styles.title_container}>
        <h1 className={styles.card_title}>{title}</h1>
      </div>
    </div>
  );
  
};
export default Card;
