
export const addProductArray = (newProductArray) => {
    return {
      type: "ADD_PRODUCT_ARRAY",
      payload: newProductArray,
    };
  };
export const removeProductArray = (id) => {
    return {
      type: "REMOVE_PRODUCT_ARRAY",
      payload: id,
    };
  };