export const COMPANY_SIZE_LIST_REQUEST = "COMPANY_SIZE_LIST_REQUEST";
export const COMPANY_SIZE_LIST_SUCCESS = "COMPANY_SIZE_LIST_SUCCESS";
export const COMPANY_SIZE_LIST_FAIL = "COMPANY_SIZE_LIST_LIST_FAIL";
export const COMPANY_INDUSTRY_LIST_REQUEST = "COMPANY_INDUSTRY_LIST_REQUEST";
export const COMPANY_INDUSTRY_LIST_SUCCESS = "COMPANY_INDUSTRY_LIST_SUCCESS";
export const COMPANY_INDUSTRY_LIST_FAIL = "COMPANY_INDUSTRY_LIST_LIST_FAIL";
export const POST_INDUSTRY_TYPE_SUCCESS = "POST_INDUSTRY_TYPE_SUCCESS";
export const POST_INDUSTRY_TYPE_FAIL = "POST_INDUSTRY_TYPE_FAIL";
export const POST_INDUSTRY_SIZE_SUCCESS = "POST_INDUSTRY_SIZE_SUCCESS";
export const POST_INDUSTRY_SIZE_FAIL = "POST_INDUSTRY_SIZE_FAIL";
export const GET_INDUSTRY_SIZE_SUCCESS = "GET_INDUSTRY_SIZE_SUCCESS";
export const GET_INDUSTRY_SIZE_FAIL = "GET_INDUSTRY_SIZE_FAIL";
export const GET_INDUSTRY_TYPE_SUCCESS = "GET_INDUSTRY_TYPE_SUCCESS";
export const GET_INDUSTRY_TYPE_FAIL = "GET_INDUSTRY_TYPE_FAIL";
export const POST_FAQ_SUCCESS = "POST_FAQ_SUCCESS";
export const POST_FAQ_FAIL = "POST_FAQ_FAIL";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAIL = "GET_FAQ_FAIL";
