import React from "react";
import styles from "../../MyProductsPage/EmptyProducts/EmptyProducts.module.scss"
import img from "../../../assests/svg/empty products.svg";
import { useSelector } from "react-redux";
const EmptyPending = () => {
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    return (
        <div className={styles.container}>
            <img src={img} alt="empty products" className={styles.img} />
            <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>No Pending Products</h1>
            <p className={styles.text}>There is not any Pending Products.</p>
        </div>
    );
};
export default EmptyPending;
