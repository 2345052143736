import {
    GET_USER_CATEGORY_SUCCESS,
    GET_USER_CATEGORY_FAIL
} from "../constants/userConstants";
const initialState = {
    getUserById: [],
    error: null,
    loading: false,
};
export const GetUserByIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_CATEGORY_SUCCESS:
            return {
                ...state,
                getUserById: action.payload,
                error: null,
                loading: false,
            };
        case GET_USER_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
