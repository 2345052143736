import {
    GET_RECOMMENDED_PRODUCT_FAIL,
    GET_RECOMMENDED_PRODUCT_SUCCESS,
    GET_RECOMMENDED_PRODUCT_REQUEST
} from "../constants/recommendedProductConstants";
const initialState = {
    recommendedProducts: [],
    error: null,
    loading: false,
};
export const recommendedProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RECOMMENDED_PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_RECOMMENDED_PRODUCT_SUCCESS:
            return {
                ...state,
                recommendedProducts: action.payload,
                error: null,
                loading: false,
            };
        case GET_RECOMMENDED_PRODUCT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
