import {
  WHISHLIST_REQUEST,
  WHISHLIST_SUCCESS,
  WHISHLIST_FAIL,
  BID_WHISHLIST_REQUEST,
  BID_WHISHLIST_FAIL,
  BID_WHISHLIST_SUCCESS,
  PRODUCT_WHISHLIST_REQUEST,
  PRODUCT_WHISHLIST_SUCCESS,
  PRODUCT_WHISHLIST_FAIL,
  SERVICE_WHISHLIST_REQUEST,
  SERVICE_WHISHLIST_SUCCESS,
  SERVICE_WHISHLIST_FAIL,
  REMOVE_ITEM_WHISHLIST_SUCCESS,
  REMOVE_ITEM_WHISHLIST_REQUEST,
  REMOVE_ITEM_WHISHLIST_FAIL,
  WHISHLIST_IDS_REQUEST,
  WHISHLIST_IDS_SUCCESS,
  WHISHLIST_IDS_FAIL,
  ADD_ITEM_WHISHLIST_SUCCESS,
  ADD_ITEM_WHISHLIST_FAIL,
  ADD_ITEM_WHISHLIST_REQUEST,
  ADD_WHISHLIST_SUCCESS,
  ADD_WHISHLIST_FAIL,
  REMOVE_WHISHLIST_SUCCESS,
  REMOVE_WHISHLIST_FAIL,
  GET_WHISHLIST_SUCCESS,
  GET_WHISHLIST_FAIL,
  ADD_SERVICE_TO_WHISHLIST_SUCCESS,
  ADD_SERVICE_TO_WHISHLIST_FAIL,
  GET_WHISHLIST_SERVICE_SUCCESS,
  GET_WHISHLIST_SERVICE_FAIL
} from "../constants/wishlistConstants";
import axios from "axios";
import { url } from "../../common/constants/url";
export const listMyWhishlist = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WHISHLIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    };
    const { data } = await axios.get(
      `${url}/wishlist/${id}/getallwishlistbyuser`,
      config
    );
    dispatch({
      type: WHISHLIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: WHISHLIST_FAIL,
      payload: message,
    });
  }
};
export const removeFromWishlist = (offerid) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REMOVE_ITEM_WHISHLIST_REQUEST,
    });
    const {
      userById: { user },
    } = getState();
    const {
      userLogin: { userInfo },
    } = getState();
    let user_id = user.id;
    let body = {
      user_id,
      offerid,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo}`,
      },
    };
    await axios.post(
      `${url}/wishlist/removewishlist`,
      JSON.stringify(body),
      config
    );
    dispatch({
      type: REMOVE_ITEM_WHISHLIST_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: REMOVE_ITEM_WHISHLIST_FAIL,
      payload: message,
    });
  }
};
export const listMyWhishlistID = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WHISHLIST_IDS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo}`,
      },
    };
    const { data } = await axios.get(
      `${url}/wishlist/${id}/getallwishlistbyuserID`,
      config
    );
    dispatch({
      type: WHISHLIST_IDS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: WHISHLIST_IDS_FAIL,
      payload: message,
    });
  }
};
export const addItemToWishlist = (pid) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_ITEM_WHISHLIST_REQUEST,
    });
    const {
      userById: { user },
    } = getState();
    const {
      userLogin: { userInfo },
    } = getState();
    let uid = user.id;
    let body = {
      uid,
      pid,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo}`,
      },
    };
    await axios.post(
      `${url}/wishlist/addwishlistall`,
      JSON.stringify(body),
      config
    );
    dispatch({
      type: ADD_ITEM_WHISHLIST_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ADD_ITEM_WHISHLIST_FAIL,
      payload: message,
    });
  }
};
// export const listMyBidWhishlist = (id) => async (dispatch, getState) => {
//   try {
//     dispatch({
//       type: BID_WHISHLIST_REQUEST,
//     });
//     const { data } = await axios.get(`${url}/wishlist/${id}/getwishlistbybid`);
//     dispatch({
//       type: BID_WHISHLIST_SUCCESS,
//       payload: data,
//       // payload: data.body.data,
//     });
//   } catch (error) {
//     const message =
//       error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message;
//     dispatch({
//       type: BID_WHISHLIST_FAIL,
//       payload: message,
//     });
//   }
// };
// export const listMyServiceWhishlist = (id) => async (dispatch, getState) => {
//   try {
//     dispatch({
//       type: SERVICE_WHISHLIST_REQUEST,
//     });
//     const { data } = await axios.get(
//       `${url}/wishlist/${id}/getwishlistbyservice`
//     );
//     dispatch({
//       type: SERVICE_WHISHLIST_SUCCESS,
//       payload: data,
//       // payload: data.body.data,
//     });
//   } catch (error) {
//     const message =
//       error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message;
//     dispatch({
//       type: SERVICE_WHISHLIST_FAIL,
//       payload: message,
//     });
//   }
// };
// export const listMyProductWhishlist = (id) => async (dispatch, getState) => {
//   try {
//     dispatch({
//       type: PRODUCT_WHISHLIST_REQUEST,
//     });
//     const { data } = await axios.get(
//       `${url}/wishlist/${id}/getwishlistbyproduct`
//     );
//     dispatch({
//       type: PRODUCT_WHISHLIST_SUCCESS,
//       payload: data,
//       // payload: data.body.data,
//     });
//   } catch (error) {
//     const message =
//       error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message;
//     dispatch({
//       type: PRODUCT_WHISHLIST_FAIL,
//       payload: message,
//     });
//   }
// };
export const AddWishlist = (formData) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem('token')
      
      const response = await axios.put(`${url}/user/addToWishList`, formData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      
      dispatch(AddWishlistSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(AddWishlistFail(error));
      // console.log(error)
    }
  };
};
export const AddWishlistSuccess = (status) => {
  return {
    type: ADD_WHISHLIST_SUCCESS,
    payload: status,
  };
};
const AddWishlistFail = (error) => ({
  type: ADD_WHISHLIST_FAIL,
  payload: error
});
export const RemoveWishList = (formData) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem('token')
      
      const response = await axios.put(`${url}/user/removeFromWishList`, formData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      
      dispatch(RemoveWishlistSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(RemoveWishlistFail(error));
      // console.log(error)
    }
  };
};
export const RemoveWishlistSuccess = (status) => {
  return {
    type: REMOVE_WHISHLIST_SUCCESS,
    payload: status,
  };
};
const RemoveWishlistFail = (error) => ({
  type: REMOVE_WHISHLIST_FAIL,
  payload: error
});
export const GetWishList = () => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem('token')
      
      const response = await axios.get(`${url}/user/wishList`, {
        headers: {
          'authorization': `${token}`
        },
      });
      dispatch(GetWishlistSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetWishlistFail(error));
     
    }
  };
};
export const GetWishlistSuccess = (status) => {
  return {
    type: GET_WHISHLIST_SUCCESS,
    payload: status,
  };
};
const GetWishlistFail = (error) => ({
  type: GET_WHISHLIST_FAIL,
  payload: error
});
export const GetWishListService = () => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem('token')
      
      const response = await axios.get(`${url}/user/servicewishList`, {
        headers: {
          'authorization': `${token}`
        },
      });
      
      dispatch(GetWishlistServiceSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetWishlistServiceFail(error));
      // console.log(error)
    }
  };
};
export const GetWishlistServiceSuccess = (status) => {
  return {
    type: GET_WHISHLIST_SERVICE_SUCCESS,
    payload: status,
  };
};
const GetWishlistServiceFail = (error) => ({
  type: GET_WHISHLIST_SERVICE_FAIL,
  payload: error
});

export const AddServiceToWishlist = (ServiceId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token');
      
      const config = {
        headers: {
          'authorization': token,
        },
      };
      const response = await axios.put(`${url}/user/addservicewishList/${ServiceId}`, null, config);
      
      dispatch(AddServiceToWishlistSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(AddServiceToWishlistFail(error));
      // console.log(error);
    }
  };
};
export const AddServiceToWishlistSuccess = (status) => {
  return {
    type: ADD_SERVICE_TO_WHISHLIST_SUCCESS,
    payload: status,
  };
};
const AddServiceToWishlistFail = (error) => ({
  type: ADD_SERVICE_TO_WHISHLIST_FAIL,
  payload: error
});