import React, { useState, useEffect } from "react";
import "./AdminProducts.scss";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import { Table } from "react-bootstrap";
import { Pagination } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Calendar from "../../assests/svg/calender.svg";
import DatePicker from "react-datepicker";
import { BsCaretDown } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsPlusLg } from "react-icons/bs";
import Type from "./components/Type/Type";
import "react-datepicker/dist/react-datepicker.css";
import ProductName from "./components/ProductName/ProductName";
import Price from "./components/Price/Price";
import EmptyPending from "./components/EmptyPending";
import Owner from "./components/Owner/Owner";
import ProdDate from "./components/ProdDate/ProdDate";
import Action from "./components/Action/Action";
import ViewDetails from "./components/ViewDetails/ViewDetails";
import { useDispatch, useSelector } from "react-redux";
import { GetAllProduct } from "../../store/actions/productDetailsAction";
import { GetAllProductSuccess } from "../../store/actions/productDetailsAction";
import { url } from "../../common/constants/url";
import ThreeDots from "./components/threeDots/threeDots";
import Reports from "./components/Reports/Reports";
import Enable from "./components/Enable/Enable";
import Status from "./components/Status/Status";
import {
  GetPendingProduct,
  GetConfirmedProduct,
  GetPendingService,
  GetConfirmedService,
} from "../../store/actions/AdminDashboard";
import Loader from "../../common/components/Loader/Loader";
function AdminProducts() {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [activeButton, setActiveButton] = useState(1);
  const PendingProduct = useSelector(
    (state) => state.PendingProduct?.PendingProduct?.data
  );
  const ConfirmedProduct = useSelector(
    (state) => state.ConfirmedProductArray?.ConfirmedProductArray?.data
  );
  const ConfirmedServicesArray = useSelector(
    (state) => state.ConfirmedServicesArray?.ConfirmedServicesArray?.data
  );
  const PendingService = useSelector(
    (state) => state.PendingService?.PendingService?.data
  );
  const concatenatedConfirmedArray = [
    ...(ConfirmedProduct || []),
    ...(ConfirmedServicesArray || []),
  ];
  const concatenatedPendingArray = [
    ...(PendingProduct || []),
    ...(PendingService || []),
  ];
  const loading = useSelector((state) => state.PendingProduct?.loading);
  const loading1 = useSelector((state) => state.ConfirmedProductArray?.loading);
  useEffect(() => {
    dispatch(GetPendingProduct());
    dispatch(GetConfirmedProduct());
    dispatch(GetConfirmedService());
    dispatch(GetPendingService());
  }, [dispatch]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(concatenatedPendingArray);

  useEffect(() => {
    if (activeButton === 1) {
      setFilteredData(concatenatedPendingArray);
    } else {
      setFilteredData(concatenatedConfirmedArray);
    }
  }, [activeButton,PendingProduct,ConfirmedProduct,ConfirmedServicesArray,PendingService]);

  const handleSearch = (e) => {
    if (activeButton === 1) {
      const query = e.target.value;
      setSearchQuery(query);

      // Filter the data based on the search query
      const filteredArray = concatenatedPendingArray.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      );

      setFilteredData(filteredArray);
    } else {
      const query = e.target.value;
      setSearchQuery(query);

      // Filter the data based on the search query
      const filteredArray = concatenatedConfirmedArray.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      );

      setFilteredData(filteredArray);
    }
  };
  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isSmallScreensr, setIsSmallScreensr] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1050);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial value
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreensr(window.innerWidth < 730);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial value
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const renderTable = () => {
    if (activeButton === 1) {
      return (
        <>
          {isSmallScreen ? (
            <>
              {loading ? (
                <Loader />
              ) : filteredData && filteredData.length === 0 ? (
                <EmptyPending />
              ) : (
                <div className="smdtable">
                  {filteredData &&
                    filteredData.map((product) => {
                      return (
                        <div className="smdtext" key={product._id}>
                          <Table
                            striped
                            bordered
                            hover
                            style={{
                              maxWidth: "230px",
                              borderRadius: "10px",
                              marginBottom: "50px",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td>ID:</td>
                              </tr>
                              <tr>
                                <td>Type:</td>
                                <td>
                                  <Type
                                    letter={
                                      product.isService
                                        ? "s"
                                        : product.bidding?.enabled
                                        ? "b"
                                        : "p"
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Product Name:</td>
                                <td>
                                  <ProductName
                                    title={product.title}
                                    image={product.imageUrls[0]}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Price:</td>
                                <td>
                                  <Price price={product.pricing?.price} />
                                </td>
                              </tr>
                              <tr>
                                <td>Owner:</td>
                                <td>
                                  <Owner uid={product.user} />
                                </td>
                              </tr>
                              <tr>
                                <td>Date:</td>
                                <td>
                                  <ProdDate
                                    date={new Date(
                                      product.createdAt
                                    ).toLocaleDateString()}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Action:</td>
                                <td>
                                  <Action
                                    action="a"
                                    productId={product._id}
                                    isService={product.isService}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Details:</td>
                                <td>
                                  <ViewDetails
                                    fields={product.fields}
                                    pricing={product.pricing}
                                    selectedAddress={product.pickUpAddress}
                                    defaultAddress={product.serviceAddress}
                                    images={product.imageUrls}
                                    description={product.description}
                                    condition={product.condition}
                                    title={product.title}
                                    Enabled={product.bidding?.enabled}
                                    Negotiable={product.pricing?.negotiable}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>Delete:</td>
                                <td>
                                  <ThreeDots
                                    ProductId={product._id}
                                    isService={product.isService}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      );
                    })}
                </div>
              )}
            </>
          ) : (
            <>
              {loading ? (
                <Loader />
              ) : filteredData && filteredData.length === 0 ? (
                <EmptyPending />
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Type</th>
                      <th>Product Name</th>
                      <th>Price</th>
                      <th className="owner">Owner</th>
                      <th>Date</th>
                      <th>Action</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  {(filteredData || []).length > 0 && (
                    <tbody>
                      {filteredData &&
                        filteredData.map((product) => {
                          return (
                            <tr key={product._id}>
                          <td>{product._id}</td> 
                              <td>
                                <Type
                                  letter={
                                    product.isService
                                      ? "s"
                                      : product.bidding?.enabled
                                      ? "b"
                                      : "p"
                                  }
                                />
                              </td>
                              <td>
                                <ProductName
                                  title={product.title}
                                  image={product.imageUrls[0]}
                                />
                              </td>
                              <td className="pricetb">
                                <Price price={product.pricing?.price} />
                              </td>
                              <td>
                                <Owner uid={product.user} />
                              </td>
                              <td>
                                <ProdDate
                                  date={new Date(
                                    product.createdAt
                                  ).toLocaleDateString()}
                                />
                              </td>
                              <td>
                                <Action
                                  action="a"
                                  productId={product._id}
                                  isService={product.isService}
                                />
                              </td>
                              <td>
                                <ViewDetails
                                  fields={product.fields}
                                  pricing={product.pricing}
                                  selectedAddress={product.pickUpAddress}
                                  defaultAddress={product.serviceAddress}
                                  images={product.imageUrls}
                                  description={product.description}
                                  condition={product.condition}
                                  title={product.title}
                                  Enabled={product.bidding?.enabled}
                                  Negotiable={product.pricing?.negotiable}
                                  createProduct={false}
                                  quantity={product && product.quantity}
                                />
                              </td>
                              <td>
                                <ThreeDots
                                  ProductId={product._id}
                                  isService={product.isService}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
              )}
            </>
          )}
        </>
      );
    } else if (activeButton === 2) {
      return (
        <>
          {isSmallScreen ? (
            <>
              {loading ? (
                <Loader />
              ) : (
                filteredData &&
                filteredData.map((product) => (
                  <div key={product._id} className="smdtable">
                    <Table
                      striped
                      bordered
                      hover
                      style={{
                        maxWidth: "230px",
                        borderRadius: "10px",
                        marginBottom: "50px",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>ID:</td>
                        </tr>
                        <tr>
                          <td>Type:</td>
                          <td>
                            <Type
                              letter={
                                product.isService
                                  ? "s"
                                  : product.bidding?.enabled
                                  ? "b"
                                  : "p"
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Product Name:</td>
                          <td>
                            <ProductName
                              title={product.title}
                              image={product.imageUrls[0]}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Price:</td>
                          <td>
                            <Price price={[product.pricing.price]} />
                          </td>
                        </tr>
                        <tr>
                          <td>Owner:</td>
                          <td>
                            <Owner uid={product.user} />
                          </td>
                        </tr>
                        <tr>
                          <td>Date:</td>
                          <td>
                            <ProdDate
                              date={new Date(
                                product.createdAt
                              ).toLocaleDateString()}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Enable:</td>
                          <td>
                            <Enable />
                          </td>
                        </tr>
                        <tr>
                          <td>Status:</td>
                          <td>
                            <Status state="active" />
                          </td>
                        </tr>
                        <tr>
                          <td>Delete:</td>
                          <td>
                            <ThreeDots
                              ProductId={product._id}
                              isService={product.isService}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                ))
              )}
            </>
          ) : (
            <>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th className="owner">Owner</th>
                    <th>Date</th>
                    <th>Enable</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                {loading ? (
                  <Loader />
                ) : (
                  (filteredData || []).length > 0 && (
                    <tbody>
                      {filteredData &&
                        filteredData.map((product) => {
                          return (
                            <tr>
                              <td>{product._id}</td>
                              <td>
                                <Type
                                  letter={
                                    product.isService
                                      ? "s"
                                      : product.bidding?.enabled
                                      ? "b"
                                      : "p"
                                  }
                                />
                              </td>
                              <td>
                                <ProductName
                                  title={product.title}
                                  image={product.imageUrls[0]}
                                />
                              </td>
                              <td>
                                <Price price={[product.pricing.price]} />
                              </td>
                              <td>
                                <Owner uid={product.user} />
                              </td>
                              <td>
                                <ProdDate
                                  date={new Date(
                                    product.createdAt
                                  ).toLocaleDateString()}
                                />
                              </td>
                              <td>
                                <Enable />
                              </td>
                              <td>
                                <Status state="active" />
                              </td>
                              <td>
                                <ThreeDots
                                  ProductId={product._id}
                                  isService={product.isService}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  )
                )}
              </Table>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {isSmallScreen ? (
            <>
              <div className="smdtable">
                <Table
                  striped
                  bordered
                  hover
                  style={{
                    maxWidth: "230px",
                    borderRadius: "10px",
                    marginBottom: "50px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td>
                        ID
                      </td>
                    </tr>
                    <tr>
                      <td>Type:</td>
                      <td>
                        <Type letter="p" />
                      </td>
                    </tr>
                    <tr>
                      <td>Product Name:</td>
                      <td>
                        <ProductName />
                      </td>
                    </tr>
                    <tr>
                      <td>Price:</td>
                      <td>
                        <Price />
                      </td>
                    </tr>
                    <tr>
                      <td>Owner:</td>
                      <td>
                        <Owner />
                      </td>
                    </tr>
                    <tr>
                      <td>Date:</td>
                      <td>
                        <ProdDate />
                      </td>
                    </tr>
                    <tr>
                      <td>Action:</td>
                      <td>
                        <Action action="r" />
                      </td>
                    </tr>
                    <tr>
                      <td>Delete:</td>
                      <td>
                        <ThreeDots />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Type</th>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th className="owner">Owner</th>
                  <th>Date</th>
                  <th>Action</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Type letter="p" />
                  </td>
                  <td>
                    <ProductName />
                  </td>
                  <td>
                    <Price />
                  </td>
                  <td>
                    <Owner />
                  </td>
                  <td>
                    <ProdDate />
                  </td>
                  <td>
                    <Action action="r" />
                  </td>
                  <td>
                    <ThreeDots />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Type letter="p" />
                  </td>
                  <td>
                    <ProductName />
                  </td>
                  <td>
                    <Price />
                  </td>
                  <td>
                    <Owner />
                  </td>
                  <td>
                    <ProdDate />
                  </td>
                  <td>
                    <Action action="r" />
                  </td>
                  <td>
                    <ThreeDots />
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </>
      );
    }
  };
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const currentDate = new Date();
  const formattedDate = `${selectedDate.toLocaleString("default", {
    month: "short",
  })} ${selectedDate.getDate()}`;
  const handleFileChange = (file) => {
    setSelectedFile(file);
  };
  return (
    <AdminLayout>
      {/* header */}
      <div className="container_Head">
        <div className="header">
          <h3>Products</h3>
          <div className="calendar">
            <img src={Calendar} alt="" />
            Today <span className="dates"> {formattedDate}</span>
            <button onClick={toggleDatePicker}>
              <BsCaretDown />
            </button>
            {showDatePicker && (
              <div className="datepicker-wrapper">
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showPopperArrow={false}
                  dateFormat="MMM d"
                  inline
                />
              </div>
            )}
          </div>
        </div>
        {isSmallScreensr ? (
          <div className="toolbar">
            <div className="searchad">
              <div className="search_pinput">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                 
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="toolbar">
            <div className="search_pinput">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
              />
            </div>
          </div>
        )}
      </div>

      <div className="tableChoices">
        <button
          className={activeButton === 1 ? "active" : ""}
          onClick={() => handleButtonClick(1)}
        >
          Pending
        </button>
        <button
          className={activeButton === 2 ? "active" : ""}
          onClick={() => handleButtonClick(2)}
        >
          Accepted
        </button>
      </div>
      {/* table */}
      <div className="container_t">
        {isSmallScreen ? (
          <div className="smdtable">{renderTable()}</div>
        ) : (
          <div className="table">{renderTable()}</div>
        )}
        {/* <div className='table'>{renderTable()}</div> */}
        <div className="flexC">
          <div className="offers">
            <p className="textC">
              <span className="bids_circle"></span>Bids
            </p>
            <p className="textC">
              <span className="offers_circle"></span>Products
            </p>
            <p className="textC">
              <span className="services_circle"></span>Services
            </p>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
export default AdminProducts;
