import React, { useEffect, useState } from "react";
import styles from "./switch.module.scss";
import { enableNotification } from "../../../store/actions/NotificationAction";
import { useDispatch } from "react-redux";
import { GetUser } from "../../../store/actions/userActions";
const Switch = ({ isSelected, setIsSelected }) => {
  //const [isSelected, setIsSelected] = useState(true);
  const uid = localStorage.getItem('uid')
  const [isEnabled, setIsEnabled] = useState(false)
  const dispatch = useDispatch();
  const changeNotification = async () => {
    if (isEnabled) {
      const data = { "enabled": false }
      const response = await dispatch(enableNotification(data))
      if (response) {
        setIsEnabled(false)
      }
    } else {
      const data = { "enabled": true }
      const response = await dispatch(enableNotification(data))
      if (response) {
        setIsEnabled(true)
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(GetUser(uid))
      setIsEnabled(response.data?.user?.notifications?.enabled)
    };
    fetchData();
  }, [])
  return (
    <div
      onClick={changeNotification}
      className={`${styles.switch_container} ${isEnabled ? styles.active : ""
        }`}
    >
      <span className={`${styles.button} ${isEnabled ? styles.active : ""}`} />
    </div>
  );
};
export default Switch;
