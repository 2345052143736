import React, { useState, useEffect } from "react";
import styles from "./SellServiceForm.module.scss";
import LocationSection from "./LocationSection/LocationSection";
import Layout from "../../common/components/Layouts/Layout";
import Camera from "../../assests/images/camera.png";
import Switch from "react-switch";
import PostSuccess from "../../common/components/PostSuccess/PostSuccess";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PhotosComponents from "../Sell Product Form Page/PhotosComponents/PhotosComponents";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import {
  addServiceHandler,
  uploadImagesHandler,
  uploadMainImageHandler,
} from "../../store/actions/addServiceActions";
import {
  ADD_SERVICE_RESET,
  UPLOAD_IMAGES_SERVICE_RESET,
  UPLOAD_MAIN_IMAGE_SERVICE_RESET,
} from "../../store/constants/addServiceContants";
import MapModal from "../Sell Product Form Page/Properties/MapModal";
import { getAddressByUser } from "../../store/actions/addAddressActions";
import AddressComponent from "../AddressPage/AddressComponent/AddressComponent";
import { AiOutlinePlusCircle, AiOutlineRight } from "react-icons/ai";
import { OfferServieValidationSchema } from "../Sell Product Form Page/index";
import { CreateProduct } from "../../store/actions/addProductActions";
import { CreateService } from "../../store/actions/servicesActions";
import { useParams } from "react-router-dom";
import { GetTypeById } from "../../store/actions/servicesActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SellServiceForm = () => {
  const dispatch = useDispatch();
  const { serviceCatId, serviceCat } = useParams();
  useEffect(() => {
    const pageName = "Request Service"; // Replace this with the actual page name
    const pageUrl = `/sell/services/request/${serviceCat}/${serviceCatId}`; // Replace this with the actual page URL
    dispatch({
      type: "ADD_BREADCRUMB",
      payload: { name: pageName, url: pageUrl },
    });
  }, []);
  const [service, setService] = useState([]);
  useEffect(() => {
    dispatch(GetTypeById(serviceCatId))
      .then((data) => {
        setService(data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [serviceCatId, dispatch]);
  const TypeArray = service ? Object.values(service) : [];
  const [dynamicFieldsValues, setDynamicFieldsValues] = useState([]);
  const fieldsArray = TypeArray.map((item) => item.type.fields);
  const updatedFields = fieldsArray.flat().map((field) => {
    const matchingObj = dynamicFieldsValues.find(
      (item) => item.title === field.title
    );
    let value = matchingObj ? matchingObj.value : null;
    if (field.type === "boolean") {
      value = value === null ? 0 : value; // If value is null, set it to 0, otherwise keep its original value
    }
    return { ...field, value };
  });
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [toggleStates, setToggleStates] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  function handleSelect(index, value) {
    setIsOpen(false);
    setSelectedItem(value);
  }
  const [activeBool, setActiveBool] = useState(0);
  const handleBoolClick = (index) => {
    setActiveBool(index);
  };
  function handleSelect(index, value) {
    setIsOpen(false);
    setSelectedItem(value);
  }
  const [dynamicListState, setdynamicListState] = useState(null);
  const [dynamicConditionState, setdynamicConditionState] = useState(null);
  const [openaddress, setOpenaddress] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const userById = useSelector((state) => state.userById);
  const [isNegotiable, setIsNegotiable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isExchangeable, setIsExchangeable] = useState(false);
  const [isRefundable, setIsRefundable] = useState(false);
  const [startTDate, setStartTDate] = useState("");
  const [endTDate, setEndTDate] = useState("");
  const { user } = userById;
  const [price, setPrice] = useState(0);
  const [priceErr, setPriceErr] = useState("");
  const address = useSelector((state) => state.address?.address);
  const navigate = useNavigate();
  const defaultAddress = address.find((address) => address.isDefault);
  useEffect(() => {
    dispatch(getAddressByUser());
  }, [dispatch]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [deadlineTimer, setdeadlineTimer] = useState({
    enabled: false,
    deadline: null,
  });
  function handleLocationClick() {
    setShowDropdown(!showDropdown);
  }
  function handleOptionClick(id) {
    const selectedAddress = address.find((add) => add._id === id);
    setSelectedAddress(selectedAddress);
    setShowDropdown(false);
  }
  const handlePriceChange = (event) => {
    const inputValue = event.target.value;
    const newPrice = inputValue !== "" ? parseFloat(inputValue) : null;
    if (isNaN(newPrice) || newPrice === null) {
      setPrice(null);
      formik.setFieldValue("price", null);
      setPriceErr("Price is required");
    } else {
      const limitedPrice = parseFloat(newPrice.toFixed(2));
      setPrice(limitedPrice);
      formik.setFieldValue("price", newPrice);
      setPriceErr("");
    }
  };
  const [timer, setTimer] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartTDate(newStartDate);
  };

  const handleStartDateBlur = () => {
    const newStartDate = startTDate; // Get the value from state
    const currentLocalTime = new Date();
    currentLocalTime.setMinutes(
      currentLocalTime.getMinutes() - currentLocalTime.getTimezoneOffset()
    );
    if (endTDate !== "") {
      if (newStartDate >= currentLocalTime.toISOString().slice(0, 16)) {
        const newEndDate = endTDate;
        if (newStartDate < newEndDate) {
          setEndTDate(newEndDate);
        } else {
          toast.error("Start date should be before end date.");
          setStartTDate(currentLocalTime.toISOString().slice(0, 16)); // Set to current date if there's an error
        }
      } else {
        toast.error("Start date cannot be in the past.");
        setStartTDate(currentLocalTime.toISOString().slice(0, 16)); // Set to current date if there's an error
      }
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndTDate(newEndDate);
  };

  const handleEndDateBlur = () => {
    const newEndDate = endTDate; // Get the value from state
    const currentLocalTime = new Date();
    currentLocalTime.setMinutes(
      currentLocalTime.getMinutes() - currentLocalTime.getTimezoneOffset()
    );

    if (newEndDate >= currentLocalTime.toISOString().slice(0, 16)) {
      if (newEndDate > startTDate) {
        // No need to update the state here since we're already updating it on change
      } else {
        toast.error("End date should be after start date.");
        setEndTDate(currentLocalTime.toISOString().slice(0, 16)); // Set to current date if there's an error
      }
    } else {
      toast.error("End date cannot be in the past.");
      setEndTDate(currentLocalTime.toISOString().slice(0, 16)); // Set to current date if there's an error
    }
  };

  const [fileUploaded, setFileUploaded] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [photoStates, setPhotoStates] = useState(new Array(16).fill(null));
  const handleClick = (index) => {
    hiddenFileInputs[index].current.click();
  };
  const handleChange = (event, index) => {
    const selectedFiles = Array.from(event.target.files);
    const newPhotoStates = [...photoStates];
    // Read each selected file and update the state
    selectedFiles.forEach((file, fileIndex) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (readerEvent) => {
          newPhotoStates[index + fileIndex] = {
            file,
            dataURL: readerEvent.target.result,
          };
          setPhotoStates([...newPhotoStates]);
        };
        reader.readAsDataURL(file);
      }
    });
  };
  const hiddenFileInputs = photoStates.map(() => React.createRef());
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const hiddenImageInput = React.useRef(null);
  const handleImageClick = (event) => {
    hiddenImageInput.current.click();
  };
  const handleImageChange = (event) => {
    setImagesUploaded((prevState) => [...prevState, event.target.files[0]]);
  };
  useEffect(() => {
    if (timer) {
      const dateObject = new Date(endTDate);
      const milliseconds = dateObject.getTime();
      setdeadlineTimer({ enabled: true, deadline: milliseconds });
    } else {
      setdeadlineTimer({ enabled: false, deadline: null });
    }
  }, [startTDate, endTDate, timer]);
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    description: "",
    price: "",
  });
  const pricing = {
    price,
  };
  const jsonPricing = JSON.stringify(pricing, ["price"]);
  const newphotoStates = photoStates.filter(
    (item) => item !== null && item !== ""
  );
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const formik = useFormik({
    initialValues: formData,
    onSubmit: (values) => {
      // console.log({ ...values, seller: user.id });
      // dispatch(addProductHandler(values));
      // setSuccess(true);
    },
    validationSchema: OfferServieValidationSchema,
  });
  const loading = useSelector((state) => state.CreateService?.loading);
  const handleEditClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  function checkForEmptyValues(arr) {
    let isEmpty = false;
    for (const obj of arr) {
      if (obj.value === null || obj.value === "") {
        isEmpty = true;
        break;
      }
    }
    return isEmpty;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newphotoStates.length === 0) {
      toast.warning("Add at least one picture.");
    } else if (checkForEmptyValues(updatedFields)) {
      toast.warning("Please fill all the fields.");
    } else if (defaultAddress === undefined) {
      toast.warning("Please add a location.");
    } else if (formik.errors.description) {
      toast.warning(formik.errors.description);
    } else if (formik.errors.title) {
      toast.warning(formik.errors.title);
    } else {
      if (!formik.values.price) {
        toast.warning("Please fill in the price field.");
        return;
      }
      try {
        const formData = new FormData();
        formData.append("categoryId", serviceCatId);
        formData.append("title", formik.values.title);
        formData.append("description", formik.values.description);
        formData.append("pricing", jsonPricing);
        formData.append("fields", JSON.stringify(updatedFields));
        formData.append("timer", JSON.stringify(deadlineTimer));
        formData.append("isService", 1);
        formData.append("typeOfService", "request");
        for (const file of newphotoStates) {
          formData.append("images", file.file);
        }
        if (selectedLocation == null) {
          formData.append("serviceAddressId", defaultAddress._id);
        } else {
          formData.append("serviceAddressId", selectedLocation);
        }
        const response = await dispatch(CreateService(formData));
        if (response) {
          setSuccess(true);
        }
      } catch (error) {
        // console.log(error);
      }
    }
  };
  const booleanFields =
    TypeArray &&
    TypeArray.length > 0 &&
    TypeArray[0].type?.fields?.filter((field) => field.type === "list");
  const numberOfBooleans = booleanFields.length;
  const [isOpenList, setIsOpenList] = useState(
    Array(numberOfBooleans).fill(false)
  );
  const [selectedItems, setSelectedItems] = useState(
    Array(numberOfBooleans).fill(null)
  );
  const handleDelete = (index) => {
    const updatedPhotoStates = [...photoStates];
    updatedPhotoStates[index] = null;
    setPhotoStates(updatedPhotoStates);
  };
  return (
    <>
      <MapModal
        open={openaddress}
        onClose={() => {
          setOpenaddress(false);
        }}
      />
      <Layout title={"Request Service Information"}>
        <form className={styles.wrapper} onSubmit={formik.handleSubmit}>
          <div className={styles.title_container}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Service Title
            </p>
            <div className={styles.input_container}>
              <input
                type="text"
                className={`${styles.input} ${
                  darkMode ? styles.inputDark : ""
                } ${
                  formik.errors.title && formik.touched.title
                    ? styles.error_input
                    : ""
                }`}
                placeholder="Name of product and related info"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
              />
              {formik.errors.title && formik.touched.title && (
                <p className={styles.error_message}>{formik.errors.title}</p>
              )}
            </div>
          </div>
          <div className={styles.desc_container}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Description Of Service
            </p>
            <div className={styles.textarea_container}>
              <textarea
                className={`${styles.text_area} ${
                  darkMode ? styles.text_areaDark : ""
                } ${
                  formik.errors.description && formik.touched.description
                    ? styles.error_input
                    : ""
                }`}
                placeholder="Tell us more about your product"
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.errors.description && formik.touched.description && (
                <p className={styles.error_message}>
                  {formik.errors.description}
                </p>
              )}
            </div>
          </div>
          <div className={styles.price_container}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Price
            </p>
            <div className={styles.input_container}>
              <input
                type="number"
                className={`${styles.input} ${darkMode ? styles.inputDark : ""}
               ${
                 formik.errors.price && formik.touched.price
                   ? styles.error_input
                   : ""
               }`}
                placeholder="000,000"
                name="price"
                onChange={handlePriceChange}
                onBlur={formik.handleBlur}
                value={price || ""}
                onWheel={(e) => e.target.blur()}
              />
              {formik.errors.price && formik.touched.price && (
                <p className={styles.error_message}>{priceErr}</p>
              )}
              <div
                className={`${styles.currency} ${
                  darkMode ? styles.currencyDark : ""
                }`}
              >
                $
              </div>
            </div>
          </div>
          <div className={styles.line}></div>
          {TypeArray.map((item) =>
            item.type.fields.map((subCategory, index) => {
              const renderField = (subCategory) => {
                switch (subCategory.type.toLowerCase()) {
                  case "string":
                    const fieldTitle = subCategory.title;
                    const fieldValue =
                      formik.values.dynamicFields?.[subCategory.title] || "";
                    const fieldError = formik.errors[subCategory.title];
                    const fieldTouched = formik.touched[subCategory.title];
                    return (
                      <div className={`${styles.fieldContainer}`}>
                        <label
                          className={`${styles.label} ${
                            darkMode ? styles.labelDark : ""
                          }`}
                        >
                          {fieldTitle}
                        </label>
                        <div className={styles.input_container}>
                          <input
                            type="text"
                            className={`${styles.inputString} ${
                              darkMode ? styles.inputStringDark : ""
                            } 
            ${fieldError && fieldTouched ? styles.error_input : ""}`}
                            placeholder={fieldTitle}
                            name={`dynamicFields.${subCategory.title}.${subCategory.type?.id}`}
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue(
                                `dynamicFields.${fieldTitle}`,
                                value
                              );
                              const existingObjectIndex =
                                dynamicFieldsValues.findIndex(
                                  (obj) => obj.title === fieldTitle
                                );
                              if (existingObjectIndex !== -1) {
                                const updatedArray = [...dynamicFieldsValues];
                                updatedArray[existingObjectIndex].value = value;
                                setDynamicFieldsValues(updatedArray);
                              } else {
                                setDynamicFieldsValues([
                                  ...dynamicFieldsValues,
                                  { title: fieldTitle, value },
                                ]);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={fieldValue}
                          />
                          {fieldError && fieldTouched && (
                            <p className={styles.error_message}>{fieldError}</p>
                          )}
                        </div>
                      </div>
                    );
                  case "list":
                    const toggleDropdown = (index) => {
                      const updatedIsOpenList = [...isOpenList];
                      updatedIsOpenList[index] = !updatedIsOpenList[index];
                      setIsOpenList(updatedIsOpenList);
                    };
                    // Function to handle selecting an option in a specific list
                    const handleSelect = (index, value) => {
                      const updatedSelectedItems = [...selectedItems];
                      updatedSelectedItems[index] = value;
                      setSelectedItems(updatedSelectedItems);
                      const updatedIsOpenList = [...isOpenList];
                      updatedIsOpenList[index] = false;
                      setIsOpenList(updatedIsOpenList);
                    };
                    return (
                      <div className={`${styles.fieldContainer}`}>
                        <label
                          className={`${styles.label} ${
                            darkMode ? styles.labelDark : ""
                          }`}
                        >
                          {subCategory.title}
                        </label>
                        <div className={styles.input_container}>
                          <div>
                            <button
                              className={`${styles.listb} ${
                                darkMode ? styles.listbdark : ""
                              }`}
                              onClick={() => {
                                toggleDropdown(index);
                              }}
                            >
                              {selectedItems[index]
                                ? selectedItems[index]
                                : `Select a ${subCategory.title}`}
                            </button>
                            {isOpenList[index] && (
                              <div
                                className={`${styles.drop} ${
                                  darkMode ? styles.dropdark : ""
                                }`}
                              >
                                <Scrollbars
                                  autoHeight
                                  autoHeightMin={0}
                                  autoHeightMax={200}
                                >
                                  {subCategory.possibleValues.map(
                                    (value, valueIndex) => (
                                      <div
                                        key={valueIndex}
                                        className={`${styles.droplist} ${
                                          darkMode ? styles.droplistdark : ""
                                        }`}
                                        onClick={() => {
                                          setdynamicListState(valueIndex);
                                          const fieldName = subCategory.title;
                                          const title = subCategory.title;
                                          formik.setFieldValue(
                                            `dynamicFields.${fieldName}`,
                                            valueIndex
                                          );
                                          const existingObjectIndex =
                                            dynamicFieldsValues.findIndex(
                                              (obj) => obj.title === title
                                            );
                                          if (existingObjectIndex !== -1) {
                                            const updatedArray = [
                                              ...dynamicFieldsValues,
                                            ];
                                            updatedArray[
                                              existingObjectIndex
                                            ].value = valueIndex;
                                            setDynamicFieldsValues(
                                              updatedArray
                                            );
                                          } else {
                                            setDynamicFieldsValues([
                                              ...dynamicFieldsValues,
                                              { title, value: valueIndex },
                                            ]);
                                          }
                                          handleSelect(index, value);
                                        }}
                                        onMouseEnter={() => {
                                          setHoveredIndex(valueIndex);
                                        }}
                                        onMouseLeave={() => {
                                          setHoveredIndex(-1);
                                        }}
                                      >
                                        {value}
                                      </div>
                                    )
                                  )}
                                </Scrollbars>
                              </div>
                            )}
                          </div>
                          {formik.errors[subCategory.title] &&
                            formik.touched[subCategory.title] && (
                              <p className={styles.error_message}>
                                {formik.errors[subCategory.title]}
                              </p>
                            )}
                        </div>
                      </div>
                    );
                  case "number":
                    return (
                      <div className={`${styles.fieldContainer}`}>
                        <label
                          className={`${styles.label} ${
                            darkMode ? styles.labelDark : ""
                          }`}
                        >
                          {subCategory.title}
                        </label>
                        <div className={styles.input_container}>
                          <input
                            type="text"
                            className={`${styles.inputString} ${
                              darkMode ? styles.inputStringDark : ""
                            }
                              ${
                                formik.errors[subCategory.title] &&
                                formik.touched[subCategory.title]
                                  ? styles.error_input
                                  : ""
                              }`}
                            placeholder={subCategory.title}
                            name={`dynamicFields.${subCategory.title}.${subCategory.type?.id}`}
                            onChange={(e) => {
                              const value = e.target.value;
                              const fieldName = subCategory.title;
                              const title = subCategory.title;
                              formik.setFieldValue(
                                `dynamicFields.${fieldName}`,
                                value
                              );
                              const existingObjectIndex =
                                dynamicFieldsValues.findIndex(
                                  (obj) => obj.title === title
                                );
                              if (existingObjectIndex !== -1) {
                                // If an object exists, update its value property
                                const updatedArray = [...dynamicFieldsValues];
                                updatedArray[existingObjectIndex].value = value;
                                setDynamicFieldsValues(updatedArray);
                              } else {
                                // If an object doesn't exist, add a new object to the array
                                setDynamicFieldsValues([
                                  ...dynamicFieldsValues,
                                  { title, value },
                                ]);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values.dynamicFields?.[
                                subCategory.title
                              ] || ""
                            }
                          />
                          {formik.errors[subCategory.title] &&
                            formik.touched[subCategory.title] && (
                              <p className={styles.error_message}>
                                {formik.errors[subCategory.title]}
                              </p>
                            )}
                        </div>
                      </div>
                    );
                  case "boolean":
                    const number = 0;
                    return (
                      <div className={styles.Boolean_container}>
                        <p
                          className={`${styles.label} ${
                            darkMode ? styles.labelDark : ""
                          }`}
                        >
                          {subCategory.title}
                        </p>
                        <div className={styles.switch_container}>
                          {subCategory.possibleValues.map((value, index) => (
                            <div
                              key={index}
                              className={`${styles.switch_btn} ${
                                (!toggleStates[subCategory.id] &&
                                  number === index) ||
                                toggleStates[subCategory.id] === index
                                  ? styles.active
                                  : ""
                              } ${darkMode ? styles.switch_btnDark : ""} ${
                                darkMode &&
                                !(
                                  (!toggleStates[subCategory.id] &&
                                    number === index) ||
                                  toggleStates[subCategory.id] === index
                                )
                                  ? styles.activeDark
                                  : ""
                              }`}
                              onClick={() => {
                                const newToggleStates = { ...toggleStates };
                                newToggleStates[subCategory.id] = index;
                                setToggleStates(newToggleStates);
                                setdynamicConditionState(index);
                                const fieldName = subCategory.title;
                                const title = subCategory.title;
                                formik.setFieldValue(
                                  `dynamicFields.${fieldName}`,
                                  index
                                );
                                const existingObjectIndex =
                                  dynamicFieldsValues.findIndex(
                                    (obj) => obj.title === title
                                  );
                                if (existingObjectIndex !== -1) {
                                  const updatedArray = [...dynamicFieldsValues];
                                  updatedArray[existingObjectIndex].value =
                                    index;
                                  setDynamicFieldsValues(updatedArray);
                                } else {
                                  setDynamicFieldsValues([
                                    ...dynamicFieldsValues,
                                    { title, value: index },
                                  ]);
                                }
                                handleBoolClick(index);
                              }}
                            >
                              {value}
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  default:
                    return null;
                }
              };
              return renderField(subCategory);
            })
          )}
          <div className={styles.line2}></div>
          <div className={styles.image_section}>
            <p
              className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}
            >
              Add Photos
            </p>
            <div className={styles.wrapper}>
              {photoStates.map((photoState, index) => (
                <div
                  className={`${styles.image_container} ${
                    darkMode ? styles.image_containerDark : ""
                  }`}
                  onClick={() => handleClick(index)}
                >
                  <img
                    src={photoState ? photoState.dataURL : Camera}
                    alt="camera"
                    className={`${
                      photoState ? styles.full_image : styles.image
                    }`}
                    file={formik.values.file}
                  />
                  {photoState && (
                    <div
                      className={styles.remove_button}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                    >
                      X
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    ref={hiddenFileInputs[index]}
                    onChange={(event) => handleChange(event, index)}
                    style={{ display: "none" }}
                    multiple
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={styles.line}></div>
          <p className={`${styles.label} ${darkMode ? styles.labelDark : ""}`}>
            {selectedLocation || "Location"}
          </p>
          <div className={styles.location_section}>
            {address && address.length > 0 ? (
              address
                .filter((addr) => addr.isDefault)
                .map((addr) => (
                  <AddressComponent
                    defaultAddress={true}
                    title={addr.title}
                    description={addr.description}
                    threedots={false}
                  />
                ))
            ) : (
              <AddressComponent
                defaultAddress={false}
                title={"No Addresses"}
                description={"You Can Add a Location"}
                threedots={false}
              />
            )}
            <div className={styles.vertical_line}></div>
            <div className={styles.new_address_container}>
              <p
                className={`${styles.new_address_text} ${
                  darkMode ? styles.new_address_textDark : ""
                }`}
              >
                Select a new address as the location
              </p>
              <div
                className={`${styles.location} ${
                  darkMode ? styles.locationDark : ""
                }`}
                onClick={handleLocationClick}
              >
                {selectedAddress ? selectedAddress.title : "Location"}{" "}
                <AiOutlineRight className={styles.icon} />
              </div>
              {showDropdown &&
                (address.length > 0 ? (
                  <>
                    <div className={styles.dropdown}>
                      <ul>
                        {address.map((add) => (
                          <li
                            key={add._id}
                            className={styles.places}
                            onClick={() => handleOptionClick(add._id)}
                          >
                            {add.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.dropdown}>
                      <div
                        className={styles.addloc}
                        onClick={() => setOpenaddress(true)}
                      >
                        <AiOutlinePlusCircle
                          className={styles.add_button}
                          onClick={() => setOpenaddress(true)}
                        />
                        <span>Add Location</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles.dropdown}>
                    <div
                      className={styles.addloc}
                      onClick={() => setOpenaddress(true)}
                    >
                      <AiOutlinePlusCircle
                        className={styles.add_button}
                        onClick={() => setOpenaddress(true)}
                      />
                      <span>Add Location</span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.line}></div>
          <div className={styles.last_section}>
            <div>
              <div className={styles.flex}>
                <p
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                >
                  Set a Timer
                </p>
                <Switch
                  checked={timer}
                  onChange={() => setTimer(!timer)}
                  onColor={"#0298a6"}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
              {timer && (
                <div className={styles.flexColumn}>
                  <div className={styles.flex}>
                    <label
                      className={`${styles.labelt} ${
                        darkMode ? styles.labeltDark : ""
                      }`}
                    >
                      Start Date:
                    </label>
                    <label
                      className={`${styles.labelt} ${
                        darkMode ? styles.labeltDark : ""
                      }`}
                    >
                      Current date
                    </label>
                    {/* <input className="datein" type="datetime-local" value={startTDate} onChange={handleStartDateChange}  onBlur={handleStartDateBlur}/> */}
                  </div>
                  <div className={styles.flex}>
                    <label
                      className={`${styles.labelt} ${
                        darkMode ? styles.labeltDark : ""
                      }`}
                    >
                      End Date:
                    </label>
                    <input
                      className="datein"
                      type="datetime-local"
                      value={endTDate}
                      onChange={handleEndDateChange}
                      onBlur={handleEndDateBlur}
                    />
                  </div>
                </div>
              )}
              <p
                className={`${styles.text} ${darkMode ? styles.textDark : ""}`}
              >
                When active the product will be available only for a limited
                time, specified by you. (can be changed later)
              </p>
            </div>
          </div>
          {loading ? (
            <button className={`${styles.postload}`}>Posting...</button>
          ) : (
            <button
              className={`${styles.post} ${loading ? styles.postload : ""} ${
                darkMode ? styles.postDark : ""
              }`}
              type={"submit"}
              onClick={handleSubmit}
            >
              Post Now
            </button>
          )}
        </form>
        <PostSuccess
          servicerq={true}
          open={success}
          onClose={() => setSuccess(false)}
        />
      </Layout>
    </>
  );
};
export default SellServiceForm;
