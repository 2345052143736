import React, { useState, useEffect } from "react";
import styles from "./Search.module.scss";
import { AiOutlineSearch } from "react-icons/ai";
import { SearchProduct } from "../../../store/actions/productDetailsAction";
import { SearchAction } from "../../../store/actions/productDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import { Link } from "react-router-dom";
import {
  GetAllBids,
  GetAllBidsSuccess,
} from "../../../store/actions/productDetailsAction";
import { SearchActionService } from "../../../store/actions/productDetailsAction";
import { SearchAllService } from "../../../store/actions/productDetailsAction";
import { SearchAutocomplete } from "../../../store/actions/productDetailsAction";
const Search = ({
  id,
  categoryId,
  subCategoryId,
  featured,
  imported = false,
  first,
  second,
  third,
  type,
  categoryName,
  subCategoryName,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedChips, setSelectedChips] = useState([]);
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [description, setDescription] = useState(null);
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const columnSize = window.innerWidth < 768 ? 10 : 12;
  useEffect(() => {
    setSearchTerm("");
    setSelectedChips([]);
  }, [first, second, third]);
  useEffect(() => {
    const trimmedSearchTerm = searchTerm.trim();
    if (trimmedSearchTerm !== "") {
      const fetchSearchResults = async () => {
        if (first) {
          const data = { isService: false };
          let params = { text: trimmedSearchTerm, rawData: data };
          if (subCategoryId) {
            params = { ...params, subCategoryId };
          } else if (categoryId) {
            params = { ...params, categoryId };
          }
          const response = await dispatch(SearchAutocomplete(params));
          setSearchResults(response && response.suggestions);
        } else if (second) {
          const data = { isService: true };
          let params;
          if (type !== null || type !== undefined) {
            if (categoryId) {
              params = {
                page: 1,
                pageSize: columnSize,
                text: trimmedSearchTerm,
                type: type,
                rawData: data,
                ServiceCategory: categoryId,
              };
            } else {
              params = {
                page: 1,
                pageSize: columnSize,
                text: trimmedSearchTerm,
                type: type,
                rawData: data,
              };
            }
          } else {
            if (categoryId) {
              params = {
                page: 1,
                pageSize: columnSize,
                text: trimmedSearchTerm,
                rawData: data,
                ServiceCategory: categoryId,
              };
            } else {
              params = {
                page: 1,
                pageSize: columnSize,
                text: trimmedSearchTerm,
                rawData: data,
              };
            }
          }
          const response = await dispatch(SearchAutocomplete(params));
          setSearchResults(response && response.suggestions);
        } else {
          const specialCriteria = {
            specialCriteria: ["products", "bids"],
            isService: false,
          };
          let params = {
            page: 1,
            pageSize: columnSize,
            text: trimmedSearchTerm,
            rawData: specialCriteria,
          };
          if (subCategoryId) {
            params = { ...params, subCategoryId };
          } else if (categoryId) {
            params = { ...params, categoryId };
          }
          const response = await dispatch(SearchAutocomplete(params));
          setSearchResults(response && response.suggestions);
        }
      };
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, categoryId, subCategoryId, selectedChips]);
  const handleSelect = async () => {
    if (first) {
      if (searchTerm === "") {
        let params = { page: 1, pageSize: columnSize };
        if (subCategoryId) {
          params = { ...params, subCategoryId };
        } else if (categoryId) {
          params = { ...params, categoryId };
        }
        try {
          const response = await dispatch(SearchProduct(params));
          if (subCategoryId) {
            navigate(
              `/categories/${categoryName}/${subCategoryName}/${subCategoryId}/${categoryId}`
            );
          } else if (categoryId) {
            navigate(`/categories/${categoryName}`, {
              state: { id: categoryId },
            });
          }else{
            navigate('/products')
          }
        } catch (error) {
          // console.log(error);
        }
      } else {
        let params = { page: 1, pageSize: columnSize, text: searchTerm };
        if (subCategoryId) {
          params = { ...params, subCategoryId };
        } else if (categoryId) {
          params = { ...params, categoryId };
        }
        try {
          const response = await dispatch(SearchProduct(params));
          let url = `/search/${"product"}/${searchTerm.replace(" ", "-")}`;
          if (subCategoryId) {
            url += `/${categoryId}/${subCategoryId}`;
          } else if (categoryId) {
            url += `/${categoryId}`;
          }
          navigate(url);
        } catch (error) {
          // console.log(error);
        }
      }
    } else if (second) {
      if (searchTerm === "") {
        if (type) {
          if (categoryId) {
            let params = {
              page: 1,
              pageSize: columnSize,
              type: type,
              categoryId: categoryId,
            };
            const response = await dispatch(SearchAllService(params));
            navigate(`/services`, { state: { type, categoryId } });
          } else {
            let params = { page: 1, pageSize: columnSize, type: type };
            const response = await dispatch(SearchAllService(params));
            navigate(`/services`, { state: { type } });
          }
        } else {
          if (categoryId) {
            let params = {
              page: 1,
              pageSize: columnSize,
              categoryId: categoryId,
            };
            const response = await dispatch(SearchAllService(params));
            navigate(`/services`, { state: { type, categoryId } });
          } else {
            let params = { page: 1, pageSize: columnSize };
            const response = await dispatch(SearchAllService(params));
            navigate(`/services`, { state: { type } });
          }
        }
      } else {
        let params;
        if (type) {
          if (categoryId) {
            params = {
              page: 1,
              pageSize: columnSize,
              text: searchTerm,
              type: type,
              categoryId: categoryId,
            };
          } else {
            params = {
              page: 1,
              pageSize: columnSize,
              text: searchTerm,
              type: type,
            };
          }
        } else {
          if (categoryId) {
            params = {
              page: 1,
              pageSize: columnSize,
              text: searchTerm,
              categoryId: categoryId,
            };
          } else {
            params = { page: 1, pageSize: columnSize, text: searchTerm };
          }
        }
        try {
          const response = await dispatch(SearchAllService(params));
          let url;
          if (categoryId) {
            url = `/search/${"service"}/${searchTerm.replace(
              " ",
              "-"
            )}/${categoryId}`;
          } else {
            url = `/search/${"service"}/${searchTerm.replace(" ", "-")}`;
          }
          navigate(url, { state: { type } });
        } catch (error) {
          // console.log(error);
        }
      }
    } else {
      if (searchTerm === "") {
        const specialCriteria = { specialCriteria: ["products", "bids"] };
        let params = {
          page: 1,
          pageSize: columnSize,
          rawData: specialCriteria,
        };
        if (subCategoryId) {
          params = { ...params, subCategoryId };
        } else if (categoryId) {
          params = { ...params, categoryId };
        }
        const response = await dispatch(SearchProduct(params));
        navigate("/bids", { state: { subCategoryId, categoryId } });
      } else {
        const specialCriteria = { specialCriteria: ["products", "bids"] };
        let params = {
          page: 1,
          pageSize: columnSize,
          text: searchTerm,
          rawData: specialCriteria,
        };
        if (subCategoryId) {
          params = { ...params, subCategoryId };
        } else if (categoryId) {
          params = { ...params, categoryId };
        }
        try {
          const response = await dispatch(SearchProduct(params));
          let url = `/search/${"product"}/${searchTerm.replace(" ", "-")}`;
          if (categoryId) {
            if (subCategoryId) {
              url += `/${subCategoryId}/${categoryId}`;
            } else {
              url += `/${categoryId}`;
            }
          } else if (subCategoryId) {
            url += `/${subCategoryId}`;
          }
          navigate(url);
        } catch (error) {
          // console.log(error);
        }
      }
    }
  };
  const handleSearch = async (SearchText) => {
    if (first) {
      let params = { page: 1, pageSize: columnSize, text: SearchText };
      if (subCategoryId) {
        params = { ...params, subCategoryId };
      } else if (categoryId) {
        params = { ...params, categoryId };
      }
      try {
        const response = await dispatch(SearchProduct(params));
        let url = `/search/${"product"}/${SearchText.replace(" ", "-")}`;
        if (subCategoryId) {
          url += `/${categoryId}/${subCategoryId}`;
        } else if (categoryId) {
          url += `/${categoryId}`;
        }
        navigate(url);
      } catch (error) {
        // console.log(error);
      }
    } else if (second) {
      let params;
      if (type) {
        if (categoryId) {
          params = {
            page: 1,
            pageSize: columnSize,
            text: SearchText,
            type: type,
            categoryId: categoryId,
          };
        } else {
          params = {
            page: 1,
            pageSize: columnSize,
            text: SearchText,
            type: type,
          };
        }
      } else {
        if (categoryId) {
          params = {
            page: 1,
            pageSize: columnSize,
            text: SearchText,
            categoryId: categoryId,
          };
        } else {
          params = { page: 1, pageSize: columnSize, text: SearchText };
        }
      }
      try {
        const response = await dispatch(SearchAllService(params));
        let url = `/search/${"service"}/${SearchText.replace(" ", "-")}`;
        if (categoryId) {
          url += `/${categoryId}`;
        }
        navigate(url);
      } catch (error) {
        // console.log(error);
      }
    } else {
      const specialCriteria = { specialCriteria: ["products", "bids"] };
      let params = {
        page: 1,
        pageSize: columnSize,
        text: SearchText,
        rawData: specialCriteria,
      };
      if (subCategoryId) {
        params = { ...params, subCategoryId };
      } else if (categoryId) {
        params = { ...params, categoryId };
      }
      try {
        const response = await dispatch(SearchProduct(params));
        let url = `/search/${"product"}/${SearchText.replace(" ", "-")}`;
        if (categoryId) {
          if (subCategoryId) {
            url += `/${subCategoryId}/${categoryId}`;
          } else {
            url += `/${categoryId}`;
          }
        } else if (subCategoryId) {
          url += `/${subCategoryId}`;
        }
        navigate(url, { state: { isBid: true } });
      } catch (error) {
        // console.log(error);
      }
    }
  };
  const handleSearchSubmit = () => {
    if (searchTerm.trim() !== "") {
      setSelectedChips([...selectedChips, searchTerm.trim()]);
      setSearchTerm("");
    }
  };
  const handleChipClick = (chipIndex) => {
    setSelectedChips(selectedChips.filter((_, index) => index !== chipIndex));
  };
  return (
    <div className={styles.search_container}>
      <div className={styles.center}>
        <div className={styles.chips_container}>
          {!imported && (
            <>
              {selectedChips.map((chip, index) => (
                <div
                  className={`${styles.chip} ${styles.selected_chip}`}
                  key={index}
                >
                  {chip}
                  <button
                    className={styles.chip_remove_button}
                    onClick={() => handleChipClick(index)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </>
          )}
        </div>
        <div className={styles.input_container}>
          <AiOutlineSearch
            className={`${styles.search_icon} ${
              imported ? styles.search_iconImported : ""
            }`}
          />
          <input
            type="text"
            placeholder="Search"
            className={`${styles.search_input} ${
              darkMode ? styles.search_inputDark : ""
            } ${imported ? styles.search_inputImported : ""}`}
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          <button
            className={`${styles.search_button} ${
              darkMode ? styles.search_buttonDark : ""
            } ${imported ? styles.search_buttonImported : ""}`}
            onClick={handleSelect}
          >
            Search
          </button>
        </div>
      </div>
      {searchResults && searchResults.length > 0 && (
        <div
          className={`${styles.dropdown} ${
            darkMode ? styles.dropdownDark : ""
          } ${imported ? styles.dropdownImported : ""}`}
        >
          {searchResults.map((product) => (
            <div
              key={product._id}
              className={`${styles.result} ${
                darkMode ? styles.resultDark : ""
              }`}
              onClick={() => {
                //setSelectedChips([...selectedChips, product.title]);
                // setSearchTerm('');
                // setSearchResults([]);
                setName(product.title);
                //setPrice(product.pricing?.price)
                // setDescription(product.description)
                handleSearch(product.title);
              }}
            >
              {product.title} in{" "}
              <span
                className={`${styles.categoryTitleBlack} ${
                  darkMode ? styles.resultDark : ""
                }`}
              >
                {product.categoryTitle}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Search;
