import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Pagination } from 'react-bootstrap';
import styles from "./FeaturedProductPage.module.scss";
import Layout from "../../../../common/components/Layouts/Layout";
import CurrentPath from "../../../../common/components/CurrentPath/CurrentPath";
import Search from "../../../../common/components/Search/Search";
import Header from "../../../../common/components/Header/Header";
import Footer from "../../../../common/components/Footer/Footer";
import Cards from "../../../../common/components/Cards/Cards";
import { CgSortAz } from "react-icons/cg";
import { MdGridView } from "react-icons/md";
import { AiFillFilter, AiOutlineMenu } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { listMyProductsBySubCategory } from "../../../../store/actions/categoryAction";
import { PRODUCT_LIST_BY_SUBCATEGORY_RESET } from "../../../../store/constants/categoryConstants";
import Loader from "../../../../common/components/Loader/Loader";
import ErrorMessage from "../../../../common/components/ErrorMessage/ErrorMessage";
import { handleFilterIcon } from "../../../../store/actions/filterActions";
import { GetProductBySubCat, GetProductBySubCatSuccess } from "../../../../store/actions/productDetailsAction";
import { GetAllFeaturedProduct, GetAllFeaturedProductSuccess } from "../../../../store/actions/productDetailsAction";
import CardSkel from "../../../../common/components/Cards/CardSkel";
import { Helmet } from "react-helmet";
import FilterModal from "../../../../common/components/FilterModal/FilterModal";
const FeaturedProductPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const pageName = "Featured Product"; // Replace this with the actual page name
    const pageUrl = "/featured-product"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const [activePage, setActivePage] = useState(1);
  const [Pro, setPro] = useState([])
  const [sort, setSort] = useState("")
  const params = useParams();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const loading = useSelector(
    (state) => state.featuredproducts?.loading
  );
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const filterValue = useSelector((state) => state.filterValue?.convertedFilterValues)
  const featuredProduct = useSelector((state) => state.featuredproducts?.featuredproducts?.data?.products)
  const pageSize = useSelector((state) => state.featuredproducts?.featuredproducts?.data?.pageSize)
  const totalProducts = useSelector((state) => state.featuredproducts?.featuredproducts?.data?.productCount)
  const totalPages = Math.ceil(totalProducts / pageSize);
  const error = useSelector((state) => state.featuredproducts?.error)
  const columnSize = window.innerWidth < 768 ? 10 : 12;
  const [test, setTest] = useState([])
  useEffect(() => {
    if (first) {
      setTest(filterValue)
      dispatch(GetAllFeaturedProduct({ pageSize: columnSize, page: 1, rawData: test, sort: sort }))
        .then((data) => {
          dispatch(GetAllFeaturedProductSuccess(data));
          setPro(data.data?.products)
        })
        .catch((error) => {
          // // console.log(error);
        });
    } else {
      setTest(filterValue)
      const combined = {
        ...test,
        "status": [
          "confirmed"
        ],
        "specialCriteria": [
          "products",
          "bids"
        ]
      }
      dispatch(GetAllFeaturedProduct({ pageSize: columnSize, page: 1, rawData: combined, sort: sort }))
        .then((data) => {
          dispatch(GetAllFeaturedProductSuccess(data));
          setPro(data.data?.products)
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [dispatch, params.categoryId, sort, first]);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    if (sort) {
      if (first) {
        setTest(filterValue)
        dispatch(GetAllFeaturedProduct({ pageSize: columnSize, page: pageNumber, rawData: test, sort: sort }))
          .then((data) => {
            dispatch(GetAllFeaturedProductSuccess(data));
            setPro(data.data?.products)
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        setTest(filterValue)
        const combined = {
          ...test,
          "status": [
            "confirmed"
          ],
          "specialCriteria": [
            "products",
            "bids"
          ]
        }
        dispatch(GetAllFeaturedProduct({ pageSize: columnSize, page: pageNumber, rawData: combined, sort: sort }))
          .then((data) => {
            dispatch(GetAllFeaturedProductSuccess(data));
            setPro(data.data?.products)
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    } else {
      if (first) {
        setTest(filterValue)
        dispatch(GetAllFeaturedProduct({ pageSize: columnSize, page: pageNumber, rawData: test }))
          .then((data) => {
            dispatch(GetAllFeaturedProductSuccess(data));
            setPro(data.data?.products)
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        setTest(filterValue)
        const combined = {
          ...test,
          "status": [
            "confirmed"
          ],
          "specialCriteria": [
            "products",
            "bids"
          ]
        }
        dispatch(GetAllFeaturedProduct({ pageSize: columnSize, page: pageNumber, rawData: combined }))
          .then((data) => {
            dispatch(GetAllFeaturedProductSuccess(data));
            setPro(data.data?.products)
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    }
  };
  const handleButtonClick = () => {
    const nextPage = activePage + 1;
    handlePageChange(nextPage);
  };
  const handleCategoryFilter = () => {
    // console.log(featuredProduct)
  };
  const location = useLocation();
  const ProductArray = Pro
  const activateFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
  };
  const activateSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
  };
  const activateThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
  };
  const handleSortChange = async (event) => {
    const selectedValue = event.target.value;
    setTest(filterValue)
    if (selectedValue === 'A-Z') {
      setSort('az');
    } else if (selectedValue === 'Z-A') {
      setSort('za');
    } else if (selectedValue === 'Highest-to-lowest-price') {
      setSort('high');
    } else if (selectedValue === 'lowest-to-highest-price') {
      setSort('low');
    } else if (selectedValue === 'featured') {
      setSort('featured')
    } else if (selectedValue === 'default') {
      setSort("")
    }
  };
  const [view, setView] = useState(false);
  useEffect(() => {
    function handleResize() {
      setView(window.innerWidth < 445);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const savedValue = useSelector((state) => state.value.value);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleFilter = () => {
    if (screenWidth > 900) {
      dispatch({ type: 'SAVE_VALUE', payload: !savedValue });
      setIsOpen(!savedValue)
    } else {
      dispatch({ type: 'SAVE_VALUE', payload: false });
      setIsFilterOpen(true)
    }
  };
  useEffect(() => {
    return () => {
      dispatch({ type: 'SAVE_VALUE', payload: false });
    };
  }, []);
  // Handle visibilitychange event
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        dispatch({ type: 'SAVE_VALUE', payload: false });
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Featured Product</title>
        <meta name="Featured Product" content="Featured Product" />
        <meta
          name="keywords"
          content={`Featured Products, la7i27alak`}
        />
      </Helmet>
      <Layout footer={true} isFilterIcon={true} featured={true} onCategoryFilter={handleCategoryFilter} FeaturedProduct={first} FeaturedBid={second} sort={sort}>
        <section className={`${styles.category_section} ${darkMode ? styles.category_sectionDark : ''}`}>
          {/* <div className={styles.path}>
          <CurrentPath />
        </div> */}
          <div className={styles.search}>
            <Search featured={true} />
          </div>
          <div className={styles.title_container}>
            <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>
              Featured Product
            </h1>
            <div style={{ display: 'block' }}>
              <div className={`${styles.category__type} ${darkMode ? styles.category__typeDark : ""}`}>
                <button
                  className={`${styles.button} ${first ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
                  onClick={activateFirst}
                >
                  Product
                </button>
                {/* <button
                className={`${styles.button} ${second ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
                onClick={activateSecond}
              >
                Service
              </button> */}
                <button
                  className={`${styles.button} ${third ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
                  onClick={activateThird}
                >
                  Bid
                </button>
              </div>
            </div>
          </div>
          <div className={`${styles.result_container} ${darkMode ? styles.result_containerDark : ''}`}>
            <h1 className={`${styles.result_title} ${darkMode ? styles.result_titleDark : ''}`}>
              {totalProducts && totalProducts} Results Found
            </h1>
            <div className={styles.result_options}>
              <div onClick={handleFilter} className={styles.filter_container}>
                <AiFillFilter className={styles.filter_icon} />
                <button className={styles.filter_btn}>Filter</button>
              </div>
              <span>
                <CgSortAz className={`${styles.sort_icon} ${darkMode ? styles.sort_iconDark : ''}`} />{" "}
                <label className={`${styles.label} ${darkMode ? styles.labelDark : ''}`} htmlFor="sort">Sort:</label>
                <select className={`${styles.select} ${darkMode ? styles.selectDark : ''}`} name="sort" id="sort" onChange={handleSortChange}>
                  <option value="default" selected disabled={sort === "default"}>Sort</option>
                  <option value="A-Z">Alphabetically: A-Z</option>
                  <option value="Z-A">Alphabetically: Z-A</option>
                  <option value="Highest-to-lowest-price">Highest to Lowest Price</option>
                  <option value="lowest-to-highest-price">Lowest to Highest Price</option>
                </select>
              </span>
              <span className={`${styles.span} ${darkMode ? styles.spanDark : ''}`}
                onClick={() => setView(!view)}
              >
                View:
                {view ? (
                  <AiOutlineMenu className={styles.view_icon}
                  />
                ) : (
                  <MdGridView className={styles.view_icon} />
                )}
              </span>
            </div>
          </div>
          {loading ? (
            <div >
              <CardSkel />
            </div>
          ) : error ? (
            <div className="center">
              <ErrorMessage>{error.toString()}</ErrorMessage>
            </div>
          ) : (
            <div>
              {featuredProduct ? (
                <div className={`grid gap-4
                ${view ? 'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3'
                    :
                    'grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4'}`}>
                  {featuredProduct.filter((p) => p.status === 'confirmed').map((p) => (
                    <Cards
                      id={p._id}
                      key={p.id}
                      title={p.title}
                      price={p.pricing.price}
                      old={p.condition}
                      time={p.createdAt}
                      mainimage={p.imageUrls[0]}
                      featured={p.featured}
                      location={p.pickUpAddress?.region}
                      timer={p.timer?.enabled}
                      end={p.timer?.deadline}
                      discounted={p.discount?.enabled}
                      percentage={p.discount?.percentage}
                      mobile={view}
                    />
                  ))}
                </div>
              ) : (
                <CardSkel />
              )}
            </div>
          )}
          {totalProducts > 0 && (
            <Pagination style={{ marginBottom: '0', paddingBottom: '20px' }}>
              {activePage === 1 ? null : (
                <Pagination.Prev onClick={() => handlePageChange(activePage - 1)} disabled={activePage === 1} />
              )}
              {totalPages === 1 ? (
                <Pagination.Item linkStyle={{ background: '#0298a6', border: 'none' }} active>{activePage}</Pagination.Item>
              ) : (
                <>
                  {activePage === 1 ? null : (
                    <Pagination.Item onClick={() => handlePageChange(activePage - 1)} active={activePage === 1}>
                      {activePage - 1}
                    </Pagination.Item>
                  )}
                  <Pagination.Item linkStyle={{ background: '#0298a6', border: 'none' }} active>{activePage}</Pagination.Item>
                  {activePage === totalPages ? null : (
                    <Pagination.Item onClick={() => handlePageChange(activePage + 1)} active={activePage === totalPages}>
                      {activePage === totalPages ? activePage : activePage + 1}
                    </Pagination.Item>
                  )}
                </>
              )}
              {activePage === totalPages ? null : (
                <Pagination.Next onClick={() => handlePageChange(activePage + 1)} disabled={activePage === totalPages} />
              )}
            </Pagination>
          )}
        </section>
      </Layout>
      <FilterModal
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        featured={true}
        onCategoryFilter={handleCategoryFilter}
        sort={sort}
      />
    </>
  );
};
export default FeaturedProductPage;