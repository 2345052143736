import React from "react";
import styles from "./Card.module.scss";
import img from "../../../../../assests/svg/undraw_account_re_o7id 1.svg";
import { useSelector } from "react-redux";
const Card = ({text,title,step}) => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <div className={`${styles.card_container} ${darkMode? styles.card_containerDark: ''}`}>
      <div className={styles.wrapper}>
        <img src={img} alt="create your profile" className={styles.img} />
        <h1 className={`${styles.title} ${darkMode? styles.titleDark: ''}`}>{title}</h1>
        <p className={styles.text}>{text}</p>
        <div className={`${styles.circle} ${darkMode? styles.circleDark: ''}`}>
          <div className={`${styles.circle__wrapper} ${darkMode? styles.circle__wrapperDark: ''}`}>
            <h1 className={`${styles.circle__number} ${darkMode? styles.circle__numberDark: ''}`}>{step}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
