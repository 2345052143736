import React from "react";
import styles from "./UserContainer.module.scss";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { format, parseISO } from "date-fns";
import { useSelector } from "react-redux";
const UserContainer = ({ image, name, rate, date, id }) => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const parsedDate = parseISO(date);
  // Check if the parsedDate is valid (not NaN and not equal to Invalid Date)
  const isValidDate = !isNaN(parsedDate) && parsedDate.toString() !== 'Invalid Date';
  return (
    <div className={styles.user_container}>
      <img
        src={
          image
            ? image
            : "https://images.unsplash.com/photo-1521119989659-a83eee488004?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=423&q=80"
        }
        alt="profile"
        className={styles.img}
      />
      <div className={styles.info_container}>
        <h3 className={`${styles.name} ${darkMode ? styles.nameDark : ''}`}>
          {name}
          <span className={styles.rate}>
            {rate} <AiFillStar className={styles.icon} />
          </span>
        </h3>
        <p className={`${styles.date} ${darkMode ? styles.dateDark : ''}`}>
          {isValidDate ? (
            <p>Member since {format(parsedDate, "MMMM yyyy")}</p>
          ) : (
            <p> </p>
          )}
        </p>
        <Link
          to={name ? `/user-review/${name.replaceAll(" ", "-")}/${id}` : "/user-review/"}
          state={{ id }}
          className={styles.view_profile}
        >
          View profile
        </Link>
      </div>
    </div>
  );
};
export default UserContainer;
