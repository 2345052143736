import React, { useState, useEffect } from "react";
import styles from "./ProductCard.module.scss";
import BMW from "../../../../assests/images/bmw.png";
import { MdLocationPin, MdTimer } from "react-icons/md";
import { ReactComponent as FilledHeart } from "../../../../assests/svg/Filled Heart.svg";
import { ReactComponent as New } from "../../../../assests/svg/New wideCard.svg";
import { useDispatch, useSelector } from "react-redux";
import { timePosted } from "../../../../common/Utils";
import green from "../../../../assests/svg/green.svg";
import red from "../../../../assests/svg/red.svg";
import {
  AddWishlist,
  RemoveWishList,
  GetWishList,
  GetWishlistSuccess,
} from "../../../../store/actions/whishlistActions";
import { AiFillHeart, AiOutlineHeart, AiFillBell } from "react-icons/ai";
import Modal from "../../../../common/components/Modal/Modal";
import ConfirmModal from "../../../../common/components/ConfirmModal/ConfirmModal";
import Loader from "../../../../common/components/Loader/Loader";
import ErrorMessage from "../../../../common/components/ErrorMessage/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { GetServicesWishlist } from "../../../../store/actions/servicesActions";
import { RemoveServiceWishList } from "../../../../store/actions/servicesActions";
import { AddServiceToWishlist } from "../../../../store/actions/whishlistActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ProductCard = ({
  image,
  featured,
  title,
  price,
  time,
  old,
  id,
  location,
  imported = false,
  isService,
  newPrice,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userLogin);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
  const whishlistArray = useSelector((state) => state.whishlistArray);
  const { whishlistID, error, loading } = whishlistArray;
  const removeFromWishList = useSelector((state) => state.removeFromWishList);
  const {
    success,
    error: errorRemove,
    loading: loadingRemove,
  } = removeFromWishList;
  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const ServicesWishlist = useSelector(
    (state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList
  );
  const wishList = useSelector(
    (state) => state.Wishlist?.Wishlist?.data?.wishList
  );
  useEffect(() => {
    if (isService) {
      dispatch(GetServicesWishlist());
    } else {
      dispatch(GetWishList());
    }
  }, [dispatch, id, isService]);
  const isProductInWishlist =
    (wishList ?? []).some((product) => product._id === id) ||
    (ServicesWishlist ?? []).some((product) => product._id === id);
  const handleHeartClick = async (event) => {
    event.stopPropagation();
    if (loggedInUser) {
      if (isProductInWishlist) {
        setIsOpen(true);
      } else {
        if (isService) {
          if (id) {
            const response = await dispatch(AddServiceToWishlist(id));
            if (response) {
              dispatch(GetServicesWishlist());
            }
          }
        } else {
          const formData = new FormData();
          if (id) {
            formData.append("productId", id);
            const response = await dispatch(AddWishlist(formData));
            if (response) {
              dispatch(GetWishList());
            }
          }
        }
      }
    } else {
      toast.error("Please login first.");
      setIsOpenModal(true);
    }
  };
  const [loadingWishlist, setLoadingWishlist] = useState(false);

  const removeWishList = async (event) => {
    event.stopPropagation();
    setLoadingWishlist(true);
    if (isService) {
      const response = await dispatch(RemoveServiceWishList(id));
      if (response) {
        dispatch(GetServicesWishlist());
        setIsOpen(false);
      }
      setLoadingWishlist(false);
    } else {
      const formData = new FormData();
      formData.append("productId", id);
      setLoadingWishlist(true);
      const response = await dispatch(RemoveWishList(formData));
      if (response) {
        dispatch(GetWishList());
        setIsOpen(false);
      }
      setLoadingWishlist(false);
    }
  };
  let newTitle = title ? title.replaceAll(" ", "-") : "";
  return (
    <>
      <Modal
        open={isOpenModal}
        closeHandler={() => setIsOpenModal(false)}
        // page={"login"}
      />
      <div
        onClick={() =>
          navigate(`/${isService ? "service" : "product"}/${newTitle}/${id}`, {
            state: { id, isService },
          })
        }
        className={`${styles.card_section} ${
          darkMode ? styles.card_sectionDark : ""
        } ${imported ? styles.card_sectionImported : ""}`}
      >
        <div
          className={`${styles.image_container} ${
            imported ? styles.image_containerImported : ""
          }`}
        >
          <img
            src={image}
            alt="product"
            className={`${styles.img} ${imported ? styles.imgImported : ""}`}
            onClick={() =>
              navigate(
                `/${isService ? "service" : "product"}/${newTitle}/${id}`,
                { state: { id, isService } }
              )
            }
          />
          {featured && (
            <div
              className={`${styles.featured_container} ${
                imported ? styles.featured_containerImported : ""
              }`}
            >
              <p
                className={`${styles.featured_text} ${
                  imported ? styles.featured_textImported : ""
                }`}
              >
                Featured
              </p>
            </div>
          )}
        </div>
        <div
          className={`${styles.details_container} ${
            imported ? styles.details_containerImported : ""
          }`}
        >
          <p
            className={`${styles.price} ${darkMode ? styles.priceDark : ""} ${
              imported ? styles.priceImported : ""
            }`}
          >
            ${price.length > 10 ? price.slice(0, 10) + "..." : price}
          </p>
          <p
            className={`${styles.title} ${darkMode ? styles.titleDark : ""} ${
              imported ? styles.titleImported : ""
            }`}
          >
            {title.length > 20 ? title.slice(0, 20) + "..." : title}
          </p>
          {imported ? (
            <p
              className={`${styles.offerd} ${
                darkMode ? styles.offerdDark : ""
              } ${imported ? styles.locationImported : ""}`}
            >
              Offerd Price:${newPrice}
            </p>
          ) : null}
          <p
            className={`${styles.location} ${
              darkMode ? styles.locationDark : ""
            } ${imported ? styles.locationImported : ""}`}
          >
            <MdLocationPin className={styles.icon} />
            {location}
          </p>
          <p
            className={`${styles.time} ${darkMode ? styles.timeDark : ""}${
              imported ? styles.timeImported : ""
            }`}
          >
            <MdTimer className={styles.icon} />
            {timePosted(time)} ago
          </p>
          {isProductInWishlist ? (
            <AiFillHeart
              className={`${styles.heart_icon} ${
                imported ? styles.heart_iconImported : ""
              }`}
              onClick={handleHeartClick}
            />
          ) : (
            <AiOutlineHeart
              className={`${styles.heart_icon} ${
                imported ? styles.heart_iconImported : ""
              }`}
              onClick={handleHeartClick}
            />
          )}
          {old ? (
            <div
              className={`${styles.condition_container} ${
                imported ? styles.condition_containerImported : ""
              }`}
            >
              <p
                className={`${styles.condition} ${
                  imported ? styles.conditionImported : ""
                }`}
              >
                New
              </p>
              <img
                src={green}
                alt="new"
                className={`${styles.condition_image} ${
                  imported ? styles.condition_imageImported : ""
                }`}
              />
            </div>
          ) : (
            <div
              className={`${styles.condition_container} ${
                imported ? styles.condition_containerImported : ""
              }`}
            >
              <p
                className={`${styles.condition} ${
                  imported ? styles.conditionImported : ""
                }`}
              >
                Used
              </p>
              <img
                src={red}
                alt="used"
                className={`${styles.condition_image} ${
                  imported ? styles.condition_imageImported : ""
                }`}
              />
            </div>
          )}
        </div>
      </div>
      <ConfirmModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <h1 className={styles.confirm_title}>Confirmation</h1>
        <p className={styles.confirm_message}>
          Are you sure do want to remove this product from your wishlist?
        </p>
        {loadingWishlist ? (
          <div className="center">
            <Loader />{" "}
          </div>
        ) : errorRemove ? (
          <div className="center">
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        ) : (
          <div className={styles.buttons_container}>
            <button className={styles.cancel} onClick={() => setIsOpen(false)}>
              Cancel
            </button>
            <button className={styles.confirm} onClick={removeWishList}>
              Confirm
            </button>
          </div>
        )}
      </ConfirmModal>
    </>
  );
};
export default ProductCard;
