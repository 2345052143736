import {
    GET_AVERAGE_DEAL_RESULTS_SUCCESS,
    GET_AVERAGE_DEAL_RESULTS_FAIL
} from "../constants/adminDashboardConstants";
const initialState = {
    averageResults: [],
    error: null,
    loading: false,
};
export const GetAverageDealResult = (state = initialState, action) => {
    switch (action.type) {
        case GET_AVERAGE_DEAL_RESULTS_SUCCESS:
            return {
                ...state,
                averageResults: action.payload,
                error: null,
                loading: false,
            };
        case GET_AVERAGE_DEAL_RESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
