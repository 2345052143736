import React from "react";
import ReactDom from "react-dom";
import styles from "./styles.module.scss";
import { ReactComponent as Close } from "../../../assests/svg/close modal.svg";
const VerifyCode = ({ open, setOpen }) => {
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_styles}>
        <div className={styles.container}>
          <Close
            className={styles.close}
            onClick={() => {
              setOpen();
            }}
          />
          <h1 className={styles.logo}>LOGO</h1>
          <div className={styles.verification_container}>
            <h3 className={styles.verification_title}>Verification Code</h3>
            <p className={styles.verification_text}>
              A 4-digit verification code has been sent to your email
              <span className={styles.email}>ayrouthcharbel@gmail.com</span>
              
            </p>
            <div className={styles.code_container}>
              <input
                type="text"
                className={styles.code_input}
                placeholder="Enter 4-digit code"
                maxLength={4}
              />
              <button className={styles.send_btn}>Send Code</button>
            </div>
          </div>
          <button className={styles.confirm_btn}>Confirm</button>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default VerifyCode;
