import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import styles from "./FeaturedProductPage.module.scss";
import Layout from "../../../../common/components/Layouts/Layout";
import CurrentPath from "../../../../common/components/CurrentPath/CurrentPath";
import Search from "../../../../common/components/Search/Search";
import Header from "../../../../common/components/Header/Header";
import Footer from "../../../../common/components/Footer/Footer";
import Cards from "../../../../common/components/Cards/Cards";
import { CgSortAz } from "react-icons/cg";
import { MdGridView } from "react-icons/md";
import { AiFillFilter, AiOutlineFilter, AiOutlineMenu } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../../../common/components/ErrorMessage/ErrorMessage";
import CardSkel from "../../../../common/components/Cards/CardSkel";
import { Helmet } from "react-helmet";
import {
  GetProductBySubCat,
  SearchProduct,
} from "../../../../store/actions/productDetailsAction";
import { useNavigate } from "react-router-dom";
import { GetServicesWishlist } from "../../../../store/actions/servicesActions";
import { GetWishList } from "../../../../store/actions/whishlistActions";
import FilterModal from "../../../../common/components/FilterModal/FilterModal";
const AllProductPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const [Pro, setPro] = useState([]);
  const [sort, setSort] = useState("");
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const filterValue = useSelector(
    (state) => state.filterValue?.convertedFilterValues
  );
  const loadingSubCat = useSelector((state) => state.Subcat?.loading);
  const Subcat = useSelector((state) => state.Subcat?.Subcat?.data?.products);
  const subCatpageSize = useSelector(
    (state) => state.Subcat?.Subcat?.data?.pageSize
  );
  const subCatTotalProduct = useSelector(
    (state) => state.Subcat?.Subcat?.data?.productCount
  );
  const subCatTotalPage = Math.ceil(subCatTotalProduct / subCatpageSize);
  const params = useParams();
  const location = useLocation();
  const [filterValues, setFilterValues] = useState({});
  const search = useSelector(
    (state) => state.SearchComponent?.SearchComponent?.data?.products
  );
  const pageSize = useSelector(
    (state) => state.SearchComponent?.SearchComponent?.data?.pageSize
  );
  const totalProducts = useSelector(
    (state) => state.SearchComponent?.SearchComponent?.data?.productCount
  );
  const totalPages = Math.ceil(totalProducts / pageSize);
  const test = useSelector(
    (state) => state.SearchComponent?.SearchComponent?.data
  );
  const columnSize = window.innerWidth < 768 ? 10 : 12;
  useEffect(() => {
    dispatch({ type: "CLEAR_BREADCRUMBS" });
    const pageName = "All products";
    const pageUrl = "/products";
    dispatch({
      type: "ADD_BREADCRUMB",
      payload: { name: pageName, url: pageUrl },
    });
  }, []);
  const handleFilterInputChange = (event, key) => {
    const { value } = event.target;
    setFilterValues((prevValues) => ({ ...prevValues, [key]: value }));
  };
  const handleCategoryFilter = () => {
    // console.log("filter");
  };
  const handleFilterValuesUpdate = (newFilterValues) => {
    setFilterValues(newFilterValues);
  };
  const convertedFilterValues = Object.entries(filterValues).reduce(
    (acc, [key, value]) => {
      let parsedValue = value;
      if (!isNaN(parseInt(value))) {
        parsedValue = parseInt(value);
      } else {
        try {
          parsedValue = JSON.parse(value);
        } catch {
          // If the value is not a valid JSON string, leave it as is
        }
      }
      return { ...acc, [key]: parsedValue };
    },
    {}
  );
  const ProductArray = Pro && Pro.products;
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const { products, error } = Pro;
  const loading = useSelector((state) => state.SearchComponent?.loading);
  // const loading=false
  const activateFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
  };
  const activateSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
  };
  const activateThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
  };
  const handleSortChange = async (event) => {
    const selectedValue = event.target.value;
    // Save the selected value in the state variable "setSort"
    setFilter(filterValue);
    if (selectedValue === "A-Z") {
      setSort("az");
    } else if (selectedValue === "Z-A") {
      setSort("za");
    } else if (selectedValue === "Highest-to-lowest-price") {
      setSort("high");
    } else if (selectedValue === "lowest-to-highest-price") {
      setSort("low"); // Handle other cases or the default value
    } else if (selectedValue === "featured") {
      setSort("featured");
    } else if (selectedValue === "default") {
      setSort("");
    }
  };
  const [filter, setFilter] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setFilter(filterValue);
        let parms = {
          page: activePage,
          pageSize: columnSize,
          sort: sort,
          rawData: filter,
        };
        if (location.state?.subCategoryId) {
          parms = { ...parms, subCategoryId: location.state?.subCategoryId };
        } else if (location.state?.categoryId) {
          parms = { ...parms, categoryId: location.state?.categoryId };
        }
        if (location.state?.categoryId && location.state?.subCategoryId) {
          dispatch(
            GetProductBySubCat({
              page: activePage,
              pageSize: columnSize,
              sort: sort,
              rawData: filter,
              SubCatId: location.state?.subCategoryId,
            })
          );
        } else {
          const response = await dispatch(SearchProduct(parms));
        }
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, [sort]);
  const [view, setView] = useState(false);
  useEffect(() => {
    function handleResize() {
      setView(window.innerWidth < 445);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handlePageChange = async (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setActivePage(pageNumber);
    }

    if (sort) {
      setFilter(filterValue);
      let parms = {
        page: pageNumber,
        pageSize: columnSize,
        sort: sort,
        rawData: filter,
      };
      if (location.state?.subCategoryId) {
        parms = { ...parms, subCategoryId: location.state?.subCategoryId };
      } else {
        parms = { ...parms, categoryId: location.state?.categoryId };
      }
      try {
        if (location.state?.subCategoryId) {
          setFilter(filterValue);
          dispatch(
            GetProductBySubCat({
              page: activePage,
              pageSize: columnSize,
              sort: sort,
              rawData: filter,
              SubCatId: location.state?.subCategoryId,
            })
          );
        } else {
          const response = await dispatch(SearchProduct(parms));
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      setFilter(filterValue);
      let parms = { page: pageNumber, pageSize: columnSize, rawData: filter };
      if (location.state?.subCategoryId) {
        parms = { ...parms, subCategoryId: location.state?.subCategoryId };
      } else {
        parms = { ...parms, categoryId: location.state?.categoryId };
      }
      try {
        if (location.state?.subCategoryId) {
          setFilter(filterValue);
          dispatch(
            GetProductBySubCat({
              page: activePage,
              pageSize: columnSize,
              rawData: filter,
              SubCatId: location.state?.subCategoryId,
            })
          );
        } else {
          const response = await dispatch(SearchProduct(parms));
        }
      } catch (error) {
        // console.log(error);
      }
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const savedValue = useSelector((state) => state.value.value);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleFilter = () => {
    if (screenWidth > 900) {
      dispatch({ type: "SAVE_VALUE", payload: !savedValue });
      setIsOpen(!savedValue);
    } else {
      dispatch({ type: "SAVE_VALUE", payload: false });
      setIsFilterOpen(true);
    }
  };
  useEffect(() => {
    return () => {
      dispatch({ type: "SAVE_VALUE", payload: false });
    };
  }, []);
  // Handle visibilitychange event
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        dispatch({ type: "SAVE_VALUE", payload: false });
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  const ServicesWishlist = useSelector(
    (state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList
  );
  const wishList = useSelector(
    (state) => state.Wishlist?.Wishlist?.data?.wishList
  );
  useEffect(() => {
    dispatch(GetServicesWishlist());
    dispatch(GetWishList());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>Search Results</title>
        <meta name={"Search Page"} content="This is Search Page" />
        <meta
          name="keywords"
          content={`All products La7i27alak, All products, La7i27alak`}
        />
      </Helmet>
      <Layout
        footer={true}
        isFilterIcon={true}
        handleFilterValuesUpdate={handleFilterValuesUpdate}
        handleFilterInputChange={handleFilterInputChange}
        allProducts={true}
        onCategoryFilter={handleCategoryFilter}
        sort={sort}
        SubCategoryId={location.state?.subCategoryId}
        CategoryId={location.state?.categoryId}
      >
        <section
          className={`${styles.category_section} ${
            darkMode ? styles.category_sectionDark : ""
          }`}
        >
          <div className={styles.result_container}>
            <h1
              className={`${styles.result_title} ${
                darkMode ? styles.result_titleDark : ""
              }`}
            >
              {location.state?.subCategoryId ? (
                <span>
                  {subCatTotalProduct && subCatTotalProduct} Results Found
                </span>
              ) : (
                <span>{totalProducts && totalProducts} Results Found</span>
              )}
            </h1>
            <div className={styles.result_options}>
              <div onClick={handleFilter} className={styles.filter_container}>
                <AiFillFilter className={styles.filter_icon} />
                <button className={styles.filter_btn}>Filter</button>
              </div>
              <span>
                <CgSortAz
                  className={`${styles.sort_icon} ${
                    darkMode ? styles.sort_iconDark : ""
                  }`}
                />{" "}
                <label
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  htmlFor="sort"
                >
                  Sort:
                </label>
                <select
                  className={`${styles.select} ${
                    darkMode ? styles.selectDark : ""
                  }`}
                  name="sort"
                  id="sort"
                  onChange={handleSortChange}
                >
                  <option
                    value="default"
                    selected
                    disabled={sort === "default"}
                  >
                    Sort
                  </option>
                  <option value="featured">Featured</option>
                  <option value="A-Z">Alphabetically: A-Z</option>
                  <option value="Z-A">Alphabetically: Z-A</option>
                  <option value="Highest-to-lowest-price">
                    Highest to Lowest Price
                  </option>
                  <option value="lowest-to-highest-price">
                    Lowest to Highest Price
                  </option>
                </select>
              </span>
              <span
                className={`${styles.span} ${darkMode ? styles.spanDark : ""}`}
                onClick={() => setView(!view)}
              >
                View:
                {view ? (
                  <AiOutlineMenu className={styles.view_icon} />
                ) : (
                  <MdGridView className={styles.view_icon} />
                )}
              </span>
            </div>
          </div>
          {location.state?.subCategoryId ? (
            loadingSubCat ? (
              <div>
                {/* <Loader /> */}
                <CardSkel />
              </div>
            ) : error ? (
              <div className="center">
                <ErrorMessage>{error}</ErrorMessage>
              </div>
            ) : (
              <div
                className={`grid gap-4
            ${
              view
                ? "grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3"
                : "grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4"
            }`}
              >
                {Subcat && Subcat.length > 0 ? (
                  Subcat.filter((p) => p.status === "confirmed").map((p) => (
                    <Cards
                      id={p._id}
                      key={p._id}
                      title={p.title}
                      price={p.pricing.price}
                      old={p.condition}
                      time={p.createdAt}
                      mainimage={p.imageUrls[0]}
                      featured={p.featured}
                      location={p.pickUpAddress?.region}
                      timer={p.timer?.enabled}
                      end={p.timer?.deadline}
                      discounted={p.discount?.enabled}
                      percentage={p.discount?.percentage}
                      mobile={view}
                      isService={p.isService}
                      ServicesWishlist={ServicesWishlist} // Pass ServicesWishlist as prop
                      wishList={wishList} // Pass wishList as prop
                      sold={p && p.status === "sold"}
                    />
                  ))
                ) : (
                  <div className={styles.noresultsearch}>
                    <h2>No Results Found</h2>
                  </div>
                )}
              </div>
            )
          ) : loading ? (
            <div>
              <CardSkel />
            </div>
          ) : error ? (
            <div className="center">
              <ErrorMessage>{error}</ErrorMessage>
            </div>
          ) : (
            <div
              className={`grid gap-4
            ${
              view
                ? "grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3"
                : "grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4"
            }`}
            >
              {search && search.length > 0 ? (
                search
                  .filter((p) => p.status === "confirmed")
                  .map((p) => (
                    <Cards
                      id={p._id}
                      key={p._id}
                      title={p.title}
                      price={p.pricing.price}
                      old={p.condition}
                      time={p.createdAt}
                      mainimage={p.imageUrls[0]}
                      featured={p.featured}
                      location={p.pickUpAddress?.region}
                      timer={p.timer?.enabled}
                      end={p.timer?.deadline}
                      discounted={p.discount?.enabled}
                      percentage={p.discount?.percentage}
                      mobile={view}
                      isService={p.isService}
                      ServicesWishlist={ServicesWishlist} // Pass ServicesWishlist as prop
                      wishList={wishList} // Pass wishList as prop
                    />
                  ))
              ) : (
                <div className={styles.noresultsearch}>
                  <h2>No Results Found</h2>
                </div>
              )}
            </div>
          )}
          {location.state?.subCategoryId
            ? subCatTotalProduct > 0 && (
                <Pagination
                  style={{ marginBottom: "0", paddingBottom: "20px" }}
                >
                  {activePage === 1 ? null : (
                    <Pagination.Prev
                      onClick={() => handlePageChange(activePage - 1)}
                      disabled={activePage === 1}
                    />
                  )}
                  {subCatTotalPage === 1 ? (
                    <Pagination.Item
                      linkStyle={{ background: "#0298a6", border: "none" }}
                      active
                    >
                      {activePage}
                    </Pagination.Item>
                  ) : (
                    <>
                      {activePage === 1 ? null : (
                        <Pagination.Item
                          onClick={() => handlePageChange(activePage - 1)}
                          active={activePage === 1}
                        >
                          {activePage - 1}
                        </Pagination.Item>
                      )}
                      <Pagination.Item
                        linkStyle={{ background: "#0298a6", border: "none" }}
                        active
                      >
                        {activePage}
                      </Pagination.Item>
                      {activePage === subCatTotalPage ? null : (
                        <Pagination.Item
                          onClick={() => handlePageChange(activePage + 1)}
                          active={activePage === subCatTotalPage}
                        >
                          {activePage === subCatTotalPage
                            ? activePage
                            : activePage + 1}
                        </Pagination.Item>
                      )}
                    </>
                  )}
                  {activePage === subCatTotalPage ? null : (
                    <Pagination.Next
                      onClick={() => handlePageChange(activePage + 1)}
                      disabled={activePage === subCatTotalPage}
                    />
                  )}
                </Pagination>
              )
            : totalProducts > 0 && (
                <Pagination
                  style={{ marginBottom: "0", paddingBottom: "20px" }}
                >
                  {activePage === 1 ? null : (
                    <Pagination.Prev
                      onClick={() => handlePageChange(activePage - 1)}
                      disabled={activePage === 1}
                    />
                  )}
                  {totalPages === 1 ? (
                    <Pagination.Item
                      linkStyle={{ background: "#0298a6", border: "none" }}
                      active
                    >
                      {activePage}
                    </Pagination.Item>
                  ) : (
                    <>
                      {activePage === 1 ? null : (
                        <Pagination.Item
                          onClick={() => handlePageChange(activePage - 1)}
                          active={activePage === 1}
                        >
                          {activePage - 1}
                        </Pagination.Item>
                      )}
                      <Pagination.Item
                        linkStyle={{ background: "#0298a6", border: "none" }}
                        active
                      >
                        {activePage}
                      </Pagination.Item>
                      {activePage === totalPages ? null : (
                        <Pagination.Item
                          onClick={() => handlePageChange(activePage + 1)}
                          active={activePage === totalPages}
                        >
                          {activePage === totalPages
                            ? activePage
                            : activePage + 1}
                        </Pagination.Item>
                      )}
                    </>
                  )}
                  {activePage === totalPages ? null : (
                    <Pagination.Next
                      onClick={() => handlePageChange(activePage + 1)}
                      disabled={activePage === totalPages}
                    />
                  )}
                </Pagination>
              )}
        </section>
      </Layout>
      <FilterModal
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        allProducts={true}
        onCategoryFilter={handleCategoryFilter}
        sort={sort}
        SubCategoryId={location.state?.subCategoryId}
        CategoryId={location.state?.categoryId}
      />
    </>
  );
};
export default AllProductPage;
