import {
    GET_ALL_USERS_FAIL,
    GET_ALL_USERS_SUCCESS
} from "../constants/adminDashboardConstants";
const initialState = {
    AllUser: [],
    error: null,
    loading: false,
};
export const GetAllUsers= (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                AllUser: action.payload,
                error: null,
                loading: false,
            };
        case GET_ALL_USERS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
