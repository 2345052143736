import React, { useState } from "react";
import styles from "./index.module.scss";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as Back } from "../../../../assests/svg/back.svg";
import { BsBuilding, BsUnlock, BsCreditCard } from "react-icons/bs";
import StepOne from "./StepOne/StepOne";
import StepTwo from "./StepTwo/StepTwo";
import StepThree from "./StepThree/StepThree";
import { useSelector } from "react-redux";
import { ReactComponent as BackDark } from "../../../../assests/svg/arrowleftDark.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import { loadStripe } from "@stripe/stripe-js";
const RegisterCompany = ({ setStep, closeHandler }) => {
  const [page, setPage] = useState(0);
  const [fileUploaded, setFileUploaded] = useState("");
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const userRegister = useSelector((state) => state.userRegister);
  const { userData } = userRegister;
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    companyName: "",
    website: "",
    industry: "",
    companySize: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    cardName: "",
    cardNumber: "",
    expiryDate: "",
    ccv: "",
    terms: false,
  });
  const makeRequest = (formData) => {
    // console.log("Form Submitted", formData);
  };
  const handleChanges = (newData, final = false) => {
    setFormData((prev) => ({ ...prev, ...newData }));
    if (final) {
      makeRequest(newData);
    }
  };
  const PageDisplay = () => {
    if (page === 0) {
      return (
        <StepOne
        setStep={setStep}
        setPage={setPage}
        formData={formData}
        setImage={setImage} 
        setFormData={setFormData}
        fileUploaded={fileUploaded}
        setFileUploaded={setFileUploaded}
      />
        
       
       
      );
    } else if (page === 1) {
      return (
        
        <StepTwo
          setPage={setPage}
          formData={formData}
          setFormData={setFormData}
          image={image} 
          setImage={setImage}
          fileUploaded={fileUploaded}
        />
       
      );
    } else if (page === 2) {
      return (
        <StepThree
        setStep={setStep}
        formData={formData}
        setFormData={setFormData}
        closeHandler={closeHandler}
      />
      );
    }
  };
  const stepHandler = () => {
    if (page === 0) {
      setStep("first");
    } else if (page === 1 && userData === null) {
      setPage(0);
    } else if (page === 1 && userData !== null) {
      return;
    } else if (page === 2) {
      // setPage(1);
      return;
    }
  };
  return (
    <div     
    className={`${styles.register_container} ${darkMode ? styles.register_containerDark : ''}`}
    >
     {darkMode?
       (<CloseDark
        className={`${styles.close}`} 
        onClick={() => {
              closeHandler();
              setStep("login");
            }}
          />):(<Close
            className={`${styles.close}`} 
            onClick={() => {
                  closeHandler();
                  setStep("login");
                }}
              />)}
              {darkMode?(<BackDark 
          className={`${styles.back}`}
       onClick={stepHandler} />):(<Back 
        className={`${styles.back}`}
     onClick={stepHandler} />)}
      
      <div className={`${styles.progress_container} ${darkMode ? styles.progress_containerDark : ''}`} 
      >
        <div className={`${styles.progress} ${darkMode ? styles.progressDark : ''}`}></div>
        <div
         className={`${styles.circle} ${styles.active} ${darkMode ? styles.circleDark : ''}`}
         >
          <BsBuilding className={styles.icon} />
          <p 
           className={`${styles.title} ${styles.active} ${darkMode ? styles.titleDark : ''}`}>Identity</p>
        </div>
        <div className={`${styles.circle}  ${page >= 1 ? styles.active : ""} ${darkMode ? styles.circleDark : ''}`}>
          <BsUnlock className={styles.icon} />
          <p className={`${styles.title} ${darkMode ? styles.titleDark : ''} ${page >= 1 ? styles.active : ""}`}>
            Account
          </p>
        </div>
        <div className={`${styles.circle}   ${page === 2 ? styles.active : ""} ${darkMode ? styles.circleDark : ''}`}>
          <BsCreditCard className={styles.icon} />
          <p className={`${styles.title} ${darkMode ? styles.titleDark : ''} ${page >= 2 ? styles.active : ""}`}>
            Payment
          </p>
        </div>
      </div>
      {PageDisplay()}
    </div>
  );
};
export default RegisterCompany;
