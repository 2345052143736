import React from "react";
import styles from "./PostSuccess.module.scss";
import ReactDom from "react-dom";
import img from "../../../assests/svg/post success.svg";
import { useNavigate } from "react-router-dom";
const PostSuccess = ({ open, onClose, update = false, seriveof = false, servicerq = false }) => {
  const navigate = useNavigate();
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_styles}>
        <img src={img} alt="post sucess" className={styles.img} />
        {seriveof ? (
          <>
            <h1 className={styles.title}> Your service is under review</h1>
            <button
              className={styles.done}
              onClick={() => navigate("/user/my-offers/")}
            >
              Done
            </button>
          </>
        ) : servicerq ? (
          <>
            <h1 className={styles.title}>Your request is under review</h1>
            <button className={styles.done} onClick={() => navigate("/user/my-offers/")}>
              Done
            </button>
          </>
        ) : update ? (
          <><h1 className={styles.title}> Updated Successfully</h1>
            <button
              className={styles.done}
              onClick={() => navigate("/user/my-offers/")}
            >
              Done
            </button>
          </>
        )
          : (<><h1 className={styles.title}>Your post is under review.</h1>
            <button
              className={styles.done}
              onClick={() => navigate("/user/my-offers")}
            >
              Done
            </button>
          </>)}
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default PostSuccess;
