import React, { useState, useRef, useEffect } from "react";
import styles from "./AdminChatInput.module.scss";
import { AiOutlineCamera, AiOutlineAudio, AiOutlineSend } from "react-icons/ai";
import { MdAttachFile } from "react-icons/md";
import { BiNoEntry } from "react-icons/bi";
import { GetUser } from "../../../../store/actions/userActions";
import { get, ref, update, runTransaction, push, set, increment } from 'firebase/database';
import { useDispatch, useSelector } from "react-redux";
import { db, messaging } from "../../../../firebase";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { getToken } from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
const AdminChatInput = ({ addMessage, selectedUser, ConvoRoomID, specificUserId }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [currentUser, setCurrentUser] = useState([]);
  const [unread, setUnread] = useState(0);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [fcmToken, setFcmToken] = useState('');
  const [notification, setNotification] = useState(null);
  const blocked = false;
  const handleFileSelect = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    dispatch(GetUser(specificUserId))
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        // // console.log(error);
      });
  }, [dispatch, specificUserId]);
  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(event.target.files[0]);
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setInputValue(capitalizedValue);
  };
  useEffect(() => {
    if (currentUser) {
      checkIfPathExists();
    }
  }, [currentUser]);
  const checkIfPathExists = async () => {
    const databaseRef = ref(db, `chatlist/${selectedUser.id}/${specificUserId}`);
    let roomId = ConvoRoomID;
    try {
      const snapshot = await get(databaseRef);
      const pathExists = snapshot.exists();
      if (!pathExists) {
        // The path doesn't exist, perform your desired actions here
        let myData = {
          roomId,
          id: specificUserId,
          name: currentUser.username,
          image: currentUser.imageUrl,
          email: currentUser.email,
          phonenumber: currentUser.phone,
          lastMsg: '',
          unread: 0,
          buyer: true
        };
        update(ref(db, '/chatlist/' + selectedUser.id + '/' + specificUserId), myData);
      } else {
        // The path exists, handle it accordingly
      }
    } catch (error) {
      console.error('Error checking path:', error);
    }
  };

  const handleSendMessage = () => {
    if (currentUser) {
      checkIfPathExists();
      if (inputValue.trim() !== "") {
        const newMessage = {
          to: selectedUser.id,
          from: specificUserId,
          roomId: ConvoRoomID,
          message: inputValue,
          sendTime: moment().format(),
          timestamp: moment().format('hh:mm'),
          msgType: 'text',
        };
        const newReference = push(ref(db, '/messages/' + ConvoRoomID));
        newMessage.id = newReference.key;
        set(newReference, newMessage)
          .then(() => {
            let chatListupdate = {
              lastMsg: inputValue,
              sendTime: newMessage.sendTime,
              timestamp: moment(newMessage.sendTime).format('hh:mm'),
            };
            const chatListRef = ref(
              db,
              '/chatlist/' +
              selectedUser.id +
              '/' +
              specificUserId +
              '/unread'
            );
            get(chatListRef)
              .then((snapshot) => {
                const currentUnreadCount = snapshot.val() || 0; // Use 0 as the default value if unread count is null
                // Increment the unread count by 1
                const updatedUnreadCount = currentUnreadCount + 1;
                // Update the unread count in the database
                set(chatListRef, updatedUnreadCount)
                  .then(() => {
                    // console.log('Updated unread count:', updatedUnreadCount);
                  })
                  .catch((error) => {
                    console.error('Error updating unread count:', error);
                  });
              })
              .catch((error) => {
                console.error('Error retrieving unread count:', error);
              });
            const chatListRef1 = ref(
              db,
              '/chatlist/' + selectedUser.id + '/' + specificUserId
            );
            update(chatListRef1, chatListupdate)
              .then(() => {
                // console.log('Data updated.');
              })
              .catch((error) => {
                console.error('Error updating data:', error);
              });
            const chatListRef2 = ref(
              db,
              '/chatlist/' + specificUserId + '/' + selectedUser.id
            );
            update(chatListRef2, chatListupdate)
              .then(() => {
                // console.log('Data updated.');
              })
              .catch((error) => {
                console.error('Error updating data:', error);
              }).then(() => {
                // Fetch recipient's FCM token from the database
                const recipientFcmTokenRef = ref(db, '/fcmTokens/' + selectedUser.id);
                get(recipientFcmTokenRef)
                  .then((snapshot) => {
                    const fcmToken = snapshot.val();
                    setFcmToken(fcmToken);
                    // Send notification
                    const notification = {
                      token: fcmToken,
                      notification: {
                        title: 'New Message',
                        body: inputValue,
                        icon: '/firebase-logo.png',
                      }
                    };
                    getToken(messaging)
                      .then(() => {
                        return getMessaging().send(notification);
                      })
                      .then(() => {
                        // console.log('Notification sent successfully!');
                      })
                      .catch((error) => {
                        // console.log('Error sending notification:', error);
                      });
                  })
                  .catch((error) => {
                    // console.log('Error fetching recipient FCM token:', error);
                  });
              });
          })
          .catch((error) => {
            // console.log('Error sending message:', error);
          });
        addMessage(newMessage);
        setInputValue("");
      }
    }
  };
  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  }
  const renderIcons = () => {
    if (inputValue.length > 0 || file !== null) {
      // Render send icon when input has value
      return (
        <div className={darkMode ? "iconinDark" : "iconin"}>
          <AiOutlineSend onClick={handleSendMessage} />
        </div>
      );
    }
    // Render camera and audio icons when input is empty
    return (
      <>
        <div className={darkMode ? "iconinDark" : "iconin"}>
          <AiOutlineCamera onClick={openCamera} />
        </div>
        {/* <div className={darkMode ? "iconinDark" : "iconin"}>
          <AiOutlineAudio />
        </div> */}
      </>
    );
  };
  return (
    <div className={darkMode ? "chatInputDark" : "chatInput"}>
      {blocked ? (
        <div className="blockedContent">
          <p className="blktxt">Conversation with this user is blocked</p>
          <button className="blkbtn">
            <BiNoEntry className="blkic" />
            Unblock
          </button>
        </div>
      ) : (
        <>
          <div className="inputContainerin">
            <div className={darkMode ? "iconinDark" : "iconin"}>
              <MdAttachFile onClick={handleFileSelect} />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
            </div>
            <input
              type="text"
              className={darkMode ? "inputFieldDark" : "inputField"}
              placeholder="Type Your Message Here"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            {renderIcons()}
          </div>
        </>
      )}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={true} />
    </div>
  );
};
export default AdminChatInput;
