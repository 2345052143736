import React, { useState,useRef } from "react";
import styles from "../../../../../pages/AddCardPage/AddCard.module.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from '../../../../../pages/AddCardPage/Payment'
const stripe = loadStripe("pk_test_51Mm61YLKNx7usOBBJ1lxEGb0cxNzAckqZikDB3pR2qxiFjN1FumdNJnKaI7B8I86BelQAb0POOf6jMiuJkGmTR3n00rQz55R0n");
const StepThree = ({ setStep, formData, setFormData, onResult,closeHandler }) => {
  
  return (
    <Elements stripe={stripe}>
    <PaymentForm newp={false}/>
    </Elements>
  
  );
}
export default StepThree;
