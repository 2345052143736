import {
  COMPANY_INDUSTRY_LIST_FAIL,
  COMPANY_INDUSTRY_LIST_REQUEST,
  COMPANY_INDUSTRY_LIST_SUCCESS,
  COMPANY_SIZE_LIST_FAIL,
  COMPANY_SIZE_LIST_REQUEST,
  COMPANY_SIZE_LIST_SUCCESS,
} from "../constants/companyConstants";
export const companySizeListReducer = (
  state = { loading: false, error: null, size: [] },
  action
) => {
  switch (action.type) {
    case COMPANY_SIZE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COMPANY_SIZE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        size: action.payload,
      };
    case COMPANY_SIZE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const companyIndustryListReducer = (
  state = { loading: false, error: null, industries: [] },
  action
) => {
  switch (action.type) {
    case COMPANY_INDUSTRY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case COMPANY_INDUSTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        industries: action.payload,
      };
    case COMPANY_INDUSTRY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
