import React, { useState } from "react";
// import styles from "../../../common/components/SellModal/SellModal.module.scss";
import styles from './previewModal.module.scss'
import ReactDom from "react-dom";
import { ReactComponent as Close } from "../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../assests/svg/canceldark.svg";
import Product from "../../../assests/svg/product.svg";
import cancelDark from "../../../assests/svg/product.svg";
import ServiceImg from "../../../assests/svg/service.svg";
import ServiceImgDark from "../../../assests/svg/serviceDark.svg";
import { BiMessageRounded } from "react-icons/bi";
import { ReactComponent as Phone } from "../../../assests/svg/phone white.svg";
import { ReactComponent as WhatspApp } from "../../../assests/svg/whatsapp white.svg";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../../common/components/ErrorMessage/ErrorMessage";
import Loader from "../../../common/components/Loader/Loader";
import ImageComponent from "../../ProductDescriptionPage/components/ImageComponent/ImageComponent";
import { MdLocationPin, MdTimer } from "react-icons/md";
import Layout from "../../../common/components/Layouts/Layout";
import Bid from "../../ProductDescriptionPage/components/Bid/Bid";
import Negotiate from "../../ProductDescriptionPage/components/Negotiate/Negotiate";
import UserContainer from "../../ProductDescriptionPage/components/UserContainer/UserContainer";
import ImageComponentForPreview from "./ImageComponentForPreview";
import ImagePreview from "./ImagePreview";
const PreviewModal = ({ open, onClose, images, description, condition, fields, pricing, title, Enabled, Negotiable, selectedAddress, defaultAddress, createProduct, quantity }) => {
  const getProductDetails = useSelector((state) => state.getProductDetails);
  const { productDetails, error, loading } = getProductDetails;
  const darkMode = useSelector((state) => state.darkMode.darkMode);
   if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
        <div className={styles.container}>
          {darkMode ? (
            <CloseDark className={styles.close} onClick={onClose} />
          ) : (
            <Close className={styles.close} onClick={onClose} />
          )}
          {error ? (
            <div className={styles.center}>
              <ErrorMessage>{error}</ErrorMessage>
            </div>
          ) : loading ? (
            <div className={styles.center}>
              <Loader />
            </div>
          ) : (
            <section className={styles.section}>
              <div className={styles.left_side}>
                <div className={styles.container}>
                  {createProduct ? (
                    <ImagePreview images={images} images1={images} />
                  ) : (
                    <ImageComponentForPreview images={images} images1={images} />
                  )}
                </div>
                <div className={styles.description_container}>
                  <h3 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Description</h3>
                  <p className={`${styles.text} ${darkMode ? styles.textDark : ''}`}>
                    {description}
                  </p>
                </div>
                <div className={`${styles.table_container} ${darkMode ? styles.table_containerDark : ''}`}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <span className={`${styles.left} ${darkMode ? styles.leftDark : ''}`}>Condition</span>
                          <span className={`${styles.right} ${darkMode ? styles.rightDark : ''}`}>{condition ? 'Used' : 'New'}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className={`${styles.left} ${darkMode ? styles.leftDark : ''}`}>Quantity</span>
                          <span className={`${styles.right} ${darkMode ? styles.rightDark : ''}`}>
                            {quantity}
                          </span>
                        </td>
                      </tr>
                      {fields.map((field) => (
                        <tr>
                          <td>
                            <span className={`${styles.left} ${darkMode ? styles.leftDark : ''}`}>{field.title}</span>
                            <span className={styles.right}>
                              {typeof field.value === "number"
                                ? field.possibleValues[field.value]
                                : field.value}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={`${styles.right_side} ${darkMode ? styles.right_sideDark : ''}`}>
                <div className={styles.wrapper}>
                  <h1 className={`${styles.price} ${darkMode ? styles.priceDark : ''}`}>{`USD ${pricing.price}`}</h1>
                  <p className={`${styles.description} ${darkMode ? styles.descriptionDark : ''}`}>
                    {title}
                  </p>
                  <p className={`${styles.location} ${darkMode ? styles.locationDark : ''}`}>
                    <MdLocationPin className={styles.icon} />
                    {selectedAddress && selectedAddress ? (
                      selectedAddress.region
                    ) : (
                      defaultAddress.region
                    )}
                  </p>
                  <p className={`${styles.location} ${darkMode ? styles.locationDark : ''}`}>
                    <MdTimer className={styles.icon} />
                    40 min ago
                  </p>
                  {Enabled ? (
                    loading ? (
                      <Loader />
                    ) : (
                      <button
                        className={styles.join}
                      >
                        Join Bid
                      </button>
                    )
                  ) : (
                    <Negotiate preview={true} ifnegotiate={Negotiable} />
                  )}
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                  <div className={styles.line}></div>
                  <div className={styles.safety_container}>
                    <h1 className={`${styles.safety_title} ${darkMode ? styles.safety_titleDark : ''}`}>Safety Guidelines</h1>
                    <ul>
                      <li className={`${styles.li} ${darkMode ? styles.liDark : ''}`}>Only meet in public or crowded places.</li>
                      <li className={`${styles.li} ${darkMode ? styles.liDark : ''}`}>
                        Never go alone to finish a deal, always have someone
                        with you.
                      </li>
                      <li className={`${styles.li} ${darkMode ? styles.liDark : ''}`}>
                        Always check the product thoroughly before purchase.
                      </li>
                      <li className={`${styles.li} ${darkMode ? styles.liDark : ''}`}>
                        Never pay anything in advance or transfer money before
                        inspecting the products carefully.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default PreviewModal;
