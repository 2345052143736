import React, { useState } from "react";
import styles from "./RequestDone.module.scss";
import { ReactComponent as ComingSure } from "../../../assests/svg/coming sure.svg";
import { ReactComponent as Coming } from "../../../assests/svg/coming.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
    AcceptOffer,
    RejectOffer
} from "../../../store/actions/bidActions";
import { DoneDeal } from "../../../store/actions/sendOfferActions";
import RateExperience from "../RateExperience/RateExperience";
import { GetProductOffer } from "../../../store/actions/bidActions";
const RequestDone = ({ offerId, sure, image, username, userId, productId, time, price, coordinatesSeller, coordinatesBuyer, user }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = { "productId": productId, "offerId": offerId }
    const [rate, setRate] = useState(false)
    
    async function rejectOffer() {
        try {
            const response = await dispatch(RejectOffer(data));
            dispatch(GetProductOffer(productId))
        } catch (error) {
            console.error(error);
        }
    }
    async function doneDeal(id) {
        setRate(true)
    }
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    return (
        <>
            <RateExperience
                open={rate}
                setOpen={() => setRate(false)}
                image={image}
                price={price}
                productId={productId}
                name={username}
                offerId={offerId}
                userId={userId}
            />
            <li className={styles.item}>
                <div className={styles.line}></div>
                <img src={image} alt="profile" className={styles.image} />
                <div className={styles.info_container}>
                    <h1 className={`${styles.name} ${darkMode ? styles.nameDark : ''}`}>
                        <div className={styles.username_container}>
                            <span
                                onClick={() =>
                                    navigate(`/user-review/${username.replace(" ","-")}/${userId}`, {
                                        state: {
                                            id: userId,
                                        },
                                    })
                                }
                            >
                                {username}
                            </span>
                        </div>
                    </h1>
                    {sure ? (
                        <p
                            className={`${styles.text} ${sure ? styles.sure : styles.notsure}`}
                        >
                            <ComingSure className={styles.icon} />I am coming now For Sure
                        </p>
                    ) : (
                        <p
                            className={`${styles.text} ${sure ? styles.sure : styles.notsure}`}
                        >
                            <Coming className={styles.icon} />I am coming now
                        </p>
                    )}
                    <div className={styles.state_container}>
                        <p className={styles.state}>Accepted</p>
                    </div>
                    <div className={styles.button_container}>
                        <button
                            className={styles.accept}
                            onClick={() =>
                                navigate("/user/my-offers/waiting-list/track-buyer", { state: { id: offerId, coordinatesBuyer: coordinatesBuyer, coordinatesSeller: coordinatesSeller, user: user } })}
                        >
                            Track User
                        </button>
                        <button className={styles.accept} onClick={() => doneDeal(offerId)}>Done Deal</button>
                        <button
                            className={styles.reject}
                            onClick={() => rejectOffer()}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <div className={styles.stats_container}>
                    <p className={`${styles.time} ${darkMode ? styles.timeDark : ''}`}>   {time === "now" ? 'now' : `${time} ago`}</p>
                </div>
            </li>
        </>
    );
};
export default RequestDone;
