import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import styles from "./ReviewModal.module.scss";
import { FaStar } from "react-icons/fa";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import Switch from "../../../../common/components/Switch/Switch";
import {
  AddReview,
  GetProductPerUser,
  GetUser,
} from "../../../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { GetReviewsByUID } from "../../../../store/actions/userActions";
import { useLocation } from "react-router-dom";
import { GET_USER_RESET } from "../../../../store/constants/userConstants";
import { format, parseISO } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { GetAverageReview } from "../../../../store/actions/userActions";
import "react-toastify/dist/ReactToastify.css";
const ReviewModal = ({ open, setOpen, name, image, id, time, star, ID }) => {
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [prodUser, setProdUser] = useState([]);
  const [product, setProduct] = useState([]);
  const loading = useSelector((state) => state.reviewsByUID?.loading);
  const [loading1, setLoading1] = useState(false);
  const location = useLocation;

  useEffect(() => {
    if (id) {
      dispatch({ type: GET_USER_RESET });
      dispatch(GetUser(id))
        .then((response) => {
          setProdUser(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
      dispatch(GetProductPerUser(id))
        .then((response) => {
          setProduct(response.data?.products);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [dispatch, id]);
  const handleClick = (value) => {
    setRating(value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim() === "") {
      toast.error("Please write a comment before submitting the review.");
      return;
    }
    try {
      setLoading1(true);
      const data = {
        to: id,
        rating: rating,
        comment: message,
        recommend: isSelected,
      };
      const response = await dispatch(AddReview(data));
      const response1 = await dispatch(GetReviewsByUID(ID));
      if (response) {
        const response = await dispatch(GetAverageReview(id));
        await dispatch(GetUser(ID));

        setLoading1(false);
      }
      setOpen(false);
    } catch (error) {
      // console.log(error);
    }
  };
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}>
        <div
          className={`${styles.modal_styles} ${
            darkMode ? styles.modal_stylesDark : ""
          }`}
        >
          <div className={styles.wrapper}>
            {darkMode ? (
              <CloseDark className={styles.close} onClick={setOpen} />
            ) : (
              <Close className={styles.close} onClick={setOpen} />
            )}
            {/* left side */}
            <div className={styles.user_container}>
              <img src={image} alt="profile" className={styles.image} />
              <div className={styles.name_container}>
                <h3
                  className={`${styles.name} ${
                    darkMode ? styles.nameDark : ""
                  }`}
                >
                  {name}
                </h3>
                <p
                  className={`${styles.rating} ${
                    darkMode ? styles.ratingDark : ""
                  }`}
                >
                  {star ? (
                    <>
                      {star}
                      <FaStar className={styles.rating_icon} />
                    </>
                  ) : (
                    <>
                      0
                      <FaStar className={styles.rating_icon} />
                    </>
                  )}
                </p>
              </div>
              <p
                className={`${styles.member_text} ${
                  darkMode ? styles.member_textDark : ""
                }`}
              >
                Member since{" "}
                {time ? format(parseISO(time, new Date()), "MMMM yyyy") : "N/A"}
              </p>
            </div>
            {/* right side */}
            <div className={styles.main_container}>
              <div className={styles.rating_container}>
                <p
                  className={`${styles.overall} ${
                    darkMode ? styles.overallDark : ""
                  }`}
                >
                  Your overall rating of your interaction
                </p>
                <div className={styles.stars}>
                  <FaStar
                    className={`${styles.star} ${
                      rating >= 1 ? styles.gold : ""
                    }`}
                    onClick={() => handleClick(1)}
                  />
                  <FaStar
                    className={`${styles.star} ${
                      rating >= 2 ? styles.gold : ""
                    }`}
                    onClick={() => handleClick(2)}
                  />
                  <FaStar
                    className={`${styles.star} ${
                      rating >= 3 ? styles.gold : ""
                    }`}
                    onClick={() => handleClick(3)}
                  />
                  <FaStar
                    className={`${styles.star} ${
                      rating >= 4 ? styles.gold : ""
                    }`}
                    onClick={() => handleClick(4)}
                  />
                  <FaStar
                    className={`${styles.star} ${
                      rating >= 5 ? styles.gold : ""
                    }`}
                    onClick={() => handleClick(5)}
                  />
                </div>
              </div>
              <div className={styles.line}></div>
              <p
                className={`${styles.question} ${
                  darkMode ? styles.questionDark : ""
                }`}
              >
                What did you like or dislike?
              </p>
              <textarea
                className={styles.textarea}
                placeholder="Type your message here"
                onChange={handleMessageChange}
                value={message}
              />
              <div className={styles.line}></div>
              <div className={styles.question_container}>
                <p
                  className={`${styles.question} ${
                    darkMode ? styles.questionDark : ""
                  }`}
                >
                  Would you recommend interacting with this user?
                </p>
                <div
                  onClick={() => setIsSelected((prevState) => !prevState)}
                  className={`${styles.switch_container} ${
                    isSelected ? styles.active : ""
                  }`}
                >
                  <span
                    className={`${styles.button} ${
                      isSelected ? styles.active : ""
                    }`}
                  />
                </div>
              </div>
              <div className={styles.line}></div>
              {loading1 ? (
                <button className={styles.submit_loading} disabled={loading1}>
                  Submit
                </button>
              ) : (
                <button
                  className={`${styles.submit} ${
                    darkMode ? styles.submitDark : ""
                  }`}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default ReviewModal;
