import React, { useState, useEffect } from "react";
import styles from "../AdminAnalytics/AdminAnalytics.module.scss";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import img from "../../assests/svg/back person.svg";
import { Table } from "react-bootstrap";
import Role from "../AdminDashUsers/components/Role/Role";
import { Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUsers } from "../../store/actions/AdminDashboard";
import { GetRateExperience } from "../../store/actions/AdminDashboard";
import IsAdmin from "../AdminDashUsers/components/Role/IsAdmin";
import { ChangeRole } from "../../store/actions/AdminDashboard";
import { GetEndedBids } from "../../store/actions/AdminDashboard";
import ThreeDots from "../AdminDashUsers/components/threeDots/threeDots";
import Loader from "../../common/components/Loader/Loader";
const RateExperienceAdmin = () => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial value
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const endedBids = useSelector(
    (state) => state.endedBids?.endedBids?.endedBids
  );
  const finalRate = useSelector(
    (state) => state.finalRate?.finalRate?.data?.ratings
  );
  const loading = useSelector((state) => state.finalRate?.loading);

  useEffect(() => {
    dispatch(GetEndedBids());
    dispatch(GetRateExperience());
  }, [dispatch]);
  return (
    <AdminLayout>
      <div className={styles.header}>
        <h3>All Deals</h3>
      </div>
      {loading ? (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      ) : isSmallScreen ? (
        <>
          {finalRate &&
            finalRate.map((user, index) => (
              <div className={styles.table}>
                <Table striped bordered hover style={{ width: "250px" }}>
                  <tbody>
                    <React.Fragment key={index}>
                      <tr>
                        <td className={styles.customername}>From:</td>
                        <td className={styles.customername}>
                          <div className={styles.uInfo}>
                            <img
                              src={user.from?.imageUrl}
                              alt="Customer"
                              className={styles.customerImage}
                            />
                            {user?.from?.username}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>To:</td>
                        <td className={styles.customername}>
                          <div className={styles.uInfo}>
                            <img
                              src={user.to?.imageUrl}
                              alt="Customer"
                              className={styles.customerImage}
                            />
                            {user?.to?.username}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>Comment:</td>
                        <td className={styles.customername}>{user?.comment}</td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>Product:</td>
                        <td className={styles.customername}>
                          {user?.product?.title}
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>Stars:</td>
                        <td className={styles.customername}>{user?.stars}</td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>Final Price:</td>
                        <td className={styles.customername}>
                          ${user?.finalPrice}
                        </td>
                      </tr>
                      <tr>
                        <td className={styles.customername}>Date:</td>
                        <td className={styles.customername}>
                          {user?.createdAt.slice(0, 10)}
                        </td>
                      </tr>
                    </React.Fragment>
                  </tbody>
                </Table>
              </div>
            ))}
        </>
      ) : (
        <div className={styles.table}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Comment</th>
                <th>Product</th>
                <th>Stars</th>
                <th>Final Price</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {finalRate &&
                finalRate.map((user, index) => (
                  <tr key={index}>
                    <td className={styles.customername}>
                      <div className={styles.uInfo}>
                        <img
                          src={user.from?.imageUrl}
                          alt="Customer"
                          className={styles.customerImage}
                        />
                        {user?.to?.username}
                      </div>
                    </td>

                    <td className={styles.customername}>
                      <div className={styles.uInfo}>
                        <img
                          src={user.to?.imageUrl}
                          alt="Customer"
                          className={styles.customerImage}
                        />
                        {user?.to?.username}
                      </div>
                    </td>
                    <td className={styles.email}>
                      <div className={styles.email}>{user?.comment}</div>
                    </td>

                    <td className={styles.customername}>
                      {user?.product?.title}
                    </td>
                    <td className={styles.customername}>{user?.stars}</td>
                    {/* <td><IsAdmin state={user && user.isAdmin} /></td> */}
                    <td className={styles.customername}>${user.finalPrice}</td>
                    <td className={styles.customername}>
                      {user.createdAt.slice(0, 10)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </AdminLayout>
  );
};
export default RateExperienceAdmin;
