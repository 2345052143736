import {
  PRODUCT_LIST_BY_SUBCATEGORY_REQUEST,
  PRODUCT_LIST_BY_SUBCATEGORY_SUCCESS,
  PRODUCT_LIST_BY_SUBCATEGORY_FAIL,
  CATEGORIES_LIST_REQUEST,
  CATEGORIES_LIST_SUCCESS,
  CATEGORIES_LIST_FAIL,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_CATEGORIES_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  GET_ALL_SUB_CATEGORIES_SUCCESS,
  GET_ALL_SUB_CATEGORIES_FAIL,
  GET_SINGLE_CATEGORIES_FAIL,
  GET_SINGLE_CATEGORIES_REQUEST,
  GET_SINGLE_CATEGORIES_SUCCESS,
  PUT_PREFERRED_CATEGORY_SUCCESS,
  PUT_PREFERRED_CATEGORY_FAIL,
  GET_FAV_CATEGORIES_SUCCESS,
  GET_FAV_CATEGORIES_FAIL,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_FAIL
} from "../constants/categoryConstants";
import axios from "axios";
import { url } from "../../common/constants/url";
export const getCategoritesList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORIES_LIST_REQUEST,
    });
    const { data } = await axios.get(
      url + `/Category_products/getCategory_productswithsub`
    );
    dispatch({
      type: CATEGORIES_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CATEGORIES_LIST_FAIL,
      payload: message,
    });
  }
};
export const listMyProductsBySubCategory =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_LIST_BY_SUBCATEGORY_REQUEST,
      });
      const { data } = await axios.get(
        `${url}/products/${id}/getproductsbysubcat`
      );
      dispatch({
        type: PRODUCT_LIST_BY_SUBCATEGORY_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: PRODUCT_LIST_BY_SUBCATEGORY_FAIL,
        payload: message,
      });
    }
  };
export const CreateCategory = (formData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.post(`${url}/category`, formData, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      const newCategory = response.data
      
      dispatch(GetAllCategories())
        .then((data) => {
          dispatch(GetAllCategoriesSuccess(data));
        })
        .catch((error) => {
          // console.log(error);
        });
      dispatch(createCategorySuccess(response.data));
      return newCategory;
    } catch (error) {
      dispatch(createCategoryFail(error));
      // console.log(error)
    }
  };
};
const createCategorySuccess = (data) => ({
  type: CREATE_CATEGORY_SUCCESS,
  payload: data
});
const createCategoryFail = (error) => ({
  type: CREATE_CATEGORY_FAIL,
  payload: error
});
export const GetAllCategories = () => {
  return async (dispatch) => {
    try {
      dispatch(GetAllCategoriesrequest());
      const token = localStorage.getItem('token')
      
      const response = await axios.get(`${url}/category?withSubCategories=true`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      
      dispatch(GetAllCategoriesSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetAllCategoriesFail(error));
      // console.log(error)
    }
  };
};
export const GetAllCategoriesSuccess = (categories) => {
  return {
    type: GET_ALL_CATEGORIES_SUCCESS,
    payload: categories,
  };
};
export const GetAllCategoriesrequest = () => {
  return {
    type: GET_ALL_CATEGORIES_REQUEST,
  };
};
const GetAllCategoriesFail = (error) => ({
  type: GET_ALL_CATEGORIES_FAIL,
  payload: error
});
export const DeleteCategory = (CategoryId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios.delete(`${url}/category/${CategoryId}`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      dispatch(DeleteCategorySuccess(CategoryId));
    } catch (error) {
      dispatch(DeleteCategoryFail(error));
      // console.log(error)
    }
  }
}
export const DeleteCategorySuccess = (id) => {
  return {
    type: "DELETE_CATEGORY_SUCCESS",
    payload: id,
  };
};
const DeleteCategoryFail = (error) => ({
  type: "DELETE_CATEGORY_FAIL",
  payload: error
});
export const GetAllSubCategories = (CategoryId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.get(`${url}/category/sub/${CategoryId}`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      dispatch(GetAllSubCategoriesSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetAllSubCategoriesFail(error));
      // console.log(error)
    }
  };
};
export const GetAllSubCategoriesSuccess = (subCategories) => {
  return {
    type: GET_ALL_SUB_CATEGORIES_SUCCESS,
    payload: subCategories,
  };
};
const GetAllSubCategoriesFail = (error) => ({
  type: GET_ALL_SUB_CATEGORIES_FAIL,
  payload: error
});
export const GetSingleCategory = (CategoryId) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.get(`${url}/category/${CategoryId}`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      dispatch(GetSingleCategorySuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetSingleCategoryFail(error));
      // console.log(error)
    }
  };
};
export const GetSingleCategorySuccess = (category) => {
  return {
    type: GET_SINGLE_CATEGORIES_SUCCESS,
    payload: category,
  };
};
const GetSingleCategoryFail = (error) => ({
  type: GET_SINGLE_CATEGORIES_FAIL,
  payload: error
});
export const PutPreferredCategory = (formData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.put(`${url}/user/setPrefCategories`, formData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      dispatch(PutPreferredCategorySuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(PutPreferredCategoryFail(error));
      // console.log(error.response.data);
    }
  };
};
export const PutPreferredCategorySuccess = (category) => {
  window.location.reload();
  return {
    type: PUT_PREFERRED_CATEGORY_SUCCESS,
    payload: category,
  };
};
const PutPreferredCategoryFail = (error) => ({
  type: PUT_PREFERRED_CATEGORY_FAIL,
  payload: error
});
export const PutPreferredCategoryPg = (formData) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.put(`${url}/user/setPrefCategories`, formData, {
        headers: {
          'authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      dispatch(PutPreferredCategoryPgSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(PutPreferredCategoryPgFail(error));
      // console.log(error.response.data);
    }
  };
};
export const PutPreferredCategoryPgSuccess = (category) => {
  return {
    type: PUT_PREFERRED_CATEGORY_SUCCESS,
    payload: category,
  };
};
const PutPreferredCategoryPgFail = (error) => ({
  type: PUT_PREFERRED_CATEGORY_FAIL,
  payload: error
});
export const GetFavCategory = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.get(`${url}/user/getPrefCategories`, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      dispatch(GetFavCategorySuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(GetFavCategoryFail(error));
      // console.log(error)
    }
  };
};
export const GetFavCategorySuccess = (category) => {
  return {
    type: GET_FAV_CATEGORIES_SUCCESS,
    payload: category,
  };
};
const GetFavCategoryFail = (error) => ({
  type: GET_FAV_CATEGORIES_FAIL,
  payload: error
});
export const EditCategory = (formData, id) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem('token')
      
      const response = await axios.put(`${url}/category/${id}`, formData ,{
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      dispatch(EditCategorySuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(EditCategoryFail(error));
      // console.log(error)
    }
  };
};
export const EditCategorySuccess = (category) => {
  return {
    type: EDIT_CATEGORY_SUCCESS,
    payload: category,
  };
};
const EditCategoryFail = (error) => ({
  type: EDIT_CATEGORY_FAIL,
  payload: error
});