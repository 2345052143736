import {
  SEARCH_PRODUCT_SUCCESS,
  SEARCH_PRODUCT_FAIL,
  SEARCH_PRODUCT_REQUEST
} from "../constants/productDetailsConstants";
const initialState = {
  SearchComponent: [],
  error: null,
  loading: false
};
export const SearchComponentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case SEARCH_PRODUCT_SUCCESS:
      return {
        ...state,
        SearchComponent: action.payload,
        error: null,
        loading: false,
      };
    case SEARCH_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
