import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import img from "../../../assests/images/notFound.jpg";
const NotFound = () => {
  const navigate = useNavigate();
  const handleTryAgain = () => {
    navigate(-1);
  };
  return (
    <div className={styles.container}>
      <img src='/notFound.jpg' alt="error" className={styles.img} />
      <h1 className={styles.title}>Page Not Found</h1>
      <p className={styles.text}>
        Please enter a correct URL and try again
      </p>
      <button onClick={handleTryAgain} className={styles.btn}>
        Go Back
      </button>
    </div>
  );
};
export default NotFound;
