import React from "react";
import Layout from "../../common/components/Layouts/Layout";
import ProfilePicture from "./components/ProfilePicture/ProfilePicture";
import RatingCard from "./components/RatingCard/RatingCard";
import ProductCard from "./components/ProductCard/ProductCard";
import styles from "./UserProducts.module.scss";
import { FaStar } from "react-icons/fa";
const UserProducts = () => {
  return (
    <Layout title={"Published Products"}>
      <div className={styles.wrapper}>
        <aside className={styles.aside}>
          <ProfilePicture />
          <div className={styles.name_container}>
            <h3 className={styles.name}>Ali Karak</h3>
            <p className={styles.rating}>
              4.6 <FaStar className={styles.rating_icon} />
            </p>
          </div>
          <p className={styles.member}>Member since August 2021</p>
          <div className={styles.account_info}>
            <p>
              25 <span>Posts</span>
            </p>
            <p>
              1,525 <span>Followers</span>
            </p>
          </div>
          <RatingCard />
        </aside>
        <div className={styles.main}>
          <ProductCard />
          <ProductCard />
          <ProductCard />
          <ProductCard />
          <ProductCard />
          <ProductCard />
          <ProductCard />
          <ProductCard />
        </div>
      </div>
    </Layout>
  );
};
export default UserProducts;
