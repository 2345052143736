import React, { useState, useEffect } from "react";
import styles from "./Header.module.scss";
import { FaMobileAlt } from "react-icons/fa";
import StatusBar from "./StatusBar/StatusBar";
import NavBar from "./NavBar/NavBar";
import SideDrawer from "./SideDrawer/SideDrawer";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../Modal/loginModal/LoginModal";
import { logout } from "../../../store/actions/authActions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
const Header = ({ isFilterIcon, SubCategoryId, onCategoryFilter, bids, featured, offered, requested, service, typeId, allProducts, allServices, onObjectChange, sort, text, offer, searchCatId, searchSubId, type, searchBid, CategoryId, FeaturedProduct, FeaturedBid, search }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userLogin);
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);
  const [sideDrawer, setSideDrawer] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [navbar, setNavbar] = useState(false);
  const toggleSideDrawer = () => {
    setSideDrawer((prevState) => !prevState);
  };
  if (sideDrawer) {
  }
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    const changeBackground = () => {
      if (window.scrollY >= 80) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    };
    window.addEventListener("resize", changeWidth);
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("resize", changeWidth);
      window.removeEventListener("scroll", changeBackground);
    };
  }, [user]);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const handleScrollToCOD = (event) => {
    event.preventDefault();
    // if (location.pathname !== '/') {
    //   // Redirect to the homepage
    //   navigate('/');
    //   return
    // }
    // Get the target section element you want to scroll to (assuming you have an element with the 'name' attribute set to "CatchOfTheDay")
    const targetSection = document.getElementsByName("DownloadTheApp")[0];
    // Scroll to the target section using smooth behavior
    targetSection.scrollIntoView({ behavior: "smooth" });
  };
  

  return (
    <>
      <section className={styles.header__section}>
        <header className={`${styles.header} ${darkMode ? styles.headerDark : ''}`}>
          <Link className={styles.link} to={"/contact-us"}>
            <h4 className={styles.contact}>Contact Us</h4>
          </Link>
          <div className={styles.center}>
            <FaMobileAlt className={styles.icon} />
            <a onClick={handleScrollToCOD} id="app-link" className={styles.download}>Download App</a>
          </div>
        </header>
        <StatusBar toggleSideDrawer={toggleSideDrawer} user={loggedInUser} />
        {screenWidth < 900 && (
          <SideDrawer
            sideDrawer={sideDrawer}
            toggleSideDrawer={toggleSideDrawer}
            user={loggedInUser}
          />
        )}
        <NavBar active={navbar} isFilterIcon={isFilterIcon} SubCategoryId={SubCategoryId} onCategoryFilter={onCategoryFilter} bids={bids} featured={featured} offered={offered} requested={requested} service={service} typeId={typeId} allProducts={allProducts} allServices={allServices} onObjectChange={onObjectChange} sort={sort} text={text} offer={offer} searchSubId={searchSubId} searchCatId={searchCatId} type={type} searchBid={searchBid} CategoryId={CategoryId} FeaturedProduct={FeaturedProduct} FeaturedBid={FeaturedBid} search={search} />
      </section>
      <style>
        {sideDrawer ? 'body::-webkit-scrollbar { display: none; }' : ''}
      </style>
    </>
  );
        }  
export default Header;
