import {
    GET_RANGE_FOR_ADMIN_FAIL,
    GET_RANGE_FOR_ADMIN_SUCCESS
} from "../constants/adminDashboardConstants";
const initialState = {
    rangeAdmin: [],
    error: null,
    loading: false,
};
export const GetRangeForAdmin = (state = initialState, action) => {
    switch (action.type) {
        case GET_RANGE_FOR_ADMIN_SUCCESS:
            return {
                ...state,
                rangeAdmin: action.payload,
                error: null,
                loading: false,
            };
        case GET_RANGE_FOR_ADMIN_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
