import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./AddAddressPage.module.scss";
import { ReactComponent as Search } from "../../assests/svg/search icon.svg";
import map from "../../assests/images/map.png";
import { GoogleMap, useLoadScript, MarkerF, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../../store/actions/addAddressActions";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { google } from 'google-maps';
import { useActionData, useNavigate } from "react-router-dom";
import { makeDefault } from "../../store/actions/addAddressActions";
import { CreateAddressReducer } from "../../store/reducers/AddAddressReducer";
import Switch from "react-switch";
const libraries = ["places"];
const AddAddressPage = ({ imported = false }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const pageName = "Address Page"; // Replace this with the actual page name
    const pageUrl = "/user/addresses/add-address"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [center, setCenter] = useState({ lat: 33.8547, lng: 35.8623 });
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          // Handle error
        }
      );
    } else {
      // console.log('error in geo')
    }
  }, []); const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {
        lat: () => 43.6532,
        lng: () => -79.3832,
      },
      radius: 200 * 1000,
    },
  });
  const [isDefault, setIsDefault] = useState(false);
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCLlu4lwcE0fWQFAOf50EUql1LwAmNRaPk",
    libraries,
  });
  const [markers, setMarkers] = useState({});
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [selected, setSelected] = useState(null);
  const [newPlace, setNewPlace] = useState(null);
  const [added, setAdded] = useState(null);
  let markerAddress = [];
  //autocomplte
  const [addresss, setAddress] = useState(null);
  const [markerLocation, setMarkerLocation] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const handlePlaceSelect = () => {
    const inputElement = document.getElementById('autocomplete-input');
    const autocomplete = new window.google.maps.places.Autocomplete(inputElement);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      setSelectedPlace(place);
      setCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    });
  };
  React.useEffect(() => {
    if (selectedPlace) {
      const lat = selectedPlace.geometry.location.lat();
      const lng = selectedPlace.geometry.location.lng();
    }
  }, [selectedPlace]);
  const getAddress = async (event) => {
    Geocode.setApiKey("AIzaSyCLlu4lwcE0fWQFAOf50EUql1LwAmNRaPk");
    Geocode.fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
      async (response) => {
        const address = response.results[0].formatted_address;
        markerAddress[0] = address;
        const newMarker = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
          address: address,
          time: new Date(),
        };
        if (markerAddress.length > 0) {
          setMarkerLocation(newMarker);
        }
      },
      (error) => { }
    );
  };
  const [vvalue, setVvalue] = useState(null);
  const onMapClick = (event) => {
    setCenter({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };
  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);
  const navigate = useNavigate();
  const display = () => {
    if (!isLoaded) return <div>Loading...</div>;
    return (
      <GoogleMap
        zoom={10}
        center={center}
        mapContainerClassName={styles.map}
        onLoad={onMapLoad}
        onClick={onMapClick}
      >
        <MarkerF
          position={center}
          onClick={() => {
            setSelected(null);
          }}
          draggable={true}
          onDragEnd={onMapClick}
        />
      </GoogleMap>
    );
  };
  const coord = { lat: center.lat, long: center.lng }
  const loading = useSelector(
    (state) => state.CreatedAddress?.loading
  );
  const [successMessage, setSuccessMessage] = useState('');
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const data = { title: title, coordinates: coord, description: description };
      const jsonData = JSON.stringify(data);
      const response = await dispatch(addAddress(jsonData));
      
      if (isDefault) {
        const response23 = await dispatch(makeDefault(response._id));
   
      }
      if (response && response._id) {
        setSuccessMessage('Address added successfully')
        setTimeout(() => setSuccessMessage(''), 3000);
        if (!imported) {
          navigate(-1)
        }
      } else {
        setSuccessMessage('Something went wrong while creating this address')
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <>
      <Layout title={"Add New Address"}>
        <div className={styles.container}>
          <div className={`${styles.wrapper} ${darkMode ? styles.wrapperDark : ''}`}>
            <div className={styles.input_container}>
              <input
                type="text"
                placeholder="Type Address Nickname"
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              // disabled={!ready}
              />
              <Search className={styles.icon} />
            </div>
            <div className={styles.input_container}>
              <input
                type="text"
                placeholder="Type Description"
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              // disabled={!ready}
              />
              <Search className={styles.icon} />
            </div>
            <div className={styles.input_container}>
              <input
                id="autocomplete-input"
                type="text"
                placeholder="Enter a location"
                className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                onFocus={handlePlaceSelect}
              />
              <Search className={styles.icon} />
            </div>
            <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Select The location from the map</h1>
            {/* <img src={map} alt="map" className={styles.map} /> */}
            {display()}
            <div className={styles.default_container}>
              <h3 className={`${styles.subtitle} ${darkMode ? styles.subtitleDark : ''}`}>Set as the default address</h3>
              <Switch
                checked={isDefault}
                onChange={() => setIsDefault(!isDefault)}
                onColor={'#0298a6'}
                uncheckedIcon={false}
                checkedIcon={false}
              />
            </div>
            {successMessage && <p style={{ color: '#0298a6', fontWeight: '600' }}>{successMessage}</p>}
            {loading ? (
              <button className={styles.saveload_btn}>saving...</button>
            ) : (
              <button className={`${styles.save_btn} ${darkMode ? styles.save_btnDark : ''}`} onClick={handleSubmit}>Save Addresses</button>
            )
            }
          </div>
        </div>
      </Layout>
    </>
  );
};
export default AddAddressPage;
