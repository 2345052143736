import {
    REMOVE_WHISHLIST_SUCCESS,
    REMOVE_WHISHLIST_FAIL
} from "../constants/wishlistConstants";
const initialState = {
    removeWishlist: [],
    error: null,
    loading: false,
};
export const RemoveProductFromWishlist = (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_WHISHLIST_SUCCESS:
            return {
                ...state,
                removeWishlist: action.payload,
                error: null,
                loading: false,
            };
        case REMOVE_WHISHLIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
