import React, { useState, useEffect } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./ProductDescription.module.scss";
import { MdLocationPin, MdTimer } from "react-icons/md";
import Negotiate from "./components/Negotiate/Negotiate";
import RequestAcceptedPage from "./components/Request Accepted/RequestAcceptedPage";
import UserContainer from "./components/UserContainer/UserContainer";
import Bid from "./components/Bid/Bid";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProdDetails } from "../../store/actions/productDetailsAction";
import Loader from "../../common/components/Loader/Loader";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import { useParams } from "react-router-dom";
import { PRODUCT_DETAILS_RESET } from "../../store/constants/productDetailsConstants";
import ImageComponent from "./components/ImageComponent/ImageComponent";
import { timePosted } from "../../common/Utils";
import { getDefaultCard } from "../../store/actions/paymentActions";
import { DEFAULT_CARD_RESET } from "../../store/constants/paymentConstants";
import { BiMessageRounded } from "react-icons/bi";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { ReactComponent as Phone } from "../../assests/svg/phone white.svg";
import { ReactComponent as WhatspApp } from "../../assests/svg/whatsapp white.svg";
import { handleFilterIcon } from "../../store/actions/filterActions";
import { CHECK_USER_PAYMENT_BID_RESET } from "../../store/constants/makePaymentConstants";
import { CHECK_USER_IN_WAITINGLIST_RESET } from "../../store/constants/waitingListConstants";
const ProductDescription = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  useEffect(() => {
    const pageName = "Product Description"; // Replace this with the actual page name
    const pageUrl = `/sell/product/Products-Information/Product-Preview/${params.productName}`; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const getProductDetails = useSelector((state) => state.getProductDetails);
  const { productDetails, error, loading } = getProductDetails;
  const checkUserInWaitingList = useSelector(
    (state) => state.checkUserInWaitingList
  );
  const { user } = checkUserInWaitingList;
  const defaultCard = useSelector((state) => state.defaultCard);
  const { card_id, loading: loadingCard, error: errorCard } = defaultCard;
  const [requestAccepted, setRequestAccepted] = useState(false);
  const ProductArray = useSelector(state => state.ProductArray);
  const title = ProductArray.data[0][0][0].title
  const conditionString = ProductArray.data[0][0][0].conditionString
  const condition = conditionString === "0" ? "Used" : "New";
  const description = ProductArray.data[0][0][0].description
  const pricing = ProductArray.data[0][0][0].Pricing
  const fields = ProductArray.data[0][0][0].fields
  const images = ProductArray.data[0][0][0].images
  const timer = ProductArray.data[0][0][0].timer
  return (
    <>
      <div className={styles.fixed_container}>
        <div className={`${styles.fixed_button} ${styles.first}`}>
          <div>
            <BiMessageRounded className={styles.fixed_icon} />
            <p>Chat</p>
          </div>
        </div>
        <div className={`${styles.fixed_button} ${styles.second}`}>
          <div>
            <Phone className={styles.fixed_icon} />
            <p>Call</p>
          </div>
        </div>
        <div className={`${styles.fixed_button} ${styles.third}`}>
          <div>
            <WhatspApp className={styles.fixed_icon} />
            <p>Whatsapp</p>
          </div>
        </div>
      </div>
      <Layout title={"Product Preview"}>
        {error ? (
          <div className="center">
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        ) : loading ? (
          <div className="center">
            <Loader />
          </div>
        ) : (
          <section className={styles.section}>
            <div className={styles.left_side}>
              <div className={styles.container}>
                <ImageComponent
                  images={images}
                />
                {/* <div className={styles.social_media_container}>
            Share: <WhatsApp className={styles.social} />{" "}
            <FB className={styles.social} />{" "}
          </div> */}
              </div>
              <div className={styles.description_container}>
                <h3 className={styles.title}>Description</h3>
                <p className={styles.text}>
                  {description}
                </p>
              </div>
              <div className={styles.table_container}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <span className={styles.left}>Condition</span>
                        <span className={styles.right}>{condition}</span>
                      </td>
                    </tr>
                    {fields.map((field) => (
                      <tr>
                        <td>
                          <span className={styles.left}>{field.title}</span>
                          <span className={styles.right}>
                            {typeof field.value === "number"
                              ? field.possibleValues[field.value]
                              : field.value}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={styles.right_side}>
              <div className={styles.wrapper}>
                <h1 className={styles.price}>{`USD ${pricing.price}`}</h1>
                <p className={styles.description}>
                  {title}
                </p>
                <p className={styles.location}>
                  <MdLocationPin className={styles.icon} />
                  Hazmieh, Baabda
                </p>
                <p className={styles.location}>
                  <MdTimer className={styles.icon} />
                  40 min ago
                </p>
                {/*} {productDetails.offer[0].bid === 1 ? (
                    loadingCard ? (
                      <Loader />
                    ) : (
                      <Bid
                        start={productDetails.offer[0].startdatetimer}
                        end={productDetails.offer[0].enddatetimer}
                        card_id={card_id}
                        id={productDetails.offer[0].id}
                        title={productDetails.offer[0].title}
                        productId={productDetails.offer[0].id}
                      />
                    )
                  ) : (
                    <Negotiate
                      productId={productDetails.offer[0].id}
                      card_id={card_id}
                    />
                  )}
                  <div className={styles.line}></div>
                  <div className={styles.user_container}>
                    <UserContainer
                      image={productDetails.offer[0].image}
                      name={productDetails.offer[0].username}
                      rate={productDetails.offer[0].TotalRate}
                      date={productDetails.offer[0].dateofmembership}
                      id={productDetails.offer[0].uid}
                    />
                  </div> */}
                <div className={styles.line}></div>
                <div className={styles.safety_container}>
                  <h1 className={styles.safety_title}>Safety Guidelines</h1>
                  <ul>
                    <li>Only meet in public or crowded places.</li>
                    <li>
                      Never go alone to finish a deal, always have someone
                      with you.
                    </li>
                    <li>
                      Always check the product thoroughly before purchase.
                    </li>
                    <li>
                      Never pay anything in advance or transfer money before
                      inspecting the products carefully.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        )}
      </Layout>
    </>
  );
};
export default ProductDescription;
