import React, { useRef } from "react";
import { BrowserRouter, Routes, Route, useNavigate, Navigate, Router } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import CategoriesPage from "./pages/CategoriesPage/CategoriesPage";
import HelpPage from "./pages/HelpPage/HelpPage";
import WishList from "./pages/WishList/WishList";
import SellPage from "./pages/HelpPage/Pages/SellPage/SellPage";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import NotificationsPage from "./pages/NotificationsPage/NotificationsPage";
import OrderHistoryPage from "./pages/OrderHistoryPage/OrderHistoryPage";
import EditProfilePage from "./pages/EditProfilePage/EditProfilePage";
import AdminReports from "./pages/AdminReports/AdminReports";
import ChangePasswordPage from "./pages/ChangePasswordPage/ChangePasswordPage";
import MyProductsPage from "./pages/MyProductsPage/MyProductsPage";
import RateExperienceAdmin from "./pages/admin/rateExperience";
import WaitingListPage from "./pages/WaitingListPage/WaitingListPage";
import PrivateRoute from "./PrivateRoute";
import ContactUs from "./pages/ContactUsPage/ContactUs";
import EndedBidPage from "./pages/admin/endedBidsPage";
import MyLiveBids from "./pages/BuyerWaitList/MyLiveBids";
import firebase from 'firebase/app';
import Notification from "./notification";
import AddressPage from "./pages/AddressPage/AddressPage";
import AddAddressPage from "./pages/AddAddressPage/AddAddressPage";
import PaymentPage from "./pages/PaymentPage/PaymentPage";
import AddCardPage from "./pages/AddCardPage/AddCardPage";
import SpecialOffersPage from "./pages/SpecialOffersPage/SpecialOffersPage";
import ServicePage from "./pages/ServicesPage/ServicePage";
import AllServicesPage from "./pages/HomePage/components/FeaturedProduct/AllServices";
import SellOrBidProductCategory from "./pages/SellOrBidProduct/SellOrBidProductCategory";
import SellProductForm from "./pages/Sell Product Form Page/SellProductForm";
import PrivacyPolicy from "./pages/PrivacyPolicyPage/PrivacyPolicy";
import NotificationSetting from "./pages/NotificationSettingPage/NotificationSetting";
import AllProductPage from "./pages/HomePage/components/FeaturedProduct/AllProductPage";
import SellServiceOfferCategory from "./pages/SellServiceCategory/SellServiceCategory";
import SellServiceRequestCategory from "./pages/SellServiceCategory/SellServiceRequestCategory";
import SellServiceForm from "./pages/Sell Service Form Page/SellServiceForm";
import RequestServiceForm from "./pages/Sell Service Form Page/RequestServiceForm"
import BidDetailsPage from "./pages/BidDetailsPage/BidDetailsPage";
import ProductDescription from "./pages/ProductDescriptionPage/ProductDescription";
import RequestAcceptedPageBuyer from "./pages/WaitingListPage/Request Accepted/RequestAcceptedPageBuyer";
import { AddFcmToken } from "./store/actions/NotificationAction";
import UserPage from "./pages/UserPage/UserPage";
import UserProducts from "./pages/UserPage/UserProducts";
import FAQPage from "./pages/FAQ Page/FAQPage";
import ScrollToTop from "./common/components/ScrollToTop.jsx";
import TrackUser from "./pages/TrackUserPage/TrackUser";
import RegisterAdmin from "./pages/admin/RegisterAdmin/RegisterAdmin";
import LoginAdmin from "./pages/admin/LoginAdmin/LoginAdmin";
import AdminDashboard from "./pages/admin/AdminDashboard/AdminDashboard";
import ChatPage from "./pages/ChatPage/ChatPage";
import AdminCategories from "./pages/AdminCategories/AdminCategories";
import PrivateRoutes from "./common/components/PrivateRoutes";
import Error404 from "./common/components/Error404/Error404.jsx";
import Properties from "./pages/Sell Product Form Page/Properties/Properties";
import { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import { onForegroundMessage } from "./firebase";
import { ToastContainer, toast } from 'react-toastify';
import EditInfo from "./common/components/Modal/loginModal/editinfo";
import AdminProducts from "./pages/AdminProducts/AdminProducts";
import StepTwo from "./common/components/Modal/RegisterCompanyModal/StepTwo/StepTwo";
import EditProduct from "./pages/MyProductsPage/EditProduct";
import CategoryPageDropDown from "./pages/CategoryPage/CategoryPageDropDown";
import ProductDetails from "./pages/ProductDetails/ProductDetails"
import FeaturedProductPage from "./pages/HomePage/components/FeaturedProduct/FeaturedProductPage";
import SearchPage from "./pages/SearchProduct/SearchPage";
import './App.css'
import AdminCOD from "./pages/AdminCOD/AdminCOD";
import AdminSets from "./pages/AdminSets/AdminSets";
import AdminProfile from "./pages/AdminProfile/AdminProfile";
import AdminAnalytics from "./pages/AdminAnalytics/AdminAnalytics";
import DashboardUsers from "./pages/AdminDashUsers/DashboardUsers";
import Adminnot from "./pages/AdminNotification/Adminnot";
import AdminChat from "./pages/AdminChat/AdminChat";
import PrivateRoutesADM from "./common/components/PrivateRoutesAdm";
import OfferedServicesPage from "./pages/HomePage/components/Offered Service/OfferedServicesPage";
import RequestServicesPage from "./pages/HomePage/components/Requested Services/RequestServicesPage";
import RecommendedForPage from "./pages/HomePage/components/RecommendedForYou/RecommendedForYouPage";
import axios from "axios";
import RequestAcceptedPage from "./pages/WaitingListPage/Request Accepted/RequestAcceptedPage";
import EditService from "./pages/MyProductsPage/EditService";
import AllBidsPage from "./pages/CategoryPage/AllBidsPage";
import AdminChatPage from "./pages/AdminChatPage/AdminChatPage";
import NotFound from "./common/components/Error404/NotFound";
import 'react-toastify/dist/ReactToastify.css';
import { getMessaging, getToken } from 'firebase/messaging';
import { Toaster } from 'react-hot-toast';
import FavCategs from "./pages/Fav Categories/FavCategs";
import DeleteAccountPage from "./pages/DeleteAccount/DeleteAccountPage";
import BuyerWaitList from "./pages/BuyerWaitList/BuyerWaitList";
import BlockedAccount from "./pages/BlockedAccount/BlockedAccount";
import SiteMap from "./pages/SiteMap/SiteMap";
import BuyPage from "./pages/HelpPage/Pages/SellPage/BuyPage";
import BidPage from "./pages/HelpPage/Pages/SellPage/BidPage";
import FeatureProducts from "./pages/HelpPage/Pages/SellPage/FeatureProducts";
import Payment from "./pages/HelpPage/Pages/SellPage/Payment";
import Profile from "./pages/HelpPage/Pages/SellPage/Profile";
import { io } from "socket.io-client";
import { db, messaging } from "./firebase";
import { getDatabase, ref, push, set } from "firebase/database";
import { useDispatch, useSelector } from "react-redux";
import TermOfUse from "./pages/TermOfUse/TermOfUse";
import EditServiceCategory from "./pages/AdminCategories/EditServiceCategory";
import EditProductCategory from "./pages/AdminCategories/EditProductCategory";
import { auth } from "./firebase";
import PaymentHistory from "./pages/PaymentHistory/PaymentHistory";
import MobileBanner from "./common/components/Mobile Banner/MobileBanner";
function App() {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState(true)
  const [userData, setUserData] = useState([])
  const socketRef = useRef(undefined);

  const locRef = useRef(undefined);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const userid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const fetchUserData = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`https://backend.la7i27alak.com/user/${userid}`, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      setRole(response.data?.user.isAdmin);
      let data = [];
      data.push(response.data?.user)
      setUserData(data);
      setIsLoading(false);
      // setInterval(watchLocation(data),1000);
    } catch (error) {
      console.error(error);
    }
  };
  const baseUrl = 'https://backend.la7i27alak.com'
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  window.addEventListener('scroll', toggleVisibility);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = () => toast(<ToastDisplay />);
  function ToastDisplay() {
    return (
      <div>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };
  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])
  useEffect(() => {
    fetchUserData();
    return () => {
      if (socketRef.current) {
        socketRef.current.off("connect");
        socketRef.current.disconnect();
      }
    }
  }, [])
  onForegroundMessage()
    .then((payload) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));
  useEffect(() => {
    let intervalId = undefined;
    if (userData.length > 0) {
      socketRef.current = io('https://backend.la7i27alak.com/', { transports: ['websocket'] });
      socketRef.current.on('connect', () => {
        intervalId = setInterval(() => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const newLocation = {
                user: userData[0],
                lat: position.coords.latitude.toString(),
                long: position.coords.longitude.toString(),
                timestamp: new Date().getTime() // Current timestamp in ms
              };

              if(locRef.current !== undefined && locRef.current.lat == newLocation.lat && locRef.current.long == newLocation.long) {
                // console.log("Same location as before");
              } else {
                locRef.current = {
                  lat: newLocation.lat,
                  long: newLocation.long
                }

                // console.log("Position has changed", position)
                socketRef.current.emit('newCoordinates', newLocation, (error) => {
                  if (error) {
                    // console.log("Failed to send newCoordinates event:", error);
                  } else {
                    // console.log("Successfully sent newCoordinates event.");
                  }
                });
              }
            },
            error => {
            },
            { enableHighAccuracy: true, maximumAge: 0 }
          );
        }, 5000);
      });
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [userData])
  const retrieveToken = async () => {
    if (token) {
      try {
        const currentToken = await getToken(messaging, { vapidKey: 'BExWqY3AWRQPXdBAVUQ7U_BIQKa0eLnCoX1GTni6ku4AjnW6gdFpxCqQ-_1yHit1H2TeOq3qj-uQfOFuI8_633M' });
        if (currentToken) {
          const fcmTokenRef = ref(db, `fcmTokens/${localStorage.getItem('uid')}`);
          await set(fcmTokenRef, currentToken);
        } else {
          // console.log('No registration token available. Request permission to generate one.');
        }
        const data = { "token": currentToken }
        const response = await dispatch(AddFcmToken(data))
      } catch (err) {
        // console.log('An error occurred while retrieving token:', err);
      }
    } else {
      // console.log("user not signed in ")
    }
  };
  useEffect(() => {
    retrieveToken()
  }, [])

  useEffect(() => {
    dispatch({ type: 'CLEAR_VALUE' });
  }, []);
  return (
    <>
    <MobileBanner/>
      <Toaster />
      <ToastContainer />
      <BrowserRouter>
        <Notification />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/site-map" element={<SiteMap />} />
            <Route path="/editinfo" element={<EditInfo />} />
            <Route
              path="/sell/product/Products-Information/Product-Preview/:productName"
              element={<ProductDescription />}
            />
            <Route
              path="/:type/:serviceName/:id"
              element={<ProductDetails />}
            />
            <Route
              path="/product/:productName/:productId/bid"
              element={<BidDetailsPage />}
            />
            <Route path="/user-review/:name/:UserId" element={<UserPage />} />
            <Route path="/user-review/products" element={<UserProducts />} />
            <Route
              path="/search/:product/:text/:CatId?/:SubId?"
              element={<SearchPage />}
            />
            <Route
              path="/products"
              element={<AllProductPage />}
            />
            <Route
              path="/services"
              element={<AllServicesPage />}
            />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route path="/categories/:categoryName" element={<CategoryPage />} />
            <Route path="/bids" element={<AllBidsPage />} />
            <Route path="/featured-product" element={<FeaturedProductPage />} />
            <Route path="/Offered-Services" element={<OfferedServicesPage />} />
            <Route path="/Requested-Services" element={<RequestServicesPage />} />
            <Route path="/Recommended-For-You" element={<RecommendedForPage />} />
            <Route path="/categories/:categoryName/:subCategoryName/:categoryId/:Categoryid" element={<CategoryPageDropDown />} />
            <Route path="/services/:categoryName/:categoryId" element={<ServicePage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/help/sell" element={<SellPage />} />
            <Route path="/help/buy" element={<BuyPage />} />
            <Route path="/help/bid" element={<BidPage />} />
            <Route path="/help/feature-products" element={<FeatureProducts />} />
            <Route path="/help/payment" element={<Payment />} />
            <Route path="/help/profile" element={<Profile />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/special-offers" element={<SpecialOffersPage />} />
            <Route path="/special-offers/:selectedDayURL" element={<SpecialOffersPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/term-of-use" element={<TermOfUse />} />
            <Route path="/FAQ" element={<FAQPage />} />
            <Route path="/*" element={<NotFound />} />
            {/* Private Route */}
            <Route element={<PrivateRoutes />}>
              <Route path="/sell/products/:subtitle/:id"
                element={<Properties />}
              />
              <Route path="/user/favorite-categories"
                element={<FavCategs />}
              />
              <Route path="/user/waiting-list"
                element={<BuyerWaitList />}
              />
              <Route path="/user/live-bids"
                element={<MyLiveBids />}
              />
              <Route path="/user/blocked-accounts"
                element={<BlockedAccount />}
              />
              <Route path="/user/delete-account"
                element={<DeleteAccountPage />}
              />
              {/* //! general mana 3am nesta3mela */}
              <Route
                path="/sell/products/products-categories/product-information/form"
                element={<SellProductForm />}
              />
              <Route
                path="/sell/services/offer"
                element={<SellServiceOfferCategory />}
              />
              <Route
                path="/sell/services/request"
                element={<SellServiceRequestCategory />}
              />
              <Route
                path="/sell/services/offer/:serviceCat/:serviceCatId"
                element={<SellServiceForm />}
              />
              <Route
                path="/sell/services/request/:serviceCat/:serviceCatId"
                element={<RequestServiceForm />}
              />
              <Route path="/notifications" element={<NotificationsPage />} />
              <Route path="/chat" element={<ChatPage />} />
              <Route path="/chat/:chatid" element={<ChatPage />} />
              <Route
                path="/sell/product/Products-Information/Product-Preview/:productName"
                element={<ProductDescription />}
              />
              <Route
                path="/product/:productName/bid"
                element={<BidDetailsPage />}
              />
              <Route
                path="/sell/products"
                element={<SellOrBidProductCategory />}
              />
              <Route path="/wishlist" element={<WishList />} />
              <Route
                path="/notifications-setting"
                element={<NotificationSetting />}
              />
              <Route path="/user/order-history" element={<OrderHistoryPage />} />
              <Route path="/user/edit-profile" element={<EditProfilePage />} />
              <Route
                path="/user/edit-profile/change-password"
                element={<ChangePasswordPage />}
              />
              <Route path="/user/my-offers/" element={<MyProductsPage />} />
              <Route path="/edit-product/:ProductId" element={<EditProduct />} />
              <Route path="/edit-service" element={<EditService />} />
              <Route
                path="/user/my-offers/waiting-list/:id"
                element={<WaitingListPage />}
              />
              <Route
                path="/user/waiting-list/track-seller"
                element={<RequestAcceptedPage />}
              />
              <Route
                path="/user/my-offers/waiting-list/track-buyer"
                element={<RequestAcceptedPageBuyer />}
              />
              <Route path="/user/addresses" element={<AddressPage />} />
              <Route path="/user/payment" element={<PaymentPage />} />
              <Route path="/user/payment-history" element={<PaymentHistory />} />
              <Route path="/user/payment/add-card" element={<AddCardPage />} />
              <Route
                path="/user/addresses/add-address"
                element={<AddAddressPage />}
              />
              {role ? (
                <>
                  <Route path="/admin/dashboard" element={<AdminDashboard />} />
                  <Route path="/admin/adminCategories" element={<AdminCategories />} />
                  <Route path="/admin/catchoftheday" element={<AdminCOD />} />
                  <Route path="/admin/sets" element={<AdminSets />} />
                  <Route path="/admin/analytics" element={<AdminAnalytics />} />
                  <Route path="/admin/notification" element={<Adminnot />} />
                  <Route path="/admin/adminchat" element={<AdminChatPage />} />
                  <Route path="/admin/edit-service-category/:id" element={<EditServiceCategory />} />
                  <Route path="/admin/edit-product-category/:id" element={<EditProductCategory />} />
                  <Route path="/admin/products" element={<AdminProducts />} />
                  <Route path="/admin/dashboardUser" element={<DashboardUsers />} />
                  <Route path="/admin/reports" element={<AdminReports />} />
                  <Route path="/admin/bids" element={<EndedBidPage />} />
                  <Route path="/admin/deals" element={< RateExperienceAdmin />} />
                 

                </>
              ) : (
                null
              )}
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
      {isVisible && (
        <button className="scroll-to-top-button" onClick={scrollToTop}>
          <FaArrowUp />
        </button>
      )}
    </>
  );
}
export default App;
