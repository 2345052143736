import React, { useEffect, useState } from "react";
import styles from "./Layout.module.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import CurrentPath from "../CurrentPath/CurrentPath";
import { useSelector } from "react-redux";
const Layout = ({ children, title, footer = true, isFilterIcon = false, SubCategoryId, onCategoryFilter, bids, featured, offered, requested, service, typeId, allProducts, allServices, onObjectChange, sort, text, offer, searchCatId, searchSubId, type, searchBid, CategoryId, FeaturedProduct, FeaturedBid, search }) => {
  const filterBar = useSelector((state) => state.filterBar.isFilterBarOn);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  return (
    <>
      <Header isFilterIcon={isFilterIcon} SubCategoryId={SubCategoryId} onCategoryFilter={onCategoryFilter} bids={bids} featured={featured} offered={offered} requested={requested} service={service} typeId={typeId} allProducts={allProducts} allServices={allServices} onObjectChange={onObjectChange} sort={sort} text={text} offer={offer} searchCatId={searchCatId} searchSubId={searchSubId} type={type} searchBid={searchBid} CategoryId={CategoryId} FeaturedProduct={FeaturedProduct} FeaturedBid={FeaturedBid} search={search} />
      <div
        className={`${styles.section} ${darkMode ? styles.sectionDark : ''} ${filterBar ? styles.sectionBar : ''}`}
      >
        <div className={styles.path}>
          <CurrentPath />
        </div>
        {title && <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>{title}</h1>}
        {children}
      </div>
      {footer && <Footer />}
    </>
  );
};
export default Layout;
