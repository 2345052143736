import React from "react";
import "./Type.scss";
const Type = ({ letter }) => {
  let color = "";
  switch (letter.toLowerCase()) {
    case "p":
      color = "yellow";
      break;
    case "s":
      color = "blue";
      break;
    case "b":
      color = "red";
      break;
    default:
      color = "gray";
  }
  return (
    <div className={`circle ${color}`}>
      <span className="letter">{letter}</span>
    </div>
  );
};
export default Type;