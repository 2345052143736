import React, { useState, useEffect } from "react";
import styles from "./ImageComponent.module.scss";
import { AiOutlineEye } from "react-icons/ai";
import { AiFillHeart, AiOutlineHeart, AiFillBell } from "react-icons/ai";
import { ReactComponent as WhatsApp } from "../../../../assests/svg/whatsapp green.svg";
import { ReactComponent as FB } from "../../../../assests/svg/facebook.svg";
import { MdListAlt } from "react-icons/md";
import { AiOutlineLink } from "react-icons/ai";
import { useSelector } from "react-redux";
import { AddWishlist, RemoveWishList, GetWishList, GetWishlistSuccess } from "../../../../store/actions/whishlistActions";
import { useDispatch } from "react-redux";
import Modal from "../../../../common/components/Modal/Modal";
import ConfirmModal from "../../../../common/components/ConfirmModal/ConfirmModal";
import Loader from "../../../../common/components/Loader/Loader";
import ErrorMessage from "../../../../common/components/ErrorMessage/ErrorMessage";
import { GetServicesWishlist } from "../../../../store/actions/servicesActions";
import { AddServiceToWishlist } from "../../../../store/actions/whishlistActions";
import { RemoveServiceWishList } from "../../../../store/actions/servicesActions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShareLinkAction } from "../../../../store/actions/shareLinkAction";
import { countDown } from "../../../../common/Utils";
const ImageComponent = ({ featured, images, views, likes, id, isService, waiting, name, end, timer }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userLogin);
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loadingWishlist, setLoadingWishlist] = useState(false)
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const ServicesWishlist = useSelector((state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList)
  const wishList = useSelector((state) => state.Wishlist?.Wishlist?.data?.wishList)
  let isExpired;
  useEffect(() => {
    if (end) {
      isExpired = countDown(end)
    }
    if (isService) {
      dispatch(GetServicesWishlist())
    } else {
      dispatch(GetWishList())
    }
  }, [dispatch, id, isService]);
  const whishlistArray = useSelector((state) => state.whishlistArray);
  const { whishlistID, error, loading } = whishlistArray;
  const removeFromWishList = useSelector((state) => state.removeFromWishList);
  const {
    success,
    error: errorRemove,
    loading: loadingRemove,
  } = removeFromWishList
  const isProductInWishlist = (wishList ?? []).some(product => product._id === id) || (ServicesWishlist ?? []).some(product => product._id === id);
  const handleHeartClick = async () => {
    if (loggedInUser) {
      if (isProductInWishlist) {
        setIsOpen(true);
      } else {
        if (isService) {
          dispatch(AddServiceToWishlist(id))
            .then(() => {
              const updatedWishList = wishList ? [...wishList, { _id: id }] : [{ _id: id }];
              dispatch(GetServicesWishlist())
            })
            .catch((error) => {
              // Handle the error here, if necessary
            });
        } else {
          const formData = new FormData();
          formData.append("productId", id);
          const response = await dispatch(AddWishlist(formData))
          if (response) {
            dispatch(GetWishList())
          }
        }
      }
    } else {
      toast.error('Please login first.')
      setIsOpenModal(true);
    }
  };
  const removeWishList = async (event) => {
    event.stopPropagation();
    setLoadingWishlist(true)
    if (isService) {
      const response = await dispatch(RemoveServiceWishList(id))
      if (response) {
        dispatch(GetServicesWishlist())
        setIsOpen(false)
      }
      setLoadingWishlist(false)
    } else {
      const formData = new FormData();
      formData.append("productId", id);
      setLoadingWishlist(true)
      const response = await dispatch(RemoveWishList(formData))
      if (response) {
        dispatch(GetWishList())
        setIsOpen(false)
      }
      setLoadingWishlist(false)
    }
  };
  const [mainImg, setMainImg] = useState(images && images[0]);
  const [otherImgs, setOtherImgs] = useState(
    images && images.filter(function (el) {
      return el !== mainImg;
    })
  );
  const response = useSelector(state => state.getLink);
  const modifiedName = name && name.replace(/\s/g, '-');
  const handleWhatsAppClick = () => {
    if (isExpired === 'Expired' && timer) {
      toast.warning("Product expired.")
    } else {
      let currentUrl;
      if (isService) {
        currentUrl = `https://share.la7i27alak.com/share/service/${modifiedName}/${id}`;
      } else {
        currentUrl = `https://share.la7i27alak.com/share/product/${modifiedName}/${id}`;
      }
      const text = `Check out this link: ${currentUrl}`;
      const encodedText = encodeURIComponent(text);
      const url = `https://api.whatsapp.com/send?text=${encodedText}`;
      window.open(url, '_blank');
    }
  };
  const handleFBClick = () => {
    if (isExpired === 'Expired' && timer) {
      toast.warning('Product expired.')
    } else {
      let currentUrl;
      if (isService) {
        currentUrl = `https://share.la7i27alak.com/share/service/${modifiedName}/${id}`;
      } else {
        currentUrl = `https://share.la7i27alak.com/share/product/${modifiedName}/${id}`;
      }
      const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`;
      window.open(fbShareUrl, 'Share on Facebook', 'width=600,height=400');
    }
  };
  const imgHandler = (index) => {
    let temp = mainImg;
    setMainImg(otherImgs[index]);
    setOtherImgs((prevState) => prevState.filter((img, i) => i != index));
    setOtherImgs((prevState) => [...prevState, temp]);
  };
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyLink = () => {
    if (isExpired === 'Expired' && timer) {
      toast.warning("Product expired.")
    } else {
      let currentUrl;
      if (isService) {
        currentUrl = `https://share.la7i27alak.com/share/service/${modifiedName}/${id}`;
      } else {
        currentUrl = `https://share.la7i27alak.com/share/product/${modifiedName}/${id}`;
      }
      const input = document.createElement('input');
      input.setAttribute('value', currentUrl);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      setIsCopied(true);
      toast.success('Copied')
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
  };
  return (
    <>
      <Modal
        open={isOpenModal}
        closeHandler={() => setIsOpenModal(false)}
      // page={"login"}
      />
      <div className={styles.images_container}>
        <div className={styles.big_image_container}>
          {isProductInWishlist ? (
            <div className={styles.wishlist}
              onClick={() => handleHeartClick()} >
              <svg viewBox="0 0 35 31" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M31.2918 3.00002C29.4634 1.55208 27.1398 0.839235 24.7933 1.00635C22.4467 1.17347 20.2533 2.20801 18.6587 3.89971L17.3277 5.24356L15.9966 3.89971C14.4021 2.20801 12.2086 1.17347 9.86208 1.00635C7.51551 0.839235 5.19191 1.55208 3.36352 3.00002C2.36647 3.84292 1.55511 4.87527 0.978757 6.03433C0.402404 7.19339 0.0730964 8.45495 0.0108487 9.74233C-0.051399 11.0297 0.154713 12.316 0.616659 13.5231C1.0786 14.7301 1.78673 15.8327 2.69801 16.7639L15.7987 29.9909C15.9968 30.1932 16.2348 30.3542 16.4983 30.4642C16.7618 30.5742 17.0453 30.6309 17.3318 30.6309C17.6183 30.6309 17.9019 30.5742 18.1654 30.4642C18.4288 30.3542 18.6668 30.1932 18.865 29.9909L31.9657 16.7639C32.8778 15.8332 33.5867 14.7307 34.0492 13.5236C34.5117 12.3164 34.7181 11.0298 34.6558 9.74219C34.5936 8.45454 34.264 7.19275 33.687 6.03365C33.1101 4.87455 32.298 3.84239 31.3001 3.00002H31.2918Z" fill="#0298A6" /> </svg>
            </div>
          ) : (
            <div className={styles.wishlist} onClick={() => handleHeartClick()}>
              <svg viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M32.5927 3.03518C30.7467 1.58724 28.4008 0.874391 26.0317 1.04151C23.6625 1.20862 21.448 2.24316 19.8381 3.93487L18.4943 5.27872L17.1505 3.93487C15.5406 2.24316 13.326 1.20862 10.9569 1.04151C8.58778 0.874391 6.24183 1.58724 4.39586 3.03518C3.38922 3.87808 2.57006 4.91043 1.98817 6.06949C1.40627 7.22855 1.0738 8.4901 1.01095 9.77748C0.948107 11.0649 1.1562 12.3512 1.62259 13.5582C2.08897 14.7653 2.80391 15.8679 3.72395 16.799L16.9506 30.026C17.1506 30.2283 17.3909 30.3893 17.657 30.4993C17.923 30.6093 18.2092 30.666 18.4985 30.666C18.7877 30.666 19.074 30.6093 19.34 30.4993C19.606 30.3893 19.8463 30.2283 20.0464 30.026L33.273 16.799C34.1939 15.8684 34.9097 14.7659 35.3766 13.5587C35.8435 12.3516 36.0519 11.065 35.9891 9.77734C35.9262 8.4897 35.5934 7.22791 35.011 6.06881C34.4285 4.90971 33.6086 3.87755 32.6011 3.03518H32.5927Z" fill="#F8FEFF" stroke="#0298A6" stroke-width="2" /> </svg>
            </div>
          )}
          {featured && <div className={styles.featured}>Featured</div>}
          <img
          itemprop="image"
            src={
              mainImg
                ? mainImg
                : "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?format=webp&v=1530129081"
            }
            alt="product"
            className={styles.image}
          />
          <div className={`${styles.image_details} ${darkMode ? styles.image_detailsDark : ''}`}>
            <p className={styles.image_details_text}>
              <AiOutlineEye className={styles.icon} />
              {views} Views
            </p>
            <p className={styles.image_details_text}>
              <MdListAlt className={styles.icon} /> {waiting} Waiting List
            </p>
            <p className={styles.image_details_text}>
              <AiOutlineHeart className={styles.icon} />
              {likes} Likes
            </p>
          </div>
          <div className={`${styles.social_media_container} ${darkMode ? styles.social_media_containerDark : ''}`}>
            Share: <WhatsApp onClick={handleWhatsAppClick} className={styles.social} />{" "}
            <FB onClick={handleFBClick} className={styles.social} />{" "}
            <AiOutlineLink onClick={handleCopyLink} className={styles.social} />{" "}
          </div>
          {/* <div className={styles.copied}>
            {isCopied &&
              <p className={styles.copiedp}>Copied</p>
            }
          </div> */}
        </div>
        <div className={styles.small_images_container}>
          {otherImgs && otherImgs.map((image, index) => (
            <img
              key={index}
              src={image}
              alt=""
              className={styles.small_image}
              onClick={() => imgHandler(index)}
            />
          ))}
        </div>
      </div>
      <ConfirmModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <h1 className={styles.confirm_title}>Confirmation</h1>
        <p className={styles.confirm_message}>
          Are you sure do want to remove this product from your wishlist?
        </p>
        {loadingWishlist ? (
          <div className="center">
            <Loader />{" "}
          </div>
        ) : errorRemove ? (
          <div className="center">
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        ) : (
          <div className={styles.buttons_container}>
            <button className={styles.cancel} onClick={() => setIsOpen(false)}>
              Cancel
            </button>
            <button
              className={styles.confirm}
              onClick={removeWishList}
            >
              Confirm
            </button>
          </div>
        )}
      </ConfirmModal>
    </>
  );
};
export default ImageComponent;