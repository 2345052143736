import React, { useState } from "react";
import styles from "./Card.module.scss";
import { ReactComponent as More } from "../../../assests/svg/more.svg";
import { ReactComponent as Eye } from "../../../assests/svg/eye.svg";
import { ReactComponent as WaitingList } from "../../../assests/svg/waiting list.svg";
import { ReactComponent as Likes } from "../../../assests/svg/like.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  AiFillStar,
  AiOutlineEye,
  AiOutlineFieldNumber,
  AiOutlineGold,
  AiOutlineHeart,
  AiOutlineNumber,
} from "react-icons/ai";
import { MdCalendarViewMonth } from "react-icons/md";
import { BsEyeFill, BsThreeDotsVertical } from "react-icons/bs";
import { BsPencil } from "react-icons/bs";
import { BsFillTrashFill } from "react-icons/bs";
import car from "../../../assests/images/VEHICLES.png";
import { useDispatch, useSelector } from "react-redux";
import { FeatureProduct } from "../../../store/actions/ProductStateAction";
import { DeleteProductUser } from "../../../store/actions/productDetailsAction";
import { DeleteServiceById } from "../../../store/actions/servicesActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ComingForSureModal from "../../ProductDescriptionPage/components/ComingForSureModal/ComingForSureModal";
import { set } from "date-fns";
import { SellProduct } from "../../../store/actions/ProductStateAction";
import ConfirmModal from "../../../common/components/ConfirmModal/ConfirmModal";
import Loader from "../../../common/components/Loader/Loader";
const Card = ({
  status,
  active,
  title,
  price,
  image,
  timeposted,
  likes,
  views,
  waiting,
  id,
  isService,
  isDiscount,
  percentage,
  newPrice,
  featured,
  quantity,
  bid,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false); // new state variable
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [isFeatured, setIsFeatured] = useState(false);
  const [loadingEndDeal, setLoadingEndDeal] = useState(false);
  const [isSold, setIsSold] = useState(false);
  const [isStatus, setIsStatus] = useState(status);
  const [isOpen, setIsOpen] = useState(false);
  function toggleDropdown() {
    setShowDropdown(!showDropdown);
  }
  const handleDelete = (ProductId) => {
    if (isService) {
      dispatch(DeleteServiceById(ProductId));
      setIsDeleted(true);
    } else {
      dispatch(DeleteProductUser(ProductId));
      setIsDeleted(true);
    } // set the state variable to true when product is deleted
  };
  const handleSell = async (ProductId) => {
    setLoadingEndDeal(true);
    const response = await dispatch(SellProduct(ProductId));
    setLoadingEndDeal(false);
    if (response) {
      setLoadingEndDeal(false);
      setIsStatus("sold");
      setIsSold(true);
      toast.success("Offer ended successfully.");
      setIsOpen(false);
    }
  };
  const [payModal, setPayModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const makeFeature = async (ProductId) => {
    setIsLoading(true);
    const parms = { ProductId: ProductId, type: isService };
    const response = await dispatch(FeatureProduct(parms));
    if (response) {
      if (isService) {
        toast.success("This service is now featured.");
      } else {
        toast.success("This product is now featured.");
      }
      setIsLoading(false);
      setPayModal(false);
      setIsFeatured(true);
    } else {
      navigate("/user/payment/add-card", { state: { isFaster: true } });
      toast.error("Please add a payment.");
      setIsLoading(false);
      setIsFeatured(false);
    }
  };
  // Step 1: Parse the date string to a Date object
  const parsedDate = new Date(timeposted);
  // Step 2: Get the individual date components (year, month, day)
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so adding 1
  const day = String(parsedDate.getDate()).padStart(2, "0");
  // Step 3: Combine the date components to form the desired date string
  const formattedDate = `${year}-${month}-${day}`;
  const newprice = price - price * (percentage / 100);
  if (isDeleted) return null;
  return (
    <>
      <ComingForSureModal
        open={payModal}
        closeHandler={() => setPayModal(false)}
        paymentHandler={() => makeFeature(id)}
        isLoading={isLoading}
        feature={true}
        isService={isService}
        forsure={false}
      />
      <section className={styles.card_section}>
        <div
          className={`${styles.card_header} ${
            darkMode ? styles.card_headerDark : ""
          } `}
        >
          <p className={`${styles.date} ${darkMode ? styles.dateDark : ""} `}>
            Posted at: {formattedDate}
          </p>
          {active ? (
            <div className={styles.EdDelDropMYProdDiv} onClick={toggleDropdown}>
              <button>
                <More className={styles.more_icon} />
              </button>
              {showDropdown && (
                <div
                  className={`${styles.EdDelDropMYProd} ${
                    darkMode ? styles.EdDelDropMYProdDark : ""
                  }`}
                >
                  {isService ? (
                    <div>
                      <button
                        onClick={() =>
                          navigate(
                            `/${"service"}/${title.replace(" ", "-")}/${id}`,
                            { state: { id: id, isService: true } }
                          )
                        }
                      >
                        <p
                          className={`${styles.edit} ${
                            darkMode ? styles.editDark : ""
                          }`}
                        >
                          <BsEyeFill
                            className={`${styles.ic} ${
                              darkMode ? styles.icDark : ""
                            }`}
                          />{" "}
                          View Details
                        </p>
                      </button>
                      <hr />
                      <button
                        onClick={() =>
                          navigate(`/edit-service`, { state: { id } })
                        }
                      >
                        <p
                          className={`${styles.edit} ${
                            darkMode ? styles.editDark : ""
                          }`}
                        >
                          <BsPencil
                            className={`${styles.ic} ${
                              darkMode ? styles.icDark : ""
                            }`}
                          />{" "}
                          Edit Service
                        </p>
                      </button>
                      <hr />
                      <button onClick={() => handleDelete(id)}>
                        <p className={styles.delete}>
                          <BsFillTrashFill className={styles.icd} /> Delete
                          Service
                        </p>
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        onClick={() =>
                          navigate(
                            `/${"product"}/${title.replace(" ", "-")}/${id}`,
                            { state: { id: id, isService: false } }
                          )
                        }
                      >
                        <p
                          className={`${styles.edit} ${
                            darkMode ? styles.editDark : ""
                          }`}
                        >
                          <BsEyeFill
                            className={`${styles.ic} ${
                              darkMode ? styles.icDark : ""
                            }`}
                          />{" "}
                          View Details
                        </p>
                      </button>
                      <hr />
                      <Link to={`/edit-product/${id}`}>
                        <button>
                          <p
                            className={`${styles.edit} ${
                              darkMode ? styles.editDark : ""
                            }`}
                          >
                            <BsPencil
                              className={`${styles.ic} ${
                                darkMode ? styles.icDark : ""
                              }`}
                            />{" "}
                            Edit Product
                          </p>
                        </button>
                      </Link>
                      <hr />
                      <button onClick={() => handleDelete(id)}>
                        <p className={styles.delete}>
                          <BsFillTrashFill className={styles.icd} /> Delete
                          Product
                        </p>
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
        </div>
        <div
          className={`${styles.card_container} ${
            active ? styles.card_containeractive : ""
          }`}
        >
          <div className={styles.top}>
            {isService ? (
              <img
                src={image}
                alt="car"
                className={styles.image}
                onClick={() =>
                  navigate(`/${"service"}/${title.replace(" ", "-")}/${id}`, {
                    state: { id: id, isService: true },
                  })
                }
              />
            ) : (
              <img
                src={image}
                alt="car"
                className={styles.image}
                onClick={() =>
                  navigate(`/${"product"}/${title.replace(" ", "-")}/${id}`, {
                    state: { id: id, isService: false },
                  })
                }
              />
            )}

            <div
              className={`${styles.details_container} ${
                !active ? styles.small_gap : ""
              }`}
            >
              {isDiscount ? (
                <div
                  className={`${styles.price__containermb} ${
                    isDiscount ? styles.price__containermbdisc : ""
                  }`}
                >
                  <h3
                    className={`${styles.pricemb} ${
                      darkMode ? styles.pricembDark : ""
                    }`}
                  >
                    $ {newprice}
                  </h3>
                  <div className={styles.oldmbpd}>
                    <h3
                      className={`${styles.oldmbprice} ${
                        darkMode ? styles.oldmbpriceDark : ""
                      }`}
                    >
                      {price}
                    </h3>
                    <h3
                      className={`${styles.discountmb} ${
                        darkMode ? styles.discountmbDark : ""
                      }`}
                    >
                      %{percentage}
                    </h3>
                  </div>
                </div>
              ) : (
                <div className={styles.price__containermb}>
                  <h3
                    className={`${styles.pricemb} ${
                      darkMode ? styles.pricembDark : ""
                    }`}
                  >
                    $ {price && price}
                  </h3>
                </div>
              )}
              <p
                className={`${styles.text} ${darkMode ? styles.textDark : ""} `}
              >
                {title.length > 20 ? `${title.substring(0, 20)}...` : title}
              </p>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className={styles.button_container}>
                  {!isService &&
                    status === "confirmed" &&
                    isSold === false &&
                    !bid && (
                      <button
                        className={`${styles.waiting_btn} ${
                          darkMode ? styles.waiting_btnDark : ""
                        } `}
                        onClick={() =>
                          navigate(`/user/my-offers/waiting-list/${id}`, {
                            state: {
                              id: id,
                            },
                          })
                        }
                      >
                        Waiting List
                      </button>
                    )}
                  {isService
                    ? active === true &&
                      featured === false &&
                      isFeatured === false &&
                      isSold === false && (
                        <button
                          className={`${styles.sell_btn} ${
                            darkMode ? styles.sell_btnDark : ""
                          }`}
                          onClick={() => setPayModal(true)}
                        >
                          Deliver faster
                        </button>
                      )
                    : active === true &&
                      featured === false &&
                      isFeatured === false &&
                      isSold === false && (
                        <button
                          className={`${
                            bid? styles.waiting_btn1:styles.sell_btn} ${
                            darkMode ? styles.sell_btnDark : ""
                          }`}
                          onClick={() => setPayModal(true)}
                        >
                          Sell faster
                        </button>
                      )}
                  {isService
                    ? active === true &&
                      (featured === true || isFeatured === true) &&
                      isSold === false && (
                        <button
                          className={`${
                            bid ? styles.waiting_btn1 : styles.sell_btnf
                          } ${darkMode ? styles.sell_btnfDark : ""}`}
                        >
                          Featured{" "}
                        </button>
                      )
                    : active === true &&
                      (featured === true || isFeatured === true) &&
                      isSold === false && (
                        <button
                          className={`${
                            bid ? styles.waiting_btn1 : styles.sell_btnf
                          } ${darkMode ? styles.sell_btnfDark : ""}`}
                        >
                          Featured{" "}
                        </button>
                      )}
                </div>
                <div className={styles.button_container}>
                  {active &&
                    status !== "pending" &&
                    !isService &&
                    isSold === false && (
                      <button
                        className={`${styles.end_deal} ${
                          darkMode ? styles.end_dealDark : ""
                        }`}
                        onClick={() => setIsOpen(true)}
                      >
                        End Deal
                      </button>
                    )}
                </div>
              </div>
            </div>
            <div
              className={`${styles.line} ${
                active ? styles.active : styles.sold
              }`}
            ></div>
            <div className={styles.info_container}>
              {!isService && (
                <p
                  className={`${styles.text} ${
                    darkMode ? styles.textDark : ""
                  } `}
                >
                  <AiOutlineGold
                    className={`${styles.icon} ${
                      darkMode ? styles.iconDark : ""
                    } `}
                  />{" "}
                  {quantity} {quantity <= 1 ? "Unit" : "Units"}
                </p>
              )}
              <p
                className={`${styles.text} ${darkMode ? styles.textDark : ""} `}
              >
                <AiOutlineEye
                  className={`${styles.icon} ${
                    darkMode ? styles.iconDark : ""
                  } `}
                />{" "}
                {views} views
              </p>
              {!isService && (
                <p
                  className={`${styles.text} ${
                    darkMode ? styles.textDark : ""
                  } `}
                >
                  <MdCalendarViewMonth
                    className={`${styles.icon} ${
                      darkMode ? styles.iconDark : ""
                    } `}
                  />{" "}
                  {waiting} Waiting list
                </p>
              )}
              <p
                className={`${styles.text} ${darkMode ? styles.textDark : ""} `}
              >
                <AiOutlineHeart
                  className={`${styles.icon} ${
                    darkMode ? styles.iconDark : ""
                  } `}
                />{" "}
                {likes} Likes
              </p>
              {isSold ? (
                <div className={styles.status_sold}>Sold</div>
              ) : status === "confirmed" ? (
                <div className={styles.status_active}>Active</div>
              ) : status === "pending" ? (
                <div className={styles.status_active}>Pending</div>
              ) : status === "sold" ? (
                <div className={styles.status_sold}>Sold</div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <ConfirmModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <h1
          className={`${styles.confirm_title} ${
            darkMode ? styles.confirm_titleDark : ""
          }`}
        >
          Confirmation
        </h1>
        <p
          className={`${styles.confirm_message} ${
            darkMode ? styles.confirm_messageDark : ""
          }`}
        >
          Are you sure you want to end this offer?
        </p>
        {loadingEndDeal ? (
          <div className="center">
            <Loader />{" "}
          </div>
        ) : (
          <div className={styles.buttons_container}>
            <button className={styles.cancel} onClick={() => setIsOpen(false)}>
              Cancel
            </button>
            <button className={styles.confirm} onClick={() => handleSell(id)}>
              Confirm
            </button>
          </div>
        )}
      </ConfirmModal>
    </>
  );
};
export default Card;
