import React, { useState, useEffect } from "react";
import styles from "./AdminDashboard.module.scss";
import AdminLayout from "../AdminLayout/AdminLayout";
// import { ReactComponent as Calendar } from "../../../assests/svg/calender.svg";
import { ReactComponent as Search } from "../../../assests/svg/search icon.svg";
import TopCard from "./Components/TopCard/TopCard";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { MdHeight } from "react-icons/md";
import "chartjs-plugin-datalabels";
import Chart from "chart.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsCaretDown, BsFilter } from "react-icons/bs";
import Calendar from "../../../assests/svg/calender.svg";
import { Table } from "react-bootstrap";
import img from "../../../assests/svg/back person.svg";
import { useDispatch, useSelector } from "react-redux";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  GetProductByMonth,
  GetTotalBids,
  GetProductDifference,
  GetServiceDifference,
  GetProductsCount,
  GetServiceCount,
  GetTopSellingUserOfTheDay,
  GetAverageDealResults,
  GetTotalSales,
  GetRangeForAdmin,
} from "../../../store/actions/AdminDashboard";
const options = {
  scales: {
    y: {
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
      },
    },
  },
  plugins: {
    tooltip: {
      mode: "index",
      intersect: false,
    },
    filler: {
      propagate: true,
    },
  },
};
const optionsProd = {
  indexAxis: "y",
  elements: {
    bar: {},
  },
  responsive: true,
  scales: {
    x: {
      beginAtZero: true,
    },
  },
};
const Dealoptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.label || "";
          if (label) {
            label += ": ";
          }
          if (context.dataset) {
            label += context.dataset.data[context.dataIndex] + "%";
          }
          return label;
        },
      },
    },
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
      },
    },
  },
};
const AdminDashboard = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  // console.log(selectedDate);
  const [date, setDate] = useState(null);
  useEffect(() => {
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
    const day = String(selectedDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const result = {
      currentDate: formattedDate
    };
    setDate(result);

    const responsee = dispatch(GetTotalSales(result));
    console.log(responsee)
    console.log(date)
    // console.log(result);
  }, [selectedDate])
  // console.log(date);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const topSellingUser = useSelector(
    (state) => state.topSellingUser?.topSellingUser?.topSellingUsers
  );
  const topSellingUser1 = useSelector(
    (state) => state.rangeAdmin?.rangeAdmin?.topSellingUsers
  );
  const averageResults = useSelector(
    (state) => state.averageResults?.averageResults?.statusCounts
  );
  const totalCount =
    averageResults && averageResults.reduce((sum, item) => sum + item.count, 0);
  const percentageArray =
    averageResults &&
    averageResults.map((item) => (item.count / totalCount) * 100);
  const TotalSales = useSelector(
    (state) => state.TotalSales?.TotalSales?.productsSold
  );
  const hourArray = TotalSales && TotalSales.map((item) => item.hour);
  const countArray = TotalSales && TotalSales.map((item) => item.count);
  const rangeAdmin = useSelector((state) => state.rangeAdmin?.rangeAdmin);
  const totalCount1 =
    rangeAdmin &&
    rangeAdmin?.statusCounts?.reduce(
      (total, status) => total + status.count,
      0
    );
  const idArray = rangeAdmin?.statusCounts?.map((item) => item._id);

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const percentageCounts =
    rangeAdmin &&
    rangeAdmin?.statusCounts?.map((status) =>
      Math.round((status.count / totalCount1) * 100)
    );
  useEffect(() => {
    const response1 = dispatch(GetTopSellingUserOfTheDay());
    const response = dispatch(GetAverageDealResults());
    // const responsee = dispatch(GetTotalSales());
  }, [dispatch, selectedDateRange]);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Set the initial value
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const currentDate = new Date();
  const handleDateRangeChange = (ranges) => {
    setSelectedDateRange(ranges.selection);
  };
  const startDateString = selectedDateRange.startDate.toDateString();
  const endDateString = selectedDateRange.endDate.toDateString();
  const formattedDate =
    startDateString === endDateString
      ? startDateString
      : `${startDateString} - ${endDateString}`;
  // const formattedDate = `${selectedDate.toLocaleString("default", {
  //   month: "short"
  // })} ${selectedDate.getDate()}`;
  const originalStartDate = new Date(startDateString);
  const originalEndDate = new Date(endDateString);
  const Startmonth = originalStartDate.getMonth() + 1;
  const Startday = originalStartDate.getDate();
  const Startyear = originalStartDate.getFullYear();
  const Endmonth = originalEndDate.getMonth() + 1;
  const Endday = originalEndDate.getDate();
  const Endyear = originalEndDate.getFullYear();
  const formattedStartDate = `${Startmonth}/${Startday}/${Startyear}`;
  const formattedEndDate = `${Endmonth}/${Endday}/${Endyear}`;
  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const ProductByMonth = useSelector(
    (state) => state.ProductByMonth?.ProductByMonth
  );
  const TotalBids = useSelector((state) => state.TotalBids?.TotalBids?.count);
  const TotalBids1 = useSelector(
    (state) => state.rangeAdmin?.rangeAdmin?.productsWithBidCount
  );
  const ProductDifference = useSelector(
    (state) => state.ProductDifference?.ProductDifference
  );
  const ServiceDifference = useSelector(
    (state) => state.ServiceDifference?.ServiceDifference
  );
  const ProductCount = useSelector(
    (state) => state.ProductCount?.ProductCount?.count
  );
  const ServiceCount = useSelector(
    (state) => state.ServiceCount?.ServiceCount?.count
  );
  const ProductCount1 = useSelector(
    (state) => state.rangeAdmin?.rangeAdmin?.productCount
  );
  const ServiceCount1 = useSelector(
    (state) => state.rangeAdmin?.rangeAdmin?.serviceCount
  );

  useEffect(() => {
    dispatch(GetProductByMonth());
    dispatch(GetTotalBids());
    dispatch(GetProductDifference());
    dispatch(GetServiceDifference());
    dispatch(GetProductsCount());
    dispatch(GetServiceCount());

    const rawData = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
    const response = dispatch(GetRangeForAdmin(rawData));
  }, [dispatch, formattedStartDate, formattedEndDate]);
  const data = [];
  for (let month = 1; month <= 12; month++) {
    const key = `${month} - 2023`;
    const value = ProductByMonth[key] || 0;
    data.push(value);
  }
  const dataprod = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Added Products",
        data: data,
        backgroundColor: ["#0298A6", "#94B7B7"],
        borderRadius: "25",
      },
    ],
  };
  const Dealdata = {
    labels: ["Pending", "Done"],
    datasets: [
      {
        label: "My First Dataset",
        data: percentageArray,
        backgroundColor: ["#E76859", "#59AEF7"],
        borderWidth: 1,
        fill: true,
        tension: 0.4,
      },
    ],
  };
  const Dealdata1 = {
    labels: idArray,
    datasets: [
      {
        label: "My First Dataset",
        data: percentageCounts,
        backgroundColor: ["#E76859", "#FFCB3C", "#59AEF7", "#86EBF4"],
        borderWidth: 1,
        fill: true,
        tension: 0.4,
      },
    ],
  };
  const dataSales = {
    labels: hourArray,
    datasets: [
      {
        label: "Sales",
        data: countArray,
        backgorundColor:
          "linear-gradient(89.96deg, #86EBF4 -0.98%, #07727C 100%)",
        borderWidth: 1,
        fill: true,
        tension: 0.4,
      },
    ],
  };
  const dataToRender = topSellingUser1;

  return (
    <AdminLayout>
      <div className={styles.wrapper}>
        <div className={styles.top_container}>
          <div className={styles.header}>
            <h3>Dashboard</h3>
            <div className={styles.calendar}>
              <img src={Calendar} alt="" />
              <span className={styles.date}> {formattedDate}</span>
              <button onClick={toggleDatePicker}>
                <BsCaretDown />
              </button>
              {showDatePicker && (
                <div className={styles.datepicker_wrapper}>
                  <DateRangePicker
                    ranges={[selectedDateRange]}
                    onChange={handleDateRangeChange}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    className={styles.react_datepicker}
                    months={1}
                  />
                  <style>
                    {`
                    @media screen and (max-width: 670px) {
                      .rdrStaticRangeLabel { display: none; }
                      .rdrInputRange { display: none; }
                      .rdrMonth {width:200px}
                      .rdrMonthAndYearWrapper {width:200px;}
                      .rdrMonthAndYearPickers {width:140px}
                      .rdrDateDisplayWrapper {width:200px}
                      .rdrDefinedRangesWrapper {display:none}
                      select {width:70px}
                     
                    }
                  `}
                  </style>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className={styles.filter_container}>
          <div className={styles.filter}> <BsFilter />Filters</div>
        </div> */}
        <div className={styles.upcontainer}>
          <div className={styles.card_container}>
            <TopCard
              product={true}
              Bid={false}
              service={false}
              ProductCount={ProductCount1}
            />
            <TopCard
              product={false}
              Bid={true}
              service={false}
              TotalBids={TotalBids1}
            />
            <TopCard
              product={false}
              Bid={false}
              service={true}
              ServiceCount={ServiceCount1}
            />
          </div>
          <div className={styles.flex}>
            <div className={styles.sales_container}>
              <label className={styles.label}>Total sales per day</label>
              <div className={styles.salesChartContainer}>
                <div className={styles.salesChart}>
                  <Line data={dataSales} options={options} type="area" />
                  <div>
                    <label className={styles.label}>Day: </label>
                  </div>
                  <div className={styles.datePickerContainer}>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      className={styles.react_datepicker}
                    />
                  </div>
                </div>
              </div>


            </div>
            <div className={styles.products_added_container}>
              <label className={styles.label}>Products added by Month</label>
              <Bar data={dataprod} options={optionsProd} />
            </div>
          </div>
        </div>
        <div className={styles.bottom_container}>
          <div className={styles.top_users}>
            <label className={styles.label}>
              {" "}
              Top Selling Users of the Day
            </label>
            {isSmallScreen ? (
              <>
                {dataToRender &&
                  dataToRender.map((customer, index) => (
                    <div className={styles.smtable} key={index}>
                      <Table striped bordered hover style={{ width: "250px" }}>
                        <tbody>
                          <tr>
                            <td className={styles.customername}>
                              Customer Name:
                            </td>
                            <td className={styles.customername}>
                              {customer.user?.username}
                            </td>
                          </tr>
                          <tr>
                            <td className={styles.customername}>Email:</td>
                            <td className={styles.customername}>
                              {customer.user?.email}
                            </td>
                          </tr>
                          <tr>
                            <td className={styles.customername}>Sold Items:</td>
                            <td className={styles.customername}>
                              <div className={styles.sold}>
                                <p>{customer.productsSold}</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className={styles.customername}>Rating:</td>
                            <td className={styles.customername}>
                              {customer.user?.reviews?.average}/5
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  ))}
              </>
            ) : (
              <div className={styles.table}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Customer Name</th>
                      <th>Email</th>
                      <th>Sold Items</th>
                      <th>Rating</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataToRender &&
                      dataToRender.map((customer, index) => (
                        <tr key={index}>
                          <td className={styles.customername}>
                            <div className={styles.uInfo}>
                              <img
                                src={customer.user?.imageUrl}
                                alt="Customer"
                                className={styles.customerImage}
                              />
                              {customer.user?.username}
                            </div>
                          </td>
                          <td className={styles.customername}>
                            {customer.user?.email}
                          </td>
                          <td>
                            <div className={styles.sold}>
                              <p>{customer.productsSold}</p>
                            </div>
                          </td>
                          <td className={styles.customername}>
                            {customer.user?.reviews?.average}/5
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <div className={styles.dcontainer}>
            <div className={styles.average}>
              <label className={styles.label}> Average Deal Results</label>
              <div className={styles.dough}>
                <Doughnut data={Dealdata1} options={Dealoptions} />
              </div>
            </div>
            <div className={styles.online_users}>
              <TopCard online={true} />
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};
export default AdminDashboard;
