import React, { useState } from "react";
import Layout from "../../../common/components/Layouts/Layout";
import styles from "./EditModel.module.scss";
import { useSelector } from "react-redux";
import { url } from "../../../common/constants/url";
import Card from "./cards/Card";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
const EditModel = ({}) => {
    const categories = useSelector((state) => state.categories.categories);
    const loading = useSelector((state) => state.categories.loading);
    const error = useSelector((state) => state.categories.error);
    const categoriesArray = Object.values(categories);

    
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (categories.length === 0) {
      return <div>No categories found</div>;
    }
  
  
      return (
        <>
          <div className={styles.categories_container}>
          {categoriesArray[0].categories.map((category) => (
            <Card
            key={category._id}
            title={category.title}
            image={url+category.iconUrl}
            categoryId={category._id}
          />
            
          ))}
          </div>
        </>
      );
    };
  
    export default EditModel;
  