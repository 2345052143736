import React, { useState, useEffect } from "react";
import styles from "./OrderHistoryPage.module.scss";
import Header from "../../common/components/Header/Header";
import Footer from "../../common/components/Footer/Footer";
import CurrentPath from "../../common/components/CurrentPath/CurrentPath";
import EmptyHistory from "./components/EmptyHistory/EmptyHistory";
import OrderHistory from "./components/OderHistory/OrderHistory";
import { useDispatch, useSelector } from "react-redux";
import { listMyOrderHistory } from "../../store/actions/orderActions";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import Loader from "../../common/components/Loader/Loader";
import { Helmet } from "react-helmet";
import { GetBoughtHistory } from "../../store/actions/userActions";
import Layout from "../../common/components/Layouts/Layout";
const OrderHistoryPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.isOrder) {
      const pageName = "Order history"; // Replace this with the actual page name
      const pageUrl = "/user/order-history"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Order history"; // Replace this with the actual page name
      const pageUrl = "/user/order-history"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, []);
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userById = useSelector((state) => state.userById);
  const { user } = userById;
  const orderHistory = useSelector((state) => state.BoughtHistory?.BoughtHistory);
  const error = useSelector((state) => state.BoughtHistory?.error)
  const loading = useSelector((state) => state.BoughtHistory?.loading)
  useEffect(() => {
    const response = dispatch(GetBoughtHistory());
  }, [dispatch]);
  const display = () => {
    if (orderHistory && orderHistory.length === 0) {
      return <EmptyHistory />;
    } else {
      return <OrderHistory orders={orderHistory} />;
    }
  };
  return (
    <>
      <Helmet>
        <title>Order History</title>
        <meta name="Order History" content="Order history of the user" />
        <meta
          name="keywords"
          content={`Order history, la7i27alak`}
        />
      </Helmet>
      <Layout title={'Order History'}>
        <section className={`${styles.section} ${darkMode ? styles.sectionDark : ''}`}>
          {/* <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Order History</h1> */}
          {error ? (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <ErrorMessage>{error}</ErrorMessage>
            </div>
          ) : loading ? (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            display()
          )}
        </section>
      </Layout>
    </>
  );
};
export default OrderHistoryPage;
