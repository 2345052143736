import {
  UPDATE_GEOLOCATION_REQUEST,
  UPDATE_GEOLOCATION_SUCCESS,
  UPDATE_GEOLOCATION_RESET,
  UPDATE_GEOLOCATION_FAIL,
  GET_GEOLOCATION_REQUEST,
  GET_GEOLOCATION_SUCCESS,
  GET_GEOLOCATION_RESET,
  GET_GEOLOCATION_FAIL,
} from "../constants/locationConstants";
export const getGeolocationReducer = (
  state = { location: null, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case UPDATE_GEOLOCATION_REQUEST:
      return { ...state, loading: true };
    case UPDATE_GEOLOCATION_SUCCESS:
      return { ...state, loading: false, location: action.payload };
    case UPDATE_GEOLOCATION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_GEOLOCATION_RESET:
      return { location: null, loading: false, error: null };
    default:
      return state;
  }
};
export const updateGeolocationReducer = (
  state = { success: false, loading: false, error: null },
  action
) => {
  switch (action.type) {
    case GET_GEOLOCATION_REQUEST:
      return { ...state, loading: true };
    case GET_GEOLOCATION_SUCCESS:
      return { ...state, loading: false, success: false };
    case GET_GEOLOCATION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case GET_GEOLOCATION_RESET:
      return { success: false, loading: false, error: null };
    default:
      return state;
  }
};
