import React, { useEffect, useState } from "react";
import styles from "./PlaceBidModal.module.scss";
import ReactDom from "react-dom";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import { ReactComponent as Delete } from "../../../../assests/svg/delete calculator.svg";
import { useDispatch, useSelector } from "react-redux";
import { placeBidHandler } from "../../../../store/actions/bidActions";
import { AiOutlineDelete } from "react-icons/ai";
import { BsBackspace, BsBackspaceFill } from "react-icons/bs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GetSingleProduct } from "../../../../store/actions/myProductsActions";
const PlaceBidModal = ({ open, closeHandler, userId, id, bidDetails, bidList, productId }) => {
  const dispatch = useDispatch();
  const [number, setNumber] = useState(0);
  const [productDetails, setProductDetails] = useState([]);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const getBiders = useSelector((state) => state.getBiders);
  const { biders, error, loading } = getBiders;
  const authToken = localStorage.getItem('token')
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(GetSingleProduct(productId));
      setProductDetails(response.data?.product);
    };
    fetchData();
  }, [dispatch, productId]);
  const [loading1, setLoading] = useState(false)
  const makeBid = async () => {
    try {
      setLoading(true); // Start loading state

      if (bidList.length === 0) {
        if (number === 0 || number <= productDetails.pricing?.price) {
          toast.warning(`Enter an amount greater than $${productDetails.pricing?.price}.`);
          setNumber(0);
        } else {
          const response = await fetch(`https://backend.la7i27alak.com/product/bid/make`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "authorization": authToken,
            },
            body: JSON.stringify({
              productId: id,
              amount: number,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            setNumber(0);
            closeHandler();
          } else {
            toast.error("Unable to make a bid. Please try again later.");
          }
        }
      } else {
        if (number === 0 || number <= bidList[0].amount) {
          toast.warning(`Enter an amount greater than $${bidList[0].amount}.`);
          setNumber(0);
        } else if (number >= 50000000) {
          toast.warning(`You cannot place an amount greater than 50000000.`);
          setNumber(0);
        } else {
          const response = await fetch(`https://backend.la7i27alak.com/product/bid/make`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "authorization": authToken,
            },
            body: JSON.stringify({
              productId: id,
              amount: number,
            }),
          });

          if (response.ok) {
            const data = await response.json();
            setNumber(0);
            closeHandler();
          } else {
            toast.error("Unable to make a bid. Please try again later.");
          }
        }
      }
    } catch (error) {
      console.error("Error making a bid:", error);
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  const handleClick = (numericValue) => {
    // Check if the number has not reached 8 digits yet
    if (number.toString().length < 8) {
      // Check if the number after adding the new digit will not exceed 8 digits
      if (number * 10 + numericValue <= 99999999) {
        setNumber((prevNumber) => prevNumber * 10 + numericValue);
      }
    }
  }
  useEffect(() => {
    const handleKeyPress = (event) => {
      const numericValue = parseInt(event.key);
      if (!isNaN(numericValue) && number.toString().length < 8) {
        handleClick(numericValue);
      } else if (event.key === "Backspace") {
        setNumber((prevNumber) => Math.floor(prevNumber / 10));
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [number]);

  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}>
        <div className={`${styles.wrapper} ${darkMode ? styles.wrapperdark : ''}`}>
          {darkMode ? (
            <CloseDark className={styles.close} onClick={closeHandler} />
          ) : (
            <Close className={styles.close} onClick={closeHandler} />
          )}
          <h1 className={`${styles.title} ${darkMode ? styles.titledark : ''}`}>Place Your Bid</h1>
          <h3 className={`${styles.amount} ${darkMode ? styles.amountdark : ''}`}>$ {number}</h3>
          <div className={styles.container}>
            <div className={styles.calculator}>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}
                onClick={() => handleClick(1)}
              >
                1
              </span>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}
                onClick={() => handleClick(2)}
              >
                2
              </span>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}
                onClick={() => handleClick(3)}
              >
                3
              </span>
            </div>
            <div className={styles.calculator}>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}
                onClick={() => handleClick(4)}
              >
                4
              </span>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}
                onClick={() => handleClick(5)}
              >
                5
              </span>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}
                onClick={() => handleClick(6)}
              >
                6
              </span>
            </div>
            <div className={styles.calculator}>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}
                onClick={() => handleClick(7)}
              >
                7
              </span>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}
                onClick={() => handleClick(8)}
              >
                8
              </span>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}
                onClick={() => handleClick(9)}
              >
                9
              </span>
            </div>
            <div className={styles.calculator}>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}>.</span>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`} onClick={() => handleClick(0)}>
                0
              </span>
              <span
                className={`${styles.span} ${darkMode ? styles.spandark : ''}`}
                onClick={() =>
                  setNumber((prevNumber) => Math.floor(prevNumber / 10))
                }
              >
                <BsBackspace className={styles.icon} />
              </span>
            </div>
          </div>
          {loading1 ? (
            <button
              className={`${styles.confirmLd} `}
              disabled={loading1}
            >
              Confirm...
            </button>
          ) : (
            <button
              className={`${styles.confirm} ${darkMode ? styles.confirmDark : ''}`}
              onClick={makeBid}
            >
              Confirm Bid
            </button>
          )}
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default PlaceBidModal;
