import React, { useState } from "react";
import location from "../../../assests/svg/location marker.svg";
import badge from "../../../assests/svg/default small.svg";
import styles from "./index.module.scss";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsFillTrashFill } from "react-icons/bs";
import { useSwipeable } from "react-swipeable";
import { DeleteAddress, makeDefault } from "../../../store/actions/addAddressActions";
import { useSelector, useDispatch } from "react-redux";
import { getAddressByUser } from "../../../store/actions/addAddressActions";
const AddressComponent = ({ defaultAddress, title, description, id,threedots=true }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
 
  const handleDelete = () => {
    const response = dispatch(DeleteAddress(id))
    setShowDropdown(false)
    setHidden(true)
  };
  const handleswipe = () => {
    setShowDropdown(!showDropdown)
  }
  const handleSetDefault =async () => {
   const defaultDone = await dispatch(makeDefault(id));
   dispatch(getAddressByUser());
  setShowDropdown(false);
  };
  
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleswipe(),
    trackMouse: true,
    preventDefaultTouchmoveEvent: true,
  });
  return hidden ? null : (
    <li className={`${styles.item} ${darkMode? styles.itemDark:''} ${defaultAddress ? styles.defaultBorder : ""}`}
    {...swipeHandlers}
    >
      {defaultAddress && (
        <div className={styles.default_container}>
          <img src={badge} alt="default badge" className={styles.default} />
          <p className={styles.default_text}>Default</p>
        </div>
      )}
  
      {threedots&&(
      <div className={styles.three_dots}>
        <BsThreeDotsVertical onClick={() => setShowDropdown(!showDropdown)} />
        {showDropdown && (
          <div className={`${styles.dropdown} ${darkMode? styles.dropdownDark:''}`}>
            <div className={styles.dropdown_item} onClick={handleSetDefault}>
              Set as Default
            </div>
            <div className={styles.dropdown_itemDel} onClick={handleDelete}>
              <BsFillTrashFill className={styles.trash} />Delete
            </div>
            
          </div>
        )}
      </div>
)}
      <div className={styles.icon_container}>
        <img src={location} alt="location marker" className={styles.image} />
      </div>
      <div className={styles.details_container}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.text}>
          {description}
        </p>
      </div>
    </li>
  )
};
export default AddressComponent;
