export const PLACE_BID_REQUEST = "PLACE_BID_REQUEST";
export const PLACE_BID_SUCCESS = "PLACE_BID_SUCCESS";
export const PLACE_BID_FAIL = "PLACE_BID_FAIL";
export const PLACE_BID_RESET = "PLACE_BID_RESET";
export const GET_BIDERS_REQUEST = "GET_BIDERS_REQUEST";
export const GET_BIDERS_SUCCESS = "GET_BIDERS_SUCCESS";
export const GET_BIDERS_FAIL = "GET_BIDERS_FAIL";
export const GET_BIDERS_RESET = "GET_BIDERS_RESET";
export const JOIN_BID_SUCCESS = "JOIN_BID_SUCCESS";
export const JOIN_BID_FAIL = "JOIN_BID_FAIL";
export const SEND_OFFER_FOR_PRODUCT_REQUEST = "SEND_OFFER_FOR_PRODUCT_REQUEST";
export const SEND_OFFER_FOR_PRODUCT_SUCCESS = "SEND_OFFER_FOR_PRODUCT_SUCCESS";
export const SEND_OFFER_FOR_PRODUCT_FAIL = "SEND_OFFER_FOR_PRODUCT_FAIL";
export const ACCEPT_OFFER_SUCCESS = "ACCEPT_OFFER_SUCCESS";
export const ACCEPT_OFFER_FAIL = "ACCEPT_OFFER_FAIL";
export const REJECT_OFFER_SUCCESS = "REJECT_OFFER_SUCCESS";
export const REJECT_OFFER_FAIL = "REJECT_OFFER_FAIL";
export const GET_PRODUCT_OFFER_SUCCESS = "GET_PRODUCT_OFFER_SUCCESS";
export const GET_PRODUCT_OFFER_FAIL = "GET_PRODUCT_OFFER_FAIL";
export const GET_ALLOWED_BIDERS_SUCCESS = "GET_ALLOWED_BIDERS_SUCCESS";
export const GET_ALLOWED_BIDERS_FAIL = "GET_ALLOWED_BIDERS_FAIL";
export const CHECK_USER_IN_BID_SUCCESS = "CHECK_USER_IN_BID_SUCCESS";
export const CHECK_USER_IN_BID_FAIL = "CHECK_USER_IN_BID_FAIL";
export const GET_LIVE_BIDS_SUCCESS = "GET_LIVE_BIDS_SUCCESS";
export const GET_LIVE_BIDS_REQUEST = "GET_LIVE_BIDS_REQUEST";
export const GET_LIVE_BIDS_FAIL = "GET_LIVE_BIDS_FAIL";
export const END_ONE_BID_SUCCESS = "END_ONE_BID_SUCCESS";
export const END_ONE_BID_FAIL = "END_ONE_BID_FAIL";
export const END_ALL_BID_SUCCESS = "END_ALL_BID_SUCCESS";
export const END_ALL_BID_FAIL = "END_ALL_BID_FAIL";
