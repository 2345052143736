import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as Plus } from "../../../../../assests/svg/Plus Sign2.svg";
import { ReactComponent as Company } from "../../../../../assests/svg/company.svg";
import { ReactComponent as CompanySize } from "../../../../../assests/svg/company size.svg";
import { ReactComponent as Industry } from "../../../../../assests/svg/industry.svg";
import { ReactComponent as Web } from "../../../../../assests/svg/web.svg";
import Profile from "../../../../../assests/svg/Icon material-person.svg";
import CompanyP from "../../../../../assests/svg/company.svg"
import { AiOutlineUpload } from "react-icons/ai";
import styles from "./index.module.scss";
import { Formik, Form } from "formik";
import { BsBuilding } from "react-icons/bs";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  listCompanySize,
  listCompanyIndustry,
} from "../../../../../store/actions/companyActions";
import { GetIndustrySize } from "../../../../../store/actions/companyActions";
import { GetIndustryType } from "../../../../../store/actions/companyActions";
const StepOne = ({
  setStep,
  setPage,
  formData,
  setFormData,
  setImage,
  setFileUploaded,
  fileUploaded,
}) => {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);//
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const companyIndustryList = useSelector((state) => state.companyIndustryList);
  const { loading, error, industries } = companyIndustryList;
  const industrySize = useSelector((state) => state.IndustrySize?.IndustrySize?.industrySizes);
  const industryType = useSelector((state) => state.IndustryType?.IndustryType?.industryTypes);
  const companySizeList = useSelector((state) => state.companySizeList);
  const { loading: loadingSize, error: errorSize, size } = companySizeList;
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(listCompanyIndustry());
      const response = await dispatch(GetIndustrySize());
      const response1 = await dispatch(GetIndustryType());
    };
    fetchData();
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);
  const handleSubmit = (values) => {
    setFormData((prev) => ({ ...prev, ...values }));
    setPage(1);
  };
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const [preview, setPreview] = useState(null);
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    reader.onloadend = () => {
      setPreview(reader.result); // set preview state to base64-encoded image string
    };
    setOpen(false);
  };
  const websiteRegex = /^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

  const stepOneValidationSchema = Yup.object({
    companyName: Yup.string()
      .required("Company name is required"),
    industry: Yup.string().required("Industry is required"),
    companySize: Yup.string().required("Company size is required"),
  });
  return (
    <section
      className={`${styles.section} ${darkMode ? styles.sectionDark : ''}`}
    >
      <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Let's get started</h1>
      <Formik
        initialValues={formData}
        onSubmit={handleSubmit}
        validationSchema={stepOneValidationSchema}
      >
        {(props) => (
          <Form>
            <div className={styles.container}>
              <div className={styles.picture_container}>
                <div className={`${styles.pp} ${darkMode ? styles.ppDark : ''}`} ref={menuRef}>
                  <img
                    src={preview ? preview : CompanyP}
                    className={preview ? styles.preview_img : styles.default_img}
                    alt="profile"
                  />
                  <Plus
                    className={styles.plus_icon}
                    //onClick={() => {
                    //setOpen(!open);
                    //setShowModal(open);
                    //}}
                    onClick={handleClick}
                  />
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                  <div
                    className={`${styles.dropdown} ${open ? styles.active : styles.inactive} ${darkMode ? styles.dropdownDark : ''}`}
                  >
                    <div className={styles.item} onClick={handleClick}>
                      <AiOutlineUpload className={styles.icon} /> Upload Image
                      from Computer{" "}
                      <span className={styles.center_text}>PNG or JPEG</span>
                    </div>
                  </div>
                  {open && <div className={styles.modal}></div>}
                </div>
                <h3 className={`${styles.upload_text} ${open ? styles.active : styles.inactive} ${darkMode ? styles.upload_textDark : ''}`}>
                  Upload Image
                </h3>
              </div>
              <div className={styles.line}></div>
              <div className={styles.form_container}>
                <div
                  className={`${styles.input_container} ${props.errors.companyName && props.touched.companyName
                    ? styles.error_input
                    : ""
                    }`}
                >
                  <input
                    type="text"
                    name="companyName"
                    className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                    placeholder="Company Name"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.companyName}
                  />
                  {/* <Company className={styles.input_icon} /> */}
                  <BsBuilding className={styles.input_icon} />
                  {props.errors.companyName && props.touched.companyName && (
                    <p className={styles.error_message}>
                      {props.errors.companyName}
                    </p>
                  )}
                </div>
                <div
                  className={`${styles.input_container} ${props.errors.website && props.touched.website
                    ? styles.error_input
                    : ""
                    }`}
                >
                  <input
                    type="text"
                    name="website"
                    className={`${styles.input} ${darkMode ? styles.inputDark : ''}`}
                    placeholder="Website"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.website}
                  />
                  <Web className={styles.input_icon} />
                  {props.errors.website && props.touched.website && (
                    <p className={styles.error_message}>
                      {props.errors.website}
                    </p>
                  )}
                </div>
                <div
                  className={`${styles.input_container} ${props.errors.industry && props.touched.industry
                    ? styles.error_input
                    : ""
                    }`}
                >
                  <select
                    onChange={props.handleChange}
                    name="industry"
                    onBlur={props.handleBlur}
                    value={props.values.industry}
                    className={`${styles.dropdown} ${darkMode ? styles.dropdownDark : ''}`}
                  >
                    <option value="">Please select an industry</option>
                    {industryType && industryType.map((industry) => (
                      <option key={industry.id} value={industry.id}>
                        {industry}
                      </option>
                    ))}
                  </select>
                  <Industry className={styles.input_icon} />
                  {props.errors.industry && props.touched.industry && (
                    <p className={styles.error_message}>
                      {props.errors.industry}
                    </p>
                  )}
                </div>
                <div
                  className={`${styles.input_container} ${props.errors.companySize && props.touched.companySize
                    ? styles.error_input
                    : ""
                    }`}
                >
                  <select
                    name="companySize"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.companySize}
                    className={`${styles.dropdown} ${darkMode ? styles.dropdownDark : ''}`}
                  >
                    <option value="">Please select a size</option>
                    {industrySize && industrySize.map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                  <CompanySize className={styles.input_icon} />
                  {props.errors.companySize && props.touched.companySize && (
                    <p className={styles.error_message}>
                      {props.errors.companySize}
                    </p>
                  )}
                </div>
                <button
                  className={`${styles.register_btn} ${darkMode ? styles.register_btnDark : ''}`}
                  type={"submit"}>
                  Register
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <p
        className={`${styles.account_text} ${darkMode ? styles.account_textDark : ''}`}
      >
        Already have an account?{" "}
        <span
          className={`${styles.login_text} ${darkMode ? styles.login_textDark : ''}`}
          onClick={() => setStep("login")}>
          Login
        </span>
      </p>
    </section>
  );
};
export default StepOne;
