import React, { useState, useEffect } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./SellProductForm.module.scss";
import Car from "../../assests/images/Car.png";
import Camera from "../../assests/images/camera.png";
import LocationMarker from "../../assests/svg/location marker.svg";
import { AiOutlineRight } from "react-icons/ai";
import Switch from "../../common/components/Switch/Switch";
import PostSuccess from "../../common/components/PostSuccess/PostSuccess";
import { useDispatch, useSelector } from "react-redux";
import {
  listvehiclebrand,
  listbrandmodules,
  listbodytypes,
  addprod,
  uploadMainImageHandler,
} from "../../store/actions/addProductActions";
import { useFormik } from "formik";
import * as Yup from "yup";
const SellProductForm = () => {
  const dispatch = useDispatch();
  const [fileUploaded, setFileUploaded] = useState("");
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    setFileUploaded(event.target.files[0]);
  };
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    condition: "",
    price: "",
    negotiatable: "",
    exchangable: "",
    refundable: "",
    vehicleBrand: "",
    bodyType: "",
    vehicleColor: "",
    brandmodule: "",
    manufactureYear: "",
    transmissionType: "",
  });
  const formik = useFormik({
    initialValues: formData,
    onSubmit: (values) => {
      // // console.log(values);
      // // console.log(fileUploaded);
      dispatch(uploadMainImageHandler(fileUploaded)).then(() =>
        dispatch(
          addprod(
            values.title,
            values.description,
            values.price,
            values.vehicleBrand,
            values.brandmodule,
            values.bodyType
          )
        )
      );
    },
  });
  const vehiclebrand = useSelector((state) => state.vehiclebrand);
  const { vehiclebrandlist, error, loading } = vehiclebrand;
  const brandmodule = useSelector((state) => state.brandmodule);
  const { brandmodulelist } = brandmodule;
  const bodytypes = useSelector((state) => state.bodytypes);
  const { bodytypeslist } = bodytypes;
  const [isNew, setIsNew] = useState(true);
  const [isUsed, setIsUsed] = useState(false);
  const [automatic, setAutomatic] = useState(true);
  const [manual, setManuel] = useState(false);
  const [timer, setTimer] = useState(false);
  const [bid, setBid] = useState(false);
  const [success, setSuccess] = useState(false);
  const newHandler = () => {
    setIsNew(true);
    setIsUsed(false);
    setFormData((prevState) => ({
      ...prevState,
      condition: "new",
    }));
  };
  const usedHandler = () => {
    setIsNew(false);
    setIsUsed(true);
    setFormData((prevState) => ({
      ...prevState,
      condition: "new",
    }));
  };
  const automaticHandler = () => {
    setAutomatic(true);
    setManuel(false);
  };
  const manualHandler = () => {
    setAutomatic(false);
    setManuel(true);
  };
  useEffect(() => {
    dispatch(listvehiclebrand());
    dispatch(listbrandmodules());
    dispatch(listbodytypes());
  }, [dispatch]);
  return (
    <Layout title={"Product Information"}>
      <form className={styles.wrapper} onSubmit={formik.handleSubmit}>
        {/*  */}
        <div className={styles.category_container}>
          <p className={styles.label}>Selected Category</p>
          <div className={styles.category_input}>
            <div className={styles.img_container}>
              <img src={Car} alt="product" className={styles.img} />
            </div>
            <div>
              <p className={styles.title}>Vehicles</p>
              <p className={styles.subtitle}>Cars for Sale</p>
              <button className={styles.edit_btn}>Edit</button>
            </div>
          </div>
        </div>
        <div className={styles.title_container}>
          <p className={styles.label}>Product Title</p>
          <input
            type="text"
            className={styles.input}
            placeholder="Name of product and related info"
            name="title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
          />
        </div>
        <div className={styles.desc_container}>
          <p className={styles.label}>Description</p>
          <textarea
            className={styles.text_area}
            placeholder="Tell us more about your product"
            name="description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
          />
        </div>
        <div className={styles.condition_container}>
          <p className={styles.label}>Condition</p>
          <div className={styles.switch_container}>
            <button
              className={`${styles.switch_btn} ${isNew ? styles.active : ""}`}
              onClick={newHandler}
            >
              New
            </button>
            <button
              className={`${styles.switch_btn} ${isUsed ? styles.active : ""}`}
              onClick={usedHandler}
            >
              Used
            </button>
          </div>
        </div>
        <div className={styles.price_container}>
          <p className={styles.label}>Price</p>
          <div className={styles.input_container}>
            <input
              type="number"
              className={styles.input}
              placeholder="000,000"
              name="price"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.price}
            />
            <div className={styles.currency}>$</div>
          </div>
          <div className={styles.checkboxes}>
            <div className={styles.checkbox_container}>
              <input type="checkbox" name="negotiatable" id="box1" />
              <label htmlFor="box1">Negotiatable</label>
            </div>
            <div className={styles.checkbox_container}>
              <input type="checkbox" name="negotiatable" id="box1" />
              <label htmlFor="box1">Exchangable</label>
            </div>
            <div className={styles.checkbox_container}>
              <input type="checkbox" name="negotiatable" id="box1" />
              <label htmlFor="box1">Refundable</label>
            </div>
          </div>
        </div>
        <div className={styles.line}></div>
        {/* changing parts */}
        <div className={styles.form_container}>
          <div>
            <p className={styles.label}>Vehicle Brand</p>
            <select
              name="vehicleBrand"
              id=""
              className={styles.input}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.vehicleBrand}
            >
              <option value="">Please select a brand</option>
              {vehiclebrandlist &&
                vehiclebrandlist.map((brand, i) => (
                  <option key={brand.i} value={brand.value}>
                    {brand.label}
                  </option>
                ))}
            </select>
            <p className={styles.label}>Body Type</p>
            <select
              name="bodyType"
              id=""
              className={styles.input}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bodyType}
            >
              <option value="">Please select a body type</option>
              {bodytypeslist &&
                bodytypeslist.map((body, i) => (
                  <option key={body.i} value={body.value}>
                    {body.label}
                  </option>
                ))}
            </select>
            <p className={styles.label}>Vehicle Color</p>
            <input
              type="text"
              className={styles.input}
              placeholder="Color"
              name="vehicleColor"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.vehicleColor}
            />
          </div>
          <div>
            <p className={styles.label}>Brand Module</p>
            <select
              id=""
              className={styles.input}
              name="brandmodule"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.brandmodule}
            >
              <option value="">Please select a brand module</option>
              {brandmodulelist &&
                brandmodulelist.map((brand, i) => (
                  <option key={brand.i} value={brand.value}>
                    {brand.label}
                  </option>
                ))}
            </select>
            <p className={styles.label}>Year of Manufacturing</p>
            <input
              type="text"
              className={styles.input}
              placeholder="Year"
              name="manufactureYear"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.manufactureYear}
            />
            <p className={styles.label}>Transmission Type</p>
            <div className={styles.transmission_type_container}>
              <button
                className={`${styles.switch_btn} ${
                  automatic ? styles.active : ""
                }`}
                onClick={automaticHandler}
              >
                New
              </button>
              <button
                className={`${styles.switch_btn} ${
                  manual ? styles.active : ""
                }`}
                onClick={manualHandler}
              >
                Used
              </button>
            </div>
          </div>
        </div>
        {/* end of changing parts */}
        <div className={styles.line2}></div>
        <div className={styles.image_section}>
          <p className={styles.label}>Add Photos</p>
          <div className={styles.wrapper}>
            <div className={styles.image_container} onClick={handleClick}>
              <img
                src={fileUploaded ? URL.createObjectURL(fileUploaded) : Camera}
                alt="camera"
                className={`${fileUploaded ? styles.full_image : styles.image}`}
              />
            </div>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
            <div className={styles.image_container}>
              <img src={Camera} alt="camera" className={styles.image} />
            </div>
          </div>
        </div>
        <div className={styles.line}></div>
        <p className={styles.label}>Location</p>
        <div className={styles.location_section}>
          <div className={styles.location_container}>
            <div className={styles.image_container}>
              <img
                src={LocationMarker}
                alt="location marker"
                className={styles.img}
              />
            </div>
            <div className={styles.details_container}>
              <h1 className={styles.title}>My House</h1>
              <p className={styles.text}>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
              </p>
            </div>
          </div>
          <div className={styles.vertical_line}></div>
          <div className={styles.new_address_container}>
            <p className={styles.new_address_text}>
              Select a new address as the location
            </p>
            <div className={styles.location}>
              Location <AiOutlineRight className={styles.icon} />
            </div>
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.last_section}>
          <div>
            <div className={styles.flex}>
              <p className={styles.label}>Set a Timer</p>
              <Switch
                isSelected={timer}
                setIsSelected={() => setTimer(!timer)}
              />
            </div>
            <p className={styles.text}>
              When active the product will be available only for a limited time,
              specified by you. (can be changed later)
            </p>
          </div>
          <div className={styles.vertical_line}></div>
          <div>
            <div className={styles.flex}>
              <p className={styles.label}>Make Product a Bid</p>
              <Switch isSelected={bid} setIsSelected={() => setBid(!bid)} />
            </div>
            <p className={styles.text}>
              When active the product will be published as a bid, people will
              compete for the product by offering higher prices for a limited
              time and the person with the highest bid wins. (Can be turned off
              from the my products section)
            </p>
          </div>
        </div>
        <div className={styles.button_container}>
          <button
            className={styles.post_now}
            // onClick={() => setSuccess(true)}
            type={"submit"}
          >
            Post Now
          </button>
          <button className={styles.preview}>Preview</button>
        </div>
      </form>
      <PostSuccess open={success} onClose={() => setSuccess(false)} />
    </Layout>
  );
};
export default SellProductForm;
