import {
    GET_PRODUCTS_SOLD_FAIL,
    GET_PRODUCTS_SOLD_SUCCESS
} from "../constants/adminDashboardConstants";
const initialState = {
    ProductSold: [],
    error: null,
    loading: false,
};
export const GetProductSold = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCTS_SOLD_SUCCESS:
            return {
                ...state,
                ProductSold: action.payload,
                error: null,
                loading: false,
            };
        case GET_PRODUCTS_SOLD_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
