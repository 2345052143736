import React, { useState, useRef, useEffect } from "react";
import AdminLayout from "../admin/AdminLayout/AdminLayout";
import styles from "./AdminSets.module.scss";
import Camera from "../../assests/images/camera.png";
import { useDispatch, useSelector } from "react-redux";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import {
  GetIndustrySize,
  GetIndustryType,
  PostIndustrySize,
  PostIndustryType,
} from "../../store/actions/companyActions";
import { PostFAQ } from "../../store/actions/companyActions";
import { UpdateCarousel } from "../../store/actions/AdminDashboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetPayment } from "../../store/actions/AdminDashboard";
import { CreatePayment } from "../../store/actions/AdminDashboard";
import { DeletePayment } from "../../store/actions/AdminDashboard";
import { UpdatePayment } from "../../store/actions/AdminDashboard";
import { GetFAQ } from "../../store/actions/companyActions";
import {
  DeleteFAQ,
  DeleteSize,
  DeleteType,
} from "../../store/actions/AdminDashboard";
import { UpdateFaq } from "../../store/actions/AdminDashboard";

const AdminSets = () => {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const [response, setResponse] = useState("");
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const faq = useSelector((state) => state.faq?.faq?.data?.faqs);
  const industrySize = useSelector(
    (state) => state.IndustrySize?.IndustrySize?.industrySizes
  );

  const industryType = useSelector(
    (state) => state.IndustryType?.IndustryType?.industryTypes
  );

  useState(() => {
    dispatch(GetIndustrySize());
    dispatch(GetIndustryType());
    dispatch(GetFAQ());
  }, [dispatch]);
  const data = {
    title: question,
    description: response,
  };
  const [photoStates, setPhotoStates] = useState(new Array(4).fill(null));
  const carousel = useSelector((state) => state.carousel?.carousel?.imageUrls);
  const getPayment = useSelector((state) => state.getPayment?.getPayment);
  const [payments, setPayments] = useState([]);
  useEffect(() => {
    setPayments(getPayment);
  }, [getPayment]);

  const handleAmountChange = (index, newValue) => {
    const updatedPayments = [...payments];
    updatedPayments[index].amount = parseInt(newValue, 10); // Parse the input as an integer
    setPayments(updatedPayments);
  };
  const [loadingDelete, setLoadingDelete] = useState(
    Array(getPayment.length).fill(false)
  );
  const [loadingUpdate, setLoadingUpdate] = useState(
    Array(getPayment.length).fill(false)
  );
  useEffect(() => {
    dispatch(GetPayment());
    const combinedArray =
      carousel && carousel.concat(new Array(4 - carousel.length).fill(null));
    setPhotoStates(combinedArray);
  }, [carousel]);
  const [loadingCarousel, setLoadingCarousel] = useState(false);
  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };
  const handleResponseChange = (e) => {
    setResponse(e.target.value);
  };
  const [size, setSize] = useState({
    industrySize: "",
  });
  const [type, setType] = useState({
    industryType: "",
  });
  const handleSizeChange = (e) => {
    setSize({ ...size, [e.target.name]: e.target.value });
  };
  const handleTypeChange = (e) => {
    setType({ ...type, [e.target.name]: e.target.value });
  };
  const [isAddingSize, setIsAddingSize] = useState(false);
  const [isAddingType, setIsAddingType] = useState(false);
  const [isAddingFAQ, setIsAddingFAQ] = useState(false);
  const [fileUploaded, setFileUploaded] = useState("");
  const hiddenFileInput = React.useRef(null);
  const handleClick = (index) => {
    hiddenFileInputs[index].current.click();
  };
  const handleChange = (event, index) => {
    const file = event.target.files[0];
    const newPhotoStates = [...photoStates];
    newPhotoStates[index] = file;
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    setPhotoStates(newPhotoStates);
  };
  const newphotoStates =
    photoStates && photoStates.filter((item) => item !== null && item !== "");
  const fileNames =
    photoStates && photoStates.map((file) => file && file.name).filter(Boolean);
  const hiddenFileInputs =
    photoStates && photoStates.map(() => React.createRef());
  const isBackendURL = (url) =>
    typeof url === "string" &&
    url.startsWith("https://storage.googleapis.com/");
  // Separate the URLs into two arrays
  const backendURLs = newphotoStates.filter(isBackendURL);
  const backendURLsString = backendURLs.join(",");
  const nonBackendURLs = newphotoStates.filter((url) => !isBackendURL(url));
  const backendURLsArray = Object.values(backendURLs);
  const handleSubmitCarousel = async (e) => {
    e.preventDefault();
    try {
      setLoadingCarousel(true);
      const formData = new FormData();
      formData.append("imageUrls", JSON.stringify(backendURLsArray));
      for (const file of nonBackendURLs) {
        formData.append("images", file);
      }
      const response = await dispatch(UpdateCarousel(formData));
      setLoadingCarousel(false);
      if (response) {
        toast.success("Carousel updated successfully.");
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const handleSizeSubmit = async (e) => {
    e.preventDefault();
    if (isAddingSize) {
      return; // Prevent multiple clicks while action is being processed
    }
    try {
      setIsAddingSize(true);
      const response = await dispatch(PostIndustrySize(size));
      if (response) {
        dispatch(GetIndustrySize());
        setSize({
          industrySize: "",
        });
        toast.success("Size added successfully.");
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setIsAddingSize(false); // Reset button state after action is complete
    }
  };
  const handleTypeSubmit = async (e) => {
    e.preventDefault();
    if (isAddingType) {
      return; // Prevent multiple clicks while action is being processed
    }
    try {
      setIsAddingType(true);
      const response = await dispatch(PostIndustryType(type));
      if (response) {
        dispatch(GetIndustryType());
        setType({
          industryType: "",
        });
        toast.success("Type added successfully.");
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setIsAddingType(false); // Reset button state after action is complete
    }
  };
  const handleFAQSubmit = async (e) => {
    e.preventDefault();
    if (isAddingFAQ) {
      return; // Prevent multiple clicks while action is being processed
    }
    try {
      setIsAddingFAQ(true);
      const response = await dispatch(PostFAQ(data));
      if (response) {
        await dispatch(GetFAQ());
        setQuestion("");
        setResponse("");
        toast.success("FAQ added successfully.");
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setIsAddingFAQ(false);
    }
  };
  const handleCreatePayment = async (e) => {
    setLoadingPayment(true);
    const data = { title: name, amount: parseInt(price) };
    const response = await dispatch(CreatePayment(data));
    setLoadingPayment(false);
    if (response) {
      setLoadingPayment(false);
      const test = await dispatch(GetPayment());
      setPayments(test);
      setName("");
      setPrice("");
      toast.success("Payment amount created successfully.");
    }
  };
  const handleDeletePayment = async (id, index) => {
    setLoadingDelete((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = true;
      return updatedLoading;
    });
    const response = await dispatch(DeletePayment(id));
    setLoadingDelete((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = false;
      return updatedLoading;
    });
    if (response) {
      setLoadingDelete((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index] = false;
        return updatedLoading;
      });
      dispatch(GetPayment());
      toast.success("Payment amount deleted successfully.");
    }
  };
  const handleUpdateFAQ = async (id, index) => {
    setLoadingUpdate((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = true;
      return updatedLoading;
    });
    const data = getModifiedObject(index);
    const response = await dispatch(UpdateFaq(id, data));
    setLoadingUpdate((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = false;
      return updatedLoading;
    });
    if (response) {
      setLoadingUpdate((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index] = false;
        return updatedLoading;
      });
      const test = await dispatch(GetFAQ());
      toast.success("FAQ updated successfully.");
    }
  };

  const handleDeleteFAQ = async (id, index) => {
    setLoadingDelete((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = true;
      return updatedLoading;
    });
    const response = await dispatch(DeleteFAQ(id));
    setLoadingDelete((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = false;
      return updatedLoading;
    });
    if (response) {
      setLoadingDelete((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index] = false;
        return updatedLoading;
      });
      await dispatch(GetFAQ());
      toast.success("FAQ deleted successfully.");
    }
  };
  const handleDeleteSize = async (id, index) => {
    setLoadingDelete((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = true;
      return updatedLoading;
    });
    const response = await dispatch(DeleteSize(id));
    setLoadingDelete((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = false;
      return updatedLoading;
    });
    if (response) {
      setLoadingDelete((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index] = false;
        return updatedLoading;
      });
      dispatch(GetIndustrySize());
      toast.success("Industry size deleted successfully.");
    }
  };
  const handleDeleteType = async (id, index) => {
    setLoadingDelete((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = true;
      return updatedLoading;
    });
    const response = await dispatch(DeleteType(id));
    setLoadingDelete((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = false;
      return updatedLoading;
    });
    if (response) {
      setLoadingDelete((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index] = false;
        return updatedLoading;
      });
      dispatch(GetIndustryType());
      toast.success("Industry type deleted successfully.");
    }
  };
  const handleDelete = (index) => {
    const updatedPhotoStates = [...photoStates];
    updatedPhotoStates[index] = null;
    setPhotoStates(updatedPhotoStates);
  };
  const handleUpdate = async (id, index) => {
    setLoadingUpdate((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = true;
      return updatedLoading;
    });
    const data = {
      title: payments[index].title,
      amount: payments[index].amount,
    };
    const response = await dispatch(UpdatePayment(id, data));
    setLoadingUpdate((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = false;
      return updatedLoading;
    });
    if (response) {
      setLoadingUpdate((prevLoading) => {
        const updatedLoading = [...prevLoading];
        updatedLoading[index] = false;
        return updatedLoading;
      });
      const test = await dispatch(GetPayment());
      setPayments(test);
      toast.success("Payment updated successfully.");
    }
  };

  const [modifiedFAQ, setModifiedFAQ] = useState([]); // Initialize with your FAQ data
  useEffect(() => {
    setModifiedFAQ(faq);
  }, [faq]);
  // Function to handle input changes and update the state
  const handleInputChange = (index, field, value) => {
    const updatedFAQ = [...modifiedFAQ]; // Create a copy of the modifiedFAQ array
    updatedFAQ[index][field] = value; // Update the specified field
    setModifiedFAQ(updatedFAQ); // Update the state
  };

  // Function to create a new object with title and description
  const getModifiedObject = (index) => {
    const faqItem = modifiedFAQ[index];
    return {
      title: faqItem.title,
      description: faqItem.description,
    };
  };
  return (
    <AdminLayout>
      <div className={StyleSheet.header}>
        <h3>Settings</h3>
      </div>
      <div className={styles.container}>
        <MDBContainer className="mt-5" style={{ maxWidth: "1000px" }}>
          <MDBAccordion initialActive={1}>
            <MDBAccordionItem collapseId={1} headerTitle="Carousel photos">
              <div className={styles.image_section}>
                <div className={styles.wrapper}>
                  {photoStates &&
                    photoStates.map((photoState, index) => (
                      <div
                        className={styles.image_container}
                        onClick={() => handleClick(index)}
                      >
                        <img
                          src={
                            photoState
                              ? typeof photoState === "string" &&
                                photoState.startsWith(
                                  "https://storage.googleapis.com/"
                                )
                                ? photoState
                                : URL.createObjectURL(photoState)
                              : Camera
                          }
                          alt="camera"
                          className={`${
                            photoState ? styles.full_image : styles.image
                          }`}
                        />
                        {photoState && (
                          <div
                            className={styles.remove_button}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(index);
                            }}
                          >
                            X
                          </div>
                        )}
                        <input
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          ref={hiddenFileInputs[index]}
                          onChange={(event) => handleChange(event, index)}
                          style={{ display: "none" }}
                        />
                        <input
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          ref={hiddenFileInput[index]}
                          onChange={handleChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    ))}
                </div>
                <div className={styles.phb}>
                  <button
                    className={
                      loadingCarousel
                        ? styles.addphotosLoading
                        : styles.addphotos
                    }
                    onClick={handleSubmitCarousel}
                    disabled={loadingCarousel}
                  >
                    {loadingCarousel ? "Uploading Photos..." : "Upload Photos"}
                  </button>
                </div>
              </div>
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion>
            <MDBAccordionItem collapseId={2} headerTitle="Add industry type">
              <div className={styles.compsection}>
                <div className={styles.cmptype}>
                  <input
                    className={styles.input}
                    placeholder="Enter Industry Type"
                    name="industryType"
                    value={type.industryType}
                    onChange={handleTypeChange}
                  />
                  <button
                    className={isAddingType ? styles.adding : styles.compadd}
                    onClick={handleTypeSubmit}
                    disabled={isAddingType}
                  >
                    {" "}
                    {isAddingType ? "Adding..." : "Add"}
                  </button>
                </div>
              </div>
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion>
            <MDBAccordionItem collapseId={2} headerTitle="Update industry type">
              {industryType &&
                industryType.map((faq, index) => (
                  <div key={index} className={styles.faqinputs}>
                    <input
                      className={styles.faqinput}
                      placeholder="Payment field"
                      value={faq}
                      readOnly
                    />

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className={styles.adbt}>
                        <button
                          className={
                            loadingDelete[index] ? styles.adding : styles.dlt
                          }
                          onClick={() => handleDeleteType(faq, index)}
                          disabled={loadingDelete[index]}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion>
            <MDBAccordionItem collapseId={2} headerTitle="Add industry size">
              <div className={styles.compsection}>
                <div className={styles.cmptype}>
                  <input
                    className={styles.input}
                    name="industrySize"
                    placeholder="Enter Industry Size"
                    value={size.industrySize}
                    onChange={handleSizeChange}
                  />
                  <button
                    className={isAddingSize ? styles.adding : styles.compadd}
                    onClick={handleSizeSubmit}
                    disabled={isAddingSize}
                  >
                    {" "}
                    {isAddingSize ? "Adding..." : "Add"}
                  </button>
                </div>
              </div>
            </MDBAccordionItem>
          </MDBAccordion>

          <MDBAccordion>
            <MDBAccordionItem collapseId={2} headerTitle="Update industry size">
              {industrySize &&
                industrySize.map((faq, index) => (
                  <div key={index} className={styles.faqinputs}>
                    <input
                      className={styles.faqinput}
                      placeholder="Payment amount"
                      value={faq}
                      readOnly
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className={styles.adbt}>
                        <button
                          className={
                            loadingDelete[index] ? styles.adding : styles.dlt
                          }
                          onClick={() => handleDeleteSize(faq, index)}
                          disabled={loadingDelete[index]}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="Add frequently asked questions"
            >
              <div className={styles.faqinputs}>
                <input
                  className={styles.faqinput}
                  placeholder="Question"
                  value={question}
                  onChange={handleQuestionChange}
                />
                <textarea
                  className={styles.faqarea}
                  placeholder="Response"
                  value={response}
                  onChange={handleResponseChange}
                />
                <div className={styles.adbt}>
                  <button
                    className={isAddingFAQ ? styles.adding : styles.compadd}
                    onClick={handleFAQSubmit}
                    disabled={isAddingFAQ}
                  >
                    {" "}
                    {isAddingFAQ ? "Adding..." : "Add"}
                  </button>
                </div>
              </div>
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="Update frequently asked questions"
            >
              {modifiedFAQ &&
                modifiedFAQ?.map((faq, index) => (
                  <div key={index} className={styles.faqinputs}>
                    <input
                      className={styles.faqinput}
                      placeholder="Title"
                      value={
                        modifiedFAQ && modifiedFAQ[index]
                          ? modifiedFAQ[index].title
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(index, "title", e.target.value)
                      }
                    />
                    <input
                      className={styles.faqinput}
                      placeholder="Description"
                      value={
                        modifiedFAQ && modifiedFAQ[index]
                          ? modifiedFAQ[index].description
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(index, "description", e.target.value)
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className={styles.adbt}>
                        <button
                          className={
                            loadingUpdate[index]
                              ? styles.adding
                              : styles.compadd
                          }
                          onClick={() => handleUpdateFAQ(faq._id, index)}
                          disabled={loadingUpdate[index]}
                        >
                          Update
                        </button>
                      </div>
                      <div className={styles.adbt}>
                        <button
                          className={
                            loadingDelete[index] ? styles.adding : styles.dlt
                          }
                          onClick={() => handleDeleteFAQ(faq._id, index)}
                          disabled={loadingDelete[index]}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="Add payment and commissions"
            >
              <div className={styles.faqinputs}>
                <input
                  className={styles.faqinput}
                  placeholder="Payment field"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="number"
                  className={styles.faqinput}
                  placeholder="Payment amount"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <div className={styles.adbt}>
                  <button
                    className={loadingPayment ? styles.adding : styles.compadd}
                    onClick={() => handleCreatePayment()}
                    disabled={loadingPayment}
                  >
                    Add
                  </button>
                </div>
              </div>
            </MDBAccordionItem>
          </MDBAccordion>
          <MDBAccordion>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="Update payment and commissions"
            >
              {getPayment &&
                getPayment.map((faq, index) => (
                  <div key={index} className={styles.faqinputs}>
                    <input
                      className={styles.faqinput}
                      placeholder="Payment field"
                      value={faq.title}
                      readOnly
                    />
                    <input
                      type="number"
                      className={styles.faqinput}
                      placeholder="Payment amount"
                      value={
                        payments && payments[index]
                          ? payments[index].amount
                          : ""
                      }
                      onChange={(e) =>
                        handleAmountChange(index, e.target.value)
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className={styles.adbt}>
                        <button
                          className={
                            loadingUpdate[index]
                              ? styles.adding
                              : styles.compadd
                          }
                          onClick={() => handleUpdate(faq._id, index)}
                          disabled={loadingUpdate[index]}
                        >
                          Update
                        </button>
                      </div>
                      <div className={styles.adbt}>
                        <button
                          className={
                            loadingDelete[index] ? styles.adding : styles.dlt
                          }
                          onClick={() => handleDeletePayment(faq._id, index)}
                          disabled={loadingDelete[index]}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </MDBAccordionItem>
          </MDBAccordion>
        </MDBContainer>
      </div>
    </AdminLayout>
  );
};
export default AdminSets;
