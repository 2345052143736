export const WHISHLIST_REQUEST = "WHISHLIST_REQUEST";
export const WHISHLIST_SUCCESS = "WHISHLIST_SUCCESS";
export const WHISHLIST_FAIL = "WHISHLIST_LIST_FAIL";
export const WHISHLIST_IDS_REQUEST = "WHISHLIST_IDS_REQUEST";
export const WHISHLIST_IDS_SUCCESS = "WHISHLIST_IDS_SUCCESS";
export const WHISHLIST_IDS_FAIL = "WHISHLIST_IDS_LIST_FAIL";
export const WHISHLIST_IDS_RESET = "WHISHLIST_IDS_LIST_RESET";
export const REMOVE_ITEM_WHISHLIST_REQUEST = "REMOVE_ITEM_WHISHLIST_REQUEST";
export const REMOVE_ITEM_WHISHLIST_SUCCESS = "REMOVE_ITEM_WHISHLIST_SUCCESS";
export const REMOVE_ITEM_WHISHLIST_FAIL = "REMOVE_ITEM_WHISHLIST_LIST_FAIL";
export const REMOVE_ITEM_WHISHLIST_RESET = "REMOVE_ITEM_WHISHLIST_LIST_RESET";
export const ADD_ITEM_WHISHLIST_REQUEST = "ADD_ITEM_WHISHLIST_REQUEST";
export const ADD_ITEM_WHISHLIST_SUCCESS = "ADD_ITEM_WHISHLIST_SUCCESS";
export const ADD_ITEM_WHISHLIST_FAIL = "ADD_ITEM_WHISHLIST_LIST_FAIL";
export const ADD_ITEM_WHISHLIST_RESET = "ADD_ITEM_WHISHLIST_LIST_RESET";
// NOT USED
export const BID_WHISHLIST_REQUEST = "BID_WHISHLIST_REQUEST";
export const BID_WHISHLIST_SUCCESS = "BID_WHISHLIST_SUCCESS";
export const BID_WHISHLIST_FAIL = "BID_WHISHLIST_LIST_FAIL";
export const SERVICE_WHISHLIST_REQUEST = "SERVICE_WHISHLIST_REQUEST";
export const SERVICE_WHISHLIST_SUCCESS = "SERVICE_WHISHLIST_SUCCESS";
export const SERVICE_WHISHLIST_FAIL = "SERVICE_WHISHLIST_LIST_FAIL";
export const PRODUCT_WHISHLIST_REQUEST = "PRODUCT_WHISHLIST_REQUEST";
export const PRODUCT_WHISHLIST_SUCCESS = "PRODUCT_WHISHLIST_SUCCESS";
export const PRODUCT_WHISHLIST_FAIL = "PRODUCT_WHISHLIST_LIST_FAIL";
export const ADD_WHISHLIST_REQUEST = "ADD_WHISHLIST_REQUEST";
export const ADD_WHISHLIST_SUCCESS = "ADD_WHISHLIST_SUCCESS";
export const ADD_WHISHLIST_FAIL = "ADD_WHISHLIST_LIST_FAIL";
export const ADD_WHISHLIST_RESET = "ADD_WHISHLIST_LIST_RESET";
export const REMOVE_WHISHLIST_REQUEST = "REMOVE_WHISHLIST_REQUEST";
export const REMOVE_WHISHLIST_SUCCESS = "REMOVE_WHISHLIST_SUCCESS";
export const REMOVE_WHISHLIST_FAIL = "REMOVE_WHISHLIST_LIST_FAIL";
export const REMOVE_WHISHLIST_RESET = "REMOVE_WHISHLIST_LIST_RESET";
export const GET_WHISHLIST_SUCCESS = "GET_WHISHLIST_SUCCESS";
export const GET_WHISHLIST_FAIL = "GET_WHISHLIST_FAIL";
export const ADD_SERVICE_TO_WHISHLIST_SUCCESS = "ADD_SERVICE_TO_WHISHLIST_SUCCESS";
export const ADD_SERVICE_TO_WHISHLIST_FAIL = "ADD_SERVICE_TO_WHISHLIST_FAIL";
export const GET_WHISHLIST_SERVICE_SUCCESS = "GET_WHISHLIST_SERVICE_SUCCESS";
export const GET_WHISHLIST_SERVICE_FAIL = "GET_WHISHLIST_SERVICE_FAIL";
