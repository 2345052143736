import React, { useState } from "react";
import styles from "./footer.module.scss";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import GooglePlay from "../../../assests/images/googleplay.png";
import AppleStore from "../../../assests/images/appstore.png";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineDown } from "react-icons/ai";
import { useSelector } from "react-redux";
import { auth, db } from "../../../firebase";
import SellModal from "../SellModal/SellModal";
import { toast } from "react-toastify";
import Modal from "../Modal/Modal";
import LoginWithCodeModal from "../Modal/Login with code/LoginWithCodeModal";

const Footer = () => {
  const [firstList, setFirstList] = useState(false);
  const firstClass = firstList ? styles.show_first : styles.hide_first;
  const [secondList, setSecondList] = useState(false);
  const secondClass = secondList ? styles.show_second : styles.hide_second;
  const [thirdList, setThirdList] = useState(false);
  const thirdClass = thirdList ? styles.show_third : styles.hide_third;
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [verified, setVerified] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const uid = localStorage.getItem('uid')
  const navigate=useNavigate()
  const [sell,setSell]=useState(false)
  const openSell = () => {
    if (uid) {
      const user = auth.currentUser;
      const providerData = user.providerData;
      if (user && user.providerData) {
        // Check if the providerData array contains the PhoneAuthCredential object
        const hasLinkedPhoneNumber = providerData.some(
          (provider) => provider.providerId === 'phone',
        );
        if (hasLinkedPhoneNumber) {
          setSell(true)
        } else {
          toast.error('Verify your number first, a verfication code will be sent to you.');
          setVerified(true)
        }
      } else {
        // console.log("User is not signed in or providerData is unavailable.");
      }
    } else {
      toast.error('Please login first.');
      setIsOpen(true);
    }
  };
  return (
    <>
    <SellModal
    open={sell}
    onClose={()=>setSell(false)}
    />
    <Modal
        open={isOpen}
        closeHandler={() => setIsOpen(false)}
      />
      <LoginWithCodeModal
      open={verified}
      onClose={() => setVerified(false)}/>
    <section className={`${styles.footer_container} ${darkMode ? styles.footer_containerDark : ''}`} name="DownloadTheApp">
      <div className={styles.wrapper}>
        <div className={styles.footer_part}>
          <div className={styles.title_container}>
            <h4 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>La7i27alak</h4>
            <AiOutlineDown
              className={`${styles.down_icon} ${firstClass}`}
              onClick={() => {
                setFirstList((prevState) => !prevState);
              }}
            />
          </div>
          <ul className={`${styles.list} ${firstClass}`}>
            <li>
              <Link onClick={openSell} className={`${styles.link} ${darkMode ? styles.linkDark : ''}`}>Sell</Link>
            </li>
            <li>
              <Link className={`${styles.link} ${darkMode ? styles.linkDark : ''}`} to={'/products'}>Buy</Link>
            </li>
            <li>
              <Link className={`${styles.link} ${darkMode ? styles.linkDark : ''}`} to={'/bids'}>Bid</Link>
            </li>
            <li>
              <p className={`${styles.copyright} ${darkMode ? styles.copyrightDark : ''}`}>Copyright © 2023</p>
            </li>
          </ul>
        </div>
        <div className={styles.footer_part}>
          <div className={styles.title_container}>
            <h4 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Quick links</h4>
            <AiOutlineDown
              className={`${styles.down_icon} ${secondClass}`}
              onClick={() => {
                setSecondList((prevState) => !prevState);
              }}
            />
          </div>
          <ul className={`${styles.list} ${secondClass}`}>
            <li>
              <Link className={`${styles.link} ${darkMode ? styles.linkDark : ''}`} to="/">Home</Link>
            </li>
            <li>
              <Link className={`${styles.link} ${darkMode ? styles.linkDark : ''}`} to='/categories'>Categories</Link>
            </li>
            <li>
              <Link className={`${styles.link} ${darkMode ? styles.linkDark : ''}`} to='/special-offers'>Offers</Link>
            </li>
            <li>
              <Link to={'/site-map'} className={`${styles.link} ${darkMode ? styles.linkDark : ''}`}>Site Map</Link>
            </li>
          </ul>
        </div>
        <div className={styles.footer_part}>
          <div className={styles.title_container}>
            <h4 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Support</h4>
            <AiOutlineDown
              className={`${styles.down_icon} ${thirdClass}`}
              onClick={() => {
                setThirdList((prevState) => !prevState);
              }}
            />
          </div>
          <ul className={`${styles.list} ${thirdClass}`}>
            <li>
              <Link className={`${styles.link} ${darkMode ? styles.linkDark : ''}`} to='/HELP'>Help</Link>
            </li>
            <li>
              <Link className={`${styles.link} ${darkMode ? styles.linkDark : ''}`} to='/FAQ'>FAQs</Link>
            </li>
            
            <li>
              <Link className={`${styles.link} ${darkMode ? styles.linkDark : ''}`} to='/privacy-policy'>Privacy Policy</Link>
            </li>
            <li>
              <Link className={`${styles.link} ${darkMode ? styles.linkDark : ''}`} to='/term-of-use'>Term Of Use</Link>
            </li>
           
          </ul>
        </div>
        <div className={`${styles.social} ${styles.footer_part}`}>
          <div className={styles.title_container}>
            <h4 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Follow Us:</h4>
          </div>
          <ul className={styles.icons}>
            <li>
            <a style={{color:'black'}} href="https://www.facebook.com/la7i27alak?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <FaFacebookF className={styles.icon} />
              </a>
            </li>
            <li>
            <a style={{color:'black'}} href="https://instagram.com/la7i27alak?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" target="_blank" rel="noopener noreferrer">
            <FaInstagram className={styles.icon} />
          </a>
            </li>
            {/* <li>
              <FaTwitter className={styles.icon} id="twitter" />
            </li>
            <li>
              <FaYoutube className={styles.icon} />
            </li> */}
          </ul>
        </div>
        <div className={styles.app}>
          <h4 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>Download Our App Now</h4>
          <div className={styles.stores}>
            <img
              className={styles.store}
              src='/googleplay.png'
              alt="Google Play Store"
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.la72halk', '_blank')}
            />
            <img className={styles.store} src='/appstore.png' onClick={()=>window.open('https://apps.apple.com/lb/app/la7i27alak/id1660383776','_blank')} alt="Apple Store" />
          </div>
          <p className={styles.mobile_copyright}>Copyright © 2023</p>
        </div>
      </div>
    </section>
    </>
  );
};
export default Footer;
