import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ReactComponent as User } from "../../../../assests/svg/back person.svg";
import { ReactComponent as Company } from "../../../../assests/svg/black company.svg";
import { ReactComponent as Arrow } from "../../../../assests/svg/right arrow.svg";
import { ReactComponent as Frame } from "../../../../assests/svg/Frame.svg";
import { useDispatch, useSelector } from "react-redux";
import { USER_REGISTER_RESET } from "../../../../store/constants/authConstants";
import { AiOutlineArrowRight, AiOutlineRight, AiOutlineUser} from "react-icons/ai";
import { BsBuilding } from "react-icons/bs";
import logo from '../../../../assests/svg/La7i2_7alak1.1.svg'
import { ReactComponent as Back } from "../../../../assests/svg/back.svg";
import { ReactComponent as BackDark } from "../../../../assests/svg/arrowleftDark.svg";
import { BiArrowBack, BiArrowFromRight, BiArrowToRight, BiLeftArrow } from "react-icons/bi";
const FirstPopup = ({ setStep,closeHandler }) => {
  const dispatch = useDispatch();
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const activateFirst = () => {
    setFirst(true);
    setSecond(false);
  };
  const activateSecond = () => {
    setFirst(false);
    setSecond(true);
  };
  const clickHandler = () => {
    if (first) {
      dispatch({ type: USER_REGISTER_RESET });
      setStep("register");
    } else if (second) {
      dispatch({ type: USER_REGISTER_RESET });
      setStep("register company");
    } else return;
  };
  return (
    <div 
    className={`${styles.container} ${darkMode ? styles.containerDark : ''}`}    
    >
      
      
          <AiOutlineArrowRight
            className={`${styles.back} ${darkMode? styles.backDark:''}`}
            onClick={() => {
              setStep("login");
            }}
          />
        
        <p           
      className={`${styles.skip} ${darkMode ? styles.skipDark : ''}`}    
        onClick={() => closeHandler()}>
        Skip
      </p>
      <div className={styles.logoCont}>
      <img src={logo} alt='logo' className={styles.logo}/>
      </div>
      <h2    
       className={`${styles.welcome} ${darkMode ? styles.welcomeDark : ''}`}    
          >Welcome!</h2>
      {/* <p     className={`${styles.slogan} ${darkMode ? styles.sloganDark : ''}`}    
            >Slogan</p> */}
      <h3        className={`${styles.welcome} ${darkMode ? styles.welcomeDark : ''}`}    
        >Are you a regular user or a company?</h3>
      <p        
      className={`${styles.desc} ${darkMode ? styles.descDark : ''}`}    
      >
        Choose what account type you want to register (As a company you will be
        forced to add your payment method details to create the account)
      </p>
      <div
        
        className={`${styles.btn_container} ${first ? styles.active : ""} ${darkMode ? styles.btn_containerDark : ''}`}
        onClick={() => activateFirst()}
      >
        <AiOutlineUser className={styles.input_icon} />   Sign Up as a regular user
        {first && <Frame className={styles.active_icon} />}
      </div>
      <div
        className={`${styles.btn_container} ${second ? styles.active : ""} ${darkMode ? styles.btn_containerDark : ''}`}
        onClick={() => activateSecond()}
      >
        <BsBuilding className={styles.input_icon}/> 
        Sign Up as a company
        {second && <Frame className={styles.active_icon} />}
      </div>
      <button
        onClick={() => clickHandler()}
        className={`${styles.next} ${
          first || second ? styles.active_next : ""
        } ${darkMode ? styles.nextDark : ''}`}
      >
        Next <Arrow className={styles.arrow} />
      </button>
    </div>
  );
};
export default FirstPopup;
