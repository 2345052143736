import {
    SEARCH_SERVICE_SUCCESS,
    SEARCH_SERVICE_FAIL
} from "../constants/productDetailsConstants";
const initialState = {
    SearchService: [],
    error: null,
    loading: false
};
export const SearchService = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_SERVICE_SUCCESS:
            return {
                ...state,
                SearchService: action.payload,
                error: null,
                loading: false,
            };
        case SEARCH_SERVICE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
