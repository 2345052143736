import React, { useState } from "react";
import ReactDom from "react-dom";
import styles from "./styles.module.scss";
import LoginModal from "./loginModal/LoginModal";
import RegisterModal from "./registerModal/RegisterModal";
import FirstPopup from "./FirstPopup/FirstPopup";
import LoginWithCode from "./Login with code/LoginWithCode";
import VerifyEmail from "./VerifyEmail/VerifyEmail";
import RegisterCompany from "./RegisterCompanyModal/RegisterCompany";
import FavoriteCategories from "./Favorite Categories/FavoriteCategories";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import { useSelector } from "react-redux";
const Modal = ({ open, closeHandler,fav=false }) => {
  const [step, setstep] = useState(fav ? "favorite categories" : "login");
  const [email, setEmail] = useState("");
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [formData, setFormData] = useState("");
  if (!open) return null;
  else {
    switch (step) {
      case "register":
        return ReactDom.createPortal(
          <>
            <div
              className={styles.overlay}
              onClick={() => {
                closeHandler();
                setstep("login");
              }}
            >
              {" "}
            </div>
            <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
              <RegisterModal
                setStep={setstep}
                closeHandler={closeHandler}
                setFormData={setFormData}
              />
            </div>
          </>,
          document.getElementById("portal")
        );
      case "register company":
        return ReactDom.createPortal(
          <>
            <div
              className={styles.overlay}
              onClick={() => {
                closeHandler();
                setstep("login");
              }}
            >
              {" "}
            </div>
            <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`} >
              <RegisterCompany setStep={setstep} closeHandler={closeHandler} />
            </div>
          </>,
          document.getElementById("portal")
        );
      case "login":
        return ReactDom.createPortal(
          <>
            <div
              className={styles.overlay}
              onClick={() => {
                closeHandler();
                setstep("login");
              }}
            >
              {" "}
            </div>
            <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
              <LoginModal
                setStep={setstep}
                closeHandler={closeHandler}
                setEmail={setEmail}
              />
            </div>
          </>,
          document.getElementById("portal")
        );
      case "first":
        return ReactDom.createPortal(
          <>
            <div
              className={styles.overlay}
              onClick={() => {
                closeHandler();
                setstep("login");
              }}
            >
              {" "}
            </div>
            <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
              <FirstPopup setStep={setstep} closeHandler={closeHandler} />
            </div>
          </>,
          document.getElementById("portal")
        );
      case "login with code":
        return ReactDom.createPortal(
          <>
            <div
              className={styles.overlay}
              onClick={() => {
                closeHandler();
                setstep("login");
              }}
            >
              {" "}
            </div>
            <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
              <LoginWithCode setStep={setstep} closeHandler={closeHandler} />
            </div>
          </>,
          document.getElementById("portal")
        );
      case "verify email":
        return ReactDom.createPortal(
          <>
            <div
              className={styles.overlay}
              onClick={() => {
                closeHandler();
                setstep("login");
              }}
            >
              {" "}
            </div>
            <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
              <VerifyEmail
                setStep={setstep}
                closeHandler={closeHandler}
                email={email}
                formData={formData}
              />
            </div>
          </>,
          document.getElementById("portal")
        );
      case "favorite categories":
        return ReactDom.createPortal(
          <>
            <div
              className={styles.overlay}
              onClick={() => {
                closeHandler();
                setstep("login");
              }}
            >
              {" "}
            </div>
            <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
              <FavoriteCategories
                setStep={setstep}
                closeHandler={closeHandler}
              />
            </div>
          </>,
          document.getElementById("portal")
        );
      case "forget password":
        return ReactDom.createPortal(
          <>
            <div
              className={styles.overlay}
              onClick={() => {
                closeHandler();
                setstep("login");
              }}
            >
              {" "}
            </div>
            <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
              <ForgetPassword setStep={setstep} closeHandler={closeHandler} />
            </div>
          </>,
          document.getElementById("portal")
        );
      default:
        return ReactDom.createPortal(
          <>
            <div
              className={styles.overlay}
              onClick={() => {
                closeHandler();
                setstep("login");
              }}
            >
              {" "}
            </div>
            <div className={`${styles.modal_styles} ${darkMode ? styles.modal_stylesDark : ''}`}>
              <LoginModal setStep={setstep} closeHandler={closeHandler} />
            </div>
          </>,
          document.getElementById("portal")
        );
    }
  }
};
export default Modal;
