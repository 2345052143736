export const ADD_ADDRESS_REQUEST = "ADD_ADDRESS_REQUEST";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAIL = "ADD_ADDRESS_FAIL";
export const MAKE_DEFAULT_REQUEST = "MAKE_DEFAULT_REQUEST";
export const MAKE_DEFAULT_SUCCESS = "MAKE_DEFAULT_SUCCESS";
export const MAKE_DEFAULT_FAIL = "MAKE_DEFAULT_FAIL";
export const GET_ADDRESS_BY_USER_REQUEST = "GET_ADDRESS_BY_USER_REQUEST";
export const GET_ADDRESS_BY_USER_SUCCESS = "GET_ADDRESS_BY_USER_SUCCESS";
export const GET_ADDRESS_BY_USER_FAIL = "GET_ADDRESS_BY_USER_FAIL";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAIL = "DELETE_ADDRESS_FAIL";
