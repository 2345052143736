import React, { useState, useEffect } from "react";
import styles from "./FavCategs.module.scss";
import Car from "../../assests/images/Car.png";
import Electronics from "../../assests/images/Electronics.png";
import Furniture from "../../assests/images/Furniture.png";
import Fashion from "../../assests/images/FashionANDBeauty.png";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesList } from "../../store/actions/favoriteActions";
import { addCategoryToFavorite } from "../../store/actions/favoriteActions";
import { PutPreferredCategory, PutPreferredCategoryPg } from "../../store/actions/categoryAction";
import { GetFavCategory } from "../../store/actions/categoryAction";
import Loader from "../../common/components/Loader/Loader";
import Card from "./Cards/Cards";
import Layout from "../../common/components/Layouts/Layout";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRecommendedProduct } from "../../store/actions/recommendedProductAction";
const FavCategs = () => {
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false)
  useEffect(() => {
  
    dispatch({ type: "CLEAR_BREADCRUMBS" })
    const pageName = "Favorites categories"; // Replace this with the actual page name
    const pageUrl = "/user/favorite-categories"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  const categories1 = useSelector((state) => state.categories?.categories?.data?.categories);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const userById = useSelector((state) => state.userById);
  const { user } = userById;
  const favCategory = useSelector((state) => state.favCategory?.favCategory?.data?.prefCategories);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const navigate = useNavigate();
  const clickHandler = (categoryId) => {
    if (selectedCategory.includes(categoryId)) {
      // Category already selected, remove it from the list
      setSelectedCategory(selectedCategory.filter((id) => id !== categoryId));
    } else {
      // Category not selected, add it to the list
      setSelectedCategory([...selectedCategory, categoryId]);
    }
  };
  useEffect(() => {
    dispatch(getCategoriesList());
    dispatch(GetFavCategory());
  }, [dispatch]);
  useEffect(() => {
    if (favCategory) {
      setSelectedCategory(favCategory.map((cat) => cat._id));
    }
  }, [favCategory]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("prefCategories", JSON.stringify(selectedCategory));
      setLoading(true)
      const response = await dispatch(PutPreferredCategoryPg(formData));
      setLoading(false)
      if (response) {
        toast.success("Favorites categories saved successfully.")
        navigate("/");
        dispatch(getRecommendedProduct())
      }
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <>
      <Helmet>
        <title>Favorite Categories</title>
        <meta name='Favorite Categories' content="Favorite Categories" />
        <meta
          name="keywords"
          content={`Favorite Categories`}
        />
      </Helmet>
      <Layout title={'Favorite Categories'}>
        <div className={styles.favorite_container}>
          {/* <h1 className={styles.logo}>LOGO</h1> */}
          <h2 className={styles.subtitle}>Select your Favorite Categories</h2>
          <p className={styles.text}>
            Select categories you are interested in, this will help us recommend products you would love.
          </p>
          <div className={`${styles.cards_container}`}>
            {categories1.map((cat) => (
              <Card
                key={cat._id}
                title={cat.title}
                image={cat.iconUrl}
                id={cat._id}
                clickHandler={clickHandler}
                isSelected={selectedCategory.includes(cat._id)}
              />
            ))}
          </div>
          {loading?(
             <button className={`${styles.donesave}`}>
             Saving...
           </button>
          ):(
             <button className={`${styles.done} ${darkMode ? styles.doneDark : ""}`} onClick={handleSubmit}>
             Save
           </button>
          )}
         
        </div>
      </Layout>
    </>
  );
};
export default FavCategs;
