import React, { useState, useEffect, useRef } from "react";
import Layout from "../../common/components/Layouts/Layout";
import styles from "./ProductDetails.module.scss";
import { countDown } from "../../common/Utils";
import { MdLocationPin, MdTimer } from "react-icons/md";
import Negotiate from "../ProductDescriptionPage/components/Negotiate/Negotiate";
import RequestAcceptedPage from "../ProductDescriptionPage/components/Request Accepted/RequestAcceptedPage";
import UserContainer from "../ProductDescriptionPage/components/UserContainer/UserContainer";
import Bid from "../ProductDescriptionPage/components/Bid/Bid";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProdDetails } from "../../store/actions/productDetailsAction";
import Loader from "../../common/components/Loader/Loader";
import Modal from "../../common/components/Modal/Modal";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import { PRODUCT_DETAILS_RESET } from "../../store/constants/productDetailsConstants";
import ImageComponent from "../ProductDescriptionPage/components/ImageComponent/ImageComponent";
import { timePosted } from "../../common/Utils";
import { getDefaultCard } from "../../store/actions/paymentActions";
import { DEFAULT_CARD_RESET } from "../../store/constants/paymentConstants";
import { BiMessageRounded } from "react-icons/bi";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { ReactComponent as Phone } from "../../assests/svg/phone white.svg";
import { ReactComponent as WhatspApp } from "../../assests/svg/whatsapp white.svg";
import { handleFilterIcon } from "../../store/actions/filterActions";
import { CHECK_USER_PAYMENT_BID_RESET } from "../../store/constants/makePaymentConstants";
import { CHECK_USER_IN_WAITINGLIST_RESET } from "../../store/constants/waitingListConstants";
import {
  GetSingleProduct,
  GetSingleProductSuccess,
} from "../../store/actions/myProductsActions";
import { useParams } from "react-router-dom";
import { url } from "../../common/constants/url";
import { Helmet } from "react-helmet";
import { db } from "../../firebase";
import {
  get,
  ref,
  update,
  runTransaction,
  push,
  set,
  increment,
} from "firebase/database";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { GetUser } from "../../store/actions/userActions";
import { useNavigate } from "react-router-dom";
import RelatedProducts from "../RelatedProducts/RelatedProducts";
import { GetSingleService } from "../../store/actions/servicesActions";
import { GetSingleServiceSuccess } from "../../store/actions/servicesActions";
import { CheckIfUserInWaitingList } from "../../store/actions/ProductStateAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetServicesWishlist } from "../../store/actions/servicesActions";
import { GetWishList } from "../../store/actions/whishlistActions";
const ProductDetails = () => {
  const { serviceName, id, type } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollToTopRef = useRef(null);
  const location = useLocation();
    useEffect(() => {
      const delay = 500;
      const timeoutId = setTimeout(() => {
        window.scrollTo(0, 0);
      }, delay);
  
      return () => {
        clearTimeout(timeoutId);
      };
    }, []);

  useEffect(() => {
    const pageName = `${serviceName}`.replace(/-/g, " ");
    const pageUrl = `/${type}/${serviceName}/${id}`; // Replace this with the actual page URL
    dispatch({
      type: "ADD_BREADCRUMB",
      payload: { name: pageName, url: pageUrl },
    });
  }, [serviceName]);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const [userProfile, setUserProfile] = useState([]);
  const [singleProduct, setSingleProduct] = useState(null);
  const uid = localStorage.getItem("uid");
  const [loadingP, setLoadingP] = useState(false);
  const [googleImage, setGoogleImage] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingP(true);
  
        if (location.state && location.state.id) {
          const fetchDataFunction = location.state.isService
            ? GetSingleService
            : GetSingleProduct;
  
          const data = await dispatch(fetchDataFunction(location.state.id));
          setSingleProduct(
            data.data[location.state.isService ? "service" : "product"]
          );
          dispatch(
            location.state.isService
              ? GetSingleServiceSuccess(data)
              : GetSingleProductSuccess(data)
          );
        } else if (id) {
          const fetchDataFunction =
            type === "service" ? GetSingleService : GetSingleProduct;
  
          const data = await dispatch(fetchDataFunction(id));
          setSingleProduct(
            data.data[type === "service" ? "service" : "product"]
          );
          dispatch(
            type === "service"
              ? GetSingleServiceSuccess(data)
              : GetSingleProductSuccess(data)
          );
        }
  
        // You can perform actions dependent on singleProduct here
        setGoogleImage(singleProduct.imageUrls[0]);
        setLoadingP(false);
      } catch (error) {
        // Handle errors here
        setLoadingP(false);
      }
    };
  
    fetchData();
  }, [dispatch, location.state, id, type]);
  
  // Now you can log singleProduct and googleImage here outside the useEffect
  // console.log("this is", singleProduct);
  // console.log(googleImage);
  
  // Rest of your component code...

  function getDescription() {
    const formattedProductDescription =
      singleProduct &&
      singleProduct.pricing?.price &&
      singleProduct &&
      singleProduct.description;
    return formattedProductDescription;
  }
  function getImageUrl() {
    const formattedProductImage =
      singleProduct &&
      singleProduct.pricing?.price &&
      singleProduct &&
      singleProduct.imageUrls;
    return  singleProduct.imageUrls;
  }
  // console.log(getImageUrl())
  function getTitle() {
    const formattedProductTitle =
      singleProduct &&
      singleProduct.pricing?.price &&
      singleProduct &&
      singleProduct.title;
    return formattedProductTitle;
  }
  const getProductDetails = useSelector((state) => state.getProductDetails);
  const { productDetails, error, loading } = getProductDetails;
  const checkUserInWaitingList = useSelector(
    (state) => state.checkUserInWaitingList
  );
  const { user } = checkUserInWaitingList;
  const defaultCard = useSelector((state) => state.defaultCard);
  const { card_id, loading: loadingCard, error: errorCard } = defaultCard;
  const [requestAccepted, setRequestAccepted] = useState(false);
  const formattedProductTitle =
    singleProduct &&
    singleProduct.pricing?.price &&
    singleProduct &&
    singleProduct.title;
  const formattedProductImage =
    singleProduct &&
    singleProduct.pricing?.price &&
    singleProduct &&
    singleProduct.imageUrls;
  const formattedProductDescription =
    singleProduct &&
    singleProduct.pricing?.price &&
    singleProduct &&
    singleProduct.description;
 
  const specificUserId = localStorage.getItem("uid");
  let isExpired;
  useEffect(() => {
    if (singleProduct && singleProduct.timer?.deadline) {
      isExpired = countDown(singleProduct && singleProduct.timer?.deadline);
    }
    const metaTags = [
      { name: "title", content: formattedProductTitle },
      { property: "og:title", content: formattedProductTitle },
      { property: "og:description", content: formattedProductDescription },
      { property: "og:image", content: formattedProductImage },
      { property: "og:url", content: window.location.href },
      { property: "og:type", content: "eCommerce" },
    ];
    const existingTags = document.querySelectorAll(
      'meta[property^="og"], meta[name="title"]'
    );
    existingTags.forEach((tag) => tag.remove());
    metaTags.forEach((tag) => {
      const metaTag = document.createElement("meta");
      Object.entries(tag).forEach(([key, value]) => {
        metaTag.setAttribute(key, value);
      });
      document.head.appendChild(metaTag);
    });
  }, []);
  const chatWithCustomer = async () => {
    const chatlistRef = ref(db, "chatlist");
    const snapshot = await get(chatlistRef);
    let roomId;
    if (snapshot.exists()) {
      const chatlistData = snapshot.val();
      if (
        !chatlistData[singleProduct && singleProduct.user?._id]?.[
          specificUserId
        ]
      ) {
        if (
          !chatlistData[specificUserId]?.[
            singleProduct && singleProduct.user?._id
          ]
        ) {
          roomId = uuidv4();
        } else {
          const test = ref(
            db,
            `chatlist/${specificUserId}/${
              singleProduct && singleProduct.user?._id
            }/roomId`
          );
          const test1 = await get(test);
          const test2 = test1.val();
          roomId = test2;
        }
      } else {
        const test = ref(
          db,
          `chatlist/${
            singleProduct && singleProduct.user?._id
          }/${specificUserId}/roomId`
        );
        const test1 = await get(test);
        const test2 = test1.val();
        roomId = test2;
      }
    }
    if (!uid) {
      setIsOpen(true);
      return;
    }
    const response = await dispatch(GetUser(specificUserId));
    setUserProfile(response.data);
    if (snapshot.exists()) {
      const chatlistData = snapshot.val();
      if (
        !chatlistData[specificUserId]?.[
          singleProduct && singleProduct.user?._id
        ]
      ) {
        await update(
          ref(
            db,
            `chatlist/${specificUserId}/${
              singleProduct && singleProduct.user?._id
            }`
          ),
          {
            email: singleProduct && singleProduct.user?.email,
            id: singleProduct && singleProduct.user?._id,
            image: singleProduct && singleProduct.user?.imageUrl,
            name: singleProduct && singleProduct.user?.username,
            roomId: roomId,
          }
        );
      }
      if (
        !chatlistData[singleProduct && singleProduct.user?._id]?.[
          specificUserId
        ]
      ) {
        await update(
          ref(
            db,
            `chatlist/${
              singleProduct && singleProduct.user?._id
            }/${specificUserId}`
          ),
          {
            email: response.data?.user?.email,
            id: response.data?.user?._id,
            image: response.data?.user?.imageUrl,
            name: response.data?.user?.username,
            roomId: roomId,
          }
        );
      }
    }
    const openRoomIdref = ref(
      db,
      `chatlist/${
        singleProduct && singleProduct.user?._id
      }/${specificUserId}/roomId`
    );
    const openRoomIdSnapshot = await get(openRoomIdref);
    const openRoomId = openRoomIdSnapshot.val();
    if (isExpired === "Expired " && singleProduct.timer?.enabled) {
      toast.warning("Product expired.");
    } else {
      navigate(`/chat/${openRoomId}`, { state: { isProductDetails: true } });
    }
  };
  // console.log(formattedProductImage[0])
  const modifiedName =
    formattedProductTitle && formattedProductTitle.replace(/\s/g, "-");
  function openWhatsAppConversation() {
    if (
      isExpired === "Expired " &&
      singleProduct &&
      singleProduct.timer?.enabled
    ) {
      toast.warning("Product expired.");
    } else {
      const sellerPhoneNumber = singleProduct.user.phone; // Replace with the seller's phone number
      let currentUrl;
      if (singleProduct.isService) {
        currentUrl = `https://share.la7i27alak.com/share/service/${modifiedName}/${id}`;
      } else {
        currentUrl = `https://share.la7i27alak.com/share/product/${modifiedName}/${id}`;
      }
      const text = `Hello, I'm interested in your product ${currentUrl}`;
      const encodedText = encodeURIComponent(text);
      const url = `https://wa.me/${sellerPhoneNumber}?text=${encodedText}`;
      window.location.href = url;
    }
  }
  function initiatePhoneCall() {
    if (isExpired === "Expired " && singleProduct.timer?.enabled) {
      toast.warning("Product expired.");
    } else {
      const phoneNumber = singleProduct.user.phone; // Replace with the phone number you want to call
      const url = `tel:${phoneNumber}`;
      window.location.href = url;
    }
  }
  const currentUrl = "https://www.la7i27alak.com" + location.pathname;
  const discounted =
    singleProduct &&
    singleProduct.pricing?.price &&
    singleProduct &&
    singleProduct.discount?.enabled;
  const percentage =
    singleProduct &&
    singleProduct.pricing?.price &&
    singleProduct &&
    singleProduct.discount?.percentage;
  const price =
    singleProduct &&
    singleProduct.pricing?.price &&
    singleProduct &&
    singleProduct.pricing.price;
  const newprice = price - price * (percentage / 100);
  const Url = window.location.href;
  // console.log(singleProduct.imageUrls)
  useEffect(() => {
    const handlePopState = () => {
      navigate(-1);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  const ServicesWishlist = useSelector(
    (state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList
  );
  const wishList = useSelector(
    (state) => state.Wishlist?.Wishlist?.data?.wishList
  );
  useEffect(() => {
    dispatch(GetServicesWishlist());
    dispatch(GetWishList());
  }, [dispatch]);
  // console.log(formattedProductImage[0].toString())
  return (
    <>
      <Modal open={isOpen} closeHandler={() => setIsOpen(false)} />
      {/* <Helmet>
        <title>{formattedProductTitle}</title>
        <meta name="description" content={`${formattedProductTitle} detail`} />
        <meta
          name="og:description"
          content={`${formattedProductDescription} `}
        />
        <meta
          name="keywords"
          content={`${formattedProductTitle}, la7i27alak, product, service`}
        />
        <meta property="og:title" content={`${formattedProductTitle}`} />
        <meta property="og:url" content={`${Url}`} />
        {formattedProductImage && formattedProductImage.length > 0 && (
        <meta property="og:image" content={formattedProductImage[0].toString()} />
    )}
      </Helmet> */}
      {singleProduct && singleProduct.user?._id !== uid && (
        <div className={styles.fixed_container}>
          <div
            className={`${styles.fixed_button} ${
              darkMode ? styles.fixed_buttonDark : ""
            } ${styles.first}`}
            onClick={chatWithCustomer}
          >
            <div>
              <BiMessageRounded className={styles.fixed_icon} />
              <p>Chat</p>
            </div>
          </div>
          <div
            onClick={initiatePhoneCall}
            className={`${styles.fixed_button} ${
              darkMode ? styles.fixed_buttonDark : ""
            } ${styles.second}`}
          >
            <div>
              <Phone className={styles.fixed_icon} />
              <p>Call</p>
            </div>
          </div>
          <div
            onClick={openWhatsAppConversation}
            className={`${styles.fixed_button} ${
              darkMode ? styles.fixed_buttonDark : ""
            } ${styles.third}`}
          >
            <div>
              <WhatspApp className={styles.fixed_icon} />
              <p>Whatsapp</p>
            </div>
          </div>
        </div>
      )}
      <Layout
        title={
          location.state?.isService || type === "service"
            ? "Service Details"
            : "Product Details"
        }
      >
        {loading ? (
          <div style={{ marginLeft: "50px" }} className="center">
            <Loader />
          </div>
        ) : (
          <>
            <section
              className={`${styles.section} ${
                darkMode ? styles.sectionDark : ""
              }`}
            >
              <div className={styles.left_side} itemscope itemtype="http://schema.org/Product">
                <div className={styles.container}>
                  <ImageComponent
                    key={singleProduct && singleProduct._id}
                    featured={singleProduct && singleProduct.featured}
                    images={singleProduct && singleProduct.imageUrls}
                    views={singleProduct && singleProduct.viewCount}
                    likes={singleProduct && singleProduct.likes}
                    id={singleProduct && singleProduct._id}
                    isService={location.state?.isService || type === "service"}
                    waiting={singleProduct && singleProduct.waitingListNb}
                    name={singleProduct && singleProduct.title}
                    end={singleProduct && singleProduct.timer?.deadline}
                    timer={singleProduct && singleProduct.timer?.enabled}
                  />
                </div>
                <div className={styles.description_container}>
                  <h3
                    className={`${styles.title} ${
                      darkMode ? styles.titleDark : ""
                    }`}
                  >
                    Description
                  </h3>
                  <p
                    className={`${styles.text} ${
                      darkMode ? styles.textDark : ""
                    }`}
                    itemprop="description"
                  >
                    {singleProduct ? (
                      <p>{singleProduct.description}</p>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Loader />
                      </div>
                    )}
                  </p>
                </div>
                <div
                  className={`${styles.table_container} ${
                    darkMode ? styles.table_containerDark : ""
                  }`}
                >
                  <table>
                    <tbody>
                      {!location.state?.isService && type !== "service" && (
                        <>
                          <tr>
                            <td>
                              <span
                                className={`${styles.left} ${
                                  darkMode ? styles.leftDark : ""
                                }`}
                              >
                                Condition
                              </span>
                              <span
                                className={`${styles.right} ${
                                  darkMode ? styles.rightDark : ""
                                }`}
                              >
                                {singleProduct && singleProduct.condition
                                  ? "New"
                                  : "Used"}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span
                                className={`${styles.left} ${
                                  darkMode ? styles.leftDark : ""
                                }`}
                              >
                                Quantity
                              </span>
                              <span
                                className={`${styles.right} ${
                                  darkMode ? styles.rightDark : ""
                                }`}
                              >
                                {singleProduct && singleProduct.quantity}
                              </span>
                            </td>
                          </tr>
                        </>
                      )}
                      {singleProduct &&
                        singleProduct.fields &&
                        singleProduct.fields.map((field) => (
                          <tr key={field.title}>
                            {" "}
                            {/* Added a unique key prop for each table row */}
                            <td>
                              <span className={styles.left}>{field.title}</span>
                              <span className={styles.right}>
                                {typeof field.value === "number" &&
                                field.possibleValues
                                  ? field.possibleValues[field.value] // Check if field.possibleValues exists before accessing
                                  : field.value}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className={`${styles.right_side} ${
                  darkMode ? styles.right_sideDark : ""
                }`}
                itemscope
              >
                <div className={styles.wrapper}>
                  {loadingP ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <Loader />
                    </div>
                  ) : (
                    <>
                      {discounted ? (
                        <>
                          {singleProduct && singleProduct.pricing?.price && (
                            <h1
                              className={`${styles.price} ${
                                darkMode ? styles.priceDark : ""
                              }`}
                              itemprop="price"
                            >
                              USD{" "}
                              {singleProduct &&
                                singleProduct.discount?.newPrice}
                            </h1>
                          )}
                          <div className={styles.oldpd}>
                            {singleProduct && singleProduct.pricing?.price && (
                              <h1
                                className={`${styles.oldprice} ${
                                  darkMode ? styles.oldpriceDark : ""
                                }`}

                              >
                                USD {singleProduct.pricing.price}
                              </h1>
                            )}
                            {singleProduct && singleProduct.pricing?.price && (
                              <h1
                                className={`${styles.disc} ${
                                  darkMode ? styles.discDark : ""
                                }`}
                              >
                                % {singleProduct.discount?.percentage}
                              </h1>
                            )}
                          </div>
                        </>
                      ) : singleProduct &&
                        singleProduct.bidding &&
                        singleProduct.bidding?.enabled === true ? (
                        <>
                          {singleProduct && singleProduct.pricing?.price && (
                            <h1
                              className={`${styles.price} ${
                                darkMode ? styles.priceDark : ""
                              }`}
                              itemprop="price"
                            >
                              USD {singleProduct.pricing.price}
                            </h1>
                          )}
                          {singleProduct.allowedBidders.length > 0 && (
                            <div className={styles.oldpd}>
                              {singleProduct.bidding.highestBid &&
                                singleProduct.bidding.highestBid.amount > 0 && (
                                  <>
                                    <h1
                                      className={`${styles.highestBid} ${
                                        darkMode ? styles.highestBidDark : ""
                                      }`}
                                    >
                                      Highest bid:
                                    </h1>
                                    <h1
                                      className={`${styles.disc} ${
                                        darkMode ? styles.discDark : ""
                                      }`}
                                    >
                                      ${singleProduct.bidding.highestBid.amount}
                                    </h1>
                                  </>
                                )}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {singleProduct && singleProduct.pricing?.price && (
                            <h1
                              className={`${styles.price} ${
                                darkMode ? styles.priceDark : ""
                              }`}
                              itemprop="price"
                            >
                              USD {singleProduct.pricing.price}
                            </h1>
                          )}
                        </>
                      )}

                      <p
                        className={`${styles.description} ${
                          darkMode ? styles.descriptionDark : ""
                        }`}
                        itemprop="name"
                      >
                        {singleProduct && singleProduct.title}
                      </p>
                      <p
                        className={`${styles.location} ${
                          darkMode ? styles.locationDark : ""
                        }`}
                      >
                        <MdLocationPin className={styles.icon} />
                        {location.state?.isService || type === "service" ? (
                          <span>
                            {singleProduct &&
                              singleProduct.serviceAddress?.region}
                          </span>
                        ) : (
                          <span>
                            {singleProduct &&
                              singleProduct.pickUpAddress?.region}
                          </span>
                        )}
                      </p>
                      <p
                        className={`${styles.location} ${
                          darkMode ? styles.locationDark : ""
                        }`}
                      >
                        <MdTimer className={styles.icon} />
                        {singleProduct &&
                          singleProduct?.createdAt &&
                          timePosted(singleProduct.createdAt)}{" "}
                        ago
                      </p>
                    </>
                  )}

                  {singleProduct &&
                  singleProduct.status === "sold" &&
                  singleProduct &&
                  singleProduct.user?._id !== uid ? (
                    <div className={styles.sold__container}>
                      <p className={styles.soldtxt}>This Product Is Sold</p>
                    </div>
                  ) : singleProduct &&
                    singleProduct.bidding &&
                    singleProduct.bidding?.enabled === true ? (
                    loadingCard ? (
                      <Loader />
                    ) : (
                      <Bid
                        name={singleProduct.title}
                        productId={singleProduct._id}
                        end={singleProduct.timer?.deadline}
                        timer={singleProduct.timer?.enabled}
                        id={singleProduct.user?._id}
                      />
                    )
                  ) : !(location.state?.isService || type === "service") &&
                    singleProduct &&
                    singleProduct.user?._id !== uid ? (
                    <Negotiate
                      productId={singleProduct._id}
                      image={singleProduct.imageUrls[0]}
                      price={singleProduct.pricing?.price}
                      name={singleProduct.title}
                      date={singleProduct.createdAt}
                      featured={singleProduct.featured}
                      condition={singleProduct.condition}
                      id={singleProduct._id}
                      location={singleProduct.pickUpAddress?.region}
                      ifnegotiate={singleProduct.pricing.negotiable}
                      user1={singleProduct.user}
                      isDiscount={
                        singleProduct.discount &&
                        singleProduct.discount?.enabled
                      }
                      percentage={
                        singleProduct.discount &&
                        singleProduct.discount?.percentage
                      }
                    />
                  ) : null}

                  <div className={styles.line}></div>
                  <div className={styles.user_container}>
                    {singleProduct && singleProduct.user && (
                      <UserContainer
                        image={singleProduct.user?.imageUrl}
                        name={singleProduct.user?.username}
                        rate={singleProduct.user?.reviews?.average}
                        date={singleProduct.user?.createdAt}
                        id={singleProduct.user?._id}
                      />
                    )}
                  </div>
                  <div className={styles.line}></div>
                  <div className={styles.safety_container}>
                    <h1
                      className={`${styles.safety_title} ${
                        darkMode ? styles.safety_titleDark : ""
                      }`}
                    >
                      Safety Guidelines
                    </h1>
                    <ul>
                      <li
                        className={`${styles.li} ${
                          darkMode ? styles.liDark : ""
                        }`}
                      >
                        Only meet in public or crowded places.
                      </li>
                      <li
                        className={`${styles.li} ${
                          darkMode ? styles.liDark : ""
                        }`}
                      >
                        Never go alone to finish a deal, always have someone
                        with you.
                      </li>
                      <li
                        className={`${styles.li} ${
                          darkMode ? styles.liDark : ""
                        }`}
                      >
                        Always check the product thoroughly before purchase.
                      </li>
                      <li
                        className={`${styles.li} ${
                          darkMode ? styles.liDark : ""
                        }`}
                      >
                        Never pay anything in advance or transfer money before
                        inspecting the products carefully.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <RelatedProducts
                categoryId={singleProduct && singleProduct.categoryId}
                productId={singleProduct && singleProduct._id}
                type={type}
                ServicesWishlist={ServicesWishlist}
                wishList={wishList}
              />
            </section>
          </>
        )}
      </Layout>
    </>
  );
};
export default ProductDetails;
