import React from "react";
import styles from "./Card.module.scss";
import BMW from "../../../../assests/images/bmw.png";
import { MdLocationPin, MdTimer } from "react-icons/md";
import { ReactComponent as FilledHeart } from "../../../../assests/svg/Filled Heart.svg";
import { ReactComponent as New } from "../../../../assests/svg/New wideCard.svg";
import { timePosted } from "../../../../common/Utils";
import green from "../../../../assests/svg/green.svg";
import red from "../../../../assests/svg/red.svg";
import { useSelector } from "react-redux";
const Card = ({ title, featured, image, price, location, time, condition, isDiscount, percentage }) => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const newprice = price - (price * (percentage / 100))
  return (
    <div className={`${styles.card_section} ${darkMode ? styles.card_sectionDark : ''}`}>
      <div className={styles.image_container}>
        <img src={image} alt="product" className={styles.img} />
        {featured && (
          <div className={`${styles.featured_containermb}`}>
            <p className={styles.featured_textmb}>Featured</p>
          </div>
        )}
      </div>
      <div className={styles.details_container}>
        {isDiscount ? (
          <div className={`${styles.price__containermb} ${isDiscount ? styles.price__containermbdisc : ''}`}>
            <h3 className={`${styles.pricemb} ${darkMode ? styles.pricembDark : ''}`}>$ {newprice}</h3>
            <div className={styles.oldmbpd}>
              <h3 className={`${styles.oldmbprice} ${darkMode ? styles.oldmbpriceDark : ''}`}>
                {price}
              </h3>
              <h3 className={`${styles.discountmb} ${darkMode ? styles.discountmbDark : ''}`}>%{percentage}</h3>
            </div>
          </div>
        ) : (
          <div className={styles.price__containermb}>
            <h3 className={`${styles.pricemb} ${darkMode ? styles.pricembDark : ''}`}>$ {price && price}</h3>
          </div>
        )}
        <p className={`${styles.title}  ${isDiscount ? styles.titlembdisc : ''} ${darkMode ? styles.titleDark : ''}`}>
        {title.length > 25 ? title.slice(0, 25) + "..." : title}
          </p>
        <p className={`${styles.location} ${darkMode ? styles.locationDark : ''}`}>
          <MdLocationPin className={styles.icon} />
          {location}
        </p>
        <p className={`${styles.time} ${darkMode ? styles.timeDark : ''}`}>
          <MdTimer className={styles.icon} />
          {timePosted(time)} ago
        </p>
        {/* <FilledHeart className={styles.heart_icon} /> */}
        {condition !== undefined && (
          <div className={styles.condition_container}>
            <p className={styles.condition}>{condition ? "New" : "Used"}</p>
            <img src={condition ? green : red} alt={condition ? "new" : "used"} className={styles.condition_image} />
          </div>
        )}
      </div>
    </div>
  );
};
export default Card;
