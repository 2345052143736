import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import styles from "./CategoryPage.module.scss";
import Layout from "../../common/components/Layouts/Layout";
import CurrentPath from "../../common/components/CurrentPath/CurrentPath";
import Search from "../../common/components/Search/Search";
import Header from "../../common/components/Header/Header";
import Footer from "../../common/components/Footer/Footer";
import Cards from "../../common/components/Cards/Cards";
import { CgSortAz } from "react-icons/cg";
import { MdGridView } from "react-icons/md";
import { AiFillFilter, AiOutlineMenu } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { listMyProductsBySubCategory } from "../../store/actions/categoryAction";
import { PRODUCT_LIST_BY_SUBCATEGORY_RESET } from "../../store/constants/categoryConstants";
import Loader from "../../common/components/Loader/Loader";
import ErrorMessage from "../../common/components/ErrorMessage/ErrorMessage";
import { handleFilterIcon } from "../../store/actions/filterActions";
import {
  GetProductBySubCat,
  GetProductBySubCatSuccess,
  SearchProductSuccess,
} from "../../store/actions/productDetailsAction";
import {
  GetProductByCat,
  GetProductByCatSuccess,
} from "../../store/actions/productDetailsAction";
import CardSkel from "../../common/components/Cards/CardSkel";
import { Helmet } from "react-helmet";
import {
  GetSingleProduct,
  GetSingleProductSuccess,
} from "../../store/actions/myProductsActions";
import { GetAllBids } from "../../store/actions/productDetailsAction";
import { GetAllBidsSuccess } from "../../store/actions/productDetailsAction";
import img from "../../assests/svg/empty products.svg";
import FilterModal from "../../common/components/FilterModal/FilterModal";
import { SearchProduct } from "../../store/actions/productDetailsAction";
import { GetServicesWishlist } from "../../store/actions/servicesActions";
import { GetWishList } from "../../store/actions/whishlistActions";
const AllBidsPage = () => {
  const dispatch = useDispatch();
  const filterValue = useSelector(
    (state) => state.filterValue?.convertedFilterValues
  );
  useEffect(() => {
    if (location.state?.isBid) {
      const pageName = "All bids"; // Replace this with the actual page name
      const pageUrl = "/bids"; // Replace this with the actual page URL
      dispatch({
        type: "ADD_BREADCRUMB",
        payload: { name: pageName, url: pageUrl },
      });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" });
      const pageName = "All bids"; // Replace this with the actual page name
      const pageUrl = "/bids"; // Replace this with the actual page URL
      dispatch({
        type: "ADD_BREADCRUMB",
        payload: { name: pageName, url: pageUrl },
      });
    }
  }, []);
  const [activePage, setActivePage] = useState(1);
  const [Pro, setPro] = useState([]);
  const [sort, setSort] = useState("");
  const params = useParams();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const location = useLocation();
  const [filterValues, setFilterValues] = useState({});
  const Allbids = useSelector(
    (state) => state.SearchComponent?.SearchComponent?.data?.products
  );
  const pageSize = useSelector(
    (state) => state.SearchComponent?.SearchComponent?.data?.pageSize
  );
  const totalProducts = useSelector(
    (state) => state.SearchComponent?.SearchComponent?.data?.productCount
  );
  const loading = useSelector((state) => state.SearchComponent?.loading);
  const totalPages = Math.ceil(totalProducts / pageSize);
  const error = useSelector((state) => state.SearchComponent?.error);
  const columnSize = window.innerWidth < 768 ? 10 : 12;
  const handleFilterInputChange = (event, key) => {
    const { value } = event.target;
    setFilterValues((prevValues) => ({ ...prevValues, [key]: value }));
  };
  const handleFilterValuesUpdate = (newFilterValues) => {
    setFilterValues(newFilterValues);
  };
  const navigate = useNavigate();
  const convertedFilterValues = Object.entries(filterValues).reduce(
    (acc, [key, value]) => {
      let parsedValue = value;
      if (!isNaN(parseInt(value))) {
        parsedValue = parseInt(value);
      } else {
        try {
          parsedValue = JSON.parse(value);
        } catch {
          // If the value is not a valid JSON string, leave it as is
        }
      }
      return { ...acc, [key]: parsedValue };
    },
    {}
  );
  const data = {
    status: ["confirmed"],
    specialCriteria: ["products", "bids"],
  };

  const [test, setTest] = useState([]);
  useEffect(() => {
    if (location.state?.subCategoryId) {
      setTest(filterValue);
      const combined = {
        ...test,
        status: ["confirmed"],
        specialCriteria: ["products", "bids"],
      };

      dispatch(
        SearchProduct({
          pageSize: columnSize,
          page: 1,
          rawData: combined,
          sort: sort,
          subCategoryId: location.state?.subCategoryId,
        })
      )
        .then((data) => {
          dispatch(SearchProductSuccess(data));
        })
        .catch((error) => {
          // console.log(error);
        });
    } else if (location.state?.categoryId) {
      setTest(filterValue);
      const combined = {
        ...test,
        status: ["confirmed"],
        specialCriteria: ["products", "bids"],
      };

      dispatch(
        SearchProduct({
          pageSize: columnSize,
          page: 1,
          rawData: combined,
          sort: sort,
          categoryId: location.state?.categoryId,
        })
      )
        .then((data) => {
          dispatch(SearchProductSuccess(data));
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      setTest(filterValue);
      const combined = {
        ...test,
        status: ["confirmed"],
        specialCriteria: ["products", "bids"],
      };
      dispatch(
        SearchProduct({
          pageSize: columnSize,
          page: 1,
          rawData: combined,
          sort: sort,
        })
      )
        .then((data) => {
          dispatch(SearchProductSuccess(data));
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [dispatch, sort]);
  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber);
    if (sort) {
      try {
        if (location.state?.subCategoryId) {
          setTest(filterValue);
          const combined = {
            ...test,
            status: ["confirmed"],
            specialCriteria: ["products", "bids"],
          };

          const response = await dispatch(
            SearchProduct({
              pageSize: columnSize,
              page: pageNumber,
              rawData: combined,
              sort: sort,
              subCategoryId: location.state?.subCategoryId,
            })
          );
        } else if (location.state?.categoryId) {
          setTest(filterValue);
          const combined = {
            ...test,
            status: ["confirmed"],
            specialCriteria: ["products", "bids"],
          };

          const response = await dispatch(
            SearchProduct({
              pageSize: columnSize,
              page: pageNumber,
              rawData: combined,
              sort: sort,
              categoryId: location.state?.categoryId,
            })
          );
        } else {
          setTest(filterValue);
          const combined = {
            ...test,
            status: ["confirmed"],
            specialCriteria: ["products", "bids"],
          };

          const response = await dispatch(
            SearchProduct({
              pageSize: columnSize,
              page: pageNumber,
              rawData: combined,
              sort: sort,
            })
          );
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      try {
        if (location.state?.subCategoryId) {
          setTest(filterValue);
          const combined = {
            ...test,
            status: ["confirmed"],
            specialCriteria: ["products", "bids"],
          };

          const response = await dispatch(
            SearchProduct({
              pageSize: columnSize,
              page: pageNumber,
              rawData: combined,
              subCategoryId: location.state?.subCategoryId,
            })
          );
        } else if (location.state?.categoryId) {
          setTest(filterValue);
          const combined = {
            ...test,
            status: ["confirmed"],
            specialCriteria: ["products", "bids"],
          };

          const response = await dispatch(
            SearchProduct({
              pageSize: columnSize,
              page: pageNumber,
              rawData: combined,
              categoryId: location.state?.categoryId,
            })
          );
        } else {
          setTest(filterValue);
          const combined = {
            ...test,
            status: ["confirmed"],
            specialCriteria: ["products", "bids"],
          };

          const response = await dispatch(
            SearchProduct({
              pageSize: columnSize,
              page: pageNumber,
              rawData: combined,
            })
          );
        }
      } catch (error) {
        // console.log(error);
      }
    }
  };
  const handleButtonClick = () => {
    const nextPage = activePage + 1;
    handlePageChange(nextPage);
  };
  const GetByCat = useSelector(
    (state) => state.GetByCat?.GetByCat?.data?.products
  );
  const handleCategoryFilter = () => {
    setPro(GetByCat);
  };
  const ProductArray = Pro;
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const handleSortChange = async (event) => {
    const selectedValue = event.target.value;
    setTest(filterValue);
    if (selectedValue === "A-Z") {
      setSort("az");
    } else if (selectedValue === "Z-A") {
      setSort("za");
    } else if (selectedValue === "Highest-to-lowest-price") {
      setSort("high");
    } else if (selectedValue === "lowest-to-highest-price") {
      setSort("low"); // Handle other cases or the default value
    } else if (selectedValue === "featured") {
      setSort("featured");
    } else if (selectedValue === "default") {
      setSort("");
    }
  };
  const [view, setView] = useState(false);
  useEffect(() => {
    function handleResize() {
      setView(window.innerWidth < 445);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const savedValue = useSelector((state) => state.value.value);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleFilter = () => {
    if (screenWidth > 900) {
      dispatch({ type: "SAVE_VALUE", payload: !savedValue });
      setIsOpen(!savedValue);
    } else {
      dispatch({ type: "SAVE_VALUE", payload: false });
      setIsFilterOpen(true);
    }
  };
  useEffect(() => {
    return () => {
      dispatch({ type: "SAVE_VALUE", payload: false });
    };
  }, []);
  // Handle visibilitychange event
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        dispatch({ type: "SAVE_VALUE", payload: false });
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  const ServicesWishlist = useSelector(
    (state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList
  );
  const wishList = useSelector(
    (state) => state.Wishlist?.Wishlist?.data?.wishList
  );
  useEffect(() => {
    dispatch(GetServicesWishlist());
    dispatch(GetWishList());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>All bids</title>
        <meta name="All Bids" content="This is Categories Page" />
        <meta
          name="keywords"
          content={`All Bids, La7i27alal, biddings, la7i27alak bids, product bids, all live bids, live bids in lebanon, Live Biddings Lebanon, Lebanon, Bids lebanon, Lebanon bids, Product Bids, New bids Lebanon Products`}
        />
      </Helmet>
      <Layout
        footer={false}
        isFilterIcon={true}
        onCategoryFilter={handleCategoryFilter}
        bids={true}
        sort={sort}
        SubCategoryId={location.state?.subCategoryId}
        CategoryId={location.state?.categoryId}
      >
        <section
          className={`${styles.category_section} ${
            darkMode ? styles.category_sectionDark : ""
          }`}
        >
          {/* <div className={styles.path}>
          <CurrentPath />
        </div> */}
          {/* <div className={styles.search}>
                        <Search categoryId={location.state?.id || null} />
                    </div> */}
          <div className={styles.title_container}>
            <h1
              className={`${styles.title} ${darkMode ? styles.titleDark : ""}`}
            >
              All bids
            </h1>
          </div>
          <div
            className={`${styles.result_container} ${
              darkMode ? styles.result_containerDark : ""
            }`}
          >
            <h1
              className={`${styles.result_title} ${
                darkMode ? styles.result_titleDark : ""
              }`}
            >
              {Allbids &&
                Allbids.filter((p) => p.status === "confirmed").length}{" "}
              Results Found
            </h1>
            <div className={styles.result_options}>
              <div onClick={handleFilter} className={styles.filter_container}>
                <AiFillFilter className={styles.filter_icon} />
                <button className={styles.filter_btn}>Filter</button>
              </div>
              <span>
                <CgSortAz
                  className={`${styles.sort_icon} ${
                    darkMode ? styles.sort_iconDark : ""
                  }`}
                />{" "}
                <label
                  className={`${styles.label} ${
                    darkMode ? styles.labelDark : ""
                  }`}
                  htmlFor="sort"
                >
                  Sort:
                </label>
                <select
                  className={`${styles.select} ${
                    darkMode ? styles.selectDark : ""
                  }`}
                  name="sort"
                  id="sort"
                  onChange={handleSortChange}
                >
                  <option
                    value="default"
                    selected
                    disabled={sort === "default"}
                  >
                    Sort
                  </option>
                  <option value="featured">Featured</option>
                  <option value="A-Z">Alphabetically: A-Z</option>
                  <option value="Z-A">Alphabetically: Z-A</option>
                  <option value="Highest-to-lowest-price">
                    Highest to Lowest Price
                  </option>
                  <option value="lowest-to-highest-price">
                    Lowest to Highest Price
                  </option>
                </select>
              </span>
              <span
                className={`${styles.span} ${darkMode ? styles.spanDark : ""}`}
                onClick={() => setView(!view)}
              >
                View:
                {view ? (
                  <AiOutlineMenu className={styles.view_icon} />
                ) : (
                  <MdGridView className={styles.view_icon} />
                )}
              </span>
            </div>
          </div>
          {loading ? (
            <div>
              {/* <Loader /> */}
              <CardSkel />
            </div>
          ) : error ? (
            <div className="center">
              <ErrorMessage>{error}</ErrorMessage>
            </div>
          ) : (
            <div>
              {Allbids ? (
                <div
                  className={`grid gap-4
                ${
                  view
                    ? "grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3"
                    : "grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4"
                }`}
                >
                  {Allbids.filter((p) => p.status === "confirmed").map((p) => (
                    <Cards
                      id={p._id}
                      key={p._id}
                      title={p.title}
                      price={p.pricing.price}
                      old={p.condition}
                      time={p.createdAt}
                      mainimage={p.imageUrls[0]}
                      featured={p.featured}
                      location={p && p.pickUpAddress?.region}
                      timer={p.timer?.enabled}
                      end={p.timer?.deadline}
                      discounted={p.discount?.enabled}
                      percentage={p.discount?.percentage}
                      mobile={view}
                      isService={p.isService}
                      ServicesWishlist={ServicesWishlist} // Pass ServicesWishlist as prop
                      wishList={wishList}
                      sold={p && p.status === "sold"}
                    />
                  ))}
                </div>
              ) : (
                <div className={styles.empty_container}>
                  <img src={img} alt="empty products" className={styles.img} />
                  <h1
                    className={`${styles.title} ${
                      darkMode ? styles.titleDark : ""
                    }`}
                  >
                    No Results Found
                  </h1>
                  <button
                    className={`${styles.button} ${
                      darkMode ? styles.buttonDark : ""
                    }`}
                    onClick={() => navigate("/")}
                  >
                    Go Shopping
                  </button>
                </div>
              )}
            </div>
          )}
          {totalProducts > 0 && (
            <Pagination style={{ marginBottom: "0", paddingBottom: "20px" }}>
              {activePage === 1 ? null : (
                <Pagination.Prev
                  onClick={() => handlePageChange(activePage - 1)}
                  disabled={activePage === 1}
                />
              )}
              {totalPages === 1 ? (
                <Pagination.Item
                  linkStyle={{ background: "#0298a6", border: "none" }}
                  active
                >
                  {activePage}
                </Pagination.Item>
              ) : (
                <>
                  {activePage === 1 ? null : (
                    <Pagination.Item
                      onClick={() => handlePageChange(activePage - 1)}
                      active={activePage === 1}
                    >
                      {activePage - 1}
                    </Pagination.Item>
                  )}
                  <Pagination.Item
                    linkStyle={{ background: "#0298a6", border: "none" }}
                    active
                  >
                    {activePage}
                  </Pagination.Item>
                  {activePage === totalPages ? null : (
                    <Pagination.Item
                      onClick={() => handlePageChange(activePage + 1)}
                      active={activePage === totalPages}
                    >
                      {activePage === totalPages ? activePage : activePage + 1}
                    </Pagination.Item>
                  )}
                </>
              )}
              {activePage === totalPages ? null : (
                <Pagination.Next
                  onClick={() => handlePageChange(activePage + 1)}
                  disabled={activePage === totalPages}
                />
              )}
            </Pagination>
          )}
        </section>
      </Layout>
      <FilterModal
        open={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onCategoryFilter={handleCategoryFilter}
        bids={true}
        sort={sort}
        SubCategoryId={location.state?.subCategoryId}
        CategoryId={location.state?.categoryId}
      />
      <Footer />
    </>
  );
};
export default AllBidsPage;
