import {
    SEARCH_ALL_SERVICE_FAIL,
    SEARCH_ALL_SERVICE_SUCCESS
} from "./productDetailsConstants";
const initialState = {
    searchAllService: [],
    error: null,
    loading: false
};
export const SearchAllServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_ALL_SERVICE_SUCCESS:
            return {
                ...state,
                searchAllService: action.payload,
                error: null,
                loading: false,
            };
        case SEARCH_ALL_SERVICE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
