import React from "react";
import "./Status.scss";
const Status = ({ state }) => {
  let statusText = state ? "Disabled" : "Active";
  let color = state ? "lightbb" : "blue";
  return (
    <div className={`statediv ${color}`}>
      <span className="state">{statusText}</span>
    </div>
  );
};
export default Status;
