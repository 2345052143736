import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./index.module.scss";
import AppleStore from "../../../../assests/images/appstore.png";
import { parseISO, format } from "date-fns";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../common/components/Loader/Loader";

const FeedContainer = ({ offerByDate, loading }) => {
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);

  const filteredOffers = offerByDate && offerByDate.filter(offer => offer.status !== 'pending');
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmall(window.innerWidth < 1150);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div>

      {filteredOffers && filteredOffers.length === 0 ? (
        isSmall ? (null) : (
          <div
            className={styles.container}
            style={{ cursor: 'default' }}
          >

            <div
              className={styles.line}
              style={{
                borderColor: "#0298a6"
              }}
            ></div>
            <div
              className={styles.imageSkel}
            ></div>
            <div className={styles.details}>
              <h1
                className={`${styles.title} ${darkMode ? styles.titleDark : ""
                  }`}
              >
                No feeds
              </h1>
              <p
                className={`${styles.text} ${darkMode ? styles.textDark : ""
                  }`}
              >
                Pick new date for offers.
              </p>
            </div>
          </div>
        )
      ) : (
        filteredOffers && filteredOffers.map((offer) => {
          const createdAt = parseISO(offer.createdAt);
          const formattedTime = format(createdAt, "hh:mm a");

          return (
            <div
              className={styles.container}
              key={offer._id}
              onClick={() =>
                navigate(
                  `/${offer.isService ? "service" : "product"
                  }/${offer.title.replaceAll(" ", "-")}/${offer._id}`,
                  { state: { id: offer._id, isService: offer.isService } }
                )
              }
            >
              <div className={`${styles.time} ${darkMode ? styles.timeDark : ''}`}>
                {formattedTime}
              </div>
              <div
                className={styles.line}
                style={{
                  borderColor: offer.bidding?.enabled
                    ? "#e76859"
                    : !offer.isService
                      ? "#ffcb3c"
                      : "#59aef7",
                }}
              ></div>
              <img
                src={offer.imageUrls[0]}
                alt="product"
                className={styles.image}
              />
              <div className={styles.details}>
                <h1
                  className={`${styles.title} ${darkMode ? styles.titleDark : ""
                    }`}
                >
                  {offer.title}
                </h1>
                <p
                  className={`${styles.text} ${darkMode ? styles.textDark : ""
                    }`}
                >
                  {offer.description.length > 40
                    ? offer.description.substring(0, 40) + ".."
                    : offer.description}
                </p>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default FeedContainer;
