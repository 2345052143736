import React from "react";
import styles from "./Card.module.scss";
import BMW from "../../../assests/images/bmw.png";
import { MdLocationPin, MdTimer } from "react-icons/md";
import { ReactComponent as FilledHeart } from "../../../assests/svg/Filled Heart.svg";
import { ReactComponent as New } from "../../../assests/svg/New wideCard.svg";
import { timePosted } from "../../../common/Utils";
import green from "../../../assests/svg/green.svg";
import red from "../../../assests/svg/red.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const Card = ({ title, featured, image, price, location, id, time, condition, isDiscount, newPrice, status, percentage, offerId, coordinatesSeller, coordinatesBuyer, user }) => {
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const navigate = useNavigate()
  const Accepted = true
  const Rejected = false
  return (
    <div className={`${styles.card_section} ${darkMode ? styles.card_sectionDark : ''}`}>
      <div className={styles.image_container}>
        <Link
          to={`/product/${title.replace(" ", "-")}/${id}`} // Adjust the route as needed
          className={styles.link_image_container}
        >
          <img src={image} alt="product" className={styles.img} />
        </Link>
        {status === "accepted" ? (
          <>
            <div className={styles.stateA}>Accepted</div>
            <button onClick={() => navigate("/user/waiting-list/track-seller", { state: { id: offerId, coordinatesBuyer: coordinatesBuyer, coordinatesSeller: coordinatesSeller, user: user } })} className={styles.track}>Track seller</button>
          </>
        ) : status === "rejected" ? (
          <div className={styles.stateR}>Rejected</div>
        ) : status === "sold" ? (
          <div className={styles.stateR}>Sold</div>
        ) : (
          <div className={styles.stateW}>Waiting</div>
        )}

        {featured && (
          <div className={`${styles.featured_containermb}`}>
            <p className={styles.featured_textmb}>Featured</p>
          </div>
        )}
      </div>
      <div className={styles.details_containermb}>
        <Link
          to={`/product/${title.replace(" ", "-")}/${id}`} // Adjust the route as needed
          className={styles.link_image_container}
        >
          {isDiscount ? (
            <div className={`${styles.price__containermb} ${isDiscount ? styles.price__containermbdisc : ''}`}>
              <h3 className={`${styles.pricemb} ${darkMode ? styles.pricembDark : ''}`}>$ {newPrice}</h3>
              <div className={styles.oldmbpd}>
                <h3 className={`${styles.oldmbprice} ${darkMode ? styles.oldmbpriceDark : ''}`}>
                  {price ? price : '400'}
                </h3>
                <h3 className={`${styles.discountmb} ${darkMode ? styles.discountmbDark : ''}`}>%{percentage}</h3>
              </div>
            </div>
          ) : (
            <div className={styles.price__containermb}>
              <h3 className={`${styles.pricemb} ${darkMode ? styles.pricembDark : ''}`}>$ {price ? price : '400'}</h3>
            </div>
          )}
          <p className={`${styles.title} ${isDiscount ? styles.titlembdisc : ''} ${darkMode ? styles.titleDark : ''}`}>
            {title.length > 25 ? title.slice(0, 25) + "..." : title}
          </p>
          <p className={`${styles.location} ${darkMode ? styles.locationDark : ''}`}>
            <MdLocationPin className={styles.icon} />
            {location}
          </p>
          <p className={`${styles.time} ${darkMode ? styles.timeDark : ''}`}>
            <MdTimer className={styles.icon} />
            {timePosted(time)} ago
          </p>
          {/* <FilledHeart className={styles.heart_icon} /> */}
          {condition !== undefined && (
            <div className={styles.condition_container}>
              <p className={styles.condition}>{condition ? "New" : "Used"}</p>
              <img src={condition ? green : red} alt={condition ? "new" : "used"} className={styles.condition_image} />
            </div>
          )}
        </Link>
      </div>
    </div>
  );
};
export default Card;
