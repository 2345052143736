import {
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  VEHICLE_BRAND_FAIL,
  VEHICLE_BRAND_REQUEST,
  VEHICLE_BRAND_SUCCESS,
  BRAND_MODULES_FAIL,
  BRAND_MODULES_REQUEST,
  BRAND_MODULES_SUCCESS,
  BODYTYPES_FAIL,
  BODYTYPES_REQUEST,
  BODYTYPES_SUCCESS,
  UPLOAD_MAIN_IMAGE_REQUEST,
  UPLOAD_MAIN_IMAGE_SUCCESS,
  UPLOAD_MAIN_IMAGE_FAIL,
  UPLOAD_IMAGES_REQUEST,
  UPLOAD_IMAGES_SUCCESS,
  UPLOAD_IMAGES_FAIL,
  ADD_SERVICE_REQUEST,
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAIL,
  CREATE_PRODUCT_FAIL,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_REQUEST,
} from "../constants/addProductConstants";
import { url } from "../../common/constants/url";
import axios from "axios";
export const addProductHandler =
  (body1, bidState, timerState, Sub_category_id) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_PRODUCT_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let bid = bidState ? 1 : 0;
      let timer = timerState ? 1 : 0;
      let body = { ...body1, bid, timer, Sub_category_id };
      const { data } = await axios.post(
        url + `/products/addProduct`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: ADD_PRODUCT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADD_PRODUCT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const uploadMainImageHandler = (file) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPLOAD_MAIN_IMAGE_REQUEST,
    });
    let formData = new FormData();
    formData.append("file", file);
    const {
      addproduct: { id },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    await axios.post(`${url}/products/${id}/uploadFilemain`, formData, config);
    dispatch({
      type: UPLOAD_MAIN_IMAGE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: UPLOAD_MAIN_IMAGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const uploadImagesHandler = (file) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPLOAD_IMAGES_REQUEST,
    });
    let formData = new FormData();
    file.map((f) => formData.append("file", f));
    const {
      addproduct: { id },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const { data } = await axios.post(
      `${url}/products/${id}/uploadImageProducts`,
      formData,
      config
    );
    dispatch({
      type: UPLOAD_IMAGES_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: UPLOAD_IMAGES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const addprod =
  (
    title,
    description,
    manufacturingyears,
    price,
    vehiclesbrands_id,
    brandmodules_id,
    bodytypes_id
    // offerscolors_id,
    // transmissiontype,
    // conditiontype
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_PRODUCT_REQUEST,
      });
      const {
        uploadMainImage: { link },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let published = 1;
      let mainimage = link;
      let body = {
        title,
        description,
        manufacturingyears,
        price,
        vehiclesbrands_id,
        brandmodules_id,
        bodytypes_id,
        published,
        mainimage,
      };
      await axios.post(
        url + `/products/addProduct`,
        JSON.stringify(body),
        config
      );
      dispatch({
        type: ADD_PRODUCT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ADD_PRODUCT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const listvehiclebrand = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_BRAND_REQUEST,
    });
    const { data } = await axios.get(url + `/vehiclesbrands/getvehiclesbrands`);
    let brandarray = [];
    data.body.data.map((b) => {
      brandarray.push({
        value: b.id,
        label: b.name,
      });
    });
    dispatch({
      type: VEHICLE_BRAND_SUCCESS,
      payload: brandarray,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: VEHICLE_BRAND_FAIL,
      payload: message,
    });
  }
};
export const listbrandmodules = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BRAND_MODULES_REQUEST,
    });
    const { data } = await axios.get(url + `/brandmodules/getbrandmodules`);
    let modulebrandarray = [];
    data.body.data.map((b) => {
      modulebrandarray.push({
        value: b.id,
        label: b.name,
      });
    });
    dispatch({
      type: BRAND_MODULES_SUCCESS,
      payload: modulebrandarray,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: BRAND_MODULES_FAIL,
      payload: message,
    });
  }
};
export const listbodytypes = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BODYTYPES_REQUEST,
    });
    const { data } = await axios.get(url + `/bodytypes/getbodytypes`);
    let bodytypearray = [];
    data.body.data.map((b) => {
      bodytypearray.push({
        value: b.id,
        label: b.name,
      });
    });
    dispatch({
      type: BODYTYPES_SUCCESS,
      payload: bodytypearray,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: BODYTYPES_FAIL,
      payload: message,
    });
  }
};
// export const listproductimages = () => async (dispatch, getState) => {
//   try {
//     dispatch({
//       type: PRODUCT_IMAGES_REQUEST,
//     });
//     const { data } = await axios.post(
//       url + `/productsimages/addproductsimages`
//     );
//     // let productsimages = [];
//     // data.body.data.map(b => {
//     //   productsimages.push({
//     //     value: b.id,
//     //     label: b.name,
//     //   });
//     // });
//     dispatch({
//       type: PRODUCT_IMAGES_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     const message =
//       error.response && error.response.data.message
//         ? error.response.data.message
//         : error.message;
//     dispatch({
//       type: PRODUCT_IMAGES_FAIL,
//       payload: message,
//     });
//   }
// };
// export const addprodimg = (imagesrc, products_id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: ADD_PRODUCT_REQUEST,
//     });
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };
//     const { data } = await axios.post(
//       url + `/products/addProduct`,
//       {CREATE_PRODUCT_REQUEST
//         imagesrc,
//         products_id,
//       },
//       config
//     );
//     dispatch({
//       type: ADD_PRODUCT_SUCCESS,
//       payload: data,
//     });
//   } catch (error) {
//     dispatch({
//       type: ADD_PRODUCT_FAIL,
//       payload:
//         error.response && error.response.data.message
//           ? error.response.data.message
//           : error.message,
//     });
//   }
// };
export const CreateProduct = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(createProductRequest())
      const token = localStorage.getItem('token')
      
      const response = await axios.post(`${url}/product`, formData, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      dispatch(createProductSuccess(response.data));
      return response.data
    } catch (error) {
      dispatch(createProductFail(error));
      // console.log(error)
    }
  };
};
const createProductRequest = () => ({
  type: CREATE_PRODUCT_REQUEST,
});
const createProductSuccess = (data) => ({
  type: CREATE_PRODUCT_SUCCESS,
  payload: data
});
const createProductFail = (error) => ({
  type: CREATE_PRODUCT_FAIL,
  payload: error
});