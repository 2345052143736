import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./Payment";
import Layout from "../../common/components/Layouts/Layout";
import { useDispatch } from "react-redux";
const stripe = loadStripe("pk_test_51Mm61YLKNx7usOBBJ1lxEGb0cxNzAckqZikDB3pR2qxiFjN1FumdNJnKaI7B8I86BelQAb0POOf6jMiuJkGmTR3n00rQz55R0n");
export default function AddCardPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    const pageName = "Add Card"; // Replace this with the actual page name
    const pageUrl = "/user/payment/add-card"; // Replace this with the actual page URL
    dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
  }, []);
  return (
    <Layout title={"Add New Card"}>
      <Elements stripe={stripe}>
        <PaymentForm />
      </Elements>
    </Layout>
  );
}