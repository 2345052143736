import React, { useEffect, useState } from 'react';
import { isMobile,isAndroid,isIOS } from 'react-device-detect';
import styles from './MobileBanner.module.scss'
import logo from '../../../assests/svg/La7i2_7alak1.1.svg'
import { useSelector } from 'react-redux';
import { colors } from '@material-ui/core';
import { AiOutlineArrowRight, AiOutlineRight } from 'react-icons/ai';

const MobileBanner = () => {
    const [isVisible, setIsVisible] = useState(false);
   
      const darkMode = useSelector((state) => state.darkMode.darkMode);
      useEffect(() => {
        // Check local storage for a flag indicating if the banner has been shown before
        const hasBannerBeenShownBefore = sessionStorage.getItem('hasMobileBannerBeenShown');
    
        if (!hasBannerBeenShownBefore) {
          // Show the banner if it hasn't been shown before
          setIsVisible(true);
    
          // Set a flag in local storage to indicate that the banner has been shown
          sessionStorage.setItem('hasMobileBannerBeenShown', 'true');
        }
      }, []);
    const handleDownloadClick = () => {
        let storeUrl;
    
        if (isAndroid) {
          // Redirect to Google Play for Android
          storeUrl = 'https://play.google.com/store/apps/details?id=com.la72halk';
        }  if (isIOS) {
          // Redirect to the App Store for iOS
          storeUrl = 'https://apps.apple.com/lb/app/la7i27alak/id1660383776';
        } 
        // Redirect the user to the appropriate store URL
        window.location.href = storeUrl;
      };
      const handleDontDownloadClick = () => {
        setIsVisible(false); // Hide the modal
      };
    
    if (isMobile) {
        return (
            <div className={isVisible? styles.container:styles.hideit}>
            <div className={` ${styles.MobileBanner} ${darkMode? styles.MobileBannerDark:''} ${isVisible ?styles.MobileBannerShow : styles.MobileBannerHide }`}>
            <div className={styles.logoCont}>
      <img src={logo} alt='logo' className={styles.logo}/>
      </div>
              <p style={{ color: darkMode ? 'white' : '' }}>Do you want to use our mobile app?</p>
              <button className={`${styles.Download} ${darkMode?styles.DownloadDark:''}`} onClick={handleDownloadClick}>Download App</button>
              <div style={{display:'flex'}}>
              <button className={`${styles.DontDownload} ${darkMode?styles.DontDownloadDark:''}`} onClick={handleDontDownloadClick}>
                Continue in browser <AiOutlineArrowRight style={{marginTop:'7px',marginLeft:'7px'}}/></button>
                </div>
            </div>
            </div>
          );
    }
    return null; // Don't render the banner for non-mobile users
  };

export default MobileBanner;
  