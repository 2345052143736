import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  ADD_FAVORITE_REQUEST,
  ADD_FAVORITE_SUCCESS,
  ADD_FAVORITE_FAIL,
} from "../constants/favoriteConstants";
export const getCategoriesReducer = (
  state = { categories: [], loading: false, error: null },
  action
) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { ...state, loading: true };
    case CATEGORY_LIST_SUCCESS:
      return { ...state, loading: false, categories: action.payload };
    case CATEGORY_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const addFavoriteReducer = (
  state = { loading: false, error: null, success: false },
  action
) => {
  switch (action.type) {
    case ADD_FAVORITE_REQUEST:
      return { ...state, loading: true };
    case ADD_FAVORITE_SUCCESS:
      return { ...state, loading: false, success: true };
    case ADD_FAVORITE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
