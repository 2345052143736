import { GET_PAYMENT_SUCCESS, GET_PAYMENT_FAIL } from "../constants/adminDashboardConstants";
const initialState = {
    getPayment: [],
    error: null,
    loading: false,
};
export const GetPayment= (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYMENT_SUCCESS:
            return {
                ...state,
                getPayment: action.payload,
                error: null,
                loading: false,
            };
        case GET_PAYMENT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
