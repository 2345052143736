import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import styles from "./index.module.scss";
import { ReactComponent as Close } from "../../../../assests/svg/close modal.svg";
import { ReactComponent as CloseDark } from "../../../../assests/svg/canceldark.svg";
import { useSelector } from "react-redux";
import { GetPaymentAmount } from "../../../../store/actions/userActions";
import { useDispatch } from "react-redux";
const ComingForSureModal = ({ open, closeHandler, feature, isService, forsure, paymentHandler, isLoading }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const loading = useSelector(
    (state) => state.acceptpmt?.loading
  );
  const amount = useSelector((state) => state.amount?.amount)
  useEffect(() => {
    if (forsure) {
      dispatch(GetPaymentAmount("ComingForSure"))
    }
    if (feature) {
      if (isService) {
        dispatch(GetPaymentAmount("FeatureService"))
      }
      else {
        dispatch(GetPaymentAmount("Feature"))
      }
    }
  }, [isService])
  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className={styles.overlay}>
        <div className={`${styles.wrapper} ${darkMode ? styles.wrapperDark : ''}`}>
          {darkMode ? (
            <CloseDark className={styles.close} onClick={closeHandler} />
          ) : (
            <Close className={styles.close} onClick={closeHandler} />
          )}
          <>
            <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>
              Confirming requires paying a fees of:
            </h1>
            {amount && amount.amount === 0 ? (
              <h3 className={`${styles.subtitle} ${darkMode ? styles.subtitleDark : ''}`}>${amount && amount.amount}(Free)</h3>
            ) : (
              <h3 className={`${styles.subtitle} ${darkMode ? styles.subtitleDark : ''}`}>${amount && amount.amount}</h3>
            )}
            <div className={styles.btn_container}>
              <button className={`${styles.accept}  ${isLoading ? styles.acceptLoad : ''} ${darkMode ? styles.acceptDark : ''}`}
                onClick={() => {
                  paymentHandler();
                }}
                disabled={isLoading}
              >
                Accept
              </button>
              <button className={`${styles.decline} ${darkMode ? styles.declineDark : ''}`} onClick={closeHandler}>
                Decline
              </button>
            </div>
          </>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  );
};
export default ComingForSureModal;
