import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Pagination } from 'react-bootstrap';
import styles from "./RecommendedForYou.module.scss"
import Layout from "../../../../common/components/Layouts/Layout";
import CurrentPath from "../../../../common/components/CurrentPath/CurrentPath";
import Search from "../../../../common/components/Search/Search";
import Header from "../../../../common/components/Header/Header";
import Footer from "../../../../common/components/Footer/Footer";
import Cards from "../../../../common/components/Cards/Cards";
import { CgSortAz } from "react-icons/cg";
import { MdGridView } from "react-icons/md";
import { AiFillFilter, AiOutlineMenu } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { listMyProductsBySubCategory } from "../../../../store/actions/categoryAction";
import { PRODUCT_LIST_BY_SUBCATEGORY_RESET } from "../../../../store/constants/categoryConstants";
import Loader from "../../../../common/components/Loader/Loader";
import ErrorMessage from "../../../../common/components/ErrorMessage/ErrorMessage";
import { handleFilterIcon } from "../../../../store/actions/filterActions";
import { GetProductBySubCat, GetProductBySubCatSuccess } from "../../../../store/actions/productDetailsAction";
import { GetAllFeaturedProduct, GetAllFeaturedProductSuccess } from "../../../../store/actions/productDetailsAction";
import CardSkel from "../../../../common/components/Cards/CardSkel";
import { Helmet } from "react-helmet";
import { getRecommendedProduct } from "../../../../store/actions/recommendedProductAction";
import FilterModal from "../../../../common/components/FilterModal/FilterModal";
import { GetServicesWishlist } from "../../../../store/actions/servicesActions";
import { GetWishList } from "../../../../store/actions/whishlistActions";
const RecommendedForPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const pageName = "Recommended For You"; // Replace this with the actual page name
        const pageUrl = "/Recommended-For-You"; // Replace this with the actual page URL
        dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }, []);
    const [activePage, setActivePage] = useState(1);
    const [Pro, setPro] = useState([])
    const [sort, setSort] = useState("")
    const params = useParams();
    const darkMode = useSelector((state) => state.darkMode.darkMode);
    const [recommendedProduct, setRecommendedProduct] = useState([])
    const loading = useSelector(
        (state) => state.recommendedProducts?.loading
    );
    useEffect(() => {
        dispatch(getRecommendedProduct())
            .then((data) => {
                setRecommendedProduct(data.data?.products)
            })
            .catch((error) => {
                // console.log(error);
            });
    }, [dispatch]);
    const recommendedProducts = useSelector((state) => state.recommendedProducts?.recommendedProducts?.data?.products)

    const location = useLocation();
    const [first, setFirst] = useState(true);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const { products, error } = Pro;
    const activateFirst = () => {
        setFirst(true);
        setSecond(false);
        setThird(false);
    };
    const activateSecond = () => {
        setFirst(false);
        setSecond(true);
        setThird(false);
    };
    const activateThird = () => {
        setFirst(false);
        setSecond(false);
        setThird(true);
    };

    const [view, setView] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const savedValue = useSelector((state) => state.value.value);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    useEffect(() => {
        function handleResize() {
            setView(window.innerWidth < 445);
        }

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleFilter = () => {
        if (screenWidth > 900) {
            dispatch({ type: 'SAVE_VALUE', payload: !savedValue });
            setIsOpen(!savedValue)
        } else {
            dispatch({ type: 'SAVE_VALUE', payload: false });
            setIsFilterOpen(true)
        }
    };
    useEffect(() => {
        return () => {
            dispatch({ type: 'SAVE_VALUE', payload: false });
        };
    }, []);

    // Handle visibilitychange event
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                dispatch({ type: 'SAVE_VALUE', payload: false });
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    const ServicesWishlist = useSelector((state) => state.ServicesWishlist?.ServicesWishlist?.data?.serviceWishList)
    const wishList = useSelector((state) => state.Wishlist?.Wishlist?.data?.wishList)
    useEffect(() => {
        dispatch(GetServicesWishlist())
        dispatch(GetWishList())
    }, [dispatch]);
    return (
        <>
            <Helmet>
                <title>Recommended For You</title>
                <meta name="Recommended For You" content="Recommended For You" />
                <meta
                    name="keywords"
                    content={`Recommended, products, recommended products, la7i27alak`}
                />
            </Helmet>
            <Layout footer={true} isFilterIcon={true} >
                <section className={`${styles.category_section} ${darkMode ? styles.category_sectionDark : ''}`}>
                    {/* <div className={styles.path}>
          <CurrentPath />
        </div> */}
                    {/* <div className={styles.search}>
                        <Search featured={true} />
                    </div> */}
                    <div className={styles.title_container}>
                        <h1 className={`${styles.title} ${darkMode ? styles.titleDark : ''}`}>
                            Recommended For You
                        </h1>
                        {/* <div style={{ display: 'block' }}>
                        <div className={`${styles.category__type} ${darkMode ? styles.category__typeDark : ""}`}>
                            <button
                                className={`${styles.button} ${first ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
                                onClick={activateFirst}
                            >
                                Product
                            </button>
                            <button
                                className={`${styles.button} ${second ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
                                onClick={activateSecond}
                            >
                                Service
                            </button>
                            <button
                                className={`${styles.button} ${third ? (darkMode ? styles.activeDark : styles.active) : ""} ${darkMode ? styles.buttonDark : ""}`}
                                onClick={activateThird}
                            >
                                Bid
                            </button>
                        </div>
                        
              </div> */}
                    </div>
                    <div className={`${styles.result_container} ${darkMode ? styles.result_containerDark : ''}`}>
                        <h1 className={`${styles.result_title} ${darkMode ? styles.result_titleDark : ''}`}>
                            {recommendedProducts && recommendedProducts.length} Results Found
                        </h1>
                        <div className={styles.result_options}>
                            {/* <div onClick={handleFilter} className={styles.filter_container}>
                                <AiFillFilter className={styles.filter_icon} />
                                <button className={styles.filter_btn}>Filter</button>
                            </div> */}
                            <span className={`${styles.span} ${darkMode ? styles.spanDark : ''}`}
                                onClick={() => setView(!view)}
                            >
                                View:
                                {view ? (
                                    <AiOutlineMenu className={styles.view_icon}
                                    />
                                ) : (
                                    <MdGridView className={styles.view_icon} />
                                )}
                            </span>
                        </div>
                    </div>
                    {loading ? (
                        <div >
                            <CardSkel />
                        </div>
                    ) : error ? (
                        <div className="center">
                            <ErrorMessage>{error}</ErrorMessage>
                        </div>
                    ) : (
                        <div>
                            {recommendedProducts ? (
                                <div className={`grid gap-4
                            ${view ? 'grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3'
                                        :
                                        'grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4'}`}>
                                    {recommendedProducts

                                        .map((p) => (
                                            <Cards
                                                id={p._id}
                                                key={p.id}
                                                title={p.title}
                                                price={p.pricing.price}
                                                time={p.createdAt}
                                                mainimage={p.imageUrls[0]}
                                                featured={p.featured}
                                                location={p.pickUpAddress?.region}
                                                discounted={p.discount?.enabled}
                                                percentage={p.discount?.percentage}
                                                mobile={view}
                                                end={p.timer?.deadline}
                                                timer={p.timer?.enabled}
                                                ServicesWishlist={ServicesWishlist}
                                                wishList={wishList}
                                                old={p.condition}
                                                sold={p && p.status === "sold"}

                                            />
                                        ))}
                                </div>
                            ) : (
                                <CardSkel />
                            )}
                        </div>
                    )}
                </section>
            </Layout>
            <FilterModal
                open={isFilterOpen}
                onClose={() => setIsFilterOpen(false)}
            />
        </>
    );
};
export default RecommendedForPage;