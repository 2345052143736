import { CREATE_SERVICE_FAIL, CREATE_SERVICE_REQUEST, CREATE_SERVICE_SUCCESS } from "../constants/servicesConstants";
const initialState = {
    CreateService: [],
    error: null,
    loading: false,
  };
  
  export const CreateServiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_SERVICE_REQUEST:
            return {
                ...state,
                CreateService: action.payload,
                error: null,
                loading: true,
            };
        case CREATE_SERVICE_SUCCESS:
            return {
                ...state,
                CreateService: action.payload,
                error: null,
                loading: false,
            };
        case CREATE_SERVICE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
  };