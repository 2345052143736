export const CATEGORIES_LIST_REQUEST = "CATEGORIES_LIST_REQUEST";
export const CATEGORIES_LIST_SUCCESS = "CATEGORIES_LIST_SUCCESS";
export const CATEGORIES_LIST_FAIL = "CATEGORIES_LIST_FAIL";
export const CATEGORIES_LIST_RESET = "CATEGORIES_LIST_RESET";
export const PRODUCT_LIST_BY_SUBCATEGORY_REQUEST =
  "PRODUCT_LIST_BY_SUBCATEGORY_REQUEST";
export const PRODUCT_LIST_BY_SUBCATEGORY_SUCCESS =
  "PRODUCT_LIST_BY_SUBCATEGORY_SUCCESS";
export const PRODUCT_LIST_BY_SUBCATEGORY_FAIL =
  "PRODUCT_LIST_BY_SUBCATEGORY_FAIL";
export const PRODUCT_LIST_BY_SUBCATEGORY_RESET =
  "PRODUCT_LIST_BY_SUBCATEGORY_RESET";
export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";
export const GET_ALL_CATEGORIES_REQUEST = "GET_ALL_CATEGORIES_REQUEST";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_FAIL = "GET_ALL_CATEGORIES_FAIL";
export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";
export const GET_ALL_SUB_CATEGORIES_REQUEST = "GET_ALL_SUB_CATEGORIES_REQUEST";
export const GET_ALL_SUB_CATEGORIES_SUCCESS = "GET_ALL_SUB_CATEGORIES_SUCCESS";
export const GET_ALL_SUB_CATEGORIES_FAIL = "GET_ALL_SUB_CATEGORIES_FAIL";
export const GET_SINGLE_CATEGORIES_REQUEST = "GET_SINGLE_CATEGORIES_REQUEST";
export const GET_SINGLE_CATEGORIES_SUCCESS = "GET_SINGLE_CATEGORIES_SUCCESS";
export const GET_SINGLE_CATEGORIES_FAIL = "GET_SINGLE_CATEGORIES_FAIL";
export const PUT_PREFERRED_CATEGORY_SUCCESS = "PUT_PREFERRED_CATEGORY_SUCCESS";
export const PUT_PREFERRED_CATEGORY_FAIL = "PUT_PREFERRED_CATEGORY_FAIL";
export const GET_FAV_CATEGORIES_SUCCESS = "GET_FAV_CATEGORIES_SUCCESS";
export const GET_FAV_CATEGORIES_FAIL = "GET_FAV_CATEGORIES_FAIL";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_FAIL = "EDIT_CATEGORY_FAIL";
