import React, { useState, useRef, useEffect } from 'react';
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsFillTrashFill } from "react-icons/bs";
import { DeleteProduct } from '../../../../store/actions/productDetailsAction';
import { useDispatch } from 'react-redux';
import './threeDots.scss'
import { ChangeRole } from '../../../../store/actions/AdminDashboard';
import { GetAllUsers } from '../../../../store/actions/AdminDashboard';
const ThreeDots = ({ id }) => {
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1050);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial value
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [hidden, setHidden] = useState(false);
  const [showThreeDotsDropdown, setShowThreeDotsDropdown] = useState(false);
  const toggleThreeDotsDropdown = () => {
    setShowThreeDotsDropdown(!showThreeDotsDropdown);
    setShowDropdown(false);
  };
  const handleChangeRoleAdmin = async () => {
    const rawData = { "userId": id, "isAdmin": true }
    const response = await dispatch(ChangeRole(rawData))
    await dispatch(GetAllUsers())
  };
  const handleChangeRoleUser = async () => {
    const rawData = { "userId": id, "isAdmin": false }
    const response = await dispatch(ChangeRole(rawData))
    await dispatch(GetAllUsers())
  }
  const handleChangeRole = async (event) => {
    const selectedRole = event.target.value;
    const rawData = { "userId": id, "isAdmin": selectedRole === "ADMIN" };
    const response = await dispatch(ChangeRole(rawData));
    await dispatch(GetAllUsers());
  };
  
  return hidden ? null : (
    <>
     
        <div>
        <select className="selectDropdown" onChange={handleChangeRole}>
      <option value=""selected disabled={true}>ROLE</option>
      <option value="ADMIN">ADMIN</option>
      <option value="USER">USER</option>
    </select>
      </div>
    </>
  );
}
export default ThreeDots;