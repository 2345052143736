import { SHARE_LINK_FAIL, SHARE_LINK_REQUEST, SHARE_LINK_SUCCESS } from "../constants/shareLinkConstant";
const initialState = {
    getLink: [],
    error: null,
    loading: false,
  };
  
  export const ShareLinkReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHARE_LINK_REQUEST:
            return {
                ...state,
                getLink: action.payload,
                error: null,
                loading: true,
            };
        case SHARE_LINK_SUCCESS:
            return {
                ...state,
                getLink: action.payload,
                error: null,
                loading: false,
            };
        case SHARE_LINK_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
  };