import React, { useEffect, useState } from "react";
import styles from "./BuyerWaitList.module.scss";
import img from '../../assests/images/PS5 1.png'
import Card from "./Card/Card";
import Layout from '../../common/components/Layouts/Layout'
import { useDispatch, useSelector } from "react-redux/es/exports";
import { GetMyWaitingList } from "../../store/actions/waitingListActions";
import Img from "../../assests/svg/order history empty.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Loader from "../../common/components/Loader/Loader";
const BuyerWaitList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (location.state?.isDropDown) {
      const pageName = "Waiting list"; // Replace this with the actual page name
      const pageUrl = "/user/waiting-list"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    } else {
      dispatch({ type: "CLEAR_BREADCRUMBS" })
      const pageName = "Waiting list"; // Replace this with the actual page name
      const pageUrl = "/user/waiting-list"; // Replace this with the actual page URL
      dispatch({ type: "ADD_BREADCRUMB", payload: { name: pageName, url: pageUrl } });
    }
  }, []);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const MyWaitingList = useSelector((state) => state.myWaitingList?.myWaitingList?.offers);
  const loading = useSelector((state) => state.myWaitingList.loading);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(GetMyWaitingList());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>My Waiting List</title>
        <meta name='My Waiting List' content="My Waiting List" />
        <meta
          name="keywords"
          content={`My waiting list, la7i27alak`}
        />
      </Helmet>
      <Layout title={'My Waiting List'}>
        <section className={styles.container}>
          <div>
            {loading ? (
              <Loader />
            ) : (
              MyWaitingList && MyWaitingList.length > 0 ? (
                <div className={styles.card_container}>
                  {MyWaitingList.map((offer) => (
                     offer.product && ( // <-- Add the condition here
                      <Card
                        key={offer._id}
                        title={offer.product.title}
                        featured={offer.product.featured}
                        image={offer.product.imageUrls[0]}
                        price={offer.product.pricing?.price}
                        location={offer.product.pickUpAddress?.region}
                        condition={offer.product.condition}
                        time={offer.product.createdAt}
                        isDiscount={offer.product.discount?.enabled}
                        newPrice={'555555'}
                        status={offer.status}
                        percentage={offer.product.discount?.percentage}
                        offerId={offer._id}
                        coordinatesBuyer={offer.buyerLastKnown}
                        coordinatesSeller={offer.sellerLastKnown}
                        user={offer.product?.user}
                        id={offer.product?._id}
                      />
                    )
                  ))}
                </div>
              ) : (
                <div className={styles.empty}>
                  <img src={Img} alt='empty' />
                  <h2 className={`${styles.txt} ${darkMode ? styles.txtDark : ''}`}>No offers to show.</h2>
                  <button className={`${styles.btn} ${darkMode ? styles.btnDark : ''}`} onClick={() => navigate("/")}>
                    Go Shopping
                  </button>
                </div>
              ))}
          </div>
        </section>
      </Layout>
    </>
  );
};
export default BuyerWaitList;
