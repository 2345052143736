import React, { useState, useEffect } from "react";
import styles from "./FavoriteCategories.module.scss";
import Car from "../../../../assests/images/Car.png";
import Electronics from "../../../../assests/images/Electronics.png";
import Furniture from "../../../../assests/images/Furniture.png";
import Fashion from "../../../../assests/images/FashionANDBeauty.png";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesList } from "../../../../store/actions/favoriteActions";
import Loader from "../../Loader/Loader";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import Card from "./Card/Card";
import { addCategoryToFavorite } from "../../../../store/actions/favoriteActions";
import { PutPreferredCategory } from "../../../../store/actions/categoryAction";
import logo from '../../../../assests/svg/La7i2_7alak1.1.svg'
const FavoriteCategories = ({ setStep, closeHandler }) => {
  const dispatch = useDispatch();
  const categories1 = useSelector((state) => state.categories?.categories?.data?.categories);
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const getCategories = useSelector((state) => state.categories?.categories?.data?.categories);
  const { categories, error, loading } = getCategories;
  const userById = useSelector((state) => state.userById);
  const { user } = userById;
  const addFavorite = useSelector((state) => state.addFavorite);
  const { success, error: errorAdd, loading: loadingAdd } = addFavorite;
  const [selectedCategory, setSelectedCategory] = useState([]);
  let selectedCategories = [];
  const clickHandler = (categoryId) => {
    if (selectedCategory.includes(categoryId)) {
      // Category already selected, remove it from the list
      setSelectedCategory(selectedCategory.filter(id => id !== categoryId));
    } else {
      // Category not selected, add it to the list
      setSelectedCategory([...selectedCategory, categoryId]);
    }
  };
  
  useEffect(() => {
    dispatch(getCategoriesList());
  }, [dispatch]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("prefCategories", JSON.stringify(selectedCategory))
      const response = await dispatch(PutPreferredCategory(formData));
      if(response){
        closeHandler();
      }
    } catch (error) {
      // console.log(error);
    } 
  }
  return (
    <div className={styles.favorite_container}>
      <div
        className={`${styles.skip} ${darkMode? styles.skipDark:''}`}
        onClick={() => {
          closeHandler();
          window.location.reload();
          setStep("login");
        }}
      >
        Skip
      </div>
      <div className={styles.logoCont}>
      <img src={logo} alt='logo' className={styles.logo}/>
      </div>      <h2 className={styles.subtitle}>Select your Favorite Categories</h2>
      <p className={styles.text}>
        Select categories you are interested in, this will help us recommend
        products you would love. It can be changed from settings later.
      </p>
      <div className={`${styles.cards_container}`}>
        {loading ? (
          <Loader />
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          categories1.map((cat) => (
            <Card
              key={cat._id}
              title={cat.title}
              image={cat.iconUrl}
              // setSelectedCategories={setSelectedCategories}
              id={cat._id}
              clickHandler={clickHandler}
            />
          ))
        )}
      </div>
      <button className={`${styles.done} ${darkMode? styles.doneDark:''}`} onClick={handleSubmit}>
        Done
      </button>
    </div>
  );
};
export default FavoriteCategories;
