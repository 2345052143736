import React, { useState, useEffect } from "react";
import styles from "./Negotiate.module.scss";
import { ReactComponent as Coming } from "../../../../assests/svg/coming white.svg";
import { ReactComponent as ComingSure } from "../../../../assests/svg/coming sure white.svg";
import NegotiateModal from "../NegotiateModal/NegotiateModal";
import ComingModal from "../ComingModal/ComingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWaitingListHandler,
  paymentComingForSureHandler,
  removeFromWaitingListHandler,
  checkUserInWaitingListHandler,
} from "../../../../store/actions/waitingListActions";
import { GetPaymentAmount } from "../../../../store/actions/userActions";
import { createCustomerStripe } from "../../../../store/actions/paymentActions";
import { useNavigate } from "react-router-dom";
import ComingForSureModal from "../ComingForSureModal/ComingForSureModal";
import {
  ADD_TO_WAITINGLIST_RESET,
  REMOVE_FROM_WAITINGLIST_RESET,
} from "../../../../store/constants/waitingListConstants";
import { USER_RESET } from "../../../../store/constants/userConstants";
import Modal from "../../../../common/components/Modal/Modal";
import { CheckIfUserInWaitingList } from "../../../../store/actions/ProductStateAction";
import { SendOfferForProduct } from "../../../../store/actions/bidActions";
// import { error } from "console";
import { toast } from "react-toastify";
const Negotiate = ({ productId, preview, card_id, image, price, name, featured, date, condition, id, location, ifnegotiate, user1, isDiscount, percentage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode.darkMode);
  const checkIfInWaitingList = useSelector((state) => state.checkInWaitingList?.checkInWaitingList?.exists)
  useEffect(() => {
    dispatch(CheckIfUserInWaitingList(id))
  }, [id])
  const [negotiate, setNegotiate] = useState(false);
  // coming modal
  const [coming, setComing] = useState(false);
  const [comingSure, setComingSure] = useState(false);
  //ComingForSureModal
  const [payModal, setPayModal] = useState(false);
  const [loading1, setLoading1] = useState(false)
  // login modal
  const [isOpen, setIsOpen] = useState(false);
  const removeFromWaitingList = useSelector(
    (state) => state.removeFromWaitingList
  );
  const { success } = removeFromWaitingList;
  const addToWaitingList = useSelector((state) => state.addToWaitingList);
  const { success: successAdd } = addToWaitingList;
  const { user } = useSelector((state) => state.userLogin);
  const [loggedInUser, setLoggedInUser] = useState(user ? true : false);
  useEffect(() => {
    setLoggedInUser(user ? true : false);
  }, [user]);
  const checkUserInWaitingList = useSelector(
    (state) => state.checkUserInWaitingList
  );
  const { user: userInList } = checkUserInWaitingList;
  const closeHandler = () => {
    if (coming) {
      setComing(false);
    }
    if (comingSure) {
      setComingSure(false);
    }
  };
  const loading = useSelector(
    (state) => state.acceptpmt.loading
  );
  const [isLoading, setIsLoading] = useState(false)
  const amount = useSelector((state) => state.amount?.amount)
  const paymentHandler = async () => {
    setIsLoading(true)
    await dispatch(GetPaymentAmount("ComingForSure"))
    const comingNowForSureJoin = { "productId": productId, "amount": amount.amount, "currency": "USD", "comingForSure": true }
    const response = await dispatch(SendOfferForProduct(JSON.stringify(comingNowForSureJoin)))
    await dispatch(CheckIfUserInWaitingList(id))
    if (response) {
      setIsLoading(false)
      setPayModal(false);
      setComingSure(true);
    } else {
      navigate('/user/payment/add-card')
      toast.error('Please add a payment.')
      setIsLoading(false)
    }
  };
  const commingNowHandler = async () => {
    setLoading1(true)
    const comingNowJoin = { "productId": productId, "amount": 0, "currency": "USD", "comingForSure": false }
    const response = await dispatch(SendOfferForProduct(JSON.stringify(comingNowJoin)))
    await dispatch(CheckIfUserInWaitingList(id))
    if (response) {
      setLoading1(false)
      setComing(true);
    } else {
      toast.error('Please try again.')
    }
  };
  useEffect(() => {
    if (user !== null) {
      dispatch(checkUserInWaitingListHandler(productId, user.id));
    }
  }, [dispatch, productId, user, success, successAdd]);
  return (
    <>
      <Modal open={isOpen} closeHandler={() => setIsOpen(false)} />
      {user !== null ? (
        <>
          {(ifnegotiate === true || ifnegotiate === 1) && (
            !preview ? (
              <button
                className={`${styles.negotiate} ${darkMode ? styles.negotiateDark : ''}`}
                onClick={() => setNegotiate(true)}
              >
                Negotiate
              </button>
            ) : (
              <button
                className={`${styles.negotiate} ${darkMode ? styles.negotiateDark : ''}`}
              >
                Negotiate
              </button>
            )
          )}
          <div className={styles.line}></div>
          {checkIfInWaitingList === true ? (
            <button className={`${styles.coming_btn} ${styles.now}`} onClick={() => navigate(`/user/waiting-list`, { state: { "isDropDown": true } })}>
              My Waiting List
            </button>
          ) : (
            <div>
              {!preview ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <button
                      className={`${styles.coming_btn} ${styles.now}  ${loading1 ? styles.acceptLoad : ''}`}
                      onClick={() => { commingNowHandler() }}
                      disabled={loading1}
                    >
                      <Coming className={styles.btn_icon} />
                      I am coming now
                    </button>
                    <button
                      className={`${styles.coming_btn} ${styles.sure}`}
                      onClick={() => setPayModal(true)}
                    >
                      <ComingSure className={styles.btn_icon} />
                      I am coming now For Sure
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <button
                    className={`${styles.coming_btn} ${styles.now}`}
                  >
                    <Coming className={styles.btn_icon} />
                    I am coming now
                  </button>
                  <button
                    className={`${styles.coming_btn} ${styles.sure}`}
                  >
                    <ComingSure className={styles.btn_icon} />
                    I am coming now For Sure
                  </button>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <button
            className={`${styles.coming_btn} ${styles.sure}`}
            onClick={() => {
              dispatch({ type: USER_RESET });
              setIsOpen(true);
            }}
          >
            Login For More details
          </button>
        </>
      )}
      <NegotiateModal
        open={negotiate}
        closeHandler={() => setNegotiate(false)}
        image={image}
        price={price}
        name={name}
        userProduct={user1}
        featured={featured}
        condition={condition}
        location={location}
        id={id}
        date={date}
        isDiscount={isDiscount}
        percentage={percentage}
      />
      <ComingModal
        open={coming | comingSure}
        closeHandler={closeHandler}
        coming={coming}
        image={image}
        featured={featured}
        price={price}
        name={name}
        date={date}
        condition={condition}
        id={id}
        location={location}
      />
      <ComingForSureModal
        open={payModal}
        closeHandler={() => setPayModal(false)}
        paymentHandler={paymentHandler}
        isLoading={isLoading}
        feature={false}
        forsure={true}
      />
    </>
  );
};
export default Negotiate;
